import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ModalActions from 'components/modal-actions';

import useStyles from './styles';

interface IUnsavedModal {
  confirm: () => void;
  cancel: () => void;
}

const UseParentModal: FC<IUnsavedModal> = ({ confirm, cancel }) => {
  const classes = useStyles();
  const { t } = useTranslation('payment.page');

  return (
    <>
      <div className={classes.text}>{t('use.parent.modal.label')}</div>
      <ModalActions handleClose={cancel} handleSave={confirm} />
    </>
  );
};

export default UseParentModal;
