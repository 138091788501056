import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 15,
    color: theme.palette.primary.main,

    '& > svg': {
      width: '1em',
    },
  },

  reversed: {
    flexDirection: 'column-reverse',

    '& > svg': {
      transform: 'rotate(180deg)',
    },
  },
}));

export default useStyles;
