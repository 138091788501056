import queryString from 'query-string';

import server from 'utils/server';

export interface IUsageStatistic {
  activeDrivers: number;
  totalDrivers: number;
  totalVehicles: number;
}

/**
 * Get count of active drivers, total drivers and total vehicles
 * @async
 * @returns {Promise<IUsageStatistic>} The usage statistic object.
 */
export const getDriversStatusReq = async (): Promise<IUsageStatistic> => {
  const params = {
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
  };

  return server.services.beService
    .get<IUsageStatistic>('scoring/usage/statistic?' + queryString.stringify(params))
    .then((res) => res.data);
};
