import SliderMUI from '@mui/material/Slider';
import cx from 'classnames';
import React, { FC, useMemo } from 'react';

import useStyles from './styles';

interface IProps {
  min: number;
  max: number;
  current: number;
}

const SliderView: FC<IProps> = ({ min, max, current }) => {
  const classes = useStyles();

  const level = useMemo(() => {
    if (current < min) {
      return 'low';
    }

    if (current > max) {
      return 'high';
    }

    return 'normal';
  }, [current, max, min]);

  const scope = useMemo(() => {
    const length = max - min;
    const step = length / 60;
    const width = step * 20;

    return {
      min: min - width,
      max: max + width,
    };
  }, [max, min]);

  const marks = [
    {
      value: min,
      label: min,
    },
    {
      value: max,
      label: max,
    },
  ];

  return (
    <div className={classes.wrap}>
      <SliderMUI
        value={current}
        max={scope.max}
        min={scope.min}
        marks={marks}
        classes={{
          root: classes.root,
          rail: classes.rail,
          track: classes.track,
          mark: classes.mark,
          markLabel: classes.markLabel,
          thumb: cx(classes.thumb, `${level}`),
          valueLabel: classes.valueLabel,
        }}
        disabled={true}
      />
      <div className={cx(classes.line, `${level}`)}>
        <span />
        <span />
        <span />
      </div>
    </div>
  );
};

export default SliderView;
