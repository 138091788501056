import cx from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SAVING_CIRCLE_CHART_TYPE } from 'recoil/saving/circleCharts/atom';

import ScoringContainer from '../Scoring/components/ScoringContainer';

import Chart from './components/Chart';
import CircleChartApi from './components/CircleChartaApi';
import Drivers from './components/Drivers';
import Filter from './components/Filter';
import useStyles from './styles';

const Savings = () => {
  const classes = useStyles();
  const { t } = useTranslation('saving.page');

  const circleChartData = useMemo(
    () =>
      [
        {
          id: SAVING_CIRCLE_CHART_TYPE.TOTAL,
          title: t('fleet.savings.label'),
          titleAdditional: '',
          containerSize: 'large',
          isBigChart: true,
          path: 'saving/total/fleet',
        },
        {
          id: SAVING_CIRCLE_CHART_TYPE.FUEL,
          title: t('fuel.label'),
          titleAdditional: '',
          containerSize: 'large',
          isBigChart: false,
          path: 'saving/fuel',
        },
        {
          id: SAVING_CIRCLE_CHART_TYPE.WEAR_AND_TEAR,
          title: t('wear.and.tear.label'),
          titleAdditional: '',
          containerSize: 'large',
          isBigChart: false,
          path: 'saving/wearAndTear',
        },
        {
          id: SAVING_CIRCLE_CHART_TYPE.MAINTENANCE,
          title: t('maintenance.label'),
          titleAdditional: '',
          containerSize: 'large',
          isBigChart: false,
          path: 'saving/maintenance',
        },
        {
          id: SAVING_CIRCLE_CHART_TYPE.EMISSIONS,
          title: t('emissions.label'),
          containerSize: 'large',
          titleAdditional: 'gCo₂/km',
          isBigChart: false,
          path: 'saving/emissions',
        },
      ] as const,
    [t],
  );

  return (
    <div className={classes.wrap}>
      <div data-id='chart'>
        <ScoringContainer title={t('savings.label')} size='large' titlePosition='left'>
          <Chart />
        </ScoringContainer>
      </div>

      <div className={classes.filter} data-id='filter'>
        <ScoringContainer>
          <Filter />
        </ScoringContainer>
      </div>

      {circleChartData.map(({ id, isBigChart, title, containerSize, path, titleAdditional }) => {
        return (
          <div key={id} data-id={id} className={cx(!isBigChart && classes.isSmall)}>
            <ScoringContainer
              title={t(title)}
              size={containerSize}
              titleAdditional={titleAdditional}
              titlePosition='left'
            >
              <CircleChartApi path={path} isBig={isBigChart} id={id} />
            </ScoringContainer>
          </div>
        );
      })}

      <div className={classes.drivers} data-id='drivers'>
        <ScoringContainer title={t('driver.breakdown.label')} size='large' titlePosition='left'>
          <Drivers />
        </ScoringContainer>
      </div>
    </div>
  );
};

export default Savings;
