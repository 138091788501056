import queryString from 'query-string';

import { ISortParameters } from 'interfaces/common/sort';
import { IResponseWrapper } from 'interfaces/server/response-wrapper';

import server from 'utils/server';

import { ISettingDriversFilters } from 'recoil/settings/drivers/filters/atom';

// TODO: need refactor after changed api
export interface ISettingDriver {
  driverId: string;
  companyId: null;
  firstName: string;
  lastName: string;
  phone: null;
  currentScoring: null;
  diffScoring: null;
  better: boolean;
  lastPointReceivedAt: null;
  imei: null;
  penaltyCards: null;
  driverCharacteristic: null;
  active: boolean;
  brandName: null;
  modelName: null;
  country: null;
  plateNumber: null;
  type: null;
  vehicleId: null;
  penalties: null;
  totalKm: null;
  dailyKm: null;
  dailyH: null;
  rating: boolean;
  basic: null;
  bonus: null;
  vehicle: null;
  crashCount: null;
  hasNonValidatedCrashes: boolean;
  idling: null;
  engineMinutes: null;
  status: null;
  companyName: string;
  managerName: string;
  registrationDate: number;
}
/**
 * Async function to retrieve setting drivers.
 *
 * @param {ISortParameters} sort - Object defining the sorting parameters.
 * @param {number} page - The page number to be retrieved.
 * @param {IFilters} filters - Object defining the filter parameters.
 *
 * @returns {Promise<IResponseWrapper<ISettingDriver[]>>} - A promise that resolves to an array of setting drivers.
 */
export const settingDriversReq = async (
  sort: ISortParameters,
  page: number,
  filters: ISettingDriversFilters,
): Promise<IResponseWrapper<ISettingDriver[]>> => {
  const params = {
    page,
    size: 100,
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
    ...sort,
  };

  const body = { ...filters };

  return server.services.beService
    .post<IResponseWrapper<ISettingDriver[]>>(
      'drivers/settings-page-drivers?' + queryString.stringify(params),
      body,
    )
    .then((res) => res.data);
};
