import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { getScoringColor } from 'utils/helpers/get-scoring-color';
import { isEmptyValue } from 'utils/helpers/is-empty-value';

type Props = {
  scoring?: number;
};

const useStyles = makeStyles<Theme, Props>((theme) => ({
  wrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },

  infoBtn: {
    width: 22,
    height: 22,
    position: 'absolute',
    right: 11,
    top: 8,
    opacity: 0.2,
    cursor: 'pointer',
  },

  closeIcon: {
    width: 12,
    height: 12,
    position: 'absolute',
    right: 15,
    top: 13,
    cursor: 'pointer',
  },

  title: {
    fontSize: 18,
    fontWeight: 500,
    color: ({ scoring }) => (!isEmptyValue(scoring) ? getScoringColor(scoring!) : 'unset'),
  },

  counter: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 70,
    fontWeight: 500,
    columnGap: 5,
  },

  scoring: {
    color: ({ scoring }) => (!isEmptyValue(scoring) ? getScoringColor(scoring!) : 'unset'),
  },

  delta: {
    fontSize: 20,

    '&:first-child': {
      opacity: 0,
    },
  },

  subtitle: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.primary.dark,
    maxWidth: 240,
    textAlign: 'center',
  },

  icon: {
    width: 20,

    '&.isBetter': {
      transform: 'rotate(180deg)',
    },
  },

  emptyWrap: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 28,
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
