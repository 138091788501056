import { startOfDay, endOfDay } from 'date-fns';
import queryString from 'query-string';

import { TDay, TMonth, TYear } from 'interfaces/common/date-iso';

import server from 'utils/server';

import { IGetDriverTrip } from './get-trip-points';

/**
 * Get all driver trips for a specific day.
 *
 * @async
 * @param {string} id - The ID of the driver.
 * @param {string} date - The date in the format `${TDay}.${TMonth}.${TYear}`.
 * @returns {Promise<IGetDriverTrip[]>} - A promise that resolves to an array of driver trip requests.
 */
export const driverShortAllDayReq = async (
  id: string,
  date: `${TDay}.${TMonth}.${TYear}`,
): Promise<IGetDriverTrip[]> => {
  const [day, month, year] = date.split('.');
  const result = new Date(Number(year), Number(month) - 1, Number(day));
  const params = {
    startAt: startOfDay(result).getTime(),
    finishAt: endOfDay(result).getTime(),
  };

  return server.services.beService
    .get<IGetDriverTrip[]>(`map/drivers/${id}/trips/by-period?` + queryString.stringify(params))
    .then((res) => res.data);
};
