import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SimpleBar from 'simplebar-react';

import { getCountriesForFuel } from 'requests/fuel-price-settings-controller/get-available-countries';

import Loader from 'components/Loader';
import Modal from 'components/modal';

import Header from '../../components/header';

import PriceModal from './price-modal';
import useStyles from './styles';

const FuelPrices = () => {
  const classes = useStyles();
  const { t } = useTranslation(['setting.fuel.page', 'setting.page']);

  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState<string[] | null>(null);
  const [currentCountry, setCurrentCountry] = useState<string | null>(null);

  /**
   * Get list of countries
   */
  useEffect(() => {
    const request = async () => {
      try {
        setIsLoading(true);
        const res = await getCountriesForFuel();
        setCountries(res);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    request();
  }, []);

  /**
   * Close modal
   */
  const closeModalHandler = useCallback(() => setCurrentCountry(null), []);

  return (
    <div>
      <Header backBtn={{ label: t('setting.page:fuel.label') }} />

      {isLoading && <Loader lightMode width={150} />}

      <SimpleBar className={classes.pagesWrapper}>
        <div className={classes.pagesList}>
          {countries?.map((item) => (
            <div
              className={classes.pageName}
              key={item}
              onClick={() => setCurrentCountry(item)}
              data-testid='country'
            >
              {item}
            </div>
          ))}
        </div>
      </SimpleBar>

      <Modal open={Boolean(currentCountry)} onClose={closeModalHandler}>
        <PriceModal onClose={closeModalHandler} country={currentCountry} />
      </Modal>
    </div>
  );
};

export default FuelPrices;
