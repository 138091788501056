import queryString from 'query-string';

import { ACTIVITY_STATUSES } from 'interfaces/common/activity-statuses';
import { COUNTRIES } from 'interfaces/common/countries';
import { PENALTY_CARD } from 'interfaces/common/penalty-card';
import { ISortParameters } from 'interfaces/common/sort';
import { IResponseWrapper } from 'interfaces/server/response-wrapper';
import { DEVICE_FUNCTION } from 'interfaces/vehicles/device-functions';
import { ENGINE_STATUS } from 'interfaces/vehicles/engine-status';
import { VEHICLE_TYPES } from 'interfaces/vehicles/types';

import server from 'utils/server';

import { IVehicleFilters } from 'recoil/settings/vehicles/filters/atom';

import { IDeviceInfo } from '../../mobile-service/get-live-trip-pionts';

export interface IVehiclesListItem {
  brandName: string;
  condition: null;
  country: COUNTRIES;
  crashCount: 0;
  dailyKm: null | number;
  deviceFunctions: DEVICE_FUNCTION[];
  deviceInfo: null | IDeviceInfo;
  driveTime: null | number;
  engineHoursBySecond: null | number;
  engineStatus: ENGINE_STATUS;
  fuel: null;
  id: string;
  idleTime: null | number;
  imei: string;
  maintenance: null;
  modelName: string;
  parkingTime: null | number;
  penaltyCards: null | PENALTY_CARD[];
  plateNumber: string;
  registration: string;
  scoring: null | number;
  status: ACTIVITY_STATUSES;
  totalKm: number;
  type: VEHICLE_TYPES;
  vin: string;
  year: number;
}

export const vehiclesGetReq = async (
  page: number,
  sort: ISortParameters,
  filters: IVehicleFilters,
) => {
  const params = {
    size: 100,
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
    page,
    ...sort,
  };

  const body = {
    ...filters,
    companyIds: filters.companyIds && [filters.companyIds],
    userIds: filters.userIds && [filters.userIds],
  };

  return server.services.beService
    .post('vehicles?' + queryString.stringify(params), body)
    .then<IResponseWrapper<IVehiclesListItem[]>>((res) => res.data);
};
