import { ISuccessBaseResponse } from 'interfaces/common/success-base-response';

import server from 'utils/server';

/**
 * Deletes team members from a specific team.
 *
 * @param {string} teamId - The ID of the team from which members will be deleted.
 * @param {boolean} all - Optional. Determines if all team members should be deleted. Default value is false.
 * @param {string[]} driverIds - Optional. An array of driver IDs to be deleted from the team. Default value is an empty array.
 *
 * @returns {Promise<ISuccessBaseResponse>} - A promise that resolves to the response data from the server.
 */
export const deleteTeamMembersReq = async (
  teamId: string,
  all: boolean = false,
  driverIds: string[] = [],
): Promise<ISuccessBaseResponse> => {
  const body = {
    all,
    driverIds,
  };

  return server.services.beService
    .put<ISuccessBaseResponse>(`teams/remove/${teamId}`, body)
    .then((res) => res.data);
};
