import Badge from '@mui/material/Badge';
import cx from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { driverTripsUrlRegExp } from 'constants/driver';

import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg';
import { ReactComponent as DownloadIcon } from 'assets/images/download.svg';
import { ReactComponent as FilterIcon } from 'assets/images/map-filter.svg';

import { isEmptyValue } from 'utils/helpers/is-empty-value';
import RouteManager from 'utils/services/route-manager';

import Search from 'components/search';

import { CALENDAR, CALENDAR_DEFAULT_TYPES } from './data';
import useStyles from './styles';

interface IProps {
  reportHandler: (() => void) | null;
  filterHandler: (() => void) | null;
  searchHandler: ((data: { query: string }) => void) | null;
  calendarHandler: ((startDate: number, endDate: number) => void) | null;
  showDateFiltersHandler: (() => void) | null;
  selectedPeriod: CALENDAR_DEFAULT_TYPES | null;
  setSelectedPeriod: ((id: CALENDAR_DEFAULT_TYPES) => void) | null;
  searchValue: string | null;
  appliedFiltersCount: number;
}

const Header: FC<IProps> = ({
  reportHandler,
  filterHandler,
  searchHandler,
  calendarHandler,
  showDateFiltersHandler,
  selectedPeriod,
  setSelectedPeriod,
  searchValue,
  appliedFiltersCount,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('calendar.service');

  const dateBoxHandler = (
    id: CALENDAR_DEFAULT_TYPES,
    startDate: number | null,
    endDate: number | null,
  ) => {
    if (id === CALENDAR_DEFAULT_TYPES.PERIOD || startDate === null || endDate === null) {
      if (showDateFiltersHandler) {
        showDateFiltersHandler();
      }
      return;
    }
    if (setSelectedPeriod) {
      setSelectedPeriod(id);
    }
    if (calendarHandler) {
      calendarHandler(startDate, endDate);
    }
  };

  const submitSearchHandler = (data: { query: string }) => {
    if (searchHandler) {
      searchHandler(data);
    }
  };

  const clearSearchHandler = () => {
    if (searchHandler) {
      searchHandler({ query: '' });
    }
  };

  return (
    <div className={classes.wrap}>
      {driverTripsUrlRegExp.test(location.pathname) && (
        <div
          className={classes.backBtn}
          onClick={() => navigate(RouteManager.path('drivers'))}
          data-testid='button-back'
        >
          <ArrowIcon />
        </div>
      )}
      <div className={classes.tools}>
        <div className={classes.calendar} data-testid='calendar-wrap'>
          {CALENDAR(t).map(({ title, subtitle, startDate, endDate, id }) => (
            <div
              key={id}
              className={cx(classes.dateBox, { isSelected: id === selectedPeriod })}
              onClick={() => dateBoxHandler(id, startDate, endDate)}
              data-testid={`calendar-item-${id}`}
            >
              <div className={classes.dateTitle}>{title}</div>
              <div className={classes.dateSubtitle}>{subtitle}</div>
            </div>
          ))}
        </div>

        {searchHandler && !isEmptyValue(searchValue) && (
          <Search
            wrapClass={classes.search}
            onSubmit={submitSearchHandler}
            onCancel={clearSearchHandler}
            searchValue={searchValue!}
          />
        )}

        <div className={classes.btnsPanel}>
          {filterHandler && (
            <div className={cx(classes.btn)} onClick={filterHandler} data-testid='button-filters'>
              <Badge
                badgeContent={appliedFiltersCount}
                color='secondary'
                invisible={!appliedFiltersCount}
                classes={{ badge: classes.badge }}
              >
                <FilterIcon />
              </Badge>
            </div>
          )}

          {reportHandler && (
            <div className={cx(classes.btn)} onClick={reportHandler} data-testid='button-download'>
              <DownloadIcon />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
