import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

interface Props {
  technicalMessageBlockHeight: number;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    paddingInline: 50,
    paddingTop: 15,
  },

  title: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.primary.dark,
    alignSelf: 'flex-end',
    marginBottom: 20,
  },

  items: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    columnGap: 20,
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    rowGap: 20,
    overflow: 'auto',
    marginBottom: 40,
    height: ({ technicalMessageBlockHeight }) =>
      `calc(100vh - 265px - ${technicalMessageBlockHeight}px)`,
  },

  item: {
    width: 'calc(100% / 3 - 40px / 3)',
    textDecoration: 'none',
  },
}));

export default useStyles;
