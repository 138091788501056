import { selector } from 'recoil';

import { NOTIFICATION_TABS } from 'interfaces/notifications/notification-tab';
import { NOTIFICATION_TYPES } from 'interfaces/notifications/notification-types';

import { TABS_DATA } from 'constants/notifications';

import { IFormValues } from 'modules/Notifications/filter/form-shema';

import notificationActiveTabAtom from '../active-tab';
import notificationAppliedFiltersAtom from '../applied-filters';

/**
 * Function to initialize the tabs data with an empty callback.
 * This helps in managing and handling tabs in an application.
 *
 * @returns {Object} - The initial tabs data.
 */
const tabs = TABS_DATA(); // this call here because we don't need translates for logic

/**
 * A selector that filters notification requests based on the applied filters and active tab.
 *
 * @param {IFormValues<NOTIFICATION_TYPES[] | null>} notificationRequestFiltersSelector - The input object for the selector.
 * @returns {IFormValues<NOTIFICATION_TYPES[] | null>} - The filtered notification request object.
 */
const notificationRequestFiltersSelector = selector<IFormValues<NOTIFICATION_TYPES[] | null>>({
  key: 'notificationRequestFiltersSelector',
  get: ({ get }) => {
    const activeTab = get(notificationActiveTabAtom);
    const tabCartTypes = tabs.find(({ value }) => value === activeTab)!.cartType;
    const appliedFilters = get(notificationAppliedFiltersAtom);

    // if no filters are selected, then we make a request with default filters for the tab
    if (appliedFilters.cartType.length === 0) {
      return {
        ...appliedFilters,
        cartType: tabCartTypes,
      };
    }

    const intersection = appliedFilters.cartType.filter((value) => tabCartTypes.includes(value));

    if (activeTab === NOTIFICATION_TABS.ALL) {
      return appliedFilters;
    }

    // if no matching filters found, return null for blocking request
    if (intersection.length === 0) {
      return {
        ...appliedFilters,
        cartType: null,
      };
    }

    return {
      ...appliedFilters,
      cartType: intersection,
    };
  },
});

export default notificationRequestFiltersSelector;
