import React, { Suspense } from 'react';
import { RouteObject } from 'react-router';

import RouteManager from 'utils/services/route-manager';

import Loader from 'components/Loader';
import PrivateRoute from 'components/PrivateRoute';

import App from 'modules/App';
import ContentWrap from 'modules/ContentWrap';
import Map from 'modules/dasboard-map';
import DriverProfile from 'modules/DriverProfile';
import DriversAndVehicles from 'modules/drivers-vehicles';
import DriversList from 'modules/drivers-vehicles/driver-list';
import DriverTrips from 'modules/drivers-vehicles/driver-trips';
import VehiclesList from 'modules/drivers-vehicles/vehicles-list';
// import GeoWrap from 'modules/geo';
// import GeoZones from 'modules/geo/zones/list';
import Home from 'modules/Home';
import Login from 'modules/Login';
import Savings from 'modules/Savings';
import Scoring from 'modules/Scoring';
import Settings from 'modules/Settings';
import Companies from 'modules/Settings/pages/companies';
import CompanyForm from 'modules/Settings/pages/company-form';
import SettingsDrivers from 'modules/Settings/pages/drivers';
import CompanyFinance from 'modules/Settings/pages/finance/company';
import CompanyWrapper from 'modules/Settings/pages/finance/company-wrapper';
import Finance from 'modules/Settings/pages/finance/list';
import CompanyTransactions from 'modules/Settings/pages/finance/transactions';
import VehiclesSpending from 'modules/Settings/pages/finance/vehicles-spending';
import FuelPrices from 'modules/Settings/pages/fuel';
import Language from 'modules/Settings/pages/language';
import SpeedManagement from 'modules/Settings/pages/speed-management';
import Teams from 'modules/Settings/pages/teams';
import UserForm from 'modules/Settings/pages/user-form';
import Users from 'modules/Settings/pages/users';
import VehicleModels from 'modules/Settings/pages/vehicle-models';
import SettingsVehicles from 'modules/Settings/pages/vehicles';
import Trip from 'modules/Trip';
import TripLive from 'modules/TripLive';
import TripsDay from 'modules/TripsDay';
import UnderConstruction from 'modules/UnderConstruction';
import VehicleForm from 'modules/VehicleForm';

// import ZoneForm from '../../geo/zones/form';

const routes: RouteObject[] = [
  {
    path: RouteManager.path('home'),
    Component: Home,
  },
  {
    path: RouteManager.path('login'),
    Component: Login,
  },
  {
    Component: () => (
      <PrivateRoute>
        <App />
      </PrivateRoute>
    ),
    children: [
      {
        path: RouteManager.path('dashboardMap'),
        Component: Map,
      },
      {
        path: RouteManager.path('dashboardMap.live', { isFullPath: true }),
        Component: TripLive,
      },
      // {
      //   Component: GeoWrap,
      //   children: [
      //     {
      //       path: RouteManager.path('settings.geo.zones', { isFullPath: true }),
      //       Component: GeoZones,
      //     },
      //     {
      //       path: RouteManager.path('settings.geo.zones.create', { isFullPath: true }),
      //       Component: ZoneForm,
      //     },
      //     {
      //       path: RouteManager.path('settings.geo.zones.edit', { isFullPath: true }),
      //       Component: ZoneForm,
      //     },
      //   ],
      // },
      {
        Component: ContentWrap,
        children: [
          {
            path: RouteManager.path('scoring'),
            Component: Scoring,
          },
          {
            path: RouteManager.path('saving'),
            Component: Savings,
          },
          {
            Component: DriversAndVehicles,
            children: [
              {
                path: RouteManager.path('drivers'),
                Component: DriversList,
              },
              {
                path: RouteManager.path('vehicles'),
                Component: VehiclesList,
              },
              {
                path: RouteManager.path('drivers.trips', { isFullPath: true }),
                Component: DriverTrips,
              },
            ],
          },
          {
            path: RouteManager.path('drivers.create', { isFullPath: true }),
            Component: DriverProfile,
          },
          {
            path: RouteManager.path('drivers.edit', { isFullPath: true }),
            Component: DriverProfile,
          },
          {
            path: RouteManager.path('drivers.trip', { isFullPath: true }),
            Component: () => {
              return (
                <Suspense fallback={<Loader width={150} lightMode />}>
                  <Trip />
                </Suspense>
              );
            },
          },
          {
            path: RouteManager.path('drivers.tripsByDay', { isFullPath: true }),
            Component: TripsDay,
          },
          {
            path: RouteManager.path('vehicles.create', { isFullPath: true }),
            Component: VehicleForm,
          },
          {
            path: RouteManager.path('vehicles.edit', { isFullPath: true }),
            Component: VehicleForm,
          },
          {
            path: RouteManager.path('settings'),
            Component: Settings,
          },
          {
            path: RouteManager.path('settings.drivers', { isFullPath: true }),
            Component: SettingsDrivers,
          },
          {
            path: RouteManager.path('settings.vehicles', { isFullPath: true }),
            Component: SettingsVehicles,
          },
          {
            path: RouteManager.path('settings.vehicleBrand', { isFullPath: true }),
            Component: VehicleModels,
          },
          {
            path: RouteManager.path('settings.fuelPrice', { isFullPath: true }),
            Component: FuelPrices,
          },
          {
            path: RouteManager.path('settings.speedManagement', { isFullPath: true }),
            Component: SpeedManagement,
          },
          {
            path: RouteManager.path('settings.language', { isFullPath: true }),
            Component: Language,
          },
          {
            path: RouteManager.path('settings.companies', { isFullPath: true }),
            Component: Companies,
          },
          {
            path: RouteManager.path('settings.companies.create', { isFullPath: true }),
            Component: CompanyForm,
          },
          {
            path: RouteManager.path('settings.companies.edit', { isFullPath: true }),
            Component: CompanyForm,
          },
          {
            path: RouteManager.path('settings.users', { isFullPath: true }),
            Component: Users,
          },
          {
            path: RouteManager.path('settings.users.create', { isFullPath: true }),
            Component: UserForm,
          },
          {
            path: RouteManager.path('settings.users.edit', { isFullPath: true }),
            Component: UserForm,
          },
          {
            path: RouteManager.path('settings.teams', { isFullPath: true }),
            Component: Teams,
          },
          {
            path: RouteManager.path('settings.payment', { isFullPath: true }),
            Component: Finance,
          },
          {
            Component: CompanyWrapper,
            children: [
              {
                path: RouteManager.path('settings.payment.company', { isFullPath: true }),
                Component: CompanyFinance,
              },
              {
                path: RouteManager.path('settings.payment.company.transaction', {
                  isFullPath: true,
                }),
                Component: CompanyTransactions,
              },
              {
                path: RouteManager.path('settings.payment.company.vehicles', { isFullPath: true }),
                Component: VehiclesSpending,
              },
              {
                path: RouteManager.path('settings.payment.company.vehicles.vehicle', {
                  isFullPath: true,
                }),
                Component: VehiclesSpending,
              },
            ],
          },
          {
            path: '*',
            Component: UnderConstruction,
          },
        ],
      },
    ],
  },
];

export default routes;
