import { alpha, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

interface IProps {
  technicalMessageBlockHeight: number;
}

const useStyles = makeStyles<Theme, IProps>((theme) => ({
  table: {
    height: ({ technicalMessageBlockHeight }) =>
      `calc(100vh - 360px - ${technicalMessageBlockHeight}px)`,
    margin: '40px 50px',
    maxWidth: 'calc(100% - 100px)',
  },

  row: {
    transition: 'all 0.3s ease',
    borderRadius: 10,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.08),
    },
  },

  headCell: {
    paddingBottom: '20px !important',

    '&:first-child': {
      paddingLeft: '15px !important',
    },

    '&:last-child': {
      paddingRight: '15px !important',
    },
  },

  cell: {
    fontSize: 18,
    fontWeight: 400,
    color: `${theme.palette.primary.dark} !important`,

    '&:first-child': {
      paddingLeft: '15px !important',
    },

    '&:last-child': {
      paddingRight: '15px !important',
    },
  },

  money: {
    fontSize: 20,
  },

  pdfWrap: {
    display: 'block',
    textDecoration: 'none',

    '&:has($invoiceWrap.isBlocked)': {
      pointerEvents: 'none',
    },
  },

  invoiceWrap: {
    width: 350,
    minHeight: 200,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    rowGap: 10,
    fontSize: 18,
    fontWeight: 400,
    color: `${theme.palette.primary.dark}`,
    textAlign: 'center',
  },

  loaderWrap: {
    position: 'relative',
    width: 150,
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
