import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrap: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default useStyles;
