import queryString from 'query-string';

import { ITrip } from 'interfaces/map/i-trip';
import { IResponseWrapper } from 'interfaces/server/response-wrapper';

import server from 'utils/server';

/**
 * Makes a request to the server to retrieve driver trips.
 *
 * @param {Object} data - The data object containing the driver ID and optional page number.
 * @param {string} data.id - The ID of the driver.
 * @param {number} [data.page] - The optional page number.
 * @param {Object} filters - The filters object containing the movementAtFrom and movementAtTill values.
 * @param {number} filters.movementAtFrom - The starting timestamp for filtering trips.
 * @param {number} filters.movementAtTill - The ending timestamp for filtering trips.
 * @returns {Promise<IResponseWrapper<ITrip[]>>} A promise that resolves to an array of driver trips.
 */
export const driverTripsReq = async (
  data: { id: string; page?: number },
  filters: { movementAtFrom: number; movementAtTill: number },
): Promise<IResponseWrapper<ITrip[]>> => {
  const params = {
    page: data.page ?? 0,
    size: 100,
  };
  const body = {
    id: data.id,
    movementAtFrom: filters.movementAtFrom,
    movementAtTill: filters.movementAtTill,
  };

  return server.services.beService
    .post<IResponseWrapper<ITrip[]>>(
      `drivers/${data.id}/trips?` + queryString.stringify(params),
      body,
    )
    .then((res) => res.data);
};
