import cx from 'classnames';
import React, { FC } from 'react';

import useStyles from './styles';

interface ICheckbox {
  value: boolean;
  onChange?: (value: boolean) => void;
  wrapClass?: string;
  isIndeterminate?: boolean;
  label?: string;
  disabled?: boolean;
  testId?: string;
}

type Props = ICheckbox;

const Checkbox: FC<Props> = ({
  value,
  wrapClass,
  label,
  testId,
  isIndeterminate = false,
  onChange = () => null,
  disabled = false,
}) => {
  const classes = useStyles();

  return (
    <label
      className={cx(classes.wrap, wrapClass, { disabled })}
      data-testid={testId}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={cx(classes.checkbox, { isChecked: value })}>
        <div className={cx(classes.checkedIcon, { isIndeterminate })} />
      </div>
      <div className={classes.label}>{label}</div>
      <input
        className={classes.hiddenInput}
        type='checkbox'
        onChange={(e) => onChange(e.target.checked)}
        checked={value}
      />
    </label>
  );
};

export default Checkbox;
