import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  settings: {
    display: 'flex',
    padding: '36px 38px 0 38px',
  },

  settingsAvatar: {
    position: 'relative',
    width: '120px',
    height: '120px',
  },

  dropzone: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'transparent',
    borderRadius: '50%',
    cursor: 'pointer',
  },

  active: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: palette.custom.bluePrimary,
    borderRadius: '50%',
    outline: `1px dashed ${theme.palette.primary.main}`,

    '& > svg': {
      width: '50px',
      fill: theme.palette.primary.main,
    },
  },

  avatar: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    borderRadius: '50%',
  },

  form: {
    width: '100%',
    maxWidth: '845px',
    marginLeft: '33px',
  },

  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 30,
    rowGap: 25,
  },

  fieldsWrap: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 25,
  },

  field: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.dark,
    fontSize: 25,
    marginRight: 25,

    '& div:last-child': {
      marginLeft: 10,
    },
  },

  profileInput: {
    width: '251px',
    marginRight: '30px',
    marginTop: '28px',
  },

  coins: {
    position: 'absolute',
    transform: 'translateX(5px)',
  },

  submit: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: '10px',
    marginTop: '28px',
  },

  useParent: {
    marginTop: 20,
  },
}));

export default useStyles;
