import { PENALTY_CARD } from 'interfaces/common/penalty-card';
import { IDriverData } from 'interfaces/driver/i-driver-data';
import { IPointCoordinates } from 'interfaces/map/point';
import { DEVICE_FUNCTION } from 'interfaces/vehicles/device-functions';
import { IVehicleData } from 'interfaces/vehicles/i-vehicle-data';

import server from 'utils/server';

type TCrashDriverData = Pick<
  IDriverData,
  | 'id'
  | 'active'
  | 'firstName'
  | 'lastName'
  | 'pin'
  | 'phonePrefix'
  | 'phoneNumber'
  | 'sosPhonePrefix'
  | 'sosPhoneNumber'
> & {
  bloodGroup: string;
  scoreBeforeAccident: number;
  diffScore: number;
  better: boolean;
  penaltyCards: PENALTY_CARD[];
  predictionBeforeAccident: null;
};

type TCrashVehicle = Pick<
  IVehicleData,
  'id' | 'imei' | 'plateNumber' | 'type' | 'brand' | 'model' | 'country'
> & {
  active: boolean;
  year: number;
  deviceFunctions: DEVICE_FUNCTION[];
  engineStatus: null;
};

interface ICrashDriverDetails {
  tripId: string;
  driver: TCrashDriverData;
  vehicle: TCrashVehicle;
  location: {
    startPoint: {
      address: string;
      gpsPoint: IPointCoordinates;
    };
    crashPoint: {
      address: string;
      gpsPoint: IPointCoordinates;
    };
  };
}

/**
 * Retrieves the information for a specific crash
 *
 * @param {string} crashId - The ID of the crash for which to retrieve the information
 * @returns {Promise<ICrashDriverDetails>} A promise that resolves to an object containing the crash details
 */
export const getCrashDriverInfoReq = async (crashId: string): Promise<ICrashDriverDetails> =>
  server.services.gpsService
    .get<ICrashDriverDetails>(`crashes/${crashId}/driver/details`)
    .then(({ data }) => data);
