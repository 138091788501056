import IconButton from '@mui/material/IconButton';
import cx from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BUTTON_VARIANTS } from 'interfaces/common/button-variants';

import { ReactComponent as ApproveIcon } from 'assets/images/approve.svg';
import { ReactComponent as CrossIcon } from 'assets/images/cross.svg';

import Button from 'components/form-v2/button';

import useStyles from './styles';

interface IModalActions {
  handleClose: () => void;
  handleSave: () => void;
  handleReset?: () => void;
  wrapClass?: string;
}

const ModalActions: FC<IModalActions> = ({ handleClose, handleSave, wrapClass, handleReset }) => {
  const classes = useStyles();
  const { t } = useTranslation('fields');

  return (
    <div className={cx(classes.controls, wrapClass, { hasReset: Boolean(handleReset) })}>
      {handleReset && (
        <Button
          type={'button'}
          variant={BUTTON_VARIANTS.OUTLINE}
          className={classes.reset}
          onClick={handleReset}
          testId='modal-reset-button'
        >
          {t('reset.label')}
        </Button>
      )}
      <div className={classes.icons}>
        <IconButton onClick={handleSave} data-testid='modal-save-button'>
          <ApproveIcon />
        </IconButton>
        <IconButton onClick={handleClose} data-testid='modal-close-button'>
          <CrossIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default ModalActions;
