import cx from 'classnames';
import React, { FC } from 'react';

import { PENALTY_CARD } from 'interfaces/common/penalty-card';

import useStyles from './styles';

interface IPenaltyCard {
  type: PENALTY_CARD;
}

const PenaltyCard: FC<IPenaltyCard> = ({ type }) => {
  const classes = useStyles();

  return <div className={cx(classes.card, type)} data-testid='penalty-card' />;
};

export default PenaltyCard;
