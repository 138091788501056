/**
 * Calculates the color of a circle based on the given score.
 *
 * @param {number} score - The score value to determine the color of the circle.
 * @returns {'green' | 'red' | 'yellow'} - The color of the circle based on the score.
 */
export const scoreCircle = (score: number): 'green' | 'red' | 'yellow' => {
  if (score >= 1) {
    return 'green';
  }
  if (score <= -31) {
    return 'red';
  }
  return 'yellow';
};
