import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  text: {
    fontSize: 16,
    fontWeight: 400,
    color: palette.custom.blue200,
  },
}));

export default useStyles;
