import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  section: {
    paddingBottom: '25px',
  },

  info: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',

    '& > div': {
      color: theme.palette.primary.main,
      fontSize: 20,
      lineHeight: '24px',
      fontWeight: 500,

      '&:nth-child(4)': {
        marginRight: '-20px',
      },
    },
  },

  logo: {
    fontSize: 24,
    lineHeight: '29px',
    color: theme.palette.primary.main,
  },

  nameSection: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    fontSize: 14,
    lineHeight: '17px',
    fontWeight: 400,
    maxWidth: '115px',
    minHeight: '50px',
    margin: '0 auto',
    marginTop: '22px',
    textAlign: 'start',
    color: theme.palette.primary.main,
    opacity: 0.3,
  },

  nameSectionOne: {
    marginLeft: '0px',
    justifyContent: 'start',
  },

  sectionContent: {
    display: 'flex',
    alignItems: 'center',
    height: 'calc(100% - 50px)',
  },

  driver: {
    display: 'flex',
  },

  driverInfo: {
    display: 'flex',
    flexDirection: 'column',
    margin: '5px 0 0 21.72px',

    '& span': {
      color: theme.palette.primary.main,
      fontSize: 20,
      lineHeight: '24px',
      fontWeight: 500,
    },

    '& > span': {
      fontSize: 14,
      lineHeight: '17px',
      opacity: 0.5,
    },
  },

  driverName: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 110,

    '& span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },

  delta: {
    textAlign: 'center',

    '@global': {
      '.delta-component': {
        marginTop: '20px',
        color: theme.palette.primary.main,
      },

      '.delta-component__value': {
        fontSize: 31.9091,
        lineHeight: '38px',
        fontWeight: 500,
        letterSpacing: '-0.910319px',
      },

      '.delta-component__item': {
        fontSize: 12,
        lineHeight: '14.32px',
        fontWeight: 400,
        letterSpacing: '-0.63px',
      },

      '.delta-component__item-value': {
        fontSize: 12,
        lineHeight: '14.32px',
        fontWeight: 400,
        letterSpacing: '-0.63px',
      },
    },
  },

  cards: {
    display: 'flex',
  },

  card: {
    width: '24px',
    height: '35px',
    marginRight: '8px',
    borderRadius: '2.13333px',

    '&:last-child': {
      marginRight: 0,
    },

    '&--RED': {
      backgroundColor: palette.custom.redSecondary,
    },

    '&--YELLOW': {
      backgroundColor: palette.custom.yellow,
    },
  },

  counterPercent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    fontSize: 32,
    lineHeight: '38px',
    letterSpacing: '-1px',
    fontWeight: 500,
    color: palette.custom.redSecondary,
  },
  percent: {
    height: '12px',
    margin: '6px 0 0 3px',
    fontSize: 16,
    lineHeight: '13px',
    letterSpacing: '-0.54px',
    fontWeight: 500,
  },

  address: {
    display: 'flex',
    color: theme.palette.primary.main,
    margin: '0 8px',
    marginTop: '20px',
    cursor: 'pointer',
  },

  point: {
    display: 'flex',
    alignItems: 'start',
    margin: '2px 5px 0 0',
  },

  name: {
    fontSize: '17.3672px',
    fontWeight: '500',
    lineHeight: '21px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '189px',
    color: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    letterSpacing: '-1.09px',
  },

  time: {
    fontSize: '16.2818px',
    fontWeight: 300,
    lineHeight: '19px',
    color: theme.palette.primary.dark,
  },
}));

export default useStyles;
