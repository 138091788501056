import * as yup from 'yup';

export interface IPaymentFormData {
  amount: number;
  paymentDetails: string;
  transactionId: string;
}

export const formSchema = yup.object().shape({
  amount: yup
    .number()
    .required()
    .test(
      'is-decimal',
      'number.invalidDecimal.label',
      (value) =>
        value !== undefined &&
        (value.toString().indexOf('.') < 0 || value.toString().split('.')[1].length <= 2),
    ),
  paymentDetails: yup.string().required(),
  transactionId: yup.string(),
});
