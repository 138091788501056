import React, { useEffect, useMemo, useState } from 'react';
import ApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { IUserData } from 'interfaces/user/user-data';

import userConfigAtom from 'recoil/user-config';
import userDataAtom from 'recoil/userData';

import {
  getMainChartData,
  IChartData,
} from 'requests/be-service/saving-controller/get-graph-saving';

import Loader from 'components/Loader';

import createOptions from './options';
import useStyles from './styles';

const Chart = () => {
  const classes = useStyles();
  const { t } = useTranslation('saving.page');
  const [isLoading, setIsLoading] = useState(false);
  const { currencyIcon } = useRecoilValue(userConfigAtom);
  const { language } = useRecoilValue(userDataAtom) as IUserData;
  const [data, setData] = useState<IChartData | null>(null);

  useEffect(() => {
    const request = async () => {
      try {
        setIsLoading(true);
        const res = await getMainChartData(language);
        setData(res);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    request();
  }, [language]);

  const chartConfig = useMemo(() => {
    if (!data) {
      return;
    }
    return {
      series: [
        {
          data: data.total ?? [],
        },
      ],
      options: createOptions(data, currencyIcon),
    };
  }, [currencyIcon, data]);

  if (isLoading) {
    return (
      <div className={classes.wrap}>
        <Loader width={100} lightMode isBlock />
      </div>
    );
  }

  if (!data) {
    return (
      <div className={classes.wrap}>
        <div className={classes.emptyWrap}>{t('no.data.label')}</div>
      </div>
    );
  }

  return (
    <div className={classes.wrap}>
      <ApexChart
        options={chartConfig!.options}
        series={chartConfig!.series}
        height={315}
        data-testid='chart'
      />
    </div>
  );
};

export default Chart;
