import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ModalActions from 'components/modal-actions';

import useStyles from './styles';

interface IUnsavedModal {
  confirmNavigation: () => void;
  cancelNavigation: () => void;
}

const UnsavedModal: FC<IUnsavedModal> = ({ cancelNavigation, confirmNavigation }) => {
  const classes = useStyles();
  const { t } = useTranslation('fields');

  return (
    <>
      <div className={classes.text}>{t('unsaved.changes.label')}</div>
      <ModalActions handleClose={cancelNavigation} handleSave={confirmNavigation} />
    </>
  );
};

UnsavedModal.defaultProps = {};

export default UnsavedModal;
