import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useRecoilValueLoadable } from 'recoil';

import tripsDaySelector from './tripsDaySelector';

const useTripsDay = (id, date, setTripPoints, setDriverShortData, setFetching, setError) => {
  const { enqueueSnackbar } = useSnackbar();
  const { state, contents } = useRecoilValueLoadable(tripsDaySelector({ id, date }));

  /**
   * Get driver trip: only if isDriverTripsMode === true
   */
  useEffect(() => {
    const { driver, result, errorMessage } = contents ?? {};

    if (!driver && !result) {
      return;
    }

    if (errorMessage) {
      enqueueSnackbar('Something Went Wrong', { variant: 'error' });
      setError(errorMessage);
    }

    setDriverShortData(driver);
    setTripPoints(result);
  }, [contents, enqueueSnackbar, setDriverShortData, setError, setTripPoints]);

  useEffect(() => {
    if (state === 'loading') {
      setFetching(true);
    } else if (state !== 'loading') {
      setFetching(false);
    }
  }, [setFetching, state]);

  return useMemo(() => ({}), []);
};

export default useTripsDay;
