// TODO: fixed
/* eslint-disable */
// @ts-nocheck
import { yupResolver } from '@hookform/resolvers/yup';
import { endOfDay } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';

import { USER_ROLES } from 'interfaces/user/roles';
import { IUserData } from 'interfaces/user/user-data';

import { ROLES_OBJECT } from 'constants/user';

import settingsDriverFilterAtom from 'recoil/settings/drivers/filters';
import { initValues } from 'recoil/settings/drivers/filters/atom';
import settingDriversShowFilterAtom from 'recoil/settings/drivers/show-filter';
import subcompaniesSelectListAtom from 'recoil/subcompanies/select-list';
import userDataAtom from 'recoil/userData';

import { getCompanyUsersReq } from 'requests/be-service/company-controller/get-company-users';
import { getSystemUsersReq } from 'requests/be-service/system-user-controller/get-all-users';
import { teamsGetReq } from 'requests/be-service/teams-controller/get-all-teams';

import DatePicker from 'components/form-v2/date-picker';
import Checkbox from 'components/form-v2/checkbox-with-controller';
import Select from 'components/form-v2/select';
import ModalActions from 'components/modal-actions';

import { formSchema, TSettingsDriversFilters } from './form-schema';
import useStyles from './styles';

const Filter = () => {
  const classes = useStyles();
  const { t } = useTranslation('fields');

  const setShowFilter = useSetRecoilState(settingDriversShowFilterAtom);
  const { contents: subcompaniesSelectList } = useRecoilValueLoadable(subcompaniesSelectListAtom);
  const [managers, setManagers] = useState(null);
  const [managersListPage, setManagersListPage] = useState(0);
  const [managersListTotalPages, setManagersListTotalPages] = useState(0);
  const [teams, setTeams] = useState(null);
  const [teamsPage, setTeamsPage] = useState(0);
  const [teamsTotalPages, setTeamsTotalPages] = useState(0);
  const [filtersData, setFiltersData] = useRecoilState(settingsDriverFilterAtom);
  const userData = useRecoilValue(userDataAtom) as IUserData;

  const formMethods = useForm<TSettingsDriversFilters>({
    defaultValues: {
      ...filtersData,
      companyIds: filtersData.companyIds?.[0] ?? null,
      userIds: filtersData.userIds?.[0] ?? null,
      teamIds: filtersData.teamIds?.[0] ?? null,
      includeDisabled: filtersData.includeDisabled ?? false,
    },
    resolver: yupResolver(formSchema()),
  });

  /**
   * Reset filters by user role
   * @type {(function(): void)|*}
   */
  const handleResetFilters = useCallback(() => {
    if (
      ![
        ROLES_OBJECT.TECH_ADMIN,
        ROLES_OBJECT.ADMIN,
        ROLES_OBJECT.OWNER,
        ROLES_OBJECT.OPERATOR,
      ].includes(userData.role)
    ) {
      formMethods.reset({
        ...initValues,
        companyIds: userData.companyId,
        userIds: userData.id,
      });
      return;
    }
    formMethods.reset(initValues);
  }, [formMethods, userData.companyId, userData.id, userData.role]);

  /**
   * Get managers for selected company
   */
  const getManagers = useCallback(async (companyId: string, page: number) => {
    try {
      let response;

      if (companyId) {
        response = await getCompanyUsersReq(companyId);
      } else {
        response = await getSystemUsersReq(page, { enabled: true });
      }

      const formattedResponse = (response.content ?? response)?.map((item) => {
        return {
          value: item.id,
          companyId: item.companyId,
          label: `${item.firstName} ${item.lastName}`,
        };
      });

      setManagersListTotalPages(response.totalPages ?? 0);
      setManagersListPage(response.number ?? 0);
      if (!response.number || response.number === 0) {
        setManagers(formattedResponse);
      } else {
        setManagers((prevState) => [...prevState, ...formattedResponse]);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  /**
   * Getting a list of managers with data loading when scrolling
   */
  useEffect(() => {
    getManagers(formMethods.watch('companyIds') ?? null, managersListPage);
  }, [formMethods, getManagers, managersListPage]);

  /**
   * Get teams
   */
  const getTeams = useCallback(async (page = 0) => {
    try {
      const response = await teamsGetReq(page);

      const formattedResponse = response.content.map((team) => {
        return {
          value: team.teamId,
          label: team.name,
        };
      });

      if (response.number === 0) {
        setTeams(formattedResponse);
      } else {
        setTeams((prev) => [...prev, ...formattedResponse]);
      }
      setTeamsPage(response.number);
      setTeamsTotalPages(response.totalPages);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    getTeams(teamsPage);
  }, [getTeams, teamsPage]);

  const onSubmitForm = (data) => {
    setFiltersData((prevState) => ({
      ...prevState,
      ...data,
      companyIds: data.companyIds ? [data.companyIds] : null,
      userIds: data.userIds ? [data.userIds] : null,
      teamIds: data.teamIds ? [data.teamIds] : null,
      createdAtFrom: data.createdAtFrom ? new Date(data.createdAtFrom).getTime() : null,
      createdAtTill: data.createdAtTill ? endOfDay(new Date(data.createdAtTill)).getTime() : null,
    }));
    setShowFilter(false);
  };

  return (
    <FormProvider {...formMethods}>
      <div className={classes.wrap} data-testid='drivers-filters'>
        <Select
          items={Array.isArray(subcompaniesSelectList) ? subcompaniesSelectList : []}
          name={'companyIds'}
          title={t('company.label')}
          disabled={
            ![
              ROLES_OBJECT.TECH_ADMIN,
              ROLES_OBJECT.ADMIN,
              ROLES_OBJECT.OWNER,
              ROLES_OBJECT.OPERATOR,
            ].includes(userData.role)
          }
          additionalOnChange={(val) => {
            formMethods.setValue('userIds', null);
            getManagers(val.target.value, null);
          }}
        />
        <Select
          items={managers ?? []}
          name='userIds'
          title={t('manager.label')}
          disabled={
            ![
              ROLES_OBJECT.TECH_ADMIN,
              ROLES_OBJECT.ADMIN,
              ROLES_OBJECT.OWNER,
              ROLES_OBJECT.OPERATOR,
            ].includes(userData.role)
          }
          additionalOnChange={(val) => {
            const managerCompanyId = managers.find((manager) => manager.value === val.target.value)
              ?.companyId;
            if (!formMethods.watch('companyIds')) {
              formMethods.setValue('companyIds', managerCompanyId);
              getManagers(managerCompanyId, null);
            }
          }}
          onScroll={(e) => {
            const target = e.target;
            const isBottom = target.scrollHeight - target.scrollTop - target.offsetHeight < 1;
            if (isBottom) {
              setManagersListPage((prevState) => {
                if (prevState < managersListTotalPages - 1) {
                  return prevState + 1;
                }
                return prevState;
              });
            }
          }}
        />

        <Select
          items={teams ?? []}
          name='teamIds'
          title={t('teams.label')}
          onScroll={(e) => {
            const target = e.target;
            const isBottom = target.scrollHeight - target.scrollTop - target.offsetHeight < 1;
            if (isBottom) {
              setTeamsPage((prevState) => {
                if (prevState < teamsTotalPages - 1) {
                  return prevState + 1;
                }
                return prevState;
              });
            }
          }}
        />

        <div className={classes.row}>
          <DatePicker
            name='createdAtFrom'
            title={`${t('creation.time.label')} (${t('from.filter.label')})`}
          />
          <DatePicker
            name='createdAtTill'
            title={`${t('creation.time.label')} (${t('to.filter.label')})`}
          />
        </div>

        {userData.role === USER_ROLES.TECH_ADMIN && (
          <Checkbox name={'includeDisabled'} label={t('includeDisabled.label')} />
        )}

        <ModalActions
          handleClose={() => setShowFilter(false)}
          handleSave={formMethods.handleSubmit(onSubmitForm)}
          handleReset={handleResetFilters}
        />
      </div>
    </FormProvider>
  );
};

export default Filter;
