import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    cursor: 'pointer',
    display: 'grid',
    gridTemplateAreas: `
    "icon plateNumber"
    ". vehicle"
    `,
    alignItems: 'center',
    gap: 8,
    backgroundColor: palette.custom.hoverBlue,
    borderRadius: 10,
    padding: 10,
    border: '2px solid transparent',

    '&.isShowSensors': {
      border: `2px solid ${palette.custom.goldSecondary}`,
      backgroundColor: alpha(palette.custom.goldSecondary, 0.15),
    },

    '&.hasAlarm': {
      border: `2px solid ${palette.custom.red900}`,
      backgroundColor: alpha(palette.custom.red900, 0.15),
    },
  },

  plateNumber: {
    gridArea: 'plateNumber',
  },

  vehicle: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.primary.dark,
    lineHeight: '14px',
    gridArea: 'vehicle',
    maxWidth: 105,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  icon: {
    '&.hasAlarm': {
      '& path': {
        fill: palette.custom.red900,
      },
    },
  },
}));

export default useStyles;
