import cx from 'classnames';
import queryString from 'query-string';
import React, { FC, FormEvent, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import SimpleBar from 'simplebar-react';

import { TNavigationSearchItem } from 'interfaces/navigation/searched-item';
import { SEARCHED_TYPES } from 'interfaces/navigation/searched-types';

import { ReactComponent as SearchIcon } from 'assets/images/search.svg';

import RouteManager from 'utils/services/route-manager';

import navigationOptionsAtom from 'recoil/navigation';

import { getSearchedDataReq } from 'requests/be-service/company-controller/search-companies';

import SearchedItem from '../searched-item';

import useStyles from './styles';

interface ISearchModal {
  anchorCoordinates: DOMRect;
}

const SearchModal: FC<ISearchModal> = ({ anchorCoordinates }) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');
  const [searchedData, setSearchedData] = useState<TNavigationSearchItem[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [, setNavigationState] = useRecoilState(navigationOptionsAtom);

  const getSearchedData = useCallback(async (value: string) => {
    try {
      setIsLoading(true);
      const response = await getSearchedDataReq(value);
      setSearchedData(response);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (searchValue.length < 4) {
      return;
    }

    getSearchedData(searchValue);
  }, [getSearchedData, searchValue]);

  const handleCloseSearchInput = (e: MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (!target?.classList.contains('company-search-wrap')) {
      return;
    }

    setNavigationState((prevState) => {
      return {
        ...prevState,
        isOpenSearch: false,
      };
    });
  };

  const handleInput = (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setSearchedData(null);
    setSearchValue(target.value);
  };

  /**
   * Switching to a sub company with the opening of the page depending on the type of search result
   */
  const handleSearchedItem = (item: TNavigationSearchItem) => {
    let URL = `${window.location.origin}`;
    const params = {
      subcompanyId: item.companyId,
      subcompanyName: item.companyName,
      subcompanyLogoUrl: item.companyIconUrl,
      subcompanyCurrency: item.localizationConfig.currency,
      subcompanyCountry: item.localizationConfig.country,
    };

    if (item.type === SEARCHED_TYPES.COMPANY) {
      URL += RouteManager.path('scoring', { hasLeadingSlash: true });
    }

    if (item.type === SEARCHED_TYPES.DRIVER) {
      URL += RouteManager.makeURL('drivers.trips', { id: item.driverId });
    }

    if ([SEARCHED_TYPES.IMEI, SEARCHED_TYPES.PLATENUMBER].includes(item.type)) {
      if (item.driverId) {
        URL += RouteManager.makeURL('drivers.trips', { id: item.driverId });
      } else {
        URL += RouteManager.makeURL('vehicles.edit', { id: item.vehicleId! });
      }
    }

    const path = `${URL}?${queryString.stringify(params)}`;
    window.open(path, '_blank');
  };

  return (
    <div
      className={cx(classes.wrap, 'company-search-wrap')}
      onClick={handleCloseSearchInput}
      data-testid='search-modal-root'
    >
      <div
        className={classes.content}
        style={{ top: anchorCoordinates.top, left: anchorCoordinates.left + 15 }}
        data-testid='search-modal-content'
      >
        <div className={classes.inputWrap}>
          <div className={classes.icon}>
            <SearchIcon />
          </div>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              getSearchedData(searchValue);
            }}
            data-testid='search-form'
          >
            <input
              className={classes.input}
              value={searchValue}
              onInput={handleInput}
              type='text'
              autoFocus
              data-testid='search-input'
            />
          </form>
        </div>

        <SimpleBar className={classes.dataWrap} data-testid='search-data-wrap'>
          {isLoading && <div className={classes.noData}>Searching...</div>}

          {(!searchedData || searchedData.length === 0) && !isLoading && (
            <div className={classes.noData}>No result</div>
          )}

          {!isLoading &&
            searchedData?.map((item) => {
              return (
                <SearchedItem
                  key={`${item.companyId}-${item.type}-${item.driverId}-${item.vehicleId}`}
                  data={item}
                  searchValue={searchValue}
                  onClick={() => handleSearchedItem(item)}
                />
              );
            })}
        </SimpleBar>
      </div>
    </div>
  );
};

export default SearchModal;
