import cx from 'classnames';
import React, { FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';

import Input from 'components/form-v2/input';

import useStyles from './styles';

interface ISearch {
  onSubmit: (data: { query: string }) => void;
  onCancel: () => void;
  wrapClass?: string;
  searchValue?: string;
}

const Search: FC<ISearch> = ({ onSubmit, onCancel, wrapClass, searchValue = '' }) => {
  const classes = useStyles();
  const { t } = useTranslation('fields');

  const formMethods = useForm({
    defaultValues: {
      query: '',
    },
  });

  useEffect(() => {
    formMethods.reset({ query: searchValue });
  }, [formMethods, searchValue]);

  const handleCancel = useCallback(() => {
    onCancel();
    formMethods.reset();
  }, [onCancel, formMethods]);

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit(onSubmit)}
        className={cx(classes.searchWrap, wrapClass)}
        data-testid='search-main'
      >
        <Input
          title={''}
          name='query'
          placeholder={t('search.input.label')}
          endAdornment={
            <span className={classes.inputBtns}>
              <SearchIcon
                className={classes.searchIcon}
                onClick={formMethods.handleSubmit(onSubmit)}
                data-testid='search-icon'
              />
              <CloseIcon
                className={classes.closeIcon}
                onClick={handleCancel}
                data-testid='close-icon'
              />
            </span>
          }
        />
      </form>
    </FormProvider>
  );
};

export default Search;
