export const VEHICLE_TYPES_SELECT = [
  { label: 'truck.label', value: 'TRUCK' },
  { label: 'sedan.label', value: 'SEDAN' },
  { label: 'motorcycle.label', value: 'MOTORCYCLE' },
  { label: 'tractor.label', value: 'TRACTOR' },
  { label: 'bus.label', value: 'BUS' },
];

export const COUNTRY_TYPES = [
  { id: '01', label: 'AZ', name: 'AZ', value: 'AZ' },
  { id: '02', label: 'UK', name: 'UK', value: 'UK' },
  { id: '02', label: 'US', name: 'US', value: 'US' },
];

export const DRIVE_TYPES = [
  { label: '4x4', value: 'WD4' },
  { label: '6x4', value: 'WD6' },
  { label: 'FWD', value: 'FWD' },
  { label: 'RWD', value: 'RWD' },
];

export const FUEL_TYPES_SELECT = [
  { label: 'gasoline.label', value: 'GASOLINE' },
  { label: 'diesel.label', value: 'DIESEL' },
  { label: 'cng.label', value: 'CNG' },
  { label: 'lpg.label', value: 'LPG' },
];

export const GASOLINE_TYPES = [
  { label: '92', value: 'AI_92' },
  { label: '95', value: 'AI_95' },
  { label: '98', value: 'AI_98' },
];

export const BRAKE_SYSTEM_TYPES = [
  { label: 'air.label', value: 'Air' },
  { label: 'hydraulic.label', value: 'Hydraulic' },
];

export const COLOR_TYPES = {
  en: [
    { label: 'White', value: 'WHITE' },
    { label: 'Black', value: 'BLACK' },
    { label: 'Red', value: 'RED' },
    { label: 'Green', value: 'GREEN' },
    { label: 'Grey', value: 'GREY' },
    { label: 'Brown', value: 'BROWN' },
    { label: 'Blue', value: 'BLUE' },
    { label: 'Orange', value: 'ORANGE' },
    { label: 'Silver', value: 'SILVER' },
    { label: 'Beige', value: 'BEIGE' },
    { label: 'Yellow', value: 'YELLOW' },
    { label: 'Purple', value: 'PURPLE' },
    { label: 'Gold', value: 'GOLD' },
  ],
  az: [
    { label: 'Ağ', value: 'WHITE' },
    { label: 'Qara', value: 'BLACK' },
    { label: 'Qırmızı', value: 'RED' },
    { label: 'Yaşıl', value: 'GREEN' },
    { label: 'Boz', value: 'GREY' },
    { label: 'Qəhvəyi', value: 'BROWN' },
    { label: 'Göy', value: 'BLUE' },
    { label: 'Narıncı', value: 'ORANGE' },
    { label: 'Gümüş', value: 'SILVER' },
    { label: 'Bej', value: 'BEIGE' },
    { label: 'Sarı', value: 'YELLOW' },
    { label: 'Bənövşəyi', value: 'PURPLE' },
    { label: 'Qızıl', value: 'GOLD' },
  ],
};

export const OWNERSHIP_TYPES = [
  { label: 'OWNED', value: 'OWNED' },
  { label: 'RENTED', value: 'RENTED' },
  { label: 'LEASED', value: 'LEASED' },
];

export const DEVICE_TYPE_ADDITIONAL = [
  { label: 'iButton', value: 'iButton' },
  { label: 'DuoCamera', value: 'DuoCamera' },
  { label: 'Start/Stop', value: 'Start/Stop' },
];

/**
 * TODO: need remove and change to ACTIVITY_STATUSES
 * @type {{IDLING: string, ACTIVE: string, INACTIVE: string}}
 */
export const VEHICLES_ACTIVE_STATUSES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  IDLING: 'IDLING',
};
