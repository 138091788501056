import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    marginRight: 50,
    marginLeft: 50,
    position: 'relative',
  },

  backBtn: {
    position: 'absolute',
    width: 40,
    height: 40,
    left: -40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    '& svg': {
      width: 12,

      '& *': {
        stroke: palette.custom.blue200,
      },
    },
  },

  calendar: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 5,
    marginRight: 50,
  },

  dateBox: {
    width: 100,
    height: 60,
    borderRadius: 10,
    backgroundColor: alpha(theme.palette.primary.dark, 0.08),
    border: '2px solid transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    cursor: 'pointer',

    '&.isSelected': {
      backgroundColor: alpha(palette.custom.goldSecondary, 0.15),
      border: `2px solid ${palette.custom.goldSecondary}`,
    },
  },

  tools: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  dateTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: palette.custom.blue200,
  },

  dateSubtitle: {
    fontSize: 12,
    fontWeight: 400,
    color: palette.custom.blue200,
    opacity: 0.4,
  },

  search: {
    width: 385,
    marginRight: 50,
  },

  btnsPanel: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 20,
  },

  btn: {
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    '& svg': {
      width: '24px !important',

      '& *': {
        fill: theme.palette.primary.dark,
      },
    },
  },

  badge: {
    top: '-5px',
    right: '-5px',
  },
}));

export default useStyles;
