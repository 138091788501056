import queryString from 'query-string';

import { IResponseWrapper } from 'interfaces/server/response-wrapper';
import { IUser } from 'interfaces/user/user-data';

import server from 'utils/server';

import { ISettingsUsersFilter } from 'recoil/settings/settingsUsersFilter/atom';

import { TCompanyUser } from '../../../interfaces/user/company-user';

export type TSystemUser = Pick<
  IUser,
  | 'companyId'
  | 'companyName'
  | 'enabled'
  | 'firstName'
  | 'id'
  | 'language'
  | 'lastName'
  | 'measurement'
  | 'photoUrl'
  | 'role'
>;

/**
 * Retrieves system users from the server based on specified criteria.
 *
 * @param {number} page - The page number to retrieve.
 * @param {ISettingsUsersFilter} filters - Filters to apply to the query.
 * @param {number} [size=15] - The number of results to fetch per page. Defaults to 15.
 * @returns {Promise<IResponseWrapper<TCompanyUser[]>>} - A promise that resolves to the retrieved system user data.
 */
export const getSystemUsersReq = async (
  page: number,
  filters: ISettingsUsersFilter,
  size: number = 15,
): Promise<IResponseWrapper<TCompanyUser[]>> => {
  const params = {
    page: page,
    size: size,
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
  };

  const body = {
    email: null,
    enabled: null,
    query: null,
    userName: null,
    ...filters,
  };

  return server.services.beService
    .post<IResponseWrapper<TCompanyUser[]>>('system-users?' + queryString.stringify(params), body)
    .then((res) => res.data);
};
