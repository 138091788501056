import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    position: 'relative',
  },

  root: {
    marginBottom: 0,
    zIndex: 1,
  },

  rail: {
    backgroundColor: 'transparent',
    height: 6,
    border: 'none',
    borderRadius: 100,
  },

  track: {
    backgroundColor: 'transparent',
    height: 6,
    border: 'none',
    borderRadius: 100,
  },

  mark: {
    width: 1,
    height: 4,
    backgroundColor: palette.custom.blue700,
    transform: 'translateY(-9px)', // height of track + 5px
  },

  markLabel: {
    fontSize: 14,
    fontWeight: 500,
    color: palette.custom.blue700,
    top: -13,
  },

  thumb: {
    width: 20,
    height: 12,
    border: `1.5px solid ${theme.palette.primary.light}`,
    borderRadius: 100,
    backgroundColor: palette.custom.red900,

    '&:hover': {
      boxShadow: 'none',
    },

    '&.Mui-active': {
      boxShadow: 'none',
    },

    '&.normal': {
      backgroundColor: palette.custom.green400,
    },
  },

  valueLabel: {
    backgroundColor: 'transparent',
    fontSize: 16,
    fontWeight: 500,
    color: palette.custom.blue200,
  },

  line: {
    borderRadius: 100,
    backgroundColor: palette.custom.blue800,
    width: '100%',
    height: 6,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: 16,

    '& span': {
      height: '100%',

      '&:first-child': {
        borderRadius: '3px 0 0 3px',
        width: '20%',
      },

      '&:nth-child(2)': {
        flex: 1,
      },

      '&:last-child': {
        width: '20%',
        borderRadius: '0 3px 3px 0',
      },
    },

    '&.low span:nth-child(1)': {
      backgroundColor: palette.custom.red900,
    },

    '&.normal span:nth-child(2)': {
      backgroundColor: palette.custom.green400,
    },

    '&.high span:nth-child(3)': {
      backgroundColor: palette.custom.red900,
    },
  },
}));

export default useStyles;
