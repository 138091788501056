import server from 'utils/server';

export interface ICrashManagementData {
  callAmbulance: boolean;
  callDriver: boolean;
  callSos: boolean;
  cargoStatus: null | string;
  crashId: null | string;
  driverHealth: null | string;
  id: null | string;
  notes: null | string;
  notificationAlreadySent: boolean;
  passengerHealth: null | string;
  validated: boolean;
}

/**
 * Retrieves crash management data for a given crash ID.
 *
 * @async
 * @param {string} crashId - The ID of the crash.
 * @returns {Promise<ICrashManagementData>} - A promise that resolves with the management data for the crash.
 */
export const getCrashManagementDataReq = async (crashId: string): Promise<ICrashManagementData> =>
  server.services.gpsService
    .get<ICrashManagementData>(`crashes/${crashId}/management-data`)
    .then((res) => res.data);
