import { atom, selector } from 'recoil';

const settingPaymentSubCompaniesListPaginationAtom = atom<number>({
  key: 'settingPaymentSubCompaniesListPaginationAtom',
  default: 0,
});

const settingPaymentSubCompaniesListPaginationSelector = selector<number>({
  key: 'settingPaymentSubCompaniesListPaginationSelector',
  get: ({ get }) => {
    return get(settingPaymentSubCompaniesListPaginationAtom);
  },
  set: ({ set }, newValue) => {
    set(settingPaymentSubCompaniesListPaginationAtom, newValue);
  },
});

export default settingPaymentSubCompaniesListPaginationSelector;
