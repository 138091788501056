import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginRight: '12px',
  },

  button: {
    height: 'fit-content',
    padding: '6px 6px 6px 20px',
    color: theme.palette.primary.main,
    fontSize: '17.5px',
    lineHeight: '21px',
    fontWeight: 400,
  },

  buttonIcon: {
    display: 'block',
    marginLeft: '8px',
  },

  menuPaper: {
    background: palette.custom.lightBlue,
  },

  menuList: {
    padding: 0,
  },

  menuItem: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '6px 30px 6px 20px',
    color: theme.palette.primary.main,
    fontFamily: 'SFPro',
    fontSize: '17.5px',
    lineHeight: '21px',
    fontWeight: 400,
    opacity: 0.5,
  },

  menuItemIcon: {
    position: 'absolute',
    right: '6px',
    display: 'block',
  },

  menuItemActive: {
    opacity: 1,
  },
}));

export default useStyles;
