import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import driversVehiclesShowReportFilterAtom from 'recoil/drivers-vehicles/show-report-filters';

import { driverReportReq } from 'requests/report-service/driver-reports';

import Checkbox from 'components/form-v2/checkbox';
import DateTimePicker from 'components/form-v2/date-time-picker';
import Loader from 'components/Loader';
import ModalActions from 'components/modal-actions';

import { formSchema, TReportFilters } from './form-schema';
import useStyles from './styles';

interface IProps {
  driverId?: string;
}

const ReportFilter: FC<IProps> = ({ driverId }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('fields');

  const setShowReportFilter = useSetRecoilState(driversVehiclesShowReportFilterAtom);
  const [isLoading, setIsLoading] = useState(false);

  const formMethods = useForm<TReportFilters>({
    resolver: yupResolver(formSchema()),
  });

  const handleReportReq = useCallback(
    async (values: TReportFilters) => {
      setIsLoading(true);
      const startDate = values.startAt.toISOString();
      const finishDate = values.finishAt.toISOString();

      const formatValues = {
        ...values,
        startAt: startDate,
        finishAt: finishDate,
      };

      try {
        await driverReportReq({ formatValues, id: driverId });
        enqueueSnackbar('Request has been sent', { variant: 'success' });
      } catch (e) {
        enqueueSnackbar('Save Report Error', { variant: 'error' });
      } finally {
        setShowReportFilter(false);
        setIsLoading(false);
      }
    },
    [driverId, enqueueSnackbar, setShowReportFilter],
  );

  return (
    <div data-testid='report-filters-wrap'>
      {isLoading && <Loader width={150} lightMode preventClick />}
      <FormProvider {...formMethods}>
        <form className={classes.form}>
          <DateTimePicker name='startAt' title={t('from.filter.label')} />

          <DateTimePicker name='finishAt' title={t('to.filter.label')} />

          {!driverId && (
            <div className={classes.checkboxWrap}>
              <Checkbox
                value={formMethods.watch('isSplitByDays') ?? false}
                onChange={(value) => formMethods.setValue('isSplitByDays', value)}
                testId='checkbox-isSplitByDays'
              />
              <div>{t('split.report.label')}</div>
            </div>
          )}
        </form>

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
          <ModalActions
            handleClose={() => setShowReportFilter(false)}
            handleSave={formMethods.handleSubmit(handleReportReq)}
          />
        </div>
      </FormProvider>
    </div>
  );
};

export default ReportFilter;
