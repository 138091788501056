import React from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import SimpleBar from 'simplebar-react';

import RouteManager from 'utils/services/route-manager';

import navigationOptionsAtom from 'recoil/navigation';
import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';

import Content from 'components/Content';

import useStyles from './styles';

const ContentWrap = () => {
  const technicalMessageBlockHeight = useRecoilValue(technicalMessageBlockHeightSelector);
  const classes = useStyles({ technicalMessageBlockHeight });
  const navigate = useNavigate();

  const [navigationState, setNavigationState] = useRecoilState(navigationOptionsAtom);

  const handleCloseMenu = () =>
    setNavigationState({
      isOpenNav: false,
      isOpenSearch: false,
      isOpenCompanyList: false,
    });

  const handleOpenDashboardMap = () => {
    handleCloseMenu();
    navigate(RouteManager.path('dashboardMap'));
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.map} onClick={handleOpenDashboardMap}>
        <iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d36853.94185841179!2d49.84291251228544!3d40.402700754206535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d6bd6211cf9%3A0x343f6b5e7ae56c6b!2z0JHQsNC60YMsINCQ0LfQtdGA0LHQsNC50LTQttCw0L0!5e0!3m2!1sru!2sby!4v1630052553033!5m2!1sru!2sby' />
      </div>

      <Content isOpenMenu={navigationState.isOpenNav}>
        <SimpleBar className={classes.scrollbar}>
          <div className={classes.content} onClick={handleCloseMenu}>
            <Outlet />
          </div>
        </SimpleBar>
      </Content>
    </div>
  );
};

export default ContentWrap;
