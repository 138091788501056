import cx from 'classnames';
import React, { FC } from 'react';

import useStyles from './styles';

interface ICircleListMarker {
  marked?: boolean;
  color: 'red' | 'yellow' | 'green';
}

const CircleListMarker: FC<ICircleListMarker> = ({ color = 'red', marked = false }) => {
  const classes = useStyles();

  return (
    <div
      className={cx(classes.marker, `${classes.marker}--${color}`, {
        [`${classes.marker}--marked`]: marked,
      })}
      data-testid='marker'
    >
      <div className={cx(classes.score, `${classes.score}--${color}`)} data-testid='score' />
    </div>
  );
};

export default CircleListMarker;
