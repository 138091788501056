import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useVirtual } from '@tanstack/react-virtual';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import useTableSort from 'hooks/useTableSort';

import { TABLE_VIEW_TYPE } from 'interfaces/common/table-view-type';

import { vehicleListItemsFiltersDTO } from 'utils/dto/vehicle/list-filters';
import { vehicleListItemsDTO } from 'utils/dto/vehicle/list-item';

import vehiclesShowDateFilterAtom from 'recoil/drivers-vehicles/show-vehicles-date-filter';
import driversVehiclesShowVehiclesFilterAtom from 'recoil/drivers-vehicles/show-vehicles-filter';
import vehiclesFilterAtom from 'recoil/drivers-vehicles/vehicles-filters';
import vehiclesListAtom from 'recoil/drivers-vehicles/vehicles-list';
import vehiclesSelectedPeriodAtom from 'recoil/drivers-vehicles/vehicles-selected-period';
import vehiclesSortDataAtom from 'recoil/drivers-vehicles/vehicles-sort';
import { ISortParameters, SORT_DIRECTION } from 'recoil/settings/payment/transactions/sort';
import { IVehicleFilters } from 'recoil/settings/vehicles/filters/atom';
import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';
import userConfigAtom from 'recoil/user-config';

import {
  IVehiclesListItem,
  vehiclesGetReq,
} from 'requests/be-service/vehicle-controller/get-vehicles';

import Drawer from 'components/drawer';
import Loader from 'components/Loader';
import Modal from 'components/modal';
import PaginationScroll from 'components/pagination-scroll';
import TableSortLabel from 'components/TableSortLabel';

import DateFilter from '../components/date-filter';
import QuickVehicleEdit from '../components/quick-vehicle-edit';
import VehicleRow from '../components/vehicle-row';
import VehiclesFilter from '../components/vehicles-filter';

import { TABLE_HEAD_COLUMNS } from './data';
import useStyles from './styles';

const VehiclesList = () => {
  const technicalMessageBlockHeight = useRecoilValue(technicalMessageBlockHeightSelector);
  const classes = useStyles({ technicalMessageBlockHeight });
  const { t } = useTranslation('vehicle.list.page');

  const { isMetricSystem } = useRecoilValue(userConfigAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [tableViewType, setTableViewType] = useState(TABLE_VIEW_TYPE.INFO);
  const [, setVehiclesSort] = useTableSort({ sort: '', direction: SORT_DIRECTION.ASC });
  const [sortData, setSortData] = useRecoilState(vehiclesSortDataAtom);
  const [vehicles, setVehicles] = useRecoilState(vehiclesListAtom);
  const [totalVehicles, setTotalVehicles] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filtersData, setFiltersData] = useRecoilState(vehiclesFilterAtom);
  const setSelectedPeriod = useSetRecoilState(vehiclesSelectedPeriodAtom);
  const [vehiclesShowDateFilter, setVehiclesShowDateFilter] = useRecoilState(
    vehiclesShowDateFilterAtom,
  );
  const [showVehiclesFilter, setShowVehiclesFilter] = useRecoilState(
    driversVehiclesShowVehiclesFilterAtom,
  );
  const [quickEditVehicle, setQuickEditVehicle] = useState<IVehiclesListItem | null>(null);
  const [showQuickEditVehicle, setShowQuickEditVehicle] = useState(false);
  const tableRef = React.useRef(null);

  /**
   * Change table view type
   */
  const handleTableViewType = () =>
    setTableViewType((prevState) =>
      prevState === TABLE_VIEW_TYPE.INFO ? TABLE_VIEW_TYPE.STATISTIC : TABLE_VIEW_TYPE.INFO,
    );

  /**
   * Set new sort for table
   * @type {(function(*): void)|*}
   */
  const handleTableSort = useCallback(
    (value: string) => {
      const newSort = setVehiclesSort(value);
      setSortData(newSort);
    },
    [setVehiclesSort, setSortData],
  );

  const rowVirtualizer = useVirtual({
    size: vehicles.length,
    parentRef: tableRef,
    overscan: 5,
  });

  /**
   * Get vehicles from API
   * @type {(function(*, *): Promise<void>)|*}
   */
  const getVehicles = useCallback(
    async (page: number, sort: ISortParameters, filters: IVehicleFilters) => {
      try {
        setIsLoading(true);
        const res = await vehiclesGetReq(
          page,
          sort,
          vehicleListItemsFiltersDTO(filters, isMetricSystem),
        );
        setCurrentPage(res.pageable.pageNumber);
        setTotalVehicles(res.totalElements);
        setVehicles((prevState) => [
          ...prevState,
          ...vehicleListItemsDTO(res.content, isMetricSystem),
        ]);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [isMetricSystem, setVehicles],
  );

  /**
   * Load additional vehicles
   */
  const loadMoreHandler = useCallback(
    () => getVehicles(currentPage + 1, sortData, filtersData),
    [currentPage, filtersData, getVehicles, sortData],
  );

  const vehicleEditHandler = useCallback((vehicle: IVehiclesListItem) => {
    setQuickEditVehicle(vehicle);
    setShowQuickEditVehicle(true);
  }, []);

  /**
   * Init vehicles request
   */
  useEffect(() => {
    setVehicles([]);
    setCurrentPage(0);
    getVehicles(0, sortData, filtersData);
  }, [filtersData, getVehicles, setVehicles, sortData]);

  /**
   * Clear data
   */
  useEffect(() => setVehicles([]), [setVehicles]);

  return (
    <div className={classes.wrap}>
      {isLoading && <Loader lightMode width={250} isBlock preventClick />}

      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader data-testid='vehicles-table'>
          <TableHead>
            <TableRow>
              {TABLE_HEAD_COLUMNS(t)[tableViewType].map(({ id, label, styles, align, isSort }) => (
                <TableCell
                  classes={{ head: classes.headCell }}
                  key={id}
                  style={{ ...styles }}
                  align={align}
                >
                  <TableSortLabel
                    name={id}
                    onClick={handleTableSort}
                    sortField={sortData.sort}
                    direction={sortData.direction}
                    disabled={!isSort}
                  >
                    {label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell
                align={'center'}
                classes={{ head: classes.editHeadCell }}
                onClick={handleTableViewType}
                data-testid='button-handleTableViewType'
              >
                |||
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tbody} ref={tableRef} data-testid='vehicles-list'>
            {rowVirtualizer.virtualItems.map((item) => {
              const data = vehicles[item.index];

              return (
                <VehicleRow
                  key={data.id}
                  data={data}
                  tableViewType={tableViewType}
                  editHandler={vehicleEditHandler}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationScroll
        count={vehicles.length}
        maxCount={totalVehicles}
        loadMoreHandler={loadMoreHandler}
      />

      <Modal onClose={() => setVehiclesShowDateFilter(false)} open={vehiclesShowDateFilter}>
        <DateFilter
          setDateFilter={(data) => {
            setFiltersData((prevState) => ({
              ...prevState,
              movementAtFrom: new Date(data.start).getTime(),
              movementAtTill: new Date(data.end).getTime(),
            }));
            setVehiclesShowDateFilter(false);
          }}
          setSelectedPeriod={setSelectedPeriod}
          onCloseFilter={() => setVehiclesShowDateFilter(false)}
        />
      </Modal>

      <Modal onClose={() => setShowVehiclesFilter(false)} open={showVehiclesFilter}>
        <VehiclesFilter />
      </Modal>

      <Drawer open={showQuickEditVehicle} onClose={() => setShowQuickEditVehicle(false)}>
        {quickEditVehicle && (
          <QuickVehicleEdit
            vehicle={quickEditVehicle}
            onClose={() => setShowQuickEditVehicle(false)}
          />
        )}
      </Drawer>
    </div>
  );
};

export default VehiclesList;
