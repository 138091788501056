import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrap: {
    width: 32,
    height: 32,
    transform: 'translate(-50%, -50%)',
  },
}));

export default useStyles;
