import queryString from 'query-string';

import { IResponseWrapper } from 'interfaces/server/response-wrapper';
import { ITeamMember } from 'interfaces/teams/i-team-member';

import server from 'utils/server';

/**
 * Fetches team members for a given team ID.
 *
 * @param {string} teamId - The ID of the team.
 * @param {number} page - The page number of the results.
 * @param {string} [query=''] - The search query string.
 * @param {boolean} [include=true] - Flag to include additional information.
 * @returns {Promise<IResponseWrapper<ITeamMember[]>>} The promise that resolves to the response data.
 */
export const teamMembersGetReq = async (
  teamId: string,
  page: number,
  query: string = '',
  include: boolean = true,
): Promise<IResponseWrapper<ITeamMember[]>> => {
  const params = {
    include,
    page,
    size: 25,
    query,
  };

  return server.services.beService
    .get<IResponseWrapper<ITeamMember[]>>(`teams/${teamId}?` + queryString.stringify(params))
    .then((res) => res.data);
};
