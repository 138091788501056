import queryString from 'query-string';

import server from 'utils/server';

interface IDriverReportsProps {
  formatValues: {
    startAt: string;
    finishAt: string;
    isSplitByDays?: boolean;
  };
  id?: string;
}

/**
 * Sends a request to the server to generate a driver report and initiates a file download.
 *
 * @param {IDriverReportsProps} params - The parameters for generating the driver report.
 * @param {object} params.formatValues - The formatted values for the driver report.
 * @param {string | null} params.id - The optional report ID to retrieve a specific report.
 *
 * @returns {Promise<void>} - A promise representing the completion of the request and file download.
 */
export const driverReportReq = async ({ formatValues, id }: IDriverReportsProps): Promise<void> => {
  const body = {
    finishAt: formatValues.finishAt,
    startAt: formatValues.startAt,
  };

  const params = {
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
  };
  let url = `reports/export/drivers/${formatValues.isSplitByDays ? 'by-days/' : ''}`;

  if (id) {
    url += id;
  } else {
    url += `?${queryString.stringify(params)}`;
  }

  return server.services
    .reportService({ url: url, method: 'post', responseType: 'blob', data: body })
    .then((res) => {
      const disposition = res.headers['content-disposition'];
      const href = URL.createObjectURL(res.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `${disposition.split('Filename=')[1].split('.xlsx')[0]}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};
