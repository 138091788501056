import queryString from 'query-string';

import { ACTIVITY_STATUSES } from 'interfaces/common/activity-statuses';
import { IFullyPointCoordinates } from 'interfaces/map/point';
import { ENGINE_STATUS } from 'interfaces/vehicles/engine-status';

import server from 'utils/server';

export interface IDeviceInfo {
  active: boolean;
  gpsPoint: Omit<IFullyPointCoordinates, 'angle'>;
  imei: string;
  driverId: string;
}

interface ILiveTripPoints {
  isActive: boolean;
  speed: number;
  wayPoints: (IFullyPointCoordinates & { speed: number; penalty: string })[];
  distance: number;
  driveTime: number;
  idleTime: number;
  parkingTime: number;
  engineStatus: ENGINE_STATUS;
  status: ACTIVITY_STATUSES;
  deviceInfo: IDeviceInfo;
}

/**
 * Retrieves live trip points for a given vehicle and start time.
 *
 * @param {string} vehicleId - The ID of the vehicle.
 * @param {number} startTime - The start time of the trip (optional, default is null).
 * @returns {Promise<ILiveTripPoints>} - A promise that resolves to the live trip points.
 */
export const getLiveTripPointsReq = async (
  vehicleId: string,
  startTime: number | null = null,
): Promise<ILiveTripPoints> => {
  const params = {
    limit: 200,
    startTime,
  };

  return server.services.mobileService
    .get<ILiveTripPoints>(`track/vehicles/${vehicleId}/track?` + queryString.stringify(params))
    .then((res) => res.data);
};
