import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { TABLE_VIEW_TYPE } from 'interfaces/common/table-view-type';
import { TIME_DEGREE } from 'interfaces/common/time-degree';

import { ReactComponent as PencilIcon } from 'assets/images/pencil.svg';

import RouteManager from 'utils/services/route-manager';

import crashModalAtom from 'recoil/crash-modal';
import { CRASH_ANCHOR, CRASH_MODAL_TABS } from 'recoil/crash-modal/atom';

import { IGetDriversItem } from 'requests/be-service/driver-controller/get-drivers';
import { getDriverCrashListReq } from 'requests/gps-service/crash-controller/get-driver-crashes';

import ActivityStatus from 'components/activity-statuses';
import Collision from 'components/Collision';
import PenaltyCard from 'components/penalty-card';
import PlateNumber from 'components/plate-number';
import ScoreValue from 'components/score-value';
import StatisticValue from 'components/statistic-value';
import Time from 'components/time';
import VehicleIcon from 'components/vehicle-icon';

import useStyles from './styles';

interface IProps {
  data: IGetDriversItem;
  tableViewType: TABLE_VIEW_TYPE;
  editHandler: (vehicle: IGetDriversItem['vehicle'], driverId: string) => void;
}

const DriverRow: FC<IProps> = ({ data, tableViewType, editHandler }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const setCrashModal = useSetRecoilState(crashModalAtom);

  const rowHandler = () => navigate(RouteManager.makeURL('drivers.trips', { id: data.driverId }));

  const pencilEditHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    editHandler(
      {
        ...data.vehicle,
        id: data.vehicleId ?? data.vehicle.id,
      },
      data.driverId,
    );
  };

  const handleCrashClick = useCallback(async () => {
    try {
      setCrashModal((prevState) => {
        return {
          ...prevState,
          isOpen: true,
          isFetching: true,
          anchor: CRASH_ANCHOR.VEHICLE,
          activeTab: CRASH_MODAL_TABS.DESCRIPTION,
        };
      });

      const response = await getDriverCrashListReq(data.driverId);

      setCrashModal((prevState) => {
        return {
          ...prevState,
          currentCrashIdNumber: response.length - 1,
          crashList: response,
        };
      });
    } catch (error) {
      setCrashModal((prevState) => {
        return {
          ...prevState,
          error: error,
        };
      });
    } finally {
      setCrashModal((prevState) => {
        return {
          ...prevState,
          isFetching: false,
        };
      });
    }
  }, [data.driverId, setCrashModal]);

  const handleActivityStatusClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      navigate(RouteManager.makeURL('dashboardMap.live', { id: data?.vehicle?.id }));
    },
    [data?.vehicle?.id, navigate],
  );

  return (
    <TableRow
      className={classes.row}
      onClick={rowHandler}
      data-testid={`driver-row-${data.driverId}`}
    >
      <TableCell classes={{ body: classes.cell }}>
        <div className={classes.driverInfoWrap}>
          {data.status && (
            <ActivityStatus
              variant={'fill'}
              value={data.status}
              onClick={handleActivityStatusClick}
            />
          )}
          <div className={classes.driverInfo}>
            <div
              className={classes.driverName}
              data-testid='driverName'
            >{`${data.firstName} ${data.lastName}`}</div>

            {data.vehicle?.vehicleType && (
              <VehicleIcon variant={'outline'} type={data.vehicle?.vehicleType} />
            )}

            {data.vehicle?.country && data.vehicle?.plateNumber && (
              <PlateNumber
                country={data.vehicle?.country}
                plateNumber={data.vehicle?.plateNumber}
              />
            )}
          </div>
        </div>
      </TableCell>

      <TableCell classes={{ body: classes.cell }} align={'center'} data-testid='currentScoring'>
        <ScoreValue value={data.currentScoring} />
      </TableCell>

      {tableViewType === 'info' && (
        <TableCell classes={{ body: classes.cell }} align={'center'}>
          <div className={classes.problems}>
            {Boolean(data.crashCount) && (
              <Collision count={data.crashCount} onClick={handleCrashClick} />
            )}
            {data.penaltyCards?.map((type, i) => <PenaltyCard type={type} key={`${type}-${i}`} />)}
          </div>
        </TableCell>
      )}

      <TableCell classes={{ body: classes.cell }} align={'center'} data-testid='dailyKm'>
        <StatisticValue value={data.dailyKm} />
      </TableCell>

      <TableCell classes={{ body: classes.cell }} align={'center'} data-testid='dailyH'>
        <Time timestamp={data.dailyH} />
      </TableCell>

      {tableViewType === 'info' && (
        <TableCell classes={{ body: classes.cell }} align={'center'} data-testid='idling'>
          <Time timestamp={data.idling} />
        </TableCell>
      )}

      {tableViewType === 'statistic' && (
        <>
          <TableCell
            classes={{ body: classes.cell }}
            align={'center'}
            data-testid='maxTimeBetweenTripsBySecond'
          >
            <Time timestamp={data.maxTimeBetweenTripsBySecond} degree={TIME_DEGREE.S} />
          </TableCell>
          <TableCell align={'center'} classes={{ body: classes.cell }} data-testid='fuelUsed'>
            <StatisticValue value={data.fuelUsed} />
          </TableCell>
        </>
      )}

      <TableCell classes={{ body: classes.cell }} align={'center'}>
        <div onClick={pencilEditHandler} data-testid='button-edit'>
          <PencilIcon className={classes.pencil} />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default DriverRow;
