import { atom, selector } from 'recoil';

import { IUserData } from 'interfaces/user/user-data';

import { ROLES_OBJECT } from 'constants/user';

import recoilLocalStorageEffect from 'utils/recoil-localStorage-effect';

import userDataAtom from '../../../userData';

export interface ISettingDriversFilters {
  companyIds: null | [string];
  userIds: null | [string];
  teamIds: null | [string];
  createdAtFrom: null | number;
  createdAtTill: null | number;
  query: string;
  includeDisabled: boolean;
}
export const initValues = {
  companyIds: null,
  userIds: null,
  teamIds: null,
  createdAtFrom: null,
  createdAtTill: null,
  query: '',
  includeDisabled: false,
} as ISettingDriversFilters;

const settingsDriverFilterAtom = atom<ISettingDriversFilters>({
  key: 'settingsDriverFilterAtom',
  default: initValues,
  effects_UNSTABLE: [recoilLocalStorageEffect('settingsDriverFilterAtom', initValues)],
});

const settingsDriverFilterSelector = selector<ISettingDriversFilters>({
  key: 'settingsDriverFilterSelector',
  get: ({ get }) => {
    const { role, companyId, id } = get(userDataAtom) as IUserData;
    const init = get(settingsDriverFilterAtom);

    if (
      [
        ROLES_OBJECT.TECH_ADMIN,
        ROLES_OBJECT.ADMIN,
        ROLES_OBJECT.OWNER,
        ROLES_OBJECT.OPERATOR,
      ].includes(role)
    ) {
      return init;
    }

    return {
      ...init,
      companyIds: [companyId],
      userIds: [id],
    };
  },
  set: ({ set }, newValue) => set(settingsDriverFilterAtom, newValue),
});

export default settingsDriverFilterSelector;
