import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

type Props = {
  technicalMessageBlockHeight: number;
};

const useStyles = makeStyles<Theme, Props>(() => ({
  app: {
    position: 'relative',
    width: '100vw',
    maxWidth: '100vw',
    height: ({ technicalMessageBlockHeight }) => `calc(100vh - ${technicalMessageBlockHeight}px)`,
    maxHeight: ({ technicalMessageBlockHeight }) =>
      `calc(100vh - ${technicalMessageBlockHeight}px)`,
    overflow: 'hidden',
  },
}));

export default useStyles;
