import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

type Props = {
  technicalMessageBlockHeight: number;
};

const useStyles = makeStyles<Theme, Props>(() => ({
  wrap: {
    margin: '0 auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1440,
  },

  content: {
    paddingInline: 50,
    marginTop: 50,
  },

  usersWrap: {
    position: 'relative',
    marginTop: 15,
    height: ({ technicalMessageBlockHeight }) =>
      `calc(100vh - 430px - ${technicalMessageBlockHeight}px)`,
  },
}));

export default useStyles;
