import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useVirtual } from '@tanstack/react-virtual';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import useTableSort from 'hooks/useTableSort';

import { BUTTON_VARIANTS } from 'interfaces/common/button-variants';
import { ISortParameters } from 'interfaces/common/sort';
import { TABLE_VIEW_TYPE } from 'interfaces/common/table-view-type';
import { IUserData } from 'interfaces/user/user-data';
import { ENGINE_STATUS } from 'interfaces/vehicles/engine-status';

import { ROLES_OBJECT } from 'constants/user';

import { vehicleListItemsFiltersDTO } from 'utils/dto/vehicle/list-filters';
import { vehicleListItemsDTO } from 'utils/dto/vehicle/list-item';
import { getObjectFillValuesCount } from 'utils/helpers/get-object-fill-values-count';
import RouteManager from 'utils/services/route-manager';

import crashModalAtom from 'recoil/crash-modal';
import { SORT_DIRECTION } from 'recoil/settings/payment/transactions/sort';
import settingVehiclesShowDisableModalAtom from 'recoil/settings/vehicles/disable/show-modal';
import settingVehiclesFilterAtom from 'recoil/settings/vehicles/filters';
import { IVehicleFilters } from 'recoil/settings/vehicles/filters/atom';
import settingVehiclesListAtom from 'recoil/settings/vehicles/list';
import settingVehiclesShowFilterAtom from 'recoil/settings/vehicles/show-filter';
import settingVehiclesShowQuickEditAtom from 'recoil/settings/vehicles/show-quick-edit';
import settingsVehiclesSortDataAtom from 'recoil/settings/vehicles/sort';
import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';
import userConfigAtom from 'recoil/user-config';
import userDataAtom from 'recoil/userData';

import {
  IVehiclesListItem,
  vehiclesGetReq,
} from 'requests/be-service/vehicle-controller/get-vehicles';
import { vehicleEnginePowerToggleReq } from 'requests/be-service/vehicle-controller/send-command';

import Drawer from 'components/drawer';
import EngineStatusModal from 'components/EngineStatusModal';
import Loader from 'components/Loader';
import Modal from 'components/modal';
import PaginationScroll from 'components/pagination-scroll';
import TableSortLabel from 'components/TableSortLabel';

import Header from '../../components/header';

import DisableModal from './components/disable-modal';
import Filter from './components/filter';
import QuickEdit from './components/quick-edit';
import VehicleRow from './components/vehicle-row';
import { TABLE_HEAD_COLUMNS } from './data';
import useStyles from './styles';

interface IEngineModalProps {
  isOpen: boolean;
  vehicleIMEI: null | string;
  status: null | ENGINE_STATUS;
}

const SettingsVehicles = () => {
  const technicalMessageBlockHeight = useRecoilValue(technicalMessageBlockHeightSelector);
  const classes = useStyles({ technicalMessageBlockHeight });
  const navigate = useNavigate();
  const { t } = useTranslation(['setting.vehicles.page', 'setting.page']);

  const setCrashModal = useSetRecoilState(crashModalAtom);
  const userData = useRecoilValue(userDataAtom) as IUserData;
  const [isLoading, setIsLoading] = useState(false);
  const [tableViewType, setTableViewType] = useState<TABLE_VIEW_TYPE>(TABLE_VIEW_TYPE.INFO);
  const [, setVehiclesSort] = useTableSort({ sort: '', direction: SORT_DIRECTION.ASC });
  const [sortData, setSortData] = useRecoilState(settingsVehiclesSortDataAtom);
  const { isMetricSystem } = useRecoilValue(userConfigAtom);
  const [vehicles, setVehicles] = useRecoilState(settingVehiclesListAtom);
  const [totalVehicles, setTotalVehicles] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [engineModal, setEngineModal] = useState<IEngineModalProps>({
    isOpen: false,
    vehicleIMEI: null,
    status: null,
  });
  const [filtersData, setFiltersData] = useRecoilState(settingVehiclesFilterAtom);
  const [showFilter, setShowFilter] = useRecoilState(settingVehiclesShowFilterAtom);
  const [showDisabledModal, setShowDisabledModal] = useRecoilState(
    settingVehiclesShowDisableModalAtom,
  );
  const [quickEditVehicle, setQuickEditVehicle] = useState<IVehiclesListItem | null>(null);
  const [showQuickEditVehicle, setShowQuickEditVehicle] = useRecoilState(
    settingVehiclesShowQuickEditAtom,
  );
  const tableRef = React.useRef(null);

  /**
   * Change table view type
   */
  const handleTableViewType = () =>
    setTableViewType((prevState) =>
      prevState === TABLE_VIEW_TYPE.INFO ? TABLE_VIEW_TYPE.STATISTIC : TABLE_VIEW_TYPE.INFO,
    );

  /**
   * Set new sort for table
   * @type {(function(*): void)|*}
   */
  const handleTableSort = useCallback(
    (value: string) => {
      const newSort = setVehiclesSort(value);
      setSortData(newSort);
    },
    [setVehiclesSort, setSortData],
  );

  const rowVirtualizer = useVirtual({
    size: vehicles.length,
    parentRef: tableRef,
    overscan: 5,
  });

  /**
   * Get vehicles from API
   * @type {(function(*, *): Promise<void>)|*}
   */
  const getVehicles = useCallback(
    async (page: number, sort: ISortParameters, filters: IVehicleFilters) => {
      try {
        setIsLoading(true);
        const res = await vehiclesGetReq(
          page,
          sort,
          vehicleListItemsFiltersDTO(filters, isMetricSystem),
        );
        setCurrentPage(res.pageable.pageNumber);
        setTotalVehicles(res.totalElements);
        setVehicles((prevState) => [
          ...prevState,
          ...vehicleListItemsDTO(res.content, isMetricSystem),
        ]);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [setVehicles, isMetricSystem],
  );

  const handleOpenEngineModal = useCallback((vehicleIMEI: string, engineStatus: ENGINE_STATUS) => {
    if (sessionStorage.getItem('subcompanyId')) {
      return;
    }

    if ([ENGINE_STATUS.ACTIVE, ENGINE_STATUS.INACTIVE].includes(engineStatus)) {
      setEngineModal({ isOpen: true, vehicleIMEI, status: engineStatus });
    }
  }, []);

  const handleCloseEngineModal = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setEngineModal({ isOpen: false, vehicleIMEI: null, status: null });
    }
  }, []);

  const handleApproveToggleEngine = useCallback(async () => {
    if (!engineModal.vehicleIMEI || !engineModal.status) return;

    try {
      await vehicleEnginePowerToggleReq(engineModal.vehicleIMEI, engineModal.status);
    } catch (e) {
      console.log(e);
    } finally {
      setEngineModal({ isOpen: false, vehicleIMEI: null, status: null });
    }
  }, [engineModal.status, engineModal.vehicleIMEI]);

  /**
   * Load additional vehicles
   */
  const loadMoreHandler = useCallback(
    () => getVehicles(currentPage + 1, sortData, filtersData),
    [currentPage, filtersData, getVehicles, sortData],
  );

  const vehicleEditHandler = useCallback(
    (vehicle: IVehiclesListItem) => {
      setQuickEditVehicle(vehicle);
      setShowQuickEditVehicle(true);
    },
    [setShowQuickEditVehicle],
  );

  const searchHandler = useCallback(
    (data: { query: string }) =>
      setFiltersData((prevState) => ({
        ...prevState,
        ...data,
      })),
    [setFiltersData],
  );

  /**
   * Init vehicles request
   */
  useEffect(() => {
    setVehicles([]);
    setCurrentPage(0);
    getVehicles(0, sortData, filtersData);
  }, [filtersData, getVehicles, setVehicles, sortData]);

  return (
    <div className={classes.wrap}>
      {isLoading && <Loader lightMode width={250} isBlock preventClick />}

      <Header
        searchHandler={searchHandler}
        toolBarBtns={[
          {
            label: 'Add vehicle +',
            onClick: () => navigate(RouteManager.makeURL('vehicles.create')),
            variant: BUTTON_VARIANTS.DEFAULT,
            hide: userData.role !== ROLES_OBJECT.TECH_ADMIN,
          },
        ]}
        backBtn={{ label: t('setting.page:vehicles.label') }}
        onClickFilter={() => setShowFilter(true)}
        onCancelSearch={() => {
          setFiltersData((prevState) => ({
            ...prevState,
            query: '',
          }));
        }}
        searchValue={filtersData.query}
        appliedFiltersCount={getObjectFillValuesCount(filtersData)}
      />

      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader data-testid='vehicles-table'>
          <TableHead>
            <TableRow>
              {TABLE_HEAD_COLUMNS(t, isMetricSystem)[tableViewType].map(
                ({ id, label, styles, align, isSort }) => (
                  <TableCell
                    classes={{ head: classes.headCell }}
                    key={id}
                    style={{ ...styles }}
                    align={align}
                  >
                    <TableSortLabel
                      name={id}
                      onClick={handleTableSort}
                      sortField={sortData.sort}
                      direction={sortData.direction}
                      disabled={!isSort}
                    >
                      {label}
                    </TableSortLabel>
                  </TableCell>
                ),
              )}
              <TableCell
                align={'center'}
                classes={{ head: classes.editHeadCell }}
                onClick={handleTableViewType}
                data-testid='button-handleTableViewType'
              >
                |||
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tbody} ref={tableRef} data-testid='vehicles-list'>
            {rowVirtualizer.virtualItems.map((item) => {
              const data = vehicles[item.index];

              return (
                <VehicleRow
                  key={data.id}
                  data={data}
                  tableViewType={tableViewType}
                  editHandler={vehicleEditHandler}
                  handleOpenEngineModal={handleOpenEngineModal}
                  setCrashModal={setCrashModal}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationScroll
        count={vehicles.length}
        maxCount={totalVehicles}
        loadMoreHandler={loadMoreHandler}
      />

      <Modal onClose={() => setShowFilter(false)} open={showFilter}>
        <Filter />
      </Modal>

      <Modal onClose={() => setShowDisabledModal(false)} open={showDisabledModal}>
        <DisableModal />
      </Modal>

      <Drawer open={showQuickEditVehicle} onClose={() => setShowQuickEditVehicle(false)}>
        {quickEditVehicle && (
          <QuickEdit vehicle={quickEditVehicle} onClose={() => setShowQuickEditVehicle(false)} />
        )}
      </Drawer>

      {engineModal.status && (
        <EngineStatusModal
          isOpen={engineModal.isOpen}
          status={engineModal.status}
          onCancel={handleCloseEngineModal}
          onSubmit={handleApproveToggleEngine}
        />
      )}
    </div>
  );
};

export default SettingsVehicles;
