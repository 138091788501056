export const descriptionData = {
  awesome: {
    title: 'chart.title.from15to30.label',
    subtitle: 'chart.subtitle.from15to30.label',
  },
  good: {
    title: 'chart.title.from0to14.label',
    subtitle: 'chart.subtitle.from0to14.label',
  },
  bad: {
    title: 'chart.title.from0toM30.label',
    subtitle: 'chart.subtitle.from0toM30.label',
  },
  risky: {
    title: 'chart.title.fromM31toM100.label',
    subtitle: 'chart.subtitle.fromM31toM100.label',
  },
  dangerous: {
    title: 'chart.title.fromM101toM500.label',
    subtitle: 'chart.subtitle.fromM101toM500.label',
  },
  aggressive: {
    title: 'chart.title.fromM501.label',
    subtitle: 'chart.subtitle.fromM501.label',
  },
};
