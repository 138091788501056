import cx from 'classnames';
import React, { FC } from 'react';

import { COUNTRIES } from 'interfaces/common/countries';

import useStyles from './styles';

interface IVehicleNumber {
  onClick: () => void;
  isLittle?: boolean;
  country: COUNTRIES;
  plateNumber: string;
}

const VehicleNumber: FC<IVehicleNumber> = ({ country, plateNumber, onClick, isLittle = false }) => {
  const classes = useStyles({ isLittle });

  return (
    <div
      data-testid='plateNumber'
      className={cx(classes.wrap)}
      onClick={onClick}
      title={plateNumber}
    >
      <div className={classes.country}>{country}</div>
      <div className={cx(classes.plateNumber)}>{plateNumber}</div>
    </div>
  );
};

export default React.memo(VehicleNumber);
