import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import SimpleBar from 'simplebar-react';

import { ISelectItem } from 'interfaces/common/select-items';

import { ReactComponent as ArrowIcon } from 'assets/images/arrow-simple.svg';

import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';

import { createVehicleBrandReq } from 'requests/be-service/brand-model-controller/create-brand';
import { createVehicleModelReq } from 'requests/be-service/brand-model-controller/create-brand-model';
import { vehicleBrandsReq } from 'requests/be-service/brand-model-controller/find-brands';
import { vehicleModelsReq } from 'requests/be-service/brand-model-controller/find-models-by-brand';
import { renameVehicleBrandReq } from 'requests/be-service/brand-model-controller/update-brand';
import { renameVehicleModelReq } from 'requests/be-service/brand-model-controller/update-brand-model';

import Loader from 'components/Loader';

import Header from '../../components/header';

import AddNewField from './add-new-field';
import Input from './input';
import useStyles from './styles';

const VehicleModels = () => {
  const technicalMessageBlockHeight = useRecoilValue(technicalMessageBlockHeightSelector);
  const classes = useStyles({ technicalMessageBlockHeight });
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('setting.page');

  const [isLoadingBrands, setIsLoadingBrands] = useState(false);
  const [isLoadingModels, setIsLoadingModels] = useState(false);
  const [brand, setBrand] = useState<ISelectItem[]>([]);
  const [models, setModels] = useState<ISelectItem[]>([]);
  const [brandFilter, setBrandFilter] = useState('');
  const [activeBrandId, setActiveBrandId] = useState<number | null>(null);

  /**
   * Get vehicle brands
   */
  const getVehicleBrands = useCallback(async () => {
    try {
      setIsLoadingBrands(true);
      const response = await vehicleBrandsReq();
      setBrand(response);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingBrands(false);
    }
  }, []);

  const getBrandModels = useCallback(async (brandId: number) => {
    try {
      setModels([]);
      setIsLoadingModels(true);
      setActiveBrandId(brandId);

      const response = await vehicleModelsReq(brandId);

      setModels(response);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingModels(false);
    }
  }, []);

  /**
   * Init request for vehicle brands
   */
  useEffect(() => {
    const req = async () => getVehicleBrands();
    req();
  }, [getVehicleBrands]);

  const handleBrandClick = useCallback(
    (brandId: number) => {
      if (brandId === activeBrandId) {
        setActiveBrandId(null);
        return;
      }
      getBrandModels(brandId);
    },
    [activeBrandId, getBrandModels],
  );

  /**
   * Create new vehicle brand and update brands list
   * @type {(function(*): Promise<void>)|*}
   */
  const handleCreateBrand = useCallback(
    async (value: string) => {
      try {
        setIsLoadingBrands(true);
        await createVehicleBrandReq(value);
        enqueueSnackbar('Saved', { variant: 'success' });
        getVehicleBrands();
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoadingBrands(false);
      }
    },
    [enqueueSnackbar, getVehicleBrands],
  );

  /**
   * Rename vehicle brand and get updated list
   * @type {(function(*, *): Promise<void>)|*}
   */
  const handleRenameBrand = useCallback(
    async (id: number, value: string) => {
      try {
        setIsLoadingBrands(true);
        await renameVehicleBrandReq(id, value);
        enqueueSnackbar('Saved', { variant: 'success' });
        getVehicleBrands();
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoadingBrands(false);
      }
    },
    [enqueueSnackbar, getVehicleBrands],
  );

  const handleCreateModel = useCallback(
    async (brandId: number, value: string) => {
      try {
        setIsLoadingModels(true);
        await createVehicleModelReq(brandId, value);
        await getBrandModels(brandId);
        enqueueSnackbar('Saved', { variant: 'success' });
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoadingModels(false);
      }
    },
    [enqueueSnackbar, getBrandModels],
  );

  const handleRenameModel = useCallback(
    async (brandId: number, modelId: number, value: string) => {
      try {
        setIsLoadingModels(true);
        await renameVehicleModelReq(brandId, modelId, value);
        enqueueSnackbar('Saved', { variant: 'success' });
        getBrandModels(brandId);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoadingModels(false);
      }
    },
    [enqueueSnackbar, getBrandModels],
  );

  const filteredBrands = useMemo(() => {
    if (!brandFilter) {
      return brand;
    }

    return brand?.filter((item) => {
      const i = item.label.toLowerCase().indexOf(brandFilter.toLowerCase());
      return i !== -1;
    });
  }, [brand, brandFilter]);

  return (
    <div className={classes.wrap}>
      <Header backBtn={{ label: t('vehicle.model.label') }} />

      {isLoadingBrands && <Loader width={100} lightMode preventClick />}

      <input
        type='text'
        className={classes.searchInput}
        placeholder='Search'
        data-testid='input-search'
        onInput={(e: ChangeEvent<HTMLInputElement>) => setBrandFilter(e.target.value)}
      />

      <AddNewField handleApprove={handleCreateBrand} title='+ Add new brand' />

      <SimpleBar className={classes.brandsWrap}>
        <div className={classes.brandList} data-testid='brand-list'>
          {filteredBrands?.map(({ value, label }) => {
            const isOpen = activeBrandId === value;

            return (
              <div className={classes.brandItem} key={value} data-testid={`brand-item-${value}`}>
                <div className={classes.row}>
                  <div
                    style={{
                      transform: isOpen ? 'rotate(180deg)' : '',
                    }}
                    data-testid='button-arrow'
                    onClick={() => handleBrandClick(+value)}
                  >
                    <ArrowIcon />
                  </div>

                  <Input
                    initValue={label}
                    handlerApprove={handleRenameBrand.bind(null, +value)}
                    disabled
                  />
                </div>

                {isOpen && (
                  <div className={classes.modelsWrap}>
                    <AddNewField
                      title='+ Add new model'
                      handleApprove={handleCreateModel.bind(null, value)}
                    />

                    {isLoadingModels && (
                      <div className={classes.loaderWrap}>
                        <Loader width={100} lightMode isBlock />
                      </div>
                    )}

                    {!isLoadingModels && (
                      <SimpleBar className={classes.modelsList} data-testid='models-list'>
                        {models?.map((model) => {
                          return (
                            <Input
                              key={model.value}
                              initValue={model.label}
                              handlerApprove={handleRenameModel.bind(null, +value, +model.value)}
                              disabled
                            />
                          );
                        })}
                      </SimpleBar>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </SimpleBar>
    </div>
  );
};

export default VehicleModels;
