import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    position: 'relative',
    width: 60,
    height: 50,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid transparent',
    transition: 'all ease 0.3s',
    cursor: 'pointer',
    pointerEvents: 'none',
    background: alpha(theme.palette.primary.dark, 0.08),

    '&:hover': {
      backgroundColor: palette.custom.blue900,
    },

    '&.selected': {
      border: `2px solid ${palette.custom.goldSecondary}`,
      backgroundColor: alpha(palette.custom.goldSecondary, 0.15),
    },

    '&.isBad': {
      pointerEvents: 'unset',
    },
  },

  icon: {
    '& path': {
      stroke: palette.custom.green400,
    },

    '& circle': {
      fill: palette.custom.green400,
    },

    '&.isBad': {
      '& path': {
        stroke: palette.custom.red900,
      },

      '& circle': {
        fill: palette.custom.red900,
      },
    },
  },

  counter: {
    position: 'absolute',
    top: 5,
    right: 0,
    backgroundColor: palette.custom.blue200,
    height: 18,
    minWidth: 18,
    borderRadius: 9,
    padding: '0 5px',
    fontSize: 12,
    fontWeight: 500,
    color: palette.custom.bluePrimary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
