import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  wrap: {
    minHeight: 424,
    padding: '15px 10px 28px 17px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },

  emptyLabel: {
    fontSize: 28,
    color: theme.palette.primary.main,
  },

  tbody: {
    marginTop: 30,
    width: '100%',
    display: 'block',
    paddingRight: 21,
    maxHeight: 355,
    overflow: 'hidden',

    '&:hover': {
      overflowY: 'auto',
    },
  },

  row: {
    paddingBottom: '31px !important',
    display: 'block',
  },

  cell: {
    padding: '0 !important',
  },

  number: {
    minWidth: 25,
  },

  scoring: {
    minWidth: 40,
  },

  circleMarker: {
    pointerEvents: 'none',
  },

  activeCircleMarker: {
    cursor: 'pointer',
    pointerEvents: 'unset',
  },

  name: {
    minWidth: 180,
    width: '100%',
  },

  nameLink: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 15,
    color: theme.palette.primary.main,
    textDecoration: 'none',
    cursor: 'pointer',

    '& div': {
      maxWidth: 150,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 'fit-content',
    },
  },

  sorting: {
    paddingRight: '25px !important',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  saving: {
    minWidth: 100,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 32,
    textAlign: 'right',
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
  },
}));

export default useStyles;
