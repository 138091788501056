import { TDataWithId } from 'interfaces/common/data-with-id';

import server from 'utils/server';

import { TDriverCreateData } from 'modules/DriverProfile/form-schema';

/**
 * Retrieves complete driver data from the server based on the provided driver ID.
 *
 * @param {string} id - The ID of the driver to retrieve data for.
 * @returns {Promise<TDriverCreateData>} - A Promise that resolves with the complete driver data.
 */
export const driverGetReq = async (id: string): Promise<TDataWithId<TDriverCreateData>> =>
  server.services.beService
    .get<TDataWithId<TDriverCreateData>>(`drivers/${id}/full`)
    .then((res) => res.data);
