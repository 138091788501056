import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 130,
  },

  header: {
    padding: '20px 46px',
    marginBottom: 20,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    backgroundColor: palette.custom.bluePrimary,
    zIndex: 100,
    boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.3)',
  },

  btnBack: {
    position: 'absolute',
    left: 19,
    display: 'block',
    width: 12,
    cursor: 'pointer',

    '& svg': {
      width: '100%',
    },
  },

  title: {
    fontSize: 28,
    fontWeight: 500,
    color: palette.custom.blue200,
  },

  btnWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  btn: {
    cursor: 'pointer',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '19px',
    color: palette.custom.bluePrimary,
    padding: '13px 30px',
    backgroundColor: palette.custom.goldSecondary,
    borderRadius: 10,
    border: 'none',
  },

  form: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: 30,
    paddingLeft: 46,
    paddingRight: 46,
  },

  geo: {
    fontFamily: 'SFPro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: palette.custom.blue200,
    display: 'flex',
    alignItems: 'center',
  },

  geoIcon: {
    width: 42,
    height: 42,
    borderRadius: '50%',
    backgroundColor: alpha(palette.custom.goldSecondary, 0.1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    cursor: 'pointer',

    '&.disabled': {
      pointerEvents: 'none',
    },

    '& svg': {
      width: 24,
      height: 24,
    },

    '& line': {
      stroke: palette.custom.goldSecondary,
    },

    '& circle:first-child': {
      stroke: palette.custom.goldSecondary,
    },

    '& circle:nth-child(2)': {
      fill: palette.custom.goldSecondary,
    },
  },

  pinWrap: {
    padding: '20px 35px 20px 20px',
    gridColumnStart: 1,
    gridColumnEnd: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `2px solid ${palette.custom.blue900}`,
    borderRadius: 5,
  },

  chart: {
    marginLeft: 20,
  },
}));

export default useStyles;
