import queryString from 'query-string';

import { ACTIVITY_STATUSES } from 'interfaces/common/activity-statuses';
import { COUNTRIES } from 'interfaces/common/countries';
import { IFullyPointCoordinates } from 'interfaces/map/point';
import { ENGINE_STATUS } from 'interfaces/vehicles/engine-status';
import { IVehicleData } from 'interfaces/vehicles/i-vehicle-data';

import server from 'utils/server';

interface IMapGetAllVehiclesFilters {
  status: ACTIVITY_STATUSES[];
  bottomRightLat: number;
  bottomRightLong: number;
  topLeftLat: number;
  topLeftLong: number;
  limit: number;
}

export interface IMapVehicle {
  // TODO: change type for driver's name and vehicles fields
  driver: {
    id: string;
    phone: string;
    name: string;
  };
  vehicle: Pick<
    IVehicleData,
    'id' | 'plateNumber' | 'brand' | 'model' | 'type' | 'deviceFunctions'
  > & {
    year: number;
    countryCode: COUNTRIES;
    engineStatus: ENGINE_STATUS;
  };
  scoring: number;
  position: IFullyPointCoordinates;
  status: ACTIVITY_STATUSES;
}

/**
 * Get all vehicles from API by default
 */
export const mapGetAllVehiclesReq = async (
  filters: IMapGetAllVehiclesFilters,
  signal: AbortSignal,
) => {
  const params = {
    ...filters,
    limit: 800,
    status: filters.status.length ? filters.status : null,
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
  };

  return server.services.mobileService
    .get<IMapVehicle[]>('dashboard/track/vehicles?' + queryString.stringify(params), { signal })
    .then((res) => res.data);
};
