import { addMinutes } from 'date-fns';

import { TDateISO } from 'interfaces/common/date-iso';

/**
 * Converts a given local time to UTC time.
 *
 * @param {Date} localTime - The local time to be converted.
 * @returns {TDateISO} - The converted UTC time in ISO format.
 */
export const convertToUtcTime = (localTime: Date): TDateISO => {
  const offset = localTime.getTimezoneOffset();
  return addMinutes(localTime, -offset).toJSON() as TDateISO;
};
