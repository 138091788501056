import SearchIcon from '@mui/icons-material/Search';
import React, { FC, useState } from 'react';

import CheckBox from 'components/form-v2/checkbox';
import SearchInput from 'components/search';

import useStyles from './styles';

interface IProps {
  onSearchSubmit?: (data: { query: string }) => void;
  label: string;
  // TODO: refactor
  checkBoxData:
    | {
        show: true;
        handler: (value: boolean) => void;
        isIndeterminate: boolean;
        value: boolean;
      }
    | { show: false };
}

const ColumnHeader: FC<IProps> = ({ onSearchSubmit, label, checkBoxData }) => {
  const classes = useStyles();

  const [showSearchInput, setShowSearchInput] = useState(false);

  return (
    <div className={classes.head}>
      {!showSearchInput && (
        <>
          <div className={classes.content}>
            {checkBoxData.show ? (
              <CheckBox
                onChange={checkBoxData.handler}
                value={checkBoxData.value}
                isIndeterminate={checkBoxData.isIndeterminate}
                testId='checkbox-all'
              />
            ) : (
              ''
            )}
            <div>{label}</div>
          </div>
          {Boolean(onSearchSubmit) && (
            <SearchIcon
              className={classes.searchIcon}
              onClick={() => setShowSearchInput(true)}
              data-testid='button-search'
            />
          )}
        </>
      )}

      {showSearchInput && onSearchSubmit && (
        <SearchInput
          onSubmit={onSearchSubmit}
          onCancel={() => {
            onSearchSubmit({ query: '' });
            setShowSearchInput(false);
          }}
        />
      )}
    </div>
  );
};

export default ColumnHeader;
