import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    position: 'absolute',
    bottom: 10,
    left: 10,
    width: 'calc(100% - 135px)',
    borderRadius: 10,
    padding: 5,
    backgroundColor: palette.custom.lightBlue,
  },

  animationBtns: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 10,
    position: 'absolute',
    top: 11,
    right: 145,
    zIndex: 100,

    '& > div': {
      cursor: 'pointer',
    },
  },

  playBtn: {
    width: 10,
    height: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  stopBtn: {
    width: 10,
    height: 10,
    borderRadius: 2,
    backgroundColor: theme.palette.primary.main,
  },
}));

export default useStyles;
