import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import TimeAgo from 'javascript-time-ago';
import az from 'javascript-time-ago/locale/az';
import en from 'javascript-time-ago/locale/en.json';
import { SnackbarProvider } from 'notistack';
import React, { useMemo, useRef } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import theme from 'assets/theme';

import 'assets/scss/form.scss';
import 'assets/scss/table.scss';
import 'assets/scss/global.scss';
import 'assets/scss/paragraph.scss';

import ContextAppScroll from './ContextAppScroll';
import routes from './routes';
import './styles.scss';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(az);

function Root() {
  const styles = useMemo(
    () => ({
      simpleBar: {
        height: '100vh',
      },
    }),
    [],
  );

  const appScrollRef = useRef<HTMLElement | null>(null);

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <RecoilRoot>
            <div>
              <SimpleBar scrollableNodeProps={{ ref: appScrollRef }} style={styles.simpleBar}>
                <ContextAppScroll.Provider value={appScrollRef}>
                  <RouterProvider router={createBrowserRouter(routes)} />
                </ContextAppScroll.Provider>
              </SimpleBar>
            </div>
          </RecoilRoot>
        </SnackbarProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default Root;
