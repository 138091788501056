import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import { atom, selector } from 'recoil';

import { ROLES_OBJECT } from 'constants/user';

import recoilLocalStorageEffect from 'utils/recoil-localStorage-effect';

import userDataAtom from '../../userData';

export interface IDriversFilters {
  companyIds: null | string[];
  userIds: null | string[];
  teamIds: null | string[];
  movementAtFrom: number;
  movementAtTill: number;
  query: string;
}

export const initValues = {
  companyIds: null,
  userIds: null,
  teamIds: null,
  movementAtFrom: startOfDay(new Date()).getTime(),
  movementAtTill: endOfDay(new Date()).getTime(),
  query: '',
};

const driverFilterAtom = atom<IDriversFilters>({
  key: 'driverFilterAtom',
  default: initValues,
  effects_UNSTABLE: [recoilLocalStorageEffect('driverFilterAtom', initValues)],
});

const driverFilterSelector = selector<IDriversFilters>({
  key: 'driverFilterSelector',
  get: ({ get }) => {
    const user = get(userDataAtom);
    const init = get(driverFilterAtom);

    if (
      [
        ROLES_OBJECT.TECH_ADMIN,
        ROLES_OBJECT.ADMIN,
        ROLES_OBJECT.OWNER,
        ROLES_OBJECT.OPERATOR,
      ].includes(user!.role)
    ) {
      return init;
    }

    return {
      ...init,
      companyIds: [user!.companyId],
      userIds: [user!.id],
    };
  },
  set: ({ set }, newValue) => {
    set(driverFilterAtom, newValue);
  },
});

export default driverFilterSelector;
