import { IVehicleModel } from 'interfaces/vehicles/model';

import server from 'utils/server';

/**
 * Creates a new vehicle model.
 *
 * @param {number} brandId - The ID of the brand associated with the model.
 * @param {string} modelName - The name of the model.
 * @returns {Promise<IVehicleModel>} A promise that resolves with the created vehicle model.
 */
export const createVehicleModelReq = async (
  brandId: number,
  modelName: string,
): Promise<IVehicleModel> => {
  const body = {
    name: modelName,
  };

  return server.services.beService
    .post<IVehicleModel>(`brands/${brandId}/models`, body)
    .then((res) => res.data);
};
