export const INTRODUCE_LANGUAGES = [
  {
    label: 'ENG',
    value: 'en',
    icon: true,
  },
  {
    label: 'AZE',
    value: 'az',
  },
];
