import { valueConverter } from 'utils/helpers/value-converter';

import { IVehiclesListItem } from 'requests/be-service/vehicle-controller/get-vehicles';

export const vehicleListItemsDTO = (
  data: IVehiclesListItem[],
  isMetricSystem: boolean,
): IVehiclesListItem[] => {
  if (isMetricSystem) {
    return data;
  }
  return data.map((item) => {
    return {
      ...item,
      dailyKm: item.dailyKm && valueConverter.distance.toImperial(item.dailyKm),
      totalKm: item.totalKm && valueConverter.distance.toImperial(item.totalKm),
    };
  });
};
