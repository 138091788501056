import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import sensorAlarmsTypes from 'constants/sensor-alarms';

import { ReactComponent as BellIcon } from 'assets/images/bell.svg';

import { valueConverter } from 'utils/helpers/value-converter';

import liveTrackingDriverDescriptionAtom from 'recoil/live-tracking/driver-description';
import vehicleSensorsListAtom from 'recoil/vehicles/vehicleSensorsList';

import { toggleSensorNotificationsReq } from 'requests/be-service/sensor-controller/switch-notification';
import { getSensorAlarmForLiveTripReq } from 'requests/gps-service/sensor-controller/get-alarm-by-imei-for-live';

import Switch from 'components/form-v2/switch';
import Loader from 'components/Loader';
import SensorParameterView from 'components/sensor-parameter-view';

import useStyles from './styles';

const Sensor = ({ wrapClass, sensor, finishTimestamp }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const setVehicleSensorsList = useSetRecoilState(vehicleSensorsListAtom);

  // TODO: need refactor
  const [{ vehicleInfo }, setDriverDescription] = useRecoilState(liveTrackingDriverDescriptionAtom);

  /**
   * Toggle sensor notifications
   */
  const toggleNotification = useCallback(async () => {
    try {
      setIsLoading(true);
      await toggleSensorNotificationsReq(sensor.id);
      const vehiclesSensorsInfo = await getSensorAlarmForLiveTripReq(
        vehicleInfo.id,
        finishTimestamp,
      );
      const hasAlarm = vehiclesSensorsInfo[vehiclesSensorsInfo.length - 1];
      setDriverDescription((prevState) => {
        return {
          ...prevState,
          vehicleInfo: { ...prevState.vehicleInfo, hasAlarm },
        };
      });
      setVehicleSensorsList(vehiclesSensorsInfo.slice(0, -1));
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, [finishTimestamp, sensor, setDriverDescription, setVehicleSensorsList, vehicleInfo.id]);

  if (isLoading) {
    return (
      <div className={cx(classes.wrap, wrapClass)}>
        <Loader isBlock width={50} lightMode />
      </div>
    );
  }

  return (
    <div className={cx(classes.wrap, wrapClass)} data-testid='sensors-wrap'>
      <div className={classes.sensorNumber}>{sensor.sensorNumber}</div>

      <Switch
        value={sensor.notification ?? false}
        onChange={toggleNotification}
        LeftIcon={BellIcon}
        RightIcon={BellIcon}
      />

      <div className={classes.parameters}>
        {sensor?.temperature?.active && (
          <SensorParameterView
            min={
              sensor.temperature.celsius
                ? sensor.temperature.from
                : valueConverter.temperature.celsiusToFahrenheit(sensor.temperature.from)
            }
            current={
              sensor.temperature.celsius
                ? sensor.temperature.value
                : valueConverter.temperature.celsiusToFahrenheit(sensor.temperature.value)
            }
            max={
              sensor.temperature.celsius
                ? sensor.temperature.to
                : valueConverter.temperature.celsiusToFahrenheit(sensor.temperature.to)
            }
            type={sensorAlarmsTypes.TEMPERATURE}
            isCelsius={sensor.temperature.celsius}
          />
        )}

        {sensor?.humidity?.active && (
          <SensorParameterView
            min={sensor.humidity.from}
            current={sensor.humidity.value}
            max={sensor.humidity.to}
            type={sensorAlarmsTypes.HUMIDITY}
          />
        )}

        {sensor?.movement?.active && (
          <SensorParameterView
            min={sensor.movement.from}
            current={sensor.movement.value}
            max={sensor.movement.to}
            type={sensorAlarmsTypes.TEMPERATURE}
          />
        )}
      </div>
    </div>
  );
};

Sensor.propTypes = {
  wrapClass: PropTypes.string,
  sensor: PropTypes.any.isRequired,
  finishTimestamp: PropTypes.number.isRequired,
};

Sensor.defaultProps = {};

export default Sensor;
