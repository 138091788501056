import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '0 auto',
  },

  content: {
    padding: '0 50px 0 50px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  controls: {
    marginBottom: 35,
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'end',
  },

  fileName: {
    fontSize: 18,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
}));

export default useStyles;
