import React, { FC } from 'react';
import CountUp from 'react-countup';

import useStyles from './styles';

interface INumberCounter {
  value: number;
  length: number;
  fillEmptyPositionsWith?: string;
}

const NumberCounter: FC<INumberCounter> = ({ value, length, fillEmptyPositionsWith = '' }) => {
  const classes = useStyles();

  const parsedValue = String(value).split('');
  const needNumbers = length - parsedValue.length;
  const isNotEnoughNumbers = needNumbers > 0;
  const formatValue: string[] = isNotEnoughNumbers
    ? new Array(needNumbers)
        .fill(null)
        .reduce((acc) => [fillEmptyPositionsWith, ...acc], parsedValue)
    : parsedValue;

  return (
    <div className={classes.container}>
      {formatValue.map((item, index) => (
        <div key={index} className={classes.numberContainer}>
          {item ? (
            <CountUp
              start={0}
              end={Number(item)}
              delay={(formatValue.length - (index + 1)) * 0.3}
            />
          ) : (
            item
          )}
        </div>
      ))}
    </div>
  );
};

export default React.memo(NumberCounter);
