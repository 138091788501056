import queryString from 'query-string';

import { ACTIVITY_STATUSES } from 'interfaces/common/activity-statuses';
import { COUNTRIES } from 'interfaces/common/countries';
import { TDataWithId } from 'interfaces/common/data-with-id';
import { PENALTY_CARD } from 'interfaces/common/penalty-card';
import { ISortParameters } from 'interfaces/common/sort';
import { TCompany } from 'interfaces/company/company';
import { IDriverData } from 'interfaces/driver/i-driver-data';
import { IResponseWrapper } from 'interfaces/server/response-wrapper';
import { IVehicleData } from 'interfaces/vehicles/i-vehicle-data';

import server from 'utils/server';

import { IDriversFilters } from 'recoil/driver/drivers-filters/atom';

// TODO: need refactor interface
export interface IGetDriversItem {
  active: IDriverData['active'];
  basic: IDriverData['basic'];
  better: boolean;
  bonus: IDriverData['bonus'];
  fuelUsed: number | null;
  brandName: null | IVehicleData['brand'];
  companyId: TDataWithId<TCompany>['id'];
  companyName: TCompany['name'];
  country: null | COUNTRIES;
  crashCount: number;
  currentScoring: number;
  dailyH: null | number;
  dailyKm: null | number;
  diffScoring: number;
  driverCharacteristic: null;
  driverId: IDriverData['id'];
  engineMinutes: null | number;
  firstName: IDriverData['firstName'];
  hasNonValidatedCrashes: boolean;
  idling: null | number;
  imei: IVehicleData['imei'];
  lastName: IDriverData['lastName'];
  lastPointReceivedAt: string;
  managerName: null;
  modelName: null | IVehicleData['model'];
  penalties: null;
  penaltyCards: PENALTY_CARD[];
  phone: string;
  plateNumber: null | IVehicleData['plateNumber'];
  rating: boolean;
  registrationDate: number;
  status: ACTIVITY_STATUSES;
  totalKm: null | number;
  type: IVehicleData['type'];
  vehicle: {
    id: IVehicleData['id'];
    vehicleId: IVehicleData['id'];
    plateNumber: IVehicleData['plateNumber'];
    engine: IVehicleData['imei']; // TODO: need check
    vin: IVehicleData['vin'];
    creationTime: number;
    country: IVehicleData['country'];
    vehicleType: IVehicleData['type'];
    deviceFunctions: IVehicleData['deviceFunctions'];
  };
  vehicleId: IVehicleData['id'] | null;
  maxTimeBetweenTripsBySecond: number | null;
}

/**
 * Requests a list of drivers from the server.
 *
 * @async
 * @param {ISortParameters} sort - The sort parameters for the request.
 * @param {number} page - The page number for the request.
 * @param {IDriversFilters} filters - The filters to apply to the request.
 * @returns {Promise<IResponseWrapper<IGetDriversItem[]>>} A promise that resolves with the response data.
 */
export const driversReq = async (
  sort: ISortParameters,
  page: number,
  filters: IDriversFilters,
): Promise<IResponseWrapper<IGetDriversItem[]>> => {
  const params = {
    page,
    size: 100,
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
    ...sort,
  };

  return server.services.beService
    .post<IResponseWrapper<IGetDriversItem[]>>('drivers?' + queryString.stringify(params), filters)
    .then((res) => res.data);
};
