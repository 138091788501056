import { selectorFamily } from 'recoil';

import {
  getTripReq,
  IGetDriverTrip,
} from 'requests/be-service/map-view-controller/get-trip-points';

const tripPointsSelector = selectorFamily<IGetDriverTrip, string>({
  key: 'tripPointsSelector',
  get: (tripId) => async () => {
    return await getTripReq(tripId);
  },
});

export default tripPointsSelector;
