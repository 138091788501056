import { atom, selector } from 'recoil';

const settingPaymentCompaniesListPaginationAtom = atom<number>({
  key: 'settingPaymentCompaniesListPaginationAtom',
  default: 0,
});

const settingPaymentCompaniesListPaginationSelector = selector<number>({
  key: 'settingPaymentCompaniesListPaginationSelector',
  get: ({ get }) => {
    return get(settingPaymentCompaniesListPaginationAtom);
  },
  set: ({ set }, newValue) => {
    set(settingPaymentCompaniesListPaginationAtom, newValue);
  },
});

export default settingPaymentCompaniesListPaginationSelector;
