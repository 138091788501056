import cx from 'classnames';
import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { USER_ROLES } from 'interfaces/user/roles';
import { IUserData } from 'interfaces/user/user-data';

import { ReactComponent as CoinsIcon } from 'assets/images/coins.svg';
import { ReactComponent as DriverIcon } from 'assets/images/menu/user-menu.svg';
import { ReactComponent as VehicleIcon } from 'assets/images/menu/vehicles-menu.svg';

import RouteManager from 'utils/services/route-manager';

import userDataAtom from 'recoil/userData';

import { ICompanySettingsItem } from 'requests/company-controller/find-all-companies';

import NewAvatar from 'components/NewAvatar';

import useStyles from './styles';

const CompanySettingsItem: FC<ICompanySettingsItem> = ({
  id,
  iconUrl,
  name,
  activeDriverCount,
  activeVehicleCount,
  vehicleMonthlyPrice,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const userData = useRecoilValue(userDataAtom) as IUserData;

  const handleOpenCompanyEditPage = useCallback(() => {
    if (userData.role !== USER_ROLES.TECH_ADMIN) {
      return;
    }
    navigate(RouteManager.makeURL('settings.companies.edit', { id }));
  }, [id, navigate, userData.role]);

  return (
    <div
      className={cx(classes.wrap, { isTechAdmin: userData.role === USER_ROLES.TECH_ADMIN })}
      onClick={handleOpenCompanyEditPage}
      data-testid={`company-item-${id}`}
    >
      <div className={classes.info}>
        <div className={classes.avatar} data-testid='avatar'>
          <NewAvatar logo={iconUrl} fullscreen />
        </div>
        <div className={classes.company} data-testid='company-name'>
          {name}
        </div>
      </div>

      <div className={classes.statistic}>
        <div className={classes.field}>
          <div className={classes.iconWrap}>
            <DriverIcon />
          </div>
          <div data-testid='active-driver-count'>{activeDriverCount}</div>
        </div>

        <div className={classes.field}>
          <div className={classes.iconWrap}>
            <VehicleIcon />
          </div>
          <div data-testid='active-vehicle-count'>{activeVehicleCount}</div>
        </div>

        <div className={classes.field}>
          <div className={classes.iconWrap}>
            <CoinsIcon />
          </div>
          <div data-testid='vehicle-monthly-price'>{vehicleMonthlyPrice ?? '-'}</div>
        </div>
      </div>
    </div>
  );
};

export default CompanySettingsItem;
