import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  list: {
    paddingRight: 10,
    paddingLeft: 10,
    flex: 1,
    position: 'relative',
  },

  item: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingRight: 15,
    paddingLeft: 15,
    transition: 'all ease 0.3s',

    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.15),
    },
  },

  checkbox: {
    marginTop: 15,
  },

  info: {
    flex: 1,
  },

  emptyLabel: {
    fontSize: 20,
    fontWeight: 500,
    color: palette.custom.blue200,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export default useStyles;
