import cx from 'classnames';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import SimpleBar from 'simplebar-react';

import { ISubcompanyData } from 'interfaces/company/subcompany-data';
import { TNavigationSubcompany } from 'interfaces/navigation/subcompany';

import { ROLES_OBJECT } from 'constants/user';

import { ReactComponent as ArrowIcon } from 'assets/images/arrow-back.svg';

import RouteManager from 'utils/services/route-manager';

import navigationOptionsAtom from 'recoil/navigation';
import userDataAtom from 'recoil/userData';

import { getCompanyChildrenReq } from 'requests/be-service/company-controller/get-child-company-ids';

import NewAvatar from 'components/NewAvatar';

import CompanyItem from '../company-item';
import SearchItem from '../search';

import useStyles from './styles';

const Company = () => {
  const classes = useStyles();
  const [userData] = useRecoilState(userDataAtom);
  const [navigationState, setNavigationState] = useRecoilState(navigationOptionsAtom);
  const [companiesList, setCompaniesList] = useState<TNavigationSubcompany[] | null>(null);

  /**
   * Get sub companies list when opening list
   */
  useEffect(() => {
    if (!navigationState.isOpenCompanyList) {
      return;
    }

    const req = async () => {
      try {
        const response = await getCompanyChildrenReq(userData!.companyId);
        setCompaniesList(response);
      } catch (error) {
        console.log({ error });
      }
    };

    req();
  }, [navigationState.isOpenCompanyList, userData]);

  /**
   * Toggle company list
   */
  const handleArrowClick = () => {
    if (
      navigationState.isOpenNav &&
      !sessionStorage.getItem('subcompanyId') &&
      [ROLES_OBJECT.TECH_ADMIN, ROLES_OBJECT.OWNER].includes(userData!.role)
    ) {
      setNavigationState((prevState) => ({
        ...prevState,
        isOpenSearch: false,
        isOpenCompanyList: !prevState.isOpenCompanyList,
      }));
    }
  };

  const handleCompanyClick = (params: ISubcompanyData) => {
    const path = `${window.location.origin}/${RouteManager.path('scoring')}?${queryString.stringify(
      params,
    )}`;
    window.open(path, '_blank');
  };

  return (
    <div
      className={cx(classes.wrap, [{ isOpen: navigationState.isOpenCompanyList }])}
      data-testid='company-wrap'
    >
      <div
        className={classes.companyWrap}
        onClick={handleArrowClick}
        data-testid='arrow-click-area'
      >
        <div className={classes.avatar} data-testid='company-avatar'>
          <NewAvatar
            logo={
              sessionStorage.getItem('subcompanyId')
                ? sessionStorage.getItem('subcompanyLogoUrl')!
                : userData!.companyLogo
            }
            isColorCircle
          />
        </div>
        {navigationState.isOpenNav && (
          <span className={classes.companyName} data-testid='company-name'>
            {sessionStorage.getItem('subcompanyName') ?? userData!.companyName}
          </span>
        )}
        {navigationState.isOpenNav &&
          !sessionStorage.getItem('subcompanyId') &&
          [ROLES_OBJECT.TECH_ADMIN, ROLES_OBJECT.OWNER].includes(userData!.role) && (
            <ArrowIcon
              className={cx(classes.arrow, navigationState.isOpenCompanyList && 'isOpenList')}
            />
          )}
      </div>

      {navigationState.isOpenCompanyList && (
        <SimpleBar className={classes.listScroll} forceVisible='y' data-testid='simplebar-scroll'>
          <div className={classes.list} data-testid='company-list'>
            <SearchItem />

            {companiesList?.map(({ name, iconUrl, id, localizationConfig }) => {
              // TODO: need check fields
              return (
                <CompanyItem
                  key={id}
                  name={name}
                  iconUrl={iconUrl}
                  onClick={() =>
                    handleCompanyClick({
                      subcompanyId: id,
                      subcompanyCountry: localizationConfig.country,
                      subcompanyCurrency: localizationConfig.currency!,
                      subcompanyLogoUrl: iconUrl,
                      subcompanyName: name,
                    })
                  }
                />
              );
            })}
          </div>
        </SimpleBar>
      )}
    </div>
  );
};

export default Company;
