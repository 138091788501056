import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    fontFamily: 'SFPro',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },

  impact: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  impactItem: {
    marginTop: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: '17px',
    color: palette.custom.smokyWhite,
  },

  impactName: {
    minWidth: 115,
    fontWeight: 600,
  },

  impactValue: {
    fontWeight: 300,
  },

  validate: {
    marginTop: 20,
  },

  validateWrap: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '&.disabled': {
      pointerEvents: 'none',
    },

    '& .validateYes, & .validateNo': {
      opacity: 0.3,
    },
  },

  hiddenCheckbox: {
    position: 'absolute',
    width: 0,
    height: 0,
    opacity: 0,

    '&:checked ~ .fakeValidate': {
      justifyContent: 'flex-start',
    },

    '&:checked ~ .validateYes': {
      opacity: 1,
    },

    '&:not(:checked) ~ .validateNo': {
      opacity: 1,
    },
  },

  fakeValidate: {
    padding: 2,
    margin: '0 10px',
    width: 52,
    height: 28,
    borderRadius: 28,
    backgroundColor: theme.palette.primary.light,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  circle: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    backgroundColor: palette.custom.bluePrimary,
  },

  label: {
    marginBottom: 10,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '14px',
    color: theme.palette.primary.main,
    opacity: 0.3,
  },

  labelWithoutMargin: {
    marginBottom: -15,
  },

  management: {
    marginRight: 35,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  managementTitle: {
    marginBottom: 22,
    fontWeight: 400,
    fontSize: 21,
    color: theme.palette.primary.main,
  },

  managementForm: {
    display: 'grid',
    gridTemplateColumns: '120px 105px 105px 170px 130px',
    gridTemplateAreas: `'callDriver callAmbulance insurance cargo .'
                        'callDriver driverHealth passengerHealth note btn'
                        `,
    gridColumnGap: 35,
    gridRowGap: 25,
  },

  callDriver: {
    gridArea: 'callDriver',
    overflow: 'hidden',

    '& > div:nth-child(3)': {
      marginTop: 90,
    },
  },

  callAmbulance: {
    gridArea: 'callAmbulance',
  },

  insurance: {
    gridArea: 'insurance',
  },

  cargo: {
    gridArea: 'cargo',
  },

  noteWrap: {
    gridArea: 'note',
  },

  note: {
    border: '1px solid #3F4A66',
    borderRadius: '7px',

    '& > div': {
      backgroundColor: 'transparent',
    },
  },

  driverHealth: {
    gridArea: 'driverHealth',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },

  passengerHealth: {
    gridArea: 'passengerHealth',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
