import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  speedMarker: {
    paddingRight: 8,
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'SF Pro Text',
    fontStyle: 'normal',
    color: palette.custom.black,
    backgroundColor: theme.palette.primary.light,
    transform: 'translate(-16px, -16px)',
    borderRadius: 16,
  },

  maxSpeed: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    border: `2px solid ${palette.custom.redSecondary}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    fontSize: 10,
  },

  currentSpeed: {
    marginLeft: 3,
    fontWeight: 400,
    fontSize: 12,
  },
}));

export default useStyles;
