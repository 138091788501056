import Button from '@mui/material/Button';
import cx from 'classnames';
import React, { useEffect, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import SimpleBar from 'simplebar-react';

import shortLogo from 'assets/images/menu-logo-short.svg';
import logo from 'assets/images/menu-logo.svg';

import RouteManager from 'utils/services/route-manager';

import navigationOptionsAtom from 'recoil/navigation';
import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';
import userDataAtom from 'recoil/userData';

import NewAvatar from 'components/NewAvatar';

import Company from './company';
import { NAVIGATION, INavigationItem } from './data';
import useStyles from './styles';

const Navigation = () => {
  const classes = useStyles();
  const { t } = useTranslation('nav.page');
  const location = useLocation();
  const navigate = useNavigate();
  const userData = useRecoilValue(userDataAtom);
  const [navigationState, setNavigationState] = useRecoilState(navigationOptionsAtom);
  const resetNavigationState = useResetRecoilState(navigationOptionsAtom);
  const technicalMessageBlockHeight = useRecoilValue(technicalMessageBlockHeightSelector);

  const handleMenuItem = (event: MouseEvent<HTMLButtonElement>, item: INavigationItem) => {
    event.stopPropagation();

    const isLogout = item.value === 'logout';

    if (isLogout) {
      localStorage.clear();
      sessionStorage.clear();
      navigate(RouteManager.makeURL('login'));
    } else {
      navigate(item.value);
    }
  };

  const pathname = location.pathname.substring(1);

  const userNameFull = `${userData!.firstName} ${userData!.lastName}`;

  const handleOpenMenu = () =>
    setNavigationState((prevState) => ({
      ...prevState,
      isOpenNav: true,
    }));

  useEffect(() => {
    return () => resetNavigationState();
  }, [resetNavigationState]);

  return (
    <div
      onClick={handleOpenMenu}
      className={cx(classes.nav, {
        minimized: !navigationState.isOpenNav,
        withOpenSearch: navigationState.isOpenSearch,
      })}
      style={{
        height: `calc(100vh - ${technicalMessageBlockHeight}px)`,
      }}
      data-testid='nav-wrapper'
    >
      <SimpleBar
        className={classes.scrollbar}
        style={{
          height: `calc(100vh - ${technicalMessageBlockHeight}px)`,
        }}
      >
        <Company />

        <div className={classes.user} data-testid='nav-user'>
          <div className={classes.avatar} data-testid='nav-avatar'>
            <NewAvatar logo={userData!.photoUrl ?? ''} fullscreen />
          </div>
          <span className={classes.name} data-testid='nav-username'>
            {userNameFull}
          </span>
        </div>
        <div className={classes.menu} data-testid='nav-menu'>
          {NAVIGATION(t, userData!.role).map((item) => {
            if (!item) {
              return null;
            }
            const Icon = item.icon;

            return (
              <div
                key={item.name}
                className={cx(classes.menuItem, {
                  active: item.pageName.includes(pathname),
                })}
                data-testid='nav-menuitem'
              >
                <Button onClick={(event) => handleMenuItem(event, item)}>
                  <>
                    <Icon />
                    <span className={classes.menuItemName}>{item.name}</span>
                  </>
                </Button>
              </div>
            );
          })}
        </div>

        <img
          src={navigationState.isOpenNav ? logo : shortLogo}
          alt='Logo'
          className={classes.logo}
          data-testid='nav-logo'
        />
      </SimpleBar>
    </div>
  );
};

export default Navigation;
