import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '1440px',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    padding: '0 122px 85px',
    boxSizing: 'border-box',
  },

  form: {
    width: '100%',
    maxWidth: '1196px',
    position: 'relative',
    border: '6px solid #2D3751',
    boxSizing: 'border-box',
    borderRadius: '6.11839px',
    padding: '40px 44px 20px 41px',
  },

  block: {
    display: 'flex',
    flexDirection: 'row',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },

  blockRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',

    '&:nth-child(1)': {
      maxWidth: '349px',
      minWidth: '349px',
      marginRight: '49px',

      [theme.breakpoints.down('md')]: {
        width: '100%',
        maxWidth: '100%',
        marginRight: 0,
      },
    },

    '&:nth-child(2)': {
      maxWidth: '349px',
      marginRight: '49px',

      [theme.breakpoints.down('md')]: {
        width: '100%',
        maxWidth: '100%',
        marginRight: 0,
      },
    },

    '&:nth-child(3)': {
      maxWidth: '311px',

      [theme.breakpoints.down('md')]: {
        width: '100%',
        maxWidth: '100%',
      },
    },
  },

  text: {
    paddingTop: '10px',
    marginBottom: '38px',

    '& > div': {
      color: theme.palette.primary.main,
      fontFamily: 'SFText',

      '&:nth-child(1)': {
        fontWeight: 400,
        fontSize: 25,
        lineHeight: '27.33px',
      },

      '&:nth-child(2)': {
        fontWeight: 700,
        fontSize: 26,
        lineHeight: '28.43px',
      },
    },
  },

  textAreaWrapper: {
    width: '100%',
  },

  area: {
    width: '100%',
    color: theme.palette.primary.dark,
    background: palette.custom.lightBlue,
    marginTop: '20px',
    borderRadius: '6.11839px',

    '& > div': {
      color: theme.palette.primary.dark,
    },

    '#filled-multiline-static': {
      color: theme.palette.primary.dark,
    },
  },

  button: {
    width: '100%',
    height: 52,
    marginTop: '28px',
    border: `1px solid ${palette.custom.goldSecondary}`,
    borderRadius: '7px',
    background: 'transparent',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '19px',
    color: theme.palette.primary.main,
    textTransform: 'initial',
    cursor: 'pointer',
  },
}));

export default useStyles;
