import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { StyledEngineProvider } from '@mui/material/styles';
import cx from 'classnames';
import { endOfMonth, endOfYear, startOfMonth, startOfYear } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { LANGUAGES } from 'interfaces/common/languages';
import { IUserData } from 'interfaces/user/user-data';

import { MONTHS } from 'constants/translates';

import { ReactComponent as Arrow } from 'assets/images/drop-down.svg';
import { ReactComponent as Refresh } from 'assets/images/refresh.svg';

import { convertToUtcTime } from 'utils/helpers/convert-to-utc-time';

import savingFilterAtom from 'recoil/saving/filter';
import userDataAtom from 'recoil/userData';

import { defaultFilter } from './data';
import useStyles from './styles';

const Filter = () => {
  const classes = useStyles();

  const [filter, setFilter] = useState<{ years: string; month: string }>(defaultFilter);

  const setSavingFilter = useSetRecoilState(savingFilterAtom);
  const resetSavingFilter = useResetRecoilState(savingFilterAtom);
  const { language } = useRecoilValue(userDataAtom) as IUserData;

  useEffect(() => {
    if (filter.years === 'all') {
      resetSavingFilter();
      return;
    }

    if (filter.month === 'full') {
      const year = new Date(filter.years);

      setSavingFilter({
        finishAt: convertToUtcTime(endOfYear(year)),
        startAt: convertToUtcTime(startOfYear(year)),
      });

      return;
    }

    const date = new Date(+filter.years, +filter.month);

    setSavingFilter({
      finishAt: convertToUtcTime(endOfMonth(date)),
      startAt: convertToUtcTime(startOfMonth(date)),
    });
  }, [filter, resetSavingFilter, setSavingFilter]);

  const hasFilterChanges = useMemo(() => defaultFilter !== filter, [filter]);

  const monthsFilterItems = useMemo(() => {
    const fullTitle = language === LANGUAGES.EN ? 'Full year' : 'Tam il';
    const months = MONTHS.full[language].map((item, i) => {
      return {
        name: item,
        value: i,
      };
    });

    return [{ name: fullTitle, value: 'full' }, ...months];
  }, [language]);

  const yearsFilterItems = useMemo(() => {
    const end = new Date('2020').getFullYear();
    const start = new Date().getFullYear();
    const allTimeTitle = language === LANGUAGES.EN ? 'All time' : 'Hər zaman';

    const res = [
      {
        value: 'all',
        name: allTimeTitle,
      },
    ];

    for (let i = start; i >= end; i--) {
      res.push({
        value: String(i),
        name: String(i),
      });
    }

    return res;
  }, [language]);

  const handleChange = (name: 'years' | 'month', value: string) => {
    setFilter((prevState) => {
      if (name === 'years' && value === 'all') {
        return defaultFilter;
      }

      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  return (
    <div className={classes.wrap} data-testid='filter-main'>
      <StyledEngineProvider injectFirst>
        <Select
          classes={{
            select: classes.root,
          }}
          IconComponent={() => (
            <div
              className={cx(classes.arrow, { disabled: filter.years === 'all' })}
              data-testid='month-arrow-icon'
            >
              <Arrow />
            </div>
          )}
          value={filter.month}
          disabled={filter.years === 'all'}
          name='month'
          onChange={({ target: { value } }) => {
            handleChange('month', value);
          }}
          MenuProps={{
            PopoverClasses: { paper: classes.selectList },
          }}
          data-testid='month-select'
        >
          {monthsFilterItems.map(({ name, value }) => {
            return (
              <MenuItem key={value} value={value} data-testid={`month-item-${value}`}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </StyledEngineProvider>

      {hasFilterChanges && (
        <Refresh
          className={classes.refresh}
          onClick={() => setFilter(defaultFilter)}
          data-testid='refresh-icon'
        />
      )}

      <StyledEngineProvider injectFirst>
        <Select
          classes={{
            select: classes.root,
          }}
          value={filter.years}
          name='years'
          IconComponent={() => (
            <div className={classes.arrow} data-testid='year-arrow-icon'>
              <Arrow />
            </div>
          )}
          onChange={({ target: { value } }) => {
            handleChange('years', value);
          }}
          MenuProps={{
            PopoverClasses: { paper: classes.selectList },
          }}
          data-testid='year-select'
        >
          {yearsFilterItems.map(({ value, name }) => {
            return (
              <MenuItem value={value} key={value} data-testid={`year-item-${value}`}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </StyledEngineProvider>
    </div>
  );
};

export default Filter;
