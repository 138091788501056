/**
 * Enum representing the types of search that can be performed.
 * @enum {string}
 */
export enum SEARCHED_TYPES {
  COMPANY = 'COMPANY',
  DRIVER = 'DRIVER',
  IMEI = 'IMEI',
  PLATENUMBER = 'PLATENUMBER',
}
