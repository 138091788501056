import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: 10,
  },

  icon: {
    width: 24,
  },

  time: {
    textAlign: 'left',
  },
}));

export default useStyles;
