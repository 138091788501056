import cx from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { ITeamData } from 'interfaces/teams/i-team-data';
import { TTeamFormData } from 'interfaces/teams/team-form-data';

import { ReactComponent as EditIcon } from 'assets/images/pencil.svg';

import isLoadingTeamsAtom from 'recoil/settings/teams/is-loading-teams';
import isOpenCreateTeamAtom from 'recoil/settings/teams/is-open-create-team';
import selectedTeamAtom from 'recoil/settings/teams/selected-team';
import showAvailableMembersAtom from 'recoil/settings/teams/show-available-members';
import teamsListAtom from 'recoil/settings/teams/teams-list';
import teamsListPagesAtom from 'recoil/settings/teams/teams-pagination';
import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';

import { createTeamReq } from 'requests/be-service/teams-controller/create-team';
import { deleteTeamReq } from 'requests/be-service/teams-controller/delete-team';
import { teamsGetReq } from 'requests/be-service/teams-controller/get-all-teams';
import { editTeamReq } from 'requests/be-service/teams-controller/update-team';

import Drawer from 'components/drawer';
import Loader from 'components/Loader';

import ColumnHeader from '../column-header';
import CreateTeam from '../create-team';
import EditTeam from '../edit-team';

import useStyles from './styles';

const TeamsList = () => {
  const technicalMessageBlockHeight = useRecoilValue(technicalMessageBlockHeightSelector);
  const classes = useStyles();
  const { t } = useTranslation('teams.page');
  const [teams, setTeams] = useRecoilState(teamsListAtom);
  const [selectedTeam, setSelectedTeam] = useRecoilState(selectedTeamAtom);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingTeamsAtom);
  const [isOpenCreateTeam, setIsOpenCreateTeam] = useRecoilState(isOpenCreateTeamAtom);
  const [editTeam, setEditTeam] = useState<ITeamData | null>(null);
  const [{ totalPages, currentPage }, setTeamsListPages] = useRecoilState(teamsListPagesAtom);

  const setShowAvailableMembers = useSetRecoilState(showAvailableMembersAtom);

  /**
   * Get teams by filters
   */
  const getTeams = useCallback(
    async (page: number) => {
      try {
        setIsLoading(true);
        const res = await teamsGetReq(page);
        setTeamsListPages({ currentPage: res.number, totalPages: res.totalPages });
        setTeams((prev) => [...prev, ...res.content]);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setTeams, setTeamsListPages],
  );

  /**
   * Create new team and update teams list
   */
  const createTeam = useCallback(
    async (data: TTeamFormData) => {
      try {
        await createTeamReq(data.name);
        setIsOpenCreateTeam(false);
        setSelectedTeam(null);
        setTeams([]);
        await getTeams(0);
      } catch (e) {
        console.log(e);
      }
    },
    [getTeams, setIsOpenCreateTeam, setSelectedTeam, setTeams],
  );

  /**
   * Edit team and update teams list
   */
  const editTeamHandler = useCallback(
    async (data: TTeamFormData) => {
      if (!editTeam) return;

      try {
        await editTeamReq(editTeam.teamId, data.name);
        setEditTeam(null);
        setSelectedTeam(null);
        setTeams([]);
        await getTeams(0);
      } catch (e) {
        console.log(e);
      }
    },
    [editTeam, getTeams, setSelectedTeam, setTeams],
  );

  /**
   * Delete team and update teams list
   */
  const deleteTeamHandler = useCallback(async () => {
    if (!editTeam) return;

    try {
      await deleteTeamReq(editTeam.teamId);
      setEditTeam(null);
      setSelectedTeam(null);
      setTeams([]);
      await getTeams(0);
    } catch (e) {
      console.log(e);
    }
  }, [editTeam, getTeams, setSelectedTeam, setTeams]);

  /**
   * Init teams request
   */
  useEffect(() => {
    setTeams([]);
    getTeams(0);
  }, [getTeams, setTeams]);

  return (
    <div className={classes.wrap}>
      {isLoading && <Loader lightMode width={150} preventClick isBlock />}

      <div className={classes.header}>
        <ColumnHeader label={t('teams.list.label')} checkBoxData={{ show: false }} />
      </div>

      <div data-testid='teams-list'>
        <InfiniteScroll
          dataLength={teams.length}
          next={() => getTeams(currentPage + 1)}
          hasMore={currentPage < totalPages - 1}
          className={classes.list}
          loader={null}
          height={`calc(100vh - 220px - ${technicalMessageBlockHeight}px)`}
        >
          {!teams.length && !isLoading && (
            <div className={classes.emptyText}>
              <div onClick={() => setIsOpenCreateTeam(true)} data-testid='empty-label'>
                {t('teams.list.empty.label')}
              </div>
            </div>
          )}

          {teams?.map((team) => {
            return (
              <div
                className={cx(classes.row, { isSelected: selectedTeam?.teamId === team.teamId })}
                key={team.teamId}
                onClick={() => {
                  setShowAvailableMembers(false);
                  setSelectedTeam(team);
                }}
                data-testid={`team-item-${team.teamId}`}
              >
                <div className={classes.name} data-testid='team-name'>
                  {team.name} <span>{team.count}</span>
                </div>

                <EditIcon
                  className={classes.icon}
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditTeam(team);
                  }}
                  data-testid='button-edit'
                />
              </div>
            );
          })}
        </InfiniteScroll>
      </div>

      <Drawer onClose={() => setIsOpenCreateTeam(false)} open={isOpenCreateTeam} anchor='right'>
        <CreateTeam onClose={() => setIsOpenCreateTeam(false)} onSubmit={createTeam} />
      </Drawer>

      <Drawer onClose={() => setEditTeam(null)} open={Boolean(editTeam)} anchor='right'>
        {editTeam && (
          <EditTeam
            onClose={() => setEditTeam(null)}
            onSubmit={editTeamHandler}
            onDelete={deleteTeamHandler}
            team={editTeam}
          />
        )}
      </Drawer>
    </div>
  );
};

export default TeamsList;
