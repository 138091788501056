import { selector } from 'recoil';

import { TCompanyListItem } from 'interfaces/company/list-item';

import userDataAtom from '../../userData';
import subcompaniesReqGetAtom from '../request';

const sybcompaniesListWithCurrentAtom = selector<TCompanyListItem[] | null>({
  key: 'sybcompaniesListWithCurrentAtom',
  get: async ({ get }) => {
    const userData = get(userDataAtom);
    const subcompanies = await get(subcompaniesReqGetAtom);

    if (!userData) {
      return null;
    }

    const currentCompany = {
      id: userData.companyId,
      name: userData.companyName,
      iconUrl: userData.companyLogo,
      // TODO: need check this data
      localizationConfig: { ...userData.localizationConfig },
      price: userData.price,
    };

    return subcompanies ? [currentCompany, ...subcompanies] : [currentCompany];
  },
});

export default sybcompaniesListWithCurrentAtom;
