import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRecoilState, useRecoilValue } from 'recoil';

import dashboardSidebarIsAllSelectedAtom from 'recoil/dashboard-map/sidebar/is-all-selected';
import isOpenDashboardSidebarAtom from 'recoil/dashboard-map/sidebar/is-open';
import dashboardSidebarListTypeAtom from 'recoil/dashboard-map/sidebar/list-type';
import { SIDEBAR_TYPES } from 'recoil/dashboard-map/sidebar/list-type/atom';
import dashboardSidebarListViewTypeAtomAtom from 'recoil/dashboard-map/sidebar/list-view-type';
import dashboardSidebarSearchValueAtom from 'recoil/dashboard-map/sidebar/search-value';
import dashboardMapFiltersAtom from 'recoil/dashboard-map/toolbar/fitters';

import { getVehiclesSidebarReq } from 'requests/be-service/map-view-v2-controller/list';
import { getVehiclesSidebarBySearchReq } from 'requests/be-service/map-view-v2-controller/search';

import Checkbox from 'components/form-v2/checkbox';
import Loader from 'components/Loader';

import VehicleInfo from '../../vehicle-info';

import useStyles from './styles';

const VehicleList = ({ vehicleCheckboxHandler, watch, height = '100px', teamId = null }) => {
  const classes = useStyles();
  const { t } = useTranslation('dashboard.map.page');

  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardSidebarList, setDashboardSidebarList] = useState([]);
  const isSelectAll = useRecoilValue(dashboardSidebarIsAllSelectedAtom);
  const viewType = useRecoilValue(dashboardSidebarListViewTypeAtomAtom);
  const isOpenDashboardSidebar = useRecoilValue(isOpenDashboardSidebarAtom);
  const [filters] = useRecoilState(dashboardMapFiltersAtom);
  const [totalElements, setTotalElements] = useState(0);
  const listType = useRecoilValue(dashboardSidebarListTypeAtom);
  const searchValue = useRecoilValue(dashboardSidebarSearchValueAtom);

  /**
   * Get vehicles data
   * @type {(function(*): Promise<void>)|*}
   */
  const getVehicles = useCallback(
    async (currentPage) => {
      try {
        let response;
        if (listType !== SIDEBAR_TYPES.SEARCH) {
          response = await getVehiclesSidebarReq(filters.status, currentPage, teamId);
        } else {
          // TODO: need refactor
          if (searchValue.length >= 3) {
            response = await getVehiclesSidebarBySearchReq(searchValue);
          } else {
            response = {
              totalElements: 0,
              content: [],
            };
          }
        }
        setTotalElements(response.totalElements);
        setDashboardSidebarList((prevState) => [...prevState, ...response.content]);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [filters, listType, searchValue, teamId],
  );

  /**
   * Get initial list of vehicles
   */
  useEffect(() => {
    if (!isOpenDashboardSidebar) {
      return;
    }
    setIsLoading(true);
    setDashboardSidebarList([]);
    getVehicles(0);
  }, [getVehicles, isOpenDashboardSidebar, setDashboardSidebarList]);

  /**
   * Get additional data for vehicles list
   * @type {(function(): void)|*}
   */
  const fetchMoreData = useCallback(() => {
    getVehicles(page + 1);
    setPage((prev) => prev + 1);
  }, [getVehicles, page]);

  return (
    <InfiniteScroll
      dataLength={dashboardSidebarList.length}
      next={fetchMoreData}
      hasMore={dashboardSidebarList.length < totalElements}
      loader={<h4>Loading...</h4>}
      height={height}
      className={classes.list}
    >
      {isLoading && <Loader width={100} isBlock lightMode preventClick />}

      {!isLoading && !dashboardSidebarList.length && (
        <div className={classes.emptyLabel}>{t('no.data.label')}</div>
      )}

      {dashboardSidebarList?.map((item) => {
        const isSelected = watch('selectedItems')?.includes(item?.driver?.id);

        if (!item.driver) {
          return null;
        }

        return (
          <div key={item.driver.id} className={classes.item}>
            <Checkbox
              wrapClass={classes.checkbox}
              value={isSelectAll ? !isSelected : isSelected}
              onChange={() => vehicleCheckboxHandler(item.driver.id, isSelected)}
              testId='checkbox-vehicle-item'
            />
            <VehicleInfo
              viewType={viewType}
              wrapClass={classes.info}
              vehicleType={item.vehicle?.vehicleType}
              lastName={item.driver?.lastName}
              firstName={item.driver?.firstName}
              plateNumber={item.vehicle?.plateNumber}
              country={item.vehicle?.country}
              status={item.viewData?.status}
              brandName={item.vehicle?.brandName}
              modelName={item.vehicle?.modelName}
              scoring={item.viewData?.scoring}
            />
          </div>
        );
      })}
    </InfiniteScroll>
  );
};

VehicleList.propTypes = {};

VehicleList.defaultProps = {};

export default VehicleList;
