import queryString from 'query-string';

import { ISavingCircleData } from 'interfaces/saving/i-saving-circle-data';
import { ISavingFilter } from 'interfaces/saving/i-saving-filter';

import server from 'utils/server';

/**
 * Retrieves saving circle data from the server based on the given path and filter.
 *
 * @param {string} path - The path to the server endpoint.
 * @param {ISavingFilter} filter - The filter to apply to the circle data.
 * @returns {Promise<ISavingCircleData>} - A Promise that resolves to the retrieved circle data.
 */
export const getCircleData = async (
  path: string,
  filter: ISavingFilter,
): Promise<ISavingCircleData> => {
  const params = {
    ...filter,
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
  };

  return server.services.beService
    .get<ISavingCircleData>(`${path}?${queryString.stringify(params)}`)
    .then((res) => res.data);
};
