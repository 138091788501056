import ApexCharts, { ApexOptions } from 'apexcharts';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import Chart from 'react-apexcharts';
import { useRecoilValue } from 'recoil';

import { ReactComponent as PauseIcon } from 'assets/images/pause.svg';
import { ReactComponent as PlayIcon } from 'assets/images/play.svg';
import { palette } from 'assets/theme';

import tripPointsSelector from 'recoil/trip/points';
import tripTimelineSelector from 'recoil/trip/timeline';

import { ANIMATION_STATUS } from '../use-route-animation';

import { getOptions } from './options';
import useStyles from './styles';

interface IProps {
  tripId: string;
  setPosition: (positionIndex: number) => void;
  currentIndex: number;
  speed: number;
  animationStatus: ANIMATION_STATUS;
  stopAnimation: () => void;
  toggleAnimation: () => void;
}

const Timeline: FC<IProps> = ({
  tripId,
  setPosition,
  speed,
  currentIndex,
  animationStatus,
  stopAnimation,
  toggleAnimation,
}) => {
  const classes = useStyles();
  const data = useRecoilValue(tripTimelineSelector(tripId));
  const tripData = useRecoilValue(tripPointsSelector(tripId));
  const chartRef = useRef(null);

  const updateAnnotation = useCallback(
    (xValue: number, speed: number) => {
      const chart = ApexCharts.getChartByID('speed-chart');
      if (chart) {
        chart.clearAnnotations();

        chart.addPointAnnotation({
          x: new Date(tripData.points[xValue].timestamp as string).getTime(),
          y: speed,
          label: {
            borderColor: palette.custom.black,
            borderWidth: 2,
            borderRadius: 5,
            style: {
              fontSize: '16px',
              color: palette.custom.black,
              background: palette.custom.white,
              padding: {
                left: 5,
                right: 5,
                top: 5,
                bottom: 5,
              },
            },
            text: `${speed}`,
          },
        });
      }
    },
    [tripData.points],
  );

  useEffect(() => {
    updateAnnotation(currentIndex, speed);
  }, [currentIndex, speed, updateAnnotation]);

  const options = useMemo<ApexOptions>(() => {
    return getOptions(data.timestamp, data.index, setPosition);
  }, [data.index, data.timestamp, setPosition]);

  return (
    <div className={classes.wrap}>
      <div className={classes.animationBtns}>
        <div onClick={toggleAnimation} className={classes.playBtn}>
          {[ANIMATION_STATUS.STOP, ANIMATION_STATUS.PAUSE].includes(animationStatus) && (
            <PlayIcon />
          )}
          {animationStatus === ANIMATION_STATUS.PLAY && <PauseIcon />}
        </div>
        <div onClick={stopAnimation}>
          <div className={classes.stopBtn} />
        </div>
      </div>
      <Chart
        options={options}
        width={'100%'}
        height={'200px'}
        series={[{ data: data.speed, name: 'speed' }]}
        ref={chartRef}
      />
    </div>
  );
};

export default Timeline;
