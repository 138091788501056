import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  marker: {
    width: 'fit-content',
    height: 26,
    borderRadius: 13,
    backgroundColor: palette.custom.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 10,
  },

  label: {
    fontFamily: 'SFPro',
    fontWeight: 400,
    fontSize: 12,
    color: palette.custom.bluePrimary,
  },

  icon: {
    marginLeft: 5,
    width: 26,
    height: 26,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: palette.custom.bluePrimary,
    borderRadius: '50%',

    '& div': {
      width: 10,
      height: 10,
      backgroundColor: palette.custom.white,
    },
  },
}));

export default useStyles;
