import { atom } from 'recoil';

import { ITeamData } from 'interfaces/teams/i-team-data';

const teamsListAtom = atom<ITeamData[]>({
  key: 'teamsListAtom',
  default: [],
});

export default teamsListAtom;
