import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    rowGap: 5,
  },

  item: {
    width: 180,
    fontWeight: 400,
    fontSize: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    columnGap: 5,
    whiteSpace: 'nowrap',
  },

  label: {
    color: palette.custom.blue600,
  },

  value: {
    marginTop: 5,
    color: theme.palette.primary.light,
    fontWeight: 500,
    fontSize: 28,
  },
}));

export default useStyles;
