import { selector } from 'recoil';

import companyStructureReq from '../../requests/company-structure';

const companyStructureSelector = selector({
  key: 'companyStructureSelector',
  get: async () => {
    const data = await companyStructureReq();
    return data.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
  },
});

export default companyStructureSelector;
