import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },

  leftWrap: {
    display: 'flex',
    alignItems: 'center',
  },

  driverName: {
    fontWeight: 500,
    fontSize: 28,
    lineHeight: '33px',
    color: palette.custom.blue200,
    maxWidth: 700,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginRight: 16,
    cursor: 'pointer',
  },

  vehicleInfo: {
    '&.hasSensors:hover': {
      backgroundColor: palette.custom.blue900,
      borderColor: palette.custom.blue900,
    },
  },
}));

export default useStyles;
