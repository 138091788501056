import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    columnGap: 10,
    width: 'fit-content',
    cursor: 'pointer',

    '&.disabled': {
      opacity: 0.4,
      pointerEvents: 'none',
    },
  },

  checkbox: {
    minWidth: 24,
    width: 24,
    height: 24,
    border: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: 2,
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&.isChecked': {
      borderColor: palette.custom.goldSecondary,

      '& $checkedIcon': {
        opacity: 1,
      },
    },
  },

  checkedIcon: {
    width: 10,
    height: 10,
    backgroundColor: palette.custom.goldSecondary,
    opacity: 0,
    transition: 'all 0.3s ease',

    '&.isIndeterminate': {
      opacity: '0.5 !important',
    },
  },

  label: {
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 400,
    color: theme.palette.primary.main,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  hiddenInput: {
    position: 'absolute',
    width: 0,
    height: 0,
    opacity: 0,
    pointerEvents: 'none',
  },
}));

export default useStyles;
