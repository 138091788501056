import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

type Props = {
  technicalMessageBlockHeight: number;
};

const useStyles = makeStyles<Theme, Props>((theme) => ({
  container: {
    position: 'relative',
    color: theme.palette.primary.main,
    padding: '15px 47px 15px 60px',
  },

  arrowBack: {
    position: 'absolute',
    top: 60,
    left: 19,
    cursor: 'pointer',
  },

  map: {
    position: 'relative',
    width: '100%',
    marginLeft: 45,
    height: ({ technicalMessageBlockHeight }) =>
      `calc(100vh - 360px - ${technicalMessageBlockHeight}px)`,

    [theme.breakpoints.down('md')]: {
      marginTop: 40,
      marginLeft: 0,
      height: '80vh',
    },
  },

  layerBtn: {
    zIndex: 10,
    cursor: 'pointer',
    position: 'absolute',
    bottom: 30,
    right: 60,
  },

  row: {
    marginTop: 28,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },

  penalties: {
    width: '100%',
    maxWidth: 290,

    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },

  error: {
    position: 'absolute',
    bottom: 100,
    left: '50%',
    transform: 'translateX(-50%)',
  },

  sensorsBlock: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    bottom: 10,
    right: 10,
    zIndex: 20,
    padding: 10,
    backgroundColor: palette.custom.bluePrimary,
    borderRadius: 10,
    columnGap: 10,

    '&:empty': {
      display: 'none',
    },
  },

  sensorsTypeWrap: {
    maxHeight: 228,
    overflowX: 'hidden',
    overflowY: 'auto',
  },

  parameterWrap: {
    width: 280,
    marginBottom: 10,
  },
}));

export default useStyles;
