import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  fields: {
    width: '100%',
    marginLeft: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    color: theme.palette.primary.dark,

    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },

  field: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '2px solid transparent',
    borderRadius: 5,
    padding: 5,
    pointerEvents: 'none',

    '&.canClick': {
      pointerEvents: 'unset',
      cursor: 'pointer',
    },

    '&.maxSpeed.isActive': {
      border: '2px solid #D6A35E',
      background: 'rgba(214, 163, 94, 0.15)',
    },
  },

  fieldTitle: {
    marginBottom: '5px',
  },

  fieldCount: {
    fontSize: '31px',
    lineHeight: '37px',
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default useStyles;
