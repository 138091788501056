import { ISuccessBaseResponse } from 'interfaces/common/success-base-response';

import server from 'utils/server';

/**
 * Edit the avatar of a company.
 *
 * @async
 * @param {string} id - The ID of the company to edit.
 * @param {File} avatar - The new avatar file to upload.
 * @returns {Promise<ISuccessBaseResponse>} - A promise that resolves with the updated company photo information.
 */
export const editCompanyAvatarReq = async (
  id: string,
  avatar: File,
): Promise<ISuccessBaseResponse> => {
  const body = new FormData();

  body.append('file', avatar);

  return server.services.beService
    .put<ISuccessBaseResponse>(`companies/${id}/photo`, body)
    .then((res) => res.data);
};
