import { TCompanyListItem } from 'interfaces/company/list-item';

import server from 'utils/server';

/**
 * Retrieves the list of child companies for the given company ID.
 *
 * @param {string} companyId - The ID of the parent company.
 * @returns {Promise<TCompanyListItem[]>} A promise that resolves with an array of child companies.
 */
export const getCompanyChildrenReq = async (companyId: string): Promise<TCompanyListItem[]> =>
  server.services.beService
    .get<TCompanyListItem[]>(`companies/${companyId}/children`)
    .then((res) => res.data);
