import * as yup from 'yup';

import { isEmptyValue } from 'utils/helpers/is-empty-value';

export const formSchema = () => {
  return yup.object().shape({
    companyIds: yup.string().nullable(),
    userIds: yup.string().nullable(),
    teamIds: yup.string().nullable(),
    includeDisabled: yup.boolean(),
    createdAtFrom: yup.date().nullable(),
    createdAtTill: yup
      .date()
      .nullable()
      .when('createdAtFrom', ([other], schema) =>
        !isEmptyValue(other) ? schema.min(other) : schema,
      ),
  });
};

export type TSettingsDriversFilters = yup.InferType<ReturnType<typeof formSchema>>;
