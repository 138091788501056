import queryString from 'query-string';

import { IDriverData } from 'interfaces/driver/i-driver-data';

import server from 'utils/server';

/**
 * Retrieves all active drivers for a given company ID.
 *
 * @param {string} companyId - The ID of the company.
 * @returns {Promise<IDriverData[]>} - A promise that resolves to an array of driver data.
 */
export const getAllActiveDriversReq = async (companyId: string): Promise<IDriverData[]> =>
  server.services.beService
    .get<IDriverData[]>('drivers?' + queryString.stringify({ companyId }))
    .then((res) => res.data);
