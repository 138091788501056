import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

type Props = {
  isLightBackground: boolean;
};

const useStyles = makeStyles<Theme, Props>((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  root: {
    padding: 0,
    '&:hover': {
      background: 'initial',
    },
  },

  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 30,
    background: ({ isLightBackground }) =>
      isLightBackground ? palette.custom.lightBlue : palette.custom.bluePrimary,
    borderRadius: 8,
  },

  outlinedIcon: {
    border: `1px solid ${palette.custom.borderDarkGrey}`,
  },

  small: {
    width: 24,
    height: 21,
    '& svg': {
      width: 12.6,
      height: 9.7,
    },
  },

  large: {
    width: 40,
    height: 30,
  },

  extra: {
    width: 41,
    height: 41,
    backgroundColor: palette.custom.lightBlue,
    borderRadius: 0,
  },

  label: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 400,
    color: theme.palette.primary.main,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  largeLabel: {
    fontSize: 16,
    lineHeight: '19px',
  },

  extraLabel: {
    fontSize: 16,
    lineHeight: '19px',
    marginTop: '4px',
    color: theme.palette.primary.main,
    marginLeft: '22px',
  },

  smallLabel: {
    fontSize: 12,
    lineHeight: '14px',
  },
}));

export default useStyles;
