export enum PENALTIES {
  ACCELERATION = 'ACCELERATION',
  SPEED_10 = 'SPEED_10',
  SPEED_20 = 'SPEED_20',
  BRAKING = 'BRAKING',
  CORNERING = 'CORNERING',
  CRASH = 'CRASH',
  GEOFENCING = 'GEOFENCING',
  TOTAL = 'TOTAL',
}
