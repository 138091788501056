import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  delta: {
    display: 'inline-flex',
    alignItems: 'center',
    height: '32px',
    color: theme.palette.primary.main,
  },

  value: {
    fontSize: '32px',
    lineHeight: '32px',
    fontWeight: 500,
  },

  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '8px',

    '& > svg': {
      width: '12px',
    },
  },

  reversed: {
    flexDirection: 'column-reverse',

    '& > svg': {
      transform: 'rotate(180deg)',
    },
  },

  itemValue: {
    fontSize: '12px',
    lineHeight: '14px',
  },
}));

export default useStyles;
