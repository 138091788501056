import { atom } from 'recoil';

const driverShortDataAtom = atom({
  key: 'driverShortDataAtom',
  default: {
    driverId: null,
    companyId: null,
    basic: 0,
    bonus: 0,
    currentScoring: 0,
    dailyH: 0,
    dailyKm: 0,
    diffScoring: 0,
    firstName: '',
    imei: null,
    indicator: false,
    lastName: '',
    penalties: 0,
    rating: false,
    vehicle: {
      id: '',
      plateNumber: '',
    },
  },
});

export default driverShortDataAtom;
