import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
  },

  inputContent: {
    position: 'relative',
    width: '100%',
  },

  content: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: 10,
    transform: 'translateY(-50%)',
    zIndex: 10,
    columnGap: 10,
    pointerEvents: 'none',
  },

  colorBox: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.primary.light}`,
    backgroundColor: 'transparent',
  },

  textBox: {
    color: palette.custom.blue300,
  },

  inputWrap: {
    cursor: 'pointer',
    backgroundColor: palette.custom.blue900,
    borderRadius: 5,
    padding: '3px 0',
    border: `1px solid ${palette.custom.blue900}`,
    width: '100%',
  },

  inputWrapFocus: {
    border: `1px solid ${palette.custom.blue800}`,
  },

  inputWrapError: {
    border: `1px solid ${palette.custom.redSecondary}`,
  },

  input: {
    opacity: 0,
    cursor: 'pointer',

    '&::placeholder': {
      color: palette.custom.blue300,
      opacity: 0.1,
    },
  },

  inputDisabled: {
    cursor: 'default',
    backgroundColor: palette.custom.hoverBlue,
    border: `1px solid ${palette.custom.bluePrimary}`,

    '& .MuiInputBase-input.Mui-disabled': {
      opacity: 0,
    },
  },

  endAdornment: {
    fontFamily: 'SFPro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: palette.custom.blue800,
    marginLeft: 5,
  },
}));

export default useStyles;
