import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import cx from 'classnames';
import React from 'react';

import { FCC } from 'interfaces/common/fc-with-children';

import useStyles from './styles';

interface IBadge {
  disabled: boolean;
  type: 'fill' | 'outline';
  handleRemove: () => void;
  testId?: string;
}

const Badge: FCC<IBadge> = ({
  children,
  handleRemove,
  testId,
  disabled = false,
  type = 'fill',
}) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.badge, type)} data-testid={testId}>
      <span className={classes.value}>{children}</span>
      {handleRemove && !disabled && (
        <IconButton size='small' onClick={handleRemove}>
          <ClearIcon classes={{ root: cx(classes.rootIcon, type) }} />
        </IconButton>
      )}
    </div>
  );
};

export default Badge;
