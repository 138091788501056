import { Alert, AlertTitle } from '@mui/lab';
import * as turf from '@turf/turf';
import GoogleMapReact, { fitBounds } from 'google-map-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { mapDefaultSetting } from 'constants/map';
import penaltyLabels from 'constants/penalty-labels';
import sensorAlarmsTypes from 'constants/sensor-alarms';

import { ReactComponent as ArrowBack } from 'assets/images/arrow-back.svg';

import { calculateGeofencing, createDriverTripInfo, isEmptyObj } from 'utils/helpers';
import { getTripDirectDistance } from 'utils/helpers/getTripDirectDistance';
import { isEmptyValue } from 'utils/helpers/is-empty-value';
import RouteManager from 'utils/services/route-manager';

import driverShortDataAtom from 'recoil/driver/driverShortData';
import driverTripAtom from 'recoil/driver/driverTrip';
import showDriverTripMaxSpeedMarkerAtom from 'recoil/driver/show-trip-max-speed';
import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';
import tripSensorsAlarmsAtom from 'recoil/trip/sensors-alarms';
import useTripsDay from 'recoil/tripsDayRequests/useTripsDay';
import userConfigAtom from 'recoil/user-config';
import vehicleSensorsListAtom from 'recoil/vehicles/vehicleSensorsList';

import { getPenaltiesPointsReq } from 'requests/be-service/map-view-controller/get-trip-violations';
import { getSensorAlarmForTrip } from 'requests/gps-service/sensor-controller/get-alarm-by-trip';

import Loader from 'components/Loader';
import Layer from 'components/Map/Layer';
import SensorParameterView from 'components/sensor-parameter-view';
import FinishMarker from 'components/TripComponents/FinishMarker';
import Header from 'components/TripComponents/Header';
import Penalties from 'components/TripComponents/Penalties';
import PenaltyMarker from 'components/TripComponents/PenaltyMarker';
import PenaltySpeedMarker from 'components/TripComponents/PenaltySpeedMarker';
import SensorAlarmMarker from 'components/TripComponents/sensor-alarm-marker';
import Sensors from 'components/TripComponents/sensors';
import StartMarker from 'components/TripComponents/StartMarker';

import SpeedMarker from '../../components/TripComponents/speed-marker';

import useStyles from './styles';

const TripsDay = () => {
  const technicalMessageBlockHeight = useRecoilValue(technicalMessageBlockHeightSelector);
  const classes = useStyles({ technicalMessageBlockHeight });
  const { id, date } = useParams();
  const navigate = useNavigate();

  const showMaxSpeedMarker = useRecoilValue(showDriverTripMaxSpeedMarkerAtom);
  const [driverShortData, setDriverShortData] = useRecoilState(driverShortDataAtom);
  const userConfig = useRecoilValue(userConfigAtom);
  const [driverTrip, setDriverTrip] = useRecoilState(driverTripAtom);
  const resetDriverTrip = useResetRecoilState(driverTripAtom);
  const [activePoints, setActivePoints] = useState(null);
  const [tripPoints, setTripPoints] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const [mapSize, setMapSize] = useState({ width: 0, height: 0 });
  const [distanceMap, setDistanceMap] = useState(null);
  const [activePenalties, setActivePenalties] = useState([]);
  const [penaltiesPoints, setPenaltiesPoints] = useState(null);
  const [penaltiesRef, setPenaltiesRef] = useState(null);
  const [isSatellite, setIsSatellite] = useState(false);
  const [activeSensorsAlarms, setActiveSensorsAlarms] = useState(null);
  const [tripSensorsAlarms, setTripSensorsAlarms] = useRecoilState(tripSensorsAlarmsAtom);
  const resetTripSensorsAlarms = useResetRecoilState(tripSensorsAlarmsAtom);
  const resetSensorsList = useResetRecoilState(vehicleSensorsListAtom);
  const mapRef = useRef(null);
  const routeRef = useRef([]);
  const [googleMap, setGoogleMap] = useState(null);

  useTripsDay(id, date, setTripPoints, setDriverShortData, setFetching, setError);

  /**
   * Get trip points and info from API
   */
  useEffect(() => {
    const getTripPoints = async () => {
      try {
        if (!activePoints && activePoints !== 0) {
          setPenaltiesPoints({
            SPEED_10: [],
            SPEED_20: [],
            ACCELERATION: [],
            BRAKING: [],
            CORNERING: [],
            CRASH: [],
          });
          return;
        }
        const result = await getPenaltiesPointsReq(tripPoints[activePoints]?.trip?.id);

        const res = createDriverTripInfo(tripPoints[activePoints], userConfig.isMetricSystem);
        const newStatistics = {
          ...res.statistics,
          geofencing: calculateGeofencing(res.statistics.distance, distanceMap[activePoints]),
        };
        setPenaltiesPoints(result);
        setDriverTrip({ ...res, statistics: newStatistics });
        setActiveSensorsAlarms(null);
        const sensorsRes = await getSensorAlarmForTrip(tripPoints[activePoints]?.trip?.id);
        setTripSensorsAlarms(sensorsRes);
      } catch (e) {
        setError(e);
      }
    };

    getTripPoints();
  }, [activePoints, distanceMap, setDriverTrip, setTripSensorsAlarms, tripPoints, userConfig]);

  /**
   * Get map client width and height
   */
  useEffect(() => {
    setMapSize({
      width: mapRef.current.offsetWidth,
      height: mapRef.current.offsetHeight,
    });
    return () => {
      resetDriverTrip();
      setActivePoints(null);
    };
  }, [resetDriverTrip]);

  /**
   * Clearing data
   */
  useEffect(() => {
    return () => {
      resetSensorsList();
      resetTripSensorsAlarms();
    };
  }, [resetSensorsList, resetTripSensorsAlarms]);

  /**
   * Create map options
   */
  const createMapOptions = () => ({
    fullscreenControl: false,
    mapTypeId: isSatellite ? 'hybrid' : 'roadmap',
    styles: [{ stylers: [{ visibility: 'on' }] }],
  });

  /**
   * Get trip start coordinates
   */
  const startMarkerCoordinates = useMemo(() => {
    if (!tripPoints || isEmptyObj(tripPoints)) {
      return;
    }
    return tripPoints[0].points[0];
  }, [tripPoints]);

  /**
   * Get trip finish coordinates
   */
  const finishMarkerCoordinates = useMemo(() => {
    if (!tripPoints || isEmptyObj(tripPoints)) {
      return;
    }
    const tripsLength = Object.keys(tripPoints).length;

    return tripPoints[tripsLength - 1]?.points[tripPoints[tripsLength - 1].points.length - 1];
  }, [tripPoints]);

  /**
   * Get trips start all coordinates
   */
  const newStartMarkerCoordinates = useMemo(() => {
    if (!tripPoints || isEmptyObj(tripPoints)) {
      return;
    }
    const startPointsAcc = [];
    for (let key in tripPoints) {
      startPointsAcc.push(tripPoints[key].points[0]);
    }

    return startPointsAcc;
  }, [tripPoints]);

  /**
   * Get trips finish all coordinates
   */
  const newFinishMarkerCoordinates = useMemo(() => {
    if (!tripPoints || isEmptyObj(tripPoints)) {
      return;
    }
    const startPointsAcc = [];
    for (let key in tripPoints) {
      startPointsAcc.push(tripPoints[key].points[tripPoints[key].points.length - 1]);
    }

    return startPointsAcc;
  }, [tripPoints]);

  /**
   * Get map center and zoom settings
   */
  const mapSettings = useMemo(() => {
    if (!tripPoints || isEmptyObj(tripPoints)) {
      return {
        center: mapDefaultSetting.center,
        zoom: mapDefaultSetting.zoom,
      };
    }

    const pointsAcc = [];
    for (let key in tripPoints) {
      tripPoints[key].points.map((point) => pointsAcc.push([point.longitude, point.latitude]));
    }

    const line = turf.lineString(pointsAcc);

    const bbox = turf.bbox(line);

    const bounds = {
      nw: {
        lng: bbox[0],
        lat: bbox[1],
      },
      se: {
        lng: bbox[2],
        lat: bbox[3],
      },
    };

    const { center, zoom } = fitBounds(bounds, mapSize);

    return {
      center,
      zoom,
    };
  }, [mapSize, tripPoints]);

  /**
   * Points for active penalties
   */
  const activePenaltiesPoints = useMemo(() => {
    return activePenalties
      ?.map((penalty) => {
        return penaltiesPoints[penalty].map((point) => {
          return {
            ...point,
            label: penaltyLabels[penalty],
          };
        });
      })
      .flat();
  }, [activePenalties, penaltiesPoints]);

  /**
   * Points for active sensors alarms
   */
  const activeSensorsAlarmsPoints = useMemo(() => {
    return activeSensorsAlarms
      ?.map((alarm) => {
        return tripSensorsAlarms[alarm].map((point) => {
          return {
            ...point,
            label: sensorAlarmsTypes[alarm],
          };
        });
      })
      .flat();
  }, [activeSensorsAlarms, tripSensorsAlarms]);

  /**
   * Active sensors alarms blocks
   */
  const activeSensorsAlarmsBlocks = useMemo(() => {
    return activeSensorsAlarms?.map((alarm) => {
      return tripSensorsAlarms[alarm].map((point) => {
        return {
          ...point,
          label: sensorAlarmsTypes[alarm],
        };
      });
    });
  }, [activeSensorsAlarms, tripSensorsAlarms]);

  const styles = useMemo(
    () => ({
      map: { minHeight: penaltiesRef?.offsetHeight ?? 0 },
    }),
    [penaltiesRef?.offsetHeight],
  );

  /**
   * Draw trip polyline and google direction
   */
  const handleApiLoaded = useCallback(
    (map, maps) => {
      setGoogleMap({ map, maps });
      const directionsService = new maps.DirectionsService();
      /**
       * All trips in one array
       */
      const allTripsPoints = [];
      for (let key in tripPoints) {
        tripPoints[key].points.map((point) => allTripsPoints.push(point));
      }

      const middlePoint = allTripsPoints[Math.floor(allTripsPoints.length / 2)];
      const middle = `${middlePoint.latitude},${middlePoint.longitude}`;
      const minDistance = 0.001; // the minimum distance at which an intermediate point is not added
      const arrGeofencing = [];

      newFinishMarkerCoordinates.map((_, i) => {
        const start = `${newStartMarkerCoordinates[i].latitude},${newStartMarkerCoordinates[i].longitude}`;
        const finish = `${newFinishMarkerCoordinates[i].latitude},${newFinishMarkerCoordinates[i].longitude}`;
        const distance = getTripDirectDistance(
          newStartMarkerCoordinates[i],
          newFinishMarkerCoordinates[i],
        );

        const directionOptions = {
          origin: {
            query: start,
          },
          destination: {
            query: finish,
          },
          travelMode: maps.TravelMode.DRIVING,
        };
        if (distance <= minDistance) {
          directionOptions.waypoints = [{ location: middle, stopover: false }];
        }

        if (driverShortData?.vehicle?.country !== 'AZ') {
          directionsService
            .route(directionOptions)
            .then((response) => {
              arrGeofencing.push(response.routes[0].legs[0].distance.value);
            })
            .catch((e) => {
              console.log(e);
            });
        }
      });
      setDistanceMap(arrGeofencing);

      // Drawing snapped trips on the map
      for (let key in tripPoints) {
        const route = tripPoints[key].points.map(
          (item) => new maps.LatLng(item.latitude, item.longitude),
        );

        routeRef.current.push(
          new maps.Polyline({
            path: route,
            map,
            geodesic: false,
            strokeWeight: 3,
          }),
        );
      }
    },
    [
      driverShortData?.vehicle?.country,
      newFinishMarkerCoordinates,
      newStartMarkerCoordinates,
      tripPoints,
    ],
  );

  const toggleMapType = useCallback(() => setIsSatellite((prevState) => !prevState), []);

  const mapBootstrapURLKeys = useMemo(() => {
    const config = {
      key: process.env.REACT_APP_GOOGLE_MAP_KEY,
      version: 'beta',
      libraries: ['marker'],
      mapIds: [process.env.REACT_APP_GOOGLE_MAP_ID],
    };

    if (userConfig.country === 'AZ') {
      config.region = 'AZ';
    }

    return config;
  }, [userConfig]);

  useEffect(() => {
    if (routeRef.current) {
      routeRef.current.forEach((item) =>
        item.setOptions({ strokeColor: isSatellite ? '#fff' : '#000' }),
      );
    }
  }, [isSatellite]);

  useEffect(() => {
    if (isEmptyValue(activePoints) || !googleMap) return;

    routeRef.current.forEach((item) => item.setMap(null));
    routeRef.current = [];
    for (let key in tripPoints) {
      const route = tripPoints[key].points.map(
        (item) => new googleMap.maps.LatLng(item.latitude, item.longitude),
      );

      routeRef.current.push(
        new googleMap.maps.Polyline({
          path: route,
          map: googleMap.map,
          geodesic: false,
          strokeWeight: 3,
          strokeColor: isSatellite ? '#fff' : '#000',
          zIndex: key,
        }),
      );
    }

    const route = tripPoints[activePoints].points.map(
      (item) => new googleMap.maps.LatLng(item.latitude, item.longitude),
    );

    routeRef.current.push(
      new googleMap.maps.Polyline({
        path: route,
        map: googleMap.map,
        geodesic: false,
        strokeWeight: 3,
        strokeColor: '#f00',
        zIndex: routeRef.current.length,
      }),
    );
  }, [activePoints, googleMap, isSatellite, tripPoints]);

  if (fetching) {
    return <Loader lightMode={true} />;
  }

  if (error) {
    return (
      <div className={classes.container}>
        <div className={classes.error}>
          <Alert severity='error'>
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Link
        to={'..'}
        onClick={(e) => {
          e.preventDefault();
          navigate(RouteManager.makeURL('drivers.trips', { id }));
        }}
        data-testid='button-back'
      >
        <ArrowBack className={classes.arrowBack} />
      </Link>

      <Header date={date.split('-').join('.')} />

      <div className={classes.row}>
        <div className={classes.penalties}>
          <div ref={setPenaltiesRef}>
            <Penalties
              country={driverShortData?.vehicle?.country}
              activePenalties={activePenalties}
              setActivePenalties={setActivePenalties}
              penaltiesPoints={penaltiesPoints}
            />
          </div>

          <Sensors
            activePenalties={activeSensorsAlarms}
            setActivePenalties={setActiveSensorsAlarms}
          />
        </div>

        <div className={classes.map} ref={mapRef} style={styles.map}>
          {tripPoints && startMarkerCoordinates && finishMarkerCoordinates && (
            <>
              <div className={classes.layerBtn}>
                <Layer isSatellite={isSatellite} onClick={toggleMapType} />
              </div>
              <GoogleMapReact
                options={createMapOptions}
                bootstrapURLKeys={mapBootstrapURLKeys}
                defaultCenter={mapDefaultSetting.center}
                defaultZoom={mapDefaultSetting.zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                center={mapSettings.center}
                zoom={mapSettings.zoom}
              >
                {newStartMarkerCoordinates &&
                  newStartMarkerCoordinates.map((item, number) => {
                    return (
                      <StartMarker
                        key={number}
                        lat={item.latitude}
                        lng={item.longitude}
                        number={number}
                        active={activePoints}
                        setActivePoints={setActivePoints}
                      />
                    );
                  })}

                {finishMarkerCoordinates && (
                  <FinishMarker
                    lat={finishMarkerCoordinates.latitude}
                    lng={finishMarkerCoordinates.longitude}
                  />
                )}

                {showMaxSpeedMarker && (
                  <SpeedMarker
                    currentSpeed={driverTrip.maxSpeedPoint.currentSpeed}
                    maxSpeed={driverTrip.maxSpeedPoint.maxSpeed}
                    lat={driverTrip.maxSpeedPoint.latitude}
                    lng={driverTrip.maxSpeedPoint.longitude}
                  />
                )}

                {activePenaltiesPoints?.map(
                  ({ latitude, longitude, label, timestamp, currentSpeed, maxSpeed }, i) => {
                    if (
                      [penaltyLabels.SPEED_10, penaltyLabels.SPEED_20].includes(label) &&
                      currentSpeed &&
                      maxSpeed
                    ) {
                      return (
                        <PenaltySpeedMarker
                          currentSpeed={currentSpeed}
                          maxSpeed={maxSpeed}
                          lat={latitude}
                          lng={longitude}
                          key={timestamp}
                        />
                      );
                    }
                    return (
                      <PenaltyMarker
                        label={label}
                        key={`key-${timestamp}-${i}`}
                        lat={latitude}
                        lng={longitude}
                      />
                    );
                  },
                )}

                {activeSensorsAlarmsPoints?.map((point) => {
                  return (
                    <SensorAlarmMarker
                      label={point.label}
                      lat={point.latitude}
                      lng={point.longitude}
                      key={`${point.latitude}-${point.longitude}-${point.label}`}
                    />
                  );
                })}
              </GoogleMapReact>

              <div className={classes.sensorsBlock}>
                {activeSensorsAlarmsBlocks?.map((activeSensorsAlarmsBlock) => (
                  <div className={classes.sensorsTypeWrap} key={activeSensorsAlarmsBlock[0].label}>
                    {activeSensorsAlarmsBlock?.map((parameter) => (
                      <SensorParameterView
                        key={parameter.timestamp}
                        min={parameter.from}
                        max={parameter.to}
                        current={parameter.value}
                        type={parameter.label}
                        wrapClass={classes.parameterWrap}
                        date={parameter.timestamp}
                      />
                    ))}
                  </div>
                ))}
              </div>
            </>
          )}

          {(!tripPoints || isEmptyObj(tripPoints)) && (
            <div>Sorry, something went wrong. We can't display the route</div>
          )}
        </div>
      </div>
    </div>
  );
};

TripsDay.defaultProps = {};

TripsDay.propTypes = {};

export default TripsDay;
