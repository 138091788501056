import {
  subMonths,
  startOfMonth,
  endOfMonth,
  format,
  subWeeks,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
} from 'date-fns';
import { TFunction } from 'react-i18next';

const currentDate = new Date();

const lastMonth = subMonths(currentDate, 1);
const lastMonthFirstDay = startOfMonth(lastMonth);
const lastMonthLastDay = endOfMonth(lastMonth);

const currentMonthFirstDay = startOfMonth(currentDate);

const lastWeek = subWeeks(currentDate, 1);
const lastWeekFirstDay = startOfWeek(lastWeek, { weekStartsOn: 1 });
const lastWeekLastDay = endOfWeek(lastWeek, { weekStartsOn: 1 });

const currentWeekStartDay = startOfWeek(currentDate, { weekStartsOn: 1 });

export enum CALENDAR_DEFAULT_TYPES {
  LAST_MONTH = 'LAST_MONTH',
  CURRENT_MONTH = 'CURRENT_MONTH',
  LAST_WEEK = 'LAST_WEEK',
  THIS_WEEK = 'THIS_WEEK',
  TODAY = 'TODAY',
  PERIOD = 'PERIOD',
}

export const CALENDAR = (t: TFunction) => {
  return [
    {
      id: CALENDAR_DEFAULT_TYPES.LAST_MONTH,
      title: `${format(lastMonth, 'MMM')}`,
      subtitle: `${format(lastMonthFirstDay, 'd MMM')} - ${format(lastMonthLastDay, 'd MMM')}`,
      startDate: new Date(startOfDay(lastMonthFirstDay)).getTime(),
      endDate: new Date(endOfDay(lastMonthLastDay)).getTime(),
    },
    {
      id: CALENDAR_DEFAULT_TYPES.CURRENT_MONTH,
      title: `${format(currentMonthFirstDay, 'MMM')}`,
      subtitle: `${format(currentMonthFirstDay, 'd MMM')} - ${format(currentDate, 'd MMM')}`,
      startDate: new Date(startOfDay(currentMonthFirstDay)).getTime(),
      endDate: new Date(endOfDay(currentDate)).getTime(),
    },
    {
      id: CALENDAR_DEFAULT_TYPES.LAST_WEEK,
      title: t('last.week.label'),
      subtitle: `${format(lastWeekFirstDay, 'd MMM')} - ${format(lastWeekLastDay, 'd MMM')}`,
      startDate: new Date(startOfDay(lastWeekFirstDay)).getTime(),
      endDate: new Date(endOfDay(lastWeekLastDay)).getTime(),
    },
    {
      id: CALENDAR_DEFAULT_TYPES.THIS_WEEK,
      title: t('this.week.label'),
      subtitle: `${format(currentWeekStartDay, 'd MMM')} - ${format(currentDate, 'd MMM')}`,
      startDate: new Date(startOfDay(currentWeekStartDay)).getTime(),
      endDate: new Date(endOfDay(currentDate)).getTime(),
    },
    {
      id: CALENDAR_DEFAULT_TYPES.TODAY,
      title: t('today.label'),
      subtitle: format(currentDate, 'd MMM'),
      startDate: new Date(startOfDay(currentDate)).getTime(),
      endDate: new Date(endOfDay(currentDate)).getTime(),
    },
    {
      id: CALENDAR_DEFAULT_TYPES.PERIOD,
      title: t('period.label'),
      subtitle: t('period.subtitle.label'),
      startDate: null,
      endDate: null,
    },
  ] as const;
};
