import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  errorMessage: {
    fontFamily: 'SFPro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '17px',
    color: palette.custom.redSecondary,
  },
}));

export default useStyles;
