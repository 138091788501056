import { ISensorData } from 'interfaces/sensor/i-sensor-data';

import server from 'utils/server';

/**
 * Toggles the sensor notifications for a given sensor ID.
 *
 * @param {string} sensorId - The ID of the sensor.
 * @returns {Promise<ISensorData>} - A promise that resolves to the updated sensor data.
 */
export const toggleSensorNotificationsReq = async (sensorId: string): Promise<ISensorData> =>
  server.services.beService.patch<ISensorData>(`sensors/${sensorId}`).then((res) => res.data);
