import { TFunction } from 'react-i18next';

export type TBtnKey = 'showDriverInfo' | 'showVehicleInfo';

const data = (t: TFunction<'dashboard.map.page'>): { label: string; key: TBtnKey }[] => {
  return [
    {
      label: t('drivers.label'),
      key: 'showDriverInfo',
    },
    {
      label: t('vehicles.label'),
      key: 'showVehicleInfo',
    },
  ];
};

export default data;
