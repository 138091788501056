import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { isEmptyValue } from 'utils/helpers/is-empty-value';
import { valueConverter } from 'utils/helpers/value-converter';

import userConfigAtom from 'recoil/user-config';

import useStyles from './styles';

interface IProps {
  speed?: number;
}

const SpeedBox: FC<IProps> = ({ speed }) => {
  const classes = useStyles();
  const { isMetricSystem } = useRecoilValue(userConfigAtom);
  const { t } = useTranslation('measurement.system.page');

  return (
    <div className={classes.wrap} data-testdid='speed-box'>
      <>
        <div className={classes.unit}>{t(`${isMetricSystem ? 'km' : 'mile'}.per.h.label`)}</div>
        {!isEmptyValue(speed) && (
          <div className={classes.count}>
            {isMetricSystem ? speed : valueConverter.distance.toImperial(speed as number)}
          </div>
        )}
      </>
    </div>
  );
};

export default SpeedBox;
