import { setYear } from 'date-fns';
import endOfDay from 'date-fns/endOfDay';
import startOfYear from 'date-fns/startOfYear';
import { atom } from 'recoil';

import { ISavingFilter } from 'interfaces/saving/i-saving-filter';

import { convertToUtcTime } from 'utils/helpers/convert-to-utc-time';

const date = new Date();

const savingFilterAtom = atom<ISavingFilter>({
  key: 'savingFilterAtom',
  default: {
    startAt: convertToUtcTime(startOfYear(setYear(date, 2020))),
    finishAt: convertToUtcTime(endOfDay(date)),
  },
});

export default savingFilterAtom;
