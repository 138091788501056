import * as yup from 'yup';

export const formSchema = () => {
  return yup.object().shape({
    startAt: yup.date().required(),
    finishAt: yup.date().required(),
    isSplitByDays: yup.boolean(),
  });
};
export type TReportFilters = yup.InferType<ReturnType<typeof formSchema>>;
