import { TFunction } from 'react-i18next';

import { TABLE_VIEW_TYPE } from 'interfaces/common/table-view-type';

const getColumnWidth = (widthInPX: number) => {
  const tableWidth = 1230;

  return `${(widthInPX / tableWidth) * 100}%`;
};

export const TABLE_HEAD_COLUMNS = (t: TFunction) =>
  ({
    [TABLE_VIEW_TYPE.INFO]: [
      {
        id: 'plateNumber',
        label: t('vehicle.table.header.label'),
        styles: { minWidth: 135, width: getColumnWidth(400) },
        align: 'left',
        isSort: true,
      },
      {
        id: 'currentScoring',
        label: t('score.table.header.label'),
        styles: { minWidth: 135, width: getColumnWidth(135) },
        align: 'center',
        isSort: true,
      },
      {
        id: 'problems',
        label: t('penalties.table.header.label'),
        styles: { minWidth: 135, width: getColumnWidth(220) },
        align: 'center',
        isSort: false,
      },
      {
        id: 'dailyKm',
        label: t('daily.km.header.label'),
        styles: { minWidth: 135, width: getColumnWidth(135) },
        align: 'center',
        isSort: true,
      },
      {
        id: 'driveTime',
        label: t('daily.hours.header.label'),
        styles: { minWidth: 135, width: getColumnWidth(135) },
        align: 'center',
        isSort: true,
      },
      {
        id: 'idleTime',
        label: t('idle.header.label'),
        styles: { minWidth: 135, width: getColumnWidth(135) },
        align: 'center',
        isSort: true,
      },
    ],
    [TABLE_VIEW_TYPE.STATISTIC]: [
      {
        id: 'plateNumber',
        label: t('vehicle.table.header.label'),
        styles: { minWidth: 135, width: getColumnWidth(400) },
        align: 'left',
        isSort: true,
      },
      {
        id: 'currentScoring',
        label: t('score.table.header.label'),
        styles: { minWidth: 135, width: getColumnWidth(126.6) },
        align: 'center',
        isSort: true,
      },
      {
        id: 'dailyKm',
        label: t('daily.km.header.label'),
        styles: { minWidth: 135, width: getColumnWidth(126.6) },
        align: 'center',
        isSort: true,
      },
      {
        id: 'driveTime',
        label: t('daily.hours.header.label'),
        styles: { minWidth: 135, width: getColumnWidth(126.6) },
        align: 'center',
        isSort: true,
      },
      {
        id: 'idling',
        label: t('idle.header.label'),
        styles: { minWidth: 135, width: getColumnWidth(126.6) },
        align: 'center',
        isSort: true,
      },
      {
        id: 'engineHoursBySecond',
        label: t('moto.hours.table.header.label'),
        styles: { minWidth: 135, width: getColumnWidth(126) },
        align: 'center',
        isSort: true,
      },
      {
        id: 'totalKm',
        label: t('total.km.table.header.label'),
        styles: { minWidth: 135, width: getColumnWidth(126.6) },
        align: 'center',
        isSort: true,
      },
    ],
  }) as const;
