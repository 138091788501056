import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import RouteManager from 'utils/services/route-manager';

import driverShortDataAtom from 'recoil/driver/driverShortData';
import driverTripAtom from 'recoil/driver/driverTrip';

import PathCard from '../PathCard';
import Statistics from '../Statistics';

import useStyles from './styles';

interface IProps {
  date?: string;
}

const Header: FC<IProps> = ({ date }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const driverShortData = useRecoilValue(driverShortDataAtom);
  const [{ route, statistics, id }] = useRecoilState(driverTripAtom);

  /**
   * Current trip information
   */
  const currentTrip = useMemo(() => {
    return {
      route,
      statistics,
      id,
    };
  }, [route, statistics, id]);

  return (
    <>
      <div className={classes.info} data-testid='trip-header'>
        <div className={classes.date} data-testid='date'>
          {date ? date : route?.start?.date?.day}
        </div>
        <div
          className={classes.linkDriver}
          onClick={() =>
            navigate(RouteManager.makeURL('drivers.edit', { id: driverShortData!.driverId! }))
          }
          data-testid='driverName'
        >
          {driverShortData?.firstName} {driverShortData?.lastName}
        </div>
      </div>
      <div className={classes.container}>
        <div className={classes.pathWrap}>
          <PathCard trip={currentTrip} />
        </div>

        <Statistics />
      </div>
    </>
  );
};

export default Header;
