import React from 'react';

import { FCC } from 'interfaces/common/fc-with-children';
import { ENGINE_STATUS } from 'interfaces/vehicles/engine-status';

import { ReactComponent as PowerIcon } from 'assets/images/power.svg';

import useStyles from './styles';

interface IEngineButton {
  status: ENGINE_STATUS;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

type Props = IEngineButton;

const EngineButton: FCC<Props> = ({ onClick, status }) => {
  const classes = useStyles({ status });

  return (
    <div className={classes.wrap} onClick={onClick} data-testid='button-engine'>
      <PowerIcon className={classes.icon} />
    </div>
  );
};

export default EngineButton;
