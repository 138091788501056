import { ISuccessBaseResponse } from 'interfaces/common/success-base-response';

import server from 'utils/server';

/**
 * Makes a request to add available members to a team.
 *
 * @param {string} teamId - The ID of the team to add members.
 * @param {boolean} all - Whether to add all available members or not. Default is false.
 * @param {string[]} driverIds - An array of driver IDs to add. Default is an empty array.
 * @returns {Promise<ISuccessBaseResponse>} - A promise that resolves to the response data.
 */
export const addedAvailableMembersReq = async (
  teamId: string,
  all: boolean = false,
  driverIds: string[] = [],
): Promise<ISuccessBaseResponse> => {
  const body = {
    all,
    driverIds,
  };

  return server.services.beService
    .post<ISuccessBaseResponse>(`teams/add/${teamId}`, body)
    .then((res) => res.data);
};
