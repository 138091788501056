import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';

import dashboardSidebarListTypeAtom from 'recoil/dashboard-map/sidebar/list-type';
import { SIDEBAR_TYPES } from 'recoil/dashboard-map/sidebar/list-type/atom';
import dashboardSidebarSearchValueAtom from 'recoil/dashboard-map/sidebar/search-value';

import Input from 'components/form-v2/input';

import { formSchema } from './form-schema';
import useStyles from './styles';

const Search = ({ setIsShowSearchInput }) => {
  const classes = useStyles();
  const { t } = useTranslation('fields');
  const setListType = useSetRecoilState(dashboardSidebarListTypeAtom);
  const setDashboardSidebarSearchValue = useSetRecoilState(dashboardSidebarSearchValueAtom);

  const formMethods = useForm({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = useCallback(
    async (data) => {
      setDashboardSidebarSearchValue(data.query);
    },
    [setDashboardSidebarSearchValue],
  );

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit(onSubmit)}
        className={classes.searchWrap}
        data-testdid='search-wrap'
      >
        <Input
          title={''}
          name='query'
          placeholder={t('search.input.label')}
          endAdornment={
            <span className={classes.inputBtns}>
              <SearchIcon
                className={classes.searchIcon}
                onClick={formMethods.handleSubmit(onSubmit)}
              />
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => {
                  setIsShowSearchInput(false);
                  setDashboardSidebarSearchValue('');
                  setListType(SIDEBAR_TYPES.DEFAULT);
                }}
              />
            </span>
          }
        />
      </form>
    </FormProvider>
  );
};

Search.propTypes = {};

Search.defaultProps = {};

export default Search;
