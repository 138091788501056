import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '50px 30px 58px 30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    color: theme.palette.primary.main,
    fontFamily: 'SFPro',
  },

  emptyMessage: {
    marginTop: 105,
  },

  warning: {
    position: 'absolute',
    top: -41,
    width: 82,
    height: 82,
    borderRadius: '50%',
    backgroundColor: palette.custom.lightBlue,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',

    '& svg': {
      width: 42,
    },
  },

  ops: {
    fontWeight: 700,
    fontSize: 32,
  },

  message: {
    fontWeight: 500,
    fontSize: 16,
    maxWidth: 400,
    textAlign: 'center',
  },

  user: {
    minWidth: 365,
    marginTop: 50,
    padding: '30px 0',
    backgroundColor: palette.custom.lightBlue,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  driverName: {
    marginTop: 15,
    fontSize: 18,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    maxWidth: 310,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  vehicleWrap: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
    marginBottom: 30,
    paddingTop: 30,
    paddingBottom: 30,
    borderTop: `1px solid ${palette.custom.black}`,
    borderBottom: `1px solid ${palette.custom.black}`,
  },

  more: {
    position: 'absolute',
    bottom: -24,
    padding: '12px 64px',
    backgroundColor: palette.custom.lightBlue,
    boxShadow: '0px 3.22222px 10.4722px 1.61111px rgba(0, 0, 0, 0.25)',
    borderRadius: 14,
    fontWeight: 400,
    fontSize: 20,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

export default useStyles;
