import cx from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { ISavingCircleData } from 'interfaces/saving/i-saving-circle-data';

import RouteManager from 'utils/services/route-manager';

import userConfigAtom from 'recoil/user-config';

import { scoringSavingReq } from 'requests/be-service/scoring-controller/get-saving';

import Loader from 'components/Loader';
import Money from 'components/money';

import NoInfoToShow from '../NoInfoToShow';

import createOptions from './options';
import useStyles from './styles';

const SavingsChart = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ISavingCircleData | null>(null);
  const { currencyIcon } = useRecoilValue(userConfigAtom);

  useEffect(() => {
    const request = async () => {
      try {
        setIsLoading(true);
        const res = await scoringSavingReq();
        setData(res);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };

    request();

    return () => setData(null);
  }, [currencyIcon]);

  const options = useMemo(
    () => createOptions(data?.totalFleetSaving, data?.maxTotalFleetSaving),
    [data],
  );

  if (isLoading) {
    return (
      <div className={classes.wrap} data-testid='savings-chart-loader'>
        <Loader width={100} isBlock lightMode />
      </div>
    );
  }

  if (!data) {
    return (
      <div className={classes.wrap} data-testid='no-info-savings-chart'>
        <NoInfoToShow />
      </div>
    );
  }

  return (
    <Link
      className={classes.wrap}
      to={RouteManager.path('saving', { hasLeadingSlash: true })}
      data-testid='savings-chart-link'
    >
      <div className={classes.label} data-testid='savings-chart-label'>
        <Money value={data.totalFleetSaving} currencyIcon={currencyIcon} />
      </div>
      <div
        className={cx(data?.totalFleetSaving < 0 && classes.graphNegative)}
        data-testid='savings-chart-graph'
      >
        <Chart options={options} type='radialBar' series={options.series} height={294} />
      </div>
    </Link>
  );
};

export default SavingsChart;
