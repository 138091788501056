import cx from 'classnames';
import { FC } from 'react';

import useStyles from './styles';

interface IProps {
  days: number;
  className?: string;
}
const DaysLeft: FC<IProps> = ({ days, className }) => {
  const classes = useStyles();

  return (
    <span className={cx(classes.wrap, className, days < 6 && 'isWarning')} data-testid='days-left'>
      {days}
    </span>
  );
};

export default DaysLeft;
