import cx from 'classnames';
import React, { Dispatch, useCallback, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { notificationsReadAllReq } from 'requests/notification-service/read-all';

import useStyles from './styles';

interface IReadAllModal {
  setIsOpenReadAllModal: Dispatch<boolean>;
  closeNotificationModalHandler: () => void;
  getCounter: () => void;
}

const ReadAllModal: FC<IReadAllModal> = ({
  setIsOpenReadAllModal,
  closeNotificationModalHandler,
  getCounter,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('notifications.service');

  const rejectHandler = () => setIsOpenReadAllModal(false);

  const approveHandler = useCallback(async () => {
    try {
      await notificationsReadAllReq();
      await getCounter();
      closeNotificationModalHandler();
      setIsOpenReadAllModal(false);
    } catch (e) {
      console.log(e);
    }
  }, [closeNotificationModalHandler, getCounter, setIsOpenReadAllModal]);

  return (
    <div className={classes.wrap} data-testid='read-all-modal'>
      <div className={classes.title}>{t('read.all.title.label')}</div>
      <div className={classes.btns}>
        <div
          className={cx(classes.btn, classes.approveBtn)}
          onClick={approveHandler}
          data-testid='approve-button'
        >
          {t('read.all.approve.btn.label')}
        </div>
        <div
          className={cx(classes.btn, classes.rejectBtn)}
          onClick={rejectHandler}
          data-testid='reject-button'
        >
          {t('read.all.reject.btn.label')}
        </div>
      </div>
    </div>
  );
};

export default ReadAllModal;
