import { atom } from 'recoil';

import { ISettingPaymentCompany } from 'interfaces/company/setting-payment-company';

const settingPaymentSubCompaniesAtom = atom<ISettingPaymentCompany[]>({
  key: 'settingPaymentSubCompaniesAtom',
  default: [],
});

export default settingPaymentSubCompaniesAtom;
