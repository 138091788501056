import { atom } from 'recoil';

import { IResponseWrapper } from 'interfaces/server/response-wrapper';

import { ICompanySettingsItem } from 'requests/company-controller/find-all-companies';

const settingsCompaniesAtom = atom<IResponseWrapper<ICompanySettingsItem[]> | null>({
  key: 'settingsCompaniesAtom',
  default: null,
});

export default settingsCompaniesAtom;
