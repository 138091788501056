import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import {
  ISettingPaymentVehiclesFilters,
  settingPaymentVehiclesListFilterSelector,
} from 'recoil/settings/payment/vehicles/filters';

import DatePicker from 'components/form-v2/date-picker';
import ModalActions from 'components/modal-actions';

import { formSchema } from './form-schema';

interface IFilters {
  onClose: () => void;
}

const Filters: FC<IFilters> = ({ onClose }) => {
  const [filters, setFilters] = useRecoilState(settingPaymentVehiclesListFilterSelector);
  const { t } = useTranslation('fields');

  const formMethods = useForm<ISettingPaymentVehiclesFilters>({
    defaultValues: filters,
    resolver: yupResolver(formSchema),
  });

  const onSaveFilters = (data: ISettingPaymentVehiclesFilters) => {
    setFilters(data);
    onClose();
  };

  return (
    <div data-testid='vehicls-filters'>
      <FormProvider {...formMethods}>
        <DatePicker title={`${t('date.label')} (${t('from.filter.label')})`} name={'startDate'} />
        <DatePicker title={`${t('date.label')} (${t('to.filter.label')})`} name={'endDate'} />
        <ModalActions handleClose={onClose} handleSave={formMethods.handleSubmit(onSaveFilters)} />
      </FormProvider>
    </div>
  );
};

export default Filters;
