import queryString from 'query-string';

import { IMapSideBarItem } from 'interfaces/map/i-map-side-bar-item';

import server from 'utils/server';

/**
 * Get list of vehicles for sidebar on dashboard map by search field
 * @async
 * @param {string} query - The search query.
 * @returns {Promise<{ content: IMapSideBarItem[], totalElements: number }>} - The sidebar items and the total number of elements.
 */
export const getVehiclesSidebarBySearchReq = async (
  query: string,
): Promise<{ content: IMapSideBarItem[]; totalElements: number }> => {
  const params = {
    query,
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
  };

  return server.services.beService
    .get<IMapSideBarItem[]>('v2/map/search?' + queryString.stringify(params))
    .then((res) => ({ content: res.data, totalElements: res.data.length }));
};
