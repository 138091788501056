import * as yup from 'yup';

export const formSchema = () => {
  return yup.object().shape({
    active: yup.boolean().required(),
    comment: yup.string().required(),
    id: yup.string().required(),
    includeDriverVehicle: yup.boolean().required(),
  });
};

export type TDisableVehicleForm = yup.InferType<ReturnType<typeof formSchema>>;
