import { usePubNub } from 'pubnub-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';

import crashModalAtom from 'recoil/crash-modal';

import { messageStatusChange } from 'requests/notification-service/change-message-status';

const useNotificationsWebSocket = (getCounter) => {
  const pubnub = usePubNub();
  const [{ isOpen }, setCrashModal] = useRecoilState(crashModalAtom);
  const [pubnubMessage, setPubnubMessage] = useState(null);

  const listenerIsExists = useRef();

  const openCrashModal = useCallback(
    async (id, crashId) => {
      try {
        if (id) {
          await messageStatusChange(id);
        }

        setCrashModal((prevState) => {
          return {
            ...prevState,
            isOpen: true,
            crashList: [crashId],
            currentCrashIdNumber: 0,
          };
        });
        getCounter();
      } catch (e) {
        console.log(e);
      }
    },
    [getCounter, setCrashModal],
  );

  const handleMessage = useCallback(
    (event) => {
      getCounter();

      setPubnubMessage(
        typeof event.message === 'string' ? JSON.parse(event.message) : event.message,
      );
    },
    [getCounter],
  );

  useEffect(() => {
    if (isOpen || pubnubMessage?.type !== 'CRASH') {
      setPubnubMessage(null);
      return;
    }
    openCrashModal(pubnubMessage?.notificationId, pubnubMessage?.crashId);
    setPubnubMessage(null);
  }, [
    isOpen,
    openCrashModal,
    pubnubMessage?.crashId,
    pubnubMessage?.notificationId,
    pubnubMessage?.type,
  ]);

  useEffect(() => {
    return pubnub.subscribe({ channels: [localStorage.getItem('pubnubChanel')] });
  }, [pubnub]);

  useEffect(() => {
    if (listenerIsExists.current) {
      return;
    }

    pubnub.addListener({ message: handleMessage });
    listenerIsExists.current = true;
  }, [handleMessage, pubnub]);

  useEffect(() => {
    return () => {
      pubnub.destroy();
    };
  }, [pubnub]);
};

export default useNotificationsWebSocket;
