import { ITeamData } from 'interfaces/teams/i-team-data';

import server from 'utils/server';

/**
 * Updates team information.
 *
 * @async
 * @param {string} teamId - The ID of the team to edit.
 * @param {string} name - The new name for the team.
 * @returns {Promise<ITeamData>} - A promise that resolves to the updated team data.
 */
export const editTeamReq = async (teamId: string, name: string): Promise<ITeamData> => {
  const body = {
    name,
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
  };

  return server.services.beService.put<ITeamData>(`teams/${teamId}`, body).then((res) => res.data);
};
