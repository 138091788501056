import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { blocks, markers } from './data';
import useStyles from './styles';

const Info = () => {
  const classes = useStyles();
  const { t } = useTranslation('scoring.page');

  return (
    <>
      <div className={classes.title} data-testid='info-title'>
        {t('chart.info.title.label')}
      </div>

      <div className={classes.blocks} data-testid='info-blocks'>
        {blocks.map((type) => (
          <div className={cx(classes.block, type)} key={type} data-testid={`info-block-${type}`} />
        ))}
      </div>

      <div className={classes.markers} data-testid='info-markers'>
        {markers.map(({ value, type }) => (
          <div
            key={value}
            className={cx(classes.marker, type)}
            data-testid={`info-marker-${value}`}
          >
            {value}
          </div>
        ))}
      </div>
    </>
  );
};

export default Info;
