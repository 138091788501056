import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    color: palette.custom.greenSecondary,

    '&.isNegative': {
      color: palette.custom.redSecondary,
    },
  },
}));

export default useStyles;
