import { Manager } from '@lomray/react-route-manager';

/**
 * Application URL manager
 */
const RouteManager = new Manager({
  routes: {
    home: {
      url: '/',
    },
    login: {
      url: '/login',
    },
    dashboardMap: {
      url: '/dashboard-map',
      children: {
        live: {
          url: '/:id',
          params: {
            id: '',
          },
        },
      },
    },
    scoring: {
      url: '/scoring',
    },
    saving: {
      url: '/saving',
    },
    drivers: {
      url: '/drivers',
      children: {
        trips: {
          url: '/:id',
          params: {
            id: '',
          },
        },
        trip: {
          url: '/:id/trip/:tripId',
          params: {
            tripId: '',
            id: '',
          },
        },
        tripsByDay: {
          url: '/:id/trips/:date',
          params: {
            date: '',
            id: '',
          },
        },
        create: {
          url: '/create',
        },
        edit: {
          url: '/:id/edit',
          params: {
            id: '',
          },
        },
      },
    },
    vehicles: {
      url: '/vehicles',
      children: {
        create: {
          url: '/create',
        },
        edit: {
          url: '/:id/edit',
          params: {
            id: '',
          },
        },
      },
    },
    settings: {
      url: '/settings',
      children: {
        drivers: {
          url: '/drivers',
        },
        vehicles: {
          url: '/vehicles',
        },
        vehicleBrand: {
          url: '/vehicle-brand',
        },
        fuelPrice: {
          url: '/fuel-price',
        },
        speedManagement: {
          url: '/speed-management',
        },
        language: {
          url: '/language',
        },
        companies: {
          url: '/companies',
          children: {
            create: {
              url: '/create',
            },
            edit: {
              url: '/:id',
              params: {
                id: '',
              },
            },
          },
        },
        users: {
          url: '/users',
          children: {
            create: {
              url: '/create',
            },
            edit: {
              url: '/:id',
              params: {
                id: '',
              },
            },
          },
        },
        teams: {
          url: '/teams',
        },
        payment: {
          url: '/payment',
          children: {
            company: {
              url: '/:id',
              params: {
                id: '',
              },
              children: {
                transaction: {
                  url: '/transaction',
                },
                vehicles: {
                  url: '/vehicles',
                  children: {
                    vehicle: {
                      url: '/:idOfVehicle',
                      params: {
                        idOfVehicle: '',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        geo: {
          url: '/geo',
          children: {
            zones: {
              url: '/zones',
              children: {
                create: {
                  url: '/create',
                },
                edit: {
                  url: '/:id',
                  params: { id: '' },
                },
              },
            },
          },
        },
      },
    },
  },
});

export default RouteManager;
