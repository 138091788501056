import { atom } from 'recoil';

interface INavigationOptions {
  isOpenNav: boolean;
  isOpenCompanyList: boolean;
  isOpenSearch: boolean;
}

const navigationOptionsAtom = atom<INavigationOptions>({
  key: 'navigationOptionsAtom',
  default: {
    isOpenNav: false,
    isOpenCompanyList: false,
    isOpenSearch: false,
  },
});

export default navigationOptionsAtom;
