import { atom } from 'recoil';

import { ISortParameters } from 'interfaces/common/sort';

import { SORT_DIRECTION } from '../../settings/payment/transactions/sort';

const initValues = {
  sort: '',
  direction: SORT_DIRECTION.ASC,
};

const vehiclesSortDataAtom = atom<ISortParameters>({
  key: 'vehiclesSortDataAtom',
  default: initValues,
});

export default vehiclesSortDataAtom;
