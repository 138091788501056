import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    position: 'absolute',
    top: 6,
    right: 16,

    '&.isDashboardMapPage': {
      right: 70,
      transition: 'all 0.3s ease',
    },

    '&.isOpenDashboardSidebar': {
      right: 565,
    },

    '&.isGeoPages': {
      right: 610,
    },
  },

  notificationsButton: {
    width: 38,
    height: 38,
    padding: 0,
    background: palette.custom.lightBlue,
    transition: 'background 0.2s',

    '&:hover': {
      background: palette.custom.softBlue,
    },
  },

  notificationsButtonIcon: {
    height: 18,
  },

  notificationsBadge: {
    top: '-5px',
    right: '-5px',
    background: palette.custom.redSecondary,
    color: theme.palette.primary.light,
    transform: 'scale(0.85) translate(50%, -50%)',
  },
}));

export default useStyles;
