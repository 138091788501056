import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrap: {
    '& sup': {
      opacity: 0.4,
    },

    '&:empty::before': {
      content: '"-"',
    },
  },
}));

export default useStyles;
