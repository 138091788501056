import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  modal: {
    width: '100%',
    height: '100%',
  },

  engineModalWrap: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'SFPro',
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '24px',
    backgroundColor: alpha(palette.custom.black, 0.3),
    color: theme.palette.primary.main,
  },

  engineModal: {
    padding: '30px 65px',
    borderRadius: 10,
    backgroundColor: palette.custom.softBlue,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  engineModalTitle: {
    maxWidth: 211,
    textAlign: 'center',

    '& span': {
      fontWeight: 700,
    },
  },

  engineModalControls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30,
  },

  engineModalBtn: {
    margin: '0 8px',
    width: 70,
    height: 40,
    borderRadius: 10,
    backgroundColor: palette.custom.bluePrimary,
    boxShadow: `0px 0px 13px 2px ${alpha(palette.custom.black, 0.3)}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
}));

export default useStyles;
