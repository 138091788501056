import queryString from 'query-string';

import server from 'utils/server';

/**
 * Retrieves a list of vehicle crashes based on the given vehicle IMEI.
 *
 * @param {string} vehicleIMEI - The IMEI of the vehicle.
 * @returns {Promise<string[]>} - A Promise that resolves to an array of crash data.
 */
export const getVehicleCrashListReq = async (vehicleIMEI: string): Promise<string[]> => {
  const params = {
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
  };

  return server.services.gpsService
    .get<string[]>(`crashes/vehicles/${vehicleIMEI}?` + queryString.stringify(params))
    .then(({ data }) => data.reverse());
};
