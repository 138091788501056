import { atom } from 'recoil';

import { NOTIFICATION_TABS } from 'interfaces/notifications/notification-tab';

const notificationActiveTabAtom = atom<NOTIFICATION_TABS>({
  key: 'notificationActiveTabAtom',
  default: NOTIFICATION_TABS.ALL,
});

export default notificationActiveTabAtom;
