import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    backgroundColor: palette.custom.bluePrimary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 18,
    transform: 'translateY(50%)',
    height: 30,
  },

  icon: {
    width: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  speed: {
    fontSize: 12,
    fontWeight: 500,
    color: palette.custom.redSecondary,
    backgroundColor: palette.custom.white,
    width: 30,
    height: 30,
    borderRadius: '50%',
    border: '2px solid red',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: `0 0 0 2px ${palette.custom.white}`,
  },
}));

export default useStyles;
