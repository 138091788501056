import Button from '@mui/material/Button';
import cx from 'classnames';
import React, { useCallback, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as LandingLogoIcon } from 'assets/images/landing-home-logo.svg';

import { handleGetInAnchor } from 'utils/helpers';
import RouteManager from 'utils/services/route-manager';

import ContextAppScroll from 'modules/Root/ContextAppScroll';

import Dropdown from './Dropdown';
import useStyles from './styles';

const Header = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const appScroll = useContext(ContextAppScroll);
  const { t } = useTranslation('home.page');

  const [headerShadow, setHeaderShadow] = useState(false);

  useEffect(() => {
    const rootScroll = appScroll.current;

    const handleUpdateHeader = (event) => {
      event.target.scrollTop > 1 ? setHeaderShadow(true) : setHeaderShadow(false);
    };

    rootScroll.addEventListener('scroll', handleUpdateHeader);

    return () => {
      headerShadow && setHeaderShadow(false);
      rootScroll.removeEventListener('scroll', handleUpdateHeader);
    };
  }, [appScroll, headerShadow]);

  const handleRedirectLogin = useCallback(() => navigate(RouteManager.path('login')), [navigate]);

  return (
    <div className={cx(classes.wrapper, { [classes.wrapperShadow]: headerShadow })}>
      <div className={classes.container}>
        <LandingLogoIcon
          onClick={() => handleGetInAnchor('get-in-home-anchor')}
          className={classes.logo}
        />
        <div className={classes.rightContainer}>
          <Dropdown />
          <Button onClick={handleRedirectLogin} className={classes.loginButton}>
            {t('login.label')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Header);
