/**
 * Enumeration representing different time degrees.
 * @enum {string}
 */
export enum TIME_DEGREE {
  S = 'S',
  M = 'M',
  MS = 'MS',
  H = 'H',
}
