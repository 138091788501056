import { alpha, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles<Theme>((theme) => ({
  wrap: {
    background: alpha(theme.palette.primary.dark, 0.08),
    borderRadius: 10,
    padding: 15,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    rowGap: 40,
    alignItems: 'flex-end',
  },

  name: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
    fontSize: 20,
    fontWeight: 500,
    color: palette.custom.blue200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  estimation: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.primary.dark,
  },

  days: {
    fontSize: 16,
  },

  money: {
    justifySelf: 'flex-end',
    fontSize: 28,
    fontWeight: 400,
    color: theme.palette.primary.dark,
  },
}));

export default useStyles;
