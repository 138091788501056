import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${palette.custom.blue900}`,
    borderRadius: 3,
    cursor: 'pointer',
  },

  country: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '14px',
    color: theme.palette.primary.dark,
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    padding: '0 4px',
    backgroundColor: palette.custom.blue900,
  },

  plateNumber: {
    margin: '0 7px',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '21px',
    color: palette.custom.blue200,
    maxWidth: 95,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export default useStyles;
