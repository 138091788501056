import { selectorFamily } from 'recoil';

import { CURRENCY_SYMBOL } from 'interfaces/common/currency-symbol';

import { IProps } from 'modules/Trip/conponents/header/statistic';

import tripPointsSelector from '../../points';

const statisticSelector = selectorFamily<IProps, string>({
  key: 'statisticSelector',
  get:
    (tripId) =>
    ({ get }) => {
      const tripInfo = get(tripPointsSelector(tripId));

      return {
        distance: tripInfo.trip.km,
        maxSpeed: tripInfo.maxSpeedPoint?.currentSpeed,
        fuelUsage: tripInfo.trip.fuelUsed,
        saving: tripInfo.tripSaving,
        currencyIcon: CURRENCY_SYMBOL.EUR,
      };
    },
});

export default statisticSelector;
