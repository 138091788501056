import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import { SENSOR_TYPES } from 'interfaces/sensor/types';

import activeSensorAlarmsAtom from 'recoil/trip/sensor-alarms/active-alarms';
import sensorAlarmsSelector from 'recoil/trip/sensor-alarms/data';

import ParameterButton from 'components/TripComponents/sensors/parameter-btn';

import useStyles from './styles';

interface IProps {
  tripId: string;
}
const Sensors: FC<IProps> = ({ tripId }) => {
  const classes = useStyles();
  const { t } = useTranslation('sensor.service');
  const tripSensorsAlarms = useRecoilValue(sensorAlarmsSelector(tripId));
  const [activePenalties, setActivePenalties] = useRecoilState(activeSensorAlarmsAtom);

  const rowHandler = useCallback(
    (name: SENSOR_TYPES, isActive: boolean) => {
      if (isActive) {
        const newActivePenalties = activePenalties.filter((penalty) => penalty !== name);
        setActivePenalties(newActivePenalties);
        return;
      }

      setActivePenalties([...activePenalties, name]);
    },
    [activePenalties, setActivePenalties],
  );

  const alarms = useMemo(() => {
    if (!tripSensorsAlarms) {
      return [];
    }
    return Object.entries(tripSensorsAlarms).map((item) => {
      return {
        type: item[0],
        count: item[1].length,
      } as { type: SENSOR_TYPES; count: number };
    });
  }, [tripSensorsAlarms]);

  return (
    <div className={classes.wrap} data-testid='sensors-wrap'>
      <div className={classes.title}>{t('trip.alarms.title.label')}</div>

      {alarms?.length === 0 && <div>{t('empty.alarms.label')}</div>}

      <div className={classes.alarms}>
        {alarms?.map(({ type, count }) => {
          const isActive = activePenalties?.includes(type);

          return (
            <ParameterButton
              key={type}
              type={type}
              penaltiesCount={count}
              selected={isActive}
              onClick={() => rowHandler(type, isActive)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(Sensors);
