import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrap: {
    minWidth: 300,
    maxWidth: 600,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 15,
  },

  row: {
    display: 'flex',
    columnGap: 10,
  },

  actions: {
    alignSelf: 'flex-end',
  },
}));

export default useStyles;
