import { atom } from 'recoil';

import { ACTIVITY_STATUSES } from 'interfaces/common/activity-statuses';

interface IDashboardMapFilters {
  status: ACTIVITY_STATUSES[];
  bottomRightLat: null | number;
  bottomRightLong: null | number;
  topLeftLat: null | number;
  topLeftLong: null | number;
  limit: number;
}

const dashboardMapFiltersAtom = atom<IDashboardMapFilters>({
  key: 'dashboardMapFiltersAtom',
  default: {
    status: [],
    bottomRightLat: null,
    bottomRightLong: null,
    topLeftLat: null,
    topLeftLong: null,
    limit: 200,
  },
});

export default dashboardMapFiltersAtom;
