import { TVehicleEditData } from 'interfaces/vehicles/vehicle-edit-data';

import { valueConverter } from 'utils/helpers/value-converter';

/**
 * A variable representing a vehicle data object.
 *
 * @typedef {Object} vehicleDTO
 * @property {Function} toMetric - Converts the vehicle data object from imperial units to metric units.
 * @property {Function} toImperial - Converts the vehicle data object to imperial units.
 */
export const vehicleDTO = {
  /**
   * Converts a given vehicle data object from imperial units to metric units.
   *
   * @param {TVehicleEditData} data - The vehicle data object in imperial units.
   * @returns {TVehicleEditData} - The vehicle data object in metric units.
   */
  toMetric: (data: TVehicleEditData): TVehicleEditData => {
    return {
      ...data,
      generalInfo: {
        ...data.generalInfo,
        odometer: valueConverter.distance.toMetric(data.generalInfo.odometer),
        maxPermissibleMass:
          data.generalInfo.maxPermissibleMass &&
          valueConverter.weight.toMetric(data.generalInfo.maxPermissibleMass),
        engineCapacity:
          data.generalInfo.engineCapacity &&
          valueConverter.volume.toMetric(data.generalInfo.engineCapacity),
      },
      detailsInfo: {
        ...data.detailsInfo,
        oilCapacity: valueConverter.volume.toMetric(data.detailsInfo.oilCapacity),
        fuelConsumptionCity: valueConverter.consumption.toMetric(
          data.detailsInfo.fuelConsumptionCity,
        ),
        fuelConsumptionCombined: valueConverter.consumption.toMetric(
          data.detailsInfo.fuelConsumptionCombined,
        ),
        fuelConsumptionHighWay: valueConverter.consumption.toMetric(
          data.detailsInfo.fuelConsumptionHighWay,
        ),
        weight: valueConverter.weight.toMetric(data.detailsInfo.weight),
        maxSpeed:
          data.detailsInfo.maxSpeed && valueConverter.weight.toMetric(data.detailsInfo.maxSpeed),
        maxDistance:
          data.detailsInfo.maxDistance &&
          valueConverter.weight.toMetric(data.detailsInfo.maxDistance),
      },
    };
  },
  /**
   * Converts a given vehicle data object to imperial units.
   * @param {TVehicleEditData} data - The vehicle data object to be converted.
   * @returns {TVehicleEditData} - The vehicle data object with imperial units.
   */
  toImperial: (data: TVehicleEditData): TVehicleEditData => {
    return {
      ...data,
      generalInfo: {
        ...data.generalInfo,
        odometer: valueConverter.distance.toImperial(data.generalInfo.odometer),
        maxPermissibleMass:
          data.generalInfo.maxPermissibleMass &&
          valueConverter.weight.toImperial(data.generalInfo.maxPermissibleMass),
        engineCapacity:
          data.generalInfo.engineCapacity &&
          valueConverter.volume.toImperial(data.generalInfo.engineCapacity),
      },
      detailsInfo: {
        ...data.detailsInfo,
        oilCapacity: valueConverter.volume.toImperial(data.detailsInfo.oilCapacity),
        fuelConsumptionCity: valueConverter.consumption.toImperial(
          data.detailsInfo.fuelConsumptionCity,
        ),
        fuelConsumptionCombined: valueConverter.consumption.toImperial(
          data.detailsInfo.fuelConsumptionCombined,
        ),
        fuelConsumptionHighWay: valueConverter.consumption.toImperial(
          data.detailsInfo.fuelConsumptionHighWay,
        ),
        weight: valueConverter.weight.toImperial(data.detailsInfo.weight),
        maxSpeed:
          data.detailsInfo.maxSpeed && valueConverter.weight.toImperial(data.detailsInfo.maxSpeed),
        maxDistance:
          data.detailsInfo.maxDistance &&
          valueConverter.weight.toImperial(data.detailsInfo.maxDistance),
      },
    };
  },
};
