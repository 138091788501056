import queryString from 'query-string';

import server from 'utils/server';

/**
 * Mark all notifications as read
 */
export const notificationsReadAllReq = async () => {
  const params = {
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
  };

  return server.services.notificationService.put(
    `notifications/read?${queryString.stringify(params)}`,
  );
};
