import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import useTableSort from 'hooks/useTableSort';

import RouteManager from 'utils/services/route-manager';

import settingPaymentSubCompaniesAtom from 'recoil/settings/payment/selected-company/list';
import settingPaymentSubCompaniesListPaginationSelector from 'recoil/settings/payment/selected-company/pagination';
import settingPaymentSubCompaniesListSearchSelector from 'recoil/settings/payment/selected-company/search';
import { SORT_DIRECTION } from 'recoil/settings/payment/transactions/sort';
import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';

import { getSubCompaniesReq } from 'requests/payment/get-subcompanies';

import Checkbox from 'components/form-v2/checkbox';
import Loader from 'components/Loader';
import Modal from 'components/modal';
import PaginationScroll from 'components/pagination-scroll';
import TableSortLabel from 'components/TableSortLabel';

import DaysLeft from '../components/daysLeft';
import { Money } from '../components/money';

import useStyles from './styles';
import UseParentModal from './use-parent-modal';

const SubCompanies = () => {
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const [companiesSort, setCompaniesSort] = useTableSort({
    sort: '',
    direction: SORT_DIRECTION.ASC,
  });
  const technicalMessageBlockHeight = useRecoilValue(technicalMessageBlockHeightSelector);
  const classes = useStyles({ technicalMessageBlockHeight });
  const { t } = useTranslation('payment.page');
  const [companies, setCompanies] = useRecoilState(settingPaymentSubCompaniesAtom);
  const [isLoading, setIsLoading] = useState(false);
  const searchValue = useRecoilValue(settingPaymentSubCompaniesListSearchSelector);
  const [page, setPage] = useRecoilState(settingPaymentSubCompaniesListPaginationSelector);
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [isOpenUseParentModal, setIsOpenUseParentModal] = useState(false);

  const getCompanies = useCallback(async () => {
    try {
      if (page === 0) {
        setCompanies([]);
      }
      setIsLoading(true);
      const res = await getSubCompaniesReq(id, page, companiesSort, searchValue.query);
      setTotalCompanies(res.totalElements);
      setCompanies((prev) => [...prev, ...res.content]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, [companiesSort, id, page, searchValue.query, setCompanies]);

  useEffect(() => {
    getCompanies();
  }, [getCompanies, setCompanies]);

  useEffect(() => {
    return () => setCompanies([]);
  }, [setCompanies]);

  return (
    <>
      <TableContainer className={classes.table}>
        {isLoading && <Loader width={150} isBlock lightMode />}

        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headCell} align='left'>
                {t('child.company.label')}
              </TableCell>

              <TableCell className={classes.headCell} align='center'>
                <TableSortLabel
                  onClick={setCompaniesSort}
                  name={'daysLeftEstimation'}
                  sortField={companiesSort.sort}
                  direction={companiesSort.direction}
                >
                  {t('company.days.estivation.label')}
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.headCell} align='center'>
                <TableSortLabel
                  onClick={setCompaniesSort}
                  name={'balance'}
                  sortField={companiesSort.sort}
                  direction={companiesSort.direction}
                >
                  {t('company.balance.label')}
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.headCell} align='center'>
                <TableSortLabel
                  onClick={setCompaniesSort}
                  name={'useParentBalance'}
                  sortField={companiesSort.sort}
                  direction={companiesSort.direction}
                >
                  {t('use.parent.label')}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody data-testid='child-companies-list'>
            {companies.map(({ companyId, companyName, useParent, balance, daysLeftEstimation }) => (
              <TableRow
                key={companyId}
                className={classes.row}
                onClick={() =>
                  navigate(RouteManager.makeURL('settings.payment.company', { id: companyId }))
                }
                data-testid={`company-row-${companyId}`}
              >
                <TableCell className={classes.cell} align='left' data-testid='company-name'>
                  {companyName}
                </TableCell>
                <TableCell className={classes.cell} align='center'>
                  <DaysLeft days={daysLeftEstimation} />
                </TableCell>
                <TableCell className={classes.cell} align='center'>
                  {balance ? (
                    <Money count={Number(balance.toFixed(2))} className={classes.money} />
                  ) : (
                    '-'
                  )}
                </TableCell>
                <TableCell align='center' className={classes.cell}>
                  <Checkbox
                    value={useParent}
                    wrapClass={classes.checkbox}
                    testId='checkbox-useParent'
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationScroll
        count={companies.length}
        maxCount={totalCompanies}
        loadMoreHandler={() => setPage((prevPage) => prevPage + 1)}
      />

      <Modal open={isOpenUseParentModal} onClose={() => setIsOpenUseParentModal(false)}>
        <UseParentModal
          cancel={() => setIsOpenUseParentModal(false)}
          confirm={() => {
            setPage(0);
            setIsOpenUseParentModal(false);
          }}
        />
      </Modal>
    </>
  );
};

export default SubCompanies;
