import { atom } from 'recoil';

export enum CRASH_MODAL_TABS {
  PREVIEW = 'PREVIEW',
  DESCRIPTION = 'DESCRIPTION',
}

export enum CRASH_ANCHOR {
  VEHICLE = 'VEHICLE',
  DRIVER = 'DRIVER',
}

interface IManagementData {
  callAmbulance: boolean;
  callDriver: boolean;
  callSos: boolean;
  cargoStatus: null | string; // replace string with actual type if it's known
  driverHealth: null | string; // replace string with actual type if it's known
  notes: string;
  notificationAlreadySent: boolean;
  passengerHealth: null | string; // replace string with actual type if it's known
  validated: boolean;
}

export interface ICrashModal {
  currentCrashIdNumber: null | number; // replace number with actual type if it's known
  crashList: null | string[]; // replace any with actual type if it's known
  anchor: null | CRASH_ANCHOR;
  isOpen: boolean;
  isFetching: boolean;
  error: null | unknown; // replace any with actual type if it's known
  activeTab: CRASH_MODAL_TABS;
  driverData: null | unknown; // replace any with actual type if it's known
  crashData: null | unknown; // replace any with actual type if it's known
  managementData: IManagementData;
}

const crashModalAtom = atom<ICrashModal>({
  key: 'crashModalAtom',
  default: {
    currentCrashIdNumber: null,
    crashList: null,
    anchor: null,
    isOpen: false,
    isFetching: false,
    error: null,
    activeTab: CRASH_MODAL_TABS.PREVIEW,
    driverData: null,
    crashData: null,
    managementData: {
      callAmbulance: false,
      callDriver: false,
      callSos: false,
      cargoStatus: null,
      driverHealth: null,
      notes: '',
      notificationAlreadySent: false,
      passengerHealth: null,
      validated: false,
    },
  },
});

export default crashModalAtom;
