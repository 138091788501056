import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BUTTON_VARIANTS } from 'interfaces/common/button-variants';
import { ITeamData } from 'interfaces/teams/i-team-data';
import { TTeamFormData } from 'interfaces/teams/team-form-data';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';

import Button from 'components/form-v2/button';
import Input from 'components/form-v2/input';
import Modal from 'components/modal';
import ModalActions from 'components/modal-actions';

import { formSchema } from '../create-team/form-schema';

import useStyles from './styles';

interface IProps {
  onClose: () => void;
  onSubmit: (data: TTeamFormData) => void;
  onDelete: () => void;
  team: ITeamData;
}

const EditTeam: FC<IProps> = ({ onClose, onSubmit, team, onDelete }) => {
  const classes = useStyles();
  const { t } = useTranslation(['teams.page', 'fields']);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const formMethods = useForm<TTeamFormData>({
    resolver: yupResolver(formSchema()),
  });

  useEffect(() => {
    formMethods.reset({ name: team?.name });
  }, [formMethods, team?.name]);

  return (
    <div className={classes.wrap} data-testid='edit-team-wrap'>
      <div className={classes.header}>
        <div>{t('edit.team.modal.label')}</div>

        <CloseIcon className={classes.close} onClick={onClose} data-testid='button-close' />
      </div>

      <div className={classes.content}>
        <FormProvider {...formMethods}>
          <div className={classes.section}>
            <Input name={'name'} title={t('team.name.label')} />
          </div>

          <Button
            variant={BUTTON_VARIANTS.FILL}
            onClick={formMethods.handleSubmit(onSubmit)}
            className={classes.btn}
            testId='button-save'
          >
            {t('fields:save.label')}
          </Button>
        </FormProvider>
        <div
          className={classes.deleteBtn}
          onClick={() => setIsOpenModal(true)}
          data-testid='button-delete'
        >
          {t('delete.team.label')}
        </div>
      </div>

      <Modal open={isOpenModal} onClose={() => setIsOpenModal(false)}>
        <div className={classes.modal} data-testid='delete-modal-wrap'>
          <div className={classes.deleteText} data-testid='delete-message'>
            {t('delete.message.label', { teamName: team?.name })}
          </div>
          <ModalActions
            handleClose={() => setIsOpenModal(false)}
            handleSave={() => {
              setIsOpenModal(false);
              onDelete();
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

EditTeam.propTypes = {};

EditTeam.defaultProps = {};

export default EditTeam;
