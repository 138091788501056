import queryString from 'query-string';

import { IResponseWrapper } from 'interfaces/server/response-wrapper';
import { ITeamData } from 'interfaces/teams/i-team-data';

import server from 'utils/server';

/**
 * Retrieves a paginated list of teams.
 *
 * @param {number} [page=0] - The page number to retrieve.
 * @returns {Promise<IResponseWrapper<ITeamData[]>>} A promise that resolves to the response wrapper containing an array of team data.
 */
export const teamsGetReq = async (page: number = 0): Promise<IResponseWrapper<ITeamData[]>> => {
  const params = {
    page,
    size: 20,
  };

  return server.services.beService
    .get<IResponseWrapper<ITeamData[]>>('teams?' + queryString.stringify(params))
    .then((res) => res.data);
};
