import InputBase from '@mui/material/InputBase';
import { InputBaseComponentProps } from '@mui/material/InputBase/InputBase';
import { StyledEngineProvider } from '@mui/material/styles';
import cx from 'classnames';
import React, { FC, ReactNode } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

import { getDeepValue } from 'utils/helpers/get-object-deep-value';

import ErrorMessage from '../error-message';
import FieldLabel from '../field-label';

import useStyles from './styles';

interface IProps {
  name: string;
  title?: string;
  disabled?: boolean;
  subtitle?: string;
  placeholder?: string;
  className?: string;
  type?: 'text' | 'number' | 'password';
  endAdornment?: string | ReactNode;
  rows?: number;
  multiline?: boolean;
  textTransform?: 'uppercase' | 'lowercase' | 'none';
  inputComponent?: React.ElementType<InputBaseComponentProps>;
  additionalOnChange?: (value: string | number | null) => void;
}

const Input: FC<IProps> = ({
  name,
  subtitle,
  placeholder,
  endAdornment,
  className,
  inputComponent,
  additionalOnChange,
  title = '',
  disabled = false,
  type = 'text',
  rows,
  multiline = false,
  textTransform = 'none',
}) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorData = getDeepValue<FieldError>(errors, name);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <StyledEngineProvider injectFirst>
            <div className={cx(classes.wrap, className)}>
              <FieldLabel
                htmlFor={field.name}
                error={!!errorData}
                title={title}
                subtitle={subtitle}
                disabled={disabled}
              />
              <InputBase
                {...field}
                inputProps={{
                  'data-testid': `input-${name}`,
                }}
                multiline={multiline}
                rows={rows}
                type={type}
                id={field.name}
                inputComponent={inputComponent}
                value={field.value ?? ''}
                error={!!errorData}
                onChange={(e) => {
                  if (type === 'number') {
                    field.onChange(e.target.value === '' ? null : e.target.value);
                    additionalOnChange?.(e.target.value === '' ? null : e.target.value);
                    return;
                  }
                  if (textTransform === 'uppercase') {
                    field.onChange(e.target.value.toUpperCase());
                    additionalOnChange?.(e.target.value.toUpperCase());
                    return;
                  } else if (textTransform === 'lowercase') {
                    field.onChange(e.target.value.toLowerCase());
                    additionalOnChange?.(e.target.value.toLowerCase());
                    return;
                  }
                  field.onChange(e.target.value);
                  additionalOnChange?.(e.target.value);
                }}
                classes={{
                  root: cx(classes.inputWrap, { disabled }),
                  error: classes.inputWrapError,
                  focused: classes.inputWrapFocus,
                  disabled: classes.inputDisabled,
                  input: classes.input,
                }}
                disabled={disabled}
                placeholder={placeholder}
                endAdornment={
                  endAdornment && <span className={classes.endAdornment}>{endAdornment}</span>
                }
              />
              <ErrorMessage message={errorData?.message} />
            </div>
          </StyledEngineProvider>
        );
      }}
    />
  );
};

export default Input;
