import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles<Theme>((theme) => ({
  money: {
    fontSize: 28,
    fontWeight: 400,
    color: theme.palette.primary.dark,
    justifySelf: 'flex-end',
  },

  count: {
    fontWeight: 500,
    color: palette.custom.green400,

    '&.isWarning': {
      color: palette.custom.red900,
    },

    '&.withOutColor': {
      color: palette.custom.blue200,
    },
  },
}));

export default useStyles;
