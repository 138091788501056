import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

type Props = {
  technicalMessageBlockHeight: number;
};

const useStyles = makeStyles<Theme, Props>(() => ({
  btn: {
    position: 'relative',
    top: ({ technicalMessageBlockHeight }) => 10 + technicalMessageBlockHeight,
    width: 40,
    height: 50,
    backgroundColor: palette.custom.bluePrimary,
    borderRadius: '16px 0px 0px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all 0.3s cubic-bezier(0, 0, 0.2, 1)',
    zIndex: 1300,

    '& $arrow': {
      transform: 'rotate(-90deg)',
    },
  },

  arrow: {
    transform: 'rotate(90deg)',
    transition: 'all 0.3s ease',
  },

  modal: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },

  wrap: {
    display: 'flex',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: 500,
    backgroundColor: palette.custom.bluePrimary,
  },

  updateBtn: {
    position: 'absolute',
    bottom: 50,
    width: 390,
    alignSelf: 'center',
  },
}));

export default useStyles;
