import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

interface IProps {
  isBig: boolean;
}

const useStyles = makeStyles<Theme, IProps>(() => ({
  wrap: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },

  label: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  saved: {
    fontWeight: 500,
    fontSize: ({ isBig }) => (isBig ? 35 : 27),
  },

  potential: {
    marginTop: 5,
    fontWeight: 500,
    fontSize: 20,
    opacity: 0.3,
  },

  negativeChart: {
    transform: 'rotateY(180deg)',
  },

  historyWrap: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    bottom: 15,
    left: 15,
  },

  historyItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,

    '&:last-child': {
      marginBottom: 0,
    },
  },

  historyIcon: {
    width: 17,
    height: 17,
    borderRadius: 2,
    marginRight: 5,
  },

  historyTitle: {
    fontFamily: 'SFPro',
    fontWeight: 300,
    fontSize: 12,
    color: palette.custom.blue200,
    whiteSpace: 'nowrap',
  },
}));

export default useStyles;
