import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '5px 6px',
    width: 50,
    height: 50,
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },

  img: {
    zIndex: -2,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },

  shadow: {
    zIndex: -1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 19,
    width: 50,
    background: 'linear-gradient(0deg, #232D46 -13.33%, rgba(35, 45, 70, 0) 93.33%)',
    borderRadius: '0px 0px 5px 5px',
  },

  title: {
    marginLeft: 4,
    fontSize: 8,
    color: theme.palette.primary.light,
    fontWeight: '400',
  },
}));

export default useStyles;
