import queryString from 'query-string';

import { COUNTRIES } from 'interfaces/common/countries';
import { DEVICE_FUNCTION } from 'interfaces/vehicles/device-functions';
import { ENGINE_STATUS } from 'interfaces/vehicles/engine-status';
import { VEHICLE_TYPES } from 'interfaces/vehicles/types';

import server from 'utils/server';

interface IVehicleItem {
  id: string;
  vehicleId: string;
  plateNumber: string;
  engine: ENGINE_STATUS;
  vin: string;
  creationTime: number;
  country: COUNTRIES;
  vehicleType: VEHICLE_TYPES;
  deviceFunctions: DEVICE_FUNCTION[];
}

type TFormattedVehicleItem = IVehicleItem & { value: string; label: string };

/**
 * Retrieve list of vehicles by brand and model.
 *
 * @param {string} companyId - The ID of the company.
 * @param {number} brandId - The ID of the brand.
 * @param {number=} modelId - The ID of the model (optional).
 * @returns {Promise<TFormattedVehicleItem[]>} The formatted list of vehicles.
 */
export const getVehicleByBrandAndModelReq = async (
  companyId: string,
  brandId: number,
  modelId?: number,
): Promise<TFormattedVehicleItem[]> => {
  const params = {
    companyId,
    brandId,
    modelId,
  };

  return server.services.beService
    .get<IVehicleItem[]>('/vehicles/search/by-brand-model?' + queryString.stringify(params))
    .then<TFormattedVehicleItem[]>(({ data }) => {
      return data.map((item) => ({
        ...item,
        value: item.id,
        label: item.plateNumber,
      }));
    });
};
