import server from 'utils/server';

/**
 * Edits the user avatar.
 *
 * @async
 * @param {string} id - The ID of the user.
 * @param {File} avatar - The new avatar file.
 * @returns {Promise<{ id: number; name: string }>} A promise that resolves with the edited user avatar.
 */
export const editUserAvatarReq = async (
  id: string,
  avatar: File,
): Promise<{ id: number; name: string }> => {
  const body = new FormData();

  body.append('file', avatar);

  return server.services.beService
    .put<{ id: number; name: string }>(`system-users/${id}/photo`, body)
    .then((res) => res.data);
};
