import server from 'utils/server';

import { TUserData } from './create-user';

/**
 * Edit user request.
 *
 * @param {TUserData} data - The user data to edit.
 * @returns {Promise<TUserData>} - A promise that resolves with the edited user data.
 */
export const editUserReq = async (data: TUserData): Promise<TUserData> => {
  return server.services.beService
    .put<TUserData>(`system-users/${data.id}`, data)
    .then((res) => res.data);
};
