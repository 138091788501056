import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: '6px 63px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  root: {
    width: 100,
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.primary.light,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 18,
    backgroundColor: 'transparent',
    padding: '6px 0 7px',

    '&.Mui-disabled': {
      color: theme.palette.primary.dark,
      '-webkit-text-fill-color': theme.palette.primary.dark,
    },

    '&.Mui-disabled svg path': {
      fill: theme.palette.primary.dark,
    },

    '&:before, &:after': {
      content: 'unset !important',
    },

    '&:focus': {
      backgroundColor: 'transparent',
    },

    '& ~ fieldset': {
      border: 'none',
    },
  },

  selectList: {
    paddingRight: 20,
    paddingLeft: 20,
    backgroundColor: palette.custom.lightBlue,
    color: theme.palette.primary.dark,

    '& .MuiListItem-root.Mui-selected': {
      color: theme.palette.primary.light,
      backgroundColor: 'transparent',
    },
  },

  refresh: {
    cursor: 'pointer',
    transition: 'all 0.3s ease',
  },

  arrow: {
    pointerEvents: 'none',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& svg path': {
      fill: theme.palette.primary.light,
    },

    '&.disabled svg path': {
      fill: theme.palette.primary.dark,
    },
  },
}));

export default useStyles;
