import cx from 'classnames';
import React, { FC } from 'react';

import { ReactComponent as PhotoIcon } from 'assets/images/photo-icon.svg';

import useStyles from './styles';

interface INewAvatar {
  logo?: string | null;
  onClick?: () => void;
  isColorCircle?: boolean;
  fullscreen?: boolean;
}

const NewAvatar: FC<INewAvatar> = ({
  onClick,
  logo = null,
  isColorCircle = false,
  fullscreen = false,
}) => {
  const classes = useStyles();

  return (
    <div
      data-testid='avatar-wrap'
      className={cx(
        classes.avatar,
        isColorCircle && classes.circle,
        Boolean(onClick) && classes.cursor,
      )}
      onClick={onClick}
    >
      {logo && !fullscreen && <img src={logo} alt='Choose image' className={classes.icon} />}
      {logo && fullscreen && (
        <div className={classes.iconFullscreen} style={{ backgroundImage: `url(${logo})` }} />
      )}

      {!logo && <PhotoIcon className={cx(classes.icon, !logo && classes.default)} />}
    </div>
  );
};

export default React.memo(NewAvatar);
