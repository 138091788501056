import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import { atom, selector } from 'recoil';

import { IUserData } from 'interfaces/user/user-data';

import { ROLES_OBJECT } from 'constants/user';

import recoilLocalStorageEffect from 'utils/recoil-localStorage-effect';

import { IVehicleFilters } from '../../settings/vehicles/filters/atom';
import userDataAtom from '../../userData';

export const initValues = {
  companyIds: null,
  userIds: null,
  createdAtFrom: null,
  createdAtTill: null,
  finishDailyKm: null,
  movementAtFrom: startOfDay(new Date()).getTime(),
  movementAtTill: endOfDay(new Date()).getTime(),
  vehicleTypes: [],
  startDailyKm: null,
  query: '',
};

const vehiclesFilterAtom = atom<IVehicleFilters>({
  key: 'vehiclesFilterAtom',
  default: initValues,
  effects_UNSTABLE: [recoilLocalStorageEffect('vehiclesFilterAtom', initValues)],
});

const vehiclesFilterSelector = selector<IVehicleFilters>({
  key: 'vehiclesFilterSelector',
  get: ({ get }) => {
    const { role, companyId, id } = get(userDataAtom) as IUserData;
    const init = get(vehiclesFilterAtom);

    if (
      [
        ROLES_OBJECT.TECH_ADMIN,
        ROLES_OBJECT.ADMIN,
        ROLES_OBJECT.OWNER,
        ROLES_OBJECT.OPERATOR,
      ].includes(role)
    ) {
      return init;
    }

    return {
      ...init,
      companyIds: companyId,
      userIds: id,
    };
  },
  set: ({ set }, newValue) => set(vehiclesFilterAtom, newValue),
});

export default vehiclesFilterSelector;
