import server from 'utils/server';

import { TDisableDriverForm } from 'modules/Settings/pages/drivers/comonents/disable-modal/form-schema';

/**
 * Disables driver request.
 *
 * @param {TDisableDriverForm} data - The form data for disabling driver request.
 * @returns {Promise<string>} - The promise that resolves to a string indicating the result of the operation or assign vehicle id.
 */
export const disableDriverReq = async (data: TDisableDriverForm): Promise<string> =>
  server.services.beService
    .put<string>('drivers/activate', data)
    .then((res) => (res.data ? res.data : 'ok'));
