import { atom } from 'recoil';

const dashboardMapVehiclesAtom = atom({
  key: 'dashboardMapVehiclesAtom',
  default: {
    isLoading: false,
    error: null,
    data: null,
  },
});

export default dashboardMapVehiclesAtom;
