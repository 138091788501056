/**
 * Checking for 'null' and 'undefined'
 * @param value
 * @return {boolean}
 */
export const isEmptyValue = (value: unknown): boolean => {
  if (value === null) {
    return true;
  }

  return value === undefined;
};
