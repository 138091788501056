import { yupResolver } from '@hookform/resolvers/yup';
import { SelectChangeEvent } from '@mui/material';
import { isAxiosError } from 'axios';
import cx from 'classnames';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable } from 'recoil';

import { useCallbackPrompt } from 'hooks/useCallbackPromt';

import { ISelectItem } from 'interfaces/common/select-items';
import { IUserData } from 'interfaces/user/user-data';

import { DRIVER_CATEGORIES } from 'constants/driver';
import { ROLES_OBJECT } from 'constants/user';

import { ReactComponent as ArrowBackIcon } from 'assets/images/arrow.svg';
import { ReactComponent as GeoIcon } from 'assets/images/geo-fencing.svg';

import { driverFormDTO } from 'utils/dto/driver/edit-form';
import { createScoringSeries, scoringColor } from 'utils/helpers';
import RouteManager from 'utils/services/route-manager';

import subcompaniesCurrencyIconAtom from 'recoil/subcompanies/currency-icon';
import subcompaniesReqGetAtom from 'recoil/subcompanies/request';
import subcompaniesSelectListAtom from 'recoil/subcompanies/select-list';
import userConfigAtom from 'recoil/user-config';
import userDataAtom from 'recoil/userData';

import { vehicleBrandsReq } from 'requests/be-service/brand-model-controller/find-brands';
import { vehicleModelsReq } from 'requests/be-service/brand-model-controller/find-models-by-brand';
import { getCompanyUsersReq } from 'requests/be-service/company-controller/get-company-users';
import { driverGetReq } from 'requests/be-service/driver-controller/get-driver';
import { driverCreateReq } from 'requests/be-service/driver-controller/save-driver';
import { driverEditReq } from 'requests/be-service/driver-controller/update-driver';
import { getVehicleByBrandAndModelReq } from 'requests/be-service/vehicle-controller/find-by-brand-mode';
import { vehiclesAllReq } from 'requests/be-service/vehicle-controller/get-all-vehicle';
import { vehicleGetReq } from 'requests/be-service/vehicle-controller/get-vehicle-dto';

import PhoneInput from 'components/form-v2/custom-phone-input';
import DatePicker from 'components/form-v2/date-picker';
import Input from 'components/form-v2/input';
import Section from 'components/form-v2/section';
import Select from 'components/form-v2/select';
import TimePicker from 'components/form-v2/time-picker';
import Loader from 'components/Loader';
import Modal from 'components/modal';
import UnsavedModal from 'components/unsaved-modal';

import { formSchema, defaultValues, TDriverCreateData } from './form-schema';
import { createOptions } from './options';
import Pin from './pin-field';
import useStyles from './styles';

const DriverProfile = () => {
  const { t } = useTranslation(['driver.details.page', 'fields', 'measurement.system.page']);
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const userData = useRecoilValue(userDataAtom) as IUserData;
  const { isMetricSystem } = useRecoilValue(userConfigAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [managers, setManagers] = useState<ISelectItem[] | null>(null);
  const [vehicles, setVehicles] = useState<ISelectItem[] | null>(null);
  const [brands, setBrands] = useState<ISelectItem[] | null>(null);
  const [models, setModels] = useState<ISelectItem[] | null>(null);
  const [driverScoring] = useState(0);
  const { contents: subcompaniesSelectList } = useRecoilValueLoadable(subcompaniesSelectListAtom);
  const refreshSubcompaniesList = useRecoilRefresher_UNSTABLE(subcompaniesReqGetAtom);

  const formMethods = useForm<TDriverCreateData>({
    resolver: yupResolver(formSchema()),
    defaultValues,
  });

  const isSubcompanyView = Boolean(sessionStorage.getItem('subcompanyId'));
  const isActiveDriver = formMethods.watch('active');

  const subcompaniesCurrencyIcon = useRecoilValueLoadable(
    subcompaniesCurrencyIconAtom({ companyId: formMethods.watch('companyId') }),
  );

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    formMethods.formState.isDirty,
  );

  /**
   * Show error message for backend errors
   */
  const showValidateError = useCallback(
    (errorMessage: string) => {
      switch (errorMessage) {
        case 'driver.duplicate.driver.license':
          formMethods.setError(
            'driverLicense',
            { type: 'custom', message: 'Duplicate driver license' },
            { shouldFocus: true },
          );
          enqueueSnackbar('Duplicate driver license', { variant: 'error' });
          break;

        case 'driver.duplicate.pin':
          formMethods.setError(
            'pin',
            { type: 'custom', message: 'Duplicate driver PIN' },
            { shouldFocus: true },
          );
          enqueueSnackbar('Duplicate driver PIN', { variant: 'error' });
          break;

        default:
          enqueueSnackbar('Saving error', { variant: 'error' });
          break;
      }
    },
    [enqueueSnackbar, formMethods],
  );

  /**
   * Get managers for selected subcompany
   */
  const getCompanyManagers = useCallback(async (companyId: string) => {
    try {
      const response = await getCompanyUsersReq(companyId);
      setManagers(
        response.map((manager) => ({
          value: manager.id,
          label: `${manager.firstName} ${manager.lastName}`,
        })),
      );
    } catch (e) {
      console.log(e);
    }
  }, []);

  /**
   * Get models for selected brand
   */
  const getModels = useCallback(async (brandId: number) => {
    try {
      const response = await vehicleModelsReq(brandId);
      setModels(response);
    } catch (e) {
      console.log(e);
    }
  }, []);

  /**
   * Set selected vehicle brand and model
   */
  const handleChangeAssignVehicle = useCallback(
    async (e: SelectChangeEvent | string) => {
      try {
        const res = await vehicleGetReq(typeof e === 'string' ? e : e.target.value);
        formMethods.setValue('make', res.generalInfo.brandId);
        await getModels(res.generalInfo.brandId);
        formMethods.setValue('model', res.generalInfo.modelId);
      } catch (error) {
        console.log(error);
      }
    },
    [formMethods, getModels],
  );

  /**
   * Get initial driver data for edit page
   */
  useEffect(() => {
    const getDriverData = async () => {
      try {
        if (!id) return;

        setIsLoading(true);
        const response = await driverGetReq(id);

        getCompanyManagers(response.companyId);
        const vehicleList = await vehiclesAllReq(response.companyId);
        const brandsList = await vehicleBrandsReq();
        setVehicles(vehicleList);
        setBrands(brandsList);
        if (response?.driverVehicleDto?.vehicleDto?.id) {
          handleChangeAssignVehicle(response?.driverVehicleDto?.vehicleDto?.id);
        }
        formMethods.reset(driverFormDTO.get(response));
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      getDriverData();
    }
  }, [getCompanyManagers, handleChangeAssignVehicle, id, isMetricSystem, formMethods]);

  /**
   * Set default company
   */
  useEffect(() => {
    const req = async () => {
      try {
        refreshSubcompaniesList();
        if (!id && userData.role === ROLES_OBJECT.MANAGER) {
          formMethods.setValue('companyId', userData.companyId);
          formMethods.setValue('managedById', userData.id);
          getCompanyManagers(userData.companyId);

          const vehicleList = await vehiclesAllReq(userData.companyId);
          const brandsList = await vehicleBrandsReq();
          setBrands(brandsList);
          setVehicles(vehicleList);
        }
      } catch (e) {
        console.log(e);
      }
    };

    req();
  }, [
    formMethods,
    getCompanyManagers,
    id,
    refreshSubcompaniesList,
    userData.companyId,
    userData.id,
    userData.role,
  ]);

  const createDriver = async (data: TDriverCreateData) => {
    try {
      setIsLoading(true);
      const res = await driverCreateReq(driverFormDTO.post(data));
      formMethods.reset(data);
      enqueueSnackbar('Saved', { variant: 'success' });
      navigate(RouteManager.makeURL('drivers.edit', { id: res.id }));
    } catch (error) {
      if (isAxiosError(error)) {
        showValidateError(error.response!.data.error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateDriver = async (data: TDriverCreateData) => {
    try {
      if (!id) return;

      setIsLoading(true);
      const res = await driverEditReq(id, driverFormDTO.post(data));
      formMethods.reset(res);
      enqueueSnackbar('Saved', { variant: 'success' });
    } catch (error) {
      if (isAxiosError(error)) {
        showValidateError(error.response!.data.error);
      }
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Get managers, vehicles for selected company and reset manager and assign vehicle fields
   */
  const handleChangeCompany = useCallback(
    async (e: SelectChangeEvent) => {
      try {
        formMethods.setValue('managedById', '');
        formMethods.setValue('driverVehicleDto.vehicleDto.id', '');
        formMethods.setValue('make', null);
        formMethods.setValue('model', null);
        await getCompanyManagers(e.target.value);
        const vehicleList = await vehiclesAllReq(e.target.value);
        const brandsList = await vehicleBrandsReq();
        setBrands(brandsList);
        setVehicles(vehicleList);
      } catch (error) {
        console.log(error);
      }
    },
    [formMethods, getCompanyManagers],
  );

  /**
   * Get vehicle list when change brand or model fields
   * @param companyId
   * @param brandId
   * @param modelId
   */
  const getVehiclesByBrandAndModel = useCallback(
    async (companyId: string, brandId: number, modelId?: number) => {
      try {
        formMethods.setValue('driverVehicleDto.vehicleDto.id', '');
        const res = await getVehicleByBrandAndModelReq(companyId, brandId, modelId);
        setVehicles(res);
      } catch (error) {
        console.log(error);
      }
    },
    [formMethods],
  );

  /**
   * Show main map and current vehicle
   */
  const handleGeoFencing = useCallback(() => {
    const vehicleId = formMethods.watch('driverVehicleDto.vehicleDto.id');
    if (vehicleId) {
      navigate(RouteManager.makeURL('dashboardMap.live', { id: vehicleId }));
    }
  }, [formMethods, navigate]);

  /**
   * Create options for scoring chart
   */
  const options = useMemo(() => createOptions(scoringColor(driverScoring)), [driverScoring]);

  if (userData.role === ROLES_OBJECT.OPERATOR || (sessionStorage.getItem('subcompanyId') && !id)) {
    return <Navigate to={RouteManager.makeURL('drivers')} />;
  }

  if (isLoading) {
    return <Loader width={150} lightMode preventClick />;
  }

  return (
    <div className={classes.wrap}>
      <div className={classes.header}>
        <div
          className={classes.btnBack}
          onClick={() => navigate(RouteManager.makeURL('drivers'))}
          data-testid='button-back'
        >
          <ArrowBackIcon />
        </div>
        <div className={classes.title}>{t('back.btn.label')}</div>

        <div className={classes.btnWrap}>
          {!isSubcompanyView && (
            <button
              type='submit'
              className={classes.btn}
              form='main-form'
              data-testid='button-submit'
            >
              {t('add.btn.label')}
            </button>
          )}
        </div>
      </div>

      <FormProvider {...formMethods}>
        <form
          className={classes.form}
          onSubmit={formMethods.handleSubmit(id ? updateDriver : createDriver)}
          id='main-form'
          data-testid='driver-form'
        >
          <Section title={t('general.information.label')}>
            <div className={classes.pinWrap}>
              <Pin
                title={t('pin.label')}
                name='pin'
                disabled={isSubcompanyView || !isActiveDriver}
              />
              <div className={classes.chart}>
                <Chart
                  height={130}
                  type='radialBar'
                  series={[createScoringSeries(driverScoring)]}
                  options={options}
                />
              </div>
            </div>
            <Input
              title={t('firstName.label')}
              name='firstName'
              disabled={isSubcompanyView || !isActiveDriver}
            />
            <Input
              title={t('lastName.label')}
              name='lastName'
              disabled={isSubcompanyView || !isActiveDriver}
            />

            <Input
              title={t('middleName.label')}
              name='middleName'
              disabled={isSubcompanyView || !isActiveDriver}
              subtitle={t('optional.label')}
            />

            <Input
              title={t('idCard.label')}
              name='idNumber'
              disabled={isSubcompanyView || !isActiveDriver}
            />
          </Section>

          <Section title={t('drivers.license.details.label')}>
            <Input
              title={t('driverLicense.label')}
              name='driverLicense'
              disabled={isSubcompanyView || !isActiveDriver}
            />

            <Select
              title={t('categories.label')}
              name='categories'
              items={DRIVER_CATEGORIES}
              disabled={isSubcompanyView || !isActiveDriver}
              multiple
            />

            <DatePicker
              title={t('expireDate.label')}
              name='expireDate'
              disabled={isSubcompanyView || !isActiveDriver}
            />
          </Section>

          <Section title={t('contact.details.label')}>
            <PhoneInput
              name={['phonePrefix', 'phoneNumber']}
              title={t('phoneNumber.label')}
              disabled={isSubcompanyView || !isActiveDriver}
            />
            <PhoneInput
              name={['sosPhonePrefix', 'sosPhoneNumber']}
              title={t('sosPhoneNumber.label')}
              subtitle={t('optional.label')}
              disabled={isSubcompanyView || !isActiveDriver}
            />

            <Input
              title={t('email.label')}
              name='email'
              disabled={isSubcompanyView || !isActiveDriver}
              subtitle={t('optional.label')}
            />
            <Input
              title={t('address.label')}
              name='address'
              disabled={isSubcompanyView || !isActiveDriver}
              subtitle={t('optional.label')}
            />
          </Section>

          <Section title={t('employment.information.label')}>
            <Select
              title={t('company.label')}
              name={'companyId'}
              items={Array.isArray(subcompaniesSelectList) ? subcompaniesSelectList : []}
              disabled={
                ![ROLES_OBJECT.TECH_ADMIN, ROLES_OBJECT.ADMIN, ROLES_OBJECT.OWNER].includes(
                  userData.role,
                ) ||
                isSubcompanyView ||
                !isActiveDriver
              }
              additionalOnChange={handleChangeCompany}
            />
            <Select
              title={t('manager.label')}
              name={'managedById'}
              items={managers ?? []}
              disabled={
                isSubcompanyView ||
                !managers ||
                userData.role === ROLES_OBJECT.MANAGER ||
                !isActiveDriver
              }
            />

            <DatePicker
              title={t('start.date.label')}
              name={'driverVehicleDto.startAt'}
              disabled={isSubcompanyView || !isActiveDriver}
              subtitle={t('optional.label')}
            />
            <DatePicker
              title={t('end.date.label')}
              name={'driverVehicleDto.finishAt'}
              disabled={isSubcompanyView || !isActiveDriver}
              subtitle={t('optional.label')}
            />

            <TimePicker
              title={t('startWorkingTime.label')}
              name={'startWorkingTime'}
              disabled={isSubcompanyView || !isActiveDriver}
              subtitle={t('optional.label')}
            />
            <TimePicker
              title={t('finishWorkingTime.label')}
              name={'finishWorkingTime'}
              disabled={isSubcompanyView || !isActiveDriver}
              subtitle={t('optional.label')}
            />

            <Input
              title={t('basic.label')}
              name={'basic'}
              disabled={isSubcompanyView || !isActiveDriver}
              subtitle={t('optional.label')}
              placeholder={t('monthly.label')}
              endAdornment={
                typeof subcompaniesCurrencyIcon.contents === 'string'
                  ? subcompaniesCurrencyIcon.contents
                  : ''
              }
            />
            <Input
              title={t('bonus.label')}
              name={'bonus'}
              disabled={isSubcompanyView || !isActiveDriver}
              subtitle={t('optional.label')}
              placeholder={t('monthly.label')}
              endAdornment={
                typeof subcompaniesCurrencyIcon.contents === 'string'
                  ? subcompaniesCurrencyIcon.contents
                  : ''
              }
            />
          </Section>

          <Section title={t('assign.vehicle.section.label')}>
            <Select
              title={t('assign.vehicle.label')}
              name={'driverVehicleDto.vehicleDto.id'}
              items={vehicles ?? []}
              disabled={isSubcompanyView || !vehicles || !isActiveDriver}
              additionalOnChange={handleChangeAssignVehicle}
            />
            <Select
              title={t('make.label')}
              name={'make'}
              items={brands ?? []}
              disabled={isSubcompanyView || !brands || !isActiveDriver}
              additionalOnChange={(e) => {
                formMethods.resetField('model');
                getModels(+e.target.value);
                getVehiclesByBrandAndModel(formMethods.watch('companyId'), +e.target.value);
              }}
            />
            <Select
              title={t('model.label')}
              name={'model'}
              items={models ?? []}
              disabled={isSubcompanyView || !models || !isActiveDriver}
              additionalOnChange={(e) => {
                getVehiclesByBrandAndModel(
                  formMethods.watch('companyId'),
                  formMethods.watch('make')!,
                  +e.target.value,
                );
              }}
            />
          </Section>

          {id && (
            <Section title={t('additional.section.label')}>
              <div className={classes.geo}>
                <div
                  className={cx(classes.geoIcon, { disabled: !formMethods.watch('hasGpsPoints') })}
                  onClick={handleGeoFencing}
                  data-testid='geo-icon'
                >
                  <GeoIcon />
                </div>
                <div>{t('geofencing.label')}</div>
              </div>
            </Section>
          )}
        </form>
      </FormProvider>

      <Modal open={showPrompt} onClose={cancelNavigation}>
        <UnsavedModal cancelNavigation={cancelNavigation} confirmNavigation={confirmNavigation} />
      </Modal>
    </div>
  );
};

export default DriverProfile;
