import Button from '@mui/material/Button';
import Container from '@mui/material/Modal';
import React, { FC } from 'react';

import useStyles from './styles';

interface IProps {
  isOpenModal: boolean;
  closeModal: () => void;
  onSubmit: () => void;
  fileName: string;
}

const Modal: FC<IProps> = ({ isOpenModal, closeModal, onSubmit, fileName }) => {
  const classes = useStyles();

  return (
    <Container open={isOpenModal}>
      <div className={classes.modal}>
        <div
          className={classes.modalDescription}
          data-testid='message'
        >{`Do you really want to upload a file: "${fileName}"?`}</div>

        <div>
          <Button
            variant='contained'
            onClick={closeModal}
            className={classes.closeModal}
            data-testid='button-close'
          >
            Close
          </Button>
          <Button variant='contained' onClick={onSubmit} data-testid='button-submit'>
            Submit
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Modal;
