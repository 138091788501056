import { ComponentType } from 'react';
import { TFunction } from 'react-i18next';

import { USER_ROLES } from 'interfaces/user/roles';

import { ReactComponent as SavingsIcon } from 'assets/images/menu/diagnostics-menu.svg';
import { ReactComponent as ProwerIcon } from 'assets/images/menu/power-menu.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/menu/settings-menu.svg';
import { ReactComponent as StarIcon } from 'assets/images/menu/star-menu.svg';
import { ReactComponent as TripsIcon } from 'assets/images/menu/trips.svg';

import RouteManager from 'utils/services/route-manager';

export interface INavigationItem {
  name: string;
  value: string;
  pageName: string[];
  icon: ComponentType;
}

export const NAVIGATION = (t: TFunction<'nav.page'>, userRole: USER_ROLES) => {
  return [
    {
      name: t('scoring.label'),
      value: RouteManager.path('scoring'),
      pageName: ['scoring'],
      icon: StarIcon,
    },
    {
      name: t('drivers.label'),
      value: RouteManager.path('drivers'),
      pageName: ['drivers', 'vehicles'],
      icon: TripsIcon,
    },
    {
      name: t('savings.label'),
      value: RouteManager.path('saving'),
      pageName: ['saving'],
      icon: SavingsIcon,
    },
    [USER_ROLES.TECH_ADMIN, USER_ROLES.ADMIN, USER_ROLES.OWNER, USER_ROLES.MANAGER].includes(
      userRole,
    ) && {
      name: t('settings.label'),
      value: RouteManager.path('settings'),
      pageName: ['settings'],
      icon: SettingsIcon,
    },
    { name: t('signOut.label'), value: 'logout', pageName: ['logout'], icon: ProwerIcon },
  ] as INavigationItem[];
};
