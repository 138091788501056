import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    fontFamily: 'SFPro',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '19px',
    color: palette.custom.blue300,
    padding: '20px 0',
    borderBottom: `1px solid ${palette.custom.blue900}`,
  },

  icon: {
    marginRight: 15,
    width: 50,
    height: 50,
    borderRadius: 10,
    backgroundColor: palette.custom.blue900,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  messageWrap: {
    flex: 1,
    overflow: 'hidden',
  },

  driverInfo: {
    fontWeight: 600,
    fontSize: 16,
    color: palette.custom.blue200,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginBottom: 5,
  },

  message: {
    marginRight: 5,
  },

  time: {
    textAlign: 'end',
    width: 100,
    fontSize: 14,
    color: palette.custom.blue600,
  },

  isNotReadIcon: {
    position: 'absolute',
    top: 10,
    left: -8,
    width: 8,
    height: 8,
    backgroundColor: palette.custom.goldSecondary,
    borderRadius: '50%',
  },
}));

export default useStyles;
