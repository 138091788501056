import { atom, selector } from 'recoil';

import settingPaymentCompaniesListPaginationSelector from '../pagination';

export interface ISettingPaymentCompaniesListFilter {
  minDaysLeft: null | number;
  maxDaysLeft: null | number;
  minBalance: null | number;
  maxBalance: null | number;
  useParent: null | 'true' | 'false';
}

export const initValues = {
  minDaysLeft: null,
  maxDaysLeft: null,
  minBalance: null,
  maxBalance: null,
  useParent: null,
};

const settingPaymentCompaniesListFilterAtom = atom<ISettingPaymentCompaniesListFilter>({
  key: 'settingPaymentCompaniesListFilterAtom',
  default: initValues,
});

export const settingPaymentCompaniesListFilterSelector =
  selector<ISettingPaymentCompaniesListFilter>({
    key: 'settingPaymentCompaniesListFilterSelector',
    get: ({ get }) => {
      return get(settingPaymentCompaniesListFilterAtom);
    },
    set: ({ set, reset }, newValue) => {
      reset(settingPaymentCompaniesListPaginationSelector);
      set(settingPaymentCompaniesListFilterAtom, newValue);
    },
  });

export const settingPaymentCompaniesListFilterCountSelector = selector<number>({
  key: 'settingPaymentCompaniesListFilterCountSelector',
  get: ({ get }) => {
    const filters = get(settingPaymentCompaniesListFilterAtom);

    return Object.values(filters).filter((item) => item).length;
  },
});
