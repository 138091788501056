import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    width: '100%',
  },

  row: {
    padding: '13px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 10,
    marginBottom: 5,
    cursor: 'pointer',
    border: '2px solid transparent',
    transition: 'all ease 0.3s',
    background: alpha(theme.palette.primary.dark, 0.08),

    '&:hover': {
      background: palette.custom.blue900,
    },

    '&.active': {
      border: `2px solid ${palette.custom.goldSecondary}`,
      background: alpha(palette.custom.goldSecondary, 0.15),

      '& $label': {
        color: palette.custom.goldSecondary,
      },
    },

    '&.total': {
      background: 'transparent',
      pointerEvents: 'none',

      '& $label': {
        fontSize: 15,
        fontWeight: 700,
      },

      '& $count': {
        fontSize: 28,
        fontWeight: 500,
      },
    },

    '&.geofencing': {
      pointerEvents: 'none',
    },

    '&.foul': {
      pointerEvents: 'none',
    },
  },

  label: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '19px',
    color: palette.custom.blue200,
  },

  count: {
    minWidth: 55,
    display: 'flex',
    justifyContent: 'center',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 20,
    color: palette.custom.blue200,
  },

  card: {
    width: '16px',
    height: '23.47px',
    marginRight: '8px',
    borderRadius: '2.13333px',
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.48)',

    '&:last-child': {
      marginRight: 0,
    },

    '&--RED': {
      backgroundColor: palette.custom.redSecondary,
    },

    '&--YELLOW': {
      backgroundColor: palette.custom.yellow,
    },
  },

  warningIcon: {
    width: 25,
  },
}));

export default useStyles;
