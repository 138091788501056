import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'red',
  },

  item: {
    width: 125,
    padding: '0 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontFamily: 'SFPro',
    fontStyle: 'normal',
    borderRight: `1px solid ${palette.custom.blue800}`,

    '&:last-child': {
      borderRight: 'none',
    },
  },

  count: {
    fontWeight: 500,
    fontSize: 28,
    lineHeight: '33px',
    color: palette.custom.blue200,
  },

  title: {
    marginTop: 2,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '17px',
    color: palette.custom.blue700,
  },
}));

export default useStyles;
