import { isEmptyObj } from './helpers';

const recoilLocalStorageEffect =
  (key, initValues) =>
  ({ setSelf, onSet }) => {
    const savedValue = sessionStorage.getItem(key);
    if (savedValue !== null) {
      if (isEmptyObj(JSON.parse(savedValue))) {
        setSelf(initValues);
      } else {
        setSelf(JSON.parse(savedValue));
      }
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? sessionStorage.removeItem(key)
        : sessionStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export default recoilLocalStorageEffect;
