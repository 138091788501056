import cx from 'classnames';
import React from 'react';

import { FCC } from 'interfaces/common/fc-with-children';

import useStyles from './styles';

interface IProps {
  title?: string;
  subtitle?: string;
  size?: 'small' | 'large';
  titlePosition?: 'left' | 'right';
  titleAdditional?: string;
  titleClassName?: string;
}

const ScoringContainer: FCC<IProps> = ({
  children,
  title,
  subtitle,
  titleAdditional,
  titleClassName,
  size = 'small',
  titlePosition = 'right',
}) => {
  const classes = useStyles();

  return (
    <div className={classes.scoring}>
      {subtitle && <span className={cx(classes.subtitle, classes[size])}>{subtitle}</span>}
      <span className={cx(classes.title, classes[size], titlePosition, titleClassName)}>
        {title} <span className={classes.titleAdditional}>{titleAdditional}</span>
      </span>
      {children}
    </div>
  );
};

export default React.memo(ScoringContainer);
