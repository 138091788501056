import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  wrap: {
    cursor: 'pointer',
    padding: '25px 40px',
    fontFamily: 'SFPro',
    fontWeight: 600,
    fontSize: 21,
    color: theme.palette.primary.dark,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    border: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: 10,
  },

  plus: {
    marginRight: 40,
    width: 60,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: '50%',
  },
}));

export default useStyles;
