import Tooltip from '@mui/material/Tooltip';
import cx from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { ROLES_OBJECT } from 'constants/user';

import RouteManager from 'utils/services/route-manager';

import crashModalAtom from 'recoil/crash-modal';
import isMapOpenAtom from 'recoil/mainMap/isOpen';
import userDataAtom from 'recoil/userData';

import DeltaComponent from 'components/DeltaComponent';
import DriverAvatar from 'components/DriverAvatar';
import VehicleInfo from 'components/VehicleInfo';

import Path from '../../components/path';

import useStyles from './styles';

const Header = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation('crash.modal');

  const [{ crashData, driverData }] = useRecoilState(crashModalAtom);
  const userData = useRecoilValue(userDataAtom);
  const resetModal = useResetRecoilState(crashModalAtom);
  const [, setIsMainMapOpen] = useRecoilState(isMapOpenAtom);

  const handleDriverClick = useCallback(() => {
    setIsMainMapOpen(false);
    resetModal();
  }, [resetModal, setIsMainMapOpen]);

  const handleDriverNameClick = useCallback(() => {
    if (
      [
        ROLES_OBJECT.TECH_ADMIN,
        ROLES_OBJECT.ADMIN,
        ROLES_OBJECT.OWNER,
        ROLES_OBJECT.MANAGER,
      ].includes(userData.role) &&
      driverData?.driver?.id
    ) {
      setIsMainMapOpen(false);
      navigate(RouteManager.makeURL('drivers.edit', { id: driverData?.driver?.id }));
      resetModal();
    }
  }, [driverData?.driver?.id, navigate, resetModal, setIsMainMapOpen, userData.role]);

  const handleVehicleClick = useCallback(
    (id) => {
      setIsMainMapOpen(false);
      resetModal();
      if (
        [
          ROLES_OBJECT.TECH_ADMIN,
          ROLES_OBJECT.ADMIN,
          ROLES_OBJECT.OWNER,
          ROLES_OBJECT.MANAGER,
        ].includes(userData.role)
      ) {
        navigate(RouteManager.makeURL('vehicles.edit', { id }));
      }
    },
    [navigate, resetModal, setIsMainMapOpen, userData.role],
  );

  return (
    <section className={classes.section}>
      <div className={classes.logo}>
        {t('accident.label')}: {crashData.crashId}
      </div>
      <div className={classes.info}>
        <div>
          <div className={cx(classes.nameSection, classes.nameSectionOne)}>
            {t('header.driver.label')}
          </div>
          <div className={cx(classes.driver, classes.sectionContent)}>
            <DriverAvatar
              infoIcon={false}
              id={driverData.driver.id}
              isActive={driverData.driver.active}
              currentScoring={driverData.driver.scoreBeforeAccident}
              handleOpen={handleDriverClick}
              size
            />
            <div className={classes.driverInfo}>
              <Tooltip title={`${driverData.driver.firstName} ${driverData.driver.lastName}`} arrow>
                <div className={classes.driverName} onClick={handleDriverNameClick}>
                  <span>{driverData.driver.firstName}</span>
                  <span>{driverData.driver.lastName}</span>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>

        <div className={classes.delta}>
          <div className={classes.nameSection}>{t('header.score.before.accident.label')}</div>
          <div className={classes.sectionContent}>
            <DeltaComponent
              value={driverData.driver.scoreBeforeAccident}
              deltaValue={driverData.driver.diffScore}
              deltaBetter={driverData.driver.better}
            />
          </div>
        </div>

        <div>
          <div className={classes.nameSection}>{t('header.warning.before.accident.label')}</div>
          <div className={cx(classes.cards, classes.sectionContent)}>
            {driverData.driver.penaltyCards?.map((item, i) => (
              <div key={i} className={cx(classes.card, `${classes.card}--${item}`)} />
            ))}
            {!driverData.driver.penaltyCards?.length && <div>-</div>}
          </div>
        </div>

        <div>
          <div className={classes.nameSection}>{t('header.prediction.before.accident.label')}</div>
          <div className={cx(classes.counterPercent, classes.sectionContent)}>
            {driverData.driver.predictionBeforeAccident && (
              <>
                <div>{driverData.driver.predictionBeforeAccident}</div>
                <div className={classes.percent}>%</div>
              </>
            )}
          </div>
        </div>

        <div>
          <div className={classes.nameSection}>{t('header.location.label')}</div>
          <div className={classes.sectionContent}>
            <Path />
          </div>
        </div>

        <div>
          <div className={classes.nameSection}>{t('header.vehicle.label')}</div>
          <div className={classes.sectionContent}>
            <VehicleInfo
              id={driverData.vehicle.id}
              isLittlePlateNumber
              plateNumber={driverData.vehicle.plateNumber}
              brandName={`${driverData.vehicle.brand} ${driverData.vehicle.model}`}
              type={driverData.vehicle.vehicleType}
              country={driverData.vehicle.country}
              handleOpen={() => handleVehicleClick(driverData.vehicle.id)}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

Header.defaultProps = {};

Header.propTypes = {};

export default React.memo(Header);
