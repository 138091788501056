import { atom } from 'recoil';

import { TSensorRenderData } from 'interfaces/sensor/t-sensor-render-data';

const vehicleSensorsListAtom = atom<TSensorRenderData[]>({
  key: 'vehicleSensorsListAtom',
  default: [],
  dangerouslyAllowMutability: true,
});

export default vehicleSensorsListAtom;
