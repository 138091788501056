import cx from 'classnames';
import React, { FC, useMemo } from 'react';

import { SENSOR_TYPES } from 'interfaces/sensor/types';

import { ReactComponent as HumidityIcon } from 'assets/images/humidity.svg';
import { ReactComponent as MovementIcon } from 'assets/images/movement.svg';
import { ReactComponent as TemperatureIcon } from 'assets/images/temperature.svg';

import useStyles from './styles';

interface IProps {
  type: SENSOR_TYPES;
  onClick: () => void;
  selected: boolean;
  penaltiesCount: number;
}

const ParameterButton: FC<IProps> = ({ type, onClick, selected, penaltiesCount }) => {
  const classes = useStyles();

  const Icon = useMemo(() => {
    switch (type) {
      case SENSOR_TYPES.TEMPERATURE:
        return TemperatureIcon;
      case SENSOR_TYPES.MOVEMENT:
        return MovementIcon;
      case SENSOR_TYPES.HUMIDITY:
        return HumidityIcon;
    }
  }, [type]);

  return (
    <div
      className={cx(classes.wrap, { selected, isBad: penaltiesCount > 0 })}
      onClick={onClick}
      data-testid={`sensor-param-${type}`}
    >
      <Icon className={cx(classes.icon, { isBad: penaltiesCount > 0 })} />

      {Boolean(penaltiesCount) && (
        <div className={classes.counter} data-testid='sensor-count'>
          {penaltiesCount}
        </div>
      )}
    </div>
  );
};

export default ParameterButton;
