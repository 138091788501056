import server from 'utils/server';

import { TUserData } from './create-user';

/**
 * Retrieves user request data by ID.
 *
 * @param {string} id - The ID of the user request.
 * @returns {Promise<TUserData>} - A Promise that resolves to the user request data.
 */
export const getUserReq = async (id: string): Promise<TUserData> =>
  server.services.beService.get<TUserData>(`system-users/${id}`).then<TUserData>(({ data }) => ({
    ...data,
    password: data.password ?? '',
  }));
