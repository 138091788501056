import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

const Button = () => {
  const classes = useStyles();
  const { t } = useTranslation('crash.modal');

  return (
    <button className={cx(classes.button, classes.buttonSubmit)} type='submit'>
      {t('submit.label')}
    </button>
  );
};

export default Button;
