import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `0.5px solid ${palette.custom.borderDark}`,
    cursor: 'pointer',
  },

  companyWrap: {
    display: 'grid',
    gridTemplateAreas: `'logo name'
                        'logo type'
                        `,
  },

  companyLogo: {
    gridArea: 'logo',
    marginRight: 10,
    width: 31,
    height: 31,
    backgroundColor: palette.custom.bluePrimary,
    borderRadius: 5,
    backgroundSize: 'cover',
  },

  companyName: {
    gridArea: 'name',
    fontSize: 14,
    color: palette.custom.gray200,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  type: {
    gridArea: 'type',
    fontSize: 12,
    color: palette.custom.gray300,
  },

  additionalInfo: {
    marginLeft: 3,
    padding: '5px 8.5px',
    fontSize: 14,
    color: palette.custom.gray300,
    backgroundColor: palette.custom.mainLight,
    borderRadius: 5,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 110,
    width: 'fit-content',

    '&:empty': {
      display: 'none',
    },
  },
}));

export default useStyles;
