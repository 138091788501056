import { ApexOptions } from 'apexcharts';

import { palette } from 'assets/theme';

const createOptions = (
  isBig: boolean = false,
  isNegativeSaving: boolean = false,
  isNegativePotential: boolean = false,
  saved: number,
  potential: number,
): ApexOptions => {
  const renderedSeries = !saved || !potential ? 0 : Math.round((saved / potential) * 100);

  return {
    series: [renderedSeries === 0 ? 1 : renderedSeries],
    chart: {
      height: '100%',
      width: '100%',
      type: 'radialBar',
      offsetY: 0,
      selection: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        offsetX: 0,
        offsetY: 0,
        hollow: {
          margin: 0,
          size: isBig ? '70%' : '80%',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
        },
        track: {
          strokeWidth: '100%',
          margin: 0,
          background: isNegativePotential
            ? palette.custom.redSecondary
            : palette.custom.greenSecondary,
          opacity: 0.3,
        },
        dataLabels: {
          show: false,
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'horizontal',
        inverseColors: false,
        colorStops: [
          {
            offset: 0,
            color:
              renderedSeries === 0
                ? 'transparent'
                : isNegativeSaving
                ? palette.custom.redSecondary
                : palette.custom.greenSecondary,
            opacity: 1,
          },
          {
            offset: 100,
            color:
              renderedSeries === 0
                ? 'transparent'
                : isNegativeSaving
                ? palette.custom.redSecondary
                : palette.custom.greenSecondary,
            opacity: 1,
          },
        ],
      },
    },
    stroke: {
      lineCap: 'round',
    },
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    labels: ['Percent'],
    grid: {
      padding: {
        top: 10,
      },
    },
  };
};

export default createOptions;
