import { TFunction } from 'react-i18next';

const getColumnWidth = (widthInPX: number) => {
  const tableWidth = 1230;

  return `${(widthInPX / tableWidth) * 100}%`;
};

export const TABLE_HEAD_COLUMNS = (t: TFunction<['setting.drivers.page', 'setting.page']>) =>
  [
    {
      id: 'firstName',
      label: t('drivers.table.header.label'),
      styles: { minWidth: 135, width: getColumnWidth(400) },
      align: 'left',
      isSort: true,
    },
    {
      id: 'companyName',
      label: t('company.table.header.label'),
      styles: { minWidth: 250, width: getColumnWidth(253) },
      align: 'center',
      isSort: false,
    },
    {
      id: 'managerName',
      label: t('manager.table.header.label'),
      styles: { minWidth: 250, width: getColumnWidth(253) },
      align: 'center',
      isSort: false,
    },
    {
      id: 'createdAt',
      label: t('registration.table.header.label'),
      styles: { minWidth: 250, width: getColumnWidth(253) },
      align: 'center',
      isSort: true,
    },
  ] as const;
