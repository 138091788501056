import i18next from 'i18next';
import { ChangeEvent } from 'react';

import { getTranslationReq } from 'requests/translation-service/get-translation';
import { updateTranslationReq } from 'requests/translation-service/update-translation';

export enum TRANSLATION_SERVICE_LANG {
  translationAz = 'translationAz',
  translationEn = 'translationEn',
}

interface ITranslationData {
  [key: string]: {
    key: string;
    [TRANSLATION_SERVICE_LANG.translationAz]: string;
    [TRANSLATION_SERVICE_LANG.translationEn]: string;
  };
}

/**
 * Translations service
 */
export class TranslationsService {
  /**
   * @type {TranslationsService}
   */
  static myInstance: TranslationsService | null = null;

  /**
   * Variants of language versions
   * @type {{az: string, eng: string}}
   */
  static langs = {
    az: TRANSLATION_SERVICE_LANG.translationAz,
    eng: TRANSLATION_SERVICE_LANG.translationEn,
  };

  /**
   * Name of editable page
   * @type {string}
   */
  pageName = '';

  /**
   * Data for rendering
   * @type {{}}
   * @private
   */
  renderData: ITranslationData = {};

  /**
   * Data for sending to API
   * @type {{}}
   * @private
   */
  sendData: ITranslationData = {};

  /**
   * @return {TranslationsService}
   */
  static getInstance() {
    if (this.myInstance === null) {
      this.myInstance = new TranslationsService();
    }

    return this.myInstance;
  }

  /**
   * Get translations for selected page
   * @param {string} page
   * @returns {Promise<void>}
   */
  getTranslationData = async (page: string) => {
    try {
      this.pageName = page;
      const engTranslations = await getTranslationReq(this.pageName, 'en');
      const azTranslations = await getTranslationReq(this.pageName, 'az');
      this.renderData = engTranslations.reduce((result, item) => {
        result[item.key] = {
          [TRANSLATION_SERVICE_LANG.translationEn]: item.value,
          [TRANSLATION_SERVICE_LANG.translationAz]:
            azTranslations?.find(({ key }) => key === item.key)?.value ?? '',
          key: item.key,
        };

        return result;
      }, {} as ITranslationData);
    } catch (error) {
      return error;
    }
  };

  /**
   * @returns {{}}
   */
  getRenderData() {
    return this.renderData;
  }

  /**
   * Updating data arrays for rendering and sending to API
   * @param {string} key
   * @param {string} lang
   * @param event
   */
  onChangeInput(
    key: string,
    lang: TRANSLATION_SERVICE_LANG,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    this.sendData[key] = { ...this.renderData[key], [lang]: event.target.value };
    this.renderData[key] = { ...this.renderData[key], [lang]: event.target.value };
  }

  /**
   * Send data to API
   */
  submit = async () => {
    try {
      await updateTranslationReq(this.pageName, Object.values(this.sendData));
      await i18next.reloadResources();
    } catch (error) {
      return error;
    }
  };

  clearData() {
    this.sendData = {};
    this.renderData = {};
    this.pageName = '';
  }
}
