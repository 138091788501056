import queryString from 'query-string';

import { CHART_TABS } from 'interfaces/scoring/chart-tabs';

import server from 'utils/server';

interface IScoringGraphApiPoint {
  [key: string]: number;
}

export interface IScoringGraphApi {
  companyData: IScoringGraphApiPoint;
  competitorData: IScoringGraphApiPoint;
}

/**
 * Fetches data for a graph for a specific time.
 *
 * @param {CHART_TABS} type - The type of graph to fetch data for.
 * @returns {Promise<IScoringGraphApi>} A promise that resolves to the fetched graph data.
 */
export const getDataForGraphForYear = async (type: CHART_TABS): Promise<IScoringGraphApi> => {
  const params = {
    graphType: type,
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
  };

  return server.services.beService
    .get<IScoringGraphApi>('scoring/annual/chart?' + queryString.stringify(params))
    .then(({ data }) => data);
};
