import { ITeamData } from 'interfaces/teams/i-team-data';

import server from 'utils/server';

/**
 * Creates a team with the given name.
 *
 * @param {string} name - The name of the team.
 * @returns {Promise<ITeamData>} - A promise that resolves to the created team data.
 */
export const createTeamReq = async (name: string): Promise<ITeamData> => {
  const body = {
    name,
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
  };

  return server.services.beService.post<ITeamData>('teams', body).then((res) => res.data);
};
