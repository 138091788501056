import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PlusIcon } from 'assets/images/plus.svg';

import useStyles from './styles';

interface IAddButton {
  onClick: () => void;
}

const AddButton: FC<IAddButton> = ({ onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation('fields');

  return (
    <div className={classes.wrap} onClick={onClick} data-testid='add-button'>
      <div className={classes.plus}>
        <PlusIcon />
      </div>
      <div>{t('add.label')}</div>
    </div>
  );
};

export default AddButton;
