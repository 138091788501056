import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  leader: {
    color: theme.palette.primary.main,
    padding: '30px 19px 13px',
  },

  delta: {
    display: 'inline-flex',
    alignItems: 'center',
    height: '32px',
    columnGap: 8,
  },

  value: {
    fontSize: '45.88px',
    lineHeight: '54px',
    fontWeight: 500,
  },

  name: {
    marginTop: '11px',
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 300,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

export default useStyles;
