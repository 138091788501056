import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    alignItems: 'flex-start',
    columnGap: 10,
  },

  info: {
    '& > div:last-child': {
      marginTop: 12,
    },
  },

  address: {
    fontSize: 16,
    fontWeight: 400,
    color: palette.custom.blue200,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 325,
  },

  date: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.primary.dark,
  },
}));

export default useStyles;
