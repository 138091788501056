import cx from 'classnames';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { DEVICE_FUNCTION } from 'interfaces/vehicles/device-functions';

import RouteManager from 'utils/services/route-manager';

import liveTrackingDriverDescriptionAtom from 'recoil/live-tracking/driver-description';

import ScoringBox from '../ScoringBox';
import VehicleInfo from '../vehicle-info-v2';

import useStyles from './styles';

const DriverInfo = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [{ driverInfo, scoring, vehicleInfo, diffScoring, isShowSensors }, setDriverDescription] =
    useRecoilState(liveTrackingDriverDescriptionAtom);

  const hasSensors = vehicleInfo?.deviceFunctions.includes(
    DEVICE_FUNCTION.TEMPERATURE_HUMIDITY_SENSOR,
  );

  const openSensorsHandler = useCallback(() => {
    if (!hasSensors) {
      if (!vehicleInfo?.id) return;

      navigate(RouteManager.makeURL('vehicles.edit', { id: vehicleInfo?.id }));
      return;
    }
    setDriverDescription((prevState) => {
      return {
        ...prevState,
        isShowSensors: true,
      };
    });
  }, [hasSensors, navigate, setDriverDescription, vehicleInfo?.id]);

  const onClickDriver = useCallback(() => {
    if (driverInfo?.id) {
      navigate(RouteManager.makeURL('drivers.trips', { id: driverInfo?.id }));
    }
  }, [driverInfo?.id, navigate]);

  return (
    <div className={classes.wrap} data-testid='driver-info-wrap'>
      <div className={classes.leftWrap}>
        <div className={classes.driverName} onClick={onClickDriver} data-testid='driverName'>
          {`${driverInfo?.firstName} ${driverInfo?.lastName}`}
        </div>
        {scoring !== null && <ScoringBox scoring={scoring} diffScoring={diffScoring} />}
      </div>

      {vehicleInfo && (
        <VehicleInfo
          vehicleType={vehicleInfo.vehicleType}
          plateNumber={vehicleInfo.plateNumber}
          model={vehicleInfo.modelName}
          brand={vehicleInfo.brandName}
          country={vehicleInfo.country}
          openSensorsHandler={openSensorsHandler}
          hasAlarm={vehicleInfo.hasAlarm}
          isShowSensors={isShowSensors}
          wrapClass={cx(classes.vehicleInfo, { hasSensors })}
        />
      )}
    </div>
  );
};

DriverInfo.propTypes = {};

DriverInfo.defaultProps = {};

export default DriverInfo;
