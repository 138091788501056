import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    marginLeft: 15,
    marginTop: 15,
  },

  title: {
    fontSize: 16,
    fontWeight: 900,
    lineHeight: '19px',
    color: palette.custom.blue200,
    marginBottom: 15,
  },

  alarms: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: 5,
  },
}));

export default useStyles;
