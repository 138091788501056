import { ISelectItem } from 'interfaces/common/select-items';
import { IVehicleBrand } from 'interfaces/vehicles/brand';

import server from 'utils/server';

/**
 * Retrieves the list of vehicle brands.
 * @async
 * @function vehicleBrandsReq
 * @returns {Promise<ISelectItem<number>[]>} - A promise that resolves to an array of objects representing vehicle brands.
 * Each object has a `value` property which is the brand ID, and a `label` property which is the brand name.
 */
export const vehicleBrandsReq = async (): Promise<ISelectItem<number>[]> => {
  return server.services.beService
    .get<IVehicleBrand[]>('brands')
    .then<ISelectItem<number>[]>((res) =>
      res.data.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    );
};
