import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
  },

  input: {
    padding: '0 0 26px',
    margin: '-10px 0 10px 0',
    color: theme.palette.primary.main,
  },

  placeholder: {
    marginTop: '-5px',
    fontFamily: 'SFText',
    color: theme.palette.primary.dark,
    fontSize: '18px',
    lineHeight: '21.48px',
  },

  underline: {
    '&::before': {
      borderBottom: `1px solid ${palette.custom.lightBlue} !important`,
    },
    '&::after': {
      borderBottom: `2px solid ${theme.palette.primary.main} !important`,
    },
  },
}));

export default useStyles;
