import { format, set } from 'date-fns';

import { IDriverData } from 'interfaces/driver/i-driver-data';

import { TDriverCreateData } from 'modules/DriverProfile/form-schema';

// TODO: need refactor types

export const driverFormDTO = {
  post: (data: TDriverCreateData): TDriverCreateData => {
    const formattedDriverVehicleDto = {
      ...data.driverVehicleDto,
      startAt: data.driverVehicleDto.startAt
        ? format(data.driverVehicleDto.startAt, 'yyyy-MM-dd')
        : null,
      finishAt: data.driverVehicleDto.finishAt
        ? format(data.driverVehicleDto.finishAt, 'yyyy-MM-dd')
        : null,
    } as IDriverData['driverVehicleDto'];

    return {
      ...data,
      ...formattedDriverVehicleDto,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      expireDate: data.expireDate ? format(data.expireDate, 'yyyy-MM-dd') : null,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      startWorkingTime: data.startWorkingTime ? format(data.startWorkingTime, 'hh:mm:ss') : null,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      finishWorkingTime: data.finishWorkingTime ? format(data.finishWorkingTime, 'hh:mm:ss') : null,
    };
  },
  get: (data: TDriverCreateData): TDriverCreateData => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const startWorkingTime = data.startWorkingTime?.split(':');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const finishWorkingTime = data.finishWorkingTime?.split(':');

    const date = new Date();

    const formattedStatDate = startWorkingTime
      ? set(date, {
          hours: startWorkingTime[0],
          minutes: startWorkingTime[1],
          seconds: 0,
        })
      : null;
    const formattedFinishDate = finishWorkingTime
      ? set(date, {
          hours: finishWorkingTime[0],
          minutes: finishWorkingTime[1],
          seconds: 0,
        })
      : null;

    return {
      ...data,
      startWorkingTime: formattedStatDate,
      finishWorkingTime: formattedFinishDate,
    };
  },
};
