import { atom } from 'recoil';

const teamsListPagesAtom = atom({
  key: 'teamsListPagesAtom',
  default: {
    currentPage: 0,
    totalPages: 0,
  },
});

export default teamsListPagesAtom;
