import Tooltip from '@mui/material/Tooltip';
import React, { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { ACTIVITY_STATUSES } from 'interfaces/common/activity-statuses';

import { ReactComponent as VehicleActiveIcon } from 'assets/images/dashboard-map/vehicle-active.svg';
import { ReactComponent as VehicleIdliIcon } from 'assets/images/dashboard-map/vehicle-idli.svg';
import { ReactComponent as VehicleParkedIcon } from 'assets/images/dashboard-map/vehicle-parked.svg';

import RouteManager from 'utils/services/route-manager';

import dashboardMapLayoutSettingsAtom from 'recoil/dashboard-map/toolbar/layout-setting';

import useStyles from './styles';

interface IProps {
  status: ACTIVITY_STATUSES;
  driverName: string;
  plateNumber: string;
  angle: number;
  vehicleId: string;
  disabledInfo?: boolean;
}

const VehicleMarker: FC<IProps> = ({
  status,
  driverName,
  plateNumber,
  angle,
  vehicleId,
  disabledInfo = false,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dashboardMapLayoutSettings = useRecoilValue(dashboardMapLayoutSettingsAtom);

  const Icon = useMemo(() => {
    switch (status) {
      case ACTIVITY_STATUSES.ACTIVE:
        return VehicleActiveIcon;
      case ACTIVITY_STATUSES.INACTIVE:
        return VehicleParkedIcon;
      case ACTIVITY_STATUSES.IDLING:
        return VehicleIdliIcon;
    }
  }, [status]);

  /**
   * Get data by selected show params
   */
  const info = useMemo(() => {
    return dashboardMapLayoutSettings.showParams.map((item) => {
      if (item === 'showDriverInfo') {
        return driverName;
      }
      if (item === 'showVehicleInfo') {
        return plateNumber;
      }
      return item;
    });
  }, [dashboardMapLayoutSettings.showParams, driverName, plateNumber]);

  const iconStyles = useMemo(() => {
    if (status === ACTIVITY_STATUSES.ACTIVE) {
      return {
        transform: `rotate(${angle}deg)`,
      };
    }
  }, [angle, status]);

  const onClickHandler = useCallback(
    () => navigate(RouteManager.makeURL('dashboardMap.live', { id: vehicleId })),
    [navigate, vehicleId],
  );

  return (
    <div
      className={classes.wrap}
      onClick={onClickHandler}
      data-testid={`vehicle-marker-${vehicleId}`}
    >
      <Icon style={iconStyles} />
      {!disabledInfo && (
        <div className={classes.info}>
          {info.map((item) => (
            <Tooltip key={item} title={item} arrow>
              <div>{item}</div>
            </Tooltip>
          ))}
        </div>
      )}
    </div>
  );
};

export default VehicleMarker;
