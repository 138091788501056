import { IVehicleBrand } from 'interfaces/vehicles/brand';

import server from 'utils/server';

/**
 * Renames the vehicle brand with the given ID to the new value.
 *
 * @async
 * @param {number} id - The ID of the vehicle brand to rename.
 * @param {string} value - The new value to rename the vehicle brand to.
 * @returns {Promise<IVehicleBrand>} - A promise that resolves with the renamed vehicle brand object.
 *
 * @throws {Error} - If an error occurs while renaming the vehicle brand.
 */
export const renameVehicleBrandReq = async (id: number, value: string): Promise<IVehicleBrand> => {
  const body = {
    name: value,
  };
  return server.services.beService.put<IVehicleBrand>(`brands/${id}`, body).then((res) => res.data);
};
