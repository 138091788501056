import { COUNTRIES } from 'interfaces/common/countries';
import { CURRENCY } from 'interfaces/common/currency';

export const currencyList = [
  { label: 'AZN', value: 'AZN' },
  { label: 'GBP', value: 'GBP' },
  { label: 'USD', value: 'USD' },
  { label: 'EUR', value: 'EUR' },
];

export const CURRENCY_ICONS = {
  AZN: '₼',
  GBP: '£',
  USD: '$',
  EUR: '€',
};

export const countryDefaultCurrency = {
  [COUNTRIES.AT]: CURRENCY.EUR,
  [COUNTRIES.AZ]: CURRENCY.AZN,
  [COUNTRIES.UK]: CURRENCY.GBP,
  [COUNTRIES.US]: CURRENCY.USD,
};
