import { FC } from 'react';

import useStyles from './styles';

interface IProps {
  map: google.maps.Map;
  zoom: number;
}

const zoomMax = 22;
const zoomMin = 0;

const ZoomControls: FC<IProps> = ({ map, zoom }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrap}>
      <button
        className={classes.button}
        disabled={zoom >= zoomMax}
        onClick={() => {
          map.setZoom(zoom + 1);
        }}
      >
        +
      </button>
      <div className={classes.line}></div>
      <button
        className={classes.button}
        disabled={zoom <= zoomMin}
        onClick={() => {
          map.setZoom(zoom - 1);
        }}
      >
        -
      </button>
    </div>
  );
};

export default ZoomControls;
