import queryString from 'query-string';

import { ACTIVITY_STATUSES } from 'interfaces/common/activity-statuses';
import { COUNTRIES } from 'interfaces/common/countries';
import { PENALTY_CARD } from 'interfaces/common/penalty-card';
import { DEVICE_FUNCTION } from 'interfaces/vehicles/device-functions';
import { VEHICLE_TYPES } from 'interfaces/vehicles/types';

import server from 'utils/server';

export type TDriverShortFilters = {
  movementAtFrom: number;
  movementAtTill: number;
};

// TODO: need refactor after change API
export interface IDriverShortData {
  driverId: string;
  companyId: string;
  firstName: string;
  lastName: string;
  phone: null;
  currentScoring: null;
  diffScoring: null | number;
  better: boolean;
  lastPointReceivedAt: null;
  imei: null;
  penaltyCards: null | PENALTY_CARD[];
  driverCharacteristic: null;
  active: boolean;
  brandName: null;
  modelName: null;
  fuelUsed: null | number;
  country: null;
  plateNumber: null;
  type: null;
  vehicleId: null;
  penalties: null | number;
  totalKm: null | number;
  dailyKm: null | number;
  dailyH: null | number;
  rating: boolean;
  basic: null | number;
  bonus: null | number;
  vehicle: {
    id: string;
    vehicleId: string;
    plateNumber: string;
    engine: string;
    vin: string;
    creationTime: number;
    country: COUNTRIES;
    vehicleType: VEHICLE_TYPES;
    deviceFunctions: DEVICE_FUNCTION[];
  };
  crashCount: null | number;
  hasNonValidatedCrashes: boolean;
  idling: null | number;
  engineMinutes: null | number;
  status: ACTIVITY_STATUSES;
  companyName: null;
  managerName: null;
  registrationDate: null;
}

/**
 * Returns the short data of a driver.
 *
 * @param {string} id - The ID of the driver.
 * @param {TDriverShortFilters} filters - Filters to be applied to the driver's data (optional).
 * @returns {Promise<IDriverShortData>} - A Promise that resolves to the short data of the driver.
 */
export const driverShortReq = async (
  id: string,
  filters: TDriverShortFilters | null,
): Promise<IDriverShortData> => {
  let url = `drivers/${id}/short`;

  if (filters) {
    url += `?${queryString.stringify(filters)}`;
  }

  return server.services.beService
    .get<IDriverShortData>(url)
    .then<IDriverShortData>(({ data }) => data);
};
