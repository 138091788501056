import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  team: {
    fontSize: 20,
    fontWeight: 500,
    color: palette.custom.blue200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    columnGap: 20,
    padding: '20px 30px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    borderRadius: 10,

    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.15),
    },
  },

  icon: {
    width: 12,

    '& path': {
      stroke: palette.custom.blue200,
    },
  },
}));

export default useStyles;
