import { atom } from 'recoil';

import { ITeamMember } from 'interfaces/teams/i-team-member';

const teamMembersListAtom = atom<ITeamMember[]>({
  key: 'teamMembersListAtom',
  default: [],
});

export default teamMembersListAtom;
