import { StyledEngineProvider } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import useTableSort from 'hooks/useTableSort';

import { IUserData } from 'interfaces/user/user-data';

import { ROLES_OBJECT } from 'constants/user';

import { scoreCircle } from 'utils/helpers/score-circle';
import RouteManager from 'utils/services/route-manager';

import savingDriversAtom from 'recoil/saving/drivers';
import savingFilterAtom from 'recoil/saving/filter';
import { SORT_DIRECTION } from 'recoil/settings/payment/transactions/sort';
import userConfigAtom from 'recoil/user-config';
import userDataAtom from 'recoil/userData';

import { getDriversData } from 'requests/be-service/saving-controller/get-driver-breakdown';

import CircleListMarker from 'components/CircleListMarker';
import Loader from 'components/Loader';
import Money from 'components/money';
import TableSortLabel from 'components/TableSortLabel';

import useStyles from './styles';

const Drivers = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation('saving.page');
  const [isLoading, setIsLoading] = useState(false);
  const [savingDrivers, setSavingDrivers] = useRecoilState(savingDriversAtom);
  const savingFilter = useRecoilValue(savingFilterAtom);
  const userData = useRecoilValue(userDataAtom) as IUserData;
  const { currencyIcon } = useRecoilValue(userConfigAtom);
  const [driversSort, setDriversSort] = useTableSort({ sort: '', direction: SORT_DIRECTION.DESC });

  useEffect(() => {
    const request = async () => {
      try {
        setIsLoading(true);
        const res = await getDriversData(savingFilter, driversSort);

        setSavingDrivers(res);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    request();
  }, [driversSort, savingFilter, setSavingDrivers]);

  const handleCircleMarker = (vehicleId: string) => {
    if ([ROLES_OBJECT.TECH_ADMIN, ROLES_OBJECT.ADMIN, ROLES_OBJECT.OWNER].includes(userData.role)) {
      navigate(RouteManager.makeURL('dashboardMap.live', { id: vehicleId }));
    }
  };

  const handleDriver = (driverId: string) => {
    if ([ROLES_OBJECT.TECH_ADMIN, ROLES_OBJECT.ADMIN, ROLES_OBJECT.OWNER].includes(userData.role)) {
      navigate(RouteManager.makeURL('drivers.trips', { id: driverId }));
    }
  };

  if (isLoading) {
    return (
      <div className={classes.wrap} data-testid='drivers-main'>
        <Loader width={100} lightMode isBlock />
      </div>
    );
  }

  if (!savingDrivers || savingDrivers.length === 0) {
    return (
      <div className={classes.wrap} data-testid='drivers-main'>
        <div className={classes.emptyLabel}>{t('no.data.label')}</div>
      </div>
    );
  }

  return (
    <div className={classes.wrap} data-testid='drivers-main'>
      <StyledEngineProvider injectFirst>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={cx(classes.cell, classes.sorting)} data-testid='table-cell'>
                  <TableSortLabel
                    name='direction'
                    onClick={setDriversSort}
                    sortField={driversSort.sort}
                    direction={driversSort.direction}
                  >
                    {t('rankings.label')}
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tbody} data-testid='drivers'>
              {savingDrivers.map((item, i) => {
                const saving = Number(item.savings) ? +item.savings.toFixed(1) : item.savings;

                return (
                  <TableRow
                    className={classes.row}
                    key={`${item.id}`}
                    data-testid={`driver-row-${item.id}`}
                  >
                    <TableCell
                      className={cx(classes.cell, classes.number)}
                      data-testid='driver-number'
                    >
                      {i + 1}
                    </TableCell>
                    <TableCell
                      className={cx(classes.cell, classes.scoring)}
                      data-testid='driver-scoring'
                    >
                      <div
                        onClick={() => handleCircleMarker(item.vehicleId)}
                        className={cx(
                          classes.circleMarker,
                          item.active && classes.activeCircleMarker,
                        )}
                        data-testid='circle-marker'
                      >
                        <CircleListMarker
                          marked={item.active}
                          color={scoreCircle(item.currentScoring)}
                        />
                      </div>
                    </TableCell>
                    <TableCell className={cx(classes.name, classes.cell)} data-testid='driver-name'>
                      <Tooltip title={`${item.firstName} ${item.lastName}`} arrow>
                        <div
                          onClick={() => handleDriver(item.id)}
                          className={classes.nameLink}
                          data-testid='name-link'
                        >
                          <div>{item.firstName}</div>
                          <div>{item.lastName}</div>
                        </div>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={cx(classes.saving, classes.cell)}
                      data-testid='driver-saving'
                    >
                      <Money value={saving} currencyIcon={currencyIcon} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledEngineProvider>
    </div>
  );
};

export default Drivers;
