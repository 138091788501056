import cx from 'classnames';
import React, { FC } from 'react';

import { ReactComponent as LoaderIcon } from 'assets/images/loader.svg';

import useStyles from './styles';

interface ILoader {
  lightMode?: boolean;
  preventClick?: boolean;
  isBlock?: boolean;
  width: number;
}

type Props = ILoader;

const Loader: FC<Props> = ({ lightMode = false, preventClick = false, isBlock = false, width }) => {
  const classes = useStyles();

  return (
    <>
      {preventClick && <div className={cx(classes.overlay)} />}
      <LoaderIcon
        className={cx(classes.loader, lightMode && classes.light, isBlock && classes.block)}
        style={{ width }}
      />
    </>
  );
};

export default React.memo(Loader);
