import queryString from 'query-string';

import { ISavingCircleData } from 'interfaces/saving/i-saving-circle-data';

import server from 'utils/server';

/**
 * Fetches scoring saving data from the server.
 *
 * @async
 * @returns {Promise<ISavingCircleData>} A promise that resolves to the scoring saving data.
 */
export const scoringSavingReq = async (): Promise<ISavingCircleData> => {
  const params = {
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
  };

  return server.services.beService
    .get<ISavingCircleData>('scoring/savings?' + queryString.stringify(params))
    .then((res) => res.data);
};
