import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import subMonths from 'date-fns/subMonths';
import queryString from 'query-string';

import { IScoringDriver } from 'interfaces/scoring/i-scoring-driver';

import { convertToUtcTimestamp } from 'utils/helpers/convert-to-utc-timestamp';
import { hundredRangeFormat } from 'utils/helpers/hundred-range-format';
import server from 'utils/server';

/**
 * Retrieves the best driver's scoring information.
 *
 * @returns {Promise<IScoringDriver>} A promise that resolves to an object containing the best driver's scoring data.
 */
export const scoringBestDriverReq = async (): Promise<IScoringDriver> => {
  const params = {
    startAt: convertToUtcTimestamp(subMonths(startOfDay(new Date()), 1).getTime()),
    finishAt: convertToUtcTimestamp(endOfDay(new Date()).getTime()),
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
  };

  return server.services.beService
    .get<IScoringDriver>('scoring/drivers/best?' + queryString.stringify(params))
    .then<IScoringDriver>(({ data }) => ({
      ...data,
      currentScoring: hundredRangeFormat(Math.round(data.currentScoring)),
      diffScoring: hundredRangeFormat(Math.round(data.diffScoring)),
    }));
};
