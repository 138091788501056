import { atom } from 'recoil';

import { COUNTRIES } from 'interfaces/common/countries';
import { DEVICE_FUNCTION } from 'interfaces/vehicles/device-functions';
import { VEHICLE_TYPES } from 'interfaces/vehicles/types';

interface ILiveTrackingDriverDescription {
  diffScoring?: number;
  scoring: number | null;
  driverInfo: {
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    phonePrefix: string;
    sosPhoneNumber?: string;
    sosPhonePrefix?: string;
  } | null;
  vehicleInfo: {
    id: string;
    vehicleType: VEHICLE_TYPES;
    plateNumber: string;
    brandName: string;
    modelName: string;
    country: COUNTRIES;
    imei: string;
    deviceFunctions: DEVICE_FUNCTION[];
    hasAlarm: boolean;
  } | null;
  isShowSensors: boolean;
}

const liveTrackingDriverDescriptionAtom = atom<ILiveTrackingDriverDescription>({
  key: 'liveTrackingDriverDescriptionAtom',
  default: {
    vehicleInfo: null,
    driverInfo: null,
    scoring: null,
    isShowSensors: false,
  },
});

export default liveTrackingDriverDescriptionAtom;
