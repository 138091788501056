/**
 * Enum representing various activity statuses.
 *
 * @enum {string}
 */
export enum ACTIVITY_STATUSES {
  ACTIVE = 'ACTIVE',
  IDLING = 'IDLING',
  INACTIVE = 'INACTIVE',
}
