import React, { useMemo, FC } from 'react';

import { TNavigationSearchItem } from 'interfaces/navigation/searched-item';
import { SEARCHED_TYPES } from 'interfaces/navigation/searched-types';

import { palette } from 'assets/theme';

import HighlightedText from 'components/highlighted-text';

import useStyles from './styles';

interface ISearchedItem {
  searchValue: string;
  onClick: () => void;
  data: TNavigationSearchItem;
}

const SearchedItem: FC<ISearchedItem> = ({
  onClick,
  data: { driverLastName, imei, type, companyName, companyIconUrl, driverFirstName, plateNumber },
  searchValue,
}) => {
  const classes = useStyles();

  const renderType = useMemo(() => {
    switch (type) {
      case SEARCHED_TYPES.IMEI:
        return 'IMEI';

      case SEARCHED_TYPES.DRIVER:
        return 'Driver';

      case SEARCHED_TYPES.COMPANY:
        return 'Company';

      case SEARCHED_TYPES.PLATENUMBER:
        return 'Plate';

      default:
        return null;
    }
  }, [type]);

  const additionalInfo = useMemo(() => {
    switch (type) {
      case SEARCHED_TYPES.IMEI:
        return imei;

      case SEARCHED_TYPES.DRIVER:
        return `${driverFirstName} ${driverLastName}`;

      case SEARCHED_TYPES.COMPANY:
        return null;

      case SEARCHED_TYPES.PLATENUMBER:
        return plateNumber;

      default:
        return null;
    }
  }, [driverFirstName, driverLastName, imei, plateNumber, type]);

  return (
    <div className={classes.wrap} onClick={onClick} data-testid='searched-item'>
      <div className={classes.companyWrap}>
        <div
          className={classes.companyLogo}
          style={{ backgroundImage: `url(${companyIconUrl})` }}
          data-testid='company-logo'
        />
        <div className={classes.companyName} title={companyName} data-testid='company-name'>
          {companyName}
        </div>
        <div className={classes.type} data-testid='searched-item-type'>
          {renderType}
        </div>
      </div>
      <div
        className={classes.additionalInfo}
        title={additionalInfo ?? ''}
        data-testid='additional-info'
      >
        {additionalInfo && (
          <HighlightedText
            text={additionalInfo}
            highlight={searchValue}
            highlightColor={palette.custom.smokyWhite}
          />
        )}
      </div>
    </div>
  );
};

export default SearchedItem;
