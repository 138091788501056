import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  addBtn: {
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: palette.custom.mainDark,
    borderBottom: `1px solid ${palette.custom.lightBlue}`,
    boxShadow: '0 5px 5px 5px rgba(45, 55, 81, 0.3)',
    cursor: 'pointer',
  },

  wrap: {
    margin: '0 auto',
    width: 400,
    color: theme.palette.primary.light,
  },
}));

export default useStyles;
