import React, { useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { driverTripsUrlRegExp } from 'constants/driver';

import { getObjectFillValuesCount } from 'utils/helpers/get-object-fill-values-count';
import RouteManager from 'utils/services/route-manager';

import driversFiltersDataAtom from 'recoil/driver/drivers-filters';
import driverTripsFilterAtom from 'recoil/drivers-vehicles/driver-trips-filters';
import driverTripsSelectedPeriodAtom from 'recoil/drivers-vehicles/driver-trips-selected-period';
import driversSelectedPeriodAtom from 'recoil/drivers-vehicles/drivers-selected-period';
import driversVehiclesShowDateFilterAtom from 'recoil/drivers-vehicles/show-date-filter';
import showDriverTripsDateFilterAtom from 'recoil/drivers-vehicles/show-driver-trip-date-filter';
import driversVehiclesShowDriversFilterAtom from 'recoil/drivers-vehicles/show-drivers-filter';
import driversVehiclesShowReportFilterAtom from 'recoil/drivers-vehicles/show-report-filters';
import vehiclesShowDateFilterAtom from 'recoil/drivers-vehicles/show-vehicles-date-filter';
import driversVehiclesShowVehiclesFilterAtom from 'recoil/drivers-vehicles/show-vehicles-filter';
import vehiclesFilterAtom from 'recoil/drivers-vehicles/vehicles-filters';
import vehiclesSelectedPeriodAtom from 'recoil/drivers-vehicles/vehicles-selected-period';

import Header from './components/header';
import { CALENDAR_DEFAULT_TYPES } from './components/header/data';
import Tabs from './components/tabs';
import useStyles from './styles';

const driversPath = `/${RouteManager.path('drivers')}`;
const vehiclesPath = `/${RouteManager.path('vehicles')}`;
const DriversVehicles = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const setShowReportFilter = useSetRecoilState(driversVehiclesShowReportFilterAtom);
  const setShowDriversFilter = useSetRecoilState(driversVehiclesShowDriversFilterAtom);
  const setShowVehiclesFilter = useSetRecoilState(driversVehiclesShowVehiclesFilterAtom);
  const [driverFiltersData, setDriverFiltersData] = useRecoilState(driversFiltersDataAtom);
  const setShowDriversDateFilter = useSetRecoilState(driversVehiclesShowDateFilterAtom);
  const setShowDriverTripsDateFilter = useSetRecoilState(showDriverTripsDateFilterAtom);
  const setVehiclesShowDateFilter = useSetRecoilState(vehiclesShowDateFilterAtom);
  const setDriverTripsFilter = useSetRecoilState(driverTripsFilterAtom);
  const [vehiclesFilter, setVehiclesFilter] = useRecoilState(vehiclesFilterAtom);

  const [driversSelectedPeriod, setDriversSelectedPeriod] =
    useRecoilState(driversSelectedPeriodAtom);
  const [driverTripsSelectedPeriod, setDriverTripsSelectedPeriod] = useRecoilState(
    driverTripsSelectedPeriodAtom,
  );
  const [vehiclesSelectedPeriod, setVehiclesSelectedPeriod] = useRecoilState(
    vehiclesSelectedPeriodAtom,
  );

  const filterHandler = useMemo(() => {
    switch (true) {
      case pathname === driversPath:
        return () => setShowDriversFilter(true);

      case pathname === vehiclesPath:
        return () => setShowVehiclesFilter(true);

      default:
        return null;
    }
  }, [pathname, setShowDriversFilter, setShowVehiclesFilter]);

  const reportHandler = useMemo(() => {
    switch (true) {
      case pathname === driversPath:
        return () => setShowReportFilter(true);

      case driverTripsUrlRegExp.test(pathname):
        return () => setShowReportFilter(true);

      default:
        return null;
    }
  }, [pathname, setShowReportFilter]);

  const searchHandler = useMemo(() => {
    switch (pathname) {
      case driversPath:
        return (data: { query: string }) =>
          setDriverFiltersData((prevState) => ({ ...prevState, ...data }));

      case vehiclesPath:
        return (data: { query: string }) =>
          setVehiclesFilter((prevState) => ({ ...prevState, ...data }));

      default:
        return null;
    }
  }, [pathname, setDriverFiltersData, setVehiclesFilter]);

  const searchValue = useMemo(() => {
    switch (pathname) {
      case driversPath:
        return driverFiltersData.query;

      case vehiclesPath:
        return vehiclesFilter.query;

      default:
        return null;
    }
  }, [driverFiltersData.query, pathname, vehiclesFilter.query]);

  const showDateFiltersHandler = useMemo(() => {
    switch (true) {
      case pathname === driversPath:
        return () => setShowDriversDateFilter(true);

      case driverTripsUrlRegExp.test(pathname):
        return () => setShowDriverTripsDateFilter(true);

      case pathname === vehiclesPath:
        return () => setVehiclesShowDateFilter(true);

      default:
        return null;
    }
  }, [pathname, setShowDriversDateFilter, setShowDriverTripsDateFilter, setVehiclesShowDateFilter]);

  const calendarHandler = useMemo(() => {
    switch (true) {
      case pathname === driversPath:
        return (startDate: number, endDate: number) => {
          setDriverFiltersData((prevState) => ({
            ...prevState,
            movementAtFrom: startDate,
            movementAtTill: endDate,
          }));
          setDriverTripsFilter((prevState) => ({
            ...prevState,
            movementAtFrom: startDate,
            movementAtTill: endDate,
          }));
        };

      case driverTripsUrlRegExp.test(pathname):
        return (startDate: number, endDate: number) =>
          setDriverTripsFilter((prevState) => ({
            ...prevState,
            movementAtFrom: startDate,
            movementAtTill: endDate,
          }));

      case pathname === vehiclesPath:
        return (startDate: number, endDate: number) =>
          setVehiclesFilter((prevState) => ({
            ...prevState,
            movementAtFrom: startDate,
            movementAtTill: endDate,
          }));

      default:
        return null;
    }
  }, [pathname, setDriverFiltersData, setDriverTripsFilter, setVehiclesFilter]);

  const calendarProps = useMemo(() => {
    switch (true) {
      case pathname === driversPath:
        return {
          data: driversSelectedPeriod,
          handler: (id: CALENDAR_DEFAULT_TYPES) => {
            setDriversSelectedPeriod(id);
            setDriverTripsSelectedPeriod(id);
          },
        };

      case driverTripsUrlRegExp.test(pathname):
        return {
          data: driverTripsSelectedPeriod,
          handler: setDriverTripsSelectedPeriod,
        };

      case pathname === vehiclesPath:
        return {
          data: vehiclesSelectedPeriod,
          handler: setVehiclesSelectedPeriod,
        };

      default:
        return {
          data: null,
          handler: null,
        };
    }
  }, [
    driverTripsSelectedPeriod,
    driversSelectedPeriod,
    pathname,
    setDriverTripsSelectedPeriod,
    setDriversSelectedPeriod,
    setVehiclesSelectedPeriod,
    vehiclesSelectedPeriod,
  ]);

  const appliedFiltersCount = useMemo(() => {
    switch (true) {
      case pathname === driversPath:
        return getObjectFillValuesCount(driverFiltersData) - 2;

      case pathname === vehiclesPath:
        return getObjectFillValuesCount(vehiclesFilter) - 2;

      default:
        return 0;
    }
  }, [driverFiltersData, pathname, vehiclesFilter]);

  return (
    <div className={classes.wrap}>
      <Tabs />

      <Header
        reportHandler={reportHandler}
        filterHandler={filterHandler}
        searchHandler={searchHandler}
        calendarHandler={calendarHandler}
        showDateFiltersHandler={showDateFiltersHandler}
        selectedPeriod={calendarProps.data}
        setSelectedPeriod={calendarProps.handler}
        searchValue={searchValue}
        appliedFiltersCount={appliedFiltersCount}
      />

      <Outlet />
    </div>
  );
};

export default DriversVehicles;
