import endOfDay from 'date-fns/endOfDay';
import startOfMonth from 'date-fns/startOfMonth';
import { atom, selector } from 'recoil';

import settingPaymentTransactionsListPaginationSelector from '../pagination';

export interface ISettingPaymentTransactionsFilters {
  startDate: Date | null;
  endDate: Date | null;
}

export const initValues = {
  startDate: startOfMonth(new Date()),
  endDate: endOfDay(new Date()),
};

const settingPaymentTransactionsListFilterAtom = atom<ISettingPaymentTransactionsFilters>({
  key: 'settingPaymentTransactionsListFilterAtom',
  default: initValues,
});

export const settingPaymentTransactionsListFilterSelector =
  selector<ISettingPaymentTransactionsFilters>({
    key: 'settingPaymentTransactionsListFilterSelector',
    get: ({ get }) => {
      return get(settingPaymentTransactionsListFilterAtom);
    },
    set: ({ set, reset }, newValue) => {
      reset(settingPaymentTransactionsListPaginationSelector);
      set(settingPaymentTransactionsListFilterAtom, newValue);
    },
  });

export const settingPaymentTransactionsListFilterCountSelector = selector<number>({
  key: 'settingPaymentTransactionsListFilterCountSelector',
  get: ({ get }) => {
    const filters = get(settingPaymentTransactionsListFilterAtom);

    return Object.values(filters).filter((item) => item).length;
  },
});

export const isOpenSettingPaymentTransactionsListFilterAtom = atom<boolean>({
  key: 'isOpenSettingPaymentTransactionsListFilterAtom',
  default: false,
});
