import React, { FC, useMemo } from 'react';
import ReactTimeAgo from 'react-time-ago';
import { useRecoilValue } from 'recoil';

import { LANGUAGES } from 'interfaces/common/languages';
import { NOTIFICATION_TYPES } from 'interfaces/notifications/notification-types';

import { ReactComponent as BeltIcon } from 'assets/images/notifications/belt.svg';
import { ReactComponent as BootIcon } from 'assets/images/notifications/boot.svg';
import { ReactComponent as CrashIcon } from 'assets/images/notifications/crash.svg';
import { ReactComponent as DoorIcon } from 'assets/images/notifications/door.svg';
import { ReactComponent as FuelIcon } from 'assets/images/notifications/fuel.svg';
import { ReactComponent as HoodIcon } from 'assets/images/notifications/hood.svg';
import { ReactComponent as HumidityIcon } from 'assets/images/notifications/humidity.svg';
import { ReactComponent as IgnitionOffIcon } from 'assets/images/notifications/ignition-off.svg';
import { ReactComponent as IgnitionOnIcon } from 'assets/images/notifications/ignition-on.svg';
import { ReactComponent as OilIcon } from 'assets/images/notifications/oil.svg';
import { ReactComponent as RedCardIcon } from 'assets/images/notifications/red-card.svg';
import { ReactComponent as SensorTemperatureIcon } from 'assets/images/notifications/sensor-temperature.svg';
import { ReactComponent as SpeedIcon } from 'assets/images/notifications/speed.svg';
import { ReactComponent as TemperatureIcon } from 'assets/images/notifications/temperature.svg';
import { ReactComponent as UnplugIcon } from 'assets/images/notifications/unplug.svg';
import { ReactComponent as WindowIcon } from 'assets/images/notifications/window.svg';
import { ReactComponent as YellowCardIcon } from 'assets/images/notifications/yellow-card.svg';

import userDataAtom from 'recoil/userData';

import useStyles from './styles';

interface IMessage {
  type: NOTIFICATION_TYPES;
  message: string;
  isRead: boolean;
  createdAt: number;
  onClick: () => void;
  driverFullName: string;
  vehiclePlateNumber: string;
}

const Message: FC<IMessage> = ({
  type,
  message,
  isRead,
  createdAt,
  onClick,
  driverFullName,
  vehiclePlateNumber,
}) => {
  const classes = useStyles();
  const userData = useRecoilValue(userDataAtom);

  const Icon = useMemo(() => {
    switch (type) {
      case NOTIFICATION_TYPES.RED_CARD:
        return RedCardIcon;

      case NOTIFICATION_TYPES.YELLOW_CARD:
        return YellowCardIcon;

      case NOTIFICATION_TYPES.CRASH:
        return CrashIcon;

      case NOTIFICATION_TYPES.BELT:
        return BeltIcon;

      case NOTIFICATION_TYPES.BOOT:
        return BootIcon;

      case NOTIFICATION_TYPES.DOOR:
        return DoorIcon;

      case NOTIFICATION_TYPES.FUEL_FRAUD:
        return FuelIcon;

      case NOTIFICATION_TYPES.HOOD:
        return HoodIcon;

      case NOTIFICATION_TYPES.OIL_LEVEL:
        return OilIcon;

      case NOTIFICATION_TYPES.ENGINE_TEMPERATURE:
        return TemperatureIcon;

      case NOTIFICATION_TYPES.WINDOW:
        return WindowIcon;

      case NOTIFICATION_TYPES.TEMPERATURE:
        return SensorTemperatureIcon;

      case NOTIFICATION_TYPES.HUMIDITY:
        return HumidityIcon;

      case NOTIFICATION_TYPES.SPEEDING:
        return SpeedIcon;

      case NOTIFICATION_TYPES.IGNITION_OFF:
        return IgnitionOffIcon;

      case NOTIFICATION_TYPES.IGNITION_ON:
        return IgnitionOnIcon;

      case NOTIFICATION_TYPES.UNPLUG:
        return UnplugIcon;

      default:
        return null;
    }
  }, [type]);

  return (
    <div className={classes.wrap} onClick={onClick} data-testid='message-main'>
      <div className={classes.icon}>{Icon && <Icon />}</div>
      <div className={classes.messageWrap}>
        <div
          className={classes.driverInfo}
          data-testid='driver-info'
        >{`${driverFullName} ${vehiclePlateNumber}`}</div>
        <div className={classes.message} data-testid='message-text'>
          {message}
        </div>
      </div>
      <ReactTimeAgo
        className={classes.time}
        date={createdAt}
        locale={userData?.language === LANGUAGES.EN ? 'en-US' : 'az-AZ'}
        data-testid='time-ago'
      />
      {!isRead && <div className={classes.isNotReadIcon} data-testid='not-read-icon' />}
    </div>
  );
};

export default Message;
