import { selectorFamily } from 'recoil';

import { SENSOR_TYPES } from 'interfaces/sensor/types';

import activeSensorAlarmsAtom from '../../sensor-alarms/active-alarms';
import sensorAlarmsSelector from '../../sensor-alarms/data';

type TSensorAlarms = {
  label: SENSOR_TYPES;
  lat: number;
  lng: number;
};

const mapSensorAlarmsSelector = selectorFamily<TSensorAlarms[], string>({
  key: 'mapSensorAlarmsSelector',
  get:
    (tripId) =>
    ({ get }) => {
      const activeSensorAlarms = get(activeSensorAlarmsAtom);
      const sensorAlarms = get(sensorAlarmsSelector(tripId));

      return activeSensorAlarms
        .map((penalty) => {
          return sensorAlarms[penalty].map((point) => {
            return {
              lat: point.latitude,
              lng: point.longitude,
              label: penalty,
            };
          });
        })
        .flat();
    },
});

export default mapSensorAlarmsSelector;
