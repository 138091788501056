import { ISettingPaymentCompany } from 'interfaces/company/setting-payment-company';

import server from 'utils/server';

export const getSettingPaymentCompanyData = async (
  companyId: string,
): Promise<ISettingPaymentCompany> => {
  return server.services.paymentService
    .get<ISettingPaymentCompany>(`balance/by/${companyId}`)
    .then((res) => res.data);
};
