import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import { ReactComponent as FleetIcon } from 'assets/images/fleet.svg';
import { ReactComponent as HotelsIcon } from 'assets/images/hotels.svg';
import { ReactComponent as MarineIcon } from 'assets/images/marine.svg';

import useStyles from './styles';

const Slider = () => {
  const { t } = useTranslation('home.page');

  const mockProducts = [
    {
      id: '01',
      title: `${t('fleet.label')}`,
      Img: FleetIcon,
      text: `${t('more.label')}`,
      link: `${t('commercialLink')}`,
    },
    {
      id: '02',
      title: `${t('hotels.label')}`,
      Img: HotelsIcon,
      text: `${t('progress.label')}`,
      textDate: '(Q4 2022)',
      link: false,
    },
    {
      id: '03',
      title: `${t('marine.label')}`,
      Img: MarineIcon,
      text: `${t('progress.label')}`,
      textDate: '(Q3 2022)',
      link: false,
    },
  ];

  const classes = useStyles();

  const handleOpenPdf = (link) => {
    if (!link) {
      return;
    }
    window.open(link, '_blank');
  };

  return (
    <>
      <div className={classes.products}>
        <div>{t('products.label')}</div>
      </div>
      <div className={classes.container}>
        <Swiper
          className={classes.swiperSlider}
          initialSlide={0}
          modules={[Navigation]}
          loop={true}
          slidesPerView={1}
          speed={1500}
          spaceBetween={220}
          centeredSlides={true}
          allowTouchMove={false}
          navigation={{
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
          }}
          breakpoints={{
            840: {
              slidesPerView: 2,
            },
            1200: {
              slidesPerView: 3,
            },
          }}
        >
          <div className='swiper-button-prev' />
          {[...mockProducts, ...mockProducts].map(({ id, Img, title, text, textDate, link }) => (
            <SwiperSlide key={id} className={classes.slide}>
              <div className={classes.title}>{title}</div>
              <Img className={cx(classes.icon, 'activeIcon')} />
              <div
                onClick={() => handleOpenPdf(link)}
                className={cx(classes.button, 'activeButton')}
              >
                <div className={cx(classes.text, 'activeText')}>{text}</div>
                {textDate && <div className={classes.textDate}>{textDate}</div>}
              </div>
            </SwiperSlide>
          ))}
          <div className='swiper-button-next' />
        </Swiper>
      </div>
    </>
  );
};

export default React.memo(Slider);
