import * as yup from 'yup';

export const formSchema = yup.object().shape({
  sensorName: yup.string().required(),
  sensorNumber: yup.string().required(),
  notification: yup.boolean(),
  temperature: yup.object().shape({
    active: yup.boolean(),
    celsius: yup.boolean(),
    value: yup.array(),
  }),
  humidity: yup.object().shape({
    active: yup.boolean(),
    value: yup.array(),
  }),
  movement: yup.object().shape({
    active: yup.boolean(),
    value: yup.number(),
    zones: yup.array(),
    subzones: yup.array(),
  }),
});

export const defaultValues = {
  sensorName: '',
  notification: false,
  temperature: {
    active: false,
    celsius: true,
    value: [-20, 60],
  },
  humidity: {
    active: false,
    value: [0, 100],
  },
  movement: {
    active: false,
    value: 0,
    zones: [],
    subzones: [],
  },
};
