import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowDownIcon } from 'assets/images/select-arrow-down.svg';

import useStyles from './styles';

interface IPaginationScroll {
  count: number;
  maxCount: number;
  loadMoreHandler: () => void;
}

const PaginationScroll: FC<IPaginationScroll> = ({ count, maxCount, loadMoreHandler }) => {
  const classes = useStyles();
  const { t } = useTranslation('pagination.page');

  return (
    <div className={classes.loadMoreWrap}>
      <div data-testid='pagination-count'>
        {t('items.count.label', { length: count, maxLength: maxCount })}
      </div>

      {count !== maxCount && (
        <div
          className={classes.loadMore}
          data-testid='pagination-more-button'
          onClick={loadMoreHandler}
        >
          {t('load.more.label')} <ArrowDownIcon className={classes.arrowDown} />
        </div>
      )}
    </div>
  );
};

export default PaginationScroll;
