import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  pagesWrapper: {
    paddingTop: 50,
    height: 'calc(100vh - 330px)',
  },

  pagesList: {
    margin: '0 auto',
    width: 400,
    height: '100%',
  },

  pageName: {
    padding: '10px 25px',
    marginBottom: 15,
    backgroundColor: palette.custom.lightBlue,
    color: theme.palette.primary.light,
    borderRadius: 5,
    cursor: 'pointer',
    transition: 'all 0.3s ease',

    '&:hover': {
      backgroundColor: palette.custom.softBlue,
    },
  },
}));

export default useStyles;
