import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  header: {
    padding: 20,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  leftSide: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 10,
  },

  viewBtn: {
    padding: 15,
    backgroundColor: alpha(palette.custom.blue700, 0.08),
    color: alpha(palette.custom.blue200, 0.5),
    border: '1.5px solid transparent',
    borderRadius: 10,
    textTransform: 'capitalize',
    transition: 'all 0.3s ease',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: alpha(palette.custom.blue700, 0.15),
    },

    '&.isActive': {
      color: palette.custom.goldSecondary,
      backgroundColor: alpha(palette.custom.goldSecondary, 0.15),
      border: `1.5px solid ${palette.custom.goldSecondary}`,
    },
  },

  searchIcon: {
    width: 16,
    height: 16,
    cursor: 'pointer',

    '& path': {
      fill: palette.custom.blue200,
    },
  },

  closeIcon: {
    width: 16,
    height: 16,
    cursor: 'pointer',
  },

  searchWrap: {
    width: '100%',
  },

  inputBtns: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 15,
  },

  boxShadow: {
    background: 'linear-gradient(180deg, #000000 0%, rgba(35, 45, 70, 0) 100%)',
    opacity: 0.1,
    width: '100%',
    height: 6,
    position: 'absolute',
    bottom: 0,
    left: 0,
  },

  teamsSwitchLabel: {
    color: palette.custom.blue200,
    paddingInline: 8,
  },

  switch: {
    height: 56,
    width: 94,
  },
}));

export default useStyles;
