import axios from 'axios';

import { userDefaultDomain } from '../default-domain';

const beService = axios.create({
  baseURL: `${userDefaultDomain}${process.env.REACT_APP_API_URL}/`,
  headers: {
    accept: '*/*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Expose-Headers': 'Content-Disposition',
  },
});

export default beService;
