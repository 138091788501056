import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import {
  ISettingPaymentCompaniesListFilter,
  settingPaymentCompaniesListFilterSelector,
} from 'recoil/settings/payment/companies-list/filters';

import Input from 'components/form-v2/input';
import Select from 'components/form-v2/select';
import ModalActions from 'components/modal-actions';

import { useParentSelectItems } from './data';
import { formSchema } from './form-schema';
import useStyles from './styles';

interface IFilters {
  onClose: () => void;
}

const Filters: FC<IFilters> = ({ onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation('fields');

  const [filters, setFilters] = useRecoilState(settingPaymentCompaniesListFilterSelector);
  const formMethods = useForm<ISettingPaymentCompaniesListFilter>({
    defaultValues: filters,
    resolver: yupResolver(formSchema),
  });

  const onSaveFilters = (data: ISettingPaymentCompaniesListFilter) => {
    setFilters(data);
    onClose();
  };

  return (
    <div className={classes.wrapper}>
      <FormProvider {...formMethods}>
        <Input name='minDaysLeft' title={t('minDaysLeft.label')} type='number' />
        <Input name='maxDaysLeft' title={t('maxDaysLeft.label')} type='number' />

        <Input name='minBalance' title={t('minBalance.label')} type='number' />
        <Input name='maxBalance' title={t('maxBalance.label')} type='number' />

        <Select title={t('useParent.label')} name={'useParent'} items={useParentSelectItems(t)} />
        <ModalActions handleClose={onClose} handleSave={formMethods.handleSubmit(onSaveFilters)} />
      </FormProvider>
    </div>
  );
};

export default Filters;
