import { format } from 'date-fns';

import { CHART_TABS } from 'interfaces/scoring/chart-tabs';

import { IScoringAnnualRenderData } from 'recoil/scoring/scoringAnnualScore/atom';

import { IScoringGraphApi } from 'requests/be-service/scoring-controller/get-data-for-graph-for-year';

const formatDate = (date: Date, type: CHART_TABS) => {
  switch (type) {
    case CHART_TABS.H:
      return format(date, 'HH:mm');
    case CHART_TABS.D:
      return format(date, 'dd MMM');
    case CHART_TABS.W:
      return format(date, 'ww');
    case CHART_TABS.M:
      return format(date, 'MMM');
    case CHART_TABS.Y:
      return format(date, 'yyyy');
  }
};
export const createRenderData = (
  response: IScoringGraphApi,
  type: CHART_TABS,
): IScoringAnnualRenderData => {
  return {
    categories: Object.entries(response.companyData).map((item) =>
      formatDate(new Date(item[0]), type),
    ),
    companyData: Object.values(response.companyData).map((item) => Math.round(item)),
    competitorData: Object.values(response.competitorData).map((item) => Math.round(item)),
  };
};
