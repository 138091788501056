import { TDataWithId } from 'interfaces/common/data-with-id';
import { TCompany } from 'interfaces/company/company';

import server from 'utils/server';

/**
 * Retrieves company information from the server.
 * @async
 * @param {string} id - The ID of the company to retrieve.
 * @returns {Promise<TDataWithId<TCompany>>} - A promise that resolves to the retrieved company information.
 */
export const getCompanyReq = async (id: string): Promise<TDataWithId<TCompany>> =>
  server.services.beService.get<TDataWithId<TCompany>>(`companies/${id}`).then((res) => res.data);
