import { format } from 'date-fns';
import { selectorFamily } from 'recoil';

import { IProps } from 'modules/Trip/conponents/header/path-card';

import tripPointsSelector from '../../points';

const pathCardSelector = selectorFamily<IProps, string>({
  key: 'pathCardSelector',
  get:
    (tripId) =>
    ({ get }) => {
      const tripInfo = get(tripPointsSelector(tripId));

      return {
        finishAddress: tripInfo.trip.finishAddress?.fullAddress,
        startAddress: tripInfo.trip.startAddress?.fullAddress,
        finishTime: format(new Date(tripInfo.trip.finishGpsPoint.timestamp!), 'hh:mm'),
        startTime: format(new Date(tripInfo.trip.startGpsPoint.timestamp!), 'hh:mm'),
        score: tripInfo.trip.currentScoring,
      };
    },
});

export default pathCardSelector;
