import React, { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { valueConverter } from 'utils/helpers/value-converter';

import userConfigAtom from 'recoil/user-config';

import useStyles from './styles';

interface IProps {
  maxSpeed: number;
  currentSpeed: number;
}

const PenaltySpeedMarker: FC<IProps> = ({ currentSpeed, maxSpeed }) => {
  const classes = useStyles();
  const { isMetricSystem } = useRecoilValue(userConfigAtom);

  const { value, diff } = useMemo(() => {
    return {
      value: isMetricSystem ? maxSpeed : valueConverter.distance.toImperial(maxSpeed).toFixed(2),
      diff: isMetricSystem
        ? currentSpeed - maxSpeed
        : valueConverter.distance.toImperial(currentSpeed - maxSpeed).toFixed(2),
    };
  }, [currentSpeed, isMetricSystem, maxSpeed]);

  return (
    <div className={classes.speedMarker} data-testid='penalty-speed-marker'>
      <div className={classes.maxSpeed}>{value}</div>
      <div className={classes.diffSpeed}>{diff <= 0 ? diff : `+${diff}`}</div>
    </div>
  );
};

export default React.memo(PenaltySpeedMarker);
