import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  annual: {
    position: 'absolute',
    display: 'grid',
    gridTemplateColumns: '3fr 40px',
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
    padding: '20px 10px',
    marginBottom: '17px',
    userSelect: 'none',

    '& .apexcharts-tooltip': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'start',
      fontWeight: 300,
      fontSize: '12px',
      background: `${palette.custom.darkBlue} !important`,
      color: theme.palette.primary.light,
      border: '0px !important',
      lineHeight: '14px',
      borderRadius: '1.80973px',
      boxShadow: 'none',
    },
  },

  buttons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxHeight: '350px',
    cursor: 'pointer',
  },

  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '23px',
    height: '33px',
    fontWeight: 300,
    fontSize: 14,
    lineHeight: '17px',
    color: theme.palette.primary.light,
    opacity: 0.5,
    '&:first-child': {
      marginTop: '14px',
    },

    '&:last-child': {
      marginBottom: '14px',
    },
  },

  active: {
    cursor: 'pointer',
    background: palette.custom.bluePrimary,
    borderRadius: '10px',
  },

  blocking: {
    cursor: 'not-allowed',
  },

  emptyWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 28,
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
