import { atom } from 'recoil';

export const SIDEBAR_TYPES = {
  DEFAULT: 'DEFAULT',
  TEAMS: 'TEAMS',
  SEARCH: 'SEARCH',
};

const dashboardSidebarListTypeAtom = atom({
  key: 'dashboardSidebarListTypeAtom',
  default: SIDEBAR_TYPES.DEFAULT,
});

export default dashboardSidebarListTypeAtom;
