/**
 * Enum representing different button variants.
 * @enum {string}
 * @readonly
 */
export enum BUTTON_VARIANTS {
  FILL = 'fill',
  OUTLINE = 'outline',
  DEFAULT = 'default',
}
