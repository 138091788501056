import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  searchIcon: {
    width: 16,
    height: 16,
    cursor: 'pointer',

    '& path': {
      fill: palette.custom.blue200,
    },
  },

  closeIcon: {
    width: 16,
    height: 16,
    cursor: 'pointer',
  },

  searchWrap: {
    width: '100%',
  },

  inputBtns: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 15,
  },
}));

export default useStyles;
