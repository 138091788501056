import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  button: {
    gridArea: 'btn',
    width: 130,
    height: 40,
    borderRadius: '7px',
    background: 'transparent',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '14px',
    color: theme.palette.primary.main,
    textTransform: 'initial',
    cursor: 'pointer',
    alignSelf: 'flex-end',
  },

  buttonSubmit: {
    border: `1px solid ${palette.custom.goldSecondary}`,
  },
}));

export default useStyles;
