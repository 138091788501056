import { format } from 'date-fns';
import i18next from 'i18next';
import { selector } from 'recoil';

import { NOTIFICATION_TYPES } from 'interfaces/notifications/notification-types';

import { NOTIFICATION_TYPES_DATA, TABS_DATA } from 'constants/notifications';

import userDataAtom from '../../userData';
import notificationActiveTabAtom from '../active-tab';
import notificationAppliedFiltersAtom from '../applied-filters';

interface INotificationRenderFilter {
  value: NOTIFICATION_TYPES | string;
  label: string;
  disabled: boolean;
}

const notificationRenderFiltersAtom = selector<INotificationRenderFilter[]>({
  key: 'notificationRenderFiltersAtom',
  get: ({ get }) => {
    const userLang = get(userDataAtom)?.language;
    const activeTab = get(notificationActiveTabAtom);
    const t = i18next.getFixedT(userLang ?? 'EN', 'notifications.service');

    const appliedTypes = get(notificationAppliedFiltersAtom);
    const tabTypes = TABS_DATA(t).find((item) => item.value === activeTab)?.cartType ?? [];

    let types;
    if (tabTypes?.length === 0) {
      types = appliedTypes.cartType;
    } else {
      types = appliedTypes.cartType.filter((item) => tabTypes.includes(item));
    }

    return [
      {
        value: 'startAt',
        label: format(appliedTypes.startAt, 'dd.MM.yyyy'),
        disabled: true,
      },
      {
        value: 'finishAt',
        label: format(appliedTypes.finishAt, 'dd.MM.yyyy'),
        disabled: true,
      },
      ...types.map((item) => {
        return {
          value: item,
          label: NOTIFICATION_TYPES_DATA(t).find(({ value }) => value === item)!.label,
          disabled: false,
        };
      }),
    ];
  },
});

export default notificationRenderFiltersAtom;
