import { ITrip } from 'interfaces/map/i-trip';

import { valueConverter } from 'utils/helpers/value-converter';

export const tripsListItemsDTO = (data: ITrip[], isMetricSystem: boolean): ITrip[] => {
  if (isMetricSystem) {
    return data;
  }
  return data.map((item) => {
    return {
      ...item,
      fuelUsed: item.fuelUsed && valueConverter.volume.toImperial(item.fuelUsed),
      km: item.km && valueConverter.distance.toImperial(item.km),
    };
  });
};
