import { StyledEngineProvider } from '@mui/material/styles';
import cx from 'classnames';
import React, { ChangeEventHandler, ComponentType } from 'react';

import { FCC } from 'interfaces/common/fc-with-children';

import useStyles from './styles';

interface IProps {
  onChange: ChangeEventHandler;
  value: boolean;
  disabled?: boolean;
  wrapClass?: string;
  RightIcon?: ComponentType;
  LeftIcon?: ComponentType;
}
const Switch: FCC<IProps> = ({ value, disabled, onChange, RightIcon, LeftIcon, wrapClass }) => {
  const classes = useStyles();

  return (
    <StyledEngineProvider injectFirst>
      <label
        className={cx(classes.wrap, wrapClass, { checked: value, disabled })}
        data-testid='swith-wrap'
      >
        <input className={classes.hide} type='checkbox' onChange={onChange} checked={value} />
        {LeftIcon && (
          <div className={cx(classes.icon, { hide: !value }, 'left')}>
            <LeftIcon />
          </div>
        )}
        {RightIcon && (
          <div className={cx(classes.icon, { hide: value }, 'right')}>
            <RightIcon />
          </div>
        )}
        <div className={cx(classes.track, { checked: value })} />
      </label>
    </StyledEngineProvider>
  );
};

export default Switch;
