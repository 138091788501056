import queryString from 'query-string';

import { ISensorData } from 'interfaces/sensor/i-sensor-data';
import { TSensorRenderData } from 'interfaces/sensor/t-sensor-render-data';

import { sensorDTO } from 'utils/dto/sensor';
import server from 'utils/server';

/**
 * Creates a sensor request for a given vehicle.
 *
 * @param {string} vehicleId - The ID of the vehicle.
 * @param {TSensorRenderData} data - The sensor render data.
 * @returns {Promise<ISensorData>} - A promise that resolves with the sensor data.
 */
export const createSensorReq = async (
  vehicleId: string,
  data: TSensorRenderData,
): Promise<ISensorData> => {
  const params = {
    vehicleId,
  };
  const body = {
    ...sensorDTO.post(data),
  };

  return server.services.beService
    .post<ISensorData>('sensors?' + queryString.stringify(params), body)
    .then((res) => res.data);
};
