import Pagination from '@mui/material/Pagination';
import { UsePaginationProps } from '@mui/material/usePagination/usePagination';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

interface IPaginationComponent {
  totalElements: number;
  totalPages: number;
  page: number;
  size: number;
  numberOfElements: number;
  onChange: UsePaginationProps['onChange'];
}

const PaginationComponent: FC<IPaginationComponent> = ({
  totalElements,
  totalPages,
  page,
  size,
  numberOfElements,
  onChange,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('fields');

  const fromIndex = size * page;
  const from = numberOfElements ? fromIndex + 1 : 0;
  const to = fromIndex + numberOfElements;

  return (
    <div className={classes.pagination} data-testid='pagination-wrap'>
      <Pagination page={page + 1} count={totalPages} shape='rounded' onChange={onChange} />
      <div className={classes.results}>{`${t('results.label')} ${from}-${to} ${t(
        'of.label',
      )} ${totalElements}`}</div>
    </div>
  );
};

export default React.memo(PaginationComponent);
