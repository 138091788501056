import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';

import { FCC } from 'interfaces/common/fc-with-children';

interface IHighlightedText {
  highlight: string;
  text: string;
  highlightColor: string;
}

type Props = IHighlightedText;

const useStyles = makeStyles<Theme, Pick<IHighlightedText, 'highlightColor'>>(() => ({
  isHighlighted: {
    color: ({ highlightColor }) => highlightColor,
  },
}));

/**
 * Highlight searching text
 */
const HighlightedText: FCC<Props> = ({ highlight, text, highlightColor }) => {
  const parts = text?.split(new RegExp(`(${highlight})`, 'gi'));
  const classes = useStyles({ highlightColor });

  return (
    <span>
      {parts?.map((part, i) => {
        const isHighlighted = part.toLowerCase() === highlight.toLowerCase();
        return (
          <span key={i} className={isHighlighted ? classes.isHighlighted : ''}>
            {part}
          </span>
        );
      })}
    </span>
  );
};

export default HighlightedText;
