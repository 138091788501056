import cx from 'classnames';
import { FC } from 'react';

import { CURRENCY_SYMBOL } from 'interfaces/common/currency-symbol';

import { formatMoney } from 'utils/helpers/format-money';
import { isEmptyValue } from 'utils/helpers/is-empty-value';

import useStyles from './styles';

interface IProps {
  value?: number | null;
  currencyIcon: CURRENCY_SYMBOL;
  className?: string;
}

const Money: FC<IProps> = ({ value, currencyIcon, className }) => {
  const classes = useStyles();

  return (
    <span className={cx(classes.wrap, { isNegative: value && value < 0 }, className)}>
      {!isEmptyValue(value) ? formatMoney(value!, currencyIcon, 1) : '-'}
    </span>
  );
};

export default Money;
