import cx from 'classnames';
import { FC } from 'react';

import { TRANSACTION_RESULT } from 'requests/payment/get-company-transactions';

import useStyles from './styles';

interface IProps {
  result: TRANSACTION_RESULT;
  className?: string;
}

const TransactionResult: FC<IProps> = ({ result, className }) => {
  const classes = useStyles();

  return (
    <span className={cx(classes.wrap, className, result)} data-testid='transaction-result'>
      {result.toLowerCase()}
    </span>
  );
};

export default TransactionResult;
