import cx from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FCC } from 'interfaces/common/fc-with-children';

import { ReactComponent as InfoIcon } from 'assets/images/info.svg';

import { scoreCircle } from 'utils/helpers/score-circle';
import RouteManager from 'utils/services/route-manager';

import CircleListMarker from '../CircleListMarker';
import NewAvatar from '../NewAvatar';

import useStyles from './styles';

interface IDriverAvatar {
  id: string;
  isPositionRight: boolean;
  isColorCircle: boolean;
  handleOpen: () => void;
  infoIcon: boolean;
  size: boolean;
  isActive: boolean;
  currentScoring: number;
}

type Props = IDriverAvatar;

const DriverAvatar: FCC<Props> = ({
  id,
  isPositionRight,
  isColorCircle,
  handleOpen,
  infoIcon,
  size,
  isActive,
  currentScoring,
}) => {
  const classes = useStyles({ size });
  const navigate = useNavigate();

  const onDriverInformation = () => {
    handleOpen?.();
    navigate(RouteManager.makeURL('drivers.edit', { id }));
  };

  return (
    <div className={classes.avatar}>
      <NewAvatar isColorCircle={isColorCircle} onClick={onDriverInformation} />
      {infoIcon && (
        <InfoIcon className={cx(classes.info, isPositionRight && classes.circleRight)} />
      )}
      <div className={classes.status}>
        <CircleListMarker marked={isActive} color={scoreCircle(currentScoring)} />
      </div>
    </div>
  );
};

export default React.memo(DriverAvatar);
