import { yupResolver } from '@hookform/resolvers/yup';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import PropTypes from 'prop-types';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DatePicker from 'components/form-v2/date-picker';
import ModalActions from 'components/modal-actions';

import { formSchema, TDateFilters } from './form-schema';
import useStyles from './styles';

export enum CALENDAR_DEFAULT_TYPES {
  LAST_MONTH = 'LAST_MONTH',
  CURRENT_MONTH = 'CURRENT_MONTH',
  LAST_WEEK = 'LAST_WEEK',
  THIS_WEEK = 'THIS_WEEK',
  TODAY = 'TODAY',
  PERIOD = 'PERIOD',
}

interface IProps {
  setDateFilter: (data: TDateFilters) => void;
  setSelectedPeriod: (id: CALENDAR_DEFAULT_TYPES) => void;
  onCloseFilter: () => void;
}
const DateFilter: FC<IProps> = ({ setDateFilter, setSelectedPeriod, onCloseFilter }) => {
  const classes = useStyles();
  const { t } = useTranslation('fields');

  const formMethods = useForm<TDateFilters>({
    resolver: yupResolver(formSchema()),
  });

  const onSubmitForm = (data: TDateFilters) => {
    console.log({ data });
    const res = {
      start: startOfDay(new Date(data.start)),
      end: endOfDay(new Date(data.end)),
    };
    setSelectedPeriod(CALENDAR_DEFAULT_TYPES.PERIOD);
    setDateFilter(res);
    onCloseFilter();
  };

  return (
    <div className={classes.wrap} data-testid='date-filter-wrap'>
      <FormProvider {...formMethods}>
        <form className={classes.form}>
          <DatePicker name={'start'} title={t('from.filter.label')} />
          <DatePicker name={'end'} title={t('to.filter.label')} />
        </form>
        <ModalActions
          handleClose={onCloseFilter}
          handleSave={formMethods.handleSubmit(onSubmitForm)}
        />
      </FormProvider>
    </div>
  );
};

DateFilter.propTypes = {
  setDateFilter: PropTypes.func.isRequired,
  onCloseFilter: PropTypes.func.isRequired,
  setSelectedPeriod: PropTypes.func.isRequired,
};

export default DateFilter;
