import { atom } from 'recoil';

import { IFullyPointCoordinates } from 'interfaces/map/point';
import { SENSOR_TYPES } from 'interfaces/sensor/types';

type SensorType = 'HUMIDITY_SENSOR' | 'MOVEMENT_SENSOR' | 'TEMPERATURE_SENSOR';

interface ISensorPoint extends IFullyPointCoordinates {
  speed: number;
  order: null;
  from: number;
  to: number;
  value: number;
  type: SensorType;
  alarm: boolean;
  active: boolean;
}

export interface ITripSensorsAlarms {
  [SENSOR_TYPES.HUMIDITY]: ISensorPoint[];
  [SENSOR_TYPES.MOVEMENT]: ISensorPoint[];
  [SENSOR_TYPES.TEMPERATURE]: ISensorPoint[];
}

const tripSensorsAlarmsAtom = atom<ITripSensorsAlarms | null>({
  key: 'tripSensorsAlarmsAtom',
  default: null,
});

export default tripSensorsAlarmsAtom;
