import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AppStoreLogo } from 'assets/images/app-store-logo.svg';
import { ReactComponent as GoogleMarketLogo } from 'assets/images/google-play-market-logo.svg';
import { ReactComponent as HomePlanetIcon } from 'assets/images/home-planet-icon.svg';
import { ReactComponent as HomePlayIcon } from 'assets/images/home-play.svg';
import { ReactComponent as HomeUserIcon } from 'assets/images/home-user-icon.svg';

import { handleGetInAnchor } from 'utils/helpers';

import { fetchCountersReq } from 'requests/be-service/home-page-controller/get-user-city-count';

import NumberCounter from 'components/NumberCounter';

import useStyles from './styles';

const Main = () => {
  const classes = useStyles();
  const { t } = useTranslation('home.page');

  const [counters, setCounters] = useState({
    cityCount: 2,
    usersCount: 57,
  });

  useEffect(() => {
    fetchCountersReq().then((response) => {
      setCounters({
        cityCount: response?.cityCount || 0,
        usersCount: response?.usersCount || 0,
      });
    });
  }, []);

  return (
    <>
      <div id='get-in-home-anchor' className={classes.container}>
        <div className={classes.containerInner}>
          <div className={classes.leftContainer}>
            <h1 className={classes.title}>{t('title.label')}</h1>
            <div className={classes.descriptionContainer}>
              {t('platformPoweredBy.label')}
              <span className={classes.descriptionOrangeText}> {t('bOS.label')} </span>
              {t('description.label')}
            </div>
            <div className={classes.actionsWrapper}>
              <Button
                className={classes.genInTouchButton}
                onClick={() => handleGetInAnchor('get-in-touch-anchor')}
              >
                {t('getInTouch.label')}
              </Button>
              <Button className={classes.playButton}>
                <HomePlayIcon />
                <span className={classes.playButtonLabel}>{t('watchVideo.label')}</span>
              </Button>
            </div>

            <div className={classes.applicationsWrap}>
              <a
                href='https://play.google.com/store/apps/details?id=com.zaman.geekbro.admin'
                className={classes.app}
              >
                <GoogleMarketLogo />
              </a>
              <a
                href='https://apps.apple.com/az/app/geekbro-admin/id1611762973'
                className={classes.app}
              >
                <AppStoreLogo />
              </a>
            </div>
          </div>

          <div className={classes.rightContainer}>
            <div className={classes.counterWrapper}>
              <HomeUserIcon className={classes.counterIcon} />
              <span className={classes.counterLabel}>{t('users.label')}</span>
              <NumberCounter value={counters.usersCount} length={7} />
            </div>
            <div className={classes.counterWrapper}>
              <HomePlanetIcon className={classes.counterIcon} />
              <span className={classes.counterLabel}>{t('cities.label')}</span>
              <NumberCounter value={counters.cityCount} length={2} fillEmptyPositionsWith='0' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Main.defaultProps = {};

Main.propTypes = {};

export default React.memo(Main);
