import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { format } from 'date-fns';
import React, { FC, useCallback } from 'react';
import { SetterOrUpdater } from 'recoil';

import { TABLE_VIEW_TYPE } from 'interfaces/common/table-view-type';
import { TIME_DEGREE } from 'interfaces/common/time-degree';
import { DEVICE_FUNCTION } from 'interfaces/vehicles/device-functions';
import { ENGINE_STATUS } from 'interfaces/vehicles/engine-status';

import { ReactComponent as PencilIcon } from 'assets/images/pencil.svg';

import { getSizeFormView } from 'utils/helpers/get-size-form-view';

import { CRASH_ANCHOR, CRASH_MODAL_TABS, ICrashModal } from 'recoil/crash-modal/atom';

import { IVehiclesListItem } from 'requests/be-service/vehicle-controller/get-vehicles';
import { getVehicleCrashListReq } from 'requests/gps-service/crash-controller/get-vehicle-crashes';

import Collision from 'components/Collision';
import DeviceStatus from 'components/device-status';
import EngineButton from 'components/engine-button';
import PenaltyCard from 'components/penalty-card';
import PlateNumber from 'components/plate-number';
import StatisticValue from 'components/statistic-value';
import Time from 'components/time';
import VehicleIcon from 'components/vehicle-icon';

import useStyles from './styles';

interface IProps {
  data: IVehiclesListItem;
  tableViewType: TABLE_VIEW_TYPE;
  editHandler: (vehicle: IVehiclesListItem) => void;
  handleOpenEngineModal: (imei: string, engineStatus: ENGINE_STATUS) => void;
  setCrashModal: SetterOrUpdater<ICrashModal>;
}

const VehicleRow: FC<IProps> = ({
  data,
  tableViewType,
  editHandler,
  handleOpenEngineModal,
  setCrashModal,
}) => {
  const classes = useStyles();

  const handleCrashClick = useCallback(async () => {
    try {
      setCrashModal((prevState) => {
        return {
          ...prevState,
          isOpen: true,
          isFetching: true,
          anchor: CRASH_ANCHOR.VEHICLE,
          activeTab: CRASH_MODAL_TABS.DESCRIPTION,
        };
      });

      const response = await getVehicleCrashListReq(data.imei);

      setCrashModal((prevState) => {
        return {
          ...prevState,
          currentCrashIdNumber: response.length - 1,
          crashList: response,
        };
      });
    } catch (error) {
      setCrashModal((prevState) => {
        return {
          ...prevState,
          error: error,
        };
      });
    } finally {
      setCrashModal((prevState) => {
        return {
          ...prevState,
          isFetching: false,
        };
      });
    }
  }, [data.imei, setCrashModal]);

  return (
    <TableRow className={classes.row} data-testid={`vehicle-row-${data.id}`}>
      <TableCell>
        <div className={classes.infoWrap}>
          {data?.deviceFunctions?.includes(DEVICE_FUNCTION.START_STOP_BUTTON) && (
            <EngineButton
              status={data?.engineStatus}
              onClick={(e) => {
                e.stopPropagation();
                handleOpenEngineModal(data.imei, data?.engineStatus);
              }}
            />
          )}

          <div className={classes.vehicleInfo}>
            {data.country && data.plateNumber && (
              <PlateNumber
                wrapClass={classes.plateNumber}
                country={data.country}
                plateNumber={data.plateNumber}
              />
            )}
            {data.type && <VehicleIcon variant={'outline'} type={data.type} />}
            <div className={classes.vehicleModel}>{`${data.brandName} ${data.modelName}`}</div>
          </div>
        </div>
      </TableCell>

      {tableViewType === TABLE_VIEW_TYPE.STATISTIC && (
        <>
          <TableCell classes={{ body: classes.cell }} align={'center'}>
            <div className={classes.problems}>
              {Boolean(data.crashCount) && (
                <Collision count={data.crashCount} onClick={handleCrashClick} />
              )}
              {data.penaltyCards?.map((type, i) => (
                <PenaltyCard type={type} key={`${type}-${i}`} />
              ))}
            </div>
          </TableCell>

          <TableCell classes={{ body: classes.cell }} align={'center'} data-testid='driverTime'>
            <Time timestamp={data.driveTime} degree={TIME_DEGREE.S} />
          </TableCell>

          <TableCell classes={{ body: classes.cell }} align={'center'} data-testid='idleTime'>
            <Time timestamp={data.idleTime} degree={TIME_DEGREE.S} />
          </TableCell>

          <TableCell classes={{ body: classes.cell }} align={'center'} data-testid='parkingTime'>
            <Time timestamp={data.parkingTime} degree={TIME_DEGREE.S} />
          </TableCell>

          <TableCell classes={{ body: classes.cell }} align={'center'} data-testid='fuel'>
            <StatisticValue value={data.fuel} />
          </TableCell>
        </>
      )}

      {tableViewType === TABLE_VIEW_TYPE.INFO && (
        <>
          <TableCell classes={{ body: classes.cell }} align={'center'}>
            <Tooltip arrow title={data.imei}>
              <div
                className={classes.overflow}
                style={{ width: getSizeFormView(126.6) }}
                data-testid='imei'
              >
                {data.imei ?? '-'}
              </div>
            </Tooltip>
          </TableCell>

          <TableCell classes={{ body: classes.cell }} align={'center'}>
            <Tooltip arrow title={data.vin}>
              <div
                className={classes.overflow}
                style={{ width: getSizeFormView(126.6) }}
                data-testid='vin'
              >
                {data.vin ?? '-'}
              </div>
            </Tooltip>
          </TableCell>

          <TableCell classes={{ body: classes.cell }} align={'center'}>
            <div className={classes.overflow} style={{ width: getSizeFormView(126.6) }}>
              {data.deviceInfo ? (
                <DeviceStatus
                  active={data.deviceInfo.active}
                  time={data.deviceInfo.gpsPoint.timestamp}
                />
              ) : (
                '-'
              )}
            </div>
          </TableCell>

          <TableCell classes={{ body: classes.cell }} align={'center'}>
            <div
              className={classes.overflow}
              style={{ width: getSizeFormView(126.6) }}
              data-testid='totalKm'
            >
              <StatisticValue value={data.totalKm} />
            </div>
          </TableCell>

          <TableCell
            classes={{ body: classes.cell }}
            align={'center'}
            data-testid='engineHoursBySecond'
          >
            <Time timestamp={data.engineHoursBySecond} degree={TIME_DEGREE.S} />
          </TableCell>

          <TableCell classes={{ body: classes.cell }} align={'center'} data-testid='registration'>
            <div className={classes.overflow} style={{ width: getSizeFormView(126.6) }}>
              {format(new Date(data.registration), 'dd.MM.yyyy')}
            </div>
          </TableCell>
        </>
      )}

      <TableCell classes={{ body: classes.cell }} align={'center'}>
        <div
          onClick={(e) => {
            e.stopPropagation();
            editHandler(data);
          }}
          data-testid='button-edid'
        >
          <PencilIcon className={classes.pencil} />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default VehicleRow;
