import React from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

const NoInfoToShow = () => {
  const classes = useStyles();

  const { t } = useTranslation('scoring.page');

  return <div className={classes.wrap}>{t('no.info.label')}</div>;
};

export default NoInfoToShow;
