import queryString from 'query-string';

import { ISensorData } from 'interfaces/sensor/i-sensor-data';
import { TSensorRenderData } from 'interfaces/sensor/t-sensor-render-data';

import { sensorDTO } from 'utils/dto/sensor';
import server from 'utils/server';

/**
 * Updates a sensor's data.
 *
 * @async
 * @param {string} sensorId - The ID of the sensor.
 * @param {string} vehicleId - The ID of the vehicle associated with the sensor.
 * @param {TSensorRenderData} data - The updated sensor data.
 * @returns {Promise<ISensorData>} - A promise that resolves to the updated sensor data.
 */
export const updateSensorReq = async (
  sensorId: string,
  vehicleId: string,
  data: TSensorRenderData,
): Promise<ISensorData> => {
  const params = {
    vehicleId,
  };
  const body = {
    ...sensorDTO.post(data),
  };

  return server.services.beService
    .put<ISensorData>(`sensors/${sensorId}?` + queryString.stringify(params), body)
    .then((res) => res.data);
};
