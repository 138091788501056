import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ReactPDF from '@react-pdf/renderer';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';

import useTableSort from 'hooks/useTableSort';

import { ReactComponent as DownloadIcon } from 'assets/images/download.svg';

import settingPaymentCompanyAtom from 'recoil/settings/payment/selected-company/company';
import {
  isOpenSettingPaymentTransactionsListFilterAtom,
  settingPaymentTransactionsListFilterSelector,
} from 'recoil/settings/payment/transactions/filters';
import settingPaymentTransactionsListPaginationSelector from 'recoil/settings/payment/transactions/pagination';
import settingPaymentTransactionsListSearchSelector from 'recoil/settings/payment/transactions/search';
import { SORT_DIRECTION } from 'recoil/settings/payment/transactions/sort';
import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';

import { getCompanyTransactionsReq, ITransaction } from 'requests/payment/get-company-transactions';
import { getInvoice } from 'requests/payment/invoices';

import Drawer from 'components/drawer';
import Loader from 'components/Loader';
import Modal from 'components/modal';
import PaginationScroll from 'components/pagination-scroll';
import TableSortLabel from 'components/TableSortLabel';

import { Money } from '../components/money';
import TransactionResult from '../components/transaction-result';
import InvoicePdf from '../invoice-pdf';

import Filters from './filters';
import useStyles from './styles';
import TransactionDrawer from './transaction-drawer';

const CompanyTransactions = () => {
  const { id } = useParams() as { id: string };
  const [transactionsSort, setTransactionsSort] = useTableSort({
    sort: '',
    direction: SORT_DIRECTION.ASC,
  });
  const technicalMessageBlockHeight = useRecoilValue(technicalMessageBlockHeightSelector);
  const companyData = useRecoilValueLoadable(settingPaymentCompanyAtom(id));
  const classes = useStyles({ technicalMessageBlockHeight });
  const { t } = useTranslation('payment.page');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenFilters, setIsOpenFilters] = useRecoilState(
    isOpenSettingPaymentTransactionsListFilterAtom,
  );
  const search = useRecoilValue(settingPaymentTransactionsListSearchSelector);
  const [page, setPage] = useRecoilState(settingPaymentTransactionsListPaginationSelector);
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const filters = useRecoilValue(settingPaymentTransactionsListFilterSelector);
  const [selectedTransaction, setSelectedTranasction] = useState<null | ITransaction>(null);

  const downloadInvoiceHandler = useCallback(async (invoiceId: string) => {
    try {
      setIsLoading(true);
      const data = await getInvoice(invoiceId);
      const file = await ReactPDF.pdf(<InvoicePdf data={data} />).toBlob();
      saveAs(file, `${data.invoiceNumber}.pdf`);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getTransactions = useCallback(async () => {
    if (companyData.state === 'loading' || companyData.state === 'hasError') {
      return;
    }
    try {
      if (page === 0) {
        setTransactions([]);
      }
      setIsLoading(true);
      const res = await getCompanyTransactionsReq(
        page,
        filters,
        transactionsSort,
        search.query,
        companyData.contents.balanceId,
      );
      setTotalTransactions(res.totalElements);
      setTransactions((prevState) => [...prevState, ...res.content]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, [
    companyData.contents.balanceId,
    companyData.state,
    filters,
    page,
    search.query,
    transactionsSort,
  ]);

  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  return (
    <>
      <TableContainer className={classes.table}>
        {isLoading && <Loader width={150} isBlock lightMode />}

        <Table stickyHeader data-testid='transaction-table'>
          <TableHead>
            <TableRow>
              <TableCell align='center' className={classes.headCell}>
                <TableSortLabel
                  onClick={setTransactionsSort}
                  name={'transactionResult'}
                  sortField={transactionsSort.sort}
                  direction={transactionsSort.direction}
                >
                  {t('transaction.status.label')}
                </TableSortLabel>
              </TableCell>

              <TableCell align='center' className={classes.headCell}>
                <TableSortLabel
                  onClick={setTransactionsSort}
                  name={'paymentDate'}
                  sortField={transactionsSort.sort}
                  direction={transactionsSort.direction}
                >
                  {t('transaction.date.label')}
                </TableSortLabel>
              </TableCell>

              <TableCell align='center' className={classes.headCell}>
                <TableSortLabel
                  onClick={setTransactionsSort}
                  name={'amount'}
                  sortField={transactionsSort.sort}
                  direction={transactionsSort.direction}
                >
                  {t('transaction.balance.label')}
                </TableSortLabel>
              </TableCell>

              <TableCell align='center' className={classes.headCell}>
                <TableSortLabel
                  onClick={setTransactionsSort}
                  name={'transactionId'}
                  sortField={transactionsSort.sort}
                  direction={transactionsSort.direction}
                >
                  {t('transaction.id.label')}
                </TableSortLabel>
              </TableCell>

              <TableCell align={'center'} className={classes.headCell}>
                |||
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody data-testid='transaction-list'>
            {transactions.map((transaction) => {
              return (
                <TableRow
                  key={transaction.transactionId}
                  onClick={() => setSelectedTranasction(transaction)}
                  className={classes.row}
                  data-testid='trnsaction-row'
                >
                  <TableCell align='center' className={classes.cell}>
                    <TransactionResult result={transaction.transactionResult} />
                  </TableCell>
                  <TableCell align='center' className={classes.cell} data-testid='paymentDate'>
                    {format(new Date(transaction.paymentDate), 'dd.MM.yyyy HH:mm')}
                  </TableCell>
                  <TableCell align='center' className={classes.cell} data-testid='amount'>
                    <Money
                      count={Number(transaction.amount.toFixed(2))}
                      className={classes.money}
                    />
                  </TableCell>
                  <TableCell align='center' className={classes.cell} data-testid='transactionId'>
                    {transaction.transactionId}
                  </TableCell>

                  <TableCell align='center' className={classes.cell}>
                    {transaction.invoiceId ? (
                      <DownloadIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          downloadInvoiceHandler(transaction.invoiceId);
                        }}
                        data-testid='button-download-invoice'
                      />
                    ) : (
                      ''
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationScroll
        count={transactions.length}
        maxCount={totalTransactions}
        loadMoreHandler={() => setPage((prevPage) => prevPage + 1)}
      />

      <Modal open={isOpenFilters} onClose={() => setIsOpenFilters(false)}>
        <Filters onClose={() => setIsOpenFilters(false)} />
      </Modal>

      <Drawer open={Boolean(selectedTransaction)} onClose={() => setSelectedTranasction(null)}>
        <TransactionDrawer
          onClose={() => setSelectedTranasction(null)}
          data={selectedTransaction}
        />
      </Drawer>
    </>
  );
};

export default CompanyTransactions;
