import * as yup from 'yup';

export const formSchema = yup.object().shape({
  minDaysLeft: yup.number().nullable().integer().positive(),
  maxDaysLeft: yup
    .number()
    .nullable()
    .integer()
    .positive()
    .when('minDaysLeft', (value) =>
      value === null
        ? yup.number().nullable()
        : yup.number().nullable().moreThan(yup.ref('minDaysLeft')),
    ),
  minBalance: yup.number().nullable(),
  maxBalance: yup
    .number()
    .nullable()
    .when('minBalance', (value) =>
      value === null
        ? yup.number().nullable()
        : yup.number().nullable().moreThan(yup.ref('minBalance')),
    ),
  useParent: yup.string().nullable(),
});
