import queryString from 'query-string';

import { COUNTRIES } from 'interfaces/common/countries';
import { IResponseWrapper } from 'interfaces/server/response-wrapper';
import { VEHICLE_TYPES } from 'interfaces/vehicles/types';

import server from 'utils/server';

import { ISortParameters } from 'recoil/settings/payment/transactions/sort';
import { ISettingPaymentVehiclesFilters } from 'recoil/settings/payment/vehicles/filters';

export interface ISpendingVehicle {
  vehicleId: string;
  daysUsed: number;
  price: number;
  amount: number;
  period: string; // '2023-12-07';
  plateNumber: string;
  type: VEHICLE_TYPES;
  brandName: string;
  modelName: string;
  country: COUNTRIES;
}

export const getSpendingVehiclesReq = async (
  page: number,
  sort: ISortParameters,
  filters: ISettingPaymentVehiclesFilters,
  balanceId: string,
  query: string,
  vehicleId?: string,
): Promise<IResponseWrapper<ISpendingVehicle[]>> => {
  const params = {
    page,
    ...sort,
  };

  const body = {
    ...filters,
    balanceId,
    vehicleIds: vehicleId && [vehicleId],
    query,
  };

  return server.services.paymentService
    .post<IResponseWrapper<ISpendingVehicle[]>>(
      'spendings/get?' + queryString.stringify(params),
      body,
    )
    .then((res) => res.data);
};
