import { StyledEngineProvider } from '@mui/material/styles';
import cx from 'classnames';
import React, { ChangeEvent, FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import useStyles from './styles';

interface IProps {
  name: string;
  disabled?: boolean;
  additionalOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const TemperatureSwitch: FC<IProps> = ({ name, disabled = false, additionalOnChange }) => {
  const { control } = useFormContext();
  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <StyledEngineProvider injectFirst>
            <label
              className={cx(classes.wrap, { checked: field.value, disabled })}
              data-testid='temperature-switch'
            >
              <input
                className={classes.hide}
                {...field}
                type='checkbox'
                onChange={(event) => {
                  additionalOnChange?.(event);
                  field.onChange(event);
                }}
                checked={field.value}
              />
              <div className={cx(classes.track, { checked: !field.value })} />
              <div className={cx(classes.icon, { hide: !field.value }, 'left')}>°C</div>
              <div className={cx(classes.icon, { hide: field.value }, 'right')}>°F</div>
            </label>
          </StyledEngineProvider>
        );
      }}
    />
  );
};

export default TemperatureSwitch;
