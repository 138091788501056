import server from 'utils/server';

import { TDriverCreateData } from 'modules/DriverProfile/form-schema';

/**
 * Modifies a driver's data in the server.
 *
 * @async
 * @param {string} id - The ID of the driver to modify.
 * @param {IDriverData} data - The updated driver data.
 * @returns {Promise<TDriverCreateData>} A promise that resolves to the modified driver data.
 */
export const driverEditReq = async (
  id: string,
  data: TDriverCreateData,
): Promise<TDriverCreateData> => {
  const body = {
    ...data,
  };

  return server.services.beService
    .put<TDriverCreateData>(`drivers/${id}`, body)
    .then((res) => res.data);
};
