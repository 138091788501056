import { format } from 'date-fns';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import RouteManager from 'utils/services/route-manager';

import useStyles from './styles';

/**
 * TODO: need move to helpers
 * @param dateString
 */
const isToday = (dateString: string) => {
  const date = new Date(dateString);
  const today = new Date();

  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
};

interface ITripsDateLabel {
  startDateLabel: string;
  driverId: string;
}

const TripsDateLabel: FC<ITripsDateLabel> = ({ startDateLabel, driverId }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation(['driver.list.page', 'calendar.service']);

  /**
   * Redirect to trips page
   */
  const tripsHandler = () =>
    navigate(
      RouteManager.makeURL('drivers.tripsByDay', {
        date: format(new Date(startDateLabel), 'dd-MM-yyyy'),
        id: driverId,
      }),
    );

  return (
    <div className={classes.wrap} data-testid='trips-date-label'>
      {isToday(startDateLabel) ? t('calendar.service:today.label') : startDateLabel}{' '}
      <span className={classes.btn} onClick={tripsHandler}>
        {t('see.all.label')}
      </span>
    </div>
  );
};

TripsDateLabel.defaultProps = {};

export default TripsDateLabel;
