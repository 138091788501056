import { atom } from 'recoil';

import { IGetDriverBreakdown } from 'interfaces/saving/i-get-driver-breakdown';

const savingDriversAtom = atom<IGetDriverBreakdown[] | null>({
  key: 'savingDriversAtom',
  default: null,
});

export default savingDriversAtom;
