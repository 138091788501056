import DrawerMUI from '@mui/material/Drawer';
import { DrawerProps } from '@mui/material/Drawer/Drawer';
import React from 'react';

import { FCC } from 'interfaces/common/fc-with-children';

import useStyles from './styles';

interface IDrawer {}

type Props = DrawerProps & IDrawer;

const Drawer: FCC<Props> = ({ children, anchor = 'right', open, onClose }) => {
  const classes = useStyles();

  return (
    <DrawerMUI anchor={anchor} open={open} onClose={onClose} classes={{ paper: classes.wrap }}>
      {children}
    </DrawerMUI>
  );
};

export default Drawer;
