import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 186,
  },

  count: {
    fontWeight: 500,
    fontSize: 56,
    color: theme.palette.primary.light,
  },

  unit: {
    fontWeight: 400,
    fontSize: 16,
    color: palette.custom.blue700,
  },
}));

export default useStyles;
