import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import scoringBestDriverAtom from 'recoil/scoring/scoringBestDriver';

import { scoringBestDriverReq } from 'requests/be-service/scoring-controller/get-best-driver';

import DiffScore from 'components/diff-score';
import Loader from 'components/Loader';
import ScoreValue from 'components/score-value';

import useStyles from './styles';

const LeaderData = () => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [scoringBestDriver, setScoringBestDriver] = useRecoilState(scoringBestDriverAtom);
  const resetScoringBestDriver = useResetRecoilState(scoringBestDriverAtom);

  const handleScoringBestDriverReq = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await scoringBestDriverReq();
      setScoringBestDriver(response);
    } catch {
      console.log('Scoring Best Driver Error');
    } finally {
      setIsLoading(false);
    }
  }, [setScoringBestDriver]);

  useEffect(() => {
    const req = async () => handleScoringBestDriverReq();
    req();

    return () => resetScoringBestDriver();
  }, [handleScoringBestDriverReq, resetScoringBestDriver]);

  const firstName = scoringBestDriver?.firstName || '';
  const lastName = scoringBestDriver?.lastName || '';

  if (isLoading) {
    return (
      <div className={classes.leader} data-testid='leader-wrap'>
        <Loader width={50} isBlock lightMode />
      </div>
    );
  }

  if (!scoringBestDriver) {
    return null;
  }

  return (
    <div className={classes.leader} data-testid='leader-wrap'>
      <div className={classes.delta}>
        <span className={classes.value} data-testid='leader-scoring'>
          <ScoreValue value={scoringBestDriver.currentScoring} />
        </span>
        <span data-testid='leader-diff'>
          <DiffScore value={scoringBestDriver.diffScoring} isBetter={scoringBestDriver.better} />
        </span>
      </div>
      <div className={classes.name} data-testid='leader-name'>{`${firstName} ${lastName}`}</div>
    </div>
  );
};

export default LeaderData;
