import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',

    '&.empty': {
      height: 350,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.primary.main,
    },
  },

  content: {
    position: 'relative',
    maxHeight: '100vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: 50,
  },

  arrow: {
    zIndex: 5,
    position: 'absolute',
    top: '50%',
    width: 48,
    height: 48,
    borderRadius: '50%',
    background: palette.custom.bluePrimary,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    '& svg': {
      transform: 'scale(0.7)',
    },
  },

  arrowBack: {
    left: -24,
  },

  arrowNext: {
    right: -24,
    transform: 'rotate(180deg)',
  },

  success: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '10px',
    padding: '10px 10px 87px 10px',
  },

  title: {
    fontWeight: 600,
    fontSize: 36,
    color: theme.palette.primary.main,
    lineHeight: '28px',
  },

  iconSuccess: {
    margin: '5px 0',
  },

  subtitle: {
    fontWeight: 300,
    fontSize: 25,
    color: theme.palette.primary.main,
    lineHeight: '18px',
  },

  danger: {
    position: 'absolute',
    zIndex: 3,
    top: 500,
    left: 0,
    opacity: 0.1,
    transform: 'scale(2.1)',
  },
}));

export default useStyles;
