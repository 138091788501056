import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'SFPro',
    fontWeight: 500,
    fontSize: 14,
    cursor: 'pointer',
  },

  icon: {
    width: 36,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& svg': {
      width: '100%',
    },
  },

  counter: {
    padding: '0 5px',
    position: 'absolute',
    top: 0,
    right: -5,
    minWidth: 21,
    height: 21,
    borderRadius: 10,
    backgroundColor: theme.palette.primary.main,
    color: palette.custom.bluePrimary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
