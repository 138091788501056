import { Popover } from '@mui/material';
import cx from 'classnames';
import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { BUTTON_VARIANTS } from 'interfaces/common/button-variants';
import { ITeamMember } from 'interfaces/teams/i-team-member';

import { ReactComponent as DeleteIcon } from 'assets/images/delete.svg';
import { ReactComponent as EditIcon } from 'assets/images/more-dotters.svg';

import isAllSelectAvailableMembersAtom from 'recoil/settings/teams/is-all-select-available-members';
import isDraggableMembersAtom from 'recoil/settings/teams/is-draggable';
import selectedAvailableMembersAtom from 'recoil/settings/teams/selected-available-members';
import selectedTeamAtom from 'recoil/settings/teams/selected-team';
import showAvailableMembersAtom from 'recoil/settings/teams/show-available-members';
import teamMembersListAtom from 'recoil/settings/teams/team-members-list';
import teamsListAtom from 'recoil/settings/teams/teams-list';
import totalAvailableMembersAtom from 'recoil/settings/teams/total-available-members';
import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';

import { addedAvailableMembersReq } from 'requests/be-service/teams-controller/add-team-member';
import { teamMembersGetReq } from 'requests/be-service/teams-controller/find-team-members';
import { deleteTeamMembersReq } from 'requests/be-service/teams-controller/remove-team-member';

import Button from 'components/form-v2/button';
import Checkbox from 'components/form-v2/checkbox';
import Loader from 'components/Loader';
import PlateNumber from 'components/plate-number';
import VehicleIcon from 'components/vehicle-icon';

import ColumnHeader from '../column-header';

import useStyles from './styles';

const TeamMembers = () => {
  const technicalMessageBlockHeight = useRecoilValue(technicalMessageBlockHeightSelector);
  const classes = useStyles();
  const { t } = useTranslation('teams.page');
  const [isLoading, setIsLoading] = useState(false);
  const [members, setMembers] = useRecoilState(teamMembersListAtom);
  const [anchorEl, setAnchorEl] = useState<{ anchor: Element; member: ITeamMember } | null>(null);
  const [selectedTeam, setSelectedTeam] = useRecoilState(selectedTeamAtom);
  const setTeams = useSetRecoilState(teamsListAtom);
  const [showAvailableMembers, setShowAvailableMembers] = useRecoilState(showAvailableMembersAtom);
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedAvailableMembers, setSelectedAvailableMembers] = useRecoilState(
    selectedAvailableMembersAtom,
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [isAllSelectAvailableMembers, setIsAllSelectAvailableMembers] = useRecoilState(
    isAllSelectAvailableMembersAtom,
  );
  const [isDraggableMembers, setIsDraggableMembers] = useRecoilState(isDraggableMembersAtom);
  const totalAvailableMembers = useRecoilValue(totalAvailableMembersAtom);

  const removeBtnText = useMemo(() => {
    if (isAllSelected) {
      if (selectedMembers.length) {
        return t('remove.all.without.selected.label', { selectedCount: selectedMembers.length });
      }
      return t('remove.all.label');
    }

    if (selectedMembers.length) {
      return t('remove.selected.label', { selectedCount: selectedMembers.length });
    }

    return null;
  }, [isAllSelected, selectedMembers.length, t]);

  /**
   * Handler for checkboxes in members list
   * @param value
   * @param id
   */
  const checkBoxHandler = (value: boolean, id: string) => {
    setSelectedMembers((prevState) => {
      if (value) {
        return [...prevState, id];
      }
      return prevState.filter((item) => item !== id);
    });
  };

  const handleOpenPopover = (e: MouseEvent, member: ITeamMember) => {
    setAnchorEl({ anchor: e.currentTarget, member });
  };

  const handleClosePopover = () => setAnchorEl(null);

  /**
   * Get team members by params
   * @type {(function(*): Promise<void>)|*}
   */
  const getTeamMembers = useCallback(
    async (page: number) => {
      if (!selectedTeam) return;

      try {
        setIsLoading(true);
        const res = await teamMembersGetReq(selectedTeam.teamId, page, searchValue);
        setMembers((prev) => [...prev, ...res.content]);
        setCurrentPage(res.number);
        setTotalPages(res.totalPages);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [searchValue, selectedTeam, setMembers],
  );

  /**
   * Added selected available members to team
   * @type {(function(): Promise<void>)|*}
   */
  const dropHandler = useCallback(async () => {
    if (!selectedTeam) return;

    try {
      setIsDraggableMembers(false);
      const newCount = isAllSelectAvailableMembers
        ? selectedAvailableMembers.length
          ? selectedTeam.count + totalAvailableMembers - selectedAvailableMembers.length
          : selectedTeam.count + totalAvailableMembers
        : selectedTeam.count + selectedAvailableMembers?.length;

      setSelectedAvailableMembers([]);
      setIsAllSelectAvailableMembers(false);
      await addedAvailableMembersReq(
        selectedTeam.teamId,
        isAllSelectAvailableMembers,
        selectedAvailableMembers,
      );
      setTeams((prevState) => {
        const teams = [...prevState];
        const currentTeamIndex = teams.findIndex((item) => {
          return item.teamId === selectedTeam?.teamId;
        });

        teams[currentTeamIndex] = {
          ...teams[currentTeamIndex],
          count: newCount,
        };

        return teams;
      });

      setSelectedTeam((prev) => ({
        ...prev!,
        count: newCount,
      }));
    } catch (e) {
      console.log(e);
    }
  }, [
    isAllSelectAvailableMembers,
    selectedAvailableMembers,
    selectedTeam,
    setIsAllSelectAvailableMembers,
    setIsDraggableMembers,
    setSelectedAvailableMembers,
    setSelectedTeam,
    setTeams,
    totalAvailableMembers,
  ]);

  /**
   * Delete team member with popover
   * @type {(function(): Promise<void>)|*}
   */
  const deleteHandler = useCallback(async () => {
    if (!selectedTeam || anchorEl) return;

    try {
      setIsLoading(true);
      setSelectedMembers([]);
      setIsAllSelected(false);
      await deleteTeamMembersReq(selectedTeam.teamId, false, [anchorEl!.member.driverInfo.id]);
      handleClosePopover();
      setMembers([]);
      setTeams((prevState) => {
        const teams = [...prevState];
        const currentTeamIndex = teams.findIndex((item) => {
          return item.teamId === selectedTeam.teamId;
        });

        teams[currentTeamIndex] = {
          ...teams[currentTeamIndex],
          count: teams[currentTeamIndex].count - 1,
        };

        return teams;
      });
      setSelectedTeam((prev) => ({
        ...prev!,
        count: prev!.count - 1,
      }));
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, [anchorEl, selectedTeam, setMembers, setSelectedTeam, setTeams]);

  /**
   * Delete team members with selected list
   * @type {(function(): Promise<void>)|*}
   */
  const deleteListHandler = useCallback(async () => {
    if (!selectedTeam) return;

    try {
      setIsLoading(true);
      await deleteTeamMembersReq(selectedTeam.teamId, isAllSelected, selectedMembers);
      setSelectedMembers([]);
      setIsAllSelected(false);
      setMembers([]);

      const newCount = isAllSelected
        ? selectedMembers.length
          ? selectedMembers.length
          : 0
        : selectedTeam.count - selectedMembers?.length;

      setTeams((prevState) => {
        const teams = [...prevState];
        const currentTeamIndex = teams.findIndex((item) => {
          return item.teamId === selectedTeam.teamId;
        });

        teams[currentTeamIndex] = {
          ...teams[currentTeamIndex],
          count: newCount,
        };

        return teams;
      });

      setSelectedTeam((prev) => ({
        ...prev!,
        count: newCount,
      }));
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, [isAllSelected, selectedMembers, selectedTeam, setMembers, setSelectedTeam, setTeams]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  /**
   * Init request team members
   */
  useEffect(() => {
    setMembers([]);
    if (selectedTeam?.count) {
      getTeamMembers(0);
    }
  }, [getTeamMembers, selectedTeam?.count, setMembers]);

  return (
    <div
      className={classes.wrap}
      onDrop={dropHandler}
      onDragOver={(e) => e.preventDefault()}
      data-testid='team-members-wrap'
    >
      {isLoading && <Loader lightMode width={150} isBlock preventClick />}

      <div className={classes.header}>
        <ColumnHeader
          label={t('team.drivers.label')}
          onSearchSubmit={(data) => setSearchValue(data.query)}
          checkBoxData={{
            show: !showAvailableMembers && Boolean(selectedTeam) && Boolean(selectedTeam?.count),
            handler: (value) => {
              setSelectedMembers([]);
              setIsAllSelected(value);
            },
            isIndeterminate: Boolean(selectedMembers.length),
            value: isAllSelected,
          }}
        />
      </div>

      <div>
        <InfiniteScroll
          dataLength={members.length}
          next={() => getTeamMembers(currentPage + 1)}
          loader={null}
          hasMore={currentPage < totalPages - 1}
          height={`calc(100vh - 220px - ${technicalMessageBlockHeight}px)`}
          className={cx(classes.list, { isActive: isDraggableMembers })}
        >
          {!isLoading && !selectedTeam && (
            <div className={classes.emptyTeam} data-testid='empty-label'>
              {t('team.drivers.not.team.label')}
            </div>
          )}

          {!isLoading && selectedTeam?.count === 0 && (
            <div className={classes.emptyTeam}>
              <div onClick={() => setShowAvailableMembers(true)} data-testid='button-add'>
                {t('empty.add.members.label')}
              </div>
              <div data-testid='team-name'>
                {t('empty.add.members.selected.team.label', { teamName: selectedTeam?.name })}
              </div>
            </div>
          )}

          {members.map((member) => {
            const isSelected = selectedMembers.includes(member.driverInfo.id);

            return (
              <div
                key={member.driverInfo.id}
                className={cx(classes.row, {
                  isActive: member.driverInfo.id === anchorEl?.member.driverInfo.id,
                })}
              >
                <div
                  className={classes.content}
                  data-testid={`member-wrap-${member.driverInfo.id}`}
                >
                  {!showAvailableMembers && (
                    <Checkbox
                      onChange={(value) => checkBoxHandler(value, member.driverInfo.id)}
                      value={isAllSelected ? !isSelected : isSelected}
                      testId='checkbox-selected'
                    />
                  )}

                  <div className={classes.item}>
                    <PlateNumber
                      wrapClass={classes.plateNumber}
                      plateNumber={member.vehicleInfo.plateNumber}
                      country={member.vehicleInfo.country}
                    />
                    <VehicleIcon
                      className={classes.vehicleIcon}
                      type={member.vehicleInfo.vehicleType}
                      variant='outline'
                    />
                    <div
                      className={classes.vehicleBrand}
                      data-testid='brand'
                    >{`${member.vehicleInfo.brandName} ${member.vehicleInfo.modelName}`}</div>
                    <div
                      className={classes.driverName}
                      data-testid='driver-name'
                    >{`${member.driverInfo.firstName} ${member.driverInfo.lastName}`}</div>
                  </div>
                </div>

                {!showAvailableMembers && (
                  <EditIcon
                    aria-describedby={id}
                    className={classes.moreIcon}
                    onClick={(e) => handleOpenPopover(e, member)}
                    data-testid='button-edit'
                  />
                )}
              </div>
            );
          })}

          {(Boolean(selectedMembers.length) || isAllSelected) && (
            <Button
              variant={BUTTON_VARIANTS.FILL}
              onClick={deleteListHandler}
              className={classes.deleteBtn}
              testId='button-remove-all'
            >
              {removeBtnText}
            </Button>
          )}
        </InfiniteScroll>
      </div>

      {anchorEl && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl.anchor}
          onClose={handleClosePopover}
          classes={{
            paper: classes.popover,
          }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <DeleteIcon
            className={classes.deleteIcon}
            onClick={deleteHandler}
            data-testid='button-delete'
          />
        </Popover>
      )}
    </div>
  );
};

TeamMembers.displayName = 'team-members';

export default TeamMembers;
