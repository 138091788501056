import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontWeight: 500,
    color: palette.custom.blue200,
    marginBottom: 20,
  },

  blocks: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 5,
    marginBottom: 10,
  },

  block: {
    width: 80,
    height: 10,
    borderRadius: 10,

    '&.bad': {
      backgroundColor: palette.custom.redSecondary,
    },

    '&.normal': {
      backgroundColor: palette.custom.yellow400,
    },

    '&.good': {
      backgroundColor: palette.custom.green400,
    },
  },

  markers: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 70,
  },

  marker: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.primary.main,

    '&:first-child': {
      fontSize: 22,
    },

    '&.bad': {
      color: palette.custom.redSecondary,
    },

    '&.normal': {
      color: palette.custom.yellow400,
    },

    '&.good': {
      color: palette.custom.green400,
    },
  },
}));

export default useStyles;
