import cx from 'classnames';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import SimpleBar from 'simplebar-react';

import { USER_ROLES } from 'interfaces/user/roles';
import { IUserData } from 'interfaces/user/user-data';

import RouteManager from 'utils/services/route-manager';

import settingsCompaniesAtom from 'recoil/settings/settingsCompanies';
import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';
import userDataAtom from 'recoil/userData';

import { getCompaniesReq } from 'requests/company-controller/find-all-companies';

import Loader from 'components/Loader';
import PaginationComponent from 'components/PaginationComponent';

import AddButton from '../../components/AddButton';
import Header from '../../components/header';

import CompanySettingsItem from './components/CompanySettingsItem';
import useStyles from './styles';

const Companies = () => {
  const technicalMessageBlockHeight = useRecoilValue(technicalMessageBlockHeightSelector);
  const classes = useStyles({ technicalMessageBlockHeight });
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('setting.page');
  const userData = useRecoilValue(userDataAtom) as IUserData;

  const [settingsCompanies, setSettingsCompanies] = useRecoilState(settingsCompaniesAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');

  const isSubcompanyView = sessionStorage.getItem('subcompanyId');

  const handleGetCompanies = useCallback(
    async (page: number) => {
      try {
        setIsLoading(true);
        const companies = await getCompaniesReq(page, search);
        setSettingsCompanies(companies);
      } catch {
        enqueueSnackbar('Something Went Wrong', { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
    [enqueueSnackbar, search, setSettingsCompanies],
  );

  useEffect(() => {
    handleGetCompanies(0);
  }, [handleGetCompanies]);

  const handleAddCompany = useCallback(
    () => navigate(RouteManager.makeURL('settings.companies.create')),
    [navigate],
  );

  /**
   * Clear list of companies
   */
  useEffect(() => {
    return () => setSettingsCompanies(null);
  }, [setSettingsCompanies]);

  return (
    <div className={classes.wrap} data-testid='companies-main'>
      <Header
        backBtn={{ label: t('company.label') }}
        searchValue={search}
        onCancelSearch={() => setSearch('')}
        searchHandler={({ query }) => {
          setSearch(query);
        }}
      />

      <div className={classes.content} data-testid='content'>
        {!isSubcompanyView && userData.role === USER_ROLES.TECH_ADMIN && (
          <AddButton onClick={handleAddCompany} />
        )}

        <SimpleBar
          className={cx(classes.companiesWrap, {
            hide: isSubcompanyView || userData.role !== USER_ROLES.TECH_ADMIN,
          })}
          data-testid='companies-list'
        >
          {isLoading && <Loader lightMode preventClick width={150} isBlock />}

          {!isLoading &&
            settingsCompanies?.content.map((item) => (
              <CompanySettingsItem key={item.id} {...item} />
            ))}
        </SimpleBar>

        {settingsCompanies && (
          <PaginationComponent
            size={settingsCompanies?.size}
            page={settingsCompanies.number}
            totalPages={settingsCompanies.totalPages}
            totalElements={settingsCompanies.totalElements}
            numberOfElements={settingsCompanies.numberOfElements}
            onChange={(_e, page) => {
              handleGetCompanies(page - 1);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Companies;
