import cx from 'classnames';
import React from 'react';

import { BUTTON_VARIANTS } from 'interfaces/common/button-variants';
import { FCC } from 'interfaces/common/fc-with-children';

import useStyles from './styles';

interface IButton {
  variant?: BUTTON_VARIANTS;
  testId?: string;
}

type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  IButton;

const Button: FCC<Props> = ({
  children,
  className,
  onClick,
  disabled,
  variant = 'fill',
  type = 'button',
  testId,
  ...props
}) => {
  const classes = useStyles();

  return (
    <button
      className={cx(classes.btn, className, variant)}
      type={type}
      onClick={onClick}
      disabled={disabled}
      data-testid={testId}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
