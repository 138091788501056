import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    backgroundColor: palette.custom.bluePrimary,
    width: 300,
    minHeight: 100,
    borderRadius: 20,
    padding: 20,
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },

  sensorNumber: {
    color: palette.custom.blue200,
    fontWeight: 500,
    fontSize: 20,
  },

  vehicleInfo: {
    backgroundColor: 'transparent',
    padding: 'unset',
    columnGap: 0,
  },

  parameters: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: 10,
    alignItems: 'center',
    marginTop: 20,
  },
}));

export default useStyles;
