import cx from 'classnames';
import format from 'date-fns/format';
import React, { FC, useMemo } from 'react';

import { SENSOR_TYPES } from 'interfaces/sensor/types';

import { ReactComponent as HumidityIcon } from 'assets/images/humidity.svg';
import { ReactComponent as MovementIcon } from 'assets/images/movement.svg';
import { ReactComponent as TemperatureIcon } from 'assets/images/temperature.svg';

import { isEmptyValue } from 'utils/helpers/is-empty-value';

import SliderView from 'components/slider-view';

import useStyles from './styles';

interface IProps {
  min: number;
  max: number;
  current: number;
  type: SENSOR_TYPES;
  date?: string;
  wrapClass?: string;
  isCelsius?: string;
}

const SensorParameterView: FC<IProps> = ({
  min,
  max,
  current,
  type,
  wrapClass = null,
  date = null,
  isCelsius = true,
}) => {
  const classes = useStyles();

  const formattedDate = useMemo(() => {
    if (!date) return;

    return format(new Date(date), 'dd MMM HH:mm');
  }, [date]);

  const typeData = {
    TEMPERATURE: {
      Icon: TemperatureIcon,
      label: isCelsius ? '°C' : '°F',
    },
    HUMIDITY: {
      Icon: HumidityIcon,
      label: '%',
    },
    MOVEMENT: {
      Icon: MovementIcon,
      label: 'dB',
    },
  };

  const { Icon, label } = typeData[type];

  const status = useMemo(() => {
    if (isEmptyValue(current)) {
      return null;
    }
    if (current >= min && current <= max) {
      return 'isGood';
    }
    return 'isBad';
  }, [current, max, min]);

  return (
    <div className={cx(classes.parameterWrap, wrapClass)} data-testid='sensor-param-view-item'>
      <div className={classes.parameterHeader}>
        <Icon className={cx(classes.icon, `${status}`)} />
        {!isEmptyValue(current) && (
          <div className={cx(classes.degrees, `${status}`)}>{`${current} ${label}`}</div>
        )}
        {isEmptyValue(current) && <div className={classes.noData}>No data</div>}
        {!!date && <div className={classes.date}>{formattedDate}</div>}
      </div>

      {!isEmptyValue(current) && <SliderView max={max} min={min} current={current} />}
    </div>
  );
};

export default SensorParameterView;
