import cx from 'classnames';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowBackIcon } from 'assets/images/dashboard-map/arror-back.svg';

import useStyles from './styles';

interface IProps {
  isOpenNav: boolean;
  href?: string;
  label?: string;
}

const BackButton: FC<IProps> = ({ href, isOpenNav, label }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const clickHandler = () => {
    if (href) {
      navigate(href);
      return;
    }

    navigate(-1);
  };

  return (
    <div
      className={cx(classes.linkBack, { isOpenNav, hasLabel: label })}
      onClick={clickHandler}
      data-testdid='button-back'
    >
      <ArrowBackIcon />
      {Boolean(label) && <span>{label}</span>}
    </div>
  );
};

export default BackButton;
