import React from 'react';
import { useTranslation } from 'react-i18next';

import AccidentPredictionData from './components/AccidentPredictionData';
import AnnualScoreChart from './components/AnnualScoreChart';
import DriversList from './components/DriversList';
import DriversStatus from './components/DriversStatus';
import LeaderData from './components/LeaderData';
import SafetyRatioData from './components/SafetyRatioData';
import SavingsChart from './components/SavingsChart';
import ScoringChart from './components/ScoringChart';
import ScoringContainer from './components/ScoringContainer';
import useStyles from './styles';

const Scoring = () => {
  const classes = useStyles();
  const { t } = useTranslation('scoring.page');

  return (
    <div className={classes.scoring}>
      <div className={classes.charts}>
        <div className={classes.trio}>
          <div>
            <ScoringContainer title={t('leader.label')}>
              <LeaderData />
            </ScoringContainer>
          </div>
          <div>
            <ScoringContainer title={t('accident.prediction.label')}>
              <AccidentPredictionData />
            </ScoringContainer>
          </div>
          <div>
            <ScoringContainer title={t('safety.ratio.label')}>
              <SafetyRatioData />
            </ScoringContainer>
          </div>
        </div>
        <div className={classes.duo}>
          <div>
            <ScoringContainer
              title={t('scoring.label')}
              size={'large'}
              titleClassName='scoring__charts__title'
            >
              <ScoringChart />
            </ScoringContainer>
          </div>
          <div>
            <ScoringContainer title={t('savings.label')} size={'large'}>
              <SavingsChart />
            </ScoringContainer>
          </div>
        </div>
        <div className={classes.solo}>
          <ScoringContainer
            subtitle={t('graphics.score.chart.label')}
            size={'large'}
            title={t('graphics.diagnostics.label')}
          >
            <AnnualScoreChart />
          </ScoringContainer>
        </div>
      </div>

      <div className={classes.stats}>
        <div className={classes.statsOne}>
          <ScoringContainer size={'large'} title={t('customer.label')}>
            <DriversStatus />
          </ScoringContainer>
        </div>
        <div className={classes.statsTwo}>
          <ScoringContainer size={'large'}>
            <DriversList />
          </ScoringContainer>
        </div>
      </div>
    </div>
  );
};

export default Scoring;
