import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import cx from 'classnames';
import i18next from 'i18next';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';

import { INTRODUCE_LANGUAGES } from 'constants/introduction';

import { ReactComponent as ArrowDownIcon } from 'assets/images/select-arrow-down.svg';

import introductionPageAtom from 'recoil/introductionPageAtom';

import useStyles from './styles';

const Dropdown = () => {
  const classes = useStyles();
  const [homePageData, setHomePageData] = useRecoilState(introductionPageAtom);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectLang = (value) => {
    setAnchorEl(null);
    setHomePageData({ ...homePageData, lang: value });

    localStorage.setItem('lang', value);
    i18next.changeLanguage(value);
  };

  const handleClose = () => setAnchorEl(null);

  const languageLabel = INTRODUCE_LANGUAGES.find((item) => item.value === homePageData.lang)?.label;

  return (
    <div className={classes.container}>
      <Button className={classes.button} onClick={handleClick}>
        {languageLabel}
        <ArrowDownIcon className={classes.buttonIcon} />
      </Button>
      <Menu
        classes={{ paper: classes.menuPaper, list: classes.menuList }}
        id='basic-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {INTRODUCE_LANGUAGES.map((item, index) => (
          <MenuItem
            key={index}
            className={cx(classes.menuItem, {
              [classes.menuItemActive]: item.value === homePageData.lang,
            })}
            onClick={() => handleSelectLang(item.value)}
          >
            {item.label}
            {item.icon && <ArrowDownIcon className={classes.menuItemIcon} />}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default Dropdown;
