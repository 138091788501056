import cx from 'classnames';
import React, { FC } from 'react';

import { isEmptyValue } from 'utils/helpers/is-empty-value';
import { numberLimit } from 'utils/helpers/number-limit';
import { scoreCircle } from 'utils/helpers/score-circle';

import Delta from 'modules/TripLive/components/delta';

import useStyles from './styles';

interface IProps {
  scoring: number;
  type?: 'dark' | 'light';
  align?: 'top' | 'center' | 'bottom';
  diffScoring?: number;
}

const ScoringBox: FC<IProps> = ({ type = 'dark', scoring, align = 'top', diffScoring }) => {
  const classes = useStyles();

  return (
    <div
      className={cx(classes.scoring, scoreCircle(scoring), type, align)}
      data-testid='scoring-box'
    >
      <span>{numberLimit(Math.abs(scoring))}</span>
      {!isEmptyValue(diffScoring) && <Delta deltaValue={diffScoring} />}
    </div>
  );
};

export default ScoringBox;
