import { atom } from 'recoil';

// TODO: need move types to global
export enum SORT_DIRECTION {
  ASC = 'ASC',
  DESC = 'DESC',
}

// TODO: remove duplicate
export interface ISortParameters {
  sort: string;
  direction: SORT_DIRECTION;
}

const initValues = {
  sort: '',
  direction: SORT_DIRECTION.ASC,
};

const settingPaymentTransactionsListSortAtom = atom<ISortParameters>({
  key: 'settingPaymentTransactionsListSortAtom',
  default: initValues,
});

export default settingPaymentTransactionsListSortAtom;
