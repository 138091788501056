import { ApexOptions } from 'apexcharts';

import { isEmptyValue } from 'utils/helpers/is-empty-value';

const createOptions = (
  saved: number | null = null,
  potential: number | null = null,
): ApexOptions => {
  const renderedSeries = !saved || !potential ? 0 : Math.round((saved / potential) * 100);
  const isNegativeSaving = isEmptyValue(saved) ? false : saved! < 0;
  const isNegativePotential = isEmptyValue(potential) ? false : potential! < 0;

  return {
    series: [renderedSeries === 0 ? 1 : renderedSeries],
    chart: {
      height: '100%',
      width: '100%',
      type: 'radialBar',
      offsetY: 0,
      selection: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        offsetX: 0,
        offsetY: 0,
        hollow: {
          margin: 0,
          size: '70%',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
        },
        track: {
          strokeWidth: '100%',
          margin: 0,
          background: isNegativePotential ? '#FF2828' : '#34BA92',
          opacity: 0.3,
        },
        dataLabels: {
          show: false,
          name: {
            offsetY: -10,
            show: true,
            color: isNegativeSaving ? '#FF2828' : '#34BA92',
            fontSize: '17px',
          },
          value: {
            formatter: function (val) {
              return String(parseInt(String(val), 10));
            },
            color: isNegativeSaving ? '#FF2828' : '#34BA92',
            fontSize: '36px',
            show: true,
          },
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'horizontal',
        inverseColors: false,
        colorStops: [
          {
            offset: 0,
            color: renderedSeries === 0 ? 'transparent' : isNegativeSaving ? '#FF2828' : '#34BA92',
            opacity: 1,
          },
          {
            offset: 100,
            color: renderedSeries === 0 ? 'transparent' : isNegativeSaving ? '#FF2828' : '#34BA92',
            opacity: 1,
          },
        ],
      },
    },
    stroke: {
      lineCap: 'round',
    },
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    labels: ['Percent'],
    grid: {
      padding: {
        top: 10,
      },
    },
  };
};
export default createOptions;
