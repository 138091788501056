import { LICENSE_CATEGORIES } from 'interfaces/driver/license-categories';

import RouteManager from 'utils/services/route-manager';

export const DRIVER_CATEGORIES = Object.values(LICENSE_CATEGORIES).map((value) => ({
  label: value,
  value,
}));

export const driverTripsUrlRegExp = new RegExp(
  RouteManager.path('drivers.trips', { isFullPath: true }).replace(':id', '([^/]+)'),
);
