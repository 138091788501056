export const ROLES_OBJECT = {
  OWNER: 'OWNER',
  TECH_ADMIN: 'TECH_ADMIN',
  ADMIN: 'ADMIN',
  MANAGER: 'MANAGER',
  OPERATOR: 'OPERATOR',
};

export const ROLES = [
  { label: ROLES_OBJECT.OWNER, value: ROLES_OBJECT.OWNER },
  { label: ROLES_OBJECT.TECH_ADMIN, value: ROLES_OBJECT.TECH_ADMIN },
  { label: ROLES_OBJECT.ADMIN, value: ROLES_OBJECT.ADMIN },
  { label: ROLES_OBJECT.MANAGER, value: ROLES_OBJECT.MANAGER },
  { label: ROLES_OBJECT.OPERATOR, value: ROLES_OBJECT.OPERATOR },
];

export const LANGUAGES = [
  { label: 'EN', value: 'EN' },
  { label: 'AZ', value: 'AZ' },
];
