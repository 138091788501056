import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  products: {
    width: '100%',
    maxWidth: '1440px',
    margin: '0 auto',
    opacity: 0.5,
    marginBottom: '30px',
    padding: '0 122px',

    '& > div': {
      fontSize: 40,
      maxWidth: 1200,
      lineHeight: '48px',
      color: theme.palette.primary.main,
      opacity: 0.5,
    },
  },

  container: {
    background: palette.custom.lightBlue,
  },

  swiperSlider: {
    margin: '0 auto',
    maxWidth: 1361,
    paddingTop: 96.82,
    paddingBottom: 57.82,
    paddingRight: 85,
    paddingLeft: 85,
    background: palette.custom.lightBlue,
    transition: 'all 1s ease-in-out 0s',

    '@global': {
      '.swiper-button-next': {
        color: palette.custom.gray200,
        opacity: 0.5,
      },

      '.swiper-button-prev': {
        color: palette.custom.gray200,
        opacity: 0.5,
      },
    },
  },

  slide: {
    paddingTop: 17,
    paddingBottom: 22,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: palette.custom.bluePrimary,
    transition: 'transform 1.5s ease',

    '&.swiper-slide-active': {
      [theme.breakpoints.up('sm')]: {
        transform: 'scale(1.458)',
        paddingBottom: 17,
        paddingTop: 12,
        marginTop: '-5px',
      },
      '& .activeIcon': {
        width: 68,
        height: 68,
        marginTop: 19,
      },

      '& .activeButton': {
        borderColor: palette.custom.gray300,
        marginTop: 32,
        width: 105,
        height: 35.6,
        opacity: 1,

        '&:hover': {
          borderColor: theme.palette.primary.main,
        },
      },

      '& .activeText': {
        color: palette.custom.gray300,
      },
    },
  },

  title: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '17px',
    color: theme.palette.primary.main,
  },

  icon: {
    width: 60,
    height: 60,
    marginTop: 27,
  },

  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 93.35,
    height: 31.73,
    border: `0.610154px solid ${palette.custom.gray200}`,
    boxSizing: 'border-box',
    borderRadius: '6.10154px',
    cursor: 'pointer',
    backgroundColor: palette.custom.bluePrimary,
    marginTop: 27,
    opacity: 0.5,

    '&:hover': {
      opacity: 0.7,
    },
  },

  text: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '14px',
    color: palette.custom.gray200,
  },

  textActive: {
    backgroundColor: 'red',
  },

  textDate: {
    fontWeight: 400,
    fontSize: 10,
    lineHeight: '11.93px',
    color: palette.custom.gray300,
  },
}));

export default useStyles;
