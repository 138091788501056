import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { TABS } from './data';
import useStyles from './styles';

const Tabs = () => {
  const classes = useStyles();
  const { t } = useTranslation('driver.list.page');

  return (
    <div className={classes.tabs} data-testid='tabs'>
      {TABS(t).map(({ name, id, path }) => {
        return (
          <NavLink
            key={id}
            to={path}
            className={({ isActive }) => {
              return cx(isActive ? 'isActive' : '', classes.tab);
            }}
            data-testid='tab'
          >
            {name}
          </NavLink>
        );
      })}
    </div>
  );
};

export default Tabs;
