import cx from 'classnames';
import format from 'date-fns/format';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import crashModalAtom from 'recoil/crash-modal';

import { sendCrashDataReq } from 'requests/gps-service/crash-controller/save-management-data';

import CheckBox from 'components/FormComponents/CheckBox';
import OutlinedSelect from 'components/FormComponents/OutlinedSelect';
import TextArea from 'components/FormComponents/TextArea';

import Button from '../../components/button';

import useStyles from './styles';

const Form = () => {
  const isSubcompanyView = sessionStorage.getItem('subcompanyId');
  const classes = useStyles();
  const { t } = useTranslation('crash.modal');

  const [{ crashData, driverData, currentCrashIdNumber, crashList, managementData }] =
    useRecoilState(crashModalAtom);

  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: managementData,
  });

  const statusItems = useMemo(() => {
    return [
      { label: t('form.vehicle.good.condition.label'), value: 'GOOD_CONDITION' },
      { label: t('form.vehicle.normal.condition.label'), value: 'SOME_DAMAGED' },
      { label: t('form.vehicle.bad.condition.label'), value: 'FULLY_DAMAGED' },
    ];
  }, [t]);

  const driverHealth = useMemo(() => {
    return [
      { label: t('form.human.good.condition.label'), value: 'GOOD_CONDITION' },
      { label: t('form.human.minor.medical.label'), value: 'MINOR_MEDICAL_NEED' },
      { label: t('form.human.major.medical.label'), value: 'MAJOR_MEDICAL_NEED' },
    ];
  }, [t]);

  const passengerHealth = useMemo(() => {
    return [
      { label: t('form.human.good.condition.label'), value: 'GOOD_CONDITION' },
      { label: t('form.human.minor.medical.label'), value: 'MINOR_MEDICAL_NEED' },
      { label: t('form.human.major.medical.label'), value: 'MAJOR_MEDICAL_NEED' },
      { label: t('form.numan.no.passengers.label'), value: 'NO_PASSENGER' },
    ];
  }, [t]);

  const impact = useMemo(() => {
    if (!crashData || !driverData) {
      return;
    }

    return [
      { label: 'impact.id.label', value: crashData.crashId },
      { label: 'impact.date.label', value: format(crashData.impactDate, 'dd MMMM yyyy') },
      { label: 'impact.time.label', value: format(crashData.impactDate, 'HH:mm:ss') },
      { label: 'driver.id.label', value: driverData.driver.pin },
    ];
  }, [crashData, driverData]);

  const onSubmit = async (values) => {
    try {
      await sendCrashDataReq(crashList[currentCrashIdNumber], values);
      enqueueSnackbar('Success', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  return (
    <form className={classes.wrap} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.impact}>
        {impact?.map(({ label, value }) => (
          <div className={classes.impactItem} key={label}>
            <div className={classes.impactName}>{t(label)}</div>
            <div className={classes.impactValue}>{value ?? '-'}</div>
          </div>
        ))}

        <div className={cx(classes.impactItem, classes.validate)}>
          <div className={classes.impactName}>{t('validated.label')}</div>
          <label className={cx(classes.validateWrap, isSubcompanyView && 'disabled')}>
            <input
              className={classes.hiddenCheckbox}
              name='validated'
              checked={watch('validated')}
              type='checkbox'
              onChange={(e) => {
                if (typeof e.target.checked !== 'undefined') {
                  setValue('validated', e.target.checked);
                }
              }}
            />
            <div className={'validateYes'}>{t('yes.label')}</div>
            <div className={cx(classes.fakeValidate, 'fakeValidate')}>
              <div className={classes.circle} />
            </div>
            <div className={'validateNo'}>{t('no.label')}</div>
          </label>
        </div>
      </div>

      <div className={classes.management}>
        <div className={classes.managementTitle}>{t('management.actions.label')}</div>
        <div className={classes.managementForm}>
          <div className={classes.callDriver}>
            <div className={classes.label}>{t('call.driver.label')}</div>
            <CheckBox
              control={control}
              name='callDriver'
              size='small'
              label={`${driverData.driver.phonePrefix}${driverData.driver.phoneNumber}`}
              isLightBackground={true}
              isDisabled={isSubcompanyView}
            />
            {driverData.driver.sosPhonePrefix && driverData.driver.sosPhoneNumber && (
              <CheckBox
                control={control}
                name='callSos'
                size='small'
                label={`${driverData.driver.sosPhonePrefix}${driverData.driver.sosPhoneNumber} (SOS)`}
                isLightBackground={true}
                isDisabled={isSubcompanyView}
              />
            )}
          </div>

          <div className={classes.callAmbulance}>
            <div className={classes.label}>{t('call.ambulance.label')}</div>
            <CheckBox
              control={control}
              name='callAmbulance'
              size='small'
              label='103'
              isLightBackground={true}
              isDisabled={isSubcompanyView}
            />
          </div>

          <div className={classes.insurance}>
            <div className={classes.label}>{t('insurance.label')}</div>
            <CheckBox
              control={control}
              name='notificationAlreadySent'
              size='small'
              label={t('notification.already.sent.label')}
              isLightBackground={true}
              isDisabled={isSubcompanyView}
            />
          </div>

          <div className={classes.cargo}>
            <div className={cx(classes.label, classes.labelWithoutMargin)}>
              {t('cargo.status.label')}
            </div>
            <OutlinedSelect
              control={control}
              rules={{ required: '*' }}
              name='cargoStatus'
              items={statusItems}
              error={errors?.cargoStatus}
              helperText={errors?.cargoStatus?.message}
              isDisabled={isSubcompanyView}
            />
          </div>

          <div className={classes.noteWrap}>
            <TextArea
              className={classes.note}
              control={control}
              name='notes'
              label={t('add.note.label')}
              rows={1}
              isDisabled={isSubcompanyView}
            />
          </div>

          <div className={classes.driverHealth}>
            <div className={cx(classes.label, classes.labelWithoutMargin)}>
              {t('driver.health.label')}
            </div>
            <OutlinedSelect
              control={control}
              rules={{ required: '*' }}
              name='driverHealth'
              items={driverHealth}
              error={errors?.driverHealth}
              helperText={errors?.driverHealth?.message}
              isDisabled={isSubcompanyView}
            />
          </div>

          <div className={classes.passengerHealth}>
            <div className={cx(classes.label, classes.labelWithoutMargin)}>
              {t('passenger.health.label')}
            </div>
            <OutlinedSelect
              control={control}
              rules={{ required: '*' }}
              name='passengerHealth'
              items={passengerHealth}
              error={errors?.passengerHealth}
              helperText={errors?.passengerHealth?.message}
              isDisabled={isSubcompanyView}
            />
          </div>

          {!isSubcompanyView && <Button />}
        </div>
      </div>
    </form>
  );
};

Form.defaultProps = {};

Form.propTypes = {};

export default React.memo(Form);
