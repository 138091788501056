import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  info: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    marginBottom: '18px',
  },

  linkDriver: {
    marginLeft: '10px',
    color: palette.custom.blue,
    fontWeight: 300,
    fontSize: 20,
    lineHeight: '23.87px',
    marginRight: '3px',
    cursor: 'pointer',
    borderBottom: `1px solid ${palette.custom.blue}`,
  },

  container: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    zIndex: 5,
  },

  date: {
    fontFamily: 'SFPro',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '19px',
    letterSpacing: '-1px',
    color: theme.palette.primary.dark,
    paddingTop: '2px',
    marginLeft: 30,
  },

  pathWrap: {
    position: 'relative',
    marginRight: 45,
  },

  pathList: {
    position: 'absolute',
    height: 'calc(100vh - 290px)',
    width: '100%',
    overflow: 'hidden',
    background: palette.custom.lightBlue,
  },

  cardWrap: {
    borderTop: `0.2px solid ${palette.custom.borderDark}`,

    '&.active': {
      backgroundColor: palette.custom.softBlue,
    },
  },

  dateLabel: {
    position: 'relative',
    top: 17,
    left: 22,
    fontFamily: 'SFPro',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '19px',
    letterSpacing: '-1px',
    color: theme.palette.primary.dark,
  },
}));

export default useStyles;
