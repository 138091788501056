import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

interface IProps {
  isBig: boolean;
}

const useStyles = makeStyles<Theme, IProps>((theme) => ({
  pathWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },

  point: {
    marginRight: 45,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',

    '&:last-child': {
      marginRight: 0,
    },

    '& svg': {
      width: 24,
    },
  },

  pointDesc: {
    marginLeft: 7,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  address: {
    cursor: 'pointer',
    maxWidth: 90,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    fontSize: ({ isBig }) => (isBig ? 20 : 14),
  },

  time: {
    fontWeight: 300,
    fontSize: ({ isBig }) => (isBig ? 18 : 16),
    opacity: 0.5,
  },
}));

export default useStyles;
