import queryString from 'query-string';

import { ISortParameters } from 'interfaces/common/sort';
import { IGetDriverBreakdown } from 'interfaces/saving/i-get-driver-breakdown';
import { ISavingFilter } from 'interfaces/saving/i-saving-filter';

import server from 'utils/server';

/**
 * Retrieves driver data based on the specified filter and sort parameters.
 *
 * @async
 * @function getDriversData
 * @param {ISavingFilter} filter - The filter parameters to apply.
 * @param {ISortParameters} sort - The sort parameters to apply.
 * @returns {Promise<IGetDriverBreakdown[]>} A promise that resolves to an array of driver data.
 */
export const getDriversData = async (
  filter: ISavingFilter,
  sort: ISortParameters,
): Promise<IGetDriverBreakdown[]> => {
  const params = {
    ...filter,
    direction: sort.direction,
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
  };

  return server.services.beService
    .get<IGetDriverBreakdown[]>(`saving/driver/breakdown?${queryString.stringify(params)}`)
    .then((res) => res.data);
};
