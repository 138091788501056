import { atom } from 'recoil';

import { IScoringDriver } from 'interfaces/scoring/i-scoring-driver';

const scoringRatingChart = atom<IScoringDriver[] | null>({
  key: 'scoringRatingChartAtom',
  default: [],
});

export default scoringRatingChart;
