import { TDataWithId } from 'interfaces/common/data-with-id';

import server from 'utils/server';

import { TDriverCreateData } from 'modules/DriverProfile/form-schema';

/**
 * Creates a new driver by sending a POST request to the server.
 *
 * @async
 * @param {TDriverCreateData} data - The data for creating the driver.
 * @returns {Promise<TDataWithId<TDriverCreateData>>} - A promise that resolves to the created driver object.
 */
export const driverCreateReq = async (
  data: TDriverCreateData,
): Promise<TDataWithId<TDriverCreateData>> => {
  const body = {
    ...data,
    active: true,
  };

  return server.services.beService
    .post<TDataWithId<TDriverCreateData>>('drivers/', body)
    .then((res) => res.data);
};
