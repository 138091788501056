import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',

    '& .MuiInputBase-root': {
      backgroundColor: palette.custom.blue900,
      border: `1px solid ${palette.custom.blue900}`,
      width: '100%',
    },

    '& .MuiInputBase-root.Mui-focused': {
      backgroundColor: palette.custom.softBlue,
      border: `1px solid ${palette.custom.softBlue}`,
    },

    '& .MuiInputBase-root.Mui-error': {
      border: `1px solid ${palette.custom.redSecondary}`,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },

  root: {
    borderRadius: 5,
    padding: 8,
    color: alpha(palette.custom.blue200, 0.4),

    '&.hasValue': {
      padding: '5px 10px',
    },
  },

  disabled: {
    textFillColor: `${alpha(palette.custom.blue200, 0.4)} !important`,
    backgroundColor: palette.custom.hoverBlue,
    border: `1px solid ${palette.custom.bluePrimary}`,

    '& $selectedValue': {
      textFillColor: `${palette.custom.blue200} !important`,
      color: palette.custom.blue200,

      '&.withBackground': {
        backgroundColor: palette.custom.blue800,
      },
    },
  },

  selectedValue: {
    fontFamily: 'SF Pro Text',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: palette.custom.blue300,
    padding: 3,
    borderRadius: 5,
    marginRight: 5,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'pre',

    '&.withBackground': {
      backgroundColor: palette.custom.blue800,
    },

    '&:last-child': {
      marginRight: 0,
    },
  },

  renderValueWrap: {
    display: 'flex',
    overflow: 'hidden',
  },

  arrow: {
    marginRight: 10,
    pointerEvents: 'none',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',

    '&.MuiSelect-iconOpen': {
      transform: 'translateY(-50%) rotate(180deg)',
    },
  },

  paper: {
    boxShadow: 'none',
    borderRadius: '0 0 4px 4px',
    border: `1px solid ${palette.custom.softBlue}`,
    borderTop: 'none',
    maxHeight: 125,
    backgroundColor: palette.custom.softBlue,
    maxWidth: 410,
    transform: 'translateX(1px) !important',

    '&::-webkit-scrollbar': {
      borderRadius: '0 0 10px 0',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.dark,
    },
  },

  list: {
    backgroundColor: palette.custom.softBlue,
  },

  selectedOption: {
    '&.Mui-selected': {
      backgroundColor: 'transparent',
    },
  },

  checkBox: {
    minWidth: 20,
    width: 20,
    height: 20,
    border: `1px solid ${palette.custom.blue300}`,
    borderRadius: 2,
    marginRight: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&.isChecked': {
      border: `1px solid ${palette.custom.goldSecondary}`,
    },
  },

  radioButton: {
    minWidth: 20,
    width: 20,
    height: 20,
    borderRadius: '50%',
    border: `1px solid ${palette.custom.blue200}`,
    marginRight: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&.isChecked': {
      border: `1px solid ${palette.custom.goldSecondary}`,
    },
  },

  checkedIcon: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: palette.custom.goldSecondary,

    '&.multiple': {
      borderRadius: 1,
    },
  },

  text: {
    fontFamily: 'SFPro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: palette.custom.blue200,
  },
}));

export default useStyles;
