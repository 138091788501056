import DialogContent from '@mui/material/DialogContent';
import Grow from '@mui/material/Grow';
import Modal from '@mui/material/Modal';
import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { IMapSideBarItem } from 'interfaces/map/i-map-side-bar-item';

import RouteManager from 'utils/services/route-manager';

import { IMapVehicle } from 'requests/mobile-service/map-get-all-vehicles';

import VehicleInfo from 'modules/dasboard-map/vehicle-info';

import useStyles from './styles';

interface IProps {
  activeClusterData: IMapVehicle[] | IMapSideBarItem[] | null;
  onClose: () => void;
}

const getIsMapVehicle = (item: IMapVehicle | IMapSideBarItem): item is IMapVehicle => {
  return (item as IMapVehicle).scoring !== undefined;
};

const ClusterModal: FC<IProps> = ({ activeClusterData, onClose }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleLink = useCallback(
    (vehicleId: string) => {
      navigate(RouteManager.makeURL('dashboardMap.live', { id: vehicleId }));
    },
    [navigate],
  );

  return (
    <Modal
      open={Boolean(activeClusterData)}
      classes={{ backdrop: classes.backdrop }}
      onClose={onClose}
    >
      <Grow in={Boolean(activeClusterData)} timeout={250}>
        <DialogContent className={classes.dialogContent} data-testdid='cluster-modal'>
          {activeClusterData
            ?.sort(
              (a, b) =>
                (getIsMapVehicle(a) ? a?.scoring : a?.viewData?.scoring) -
                (getIsMapVehicle(b) ? b?.scoring : b?.viewData?.scoring),
            )
            ?.map((item) => {
              if (!item.vehicle) {
                return null;
              }
              const isMapVehicle = getIsMapVehicle(item);

              const vehicleId = item.vehicle.id;
              const viewType = 'VEHICLE';
              const vehicleType = isMapVehicle ? item?.vehicle?.type : item?.vehicle?.vehicleType;
              const lastName = isMapVehicle ? item.driver.name.split(' ')[1] : item.driver.lastName;
              const firstName = isMapVehicle
                ? item.driver.name.split(' ')[0]
                : item.driver.firstName;
              const plateNumber = item.vehicle.plateNumber;
              const country = isMapVehicle ? item.vehicle.countryCode : item.vehicle.country;
              const status = isMapVehicle ? item?.status : item?.viewData?.status;
              const brandName = isMapVehicle ? item.vehicle.brand : item.vehicle.brandName;
              const modelName = isMapVehicle ? item.vehicle.model : item.vehicle.modelName;
              const scoring = isMapVehicle ? item?.scoring : item?.viewData?.scoring;

              return (
                <VehicleInfo
                  wrapClass={classes.item}
                  key={item.driver.id}
                  handleLink={() => handleLink(vehicleId)}
                  viewType={viewType}
                  vehicleType={vehicleType}
                  lastName={lastName}
                  firstName={firstName}
                  plateNumber={plateNumber}
                  country={country}
                  status={status}
                  brandName={brandName}
                  modelName={modelName}
                  scoring={scoring}
                />
              );
            })}
        </DialogContent>
      </Grow>
    </Modal>
  );
};

export default ClusterModal;
