import { palette } from 'assets/theme';

export const getScoringColor = (scoring: number): string => {
  if (scoring >= 0) {
    return palette.custom.green400;
  }
  if (scoring >= -30) {
    return palette.custom.yellow400;
  }
  return palette.custom.redSecondary;
};
