import { atom } from 'recoil';

import {
  NOTIFICATION_NOT_READ_TYPES,
  TNotificationNotRead,
} from 'interfaces/notifications/notification-types';

const notificationsNotReadCountAtom = atom<TNotificationNotRead>({
  key: 'notificationsNotReadCountAtom',
  default: {
    [NOTIFICATION_NOT_READ_TYPES.TOTAL]: 0,
  },
});

export default notificationsNotReadCountAtom;
