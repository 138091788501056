import { FunctionComponent, SVGProps } from 'react';

import { USER_ROLES } from 'interfaces/user/roles';

import { ReactComponent as CompaniesIcon } from 'assets/images/settings/companies.svg';
import { ReactComponent as DriversIcon } from 'assets/images/settings/drivers.svg';
import { ReactComponent as FinanceIcon } from 'assets/images/settings/finance.svg';
import { ReactComponent as FuelPriceIcon } from 'assets/images/settings/fuel-price.svg';
import { ReactComponent as LanguageIcon } from 'assets/images/settings/language.svg';
import { ReactComponent as SpeedManagementIcon } from 'assets/images/settings/speed-management.svg';
import { ReactComponent as TeamsIcon } from 'assets/images/settings/teams.svg';
import { ReactComponent as UsersIcon } from 'assets/images/settings/users.svg';
import { ReactComponent as VehicleBrandIcon } from 'assets/images/settings/vehicle-brand.svg';
import { ReactComponent as VehiclesIcon } from 'assets/images/settings/vehicles.svg';

import RouteManager from 'utils/services/route-manager';

interface ISettingLink {
  id: string;
  title: string;
  url: string;
  createLink: string | null;
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  availableRoles: USER_ROLES[];
}

const LINKS = (useRole: USER_ROLES): ISettingLink[] => {
  return [
    {
      id: 'drivers',
      title: 'drivers.label',
      url: RouteManager.makeURL('settings.drivers'),
      createLink: RouteManager.makeURL('drivers.create'),
      Icon: DriversIcon,
      availableRoles: [...Object.values(USER_ROLES)],
    },
    {
      id: 'vehicles',
      title: 'vehicles.label',
      url: RouteManager.makeURL('settings.vehicles'),
      createLink:
        useRole === USER_ROLES.TECH_ADMIN ? RouteManager.makeURL('vehicles.create') : null,
      Icon: VehiclesIcon,
      availableRoles: [...Object.values(USER_ROLES)],
    },
    {
      id: 'teams',
      title: 'teams.label',
      url: RouteManager.makeURL('settings.teams'),
      createLink: null,
      Icon: TeamsIcon,
      availableRoles: [USER_ROLES.TECH_ADMIN, USER_ROLES.ADMIN, USER_ROLES.OWNER],
    },
    {
      id: 'vehicleBrand',
      title: 'vehicle.model.label',
      url: RouteManager.makeURL('settings.vehicleBrand'),
      createLink: null,
      Icon: VehicleBrandIcon,
      availableRoles: [USER_ROLES.TECH_ADMIN],
    },
    {
      id: 'fuelPrice',
      title: 'fuel.label',
      url: RouteManager.makeURL('settings.fuelPrice'),
      createLink: null,
      Icon: FuelPriceIcon,
      availableRoles: [USER_ROLES.TECH_ADMIN],
    },
    {
      id: 'speedManagement',
      title: 'speed.management.label',
      url: RouteManager.makeURL('settings.speedManagement'),
      createLink: null,
      Icon: SpeedManagementIcon,
      availableRoles: [USER_ROLES.TECH_ADMIN],
    },
    {
      id: 'language',
      title: 'language.label',
      url: RouteManager.makeURL('settings.language'),
      createLink: null,
      Icon: LanguageIcon,
      availableRoles: [USER_ROLES.TECH_ADMIN],
    },
    {
      id: 'companies',
      title: 'company.label',
      url: RouteManager.makeURL('settings.companies'),
      createLink:
        useRole === USER_ROLES.TECH_ADMIN
          ? RouteManager.makeURL('settings.companies.create')
          : null,
      Icon: CompaniesIcon,
      availableRoles: [USER_ROLES.TECH_ADMIN, USER_ROLES.ADMIN, USER_ROLES.OWNER],
    },
    {
      id: 'users',
      title: 'user.roles.label',
      url: RouteManager.makeURL('settings.users'),
      createLink: RouteManager.makeURL('settings.users.create'),
      Icon: UsersIcon,
      availableRoles: [USER_ROLES.TECH_ADMIN, USER_ROLES.ADMIN, USER_ROLES.OWNER],
    },
    {
      id: 'payment',
      title: 'payment.label',
      url: RouteManager.makeURL('settings.payment'),
      createLink: null,
      Icon: FinanceIcon,
      availableRoles: [USER_ROLES.TECH_ADMIN, USER_ROLES.ADMIN, USER_ROLES.OWNER],
    },
    {
      id: 'geo',
      title: 'geo.label',
      url: RouteManager.makeURL('settings.geo.zones'),
      createLink: null,
      Icon: FinanceIcon,
      availableRoles: [],
    },
  ];
};

export default LINKS;
