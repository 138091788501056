import { yupResolver } from '@hookform/resolvers/yup';
import cx from 'classnames';
import React, { FC, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BUTTON_VARIANTS } from 'interfaces/common/button-variants';
import { TDataWithId } from 'interfaces/common/data-with-id';
import { TSensorRenderData } from 'interfaces/sensor/t-sensor-render-data';

import { ReactComponent as BellIcon } from 'assets/images/bell.svg';

import { valueConverter } from 'utils/helpers/value-converter';

import { updateSensorReq } from 'requests/be-service/sensor-controller/update-sensor';

import Button from 'components/form-v2/button';
import Select from 'components/form-v2/select';
import Slider from 'components/form-v2/slider';
import SwitchWithController from 'components/form-v2/switch-with-controller';
import TemperatureSwitch from 'components/form-v2/temperature-switch';
import Loader from 'components/Loader';

import { formSchema } from './form-schema';
import useStyles from './styles';

interface IProps {
  data: TDataWithId<TSensorRenderData>;
  disabled?: boolean;
}

const Sensor: FC<IProps> = ({ data, disabled }) => {
  const classes = useStyles();
  const { t } = useTranslation('sensor.service');
  const [isLoading, setIsLoading] = useState(false);

  const formMethods = useForm<TDataWithId<TSensorRenderData>>({
    defaultValues: data,
    resolver: yupResolver(formSchema),
  });

  const updateSensorHandler = useCallback(
    async (formData: TDataWithId<TSensorRenderData>) => {
      try {
        setIsLoading(true);
        await updateSensorReq(formData.id, formData.vehicleId, formData);
        formMethods.reset(formData);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [formMethods],
  );

  return (
    <FormProvider {...formMethods}>
      <div className={classes.wrap} data-testid='sensor-wrap'>
        {isLoading && <Loader lightMode preventClick width={100} isBlock />}

        <div className={classes.header}>
          <div className={classes.info}>
            <div className={classes.sensorNumber} data-testid='sensorNumber'>
              {data.sensorNumber?.split('_').slice(1).join(' ')}
            </div>
            <div className={classes.sensorName} data-testid='sensorName'>
              {data.sensorName}
            </div>
          </div>

          <SwitchWithController
            name={'notification'}
            LeftIcon={BellIcon}
            RightIcon={BellIcon}
            disabled={disabled}
          />
        </div>

        <div>
          <div className={classes.parameter}>
            <div className={classes.parameterHeader}>
              <SwitchWithController name={'temperature.active'} disabled={disabled} />

              <div className={classes.parameterTitle}>{t('temperature.title.label')}</div>

              <TemperatureSwitch
                name={'temperature.celsius'}
                disabled={!formMethods.watch('temperature.active') || disabled}
                additionalOnChange={(event) => {
                  const temperature = formMethods.getValues('temperature.value');
                  formMethods.setValue(
                    'temperature.value',
                    temperature.map((item) => {
                      return event.target.checked
                        ? valueConverter.temperature.fahrenheitToCelsius(item)
                        : valueConverter.temperature.celsiusToFahrenheit(item);
                    }),
                  );
                }}
              />
            </div>
            <Slider
              name={'temperature.value'}
              min={
                formMethods.watch('temperature.celsius')
                  ? -20
                  : valueConverter.temperature.celsiusToFahrenheit(-20)
              }
              max={
                formMethods.watch('temperature.celsius')
                  ? 60
                  : valueConverter.temperature.celsiusToFahrenheit(60)
              }
              marks={[
                {
                  value: formMethods.watch('temperature.celsius')
                    ? -20
                    : valueConverter.temperature.celsiusToFahrenheit(-20),
                  label: formMethods.watch('temperature.celsius')
                    ? -20
                    : valueConverter.temperature.celsiusToFahrenheit(-20),
                },
                {
                  value: 0,
                  label: 0,
                },
                {
                  value: formMethods.watch('temperature.celsius')
                    ? 60
                    : valueConverter.temperature.celsiusToFahrenheit(60),
                  label: formMethods.watch('temperature.celsius')
                    ? 60
                    : valueConverter.temperature.celsiusToFahrenheit(60),
                },
              ]}
              disabled={!formMethods.watch('temperature.active') || disabled}
            />
          </div>

          <div className={classes.parameter}>
            <div className={cx(classes.header, classes.parameterHeader)}>
              <SwitchWithController name={'humidity.active'} disabled={disabled} />

              <div className={classes.parameterTitle}>{t('humidity.title.label')}</div>

              <div className={classes.parameterSubtitle}>%</div>
            </div>

            <Slider
              name={'humidity.value'}
              min={0}
              max={100}
              disabled={!formMethods.watch('humidity.active') || disabled}
              marks={[
                {
                  value: 0,
                  label: '0',
                },
                {
                  value: 100,
                  label: '100',
                },
              ]}
            />
          </div>

          <div className={classes.parameter}>
            <div className={cx(classes.header, classes.parameterHeader, 'isMovement')}>
              {/* remove true after implement movement functionality*/}
              <SwitchWithController name={'movement.active'} disabled={true || disabled} />

              <div className={classes.parameterTitle}>{t('movement.title.label')}</div>

              <div className={classes.parameterSubtitle}>dB</div>
            </div>

            <div className={classes.selectWrap}>
              <Select
                title={''}
                name={'movement.zones'}
                items={[]}
                disabled={!formMethods.watch('movement.active') || disabled}
              />
              <Select
                title={''}
                name={'movement.subzones'}
                items={[]}
                disabled={!formMethods.watch('movement.active') || disabled}
              />
            </div>

            <Slider
              name={'movement.value'}
              min={0}
              max={100}
              disabled={!formMethods.watch('movement.active') || disabled}
              marks={[
                {
                  value: 0,
                  label: '0',
                },
                {
                  value: 100,
                  label: '100',
                },
              ]}
            />
          </div>
        </div>

        {formMethods.formState.isDirty && (
          <Button
            className={classes.btn}
            onClick={formMethods.handleSubmit(updateSensorHandler)}
            type={'button'}
            variant={BUTTON_VARIANTS.FILL}
            testId='button-update'
            disabled={disabled}
          >
            Update
          </Button>
        )}
      </div>
    </FormProvider>
  );
};

export default Sensor;
