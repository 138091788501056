import cx from 'classnames';
import React, { useCallback, useMemo } from 'react';

import { FCC } from 'interfaces/common/fc-with-children';
import { ISortParameters } from 'interfaces/common/sort';

import { ReactComponent as SortAscIcon } from 'assets/images/sort-asc-icon.svg';
import { ReactComponent as SortDefaultIcon } from 'assets/images/sort-default-icon.svg';
import { ReactComponent as SortDescIcon } from 'assets/images/sort-desc-icon.svg';

import useStyles from './styles';

interface IProps {
  name: string;
  sortField: string;
  direction: ISortParameters['direction'];
  onClick: (name: string) => void;
  disabled?: boolean;
}

const TableSortLabel: FCC<IProps> = ({
  name,
  sortField,
  direction,
  children,
  onClick,
  disabled,
}) => {
  const classes = useStyles();

  const SortIcon = useMemo(() => {
    if (name !== sortField) {
      return SortDefaultIcon;
    }
    switch (direction) {
      case 'ASC':
        return SortAscIcon;
      case 'DESC':
        return SortDescIcon;
      default:
        return SortDefaultIcon;
    }
  }, [direction, name, sortField]);

  const handleSort = useCallback(() => onClick(name), [name, onClick]);

  return (
    <span
      className={cx(classes.table, { disabled })}
      onClick={handleSort}
      data-testid={`table-sort-label-${name}`}
    >
      {children}
      {!disabled && <SortIcon className={classes.tableIcon} />}
    </span>
  );
};

export default React.memo(TableSortLabel);
