import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { ROLES_OBJECT } from 'constants/user';

import RouteManager from 'utils/services/route-manager';

import settingsPagesLinksAtom from 'recoil/settings/pages-links';
import userDataAtom from 'recoil/userData';

import useStyles from './styles';

const Settings = () => {
  const classes = useStyles();
  const navigation = useNavigate();
  const { t } = useTranslation('setting.page');
  const userData = useRecoilValue(userDataAtom);
  const links = useRecoilValue(settingsPagesLinksAtom);

  if (
    ![
      ROLES_OBJECT.TECH_ADMIN,
      ROLES_OBJECT.ADMIN,
      ROLES_OBJECT.OWNER,
      ROLES_OBJECT.MANAGER,
    ].includes(userData?.role ?? '')
  ) {
    return <Navigate to={RouteManager.makeURL('scoring')} />;
  }

  return (
    <div className={cx(classes.wrap, userData?.role)}>
      {links.map(({ title, id, url, Icon, createLink }) => {
        return (
          <div
            className={classes.item}
            key={id}
            style={{ gridArea: id }}
            onClick={() => navigation(url)}
            data-testid={`page-item-${id}`}
          >
            <div className={classes.title}>{t(title)}</div>
            <Icon />

            {createLink && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  navigation(createLink);
                }}
                className={classes.link}
              >
                Add new +
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Settings;
