import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrap: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
  },
}));

export default useStyles;
