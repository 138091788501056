import cx from 'classnames';
import React, { FC, HTMLAttributes, useMemo } from 'react';

import { VEHICLE_TYPES } from 'interfaces/vehicles/types';

import { ReactComponent as BusIcon } from 'assets/images/vehicles/bus.svg';
import { ReactComponent as MotorcycleIcon } from 'assets/images/vehicles/motorcycle.svg';
import { ReactComponent as SedanIcon } from 'assets/images/vehicles/sedan.svg';
import { ReactComponent as TractorIcon } from 'assets/images/vehicles/tractor.svg';
import { ReactComponent as VehicleIcon } from 'assets/images/vehicles/truck.svg';

import useStyles from './styles';

interface ICarIcon {
  type: VEHICLE_TYPES | null;
  sizeLittle: boolean;
}

type Props = HTMLAttributes<SVGAElement> & ICarIcon;

const CarIcon: FC<Props> = ({ type, sizeLittle }) => {
  const classes = useStyles();

  const Icon = useMemo(() => {
    switch (type) {
      case VEHICLE_TYPES.SEDAN:
        return SedanIcon;
      case VEHICLE_TYPES.MOTORCYCLE:
        return MotorcycleIcon;
      case VEHICLE_TYPES.TRACTOR:
        return TractorIcon;
      case VEHICLE_TYPES.BUS:
        return BusIcon;
      case VEHICLE_TYPES.TRUCK:
        return VehicleIcon;
      default:
        return null;
    }
  }, [type]);

  return (
    <>{Icon && <Icon className={cx(classes.vehicle, sizeLittle && classes.vehicleLittle)} />}</>
  );
};

export default CarIcon;
