import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import {
  ISettingPaymentTransactionsFilters,
  settingPaymentTransactionsListFilterSelector,
} from 'recoil/settings/payment/transactions/filters';

import DatePicker from 'components/form-v2/date-picker';
import ModalActions from 'components/modal-actions';

import { formSchema } from './form-schema';
import useStyles from './styles';

interface IFilters {
  onClose: () => void;
}

const Filters: FC<IFilters> = ({ onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation('fields');
  const [filters, setFilters] = useRecoilState(settingPaymentTransactionsListFilterSelector);
  const formMethods = useForm<ISettingPaymentTransactionsFilters>({
    defaultValues: filters,
    resolver: yupResolver(formSchema),
  });

  const onSaveFilters = (data: ISettingPaymentTransactionsFilters) => {
    setFilters(data);
    onClose();
  };

  return (
    <div className={classes.wrapper} data-testid='transaction-filters-wrap'>
      <FormProvider {...formMethods}>
        <DatePicker title={`${t('date.label')} (${t('from.filter.label')})`} name={'startDate'} />
        <DatePicker title={`${t('date.label')} (${t('to.filter.label')})`} name={'endDate'} />
        <ModalActions handleClose={onClose} handleSave={formMethods.handleSubmit(onSaveFilters)} />
      </FormProvider>
    </div>
  );
};

export default Filters;
