import React, { FC } from 'react';

import { ReactComponent as WarningIcon } from 'assets/images/warning.svg';

import useStyles from './styles';

interface ICollision {
  count: number;
  onClick?: () => void;
}

const Collision: FC<ICollision> = ({ count, onClick }) => {
  const classes = useStyles();

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onClick?.();
  };

  if (!count) {
    return <div className={classes.wrap}>–</div>;
  }

  return (
    <div className={classes.wrap}>
      <div className={classes.icon} onClick={handleClick} data-testid='button-collision'>
        <WarningIcon />
        {count > 1 && (
          <div className={classes.counter} data-testid='collision-count'>
            {count}
          </div>
        )}
      </div>
    </div>
  );
};

export default Collision;
