import { atom } from 'recoil';

import { NOTIFICATION_FILTERS_MODAL_DEFAULT_VALUES } from 'constants/notifications';

import { IFormValues } from 'modules/Notifications/filter/form-shema';

const notificationAppliedFiltersAtom = atom<IFormValues>({
  key: 'notificationAppliedFiltersAtom',
  default: NOTIFICATION_FILTERS_MODAL_DEFAULT_VALUES,
});

export default notificationAppliedFiltersAtom;
