import server from 'utils/server';

import { TUserData } from './create-user';

/**
 * Changes user password for a given user ID
 *
 * @async
 * @param {string} userId - The ID of the user
 * @param {string} password - The new password to set
 * @returns {Promise<TUserData>} - A promise that resolves with the updated user data
 */
export const changeUserPasswordReq = async (
  userId: string,
  password: string,
): Promise<TUserData> => {
  const body = {
    password,
  };

  return server.services.beService
    .put<TUserData>(`system-users/${userId}/password`, body)
    .then((res) => res.data);
};
