import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    marginBottom: 15,
    padding: '15px 25px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: palette.custom.lightBlue,
    borderRadius: 10,
    cursor: 'pointer',

    '&:last-child': {
      marginBottom: 0,
    },
  },

  avatar: {
    width: 84,
    height: 84,
  },

  data: {
    flex: 1,
    margin: '0 20px',
  },

  companyName: {
    fontSize: 20,
    fontWeight: 800,
    color: palette.custom.blue,
  },

  userName: {
    marginTop: 8,
    fontSize: 18,
    fontWeight: 300,
    color: theme.palette.primary.dark,
  },

  enabled: {
    marginLeft: 20,
  },

  btnRoot: {
    width: '108px',
    height: '34px',
    border: '1px solid #4F5A76',
    borderRadius: '10px',
  },
}));

export default useStyles;
