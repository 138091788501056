import Button from '@mui/material/Button';
import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { TCompanyUser } from 'interfaces/user/company-user';

import RouteManager from 'utils/services/route-manager';

import Checkbox from 'components/form-v2/checkbox';
import NewAvatar from 'components/NewAvatar';

import useStyles from './styles';

interface IProps {
  data: TCompanyUser;
}

const UserSettingsItem: FC<IProps> = ({ data }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const handleOpenUserEditPage = useCallback(() => {
    navigate(RouteManager.makeURL('settings.users.edit', { id: data.id }));
  }, [data.id, navigate]);

  const user = `${data.firstName} ${data.lastName}`;

  return (
    <div
      className={classes.wrap}
      onClick={handleOpenUserEditPage}
      data-testid={`user-row-${data.id}`}
    >
      <div className={classes.avatar}>
        <NewAvatar logo={data.photoUrl} fullscreen />
      </div>
      <div className={classes.data}>
        <div className={classes.companyName} data-testid='companyName'>
          {data.companyName}
        </div>
        <div className={classes.userName} data-testid='userName'>
          {user}
        </div>
      </div>
      <Button classes={{ root: classes.btnRoot }} data-testid='role'>
        {data.role}
      </Button>
      <div className={classes.enabled}>
        <Checkbox value={data.enabled} testId='checkbox-enabled' />
      </div>
    </div>
  );
};

export default UserSettingsItem;
