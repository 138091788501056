import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { BUTTON_VARIANTS } from 'interfaces/common/button-variants';
import { ISettingPaymentCompany } from 'interfaces/company/setting-payment-company';
import { USER_ROLES } from 'interfaces/user/roles';

import { getRouteRegex } from 'utils/helpers/get-route-regex';
import RouteManager from 'utils/services/route-manager';

import settingPaymentSubCompaniesListSearchSelector from 'recoil/settings/payment/selected-company/search';
import {
  isOpenSettingPaymentTransactionsListFilterAtom,
  settingPaymentTransactionsListFilterCountSelector,
} from 'recoil/settings/payment/transactions/filters';
import settingPaymentTransactionsListSearchSelector from 'recoil/settings/payment/transactions/search';
import {
  isOpenSettingPaymentVehiclesListFilterAtom,
  settingPaymentVehiclesListFilterCountSelector,
} from 'recoil/settings/payment/vehicles/filters';
import settingPaymentVehiclesListSearchSelector from 'recoil/settings/payment/vehicles/search';
import userDataAtom from 'recoil/userData';

import { getSettingPaymentCompanyData } from 'requests/payment/company';

import Drawer from 'components/drawer';

import Header, { IToolBarButton } from '../../../components/header';
import CurrentCompany from '../company/current-company';

import Payment from './make-payment';

const CompanyWrapper = () => {
  const { t } = useTranslation('payment.page');
  const { id } = useParams() as { id: string };
  const userData = useRecoilValue(userDataAtom);
  const [isOpenPayment, setIsOpenPayment] = useState(false);
  const [companyData, setCompanyData] = useState<ISettingPaymentCompany | null>(null);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [subCompaniesSearch, setSubCompaniesSearch] = useRecoilState(
    settingPaymentSubCompaniesListSearchSelector,
  );
  const [vehiclesSearch, setVehiclesSearch] = useRecoilState(
    settingPaymentVehiclesListSearchSelector,
  );
  const [transactionsSearch, setTransactionsSearch] = useRecoilState(
    settingPaymentTransactionsListSearchSelector,
  );

  const setIsOpenTransactionsFilter = useSetRecoilState(
    isOpenSettingPaymentTransactionsListFilterAtom,
  );
  const setIsOpenVehiclesFilter = useSetRecoilState(isOpenSettingPaymentVehiclesListFilterAtom);

  const vehiclesAppliedFiltersCount = useRecoilValue(settingPaymentVehiclesListFilterCountSelector);
  const transactionsAppliedFiltersCount = useRecoilValue(
    settingPaymentTransactionsListFilterCountSelector,
  );

  const headerBtns: IToolBarButton[] = useMemo(() => {
    return [
      {
        label: t('subcompanies.label'),
        variant: BUTTON_VARIANTS.DEFAULT,
        disabled: getRouteRegex(
          RouteManager.path('settings.payment.company', {
            isFullPath: true,
            hasLeadingSlash: true,
          }),
        ).test(pathname),
        onClick: () => navigate(RouteManager.makeURL('settings.payment.company', { id })),
      },
      {
        label: t('transactions.label'),
        variant: BUTTON_VARIANTS.DEFAULT,
        disabled: getRouteRegex(
          RouteManager.path('settings.payment.company.transaction', {
            isFullPath: true,
            hasLeadingSlash: true,
          }),
        ).test(pathname),
        onClick: () =>
          navigate(RouteManager.makeURL('settings.payment.company.transaction', { id })),
      },
      {
        label: t('vehicle.spendings.label'),
        variant: BUTTON_VARIANTS.DEFAULT,
        disabled: getRouteRegex(
          RouteManager.path('settings.payment.company.vehicles', {
            isFullPath: true,
            hasLeadingSlash: true,
          }),
        ).test(pathname),
        onClick: () => navigate(RouteManager.makeURL('settings.payment.company.vehicles', { id })),
      },
      {
        label: t('make.payment.label'),
        variant: BUTTON_VARIANTS.DEFAULT,
        disabled: userData?.role !== USER_ROLES.TECH_ADMIN,
        onClick: () => setIsOpenPayment(true),
      },
    ];
  }, [id, navigate, pathname, t, userData?.role]);

  /**
   * Represents the header search variable.
   * @type {{
   *    searchHandler: (data: { query: string }) => void;
   *    onCancelSearch: () => void;
   *    value: string;
   *  } | undefined}
   * @description This variable is used to handle header search functionality in different scenarios.
   * If the pathname does not include 'transactions' or 'vehicles-spending', it returns an object with searchHandler, onCancelSearch, and value properties.
   * If the pathname includes 'vehicles-spending', it returns an object specifically for handling vehicles search.
   * If the pathname includes 'transactions', it returns undefined.
   */
  const headerSearch:
    | {
        searchHandler: (data: { query: string }) => void;
        onCancelSearch: () => void;
        value: string;
      }
    | undefined = useMemo(() => {
    if (pathname.includes('vehicles-spending/')) {
      return;
    }

    if (!pathname.includes('transactions') && !pathname.includes('vehicles-spending')) {
      return {
        searchHandler: (data) => setSubCompaniesSearch(data),
        onCancelSearch: () => setSubCompaniesSearch({ query: '' }),
        value: subCompaniesSearch.query,
      };
    }

    if (pathname.includes('vehicles-spending')) {
      return {
        searchHandler: (data) => setVehiclesSearch(data),
        onCancelSearch: () => setVehiclesSearch({ query: '' }),
        value: vehiclesSearch.query,
      };
    }

    if (pathname.includes('transactions')) {
      return {
        searchHandler: (data) => setTransactionsSearch(data),
        onCancelSearch: () => setTransactionsSearch({ query: '' }),
        value: transactionsSearch.query,
      };
    }
  }, [
    pathname,
    setSubCompaniesSearch,
    setTransactionsSearch,
    setVehiclesSearch,
    subCompaniesSearch.query,
    transactionsSearch.query,
    vehiclesSearch.query,
  ]);

  const headerFilters = useMemo(() => {
    if (
      getRouteRegex(
        RouteManager.path('settings.payment.company.transaction', {
          isFullPath: true,
          hasLeadingSlash: true,
        }),
      ).test(pathname)
    ) {
      return {
        onClickFilter: () => setIsOpenTransactionsFilter(true),
        appliedFiltersCount: transactionsAppliedFiltersCount,
      };
    }

    if (
      getRouteRegex(
        RouteManager.path('settings.payment.company.vehicles', {
          isFullPath: true,
          hasLeadingSlash: true,
        }),
      ).test(pathname)
    ) {
      return {
        onClickFilter: () => setIsOpenVehiclesFilter(true),
        appliedFiltersCount: vehiclesAppliedFiltersCount,
      };
    }
  }, [
    pathname,
    setIsOpenTransactionsFilter,
    setIsOpenVehiclesFilter,
    transactionsAppliedFiltersCount,
    vehiclesAppliedFiltersCount,
  ]);

  const getCompanyData = useCallback(async () => {
    try {
      const res = await getSettingPaymentCompanyData(id);
      setCompanyData(res);
    } catch (e) {
      console.log(e);
    }
  }, [id]);

  useEffect(() => {
    setCompanyData(null);
    getCompanyData();
  }, [getCompanyData]);

  if (![USER_ROLES.TECH_ADMIN, USER_ROLES.ADMIN, USER_ROLES.OWNER].includes(userData!.role)) {
    return <Navigate to={RouteManager.makeURL('scoring')} />;
  }

  return (
    <div>
      <Header
        backBtn={{
          label: '',
          onClick: () => navigate(RouteManager.makeURL('settings.payment')),
        }}
        searchHandler={headerSearch?.searchHandler}
        onCancelSearch={headerSearch?.onCancelSearch}
        searchValue={headerSearch?.value}
        onClickFilter={headerFilters?.onClickFilter}
        appliedFiltersCount={headerFilters?.appliedFiltersCount}
        toolBarBtns={headerBtns}
      />

      <CurrentCompany data={companyData} />

      <Outlet />

      <Drawer open={isOpenPayment} onClose={() => setIsOpenPayment(false)}>
        <Payment onClose={() => setIsOpenPayment(false)} getCompanyData={getCompanyData} />
      </Drawer>
    </div>
  );
};

export default CompanyWrapper;
