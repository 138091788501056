import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    position: 'relative',
    width: '100%',
  },

  search: {
    position: 'absolute',
    width: 15,
    height: 15,
    bottom: 12,
    left: 12,
    cursor: 'pointer',

    '& path': {
      fill: palette.custom.blue800,
    },
  },
}));

export default useStyles;
