import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { teamsGetReq } from 'requests/be-service/teams-controller/get-all-teams';

import Loader from 'components/Loader';

import Team from '../team';

import useStyles from './styles';

const TeamsList = ({ vehicleCheckboxHandler, watch }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [{ totalPages, currentPage }, setTeamsListPages] = useState({
    currentPage: 0,
    totalPages: 0,
  });
  const [teams, setTeams] = useState([]);

  /**
   * Get teams by filters
   */
  const getTeams = useCallback(
    async (page) => {
      try {
        setIsLoading(true);
        const res = await teamsGetReq(page);
        setTeamsListPages({ currentPage: res.number, totalPages: res.totalPages });
        setTeams((prev) => [...prev, ...res.content]);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setTeams, setTeamsListPages],
  );

  /**
   * Init teams request
   */
  useEffect(() => {
    setTeams([]);
    getTeams(0);
  }, [getTeams, setTeams]);

  return (
    <InfiniteScroll
      dataLength={teams.length}
      next={() => getTeams(currentPage + 1)}
      hasMore={currentPage < totalPages - 1}
      className={classes.list}
      loader={null}
      height={'calc(100vh - 100px)'}
    >
      {isLoading && <Loader isBlock lightMode preventClick width={100} />}

      {teams?.map((team) => {
        return (
          <Team
            key={team.teamId}
            team={team}
            vehicleCheckboxHandler={vehicleCheckboxHandler}
            watch={watch}
          />
        );
      })}
    </InfiniteScroll>
  );
};

TeamsList.propTypes = {};

TeamsList.defaultProps = {};

export default TeamsList;
