import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  startWrap: {
    paddingRight: 8,
    width: 'fit-content',
    height: 26,
    backgroundColor: palette.custom.white,
    borderRadius: 13,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  startIcon: {
    width: 26,
    height: 26,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: palette.custom.bluePrimary,
    borderRadius: '50%',
    marginRight: 5,
  },

  playIcon: {
    marginLeft: 2,
  },

  startLabel: {
    fontFamily: 'SFPro',
    fontWeight: 400,
    fontSize: 12,
    color: palette.custom.bluePrimary,
  },
}));

export default useStyles;
