import Modal from '@mui/material/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FCC } from 'interfaces/common/fc-with-children';
import { ENGINE_STATUS } from 'interfaces/vehicles/engine-status';

import useStyles from './styles';

interface IEngineStatusModal {
  isOpen: boolean;
  status: ENGINE_STATUS;
  onCancel: (e: React.MouseEvent<HTMLDivElement>) => void;
  onSubmit: () => void;
}

type Props = IEngineStatusModal;

const EngineStatusModal: FCC<Props> = ({ isOpen, status, onSubmit, onCancel }) => {
  const { t } = useTranslation('vehicle.list.page');
  const classes = useStyles();

  return (
    <Modal open={isOpen} className={classes.modal}>
      <div className={classes.engineModalWrap} onClick={onCancel}>
        <div className={classes.engineModal} data-testid='engine-modal'>
          <div className={classes.engineModalTitle}>
            {t('modal.title.first.label')}{' '}
            <span>
              {status === ENGINE_STATUS.ACTIVE
                ? t('modal.title.second.stop.label')
                : t('modal.title.second.start.label')}
            </span>{' '}
            {t('modal.title.third.label')}
          </div>
          <div className={classes.engineModalControls}>
            <div className={classes.engineModalBtn} onClick={onSubmit} data-testid='button-submit'>
              {t('yes.label')}
            </div>
            <div className={classes.engineModalBtn} onClick={onCancel} data-testid='button-cancel'>
              {t('no.label')}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EngineStatusModal;
