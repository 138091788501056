export const minutesToPrettyHours = (
  minutes: number,
): { hours: number | null; minutes: number | null } => {
  if (minutes === null || minutes === undefined) {
    return { hours: null, minutes: null };
  }
  minutes = Math.round(minutes);

  const hours = Math.floor(minutes / 60);
  const onlyMinutes = minutes - hours * 60;
  return {
    hours,
    minutes: onlyMinutes,
  };
};
