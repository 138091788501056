import server from 'utils/server';

interface IUserAndCityCount {
  cityCount: number;
  usersCount: number;
}

/**
 * Fetches the user and city counters from the server.
 *
 * @async
 * @function fetchCountersReq
 * @returns {Promise<IUserAndCityCount>} A promise that resolves to an object containing the user and city counters.
 */
export const fetchCountersReq = async (): Promise<IUserAndCityCount> =>
  server.services.beService.get<IUserAndCityCount>('home/').then((res) => res.data);
