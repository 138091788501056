import { format } from 'date-fns';
import { selectorFamily } from 'recoil';

import { IProps as IPathCardProps } from 'modules/Trip/conponents/header/path-card';
import { IProps as IStatisticProps } from 'modules/Trip/conponents/header/statistic';

import driverShortDataSelector from '../../driver/short-data';
import tripPointsSelector from '../points';

import pathCardSelector from './path-card';
import statisticSelector from './statistic';

interface IHeaderInfoSelector {
  statistic: IStatisticProps;
  pathCard: IPathCardProps;
  date: string;
  driver: {
    name: string;
    id: string;
  };
}

const headerInfoSelector = selectorFamily<IHeaderInfoSelector, string>({
  key: 'headerInfoSelector',
  get:
    (tripId) =>
    async ({ get }) => {
      const statistic = get(statisticSelector(tripId));
      const pathCard = get(pathCardSelector(tripId));
      const tripData = get(tripPointsSelector(tripId));
      const driverInfo = get(
        driverShortDataSelector({ driverId: tripData.driverId, filters: null }),
      );

      return {
        statistic,
        pathCard,
        date: format(new Date(tripData.trip.startGpsPoint.timestamp as string), 'dd.MM.yyyy'),
        driver: {
          name: `${driverInfo.firstName} ${driverInfo.lastName}`,
          id: driverInfo.driverId,
        },
      };
    },
});

export default headerInfoSelector;
