import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';

import { ISelectItemWithCompanyId } from 'interfaces/common/select-items';
import { USER_ROLES } from 'interfaces/user/roles';
import { IUserData } from 'interfaces/user/user-data';

import { ROLES_OBJECT } from 'constants/user';
import { VEHICLE_TYPES_SELECT } from 'constants/vehicle';

import settingVehiclesFilterAtom from 'recoil/settings/vehicles/filters';
import { initValues } from 'recoil/settings/vehicles/filters/atom';
import settingVehiclesShowFilterAtom from 'recoil/settings/vehicles/show-filter';
import subcompaniesSelectListAtom from 'recoil/subcompanies/select-list';
import userConfigAtom from 'recoil/user-config';
import userDataAtom from 'recoil/userData';

import { getCompanyUsersReq } from 'requests/be-service/company-controller/get-company-users';
import { getSystemUsersReq } from 'requests/be-service/system-user-controller/get-all-users';

import Checkbox from 'components/form-v2/checkbox-with-controller';
import DatePicker from 'components/form-v2/date-picker';
import Input from 'components/form-v2/input';
import Select from 'components/form-v2/select';
import ModalActions from 'components/modal-actions';

import {
  formSchema,
  TVehicleFiltersForm,
} from 'modules/drivers-vehicles/components/vehicles-filter/form-schema';

import useStyles from './styles';

const Filter = () => {
  const classes = useStyles();
  const { t } = useTranslation('fields');

  const setShowFilter = useSetRecoilState(settingVehiclesShowFilterAtom);
  const { contents: subcompaniesSelectList } = useRecoilValueLoadable(subcompaniesSelectListAtom);
  const [managers, setManagers] = useState<ISelectItemWithCompanyId[]>([]);
  const [managersListPage, setManagersListPage] = useState(0);
  const [managersListTotalPages, setManagersListTotalPages] = useState(0);
  const [filtersData, setFiltersData] = useRecoilState(settingVehiclesFilterAtom);
  const userData = useRecoilValue(userDataAtom) as IUserData;
  const { isMetricSystem } = useRecoilValue(userConfigAtom);

  const formMethods = useForm<TVehicleFiltersForm>({
    defaultValues: filtersData,
    resolver: yupResolver(formSchema()),
  });

  /**
   * Reset filters by user role
   * @type {(function(): void)|*}
   */
  const handleResetFilters = useCallback(() => {
    if (
      ![
        ROLES_OBJECT.TECH_ADMIN,
        ROLES_OBJECT.ADMIN,
        ROLES_OBJECT.OWNER,
        ROLES_OBJECT.OPERATOR,
      ].includes(userData.role)
    ) {
      formMethods.reset({
        ...initValues,
        companyIds: userData.companyId,
        userIds: userData.id,
      });
      return;
    }
    formMethods.reset(initValues);
  }, [formMethods, userData.companyId, userData.id, userData.role]);

  /**
   * Get managers for selected company
   */
  const getManagers = useCallback(async (companyId: string | null, page: number | null) => {
    try {
      let response;
      let content;

      if (companyId) {
        content = await getCompanyUsersReq(companyId);
      } else {
        const res = await getSystemUsersReq(page ?? 0, { enabled: true });
        response = res;
        content = res.content;
      }

      const formattedResponse = content?.map((item) => {
        return {
          value: item.id,
          companyId: item.companyId,
          label: `${item.firstName} ${item.lastName}`,
        };
      });

      setManagersListTotalPages(response?.totalPages ?? 0);
      setManagersListPage(response?.number ?? 0);
      if (!response?.number || response?.number === 0) {
        setManagers(formattedResponse);
      } else {
        setManagers((prevState) => [...prevState, ...formattedResponse]);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  /**
   * Getting a list of managers with data loading when scrolling
   */
  useEffect(() => {
    getManagers(formMethods.watch('companyIds') ?? null, managersListPage);
  }, [formMethods, getManagers, managersListPage]);

  const onSubmitForm = (data: TVehicleFiltersForm) => {
    setFiltersData((prevState) => ({
      ...prevState,
      ...data,
    }));
    setShowFilter(false);
  };

  return (
    <div className={classes.wrap} data-testid='vehicles-filters'>
      <FormProvider {...formMethods}>
        <Select
          items={Array.isArray(subcompaniesSelectList) ? subcompaniesSelectList : []}
          name={'companyIds'}
          title={t('company.label')}
          disabled={
            ![
              ROLES_OBJECT.TECH_ADMIN,
              ROLES_OBJECT.ADMIN,
              ROLES_OBJECT.OWNER,
              ROLES_OBJECT.OPERATOR,
            ].includes(userData.role)
          }
          additionalOnChange={(val) => {
            formMethods.setValue('userIds', null);
            getManagers(val.target.value, null);
          }}
        />
        <Select
          items={managers ?? []}
          name={'userIds'}
          title={t('manager.label')}
          disabled={
            ![
              ROLES_OBJECT.TECH_ADMIN,
              ROLES_OBJECT.ADMIN,
              ROLES_OBJECT.OWNER,
              ROLES_OBJECT.OPERATOR,
            ].includes(userData.role)
          }
          additionalOnChange={(val) => {
            const managerCompanyId = managers.find(
              (manager) => manager.value === val.target.value,
            )!.companyId;
            if (!formMethods.watch('companyIds')) {
              formMethods.setValue('companyIds', managerCompanyId);
              getManagers(managerCompanyId, null);
            }
          }}
          onScroll={(e) => {
            const target = e.currentTarget;
            const isBottom = target.scrollHeight - target.scrollTop - target.offsetHeight < 1;
            if (isBottom) {
              setManagersListPage((prevState) => {
                if (prevState < managersListTotalPages - 1) {
                  return prevState + 1;
                }
                return prevState;
              });
            }
          }}
        />

        <div className={classes.row}>
          <DatePicker name={'movementAtFrom'} title={t('movement.from.label')} />
          <DatePicker name={'movementAtTill'} title={t('movement.to.label')} />
        </div>

        <div className={classes.row}>
          <DatePicker
            name={'createdAtFrom'}
            title={`${t('creation.time.label')} (${t('from.filter.label')})`}
          />
          <DatePicker
            name={'createdAtTill'}
            title={`${t('creation.time.label')} (${t('to.filter.label')})`}
          />
        </div>

        <Select
          items={VEHICLE_TYPES_SELECT.map((item) => ({
            ...item,
            label: t(item.label),
          }))}
          name={'vehicleTypes'}
          title={t('type.label')}
          multiple
          renderValueType={'number'}
        />

        <div className={classes.row}>
          <Input
            title={`${t(`daily.${isMetricSystem ? 'km' : 'mile'}.label`)} (${t(
              'from.filter.label',
            )})`}
            name={'startDailyKm'}
            type={'number'}
          />
          <Input
            title={`${t(`daily.${isMetricSystem ? 'km' : 'mile'}.label`)} (${t(
              'to.filter.label',
            )})`}
            name={'finishDailyKm'}
            type={'number'}
          />
        </div>

        {userData.role === USER_ROLES.TECH_ADMIN && (
          <Checkbox name={'includeDisabled'} label={t('includeDisabled.label')} />
        )}

        <ModalActions
          handleClose={() => setShowFilter(false)}
          handleSave={formMethods.handleSubmit(onSubmitForm)}
          handleReset={handleResetFilters}
        />
      </FormProvider>
    </div>
  );
};

Filter.propTypes = {};

Filter.defaultProps = {};

export default Filter;
