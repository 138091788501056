import cx from 'classnames';
import React, { FC } from 'react';

import { COUNTRIES } from 'interfaces/common/countries';

import useStyles from './styles';

interface IPlateNumber {
  plateNumber: string;
  country: COUNTRIES;
  wrapClass?: string;
}

const PlateNumber: FC<IPlateNumber> = ({ plateNumber, country, wrapClass }) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.wrap, wrapClass)} data-testid='plateNumberWrap'>
      <div className={classes.country} data-testid='country'>
        {country}
      </div>
      <div
        className={classes.plateNumber}
        data-platenumber={'plateNumber'}
        data-testid='plateNumber'
      >
        {plateNumber}
      </div>
    </div>
  );
};

export default PlateNumber;
