import { useEffect } from 'react';
import { useBlocker } from 'react-router';

export function useCallbackPrompt(when: boolean) {
  if (when) {
    window.onbeforeunload = () => true;
  } else {
    window.onbeforeunload = null;
  }

  useEffect(() => {
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return when && currentLocation.pathname !== nextLocation.pathname;
  });

  return {
    showPrompt: blocker.state === 'blocked',
    confirmNavigation: blocker.proceed!,
    cancelNavigation: blocker.reset!,
  };
}
