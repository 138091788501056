import SliderMUI from '@mui/material/Slider';
import { StyledEngineProvider } from '@mui/material/styles';
import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import useStyles from './styles';

interface IProps {
  name: string;
  min: number;
  max: number;
  disabled?: boolean;
  step?: number;
  marks: { value: number; label: string | number }[];
}

const Slider: FC<IProps> = ({ name, min, max, marks, step = 1, disabled = false }) => {
  const { control } = useFormContext();
  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <StyledEngineProvider injectFirst>
            <div className={classes.wrap} data-testid={`slider-${name}`}>
              <SliderMUI
                {...field}
                max={max}
                min={min}
                marks={marks}
                step={step}
                valueLabelDisplay='on'
                classes={{
                  rail: classes.rail,
                  track: classes.track,
                  mark: classes.mark,
                  markLabel: classes.markLabel,
                  thumb: classes.thumb,
                  valueLabel: classes.valueLabel,
                  disabled: classes.disabled,
                }}
                disabled={disabled}
              />
            </div>
          </StyledEngineProvider>
        );
      }}
    />
  );
};

export default Slider;
