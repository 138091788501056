import queryString from 'query-string';

import { TNavigationSearchItem } from 'interfaces/navigation/searched-item';

import server from 'utils/server';

/**
 * Retrieves searched data based on search value.
 * @async
 * @param {string} searchValue - The search value.
 * @returns {Promise<TNavigationSearchItem[]>} - The searched data.
 */
export const getSearchedDataReq = async (searchValue: string): Promise<TNavigationSearchItem[]> => {
  const params = {
    query: searchValue,
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
  };

  return server.services.beService
    .get<TNavigationSearchItem[]>(`companies/search?${queryString.stringify(params)}`)
    .then((res) => res.data);
};
