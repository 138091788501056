import cx from 'classnames';
import React, { FC, MutableRefObject, useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ACTIVITY_STATUSES } from 'interfaces/common/activity-statuses';

import isOpenDashboardSidebarAtom from 'recoil/dashboard-map/sidebar/is-open';
import dashboardMapFiltersAtom from 'recoil/dashboard-map/toolbar/fitters';

import ActivityStatus from 'components/activity-statuses';

import Settings from './settings';
import useStyles from './styles';

interface IProps {
  abortRecursiveRequest?: () => void;
  controllerRef?: MutableRefObject<AbortController>;
}

const Toolbar: FC<IProps> = ({ abortRecursiveRequest, controllerRef }) => {
  const classes = useStyles();
  const [filters, setFilters] = useRecoilState(dashboardMapFiltersAtom);
  const isOpenDashboardSidebar = useRecoilValue(isOpenDashboardSidebarAtom);

  /**
   * Changing filters by activity type
   * @type {(function(*, *))|*}
   */
  const filtersBtnHandler = useCallback(
    (key: ACTIVITY_STATUSES, isActiveFilter: boolean) => {
      abortRecursiveRequest?.();

      if (controllerRef) {
        controllerRef.current = new AbortController();
      }

      if (!isActiveFilter) {
        setFilters((prevState) => ({
          ...prevState,
          status: [...prevState.status, key],
        }));
        return;
      }

      setFilters((prevState) => ({
        ...prevState,
        status: prevState.status.filter((item) => item !== key),
      }));
    },
    [abortRecursiveRequest, controllerRef, setFilters],
  );

  return (
    <div className={cx(classes.wrap, { isOpenDashboardSidebar })} data-testdid='toolbar-wrap'>
      <div className={classes.statuses}>
        {[...Object.values(ACTIVITY_STATUSES)].map((status) => {
          const isActiveFilter = filters.status.includes(status);

          return (
            <ActivityStatus
              key={status}
              value={status}
              className={cx(classes.status, { isActiveFilter })}
              variant={'outline'}
              onClick={() => filtersBtnHandler(status, isActiveFilter)}
            />
          );
        })}
      </div>

      <Settings />
    </div>
  );
};

export default Toolbar;
