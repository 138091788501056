import cx from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import { isEmptyValue } from 'utils/helpers/is-empty-value';

import crashModalAtom from 'recoil/crash-modal';
import userConfigAtom from 'recoil/user-config';

import Car from '../../components/car';

import useStyles from './styles';

const CrushInformation = ({ label, value }) => {
  const classes = useStyles();

  return (
    <div className={classes.crashItem}>
      <div className={classes.crashKey}>{label}</div>
      <div>{value}</div>
    </div>
  );
};

const Info = () => {
  const [{ crashData }] = useRecoilState(crashModalAtom);
  const { isMetricSystem } = useRecoilValue(userConfigAtom);
  const ratingSeverity = crashData.severity;
  const { t } = useTranslation(['crash.modal', 'measurement.system.page']);
  const classes = useStyles({ ratingSeverity });

  const crushDataLeft = useMemo(
    () => ({
      crashDuration: {
        label: 'info.crash.duration.label',
        path: 'crashCondition',
        postfix: ` ${t('measurement.system.page:second.short.label')}`,
      },
      numberOfImpacts: {
        label: 'info.number.of.impacts.label',
        path: 'crashCondition',
        postfix: null,
      },
      evasiveManeuver: {
        label: 'info.evasive.maneuver.label',
        path: 'crashCondition',
        postfix: null,
      },
      meanAcceleration: {
        label: 'info.mean.acceleration.label',
        path: 'crashCondition',
        postfix: ` ${t(
          `measurement.system.page:acceleration.short.${isMetricSystem ? 'km' : 'mile'}.label`,
        )}`,
      },
      beforeAccidentSpeed: {
        label: 'info.speed.before.accident.label',
        path: 'crashCondition',
        postfix: ` ${t(`measurement.system.page:${isMetricSystem ? 'km' : 'mile'}.per.h.label`)}`,
      },
      impactSpeed: {
        label: 'info.speed.at.impact.label',
        path: 'crashCondition',
        postfix: ` ${t(`measurement.system.page:${isMetricSystem ? 'km' : 'mile'}.per.h.label`)}`,
      },
      deltaV: {
        label: 'info.delta.v.label',
        path: 'crashCondition',
        postfix: ` ${t(`measurement.system.page:${isMetricSystem ? 'km' : 'mile'}.per.h.label`)}`,
      },
    }),
    [isMetricSystem, t],
  );
  const crushDataRight = useMemo(
    () => ({
      vehicleDrivenPostCrash: {
        label: 'info.vehicle.driver.post.crash.label',
        path: 'crashCondition',
        postfix: null,
      },
      temperature: {
        label: 'info.outside.temperature.label',
        path: 'weatherCondition',
        postfix: '°',
      },
      dayTime: { label: 'info.day.or.night.label', path: 'weatherCondition', postfix: null },
      cloudCoverage: { label: 'info.cloud.coverage.label', path: 'weatherCondition', postfix: '%' },
      conditions: {
        label: 'info.weather.conditions.label',
        path: 'weatherCondition',
        postfix: null,
      },
      afterImpactSpeed: {
        label: 'info.speed.after.impact.label',
        path: 'crashCondition',
        postfix: ` ${t(`measurement.system.page:${isMetricSystem ? 'km' : 'mile'}.per.h.label`)}`,
      },
    }),
    [isMetricSystem, t],
  );

  return (
    <div className={classes.reconstruction}>
      <div>
        <div className={classes.crashData}>
          <div className={classes.crashItems}>
            {Object.entries(crushDataLeft).map(([key, { label, path, postfix }]) => {
              const value = crashData?.[path]?.[key] ?? crashData?.[key];

              return (
                <CrushInformation
                  key={key}
                  label={t(label)}
                  value={isEmptyValue(value) ? '-' : `${value}${postfix ?? ''}`}
                />
              );
            })}
          </div>

          <div className={classes.crashItems}>
            {Object.entries(crushDataRight).map(([key, { label, path, postfix }]) => {
              let value = crashData?.[path]?.[key] ?? crashData?.[key];
              value = typeof value === 'boolean' ? (value ? t('yes.label') : t('no.label')) : value;

              return (
                <CrushInformation
                  key={key}
                  label={t(label)}
                  value={isEmptyValue(value) ? '-' : `${value}${postfix ?? ''}`}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className={classes.severity}>
        <div className={classes.block}>
          <div className={classes.severityRating}>
            <div className={classes.severityInfo}>{t('info.severity.label')}</div>
            <div className={classes.severityId}>{ratingSeverity}</div>
          </div>

          <div className={classes.rating}>
            <div className={classes.ratingBody}>
              {Array(10)
                .fill()
                .map((_, i) => (
                  <div key={i} className={cx(classes.ratingUp, classes[`item${i}`])} />
                ))}
            </div>
            <div className={classes.ratingActive}>
              {Array(10)
                .fill()
                .map((_, i) => (
                  <div key={i} className={cx(classes.ratingButton, classes[`item${i}`])} />
                ))}
            </div>
          </div>
        </div>

        <Car />
      </div>
    </div>
  );
};

Info.defaultProps = {};

Info.propTypes = {};

export default React.memo(Info);
