import Tooltip from '@mui/material/Tooltip';
import cx from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { ROLES_OBJECT } from 'constants/user';

import { ReactComponent as WarningIcon } from 'assets/images/warning.svg';

import RouteManager from 'utils/services/route-manager';

import crashModalAtom from 'recoil/crash-modal';
import { CRASH_MODAL_TABS } from 'recoil/crash-modal/atom';
import isMapOpenAtom from 'recoil/mainMap/isOpen';
import userDataAtom from 'recoil/userData';

import { getCrashDriverInfoReq } from 'requests/gps-service/crash-controller/get-crash-driver-details';

import DriverAvatar from 'components/DriverAvatar';
import VehicleInfo from 'components/VehicleInfo';

import Controls from '../components/controls';
import Path from '../components/path';

import useStyles from './styles';

const ModalMessage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation('crash.modal');

  const [{ driverData, error, currentCrashIdNumber, crashList }, setCrashModal] =
    useRecoilState(crashModalAtom);
  const userData = useRecoilValue(userDataAtom);
  const resetModal = useResetRecoilState(crashModalAtom);
  const [, setIsMainMapOpen] = useRecoilState(isMapOpenAtom);

  /**
   * Get crash driver data
   */
  useEffect(() => {
    if (driverData || error || !crashList?.length) {
      return;
    }

    const request = async () => {
      try {
        setCrashModal((prevState) => ({
          ...prevState,
          isFetching: true,
        }));

        const response = await getCrashDriverInfoReq(crashList[currentCrashIdNumber]);
        setCrashModal((prevState) => {
          return {
            ...prevState,
            driverData: response,
          };
        });
      } catch (e) {
        console.log(e);
        setCrashModal((prevState) => ({
          ...prevState,
          error: e.message,
        }));
      } finally {
        setCrashModal((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
      }
    };

    request();
  }, [crashList, currentCrashIdNumber, driverData, error, setCrashModal]);

  const handleDriverClick = useCallback(() => {
    setIsMainMapOpen(false);
    resetModal();
  }, [resetModal, setIsMainMapOpen]);

  const handleDriverNameClick = useCallback(() => {
    if (
      [
        ROLES_OBJECT.TECH_ADMIN,
        ROLES_OBJECT.ADMIN,
        ROLES_OBJECT.OWNER,
        ROLES_OBJECT.MANAGER,
      ].includes(userData.role) &&
      driverData?.driver?.id
    ) {
      setIsMainMapOpen(false);
      navigate(RouteManager.makeURL('drivers.edit', { id: driverData?.driver?.id }));
      resetModal();
    }
  }, [driverData?.driver?.id, navigate, resetModal, setIsMainMapOpen, userData.role]);

  const handleVehicleClick = useCallback(
    (id) => {
      if (
        [
          ROLES_OBJECT.TECH_ADMIN,
          ROLES_OBJECT.ADMIN,
          ROLES_OBJECT.OWNER,
          ROLES_OBJECT.MANAGER,
        ].includes(userData.role)
      ) {
        setIsMainMapOpen(false);
        resetModal();
        navigate(RouteManager.makeURL('vehicles.edit', { id }));
      }
    },
    [navigate, resetModal, setIsMainMapOpen, userData.role],
  );

  if (!driverData) {
    return (
      <div className={cx(classes.wrapper, 'fullHeight')}>
        <Controls showOnlyCloseIcon />
        <div className={classes.emptyMessage}>{t('no.data.message.label')}</div>
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <Controls />

      <div className={classes.warning}>
        <WarningIcon />
      </div>
      <div className={classes.ops}>{t('ops.label')}</div>
      <div className={classes.message}>
        {t('message.title.label')} <br /> {t('message.subtitle.label')}
      </div>

      <div className={classes.user}>
        <DriverAvatar
          infoIcon={false}
          isColorCircle={true}
          id={driverData.driver.id}
          isActive={driverData.driver.active}
          currentScoring={driverData.driver.scoreBeforeAccident}
          handleOpen={handleDriverClick}
          size={false}
        />
        <Tooltip title={`${driverData.driver.firstName} ${driverData.driver.lastName}`} arrow>
          <div className={classes.driverName} onClick={handleDriverNameClick}>
            {`${driverData.driver.firstName} ${driverData.driver.lastName}`}
          </div>
        </Tooltip>

        <div className={classes.vehicleWrap}>
          <VehicleInfo
            id={driverData.vehicle.id}
            isColorCircle={false}
            type={driverData.vehicle.vehicleType}
            brandName={driverData.vehicle.brand}
            plateNumber={driverData.vehicle.plateNumber}
            country={driverData.vehicle.country}
            handleOpen={() => handleVehicleClick(driverData.vehicle.id)}
          />
        </div>

        <div className={classes.pathWrap}>
          <Path isBig />
        </div>
      </div>

      <div
        className={classes.more}
        onClick={() =>
          setCrashModal((prevState) => ({
            ...prevState,
            activeTab: CRASH_MODAL_TABS.DESCRIPTION,
          }))
        }
      >
        {t('more.label')}
      </div>
    </div>
  );
};

ModalMessage.defaultProps = {};

ModalMessage.propTypes = {};

export default React.memo(ModalMessage);
