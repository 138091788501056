import i18next from 'i18next';

import { TDataWithId } from 'interfaces/common/data-with-id';
import { TCompany } from 'interfaces/company/company';
import { IUserAuth } from 'interfaces/user/user-auth';
import { IUserData } from 'interfaces/user/user-data';

import server from 'utils/server';

const checkLanguage = (lang: string) => {
  const userLang = lang.toLowerCase();
  localStorage.setItem('lang', userLang);

  if (i18next.language !== userLang) {
    i18next.changeLanguage(userLang);
  }
};

/**
 * Fetches user data from the server and company data based on the user's information.
 *
 * @async
 * @function userDataReq
 * @returns {Promise<IUserData>} - The user data, including additional company information.
 */
export const userDataReq = async (): Promise<IUserData> => {
  // TODO: need refactor after complete store
  const authToken = localStorage.getItem('authToken');

  return server.services.beService
    .get<IUserAuth>(`api/auth/${authToken}/user-data`, {
      headers: { Authorization: `Bearer ${authToken}` },
    })
    .then((user) => {
      return server.services.beService
        .get<TDataWithId<TCompany>>(`companies/users/${user.data.id}`, {
          headers: { Authorization: `Bearer ${authToken}` },
        })
        .then<IUserData>((company) => {
          localStorage.setItem('companyId', company.data.id);

          checkLanguage(user.data.language);
          return {
            ...user.data,
            companyName: company.data.name,
            companyLogo: company.data.iconUrl,
            companyId: company.data.id,
            localizationConfig: company.data.localizationConfig,
            price: company.data.vehicleMonthlyPrice,
          };
        });
    });
};
