import cx from 'classnames';
import React, { FC, useMemo } from 'react';
import Chart from 'react-apexcharts';
import CountUp from 'react-countup';

import { ReactComponent as TripIcon } from 'assets/images/route-vertical.svg';

import { getScoringColor } from 'utils/helpers/get-scoring-color';

import { IDriverTrip } from 'recoil/driver/driverTrip/atom';

import { createOptions, series } from './options';
import useStyles from './styles';

interface IProps {
  trip: IDriverTrip;
}

const PathCard: FC<IProps> = ({ trip }) => {
  const classes = useStyles();
  const { route, statistics } = trip;

  const countUp = useMemo(() => {
    const isBigNumber = statistics?.totalScore < -999 || statistics?.totalScore > 999;

    return {
      suffix: isBigNumber ? 'K' : '',
      countEnd: isBigNumber
        ? Math.abs(statistics?.totalScore) / 1000
        : Math.abs(statistics?.totalScore),
      decimals: isBigNumber ? 1 : 0,
    };
  }, [statistics?.totalScore]);

  const options = useMemo(() => {
    const color = getScoringColor(statistics?.totalScore);
    return createOptions(color, statistics?.totalScore);
  }, [statistics?.totalScore]);

  return (
    <div className={cx(classes.container)}>
      <div className={classes.trip}>
        <div className={classes.chartWrap} data-testid='chart'>
          <span
            className={cx(
              classes.counterLabel,
              statistics?.totalScore < -99900 && classes.counterMin,
            )}
            style={{ color: getScoringColor(statistics?.totalScore) }}
            data-testid='totalScore'
          >
            <CountUp
              start={0}
              end={countUp?.countEnd || 0}
              decimals={countUp?.decimals}
              suffix={countUp?.suffix}
            />
          </span>
          <Chart
            className={cx(classes.chart, statistics?.totalScore < 0 && classes.chartMin)}
            options={options}
            type='radialBar'
            series={[series(statistics?.totalScore)]}
            height={170}
          />
        </div>

        <TripIcon className={classes.tripIcon} />

        <div>
          <div className={classes.point}>
            <div
              className={classes.name}
              title={route?.start?.address ?? 'Not selected'}
              data-testid='startTrip'
            >
              {route?.start?.address ?? 'Not selected'}
            </div>
            <div className={classes.time} data-testid='startTripTime'>
              {route?.start?.date?.time ?? '-'}
            </div>
          </div>
          <div className={cx(classes.point, classes.endPoint)}>
            <div className={classes.name} title={route?.end?.address ?? '-'} data-testid='endTrip'>
              {route?.end?.address ?? 'Not selected'}
            </div>
            <div className={classes.time} data-testid='endTripTime'>
              {route?.end?.date?.time ?? '-'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PathCard;
