import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    color: palette.custom.blue100,
    fontSize: 18,
    fontWeight: 700,
  },

  btn: {
    color: theme.palette.primary.dark,
    fontSize: 18,
    fontWeight: '700',
    cursor: 'pointer',
  },
}));

export default useStyles;
