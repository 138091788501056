import cx from 'classnames';
import React, { FC, useMemo } from 'react';

import { COUNTRIES } from 'interfaces/common/countries';
import { VEHICLE_TYPES } from 'interfaces/vehicles/types';

import { ReactComponent as BusIcon } from 'assets/images/vehicles/bus-sm.svg';
import { ReactComponent as MotorcycleIcon } from 'assets/images/vehicles/motorcycle-sm.svg';
import { ReactComponent as SedanIcon } from 'assets/images/vehicles/sedan-sm.svg';
import { ReactComponent as TractorIcon } from 'assets/images/vehicles/tractor-sm.svg';
import { ReactComponent as TruckIcon } from 'assets/images/vehicles/truck-sm.svg';

import PlateNumber from 'components/plate-number';

import useStyles from './styles';

interface IProps {
  openSensorsHandler: () => void;
  wrapClass?: string;
  plateNumber: string;
  vehicleType?: VEHICLE_TYPES;
  country: COUNTRIES;
  model: string;
  brand: string;
  hasAlarm?: boolean;
  isShowSensors?: boolean;
}

const VehicleInfo: FC<IProps> = ({
  openSensorsHandler,
  wrapClass,
  vehicleType,
  plateNumber,
  country,
  brand,
  model,
  hasAlarm = false,
  isShowSensors = false,
}) => {
  const classes = useStyles();

  const Icon = useMemo(() => {
    switch (vehicleType) {
      case VEHICLE_TYPES.BUS:
        return BusIcon;
      case VEHICLE_TYPES.TRUCK:
        return TruckIcon;
      case VEHICLE_TYPES.SEDAN:
        return SedanIcon;
      case VEHICLE_TYPES.MOTORCYCLE:
        return MotorcycleIcon;
      case VEHICLE_TYPES.TRACTOR:
        return TractorIcon;
    }
  }, [vehicleType]);

  return (
    <div
      className={cx(classes.wrap, { isShowSensors, hasAlarm }, wrapClass)}
      onClick={openSensorsHandler}
      data-testid='vehicle-info'
    >
      {Icon && <Icon className={cx(classes.icon, { hasAlarm })} />}
      <PlateNumber wrapClass={classes.plateNumber} plateNumber={plateNumber} country={country} />
      <div className={classes.vehicle} data-testid='vehicleBrand'>{`${brand} ${model}`}</div>
    </div>
  );
};

export default VehicleInfo;
