import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ACTIVITY_STATUSES } from 'interfaces/common/activity-statuses';

import { isEmptyValue } from 'utils/helpers/is-empty-value';

import StatisticValue from 'components/statistic-value';
import Time from 'components/time';

import useStyles from './styles';

interface IProps {
  driveTime?: number;
  driveDistance?: number;
  idlingTime?: number;
  parkingTime?: number;
  status: ACTIVITY_STATUSES;
}

const Statistic: FC<IProps> = ({ driveDistance, driveTime, idlingTime, parkingTime, status }) => {
  const classes = useStyles();
  const { t } = useTranslation(['dashboard.map.page', 'measurement.system.page']);

  /**
   * Create data for rendering
   * @returns {Array.<{label: String, value: String}>}
   */
  const data = useMemo(
    () => [
      {
        label: 'drive.time.label',
        value: !isEmptyValue(driveTime) ? (
          <Time timestamp={Math.round(driveTime as number)} />
        ) : null,
        show: status === ACTIVITY_STATUSES.ACTIVE,
      },
      {
        label: 'drive.distance.label',
        value: !isEmptyValue(driveDistance) ? <StatisticValue value={driveDistance} /> : null,
        show: status === ACTIVITY_STATUSES.ACTIVE,
      },
      {
        label: 'park.time.label',
        value: !isEmptyValue(parkingTime) ? (
          <Time timestamp={Math.round(parkingTime as number)} />
        ) : null,
        show: status === ACTIVITY_STATUSES.INACTIVE,
      },
      {
        label: 'idling.time.label',
        value: !isEmptyValue(idlingTime) ? (
          <Time timestamp={Math.round(idlingTime as number)} />
        ) : null,
        show: [ACTIVITY_STATUSES.IDLING, ACTIVITY_STATUSES.ACTIVE].includes(status),
      },
    ],
    [driveDistance, driveTime, idlingTime, parkingTime, status],
  );

  return (
    <div className={classes.wrap} data-testdid='statistic-wrap'>
      {data.map(({ label, value, show }) => {
        if (show) {
          return (
            <div className={classes.item} key={label} data-testdid={`statistic-item-${label}`}>
              <div className={classes.label}>{`${t(label)}`}</div>
              <div className={classes.value}>{value}</div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default Statistic;
