import { TFunction } from 'react-i18next';

import RouteManager from 'utils/services/route-manager';

export const TABS = (t: TFunction) =>
  [
    {
      name: t('tab.drivers.label'),
      id: 1,
      isActive: true,
      path: RouteManager.path('drivers'),
    },
    {
      name: t('tab.vehicles.label'),
      id: 2,
      isActive: false,
      path: RouteManager.path('vehicles'),
    },
  ] as const;
