import { INotificationTab } from 'interfaces/notifications/notification-tab';

import { TABS_DATA } from 'constants/notifications';

import { IFormValues } from 'modules/Notifications/filter/form-shema';

/**
 * Returns the active tab based on the given form values.
 *
 * @param {IFormValues} data - The form values to determine the active tab.
 * @returns {INotificationTab} - The active tab object.
 */
export const calculateNotificationActiveTab = (data: IFormValues): INotificationTab => {
  const tabs = TABS_DATA();
  if (data.cartType.length === 0) {
    return tabs[0];
  }

  for (let i = 1; i < tabs.length; i++) {
    const filteredTypes = data.cartType.filter((type) => {
      return !tabs[i].cartType.includes(type);
    });

    if (filteredTypes.length === 0) {
      return tabs[i];
    }
  }

  return tabs[0];
};
