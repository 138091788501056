import { COUNTRIES } from 'interfaces/common/countries';

import server from 'utils/server';

import { TFormValues } from 'modules/Login/form-schema';

export interface IUserDomain {
  country: COUNTRIES.UK | COUNTRIES.AZ;
  name: COUNTRIES.UK | COUNTRIES.AZ;
  url: string;
}

export const getUserDomainsReq = async (body: TFormValues) => {
  return server.services.routerService
    .post<{
      envs: IUserDomain[];
    }>('signin/user', body)
    .then((res) => res.data);
};
