import * as yup from 'yup';

import { NOTIFICATION_TYPES } from 'interfaces/notifications/notification-types';

export interface IFormValues<TCartType = NOTIFICATION_TYPES[]> {
  startAt: Date;
  finishAt: Date;
  cartType: TCartType;
}

export const formSchema = yup.object().shape({
  startAt: yup.date(),
  finishAt: yup.date(),
  cartType: yup.array(),
});
