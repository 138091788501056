import React, { FC } from 'react';

import useStyles from './styles';

interface ICompanyItem {
  iconUrl?: string | null;
  name: string;
  onClick: () => void;
}

const CompanyItem: FC<ICompanyItem> = ({ iconUrl, name, onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrap} onClick={onClick} data-testid='company-item'>
      <div className={classes.line} />
      <div
        className={classes.icon}
        style={{ backgroundImage: `url(${iconUrl})` }}
        data-testid='company-item-icon'
      />
      <div className={classes.name} title={name} data-testid='company-item-name'>
        {name}
      </div>
    </div>
  );
};

export default CompanyItem;
