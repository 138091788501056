import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    maxWidth: 275,
    padding: '20px 35px',
    fontFamily: 'SFPro',
    borderRadius: 10,
    backgroundColor: palette.custom.bluePrimary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  title: {
    marginBottom: 30,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '19px',
    color: palette.custom.blue200,
  },

  btns: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  btn: {
    cursor: 'pointer',
  },

  approveBtn: {
    marginRight: 56,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '19px',
    color: palette.custom.blue300,
  },

  rejectBtn: {
    width: 97,
    height: 38,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '17px',
    color: palette.custom.bluePrimary,
    backgroundColor: palette.custom.goldSecondary,
  },
}));

export default useStyles;
