import queryString from 'query-string';

import { LANGUAGES } from 'interfaces/common/languages';
import { INotificationMessage } from 'interfaces/notifications/message';
import { IResponseWrapper } from 'interfaces/server/response-wrapper';

import { convertToUtcTimestamp } from 'utils/helpers/convert-to-utc-timestamp';
import server from 'utils/server';

import { IFormValues } from 'modules/Notifications/filter/form-shema';

export const getNotificationsReq = async (page: number, filters: IFormValues) => {
  const lang = localStorage.getItem('lang');

  const params = {
    direction: 'DESC',
    language: lang?.toUpperCase() ?? LANGUAGES.EN,
    page,
    size: 100,
    sort: 'createdAt',
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
  };

  const body = {
    finishAt: convertToUtcTimestamp(filters.finishAt),
    startAt: convertToUtcTimestamp(filters.startAt),
    cartType: filters.cartType,
  };

  return server.services.notificationService
    .post<IResponseWrapper<INotificationMessage[]>>(
      `notifications/?${queryString.stringify(params)}`,
      body,
    )
    .then((res) => res.data);
};
