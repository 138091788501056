import { TCompanyUser } from 'interfaces/user/company-user';

import server from 'utils/server';

/**
 * Returns a promise that resolves to an array of company users for a given company ID.
 *
 * @param {string} companyId - The ID of the company.
 * @returns {Promise<TCompanyUser[]>} - A promise that resolves to an array of company users.
 */
export const getCompanyUsersReq = async (companyId: string): Promise<TCompanyUser[]> => {
  return server.services.beService
    .get<TCompanyUser[]>(`companies/${companyId}/users`)
    .then((res) => res.data);
};
