import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    backgroundColor: palette.custom.bluePrimary,
  },
}));

export default useStyles;
