import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  wrap: {
    position: 'absolute',
    left: 18,
    bottom: 14,
    fontWeight: 300,
    fontSize: 20,
    opacity: 0.2,
    userSelect: 'none',
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
