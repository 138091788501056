import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  header: {
    paddingInline: 50,
  },

  data: {
    position: 'relative',
    paddingInline: 30,
    display: 'grid',
    columnGap: 25,
    gridTemplateColumns: '1fr 2fr',

    '&.showAvailableMembers': {
      gridTemplateColumns: '1fr 1fr 1fr',
    },

    '&::after': {
      pointerEvents: 'none',
      content: '""',
      position: 'absolute',
      top: 65,
      width: '100%',
      height: 6,
      opacity: 0.1,
      background: 'linear-gradient(180deg, #000 0%, rgba(35, 45, 70, 0.00) 100%)',
    },
  },
}));

export default useStyles;
