import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { ReactComponent as ArrowBack } from 'assets/images/arrow-back.svg';

import RouteManager from 'utils/services/route-manager';

import headerInfoSelector from 'recoil/trip/header-info';

import PathCard from './path-card';
import Statistics from './statistic';
import useStyles from './styles';

interface IProps {
  tripId: string;
}

const Header: FC<IProps> = ({ tripId }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathCard, statistic, date, driver } = useRecoilValue(headerInfoSelector(tripId));

  return (
    <>
      <div className={classes.info} data-testid='trip-header'>
        <div
          onClick={() => navigate(RouteManager.makeURL('drivers.trips', { id: driver.id }))}
          data-testid='button-back'
        >
          <ArrowBack />
        </div>
        <div className={classes.date} data-testid='date'>
          {date}
        </div>
        <div
          className={classes.linkDriver}
          onClick={() => navigate(RouteManager.makeURL('drivers.edit', { id: driver.id }))}
          data-testid='driverName'
        >
          {driver.name}
        </div>
      </div>
      <div className={classes.container}>
        <div className={classes.pathWrap}>
          <PathCard {...pathCard} />
        </div>

        <Statistics {...statistic} />
      </div>
    </>
  );
};

export default Header;
