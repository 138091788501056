import { atom } from 'recoil';

import { ITeamData } from 'interfaces/teams/i-team-data';

const selectedTeamAtom = atom<ITeamData | null>({
  key: 'selectedTeamAtom',
  default: null,
});

export default selectedTeamAtom;
