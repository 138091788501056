import { selector } from 'recoil';

import { sensorNumbers } from '../../constants/sensor';
import vehicleSensorsListAtom from '../vehicles/vehicleSensorsList';

const sensorAvailableNumbersAtom = selector({
  key: 'sensorAvailableNumbersAtom',
  get: ({ get }) => {
    const sensorsList = get(vehicleSensorsListAtom).map(({ sensorNumber }) => sensorNumber);
    return sensorNumbers.filter((item) => !sensorsList.includes(item.value));
  },
});

export default sensorAvailableNumbersAtom;
