import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  row: {
    transition: 'all 0.3s ease',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.15),

      '& $pencil': {
        opacity: 1,
        pointerEvents: 'all',
      },
    },
  },

  cell: {
    fontSize: 18,
    fontWeight: 400,
    color: palette.custom.blue200,

    '&.driver': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: 100,
    },
  },

  overflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 125,
  },

  infoWrap: {
    display: 'grid',
    columnGap: 20,
    gridTemplateColumns: '40px 1fr',
  },

  vehicleInfo: {
    gridColumnStart: 2,
    gridColumnEnd: 3,
    display: 'grid',
    gridTemplateColumns: '40px 1fr',
    alignItems: 'center',
    rowGap: 10,
    columnGap: 20,
  },

  plateNumber: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },

  vehicleModel: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.primary.dark,
    maxWidth: 320,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  problems: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 10,
    justifyContent: 'center',
    fontSize: 18,
    fontWeight: 400,
    color: palette.custom.blue200,

    '&:empty::after': {
      content: '"-"',
    },
  },

  pencil: {
    width: 20,
    cursor: 'pointer',
    pointerEvents: 'none',
    opacity: 0,
    transition: 'opacity 0.3s ease',

    '& *': {
      fill: theme.palette.primary.dark,
    },
  },
}));

export default useStyles;
