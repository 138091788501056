import * as yup from 'yup';

import { LICENSE_CATEGORIES } from 'interfaces/driver/license-categories';

export const defaultValues = {
  phonePrefix: '+994',
  sosPhonePrefix: '+994',
  hasGpsPoints: false,
  active: true,
};

export const formSchema = () => {
  return yup.object().shape({
    active: yup.boolean(),
    pin: yup
      .string()
      .required()
      .max(100)
      .matches(/^[a-z0-9]+$/i),
    firstName: yup.string().max(30).required(),
    lastName: yup.string().max(30).required(),
    middleName: yup.string().max(30).nullable(),
    idNumber: yup
      .string()
      .nullable()
      .max(12)
      .matches(/^[a-z0-9]+$/i, { excludeEmptyString: true }),
    driverLicense: yup
      .string()
      .nullable()
      .max(100)
      .matches(/^[a-z0-9]+$/i, { excludeEmptyString: true }),
    categories: yup.array().of(yup.string<LICENSE_CATEGORIES>()).nullable(),
    expireDate: yup.date().nullable(),
    phoneNumber: yup.string().max(100).nullable(),
    phonePrefix: yup.string().nullable(),
    sosPhoneNumber: yup.string().max(100).nullable(),
    sosPhonePrefix: yup.string().nullable(),
    email: yup
      .string()
      .max(100)
      .matches(/(^$|^.*@.*\..*$)/)
      .nullable(),
    address: yup.string().max(255).nullable(),
    driverVehicleDto: yup.object().shape({
      vehicleDto: yup.object().shape({
        id: yup.string().nullable(),
      }),
      startAt: yup.date().nullable(),
      finishAt: yup.date().nullable(),
    }),
    make: yup.number().nullable(),
    model: yup.number().nullable(),
    companyId: yup.string().required(),
    managedById: yup.string().required(),
    startWorkingTime: yup.date().nullable(),
    finishWorkingTime: yup.date().nullable(),
    basic: yup.number().max(999999999).nullable(),
    bonus: yup.number().max(999999999).nullable(),
    hasGpsPoints: yup.boolean(),
  });
};

export type TDriverCreateData = yup.InferType<ReturnType<typeof formSchema>>;
