import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  wrap: {
    isolation: 'isolate',
    marginTop: 60,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .mask': {
      opacity: 0,
    },

    '& .mask.front': {
      opacity: ({ carDamage }) => (carDamage.front * 0.7) / 100,
    },

    '& .mask.right': {
      opacity: ({ carDamage }) => (carDamage.right * 0.7) / 100,
    },

    '& .mask.back': {
      opacity: ({ carDamage }) => (carDamage.back * 0.7) / 100,
    },

    '& .mask.left': {
      opacity: ({ carDamage }) => (carDamage.left * 0.7) / 100,
    },
  },

  damageWrap: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },

  damageCount: {
    position: 'absolute',
    fontFamily: 'SFPro',
    fontWeight: 700,
    fontSize: 18,
    color: theme.palette.primary.light,

    '&.right': {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -35px)',
    },

    '&.back': {
      top: '50%',
      right: '50%',
      transform: 'translate(85px, -50%)',
    },

    '&.left': {
      bottom: '50%',
      left: '50%',
      transform: 'translate(-50%, 35px)',
    },

    '&.front': {
      top: '50%',
      left: '50%',
      transform: 'translate(-85px, -50%)',
    },
  },
}));

export default useStyles;
