import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

type Props = {
  size: boolean;
};

const useStyles = makeStyles<Theme, Props>(() => ({
  avatar: {
    position: 'relative',
    width: ({ size }) => `${size ? '72px' : '96px'}`,
    height: ({ size }) => `${size ? '72px' : '96px'}`,
    margin: '0 auto',
  },

  info: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '28px',
    height: '28px',
  },

  circleRight: {
    left: 70,
  },

  status: {
    position: 'absolute',
    bottom: '-8px',
    right: 0,
    left: 0,
    width: '16px',
    margin: '0 auto',
  },
}));

export default useStyles;
