import { Font, StyleSheet } from '@react-pdf/renderer';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import RobotoBold from 'assets/fonts/Roboto-Bold.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import RobotoRegular from 'assets/fonts/Roboto-Regular.ttf';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoRegular,
      fontWeight: 400,
      fontStyle: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 700,
      fontStyle: 'normal',
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '1cm 1.5cm',
    fontSize: 11,
    fontFamily: 'Roboto',
  },

  logo: {
    width: '8cm',
  },

  section: {
    width: '100%',
  },

  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },

  content: {
    marginTop: '2.5cm',
  },

  header: {
    fontWeight: 700,
  },

  tableHeadCell: {
    width: '1.5cm',
  },

  tableSection: {
    marginTop: '0.5cm',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },

  hesabLogo: {
    width: '1cm',
  },

  table: {
    display: 'flex',
    flexDirection: 'column',
  },

  tCell: {
    width: '5.25cm',
  },

  description: {
    marginTop: '2cm',
  },

  signaturesWrap: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '2cm',
  },

  member: {
    marginBottom: '1cm',
  },

  signature: {
    width: '5cm',
    marginTop: '0.5cm',
  },

  stamp: {
    width: '8cm',
  },

  crmLogo: {
    width: '1cm',
  },
});

export default styles;
