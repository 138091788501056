import format from 'date-fns/format';
import queryString from 'query-string';

import { TDateISODate } from 'interfaces/common/date-iso';
import { LANGUAGES } from 'interfaces/common/languages';

import { MONTHS } from 'constants/translates';

import server from 'utils/server';

interface IChartPoint {
  fuel: number;
  wearAndTear: number;
  maintenance: number;
}

interface IChartDataApiRes {
  [key: TDateISODate]: IChartPoint;
}

export interface IChartData {
  date: string[];
  total: (number | null)[];
}

/**
 * Retrieves the company ID from session storage or local storage.
 *
 * @returns {string | null} The company ID if found, or null if not found.
 */
const getCompanyId = (): string | null =>
  sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId');

/**
 * Returns the month and year in the specified language.
 *
 * @param {string} dateStr - The date string in a valid format (e.g. '2022-01-01').
 * @param {'en' | 'az'} userLang - The language to use for the month name.
 * @return {string} The month and year formatted as 'Month Year' (e.g. 'January 22').
 */
const getMonthYear = (dateStr: string, userLang: LANGUAGES): string => {
  const monthNumber = Number(format(new Date(dateStr), 'MM')) - 1;
  const yearNumber = format(new Date(dateStr), 'yy');
  const monthName = MONTHS.short[userLang][monthNumber];
  return `${monthName} ${yearNumber}`;
};

/**
 * Retrieves main chart data from the server.
 * @returns {Promise<IChartData>} A promise that resolves to the main chart data.
 */
export const getMainChartData = async (userLang: LANGUAGES): Promise<IChartData> => {
  const params = { companyId: getCompanyId() };

  return server.services.beService
    .get<IChartDataApiRes>('saving/graph?' + queryString.stringify(params))
    .then<IChartData>(({ data }) =>
      Object.entries(data).reduce(
        (acc, [dateStr, data], index) => {
          const res = { ...acc };
          if (index === 0) {
            res.date.push(' ');
            res.total.push(null);
          }

          res.date.push(getMonthYear(dateStr, userLang));
          res.total.push((data.fuel + data.maintenance + data.wearAndTear).toFixed(1));

          if (index === 11) {
            res.date.push(' ');
            res.total.push(null);
          }
          return res;
        },
        {
          date: [],
          total: [],
        } as IChartData,
      ),
    );
};
