import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    position: 'absolute',
    width: 625,
    top: 48,
    right: 0,
    backgroundColor: palette.custom.bluePrimary,
    fontFamily: 'SFPro',
    fontStyle: 'normal',
    paddingTop: 20,
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px 0px 20px 20px',
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingInline: 25,
  },

  title: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '24px',
    color: palette.custom.blue200,
  },

  readAll: {
    cursor: 'pointer',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '14px',
    color: palette.custom.blue700,
  },

  readAllIcon: {
    marginRight: 5,
  },

  toolbar: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    paddingInline: 25,
    marginTop: 30,
    columnGap: 20,
    borderBottom: `1px solid ${palette.custom.blue900}`,
  },

  tabs: {
    display: 'flex',
    width: 625,
    overflowX: 'auto',
  },

  tabItem: {
    cursor: 'pointer',
    marginRight: 30,
    paddingBottom: 10,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '19px',
    color: palette.custom.blue200,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '3px solid transparent',
    whiteSpace: 'nowrap',

    '&.isActive': {
      borderBottom: `3px solid ${palette.custom.goldSecondary}`,
      pointerEvents: 'none',
    },

    '&:last-child': {
      marginRight: 0,
    },
  },

  tabUnreadMessagesCount: {
    marginLeft: 5,
    backgroundColor: palette.custom.blue900,
    borderRadius: 3,
    paddingInline: 4,
  },

  notificationsBadge: {
    top: '-5px',
    right: '-5px',
    background: palette.custom.redSecondary,
    color: theme.palette.primary.light,
    transform: 'scale(0.85) translate(50%, -50%)',
  },

  filterBtn: {
    cursor: 'pointer',
    width: 25,
    height: 25,
    backgroundColor: palette.custom.blue900,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& path': {
      fill: palette.custom.blue500,
    },
  },

  filters: {
    padding: '20px 25px',
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 10,
    rowGap: 10,
    borderBottom: `1px solid ${palette.custom.blue900}`,

    '&:empty': {
      display: 'none',
    },
  },

  list: {
    position: 'relative',
    height: 450,
  },

  emptyMessagesWrap: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingInline: 25,
  },

  emptyTitle: {
    fontSize: 20,
    color: palette.custom.blue300,
    marginBottom: 10,
  },

  emptySubtitle: {
    fontSize: 14,
    color: palette.custom.blue600,
    marginBottom: 40,
  },

  emptyBtn: {
    cursor: 'pointer',
    backgroundColor: palette.custom.goldSecondary,
    borderRadius: 10,
    padding: '18px 55px',
    fontSize: 16,
    color: palette.custom.bluePrimary,
    border: 'none',
  },

  infiniteScroll: {
    paddingInline: 25,
  },

  loadingMoreMessage: {
    color: palette.custom.blue300,
    marginBottom: 5,
  },
}));

export default useStyles;
