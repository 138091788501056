import { DeepPartial } from 'react-hook-form';
import * as yup from 'yup';

import { LANGUAGES } from 'interfaces/common/languages';
import { MEASUREMENT_SYSTEM } from 'interfaces/common/measurement';

import { TUserCreateData } from 'requests/be-service/system-user-controller/create-user';

export const defaultValues: DeepPartial<TUserCreateData> = {
  enabled: false,
  language: LANGUAGES.AZ,
  measurement: MEASUREMENT_SYSTEM.METRIC,
};

export const formSchema = (id?: string) =>
  yup.object().shape({
    firstName: yup
      .string()
      .required()
      .matches(/[\w ]*/g)
      .max(64),
    lastName: yup
      .string()
      .required()
      .matches(/[\w ]*/g)
      .max(64),
    companyId: yup.string().required(),
    userName: yup.string().required().max(32),
    password: yup
      .string()
      .test('is-required', 'required.label', function (value) {
        if (!id) {
          return Boolean(value);
        }
        return true;
      })
      .max(32),
    enabled: yup.bool(),
    email: yup
      .string()
      .required()
      .matches(/\S+@\S+\.\S+/)
      .max(64),
    language: yup.string().required(),
    role: yup.string().required(),
    measurement: yup.string().required(),
  });
