import { valueConverter } from 'utils/helpers/value-converter';

import { IGetDriversItem } from 'requests/be-service/driver-controller/get-drivers';

export const driverListItemsDTO = (data: IGetDriversItem[], isMetricSystem: boolean) => {
  if (isMetricSystem) {
    return data;
  }
  return data.map((item) => {
    return {
      ...item,
      fuelUsed: item.fuelUsed && valueConverter.volume.toImperial(item.fuelUsed),
      dailyKm: item.dailyKm && valueConverter.distance.toImperial(item.dailyKm),
      totalKm: item.totalKm && valueConverter.distance.toImperial(item.totalKm),
    };
  });
};
