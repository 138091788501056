import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 10,
    padding: 15,
    cursor: 'pointer',
    marginBottom: 5,
  },

  info: {
    display: 'grid',
    gridTemplateColumns: '30px 1fr',
    gridTemplateAreas: `
    "status plateNumber"
    "vehicleIcon model"
    "driverName driverName"
    `,
    columnGap: 10,
    rowGap: 5,
    alignItems: 'center',

    '&.DRIVER': {
      gridTemplateAreas: `
      "status driverName"
      "vehicleIcon plateNumber"
      "model model"
      `,
    },
  },

  driverName: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.primary.dark,
    whiteSpace: 'nowrap',
    maxWidth: 150,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    gridArea: 'driverName',

    '&.isAccent': {
      fontSize: 18,
      fontWeight: 500,
      color: palette.custom.blue100,
    },
  },

  status: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gridArea: 'status',
  },

  vehicleIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gridArea: 'vehicleIcon',
  },

  plateNumber: {
    gridArea: 'plateNumber',
  },

  model: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.primary.dark,
    whiteSpace: 'nowrap',
    maxWidth: 170,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    gridArea: 'model',
  },

  scoring: {
    fontSize: 20,
    fontWeight: 500,
  },
}));

export default useStyles;
