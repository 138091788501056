import React from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

const FinishMarker = () => {
  const classes = useStyles();
  const { t } = useTranslation('dashboard.map.page');

  return (
    <div className={classes.marker} data-testid='finish-marker'>
      <div className={classes.label}>{t('finish.label')}</div>
      <div className={classes.icon}>
        <div />
      </div>
    </div>
  );
};

export default FinishMarker;
