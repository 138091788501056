import { selectorFamily } from 'recoil';

import tripPointsSelector from '../points';

type TTripTimelineData = {
  speed: number[];
  timestamp: string[];
  index: number[];
};

const tripTimelineSelector = selectorFamily<TTripTimelineData, string>({
  key: 'tripTimelineSelector',
  get:
    (tripId) =>
    ({ get }) => {
      const tripPoints = get(tripPointsSelector(tripId)).points;

      return tripPoints.reduce<TTripTimelineData>(
        (previousValue, currentValue, currentIndex, array) => {
          if (currentValue.timestamp === array[currentIndex - 1]?.timestamp) {
            return previousValue;
          }

          previousValue.speed.push(currentValue.speed);
          previousValue.timestamp.push(currentValue.timestamp as string);
          previousValue.index.push(currentIndex);
          return previousValue;
        },
        {
          speed: [],
          timestamp: [],
          index: [],
        },
      );
    },
});

export default tripTimelineSelector;
