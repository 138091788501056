import { atom } from 'recoil';

import { IUserData } from 'interfaces/user/user-data';

const userDataAtom = atom<IUserData | null>({
  key: 'userDataAtom',
  default: null,
});

export default userDataAtom;
