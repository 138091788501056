import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: '20px 25px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  backBtn: {
    fontSize: 28,
    fontWeight: 500,
    color: palette.custom.blue200,
    cursor: 'pointer',
    marginRight: 'auto',
  },

  backIcon: {
    marginRight: 20,
  },

  toolbarBtns: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 20,
    marginLeft: 40,

    '&:empty': {
      display: 'none',
    },
  },

  toolbarBtn: {
    width: 'fit-content',
  },

  btns: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 20,
    marginLeft: 20,
  },

  btn: {
    cursor: 'pointer',
    width: 45,
    height: 45,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all ease 0.3s',
    borderRadius: 10,

    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.1),
    },
  },

  search: {
    width: 385,
  },

  badge: {
    top: '-5px',
    right: '-5px',
  },
}));

export default useStyles;
