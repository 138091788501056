import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  dialogContent: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 'fit-content',
    flex: 'unset',
    outline: 'none',
    backgroundColor: palette.custom.bluePrimary,
    borderRadius: 10,
    padding: 20,
    rowGap: 10,
  },
}));

export default useStyles;
