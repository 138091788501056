import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '100%',
    maxWidth: '1440px',
    margin: '0 auto',
    opacity: 0.5,
    padding: '132px 122px 40px',

    '& > div': {
      fontSize: 40,
      maxWidth: 1200,
      lineHeight: '48px',
      color: theme.palette.primary.main,
      opacity: 0.5,
    },
  },

  success: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '50px',
  },

  title: {
    fontWeight: 600,
    fontSize: 41,
    color: theme.palette.primary.main,
    lineHeight: '28px',
  },

  iconSuccess: {
    margin: '15px 0',
  },

  subtitle: {
    textAlign: 'center',
    fontWeight: 300,
    fontSize: 41,
    color: theme.palette.primary.main,
    lineHeight: '48px',
  },

  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 auto',
    width: '100%',
    maxWidth: '1440px',
    paddingTop: '22px',
    paddingBottom: '66.7px',
    padding: '22px 111px 66.7px',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },

  inform: {
    display: 'flex',
    flexDirection: 'row',

    '& > p': {
      fontSize: 20,
      lineHeight: '24px',
      color: theme.palette.primary.main,
      opacity: 0.5,
      cursor: 'pointer',
      marginRight: '29px',

      '&:hover': {
        opacity: 1,
      },

      '&:nth-child(4)': {
        fontWeight: 700,
        marginRight: 0,
      },
    },
  },

  logoIcon: {
    marginRight: '65px',

    [theme.breakpoints.down('md')]: {
      margin: '40px 0 20px',
    },
  },

  socialNetworks: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: '34px',
    marginRight: '16px',

    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },

  social: {
    marginLeft: '55px',
    cursor: 'pointer',
    opacity: 0.5,

    '&:hover': {
      opacity: 1,
    },

    '&:nth-child(1)': {
      marginLeft: 0,
    },
  },
}));

export default useStyles;
