import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import useTableSort from 'hooks/useTableSort';

import { BUTTON_VARIANTS } from 'interfaces/common/button-variants';
import { ISortParameters } from 'interfaces/common/sort';
import { IUserData } from 'interfaces/user/user-data';

import { ROLES_OBJECT } from 'constants/user';

import { getObjectFillValuesCount } from 'utils/helpers/get-object-fill-values-count';
import RouteManager from 'utils/services/route-manager';

import settingDriversShowDisableModalAtom from 'recoil/settings/drivers/disable/show-modal';
import settingsDriverFilterAtom from 'recoil/settings/drivers/filters';
import { ISettingDriversFilters } from 'recoil/settings/drivers/filters/atom';
import settingDriversListAtom from 'recoil/settings/drivers/list';
import settingDriversShowFilterAtom from 'recoil/settings/drivers/show-filter';
import settingDriversShowQuickEditAtom from 'recoil/settings/drivers/show-quick-edit';
import settingsDriversSortDataAtom from 'recoil/settings/drivers/sort';
import { SORT_DIRECTION } from 'recoil/settings/payment/transactions/sort';
import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';
import userDataAtom from 'recoil/userData';

import {
  ISettingDriver,
  settingDriversReq,
} from 'requests/be-service/driver-controller/get-settings-drivers';

import Drawer from 'components/drawer';
import Loader from 'components/Loader';
import Modal from 'components/modal';
import PaginationScroll from 'components/pagination-scroll';
import TableSortLabel from 'components/TableSortLabel';

import Header from '../../components/header';

import DisableModal from './comonents/disable-modal';
import DriverRow from './comonents/driver-row';
import Filter from './comonents/filter';
import QuickEdit from './comonents/quick-edit';
import { TABLE_HEAD_COLUMNS } from './data';
import useStyles from './styles';

const SettingsDrivers = () => {
  const technicalMessageBlockHeight = useRecoilValue(technicalMessageBlockHeightSelector);
  const classes = useStyles({ technicalMessageBlockHeight });
  const { t } = useTranslation(['setting.drivers.page', 'setting.page']);
  const navigate = useNavigate();
  const { role } = useRecoilValue(userDataAtom) as IUserData;

  const [isLoading, setIsLoading] = useState(false);
  const [filtersData, setFiltersData] = useRecoilState(settingsDriverFilterAtom);
  const [showFilter, setShowFilter] = useRecoilState(settingDriversShowFilterAtom);
  const [showDisableModal, setShowDisableModal] = useRecoilState(
    settingDriversShowDisableModalAtom,
  );
  const [, setDriversSort] = useTableSort({ sort: 'createdAt', direction: SORT_DIRECTION.DESC });
  const [sortData, setSortData] = useRecoilState(settingsDriversSortDataAtom);
  const [drivers, setDrivers] = useRecoilState(settingDriversListAtom);
  const [quickEditDriver, setQuickEditDriver] = useState<ISettingDriver | null>(null);
  const [showQuickEditDriver, setShowQuickEditDriver] = useRecoilState(
    settingDriversShowQuickEditAtom,
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [totalDrivers, setTotalDrivers] = useState(0);

  const searchHandler = useCallback(
    (data: { query: string }) =>
      setFiltersData((prevState) => ({
        ...prevState,
        ...data,
      })),
    [setFiltersData],
  );

  /**
   * Set new sort for table
   * @type {(function(*): void)|*}
   */
  const handleTableSort = useCallback(
    (value: string) => {
      const newSort = setDriversSort(value);
      setSortData(newSort);
    },
    [setDriversSort, setSortData],
  );

  const driverEditHandler = useCallback(
    (driver: ISettingDriver) => {
      setQuickEditDriver(driver);
      setShowQuickEditDriver(true);
    },
    [setShowQuickEditDriver],
  );

  /**
   * Get drivers from API
   * @type {(function(*, *): Promise<void>)|*}
   */
  const getDrivers = useCallback(
    async (sort: ISortParameters, page: number, filters: ISettingDriversFilters) => {
      try {
        setIsLoading(true);
        const res = await settingDriversReq(sort, page, filters);
        setCurrentPage(res.pageable.pageNumber);
        setTotalDrivers(res.totalElements);
        setDrivers((prevState) => [...prevState, ...res.content]);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [setDrivers],
  );

  /**
   * Init drivers request
   */
  useEffect(() => {
    setDrivers([]);
    setCurrentPage(0);
    getDrivers(sortData, 0, filtersData);
  }, [filtersData, getDrivers, setDrivers, sortData]);

  /**
   * Load additional drivers
   */
  const loadMoreHandler = useCallback(
    () => getDrivers(sortData, currentPage + 1, filtersData),
    [currentPage, filtersData, getDrivers, sortData],
  );

  const onCloseDisableModal = useCallback(() => setShowDisableModal(false), [setShowDisableModal]);

  if (
    ![
      ROLES_OBJECT.TECH_ADMIN,
      ROLES_OBJECT.ADMIN,
      ROLES_OBJECT.OWNER,
      ROLES_OBJECT.MANAGER,
    ].includes(role)
  ) {
    return <Navigate to={RouteManager.makeURL('scoring')} />;
  }

  return (
    <div className={classes.wrap}>
      {isLoading && <Loader lightMode width={250} isBlock preventClick />}

      <Header
        searchHandler={searchHandler}
        toolBarBtns={[
          {
            label: 'Add driver +',
            onClick: () => navigate(RouteManager.makeURL('drivers.create')),
            variant: BUTTON_VARIANTS.DEFAULT,
          },
        ]}
        backBtn={{ label: t('setting.page:drivers.label') }}
        onClickFilter={() => setShowFilter(true)}
        onCancelSearch={() => {
          setFiltersData((prevState) => ({
            ...prevState,
            query: '',
          }));
        }}
        searchValue={filtersData.query}
        appliedFiltersCount={getObjectFillValuesCount(filtersData)}
      />

      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader data-testid='drivers-table'>
          <TableHead>
            <TableRow>
              {TABLE_HEAD_COLUMNS(t).map(({ id, label, styles, align, isSort }) => (
                <TableCell
                  classes={{ head: classes.headCell }}
                  key={id}
                  style={{ ...styles }}
                  align={align}
                >
                  <TableSortLabel
                    name={id}
                    onClick={handleTableSort}
                    sortField={sortData.sort}
                    direction={sortData.direction}
                    disabled={!isSort}
                  >
                    {label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell align={'center'} classes={{ head: classes.editHeadCell }}>
                |||
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tbody} data-testid='drivers-list'>
            {drivers.map((item) => (
              <DriverRow key={item.driverId} data={item} editHandler={driverEditHandler} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationScroll
        count={drivers.length}
        maxCount={totalDrivers}
        loadMoreHandler={loadMoreHandler}
      />

      <Modal onClose={() => setShowFilter(false)} open={showFilter}>
        <Filter />
      </Modal>

      <Modal onClose={onCloseDisableModal} open={showDisableModal}>
        <DisableModal />
      </Modal>

      <Drawer open={showQuickEditDriver} onClose={() => setShowQuickEditDriver(false)}>
        {quickEditDriver && (
          <QuickEdit driver={quickEditDriver} onClose={() => setShowQuickEditDriver(false)} />
        )}
      </Drawer>
    </div>
  );
};

export default SettingsDrivers;
