import { atom } from 'recoil';

const introductionPageAtom = atom({
  key: 'introductionPageAtom',
  default: {
    lang: localStorage.getItem('lang') || 'en',
  },
});

export default introductionPageAtom;
