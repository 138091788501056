import queryString from 'query-string';

import { hundredRangeFormat } from 'utils/helpers/hundred-range-format';
import server from 'utils/server';

export interface ITotalScoring {
  currentScoring: number;
  diffScoring: number;
  better: boolean;
  competitorScoring: number;
}

/**
 * Retrieves the total scoring chart request
 *
 * @async
 * @function scoringChartReq
 * @returns {Promise<ITotalScoring>} - A promise that resolves to an object containing the total scoring information.
 */
export const scoringChartReq = async (): Promise<ITotalScoring> => {
  const params = {
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
  };

  return server.services.beService
    .get<ITotalScoring>('scoring/total/average?' + queryString.stringify(params))
    .then<ITotalScoring>(({ data }) => {
      return {
        ...data,
        currentScoring: hundredRangeFormat(Math.round(data.currentScoring)),
        diffScoring: hundredRangeFormat(Math.round(data.diffScoring)),
      };
    });
};
