import React from 'react';

import NoInfoToShow from '../NoInfoToShow';

import useStyles from './styles';

const AccidentPredictionData = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrap}>
      <NoInfoToShow />
    </div>
  );
};

export default AccidentPredictionData;
