import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import React, { FC } from 'react';

import { IInvoiceData } from 'interfaces/payment/invoice';

import hesabLogo from 'assets/images/payment/hesab-faktura.png';
import logo from 'assets/images/payment/logo.png';
import signatureHasan from 'assets/images/payment/signature-hasan.png';
import stamp from 'assets/images/payment/stamp.png';

import styles from './styles';

interface IProps {
  data: IInvoiceData;
}

const InvoicePdf: FC<IProps> = ({ data }) => {
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.section}>
          <Image src={logo} style={styles.logo} />
        </View>

        <View style={[styles.section, styles.content]}>
          <View style={styles.row}>
            <Text style={[styles.header, styles.tableHeadCell]}>Tarix</Text>
            <Text>{data.invoiceDate}</Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.header, styles.tableHeadCell]}>№</Text>
            <Text>{data.invoiceNumber}</Text>
          </View>
        </View>

        <View style={[styles.section, styles.tableSection]}>
          <View style={styles.table}>
            <View style={styles.row}>
              <Text style={[styles.tCell, styles.header]}>Benefisiarın adı</Text>
              <Text style={styles.header}>{data.beneficiaryName}</Text>
            </View>

            <View style={styles.row}>
              <Text style={styles.tCell}>VÖEN</Text>
              <Text>{data.beneficiaryTin}</Text>
            </View>

            <View style={styles.row}>
              <Text style={styles.tCell}>Hesab Nömrəsi</Text>
              <Text>{data.accountNumber}</Text>
            </View>

            <View style={styles.row}>
              <Text style={styles.tCell}>Benefisiarın Bankı</Text>
              <Text>{data.bank}</Text>
            </View>

            <View style={styles.row}>
              <Text style={styles.tCell}>Valyuta</Text>
              <Text>{data.currency}</Text>
            </View>

            <View style={styles.row}>
              <Text style={styles.tCell}>Bankın Kodu</Text>
              <Text>{data.bankCode}</Text>
            </View>

            <View style={styles.row}>
              <Text style={styles.tCell}>Bankın VÖEN-i</Text>
              <Text>{data.bankTin}</Text>
            </View>

            <View style={styles.row}>
              <Text style={styles.tCell}>Bankın SWIFT Kodu</Text>
              <Text>{data.bankSWIFT}</Text>
            </View>

            <View style={styles.row}>
              <Text style={styles.tCell}>Müxbir Hesab</Text>
              <Text>{data.correspondentAccount}</Text>
            </View>

            <View style={[styles.row, { marginTop: '0.5cm' }]}>
              <Text style={[styles.tCell, styles.header]}>Ödəyən Tərəfin Adı</Text>
              <Text style={styles.header}>{data.payerName}</Text>
            </View>

            <View style={styles.row}>
              <Text style={styles.tCell}>VÖEN</Text>
              <Text>{data.payerTin}</Text>
            </View>

            <View style={styles.row}>
              <Text style={styles.tCell}>Müqavilənin Nömrəsi</Text>
              <Text>{data.contractNumber}</Text>
            </View>

            <View style={styles.row}>
              <Text style={styles.tCell}>Müqavilənin Tarixi</Text>
              <Text>{data.contractDate}</Text>
            </View>

            {data.invoicePriceCount.map(({ count, price, servicePeriod }) => {
              return (
                <>
                  <View style={styles.row}>
                    <Text style={styles.tCell}>Xidmət Dövrü</Text>
                    <Text>{servicePeriod}</Text>
                  </View>

                  <View style={styles.row}>
                    <Text style={styles.tCell}>Abunə sayı</Text>
                    <Text>{count}</Text>
                  </View>

                  <View style={styles.row}>
                    <Text style={styles.tCell}>Aylıq Xidmət Haqqı</Text>
                    <Text>{price}</Text>
                  </View>
                </>
              );
            })}

            <View style={styles.row}>
              <Text style={styles.tCell}>Cəmi</Text>
              <Text>{data.priceSum}</Text>
            </View>

            <View style={styles.row}>
              <Text style={styles.tCell}></Text>
              <Text>{data.paymentDue}</Text>
            </View>
          </View>
          <View>
            <Image style={styles.hesabLogo} src={hesabLogo}></Image>
          </View>
        </View>

        {data.invoicePriceCount.length < 4 && (
          <>
            <Text style={styles.description}>
              Xahiş edirik ödənişi yuxarıda göstərilən bank məlumatlarına və ödəniş şərtlərinə
              əsasən həyata keçirəsiniz.
            </Text>

            <View style={styles.signaturesWrap}>
              <View>
                <View style={styles.member}>
                  <Text>Həsən Nəzərov</Text>
                  <Text>Direktor</Text>
                  <Image src={signatureHasan} style={styles.signature}></Image>
                </View>
              </View>
              <View>
                <Image src={stamp} style={styles.stamp} />
              </View>
            </View>
          </>
        )}
      </Page>

      {data.invoicePriceCount.length > 3 && (
        <Page size='A4' style={styles.page}>
          <Text style={styles.description}>
            Xahiş edirik ödənişi yuxarıda göstərilən bank məlumatlarına və ödəniş şərtlərinə əsasən
            həyata keçirəsiniz.
          </Text>

          <View style={styles.signaturesWrap}>
            <View>
              <View style={styles.member}>
                <Text>Həsən Nəzərov</Text>
                <Text>Direktor</Text>
                <Image src={signatureHasan} style={styles.signature}></Image>
              </View>
            </View>
            <View>
              <Image src={stamp} style={styles.stamp} />
            </View>
          </View>
        </Page>
      )}
    </Document>
  );
};

export default InvoicePdf;
