import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    maxWidth: 550,
    padding: '30px 25px',
    position: 'absolute',
    top: '50%',
    left: 'calc(50% + 54px)', // 54 - half of width navbar
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.primary.dark,
  },

  modalDescription: {
    color: theme.palette.primary.main,
    fontSize: 18,
    marginBottom: 25,
  },

  closeModal: {
    marginRight: 15,
  },
}));

export default useStyles;
