import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  block: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  title: {
    fontFamily: 'SFText',
    fontWeight: 700,
    fontSize: 41,
    lineHeight: '48,11px',
    letterSpacing: '-2px',
    marginTop: '27px',
    color: theme.palette.primary.main,
  },

  text: {
    fontFamily: 'SFText',
    fontSize: 18,
    lineHeight: '21,12px',
    letterSpacing: '-2px',
    marginTop: '6px',
    color: theme.palette.primary.dark,
  },
}));

export default useStyles;
