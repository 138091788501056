import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BUTTON_VARIANTS } from 'interfaces/common/button-variants';
import { TTeamFormData } from 'interfaces/teams/team-form-data';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';

import Button from 'components/form-v2/button';
import Input from 'components/form-v2/input';

import { formSchema } from './form-schema';
import useStyles from './styles';

interface IProps {
  onClose: () => void;
  onSubmit: (data: TTeamFormData) => void;
}

const CreateTeam: FC<IProps> = ({ onClose, onSubmit }) => {
  const classes = useStyles();
  const { t } = useTranslation(['teams.page', 'fields']);

  const formMethods = useForm<TTeamFormData>({
    resolver: yupResolver(formSchema()),
  });

  return (
    <div className={classes.wrap} data-testid='create-team-wrap'>
      <div className={classes.header}>
        <div>{t('add.team.modal.label')}</div>

        <CloseIcon className={classes.close} onClick={onClose} data-testid='button-close' />
      </div>

      <div className={classes.content}>
        <FormProvider {...formMethods}>
          <div className={classes.section}>
            <Input name={'name'} title={t('team.name.label')} />
          </div>

          <Button
            variant={BUTTON_VARIANTS.FILL}
            onClick={formMethods.handleSubmit(onSubmit)}
            className={classes.btn}
            testId='button-save'
          >
            {t('fields:save.label')}
          </Button>
        </FormProvider>
      </div>
    </div>
  );
};

export default CreateTeam;
