import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { isAxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';

import { BUTTON_VARIANTS } from 'interfaces/common/button-variants';
import { ISelectItem, ISelectItemWithCompanyVehicePrice } from 'interfaces/common/select-items';
import { USER_ROLES } from 'interfaces/user/roles';
import { IUserData } from 'interfaces/user/user-data';
import { TVehicleEditData } from 'interfaces/vehicles/vehicle-edit-data';

import { ROLES_OBJECT } from 'constants/user';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/delete.svg';
import { ReactComponent as SettingIcon } from 'assets/images/gear.svg';
import { ReactComponent as PowerIcon } from 'assets/images/power.svg';

import RouteManager from 'utils/services/route-manager';

import settingVehiclesShowDisableModalAtom from 'recoil/settings/vehicles/disable/show-modal';
import settingQuickEditVehicleInfoAtom from 'recoil/settings/vehicles/disable/vehicle-info';
import subcompaniesSelectListAtom from 'recoil/subcompanies/select-list';
import userConfigAtom from 'recoil/user-config';
import userDataAtom from 'recoil/userData';

import { vehicleBrandsReq } from 'requests/be-service/brand-model-controller/find-brands';
import { vehicleModelsReq } from 'requests/be-service/brand-model-controller/find-models-by-brand';
import { getCompanyUsersReq } from 'requests/be-service/company-controller/get-company-users';
import { getAllActiveDriversReq } from 'requests/be-service/driver-controller/get-all-active-drivers';
import { vehicleGetReq } from 'requests/be-service/vehicle-controller/get-vehicle-dto';
import { IVehiclesListItem } from 'requests/be-service/vehicle-controller/get-vehicles';
import { vehicleEditReq } from 'requests/be-service/vehicle-controller/update-vehicle-v2';

import Button from 'components/form-v2/button';
import Input from 'components/form-v2/input';
import Select from 'components/form-v2/select';
import Loader from 'components/Loader';
import PlateNumber from 'components/plate-number';
import VehicleIcon from 'components/vehicle-icon';

import { defaultValues, formSchema } from 'modules/VehicleForm/form-schema';

import useStyles from './styles';

interface IProps {
  vehicle: IVehiclesListItem;
  onClose: () => void;
}

const QuickEdit: FC<IProps> = ({ vehicle, onClose }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation([
    'vehicle.details.page',
    'fields',
    'measurement.system.page',
    'sensor.service',
  ]);
  const { enqueueSnackbar } = useSnackbar();

  const userData = useRecoilValue(userDataAtom) as IUserData;
  const [isLoading, setIsLoading] = useState(false);
  const [brands, setBrands] = useState<ISelectItem[] | null>(null);
  const [models, setModels] = useState<ISelectItem[] | null>(null);
  const { isMetricSystem } = useRecoilValue(userConfigAtom);
  const [vehicleInfo, setVehicleInfo] = useRecoilState(settingQuickEditVehicleInfoAtom);
  const setShowDisableModal = useSetRecoilState(settingVehiclesShowDisableModalAtom);
  const { contents: subcompaniesSelectList } = useRecoilValueLoadable(subcompaniesSelectListAtom);
  const [managers, setManagers] = useState<ISelectItem[] | null>(null);
  const [drivers, setDrivers] = useState<ISelectItem[] | null>(null);

  const formMethods = useForm<TVehicleEditData>({
    resolver: yupResolver(formSchema(isMetricSystem)),
  });

  const isSubcompanyView = Boolean(sessionStorage.getItem('subcompanyId'));
  const isActiveVehicle = formMethods.watch('generalInfo.active');

  const editHandler = useCallback(() => {
    onClose();
    navigate(RouteManager.makeURL('vehicles.edit', { id: vehicle.id }));
  }, [navigate, onClose, vehicle.id]);

  /**
   * Get models for selected brand
   */
  const getModels = useCallback(async (brandId: number) => {
    try {
      const response = await vehicleModelsReq(brandId);

      setModels(response);
    } catch (e) {
      console.log(e);
    }
  }, []);

  /**
   * Show error message for backend errors
   */
  const showValidateError = useCallback(
    (errorMessage: string) => {
      switch (errorMessage) {
        case 'vehicle.duplicate.imei':
          formMethods.setError(
            'generalInfo.imei',
            { type: 'custom', message: 'Duplicate IMEI' },
            { shouldFocus: true },
          );
          enqueueSnackbar('Duplicate Vehicle ID', { variant: 'error' });
          break;

        case 'vehicle.duplicate.platenumber':
          formMethods.setError(
            'generalInfo.plateNumber',
            { type: 'custom', message: 'Duplicate platenumber' },
            { shouldFocus: true },
          );
          enqueueSnackbar('Duplicate platenumber', { variant: 'error' });
          break;

        default:
          enqueueSnackbar('Saving error', { variant: 'error' });
          break;
      }
    },
    [enqueueSnackbar, formMethods],
  );

  /**
   * Get managers for selected subcompany
   */
  const getCompanyManagers = useCallback(async (companyId: string) => {
    try {
      const response = await getCompanyUsersReq(companyId);
      setManagers(
        response.map((manager) => ({
          value: manager.id,
          label: `${manager.firstName} ${manager.lastName}`,
        })),
      );
    } catch (e) {
      console.log(e);
    }
  }, []);

  /**
   * Get drivers for selected subcompany
   */
  const getCompanyDrivers = useCallback(async (companyId: string) => {
    try {
      const response = await getAllActiveDriversReq(companyId);
      setDrivers(
        response.map((driver) => ({
          value: driver.id,
          label: `${driver.firstName} ${driver.lastName}`,
        })),
      );
    } catch (e) {
      console.log(e);
    }
  }, []);

  /**
   * Update vehicle data
   * @param data
   * @return {Promise<void>}
   */
  const updateVehicle = useCallback(
    async (data: TVehicleEditData) => {
      try {
        setIsLoading(true);

        await vehicleEditReq(vehicle.id, data, isMetricSystem);
        enqueueSnackbar('Saved', { variant: 'success' });
      } catch (e) {
        if (isAxiosError(e)) {
          showValidateError(e.response?.data.error);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [enqueueSnackbar, isMetricSystem, showValidateError, vehicle.id],
  );

  /**
   * Get vehicle brands
   */
  useEffect(() => {
    const req = async () => {
      try {
        const response = await vehicleBrandsReq();
        setBrands(response);
      } catch (e) {
        console.log(e);
      }
    };

    req();
  }, []);

  /**
   * Get initial vehicle data for edit page
   */
  useEffect(() => {
    const getVehicleData = async () => {
      try {
        setIsLoading(true);
        const response = await vehicleGetReq(vehicle.id, isMetricSystem);
        setVehicleInfo(response);
        getModels(response.generalInfo.brandId);
        getCompanyManagers(response.generalInfo.companyId);
        getCompanyDrivers(response.generalInfo.companyId);
        formMethods.reset(response);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };

    getVehicleData();
  }, [
    formMethods,
    getCompanyDrivers,
    getCompanyManagers,
    getModels,
    isMetricSystem,
    setVehicleInfo,
    vehicle.id,
  ]);

  return (
    <div data-testid='quick-edit'>
      {isLoading && <Loader lightMode width={150} isBlock preventClick />}

      <div className={classes.header}>
        <div className={classes.vehicleInfo}>
          {vehicle.country && vehicle.plateNumber && (
            <PlateNumber
              wrapClass={classes.plateNumber}
              country={vehicle.country}
              plateNumber={vehicle.plateNumber}
            />
          )}
          {vehicle.type && <VehicleIcon variant={'outline'} type={vehicle.type} />}
          <div className={classes.vehicleModel}>{`${vehicle.brandName} ${vehicle.modelName}`}</div>
        </div>

        <div className={classes.btns}>
          {userData.role === USER_ROLES.TECH_ADMIN && (
            <div
              className={classes.btn}
              onClick={() => setShowDisableModal(true)}
              data-testid='button-delete'
            >
              {vehicleInfo?.generalInfo.active && <DeleteIcon width={20} />}
              {!vehicleInfo?.generalInfo.active && (
                <PowerIcon className={classes.powerIcon} width={20} height={20} />
              )}
            </div>
          )}

          <div className={classes.btn} onClick={editHandler} data-testid='button-setting'>
            <SettingIcon width={20} />
          </div>
          <div className={classes.btn} onClick={onClose} data-testid='button-close'>
            <CloseIcon width={12} />
          </div>
        </div>

        <div className={classes.shadow} />
      </div>

      <div className={classes.content}>
        <FormProvider {...formMethods}>
          <form className={classes.form} data-testid='vehicle-form'>
            <div className={classes.title}>Vehicle identity</div>
            <Input
              title={t('number.plate.label')}
              name='generalInfo.plateNumber'
              placeholder='Enter value'
              textTransform='uppercase'
              disabled={isSubcompanyView || !isActiveVehicle}
            />

            <Select
              title={t('make.label')}
              name='generalInfo.brandId'
              items={brands ?? []}
              additionalOnChange={(e) => {
                formMethods.resetField('generalInfo.modelId');
                getModels(+e.target.value);
              }}
              disabled={isSubcompanyView || !isActiveVehicle}
            />

            <Select
              title={t('model.label')}
              name='generalInfo.modelId'
              disabled={!models || isSubcompanyView || !isActiveVehicle}
              items={models ?? []}
            />

            <Input
              title={t('year.label')}
              name='generalInfo.year'
              placeholder={'Enter value'}
              disabled={isSubcompanyView || !isActiveVehicle}
            />
            <Input
              title={t('odometer.label')}
              name='generalInfo.odometer'
              placeholder={'Enter value'}
              disabled={isSubcompanyView || !isActiveVehicle}
              endAdornment={t(`measurement.system.page:${isMetricSystem ? 'km' : 'mile'}.label`)}
            />

            <div className={classes.title}>{t('assign.vehicle.label')}</div>
            <Select
              title={t('company.label')}
              name='generalInfo.companyId'
              items={Array.isArray(subcompaniesSelectList) ? subcompaniesSelectList : []}
              additionalOnChange={(e) => {
                formMethods.setValue('generalInfo.managedById', '');
                formMethods.setValue('generalInfo.driverId', '');
                formMethods.setValue('generalInfo.marketValue', null);
                formMethods.setValue(
                  'generalInfo.price',
                  subcompaniesSelectList.find(
                    (item: ISelectItemWithCompanyVehicePrice) => item.value === e.target.value,
                  ).vehiclePrice ?? defaultValues.generalInfo.price,
                );
                getCompanyManagers(e.target.value);
                getCompanyDrivers(e.target.value);
              }}
              disabled={
                ![ROLES_OBJECT.TECH_ADMIN, ROLES_OBJECT.ADMIN, ROLES_OBJECT.OWNER].includes(
                  userData.role,
                ) ||
                isSubcompanyView ||
                !isActiveVehicle
              }
            />
            <Select
              title={t('manager.label')}
              name='generalInfo.managedById'
              items={managers ?? []}
              disabled={
                !managers ||
                isSubcompanyView ||
                userData.role === ROLES_OBJECT.MANAGER ||
                !isActiveVehicle
              }
            />

            <Select
              title={t('assign.driver.label')}
              name='generalInfo.driverId'
              items={drivers ?? []}
              disabled={!drivers || isSubcompanyView || !isActiveVehicle}
              subtitle={t('optional.label')}
            />
          </form>

          <div className={classes.save}>
            <Button
              variant={BUTTON_VARIANTS.FILL}
              onClick={formMethods.handleSubmit(updateVehicle)}
              testId='button-save'
              disabled={isSubcompanyView || !isActiveVehicle}
            >
              {t('fields:save.label')}
            </Button>
          </div>
        </FormProvider>
      </div>
    </div>
  );
};

export default QuickEdit;
