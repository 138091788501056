import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  header: {
    width: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
    position: 'relative',
  },

  companyName: {
    fontSize: 20,
    fontWeight: 500,
    color: palette.custom.blue200,
    maxWidth: 410,
  },

  btns: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 20,
  },

  btn: {
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },

  shadow: {
    width: '100%',
    height: 6,
    position: 'absolute',
    left: 0,
    bottom: 0,
    background: 'linear-gradient(180deg, #000000 0%, rgba(35, 45, 70, 0) 100%)',
    opacity: 0.1,
  },

  list: {
    position: 'relative',
    width: '100%',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 20,
  },

  item: {
    padding: '18px 15px',
    backgroundColor: alpha(theme.palette.primary.dark, 0.08),
    borderRadius: 10,
    fontSize: 20,
    fontWeight: 400,
  },

  label: {
    color: theme.palette.primary.dark,
  },

  closeBtn: {
    width: 400,
  },
}));

export default useStyles;
