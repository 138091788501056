import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  vehicle: {
    width: 50,
    height: 35,
  },

  vehicleLittle: {
    width: 28,
    height: 22,
  },
}));

export default useStyles;
