import cx from 'classnames';
import React, { FC } from 'react';

import { COUNTRIES } from 'interfaces/common/countries';
import { VEHICLE_TYPES } from 'interfaces/vehicles/types';

import CarIcon from '../CarIcon';

import VehicleNumber from './components/VehicleRegNumber';
import useStyles from './styles';

interface IVehicleInfo {
  id: string;
  plateNumber: string;
  brandName: string;
  littleNumber?: boolean;
  isColorCircle?: boolean;
  country: COUNTRIES;
  handleOpen: () => void;
  isLittlePlateNumber?: boolean;
  type: VEHICLE_TYPES;
}

const VehicleInfo: FC<IVehicleInfo> = ({
  plateNumber,
  brandName,
  handleOpen,
  type,
  country,
  isColorCircle = false,
  littleNumber = false,
  isLittlePlateNumber = false,
}) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.vehicleInfo, littleNumber && classes.vehicleLittle)}>
      <div className={cx(isColorCircle && classes.colorCircle)}>
        <CarIcon type={type} sizeLittle={littleNumber} />
      </div>
      <div className={cx(classes.regNumber, littleNumber && classes.regLittle)}>
        <VehicleNumber
          onClick={handleOpen}
          plateNumber={plateNumber}
          country={country}
          isLittle={isLittlePlateNumber}
        />
        <div data-testid='brand' className={cx(classes.brand, littleNumber && classes.brandLittle)}>
          {brandName}
        </div>
      </div>
    </div>
  );
};

export default VehicleInfo;
