import queryString from 'query-string';

import { ILocalizationConfig } from 'interfaces/common/localization-config';
import { IResponseWrapper } from 'interfaces/server/response-wrapper';

import server from 'utils/server';

export interface ICompanySettingsItem {
  activeDriverCount: number;
  activeVehicleCount: number;
  code: string;
  companyType: string;
  geofencingEnabled: boolean;
  iconUrl: string;
  id: string;
  localizationConfig: ILocalizationConfig;
  name: string;
  parentId: string;
  vehicleMonthlyPrice: number | null;
}

export const getCompaniesReq = async (page: number, search: string) => {
  const params = {
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
    page,
    size: 25,
  };

  const body = {
    companyName: search,
  };
  const res = await server.services.beService.post<IResponseWrapper<ICompanySettingsItem[]>>(
    'companies/?' + queryString.stringify(params),
    body,
  );
  return res.data;
};
