import cx from 'classnames';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg';

import crashModalAtom from 'recoil/crash-modal';
import userConfigAtom from 'recoil/user-config';

import { getCrashInfoReq } from 'requests/gps-service/crash-controller/get-crash-details';
import { getCrashDriverInfoReq } from 'requests/gps-service/crash-controller/get-crash-driver-details';
import { getCrashManagementDataReq } from 'requests/gps-service/crash-controller/get-management-data';

import Controls from '../components/controls';

import Form from './Form';
import Header from './Header';
import Info from './Info';
import useStyles from './styles';

const ModalInfo = () => {
  const classes = useStyles();
  const { t } = useTranslation('crash.modal');
  const { isMetricSystem } = useRecoilValue(userConfigAtom);

  const [{ crashData, driverData, currentCrashIdNumber, crashList }, setCrashModal] =
    useRecoilState(crashModalAtom);

  /**
   * Get driver and crash data from API
   * @type {(function(*): Promise<void>)|*}
   */
  const getModalInfo = useCallback(
    async (crashId) => {
      try {
        setCrashModal((prevState) => ({
          ...prevState,
          isFetching: true,
        }));

        const crashInfoResponse = await getCrashInfoReq(crashId, isMetricSystem);
        const crashDriverInfoResponse = await getCrashDriverInfoReq(crashId);
        const crashManagementInfoResponse = await getCrashManagementDataReq(crashId);

        setCrashModal((prevState) => ({
          ...prevState,
          driverData: crashDriverInfoResponse,
          crashData: crashInfoResponse,
          managementData: crashManagementInfoResponse,
        }));
      } catch (error) {
        setCrashModal((prevState) => ({
          ...prevState,
          error: error,
        }));
      } finally {
        setCrashModal((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
      }
    },
    [isMetricSystem, setCrashModal],
  );

  useEffect(() => {
    if ((!crashData || !driverData) && crashList.length !== 0) {
      getModalInfo(crashList[currentCrashIdNumber]);
    }
  }, [crashData, crashList, currentCrashIdNumber, driverData, getModalInfo]);

  /**
   * Get prev crash ID
   */
  const prevCrashId = useMemo(() => {
    return crashList[currentCrashIdNumber - 1];
  }, [crashList, currentCrashIdNumber]);

  /**
   * Get next crash ID
   */
  const nextCrashId = useMemo(() => {
    return crashList[currentCrashIdNumber + 1];
  }, [crashList, currentCrashIdNumber]);

  /**
   * Set new prev crash number and clear modal info
   * @type {(function(): void)|*}
   */
  const handlePrevArrow = useCallback(() => {
    setCrashModal((prevState) => {
      return {
        ...prevState,
        currentCrashIdNumber: currentCrashIdNumber - 1,
        driverData: null,
        crashData: null,
      };
    });
  }, [currentCrashIdNumber, setCrashModal]);

  /**
   * Set new next crash number and clear modal info
   * @type {(function(): void)|*}
   */
  const handleNextArrow = useCallback(() => {
    setCrashModal((prevState) => {
      return {
        ...prevState,
        currentCrashIdNumber: currentCrashIdNumber + 1,
        driverData: null,
        crashData: null,
      };
    });
  }, [currentCrashIdNumber, setCrashModal]);

  if (!crashData) {
    return (
      <div className={cx(classes.wrapper, 'empty')}>
        <Controls showOnlyCloseIcon />
        <div className={classes.emptyMessage}>{t('no.data.message.label')}</div>
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      {prevCrashId && (
        <div className={cx(classes.arrow, classes.arrowBack)} onClick={handlePrevArrow}>
          <ArrowIcon />
        </div>
      )}

      <div className={classes.content}>
        <Controls />

        <Header />

        <Info />

        <Form />
      </div>

      {nextCrashId && (
        <div className={cx(classes.arrow, classes.arrowNext)} onClick={handleNextArrow}>
          <ArrowIcon />
        </div>
      )}
    </div>
  );
};

ModalInfo.defaultProps = {};

ModalInfo.propTypes = {};

export default React.memo(ModalInfo);
