import { atom } from 'recoil';

import { TVehicleEditData } from 'interfaces/vehicles/vehicle-edit-data';

const settingQuickEditVehicleInfoAtom = atom<TVehicleEditData | null>({
  key: 'settingQuickEditVehicleInfoAtom',
  default: null,
});

export default settingQuickEditVehicleInfoAtom;
