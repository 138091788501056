import * as yup from 'yup';

export const formSchema = () => {
  return yup.object().shape({
    companyIds: yup.string().nullable(),
    userIds: yup.string().nullable(),
    createdAtFrom: yup.date().nullable(),
    createdAtTill: yup.date().nullable(),
    vehicleTypes: yup.array().of(yup.string()).nullable(),
    startDailyKm: yup.number().nullable(),
    finishDailyKm: yup.number().nullable(),
    includeDisabled: yup.boolean(),
  });
};

export type TVehicleFiltersForm = yup.InferType<ReturnType<typeof formSchema>>;
