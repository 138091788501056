import { format } from 'date-fns';
import React, { FC } from 'react';

import { ReactComponent as ActiveIcon } from 'assets/images/device/status-active.svg';
import { ReactComponent as InactiveIcon } from 'assets/images/device/status-inactive.svg';

import useStyles from './styles';

interface IDeviceStatus {
  time: string;
  active: boolean;
  inlineDate?: boolean;
}

const DeviceStatus: FC<IDeviceStatus> = ({ time, active, inlineDate = false }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrap} data-testid='device-status-wrap'>
      <div className={classes.icon}>{active ? <ActiveIcon /> : <InactiveIcon />}</div>

      {!active && inlineDate && (
        <div className={classes.time}>{format(new Date(time), 'dd.MM.yyyy  HH:mm')}</div>
      )}
      {!active && !inlineDate && (
        <div className={classes.time}>
          <div>{format(new Date(time), 'dd.MM.yyyy')}</div>
          <div>{format(new Date(time), 'HH:mm')}</div>
        </div>
      )}
    </div>
  );
};

export default DeviceStatus;
