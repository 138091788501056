import {
  GoogleMap,
  Libraries,
  OverlayView,
  Polyline,
  useJsApiLoader,
} from '@react-google-maps/api';
import React, { FC, useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { PENALTIES } from 'interfaces/map/penalties';

import { mapDefaultSetting } from 'constants/map';

import mapDefaultBoundsSelector from 'recoil/trip/map/default-setting';
import mapPenaltiesSelector from 'recoil/trip/map/penalties';
import tripRouteSelector from 'recoil/trip/map/route';
import mapSensorAlarmsSelector from 'recoil/trip/map/sensor-alarms';
import tripPointsSelector from 'recoil/trip/points';
import showMaxSpeedMarkerAtom from 'recoil/trip/show-max-speed-marker';

import Layer from 'components/Map/Layer';
import CustomMarkerWrap from 'components/TripComponents/custom-marker-wrap';
import PenaltyMarker from 'components/TripComponents/PenaltyMarker';
import PenaltySpeedMarker from 'components/TripComponents/PenaltySpeedMarker';
import SensorAlarmMarker from 'components/TripComponents/sensor-alarm-marker';
import SpeedMarker from 'components/TripComponents/speed-marker';
import VehicleMarker from 'components/TripComponents/vehicle-marker';

import FinishMarker from './finish-marker';
import StartMarker from './start-marker';
import useStyles from './styles';
import Timeline from './timeline';
import useRouteAnimation, { ANIMATION_STATUS } from './use-route-animation';
import ZoomControls from './zoom-controls';

interface IProps {
  tripId: string;
}
const libraries: Libraries = ['marker', 'geometry'];
const id = [process.env.REACT_APP_GOOGLE_MAP_ID as string];
const Map: FC<IProps> = ({ tripId }) => {
  const classes = useStyles();
  const [isSatellite, setIsSatellite] = useState(false);
  const route = useRecoilValue(tripRouteSelector(tripId));
  const tripData = useRecoilValue(tripPointsSelector(tripId));
  const defaultBounds = useRecoilValue(mapDefaultBoundsSelector(tripId));
  const penalties = useRecoilValue(mapPenaltiesSelector(tripId));
  const sensorAlarms = useRecoilValue(mapSensorAlarmsSelector(tripId));
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const showMaxSpeedMarker = useRecoilValue(showMaxSpeedMarkerAtom);
  const [zoom, setZoom] = useState(mapDefaultSetting.zoom);

  const { currentPosition, currentIndex, animationStatus, play, stop, pause, setPosition } =
    useRouteAnimation(route, 100);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY as string,
    libraries,
    mapIds: id,
    version: 'beta',
  });

  const toggleMapType = useCallback(() => setIsSatellite((prevState) => !prevState), []);

  const zoomHandler = useCallback(() => {
    if (map) {
      setZoom(map.getZoom()!);
    }
  }, [map]);

  const onMapLoaded = useCallback(
    (map: google.maps.Map) => {
      setMap(map);
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(new window.google.maps.LatLng(defaultBounds[1], defaultBounds[0])); // south-west
      bounds.extend(new window.google.maps.LatLng(defaultBounds[3], defaultBounds[2])); // north-east

      map.fitBounds(bounds);
    },
    [defaultBounds],
  );

  const toggleAnimation = useCallback(() => {
    if (animationStatus !== ANIMATION_STATUS.PLAY) {
      play();
      return;
    }
    pause();
  }, [animationStatus, pause, play]);

  if (!isLoaded) {
    return null;
  }

  return (
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '100%' }}
      center={mapDefaultSetting.center}
      zoom={mapDefaultSetting.zoom}
      onLoad={onMapLoaded}
      onZoomChanged={zoomHandler}
      options={{
        mapId: process.env.REACT_APP_GOOGLE_MAP_ID,
        mapTypeControl: false,
        disableDoubleClickZoom: true,
        disableDefaultUI: true,
        mapTypeId: isSatellite ? 'hybrid' : 'roadmap',
      }}
    >
      <Polyline
        path={route}
        options={{
          strokeColor: isSatellite ? '#fff' : '#000',
        }}
      />

      {map && (
        <CustomMarkerWrap map={map} position={route[0]} onClick={toggleAnimation}>
          <StartMarker animationStatus={animationStatus} />
        </CustomMarkerWrap>
      )}

      {map && (
        <CustomMarkerWrap map={map} position={route[route.length - 1]} onClick={stop}>
          <FinishMarker />
        </CustomMarkerWrap>
      )}

      {showMaxSpeedMarker && (
        <OverlayView
          mapPaneName={'markerLayer'}
          position={{
            lat: tripData.maxSpeedPoint!.latitude,
            lng: tripData.maxSpeedPoint!.longitude,
          }}
        >
          <SpeedMarker
            currentSpeed={tripData.maxSpeedPoint!.currentSpeed}
            maxSpeed={tripData.maxSpeedPoint!.maxSpeed}
          />
        </OverlayView>
      )}

      {penalties.map(({ latitude, longitude, label, timestamp, currentSpeed, maxSpeed }) => {
        if ([PENALTIES.SPEED_10, PENALTIES.SPEED_20].includes(label) && currentSpeed && maxSpeed) {
          return (
            <OverlayView
              mapPaneName={'markerLayer'}
              key={timestamp}
              position={{
                lat: latitude,
                lng: longitude,
              }}
            >
              <PenaltySpeedMarker currentSpeed={currentSpeed} maxSpeed={maxSpeed} />
            </OverlayView>
          );
        }

        return (
          <OverlayView
            mapPaneName={'markerLayer'}
            key={timestamp}
            position={{
              lat: latitude,
              lng: longitude,
            }}
          >
            <PenaltyMarker label={label} />
          </OverlayView>
        );
      })}

      {sensorAlarms.map(({ lat, lng, label }, i) => {
        return (
          <OverlayView
            mapPaneName={'markerLayer'}
            position={{
              lat,
              lng,
            }}
            key={`${lat}-${lng}-${label}-${i}`}
          >
            <SensorAlarmMarker label={label} />
          </OverlayView>
        );
      })}

      {map && (
        <CustomMarkerWrap map={map} position={currentPosition}>
          <VehicleMarker speed={tripData.points[currentIndex].speed} />
        </CustomMarkerWrap>
      )}

      <div className={classes.layer}>
        <Layer isSatellite={isSatellite} onClick={toggleMapType} />
      </div>

      {map && <ZoomControls map={map} zoom={zoom} />}

      {map && animationStatus !== ANIMATION_STATUS.STOP && (
        <Timeline
          tripId={tripId}
          setPosition={setPosition}
          currentIndex={currentIndex}
          speed={tripData.points[currentIndex].speed}
          animationStatus={animationStatus}
          stopAnimation={stop}
          toggleAnimation={toggleAnimation}
        />
      )}
    </GoogleMap>
  );
};

export default Map;
