import { IVehicleModel } from 'interfaces/vehicles/model';

import server from 'utils/server';

/**
 * Renames a vehicle model.
 *
 * @async
 * @param {number} brandId - The ID of the brand.
 * @param {number} modelId - The ID of the model.
 * @param {string} value - The new name of the model.
 * @returns {Promise<IVehicleModel>} A promise that resolves to the renamed vehicle model.
 */
export const renameVehicleModelReq = async (
  brandId: number,
  modelId: number,
  value: string,
): Promise<IVehicleModel> => {
  const body = {
    name: value,
  };

  return server.services.beService
    .put<IVehicleModel>(`brands/${brandId}/models/${modelId}`, body)
    .then((res) => res.data);
};
