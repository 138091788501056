import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

interface IProps {
  message?: string | { key: string; values: Record<never, never> };
}

const ErrorMessage: FC<IProps> = ({ message }) => {
  const classes = useStyles();
  const { t } = useTranslation('errors');

  const renderString = (message: IProps['message']): string => {
    if (!message) {
      return '';
    }
    if (typeof message === 'string') {
      return t(message);
    }
    return t(message!.key, message!.values);
  };

  return <div className={classes.errorMessage}>{renderString(message)}</div>;
};

export default ErrorMessage;
