import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
  },

  inputWrap: {
    backgroundColor: palette.custom.blue900,
    borderRadius: 5,
    padding: '3px 10px',
    border: `1px solid ${palette.custom.blue900}`,
    width: '100%',

    '&.disabled': {
      border: `1px solid ${palette.custom.bluePrimary}`,
    },
  },

  inputWrapFocus: {
    backgroundColor: palette.custom.softBlue,
    border: `1px solid ${palette.custom.softBlue}`,
  },

  inputWrapError: {
    border: `1px solid ${palette.custom.redSecondary} !important`,
  },

  input: {
    color: palette.custom.blue200,

    '&::placeholder': {
      color: palette.custom.blue200,
      opacity: 0.4,
    },
  },

  inputDisabled: {
    textFillColor: `${palette.custom.blue200} !important`,
    backgroundColor: palette.custom.hoverBlue,
  },

  endAdornment: {
    fontFamily: 'SFPro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: palette.custom.blue800,
    marginLeft: 5,
  },
}));

export default useStyles;
