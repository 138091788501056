import { alpha, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

interface Props {
  technicalMessageBlockHeight: number;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  table: {
    maxWidth: 'calc(100% - 100px)',
    height: ({ technicalMessageBlockHeight }) =>
      `calc(100vh - 360px - ${technicalMessageBlockHeight}px )`,
    maxHeight: ({ technicalMessageBlockHeight }) =>
      `calc(100vh - 360px - ${technicalMessageBlockHeight}px )`,
    margin: '40px 50px',
  },

  headCell: {
    paddingBottom: '20px !important',

    '&:first-child': {
      paddingLeft: '15px !important',
    },

    '&:last-child': {
      paddingRight: '15px !important',
    },
  },

  row: {
    transition: 'all 0.3s ease',
    borderRadius: 10,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.08),
    },

    '&:hover $editIcon': {
      opacity: 1,
      pointerEvents: 'all',
    },
  },

  cell: {
    fontSize: 20,
    fontWeight: 500,
    color: palette.custom.blue200,

    '&:first-child': {
      paddingLeft: '15px !important',
    },

    '&:last-child': {
      paddingRight: '15px !important',
    },
  },

  checkbox: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
  },

  editIcon: {
    cursor: 'pointer',
    width: 24,
    pointerEvents: 'none',
    opacity: 0,
    transition: 'opacity 0.3s ease',

    '& path': {
      fill: theme.palette.primary.dark,
    },
  },

  money: {
    fontSize: 20,
  },
}));

export default useStyles;
