import { IVehicleFormResponseData } from 'interfaces/vehicles/i-vehicle-form-data';
import { TVehicleEditData } from 'interfaces/vehicles/vehicle-edit-data';

import { vehicleDTO } from 'utils/dto/vehicle/edit-form';
import server from 'utils/server';

/**
 * Creates a new vehicle using the provided data.
 *
 * @param {TVehicleEditData} data - The data for creating the vehicle.
 * @param {boolean} [isMetricSystem=false] - Whether to use the metric system for the data.
 * @returns {Promise<IVehicleFormResponseData>} A promise that resolves to the created vehicle response data.
 */
export const vehicleCreateReq = async (
  data: TVehicleEditData,
  isMetricSystem: boolean = false,
): Promise<IVehicleFormResponseData> => {
  const body = isMetricSystem ? { ...data } : vehicleDTO.toMetric(data);
  body.generalInfo.active = true;

  return server.services.beService
    .post<IVehicleFormResponseData>('vehicles/v2', body)
    .then((res) => res.data);
};
