import { ProviderContext } from 'notistack';
import { UseFormSetError } from 'react-hook-form/dist/types/form';
import { TFunction } from 'react-i18next';

import { TUserCreateData } from 'requests/be-service/system-user-controller/create-user';

export const errorHandler = (
  setError: UseFormSetError<TUserCreateData>,
  enqueueSnackbar: ProviderContext['enqueueSnackbar'],
  t: TFunction,
  errorMessage: string,
) => {
  switch (errorMessage) {
    case 'system.user.duplicate.username':
      setError(
        'userName',
        { type: 'custom', message: t(`errors:${errorMessage}`) },
        { shouldFocus: true },
      );
      enqueueSnackbar(t(`errors:${errorMessage}`), { variant: 'error' });
      return;
    case 'system.user.duplicate.email':
      setError(
        'email',
        { type: 'custom', message: t(`errors:${errorMessage}`) },
        { shouldFocus: true },
      );
      enqueueSnackbar(t(`errors:${errorMessage}`), { variant: 'error' });
      return;
  }
};
