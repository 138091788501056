import { ISuccessBaseResponse } from 'interfaces/common/success-base-response';

import server from 'utils/server';

/**
 * Delete sensor
 * @param sensorId
 * @returns {Promise<ISuccessBaseResponse>}
 */
export const deleteSensorReq = async (sensorId: string): Promise<ISuccessBaseResponse> =>
  server.services.beService
    .delete<ISuccessBaseResponse>(`sensors/${sensorId}`)
    .then((res) => res.data);
