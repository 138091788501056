import React, { useMemo, FC } from 'react';

import { VEHICLE_TYPES } from 'interfaces/vehicles/types';

import { ReactComponent as BusFillIcon } from 'assets/images/vehicles/bus-sm.svg';
import { ReactComponent as MotorcycleFillIcon } from 'assets/images/vehicles/motorcycle-sm.svg';
import { ReactComponent as BusOutlineIcon } from 'assets/images/vehicles/outline/bus.svg';
import { ReactComponent as MotorcycleOutlineIcon } from 'assets/images/vehicles/outline/motobike.svg';
import { ReactComponent as SedanOutlineIcon } from 'assets/images/vehicles/outline/sedan.svg';
import { ReactComponent as TractorOutlineIcon } from 'assets/images/vehicles/outline/tractor.svg';
import { ReactComponent as TruckOutlineIcon } from 'assets/images/vehicles/outline/truck.svg';
import { ReactComponent as SedanFillIcon } from 'assets/images/vehicles/sedan-sm.svg';
import { ReactComponent as TractorFillIcon } from 'assets/images/vehicles/tractor-sm.svg';
import { ReactComponent as TruckFillIcon } from 'assets/images/vehicles/truck-sm.svg';

type IconType = React.FC<React.SVGProps<SVGSVGElement>>;

interface IVehicleIcon {
  type: VEHICLE_TYPES;
  className?: string;
  variant?: 'fill' | 'outline';
}

const VehicleIcon: FC<IVehicleIcon> = ({ type, className, variant = 'fill' }) => {
  const Icon: IconType = useMemo(() => {
    if (variant === 'fill') {
      switch (type) {
        case VEHICLE_TYPES.BUS:
          return BusFillIcon;
        case VEHICLE_TYPES.TRUCK:
          return TruckFillIcon;
        case VEHICLE_TYPES.SEDAN:
          return SedanFillIcon;
        case VEHICLE_TYPES.MOTORCYCLE:
          return MotorcycleFillIcon;
        case VEHICLE_TYPES.TRACTOR:
          return TractorFillIcon;
      }
    }
    if (variant === 'outline') {
      switch (type) {
        case VEHICLE_TYPES.BUS:
          return BusOutlineIcon;
        case VEHICLE_TYPES.TRUCK:
          return TruckOutlineIcon;
        case VEHICLE_TYPES.SEDAN:
          return SedanOutlineIcon;
        case VEHICLE_TYPES.MOTORCYCLE:
          return MotorcycleOutlineIcon;
        case VEHICLE_TYPES.TRACTOR:
          return TractorOutlineIcon;
      }
    }
    return BusFillIcon;
  }, [type, variant]);

  return (
    <div className={className} data-testid='vehicle-icon'>
      <Icon />
    </div>
  );
};

export default React.memo(VehicleIcon);
