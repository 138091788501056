import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    filter: 'drop-shadow(0px 2px 13px rgba(0, 0, 0, 0.18))',
    cursor: 'pointer',
    borderRadius: 30,
    backgroundColor: theme.palette.primary.light,
    width: 'fit-content',
    height: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 3,
    position: 'relative',
    transform: 'translateX(-50%) translateY(-35px)',

    '&:after': {
      position: 'absolute',
      bottom: -5,
      left: '50%',
      transform: 'translateX(-50%)',
      content: '""',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '9.5px 5.5px 0 5.5px',
      borderColor: `${theme.palette.primary.light} transparent transparent transparent`,
    },
  },

  info: {
    marginLeft: 8,
    marginRight: 6,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    whiteSpace: 'nowrap',
    fontWeight: 500,
    fontSize: 12,
    color: palette.custom.blue600,

    '&:empty': {
      display: 'none',
    },

    '& div:first-child': {
      fontWeight: 600,
      fontSize: 14,
      color: palette.custom.black,
    },

    '& div': {
      maxWidth: 200,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

export default useStyles;
