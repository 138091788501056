/**
 * Enum representing license categories.
 * @enum {string}
 */
export enum LICENSE_CATEGORIES {
  A = 'A',
  A1 = 'A1',
  B = 'B',
  B1 = 'B1',
  BE = 'BE',
  C = 'C',
  C1 = 'C1',
  CE = 'CE',
  C1E = 'C1E',
  D = 'D',
  D1 = 'D1',
  DE = 'DE',
  D1E = 'D1E',
}
