import { atom } from 'recoil';

import { IGetDriversItem } from 'requests/be-service/driver-controller/get-drivers';

const driversListAtom = atom<IGetDriversItem[]>({
  key: 'driversListAtom',
  default: [],
});

export default driversListAtom;
