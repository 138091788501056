import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },

  accordion: {
    width: '100%',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },

  accordionArrow: {
    fill: theme.palette.primary.dark,
    cursor: 'pointer',
  },

  header: {
    padding: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'default',
  },

  headerFocus: {
    backgroundColor: 'transparent !important',
  },

  headerContent: {
    cursor: 'default',
    alignItems: 'center',
    margin: 0,
  },

  title: {
    fontSize: 24,
    fontWeight: 500,
    color: palette.custom.blue200,
    marginRight: 50,
  },

  sensorNumber: {
    width: 500,
    marginLeft: 30,
  },

  notificationWrap: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 400,
    color: palette.custom.blue700,
    whiteSpace: 'nowrap',
    marginLeft: 50,
    marginRight: 50,

    '& div:first-child': {
      marginRight: 10,
    },
  },

  accordionContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },

  parameter: {
    marginTop: 50,
    width: '100%',
  },

  parameterHeader: {
    justifyContent: 'flex-start',
    marginBottom: 40,

    '&.isMovement': {
      marginBottom: 20,
    },
  },

  parameterTitle: {
    fontSize: 24,
    fontWeight: 500,
    color: palette.custom.blue200,
    marginLeft: 20,
    marginRight: 20,
  },

  parameterSubtitle: {
    fontSize: 14,
    fontWeight: 400,
    color: palette.custom.blue300,
  },

  selectWrap: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 30,
    marginBottom: 40,
  },

  btn: {
    width: 600,
    height: 50,
    borderRadius: 10,
    backgroundColor: palette.custom.goldSecondary,
    fontSize: 16,
    fontWeight: 500,
    color: palette.custom.bluePrimary,
    border: 'none',
    boxShadow: 'none',
    marginTop: 50,
    cursor: 'pointer',
  },

  deleteBtn: {
    fontSize: 16,
    fontWeight: 400,
    color: palette.custom.blue200,
    textDecoration: 'underline',
    marginLeft: 'auto',
    cursor: 'pointer',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  dialogContent: {
    padding: '50px 30px',
    backgroundColor: palette.custom.softBlue,
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: 600,
  },

  label: {
    fontSize: 24,
    fontWeight: 500,
    color: palette.custom.blue200,
  },

  btns: {
    marginTop: 30,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 20,
  },

  modalBtn: {
    paddingLeft: 30,
    paddingRight: 30,
  },
}));

export default useStyles;
