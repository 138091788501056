import { TDataWithId } from 'interfaces/common/data-with-id';
import { TCompany } from 'interfaces/company/company';

import server from 'utils/server';

/**
 * Edit a company request.
 * @async
 * @param {TDataWithId<TCompany>} data - The company data to be edited.
 * @returns {Promise<TDataWithId<TCompany>>} - A promise that resolves to the edited company data.
 */
export const editCompanyReq = async (
  data: TDataWithId<TCompany>,
): Promise<TDataWithId<TCompany>> => {
  return server.services.beService
    .post<TDataWithId<TCompany>>('companies/create', data)
    .then((res) => res.data);
};
