import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  card: {
    width: 15,
    height: 27,
    borderRadius: 2,

    '&.RED': {
      backgroundColor: palette.custom.redSecondary,
    },

    '&.YELLOW': {
      backgroundColor: palette.custom.yellow,
    },
  },
}));

export default useStyles;
