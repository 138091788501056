import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import { atom, selector } from 'recoil';

import { ROLES_OBJECT } from 'constants/user';

import recoilLocalStorageEffect from 'utils/recoil-localStorage-effect';

import { TVehicleFiltersForm } from 'modules/drivers-vehicles/components/vehicles-filter/form-schema';

import userDataAtom from '../../../userData';

export interface IVehicleFilters extends TVehicleFiltersForm {
  movementAtFrom: null | number;
  movementAtTill: null | number;
  query: string;
}

export const initValues = {
  companyIds: null,
  userIds: null,
  createdAtFrom: null,
  createdAtTill: null,
  finishDailyKm: null,
  movementAtFrom: startOfDay(new Date()).getTime(),
  movementAtTill: endOfDay(new Date()).getTime(),
  vehicleTypes: [],
  startDailyKm: null,
  query: '',
  includeDisabled: false,
} as IVehicleFilters;

const settingVehiclesFilterAtom = atom<IVehicleFilters>({
  key: 'settingVehiclesFilterAtom',
  default: initValues,
  effects_UNSTABLE: [recoilLocalStorageEffect('settingVehiclesFilterAtom', initValues)],
});

const settingVehiclesFilterSelector = selector<IVehicleFilters>({
  key: 'settingVehiclesFilterSelector',
  get: ({ get }) => {
    const { role, companyId, id } = get(userDataAtom)!;
    const init = get(settingVehiclesFilterAtom);

    if (
      [
        ROLES_OBJECT.TECH_ADMIN,
        ROLES_OBJECT.ADMIN,
        ROLES_OBJECT.OWNER,
        ROLES_OBJECT.OPERATOR,
      ].includes(role)
    ) {
      return init;
    }

    return {
      ...init,
      companyIds: companyId,
      userIds: id,
    };
  },
  set: ({ set }, newValue) => set(settingVehiclesFilterAtom, newValue),
});

export default settingVehiclesFilterSelector;
