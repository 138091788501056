import { NOTIFICATION_NOT_READ_TYPES, NOTIFICATION_TYPES } from './notification-types';

export enum NOTIFICATION_TABS {
  ALL = 'ALL',
  PENALTY = 'PENALTY',
  COLLISION = 'COLLISION',
  CAN_CONTROL = 'CAN_CONTROL',
  SENSORS = 'SENSORS',
  DEVICE_POWER = 'DEVICE_POWER',
}

export interface INotificationTab {
  title: string;
  value: NOTIFICATION_TABS;
  unreadMessagesField: NOTIFICATION_NOT_READ_TYPES;
  cartType: NOTIFICATION_TYPES[];
}
