import queryString from 'query-string';

import { ISensorData } from 'interfaces/sensor/i-sensor-data';
import { TSensorRenderData } from 'interfaces/sensor/t-sensor-render-data';

import { sensorDTO } from 'utils/dto/sensor';
import server from 'utils/server';

/**
 * Retrieves the sensor list request for a given vehicle ID.
 *
 * @param {string} vehicleId - The vehicle ID to retrieve sensor list for.
 * @returns {Promise<TSensorRenderData[]>} - A promise that resolves to an array of sensor render data.
 */
export const getSensorListReq = async (vehicleId: string): Promise<TSensorRenderData[]> => {
  const params = {
    vehicleId,
  };

  return server.services.beService
    .get<ISensorData[]>('sensors?' + queryString.stringify(params))
    .then<TSensorRenderData[]>(({ data }) => {
      return data.map((sensor) => sensorDTO.get(sensor));
    });
};
