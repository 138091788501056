import cx from 'classnames';
import React from 'react';

import { FCC } from 'interfaces/common/fc-with-children';

import useStyles from './styles';

interface ISection {
  title?: string;
  contentClass?: string;
}

type Props = ISection;

const Section: FCC<Props> = ({ title, contentClass, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrap}>
      <div className={classes.title}>{title}</div>
      <div className={cx(classes.content, contentClass)}>{children}</div>
    </div>
  );
};

export default Section;
