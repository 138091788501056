import { IVehicleBrand } from 'interfaces/vehicles/brand';

import server from 'utils/server';

/**
 * Creates a new vehicle brand request.
 *
 * @async
 * @param {string} value - The name of the vehicle brand.
 * @returns {Promise<IVehicleBrand>} - A Promise that resolves with the created vehicle brand.
 */
export const createVehicleBrandReq = async (value: string): Promise<IVehicleBrand> => {
  const body = {
    name: value,
  };

  return server.services.beService.post<IVehicleBrand>('brands/', body).then((res) => res.data);
};
