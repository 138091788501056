import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ISettingPaymentCompany } from 'interfaces/company/setting-payment-company';

import Loader from 'components/Loader';

import DaysLeft from '../../components/daysLeft';
import { Money } from '../../components/money';

import useStyles from './styles';

interface IProps {
  data: ISettingPaymentCompany | null;
}

const CurrentCompany: FC<IProps> = ({ data }) => {
  const classes = useStyles();
  const { t } = useTranslation('payment.page');

  const statistics = useMemo(() => {
    if (data) {
      return [
        {
          title: t('company.next.payment.label'),
          value: <Money count={data.nextPayment} withOutColor />,
          testId: 'nextPayment',
        },
        {
          title: t('company.days.estivation.label'),
          value: <DaysLeft days={data.daysLeftEstimation} />,
          testId: 'daysLeftEstimation',
        },
        {
          title: t('company.due.date.label'),
          value: data.dueDate,
          testId: 'dueDate',
        },
        {
          title: t('company.balance.label'),
          value: data.balance ? <Money count={Number(data.balance.toFixed(2))} /> : '-',
          testId: 'balance',
        },
      ];
    }

    return [];
  }, [data, t]);

  return (
    <div className={classes.wrap} data-testid='current-company-wrap'>
      {!data && <Loader width={50} isBlock lightMode />}

      {data && (
        <>
          <div className={classes.companyName} data-testid='company-name'>
            {data.companyName}
          </div>

          <div className={classes.statistics}>
            {statistics.map(({ title, value, testId }) => {
              return (
                <div key={title} className={classes.item} data-testid={testId}>
                  <div className={classes.title}>{title}</div>
                  <div className={classes.value}>{value}</div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default CurrentCompany;
