import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { isAxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import {
  SetterOrUpdater,
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValueLoadable,
} from 'recoil';

import { useCallbackPrompt } from 'hooks/useCallbackPromt';

import { IFileWithPreview } from 'interfaces/common/file-with-preview';
import { IUserData } from 'interfaces/user/user-data';

import { MEASUREMENT } from 'constants/measure-unit';
import { LANGUAGES, ROLES_OBJECT } from 'constants/user';

import { ReactComponent as UploadIcon } from 'assets/images/up-load.svg';

import RouteManager from 'utils/services/route-manager';

import subcompaniesReqGetAtom from 'recoil/subcompanies/request';
import subcompaniesSelectListAtom from 'recoil/subcompanies/select-list';
import userDataAtom from 'recoil/userData';

import { userDataReq } from 'requests/auth';
import {
  createUserReq,
  TUserCreateData,
  TUserData,
} from 'requests/be-service/system-user-controller/create-user';
import { getUserReq } from 'requests/be-service/system-user-controller/get-user-details';
import { changeUserPasswordReq } from 'requests/be-service/system-user-controller/update-password';
import { editUserReq } from 'requests/be-service/system-user-controller/update-user';
import { editUserAvatarReq } from 'requests/be-service/system-user-controller/upload-photo';

import Button from 'components/form-v2/button';
import Checkbox from 'components/form-v2/checkbox';
import Input from 'components/form-v2/input';
import Select from 'components/form-v2/select';
import Loader from 'components/Loader';
import Modal from 'components/modal';
import NewAvatar from 'components/NewAvatar';
import UnsavedModal from 'components/unsaved-modal';

import { errorHandler } from './error-handler';
import { defaultValues, formSchema } from './form-schema';
import useStyles from './styles';

const UserForm = () => {
  const classes = useStyles();
  const { t } = useTranslation(['setting.user.page', 'errors', 'fields']);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [avatar, setAvatar] = useState<IFileWithPreview | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useRecoilState(userDataAtom) as [
    IUserData,
    SetterOrUpdater<IUserData>,
  ];
  const subcompaniesSelectList = useRecoilValueLoadable(subcompaniesSelectListAtom);
  const refreshSubcompaniesList = useRecoilRefresher_UNSTABLE(subcompaniesReqGetAtom);

  const isTechAdmin = userData.role === ROLES_OBJECT.TECH_ADMIN;
  const isSubcompanyView = Boolean(sessionStorage.getItem('subcompanyId'));

  const onDrop = useCallback<NonNullable<DropzoneOptions['onDrop']>>((acceptedFiles) => {
    setAvatar(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      )[0],
    );
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'image/*',
    maxSize: 7000000,
    onDropRejected: () => {
      enqueueSnackbar('Something Went Wrong', { variant: 'error' });
    },
  });

  const formMethods = useForm<TUserCreateData | TUserData>({
    defaultValues,
    resolver: yupResolver(formSchema(id)),
  });

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    formMethods.formState.isDirty || Boolean(avatar),
  );

  const handleInitUser = useCallback(async () => {
    if (!id) {
      return;
    }
    try {
      setIsLoading(true);
      const response = await getUserReq(id);

      formMethods.reset(response);
    } catch {
      enqueueSnackbar(t('errors:something.wrong.label'), { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  }, [enqueueSnackbar, formMethods, id, t]);

  useEffect(() => {
    refreshSubcompaniesList();
    id && handleInitUser();
  }, [handleInitUser, id, refreshSubcompaniesList]);

  const handleCreateUser = useCallback(
    async (values: TUserCreateData) => {
      setIsLoading(true);
      try {
        const response = await createUserReq(values);
        avatar && (await editUserAvatarReq(response.id, avatar));
        setAvatar(null);
        formMethods.reset(values);
        enqueueSnackbar(t('fields:save.label'), { variant: 'success' });
        navigate(RouteManager.makeURL('settings.users.edit', { id: response.id }));
      } catch (error) {
        if (isAxiosError(error)) {
          const errorMessage = error.response!.data.error;
          errorHandler(formMethods.setError, enqueueSnackbar, t, errorMessage);
          return;
        }
        enqueueSnackbar(t('errors:something.wrong.label'), { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
    [avatar, enqueueSnackbar, formMethods, navigate, t],
  );

  const handleEditUser = useCallback(
    async (values: TUserData) => {
      setIsLoading(true);
      try {
        const response = await editUserReq(values);
        avatar && (await editUserAvatarReq(values.id, avatar));
        formMethods.reset(response);
        setAvatar(null);
        await handleInitUser();
        const userDataResponse = await userDataReq();
        setUserData(userDataResponse);
        enqueueSnackbar(t('fields:save.label'), { variant: 'success' });
      } catch (error) {
        if (isAxiosError(error)) {
          const errorMessage = error.response!.data.error;
          errorHandler(formMethods.setError, enqueueSnackbar, t, errorMessage);
          return;
        }
        enqueueSnackbar(t('errors:something.wrong.label'), { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
    [avatar, enqueueSnackbar, formMethods, handleInitUser, setUserData, t],
  );

  const handleChangeUserPassword = useCallback(async () => {
    if (!id) return;

    try {
      setIsLoading(true);
      const { password } = formMethods.getValues();

      if (!password) {
        formMethods.setError('password', { type: 'required', message: 'Required' });
        return;
      }

      await changeUserPasswordReq(id, password);
      formMethods.reset({
        ...formMethods.getValues(),
        password: '',
      });
      enqueueSnackbar('Saved', { variant: 'success' });
    } catch {
      enqueueSnackbar('Something Went Wrong', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  }, [enqueueSnackbar, formMethods, id]);

  const { photoUrl } = formMethods.getValues() as TUserData;

  const ROLES_FORMAT = Object.keys(ROLES_OBJECT).map((item) => ({
    label: item,
    value: item,
    disabled: ROLES_OBJECT.TECH_ADMIN === item && !isTechAdmin,
  }));

  if (
    ![ROLES_OBJECT.TECH_ADMIN, ROLES_OBJECT.ADMIN, ROLES_OBJECT.OWNER].includes(userData.role) ||
    (isSubcompanyView && !id)
  ) {
    return <Navigate to={RouteManager.makeURL('scoring')} />;
  }

  if (isLoading) {
    return <Loader width={150} lightMode preventClick />;
  }

  return (
    <div className={classes.wrap}>
      <div className={classes.avatar} {...getRootProps()} data-testid='user-logo'>
        <input {...getInputProps()} />
        <div className={classes.dropzone}>
          {isDragActive && (
            <div className={classes.dropzoneActive}>
              <UploadIcon />
            </div>
          )}
          {(avatar || photoUrl) && (
            <div
              className={classes.dropzoneAvatar}
              style={{ backgroundImage: `url(${avatar?.preview || photoUrl})` }}
            />
          )}
        </div>
        {photoUrl ? '' : <NewAvatar />}
      </div>
      <FormProvider {...formMethods}>
        <form
          className={classes.form}
          onSubmit={formMethods.handleSubmit((values) => {
            // TODO: need refactor types
            id ? handleEditUser(values as TUserData) : handleCreateUser(values as TUserCreateData);
          })}
        >
          <div className={classes.container}>
            <Input name='firstName' title={t('first.name.label')} disabled={isSubcompanyView} />
            <Input name='lastName' title={t('last.name.label')} disabled={isSubcompanyView} />

            {[ROLES_OBJECT.TECH_ADMIN, ROLES_OBJECT.ADMIN, ROLES_OBJECT.OWNER].includes(
              userData.role,
            ) && (
              <Select
                name='companyId'
                title={t('company.label')}
                items={
                  Array.isArray(subcompaniesSelectList.contents)
                    ? subcompaniesSelectList.contents
                    : []
                }
                disabled={isSubcompanyView}
              />
            )}

            <Input name='userName' title={t('user.name.label')} disabled={isSubcompanyView} />
            <Input name='password' title={t('password.label')} disabled={isSubcompanyView} />

            {id && !isSubcompanyView && (
              <Button
                onClick={handleChangeUserPassword}
                className={classes.passwordBtn}
                testId='button-changePassword'
              >
                Change password
              </Button>
            )}
          </div>

          <div className={classes.separator} />

          <div className={classes.container}>
            <Checkbox
              value={formMethods.watch('enabled')}
              onChange={(value) => formMethods.setValue('enabled', value)}
              label={t('enabled.label')}
              disabled={isSubcompanyView}
              testId='checkbox-enabled'
            />
          </div>

          <div className={classes.container} style={{ marginTop: 25 }}>
            <Input name='email' title={t('email.label')} disabled={isSubcompanyView} />

            <Select
              name='language'
              title={t('language.label')}
              items={LANGUAGES}
              disabled={isSubcompanyView}
            />

            <Select
              name='role'
              title={t('role.label')}
              items={ROLES_FORMAT}
              disabled={isSubcompanyView}
            />

            <Select
              name='measurement'
              title={t('measurement.label')}
              items={MEASUREMENT}
              disabled={isSubcompanyView}
            />
          </div>

          <div className={classes.container}>
            <div className={classes.submit}>
              {!isSubcompanyView && (
                <Button type='submit' testId='button-submit'>
                  {t('save.label')}
                </Button>
              )}
            </div>
          </div>
        </form>
      </FormProvider>

      <Modal open={showPrompt} onClose={cancelNavigation}>
        <UnsavedModal cancelNavigation={cancelNavigation} confirmNavigation={confirmNavigation} />
      </Modal>
    </div>
  );
};

export default UserForm;
