import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '&:hover $pencilIcon': {
      display: 'unset',
    },
  },

  input: {
    padding: '10px 10px',
    color: theme.palette.primary.light,
    border: 'none',
    borderRadius: 3,
    flex: 1,
    outline: 'none',
    backgroundColor: palette.custom.softBlue,

    '&:disabled': {
      backgroundColor: 'transparent',
      border: 'none',
      color: theme.palette.primary.light,
    },
  },

  controls: {
    display: 'flex',
  },

  icon: {
    width: 20,
    margin: '0 10px',
    cursor: 'pointer',

    '& path': {
      fill: theme.palette.primary.light,
    },
  },

  pencilIcon: {
    display: 'none',
  },
}));

export default useStyles;
