import { atom } from 'recoil';

import { IScoringDriver } from 'interfaces/scoring/i-scoring-driver';

const scoringBestDriver = atom<IScoringDriver | null>({
  key: 'scoringBestDriverAtom',
  default: null,
});

export default scoringBestDriver;
