import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    position: 'relative',
    background: alpha(theme.palette.primary.dark, 0.08),
    padding: '20px 20px 50px 20px',
    borderRadius: 10,
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: 10,
    fontSize: 20,
    fontWeight: 500,
    color: palette.custom.blue500,
  },

  info: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 10,
  },

  sensorNumber: {
    textTransform: 'lowercase',

    '&::first-letter': {
      textTransform: 'capitalize',
    },
  },

  sensorName: {
    color: palette.custom.blue200,
  },

  parameter: {
    marginTop: 50,
    width: '100%',
  },

  parameterHeader: {
    padding: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'default',
    marginBottom: 40,

    '&.isMovement': {
      marginBottom: 20,
    },
  },

  parameterTitle: {
    fontSize: 24,
    fontWeight: 500,
    color: palette.custom.blue200,
    marginLeft: 20,
    marginRight: 20,
  },

  parameterSubtitle: {
    fontSize: 14,
    fontWeight: 400,
    color: palette.custom.blue300,
  },

  selectWrap: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 30,
    marginBottom: 40,
  },

  btn: {
    marginTop: 10,
  },
}));

export default useStyles;
