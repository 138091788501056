import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
  },

  root: {
    width: '100%',
    border: `1px solid ${palette.custom.blue900}`,
    borderRadius: 5,
    backgroundColor: palette.custom.blue900,
    color: palette.custom.blue300,

    '& > fieldset': {
      border: 'none',
    },
  },

  inputWrap: {
    width: '100%',
  },

  inputError: {
    border: `1px solid ${palette.custom.redSecondary}`,
  },

  inputDisabled: {
    color: palette.custom.inputDisabledText,
    textFillColor: `${palette.custom.blue200} !important`,
    backgroundColor: palette.custom.hoverBlue,
    border: `1px solid ${palette.custom.bluePrimary}`,
  },

  focused: {
    border: `1px solid ${palette.custom.blue800}`,
  },

  input: {
    padding: '7.5px 14px',
    border: 'none',

    '&::placeholder': {
      color: palette.custom.blue200,
      opacity: 0.4,
    },
  },

  icon: {
    '& path': {
      fill: palette.custom.blue800,
    },
  },

  paper: {
    '& .MuiPickersDay-dayWithMargin': {
      lineHeight: 'normal',
    },
  },
}));

export default useStyles;
