import { atom, selector } from 'recoil';

import settingPaymentVehiclesListPaginationSelector from '../pagination';

interface ISettingPaymentVehiclesListSearch {
  query: string;
}
export const initValues = { query: '' };

const settingPaymentVehiclesListSearchAtom = atom<ISettingPaymentVehiclesListSearch>({
  key: 'settingPaymentVehiclesListSearchAtom',
  default: initValues,
});

const settingPaymentVehiclesListSearchSelector = selector<ISettingPaymentVehiclesListSearch>({
  key: 'settingPaymentVehiclesListSearchSelector',
  get: ({ get }) => {
    return get(settingPaymentVehiclesListSearchAtom);
  },
  set: ({ set, reset }, newValue) => {
    reset(settingPaymentVehiclesListPaginationSelector);
    set(settingPaymentVehiclesListSearchAtom, newValue);
  },
});

export default settingPaymentVehiclesListSearchSelector;
