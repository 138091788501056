import cx from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { BUTTON_VARIANTS } from 'interfaces/common/button-variants';

import isOpenCreateTeamAtom from 'recoil/settings/teams/is-open-create-team';
import selectedAvailableMembersAtom from 'recoil/settings/teams/selected-available-members';
import selectedTeamAtom from 'recoil/settings/teams/selected-team';
import showAvailableMembersAtom from 'recoil/settings/teams/show-available-members';

import Header from '../../components/header';

import AvailableMembers from './components/available-members';
import TeamMembers from './components/team-members';
import TeamsList from './components/teams-list';
import useStyles from './styles';

const Teams = () => {
  const classes = useStyles();
  const { t } = useTranslation(['teams.page', 'setting.page']);
  const [showAvailableMembers, setShowAvailableMembers] = useRecoilState(showAvailableMembersAtom);
  const setIsOpenCreateTeam = useSetRecoilState(isOpenCreateTeamAtom);
  const selectedTeam = useRecoilValue(selectedTeamAtom);

  const resetSelectedTeam = useResetRecoilState(selectedTeamAtom);
  const resetSelectedAvailableMembers = useResetRecoilState(selectedAvailableMembersAtom);
  const resetShowAvailableMembersAtom = useResetRecoilState(showAvailableMembersAtom);

  const headerBtns = useMemo(() => {
    return [
      {
        label: t('add.members.label'),
        onClick: () => setShowAvailableMembers(true),
        variant: BUTTON_VARIANTS.FILL,
        hide: !selectedTeam || showAvailableMembers,
      },
      {
        label: t('create.team.label'),
        onClick: () => setIsOpenCreateTeam(true),
        variant: BUTTON_VARIANTS.DEFAULT,
        hide: false,
      },
    ];
  }, [selectedTeam, setIsOpenCreateTeam, setShowAvailableMembers, showAvailableMembers, t]);

  /**
   * Clear data when leave page
   */
  useEffect(() => {
    return () => {
      resetSelectedTeam();
      resetSelectedAvailableMembers();
      resetShowAvailableMembersAtom();
    };
  }, [resetSelectedTeam, resetSelectedAvailableMembers, resetShowAvailableMembersAtom]);

  return (
    <div>
      <Header backBtn={{ label: t('setting.page:teams.label') }} toolBarBtns={headerBtns} />

      <div className={cx(classes.data, { showAvailableMembers })}>
        <TeamsList />

        <TeamMembers />

        {showAvailableMembers && <AvailableMembers />}
      </div>
    </div>
  );
};

export default Teams;
