import { atom } from 'recoil';

import recoilLocalStorageEffect from 'utils/recoil-localStorage-effect';

import { CALENDAR_DEFAULT_TYPES } from 'modules/drivers-vehicles/components/header/data';

const vehiclesSelectedPeriodAtom = atom<CALENDAR_DEFAULT_TYPES>({
  key: 'vehiclesSelectedPeriodAtom',
  default: CALENDAR_DEFAULT_TYPES.TODAY,
  effects_UNSTABLE: [
    recoilLocalStorageEffect('vehiclesSelectedPeriodAtom', CALENDAR_DEFAULT_TYPES.TODAY),
  ],
});

export default vehiclesSelectedPeriodAtom;
