import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    display: 'block',
    margin: `${theme.spacing(0)} !important`,
    padding: '6px 0 7px 0',
  },

  input: {
    width: '100%',
    margin: theme.spacing(0),
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '15.5px',
    textTransform: 'initial',
    color: theme.palette.primary.dark,
    border: ({ helperText }) =>
      `1px solid ${helperText ? palette.custom.errorRed : palette.custom.borderDarkGrey}`,
    borderRadius: '10px',
    fontFamily: 'SFCompact',

    '& fieldset': {
      border: 'none',
    },

    '& .MuiSelect-selectMenu': {
      padding: '9px 13px 10px 13px',
      height: '13px',
      minHeight: 'initial',
    },

    '&.Mui-disabled': {
      color: palette.custom.borderDark,
    },

    '& input': {
      padding: '59px 13px 10px 13px',
    },
  },

  select: {
    padding: '6.5px 14px',
  },

  inputModal: {
    border: ({ helperText }) =>
      `1px solid ${helperText ? palette.custom.errorRed : palette.custom.lightBlue}`,

    '& .MuiSelect-selectMenu': {
      padding: '10px 6px 11px 7px',
      width: '160px',
      height: '20px',
      minHeight: 'initial',
    },
  },

  label: {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '15.5px',
    color: theme.palette.primary.dark,
    transform: 'translate(0, 7px) scale(1)',

    '&.Mui-focused': {
      display: 'none',
    },
    '&.MuiFormLabel-filled': {
      display: 'none',
    },
  },

  buttonIcon: {
    padding: 0,
  },

  icon: {
    color: palette.custom.gray300,
  },

  textError: {
    position: 'absolute',
    top: '0px',
    right: 0,
    color: `${palette.custom.errorRed} !important`,
    fontSize: '13px',
    fontWeight: 300,
    lineHeight: '13px',

    '&.Mui-focused': {
      color: palette.custom.errorRed,
    },
  },

  outlined: {
    position: 'relative',
    width: '100%',
    paddingTop: '25px',
  },

  outlinedLabel: {
    position: 'absolute',
    top: 0,
    left: 0,
    color: theme.palette.primary.main,
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
}));

export default useStyles;
