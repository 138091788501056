import { IPolygonData } from 'interfaces/common/polygon-data';

import server from 'utils/server';

/**
 * Uploads the speed polygons data file to the server.
 *
 * @param {File} file - The speed polygons data file to upload.
 * @returns {Promise<IPolygonData[]>} - A Promise that resolves to an array of polygon data objects.
 */
export const uploadSpeedPolygonsReq = async (file: File): Promise<IPolygonData[]> => {
  const body = new FormData();

  body.append('file', file);

  return server.services.gpsService
    .post<IPolygonData[]>('polygons/import', body)
    .then((res) => res.data);
};
