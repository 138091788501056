import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import settingVehiclesShowDisableModalAtom from 'recoil/settings/vehicles/disable/show-modal';
import settingQuickEditVehicleInfoAtom from 'recoil/settings/vehicles/disable/vehicle-info';
import settingVehiclesFilterAtom from 'recoil/settings/vehicles/filters';
import settingVehiclesShowQuickEditAtom from 'recoil/settings/vehicles/show-quick-edit';

import { disableVehicleReq } from 'requests/be-service/vehicle-controller/disable-vehicle';

import Checkbox from 'components/form-v2/checkbox-with-controller';
import Input from 'components/form-v2/input';
import Loader from 'components/Loader';
import ModalActions from 'components/modal-actions';

import { formSchema, TDisableVehicleForm } from './form-schema';
import useStyles from './styles';

interface IProps {}

const DisableModal: FC<IProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation('fields');

  const vehicleInfo = useRecoilValue(settingQuickEditVehicleInfoAtom);
  const [isLoading, setIsLoading] = useState(false);
  const setShowDisableModal = useSetRecoilState(settingVehiclesShowDisableModalAtom);
  const setShowQuickEditVehicle = useSetRecoilState(settingVehiclesShowQuickEditAtom);
  const setFiltersData = useSetRecoilState(settingVehiclesFilterAtom);

  const formMethods = useForm<TDisableVehicleForm>({
    resolver: yupResolver(formSchema()),
    defaultValues: {
      id: vehicleInfo!.generalInfo.id!,
      includeDriverVehicle: false,
      active: !vehicleInfo!.generalInfo.active,
      comment: '',
    },
  });

  /**
   * Enable or disable vehicle
   *
   * @param {TDisableVehicleForm} data - The data to be saved.
   */
  const onSave = useCallback(
    async (data: TDisableVehicleForm) => {
      try {
        setIsLoading(true);

        await disableVehicleReq(data);

        setIsLoading(false);
        setShowDisableModal(false);
        setShowQuickEditVehicle(false);

        setFiltersData((prevState) => ({
          ...prevState,
        }));
      } catch (e) {
        console.log(e);
      }
    },
    [setFiltersData, setShowDisableModal, setShowQuickEditVehicle],
  );

  return (
    <FormProvider {...formMethods}>
      {isLoading && <Loader width={100} isBlock preventClick lightMode />}

      <div className={classes.title}>
        {t(`${vehicleInfo?.generalInfo.active ? 'disable' : 'enable'}.vehicle.title.label`, {
          plateNumber: vehicleInfo?.generalInfo.plateNumber,
        })}
      </div>

      {vehicleInfo!.generalInfo.active && (
        <Checkbox
          wrapClass={classes.checkbox}
          name={'includeDriverVehicle'}
          testId={'includeDriverVehicle'}
          label={t('disable.assign.driver.label')}
        />
      )}

      <Input name={'comment'} type='text' multiline rows={3} title={t('comment.label')} />

      <ModalActions
        handleClose={() => setShowDisableModal(false)}
        handleSave={formMethods.handleSubmit(onSave)}
      />
    </FormProvider>
  );
};

export default DisableModal;
