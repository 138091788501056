import cx from 'classnames';
import React, { useRef, useState } from 'react';
import { useRecoilState } from 'recoil';

import { ReactComponent as SearchIcon } from 'assets/images/search.svg';

import navigationOptionsAtom from 'recoil/navigation';

import useStyles from '../company-item/styles';
import SearchModal from '../serach-modal';

const SearchItem = () => {
  const classes = useStyles();
  const [navigationState, setNavigationState] = useRecoilState(navigationOptionsAtom);
  const [searchContainerCoordinates, setSearchContainerCoordinates] = useState<DOMRect | null>(
    null,
  );

  const searchContainer = useRef<HTMLDivElement | null>(null);

  const handleSearchClick = () => {
    if (searchContainer.current) {
      setSearchContainerCoordinates(searchContainer.current.getBoundingClientRect());
    }
    setNavigationState((prevState) => ({
      ...prevState,
      isOpenSearch: true,
    }));
  };

  return (
    <>
      <div
        className={classes.wrap}
        onClick={handleSearchClick}
        ref={searchContainer}
        data-testid='search-container'
      >
        <div className={classes.line} />
        <div className={cx(classes.icon, classes.searchIcon)}>
          <SearchIcon />
        </div>
        <div className={classes.name}>Search</div>
      </div>

      {navigationState.isOpenSearch && searchContainerCoordinates && (
        <SearchModal anchorCoordinates={searchContainerCoordinates} />
      )}
    </>
  );
};

export default SearchItem;
