import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  avatar: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: palette.custom.lightBlue,
    cursor: 'auto',
    borderRadius: '50%',
  },

  circle: {
    backgroundColor: palette.custom.bluePrimary,
  },

  cursor: {
    cursor: 'pointer',
  },

  icon: {
    width: '100%',
  },

  iconFullscreen: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '100px',
  },

  default: {
    width: '45%',
  },
}));

export default useStyles;
