import { selectorFamily } from 'recoil';

import {
  ITripPenalties,
  getPenaltiesPointsReq,
} from 'requests/be-service/map-view-controller/get-trip-violations';

const penaltiesDataSelector = selectorFamily<ITripPenalties, string>({
  key: 'penaltiesDataSelector',
  get: (tripId) => async () => {
    return await getPenaltiesPointsReq(tripId);
  },
});

export default penaltiesDataSelector;
