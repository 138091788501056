import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import subMonths from 'date-fns/subMonths';
import queryString from 'query-string';

import { IScoringDriver } from 'interfaces/scoring/i-scoring-driver';

import { removingDuplicates } from 'utils/helpers';
import { convertToUtcTimestamp } from 'utils/helpers/convert-to-utc-timestamp';
import { hundredRangeFormat } from 'utils/helpers/hundred-range-format';
import server from 'utils/server';

/**
 * Retrieves the scoring rating of drivers within a given time frame.
 *
 * @async
 * @returns {Promise<IScoringDriver[]>} Promise that resolves to an array of scoring drivers.
 */
export const scoringRatingDriversReq = async (): Promise<IScoringDriver[]> => {
  const params = {
    startAt: convertToUtcTimestamp(subMonths(startOfDay(new Date()), 1).getTime()),
    finishAt: convertToUtcTimestamp(endOfDay(new Date()).getTime()),
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
  };

  return server.services.beService
    .get<IScoringDriver[]>('scoring/drivers/rating/chart?' + queryString.stringify(params))
    .then<IScoringDriver[]>(({ data }) =>
      data.map((item) => ({
        ...item,
        penaltyCards: removingDuplicates(item.penaltyCards),
        currentScoring: hundredRangeFormat(Math.round(item.currentScoring)),
        diffScoring: hundredRangeFormat(Math.round(item.diffScoring)),
        brandName: item?.brandName || '---',
        country: item?.country || 'AZ',
        plateNumber: item?.plateNumber || '----',
        type: item?.type || 'TRACK',
      })),
    );
};
