import { BBox, lineString, bbox } from '@turf/turf';
import { selectorFamily } from 'recoil';

import tripRouteSelector from '../route';

const mapDefaultBoundsSelector = selectorFamily<BBox, string>({
  key: 'mapDefaultBoundsSelector',
  get:
    (tripId) =>
    ({ get }) => {
      const tripRoute = get(tripRouteSelector(tripId));

      const line = lineString(tripRoute.map(({ lng, lat }) => [lng, lat]));
      return bbox(line);
    },
});

export default mapDefaultBoundsSelector;
