import { atom, selector } from 'recoil';

import settingPaymentSubCompaniesListPaginationSelector from '../pagination';

interface ISettingPaymentSubCompaniesListSearch {
  query: string;
}
export const initValues = { query: '' };

const settingPaymentSubCompaniesListSearchAtom = atom<ISettingPaymentSubCompaniesListSearch>({
  key: 'settingPaymentSubCompaniesListSearchAtom',
  default: initValues,
});

const settingPaymentSubCompaniesListSearchSelector =
  selector<ISettingPaymentSubCompaniesListSearch>({
    key: 'settingPaymentSubCompaniesListSearchSelector',
    get: ({ get }) => {
      return get(settingPaymentSubCompaniesListSearchAtom);
    },
    set: ({ set, reset }, newValue) => {
      reset(settingPaymentSubCompaniesListPaginationSelector);
      set(settingPaymentSubCompaniesListSearchAtom, newValue);
    },
  });

export default settingPaymentSubCompaniesListSearchSelector;
