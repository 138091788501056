/**
 * Generates a regular expression based on the provided URL pattern.
 *
 * @param {string} urlPattern - The URL pattern to generate a regular expression for.
 * @returns {RegExp} The generated regular expression.
 */
export const getRouteRegex = (urlPattern: string): RegExp => {
  const pattern = urlPattern.replace(/:\w+/g, '([^/]+)');
  return new RegExp(`^${pattern}$`);
};
