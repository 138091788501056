import { L100KM_IN_MPG, LBS_IN_KG, KM_IN_MILE, LITER_IN_GALLON } from 'constants/measure-unit';

type ConverterFunc = (value: number) => number;

const createConverter =
  (multiplier: number, convertToMultiplier: boolean = true): ConverterFunc =>
  (value: number) =>
    Number(convertToMultiplier ? value * multiplier : value / multiplier);

export const valueConverter = {
  distance: {
    toImperial: createConverter(KM_IN_MILE, false),
    toMetric: createConverter(KM_IN_MILE),
  },
  volume: {
    toImperial: createConverter(LITER_IN_GALLON, false),
    toMetric: createConverter(LITER_IN_GALLON),
  },
  weight: {
    toImperial: createConverter(LBS_IN_KG),
    toMetric: createConverter(LBS_IN_KG, false),
  },
  consumption: {
    toImperial: (value: number) => Number(L100KM_IN_MPG / value),
    toMetric: (value: number) => Number(L100KM_IN_MPG / value),
  },
  temperature: {
    celsiusToFahrenheit: (value: number) => Math.round((value * 9) / 5 + 32),
    fahrenheitToCelsius: (value: number) => Math.round(((value - 32) * 5) / 9),
  },
};
