import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    width: '100%',
    backgroundColor: palette.custom.hoverBlue,
    padding: 30,
  },

  title: {
    marginBottom: 20,
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '29px',
    color: palette.custom.blue200,

    '&:empty': {
      display: 'none',
    },
  },

  content: {
    display: 'grid',
    gridTemplateColumns: 'calc(50% - 15px) calc(50% - 15px)',
    columnGap: 30,
    rowGap: 20,

    ['@media (max-width:768px)']: {
      gridTemplateColumns: '1fr',
      columnGap: 0,
    },
  },
}));

export default useStyles;
