import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IPolygonData } from 'interfaces/common/polygon-data';

import useStyles from './styles';

interface IProps {
  polygons?: IPolygonData[];
}

const PolygonsTable: FC<IProps> = ({ polygons }) => {
  const classes = useStyles();
  const { t } = useTranslation('setting.speed.management.page');

  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>{t('name.label')}</TableCell>
            <TableCell align='center'>{t('speed.limit.label')}</TableCell>
            <TableCell align='center'>{t('intervals.label')}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {polygons?.map(({ name, intervals, speedLimit }, i) => (
            <TableRow className={classes.row} key={`${name}-${intervals}-${speedLimit}-${i}`}>
              <TableCell data-testid='name'>{name}</TableCell>
              <TableCell align='center' data-testid='speedLimit'>
                {speedLimit}
              </TableCell>
              <TableCell align='center' data-testid='intervals'>
                {intervals}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PolygonsTable;
