import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    padding: '34px 21px 30px 21px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8.42105,
  },

  arrow: {
    cursor: 'pointer',
    position: 'absolute',
    top: 15,
    right: 7,
    zIndex: '1',
  },

  trip: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '474px',
  },

  tripIcon: {
    width: 19,
    margin: '2px 15px',
  },

  point: {
    fontFamily: 'SFText',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 21.2035,
    lineHeight: '25px',
    letterSpacing: '-1.32522px',
    color: theme.palette.primary.main,
  },

  endPoint: {
    marginTop: 16,
  },

  name: {
    overflow: 'hidden',
    maxWidth: 271,
    whiteSpace: 'pre',
    textOverflow: 'ellipsis',
  },

  time: {
    fontFamily: 'SFPro',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 19.8783,
    lineHeight: '24px',
    color: theme.palette.primary.dark,
  },

  chartWrap: {
    marginLeft: 27,
    position: 'relative',
    width: 120,
    height: 120,
  },

  counterLabel: {
    position: 'absolute',
    top: '24%',
    left: '8%',
    fontSize: '30.22px',
    lineHeight: '65px',
    fontWeight: '500',
    width: '100px',
    textAlign: 'center',
  },
  chart: {
    marginTop: '-12px',
  },

  chartMin: {
    transform: 'rotateY(180deg)',
  },

  counterMin: {
    fontSize: '25.22px',
  },
}));

export default useStyles;
