import queryString from 'query-string';

import { COUNTRIES } from 'interfaces/common/countries';
import { ISelectItem } from 'interfaces/common/select-items';
import { DEVICE_FUNCTION } from 'interfaces/vehicles/device-functions';
import { VEHICLE_TYPES } from 'interfaces/vehicles/types';

import server from 'utils/server';

interface IVehicleSelectListItem {
  id: string;
  vehicleId: string;
  plateNumber: string;
  engine: string;
  vin: string;
  creationTime: number;
  country: COUNTRIES;
  vehicleType: VEHICLE_TYPES;
  deviceFunctions: DEVICE_FUNCTION[];
}

/**
 * Fetches all vehicles for a given company.
 *
 * @param {string} companyId - The ID of the company.
 * @returns {Promise<ISelectItem[]>} - A promise that resolves to an array of formatted vehicle select list items.
 */
export const vehiclesAllReq = async (companyId: string): Promise<ISelectItem[]> => {
  const params = {
    companyId,
  };

  return server.services.beService
    .get<IVehicleSelectListItem[]>('vehicles/all?' + queryString.stringify(params))
    .then<ISelectItem[]>(({ data }) => {
      return data.map((item) => ({
        label: item.plateNumber,
        value: item.id,
      }));
    });
};
