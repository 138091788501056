import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    display: 'grid',
    padding: 80,
    height: '100%',
    gap: 24,

    '&.TECH_ADMIN': {
      gridTemplateRows: '1fr 1fr 1fr 1fr 1.55fr',
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
      gridTemplateAreas: `
        "drivers drivers teams teams vehicleBrand vehicleBrand"
        "drivers drivers teams teams fuelPrice fuelPrice"
        "vehicles vehicles payment payment speedManagement speedManagement"
        "vehicles vehicles payment payment language language"
        "companies companies companies users users users"
      `,
    },

    '&.ADMIN, &.OWNER': {
      gridTemplateRows: '1fr 1fr 1fr',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `
        "drivers teams"
        "vehicles payment"
        "companies users"
      `,
    },

    '&.MANAGER': {
      gridTemplateRows: '1fr 1fr',
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `
        "drivers"
        "vehicles"
      `,
    },
  },

  title: {
    fontSize: 24,
    fontWeight: 500,
    color: palette.custom.blue200,
    lineHeight: '29px',
  },

  item: {
    position: 'relative',
    backgroundColor: alpha(theme.palette.primary.dark, 0.08),
    borderRadius: 10,
    padding: 20,
    cursor: 'pointer',
    transition: 'all ease 0.3s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    rowGap: 10,

    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.1),

      '& $link': {
        opacity: 1,
        pointerEvents: 'unset',
      },
    },
  },

  link: {
    padding: 13,
    borderRadius: 10,
    backgroundColor: alpha(theme.palette.primary.dark, 0.1),
    fontSize: 16,
    fontWeight: 500,
    color: palette.custom.goldSecondary,
    textDecoration: 'none',
    cursor: 'pointer',
    position: 'absolute',
    bottom: 10,
    right: 20,
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity ease 0.3s',
  },
}));

export default useStyles;
