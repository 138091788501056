import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    minHeight: 75,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    columnGap: 30,
    justifyContent: 'space-between',
    padding: '15px 65px',
  },

  companyName: {
    color: palette.custom.blue200,
    fontSize: 32,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  statistics: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 15,
  },

  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 5,
    paddingInline: 15,
  },

  title: {
    color: theme.palette.primary.dark,
    fontSize: 16,
    fontWeight: 500,
  },

  value: {
    color: palette.custom.blue200,
    fontSize: 28,
    fontWeight: 500,
  },
}));

export default useStyles;
