import React, { FC } from 'react';
import { useRecoilValue } from 'recoil';

import { VEHICLES_ACTIVE_STATUSES } from 'constants/vehicle';

import liveTrackingPathAtom from 'recoil/live-tracking/path';
import liveTrackingStatisticAtom from 'recoil/live-tracking/statistic';

import Path from '../Path';
import Penalties from '../Penalties';
import ScoringBox from '../ScoringBox';
import SpeedBox from '../SpeedBox';
import Statistic from '../Statistic';

import useStyles from './styles';

interface IProps {
  isFinishTrip: boolean;
}

const TripInfo: FC<IProps> = ({ isFinishTrip }) => {
  const classes = useStyles();
  const path = useRecoilValue(liveTrackingPathAtom);
  const statistic = useRecoilValue(liveTrackingStatisticAtom);

  return (
    <div className={classes.wrap} data-testid='trip-info-wrap'>
      <Path
        status={path?.status}
        startTime={path?.startTime}
        startAddress={path?.startAddress}
        finishAddress={path?.finishAddress}
        finishTime={path?.finishTime}
      />

      {path.status === VEHICLES_ACTIVE_STATUSES.ACTIVE && !isFinishTrip && (
        <SpeedBox speed={path.speed} />
      )}

      <Statistic
        status={path?.status}
        driveTime={statistic?.driveTime}
        driveDistance={statistic?.driveDistance}
        idlingTime={statistic?.idlingTime}
        parkingTime={statistic?.parkingTime}
      />

      {isFinishTrip && <Penalties penalties={statistic.penalties} hasCrash={statistic.hasCrash} />}
      {isFinishTrip && statistic.totalScore && (
        <ScoringBox scoring={statistic.totalScore} align='center' />
      )}
    </div>
  );
};

export default TripInfo;
