import cx from 'classnames';
import React, { FC, useState } from 'react';

import Input from '../input';

import useStyles from './styles';

interface IProps {
  handleApprove: (value: string) => void;
  title?: string;
}

const AddNewField: FC<IProps> = ({ handleApprove, title }) => {
  const classes = useStyles();

  const [isActive, setIsActive] = useState(false);

  return (
    <>
      {!isActive && (
        <div
          data-testid='button-add'
          className={cx(classes.addBtn, classes.wrap)}
          onClick={(e) => {
            e.stopPropagation();
            setIsActive(true);
          }}
        >
          {title}
        </div>
      )}

      {isActive && (
        <div className={classes.wrap}>
          <Input
            initValue={''}
            handlerApprove={handleApprove}
            rejectHandler={() => setIsActive(false)}
          />
        </div>
      )}
    </>
  );
};

export default AddNewField;
