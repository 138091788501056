import * as turf from '@turf/turf';
import cx from 'classnames';
import GoogleMapReact, { fitBounds } from 'google-map-react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import useSupercluster from 'use-supercluster';

import { mapDefaultSetting } from 'constants/map';

import { ReactComponent as ArrowIcon } from 'assets/images/arrow-simple.svg';

import { createClusterVehicle } from 'utils/helpers';
import RouteManager from 'utils/services/route-manager';

import dashboardSidebarIsAllSelectedAtom from 'recoil/dashboard-map/sidebar/is-all-selected';
import isOpenDashboardSidebarAtom from 'recoil/dashboard-map/sidebar/is-open';
import dashboardSidebarSelectedItemsAtom from 'recoil/dashboard-map/sidebar/selected-items';
import dashboardMapFiltersAtom from 'recoil/dashboard-map/toolbar/fitters';
import dashboardMapVehiclesAtom from 'recoil/dashboard-map/vehicles';
import countryMapDefaultSettingAtom from 'recoil/map-default-setting';
import navigationOptionsAtom from 'recoil/navigation';
import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';
import userConfigAtom from 'recoil/user-config';

import { mapGetAllVehiclesBySidebarReq } from 'requests/be-service/map-view-v2-controller/view';
import { mapGetAllVehiclesReq } from 'requests/mobile-service/map-get-all-vehicles';

import Loader from 'components/Loader';
import BackButton from 'components/Map/BackButton';
import ClusterMarker from 'components/Map/ClusterMarker';
import ClusterModal from 'components/Map/ClusterModal';
import Layer from 'components/Map/Layer';
import Toolbar from 'components/Map/Toolbar';
import VehicleMarker from 'components/Map/VehicleMarker';

import Sidebar from './sidebar';
import useStyles from './styles';

const Map = () => {
  const technicalMessageBlockHeight = useRecoilValue(technicalMessageBlockHeightSelector);
  const classes = useStyles({ technicalMessageBlockHeight });
  const { t } = useTranslation('dashboard.map.page');
  const location = useLocation();

  // need for cancel requests
  const controllerRef = useRef(new AbortController());

  const { contents: countryMapDefaultSetting } = useRecoilValueLoadable(
    countryMapDefaultSettingAtom,
  );
  const userConfig = useRecoilValue(userConfigAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [isSatellite, setIsSatellite] = useState(false);
  const [filters, setFilters] = useRecoilState(dashboardMapFiltersAtom);
  const [vehiclesData, setVehiclesData] = useRecoilState(dashboardMapVehiclesAtom);
  const [clusterBounds, setClusterBounds] = useState([]);
  const [clusterZoom, setClusterZoom] = useState(mapDefaultSetting.zoom);
  const [activeCluster, setActiveCluster] = useState(null);
  const { isOpenNav } = useRecoilValue(navigationOptionsAtom);
  const [mapCenter, setMapCenter] = useState(
    location.state?.center ?? countryMapDefaultSetting.center,
  );
  const [mapZoom, setMapZoom] = useState(location.state?.zoom ?? countryMapDefaultSetting.zoom);
  const [isOpenDashboardSidebar, setIsOpenDashboardSidebar] = useRecoilState(
    isOpenDashboardSidebarAtom,
  );
  const [mapSize, setMapSize] = useState({ width: 0, height: 0 });

  const [sidebarVehicles, setSidebarVehicles] = useState(null);

  const dashboardSidebarIsAllSelected = useRecoilValue(dashboardSidebarIsAllSelectedAtom);
  const dashboardSidebarSelectedItems = useRecoilValue(dashboardSidebarSelectedItemsAtom);

  const vehicleRequestRef = useRef(null);
  const mapRef = useRef(null);

  const vehicles = useMemo(() => vehiclesData.data ?? [], [vehiclesData.data]);

  const { clusters, supercluster } = useSupercluster({
    points: vehicles,
    bounds: clusterBounds,
    zoom: clusterZoom,
    options: { radius: 100, maxZoom: 20 },
  });

  /**
   * Get map client width and height
   */
  useEffect(() => {
    setMapSize({
      width: mapRef.current?.offsetWidth,
      height: mapRef.current?.offsetHeight,
    });
  }, []);

  /**
   * Calculate map zoom and center by selected vehicles in sidebar
   */
  useEffect(() => {
    if (sidebarVehicles) {
      if (sidebarVehicles.length === 1) {
        const coordinates = sidebarVehicles[0].viewData.point.gpsPoint;

        setMapZoom(18);
        setMapCenter({ lat: coordinates.latitude, lng: coordinates.longitude });
        return;
      }

      const line = turf.lineString(
        sidebarVehicles.map(({ viewData }) => [
          viewData.point.gpsPoint.longitude,
          viewData.point.gpsPoint.latitude,
        ]),
      );
      const bbox = turf.bbox(line);
      const bounds = {
        nw: {
          lng: bbox[0],
          lat: bbox[1],
        },
        se: {
          lng: bbox[2],
          lat: bbox[3],
        },
      };

      const { center, zoom } = fitBounds(bounds, mapSize);

      setMapZoom(zoom);
      setMapCenter(center);
    }
  }, [mapSize, sidebarVehicles]);

  /**
   * Recursive getting vehicles
   */
  const getVehiclesListFromApi = useCallback(async () => {
    try {
      if (!filters.topLeftLong) {
        return;
      }
      const { signal } = controllerRef.current;
      let res;
      if (dashboardSidebarIsAllSelected || dashboardSidebarSelectedItems.length > 0) {
        res = await mapGetAllVehiclesBySidebarReq(
          filters.status,
          dashboardSidebarIsAllSelected,
          dashboardSidebarSelectedItems,
          signal,
        );
        setSidebarVehicles((prev) => {
          if (prev) {
            return prev;
          }
          return res;
        });
      } else {
        res = await mapGetAllVehiclesReq(filters, signal);
      }
      const clusteredData =
        res?.vehicles?.map((item) => createClusterVehicle(item)) ??
        res?.map((item) => createClusterVehicle(item));

      setVehiclesData((prevState) => ({
        ...prevState,
        data: clusteredData,
      }));

      vehicleRequestRef.current = setTimeout(getVehiclesListFromApi, 5000);
    } catch (e) {
      if (e.name !== 'AbortError') {
        setVehiclesData((prevState) => ({
          ...prevState,
          error: e,
        }));
      }
    }
  }, [dashboardSidebarIsAllSelected, dashboardSidebarSelectedItems, filters, setVehiclesData]);

  const abortRecursiveRequest = useCallback(() => {
    controllerRef.current.abort();
    setVehiclesData((prevState) => ({
      ...prevState,
      data: null,
    }));
    vehicleRequestRef.current && clearTimeout(vehicleRequestRef.current);
  }, [setVehiclesData]);

  const openSidebar = useCallback(() => {
    controllerRef.current.abort();
    vehicleRequestRef.current && clearTimeout(vehicleRequestRef.current);
    setIsOpenDashboardSidebar(true);
  }, [setIsOpenDashboardSidebar]);

  const closeSidebar = useCallback(() => {
    controllerRef.current = new AbortController();
    setFilters((prev) => ({
      ...prev,
    }));
  }, [setFilters]);

  const clearMapZoomSettings = useCallback(() => {
    setMapZoom(null);
    setMapCenter(null);
    setSidebarVehicles(null);
  }, []);

  /**
   * Get all vehicles with interval
   */
  useEffect(() => {
    const req = async () => {
      if (location.pathname !== RouteManager.path('dashboardMap', { hasLeadingSlash: true })) {
        return;
      }

      try {
        setIsLoading(true);
        await getVehiclesListFromApi();
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };

    req();
  }, [location, getVehiclesListFromApi]);

  /**
   * When user open cluster modal - cancel current request and timeout (end recursive requests)
   * When use close cluster modal - set new AbortController for unblock request and set filters for starting recursive
   */
  useEffect(() => {
    if (activeCluster) {
      controllerRef.current.abort();
      vehicleRequestRef.current && clearTimeout(vehicleRequestRef.current);
    } else {
      controllerRef.current = new AbortController();
      setFilters((prev) => ({
        ...prev,
      }));
    }
  }, [activeCluster, setFilters]);

  /**
   * Cancel current request and timeout (end recursive requests) when page resize
   */
  useEffect(() => {
    window.addEventListener('resize', abortRecursiveRequest);

    return () => {
      abortRecursiveRequest();
      setFilters((prev) => {
        return {
          ...prev,
          bottomRightLat: null,
          bottomRightLong: null,
          topLeftLat: null,
          topLeftLong: null,
        };
      });
      window.removeEventListener('resize', abortRecursiveRequest);
    };
  }, [abortRecursiveRequest, setFilters]);

  /**
   * Array of vehicles in active cluster
   */
  const activeClusterData = useMemo(() => {
    if (!(supercluster && activeCluster)) {
      return null;
    }

    try {
      return supercluster.getLeaves(activeCluster?.id, 'Infinity').map((item) => item.properties);
    } catch (e) {
      return null;
    }
  }, [activeCluster, supercluster]);

  /**
   * Save map bounds
   * @type {(function(*): void)|*}
   */
  const mapChangedHandler = useCallback(
    (map) => {
      const {
        bounds: { nw, se },
        zoom,
      } = map;

      controllerRef.current = new AbortController();
      setFilters((prevState) => {
        return {
          ...prevState,
          bottomRightLat: se.lat,
          bottomRightLong: se.lng,
          topLeftLat: nw.lat,
          topLeftLong: nw.lng,
        };
      });
      setClusterBounds([nw.lng, se.lat, se.lng, nw.lat]);
      setClusterZoom(zoom);
    },
    [setFilters],
  );

  const toggleMapType = useCallback(() => setIsSatellite((prevState) => !prevState), []);

  const createMapOptions = () => ({
    fullscreenControl: false,
    mapTypeId: isSatellite ? 'hybrid' : 'roadmap',
    styles: [{ stylers: [{ visibility: 'on' }] }],
  });

  const mapBootstrapURLKeys = useMemo(() => {
    const config = {
      key: process.env.REACT_APP_GOOGLE_MAP_KEY,
      version: 'beta',
      libraries: ['marker'],
      mapIds: [process.env.REACT_APP_GOOGLE_MAP_ID],
    };

    if (userConfig.country === 'AZ') {
      config.region = 'AZ';
    }

    return config;
  }, [userConfig]);

  return (
    <div className={classes.container} ref={mapRef}>
      {isLoading && <Loader isBlock width={100} lightMode={isSatellite} />}

      <GoogleMapReact
        bootstrapURLKeys={mapBootstrapURLKeys}
        options={createMapOptions}
        defaultCenter={countryMapDefaultSetting.center}
        defaultZoom={countryMapDefaultSetting.zoom}
        center={mapCenter}
        zoom={mapZoom}
        yesIWantToUseGoogleMapApiInternals
        onChange={mapChangedHandler}
        onDrag={abortRecursiveRequest}
        onZoomAnimationStart={abortRecursiveRequest}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } = cluster.properties;

          if (isCluster) {
            return (
              <ClusterMarker
                key={`cluster-${cluster?.id}`}
                lat={latitude}
                lng={longitude}
                pointCount={pointCount}
                setActiveCluster={setActiveCluster.bind(null, cluster)}
                clusterId={cluster?.id}
                isActive={cluster?.id === activeCluster?.id}
              />
            );
          }

          return (
            <VehicleMarker
              lat={latitude}
              lng={longitude}
              key={cluster?.properties?.driver.id}
              angle={
                cluster?.properties?.position?.angle ??
                cluster?.properties?.viewData?.point?.angle ??
                0
              }
              plateNumber={cluster?.properties?.vehicle?.plateNumber}
              status={cluster?.properties?.status ?? cluster?.properties?.viewData?.status}
              driverName={
                cluster?.properties?.driver?.name ??
                `${cluster?.properties?.driver?.firstName} ${cluster?.properties?.driver?.lastName}`
              }
              vehicleId={cluster?.properties?.vehicle?.id}
            />
          );
        })}
      </GoogleMapReact>

      <Layer isSatellite={isSatellite} onClick={toggleMapType} wrapClass={classes.layer} />

      <Toolbar abortRecursiveRequest={abortRecursiveRequest} controllerRef={controllerRef} />

      <BackButton
        isOpenNav={isOpenNav}
        href={RouteManager.path('scoring', { hasLeadingSlash: true })}
        label={t('dashboard.label')}
      />

      {!isOpenDashboardSidebar && (
        <div
          className={cx(classes.btn, { isOpen: isOpenDashboardSidebar })}
          onClick={openSidebar}
          data-testdid='button-sidebar'
        >
          <ArrowIcon className={classes.arrow} />
        </div>
      )}

      <ClusterModal activeClusterData={activeClusterData} onClose={() => setActiveCluster(null)} />

      <Sidebar onClose={closeSidebar} clearMapZoomSettings={clearMapZoomSettings} />
    </div>
  );
};

Map.propTypes = {
  id: PropTypes.string,
};

export default Map;
