import queryString from 'query-string';

import { IDriverData } from 'interfaces/driver/i-driver-data';
import { IVehicleData } from 'interfaces/vehicles/i-vehicle-data';

import server from 'utils/server';

interface IDetailsInfo {
  driverInfo: Pick<
    IDriverData,
    | 'id'
    | 'firstName'
    | 'lastName'
    | 'phoneNumber'
    | 'phonePrefix'
    | 'sosPhoneNumber'
    | 'sosPhonePrefix'
  >;
  vehicleInfo: {
    id: IVehicleData['id'];
    vehicleType: IVehicleData['type'];
    plateNumber: IVehicleData['plateNumber'];
    brandName: IVehicleData['brand'];
    modelName: IVehicleData['model'];
    country: IVehicleData['country'];
    imei: IVehicleData['imei'];
    deviceFunctions: IVehicleData['deviceFunctions'];
  };
  scoring: number;
  diffScoring: number;
}

/**
 * Retrieves the tracking driver information for a given vehicle ID.
 * @async
 * @param {string} vehicleId - The ID of the vehicle.
 * @returns {Promise<IDetailsInfo>} - A promise that resolves with the tracking driver information.
 */
export const getTrackingDriverInfoReq = async (vehicleId: string): Promise<IDetailsInfo> => {
  const params = {
    vehicleId,
  };

  return server.services.beService
    .get<IDetailsInfo>('driver-vehicles/details-by-id?' + queryString.stringify(params))
    .then((res) => res.data);
};
