import { valueConverter } from 'utils/helpers/value-converter';

import { IDriverShortData } from 'requests/be-service/driver-controller/get-driver-general-info';

export const driverShortDataDTO = (
  data: IDriverShortData,
  isMetricSystem: boolean,
): IDriverShortData => {
  if (isMetricSystem) {
    return data;
  }

  return {
    ...data,
    dailyKm: data.dailyKm && valueConverter.distance.toImperial(data.dailyKm),
    totalKm: data.totalKm && valueConverter.distance.toImperial(data.totalKm),
    fuelUsed: data.fuelUsed && valueConverter.volume.toImperial(data.fuelUsed),
  };
};
