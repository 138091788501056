import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { TABLE_VIEW_TYPE } from 'interfaces/common/table-view-type';
import { TIME_DEGREE } from 'interfaces/common/time-degree';

import { ReactComponent as PencilIcon } from 'assets/images/pencil.svg';

import RouteManager from 'utils/services/route-manager';

import crashModalAtom from 'recoil/crash-modal';
import { CRASH_ANCHOR, CRASH_MODAL_TABS } from 'recoil/crash-modal/atom';

import { IVehiclesListItem } from 'requests/be-service/vehicle-controller/get-vehicles';
import { getVehicleCrashListReq } from 'requests/gps-service/crash-controller/get-vehicle-crashes';

import ActivityStatus from 'components/activity-statuses';
import Collision from 'components/Collision';
import PenaltyCard from 'components/penalty-card';
import PlateNumber from 'components/plate-number';
import ScoreValue from 'components/score-value';
import StatisticValue from 'components/statistic-value';
import Time from 'components/time';
import VehicleIcon from 'components/vehicle-icon';

import useStyles from './styles';

interface IProps {
  data: IVehiclesListItem;
  tableViewType: TABLE_VIEW_TYPE;
  editHandler: (data: IVehiclesListItem) => void;
}

const VehicleRow: FC<IProps> = ({ data, tableViewType, editHandler }) => {
  const classes = useStyles();
  const setCrashModal = useSetRecoilState(crashModalAtom);
  const navigate = useNavigate();

  const handleActivityStatusClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      navigate(RouteManager.makeURL('dashboardMap.live', { id: data?.id }));
    },
    [data?.id, navigate],
  );

  const handleCrashClick = useCallback(async () => {
    try {
      setCrashModal((prevState) => {
        return {
          ...prevState,
          isOpen: true,
          isFetching: true,
          anchor: CRASH_ANCHOR.VEHICLE,
          activeTab: CRASH_MODAL_TABS.DESCRIPTION,
        };
      });

      const response = await getVehicleCrashListReq(data.imei);

      setCrashModal((prevState) => {
        return {
          ...prevState,
          currentCrashIdNumber: response.length - 1,
          crashList: response,
        };
      });
    } catch (error) {
      setCrashModal((prevState) => {
        return {
          ...prevState,
          error: error,
        };
      });
    } finally {
      setCrashModal((prevState) => {
        return {
          ...prevState,
          isFetching: false,
        };
      });
    }
  }, [data.imei, setCrashModal]);

  return (
    <TableRow className={classes.row} data-testid={`vehicle-row-${data.id}`}>
      <TableCell classes={{ body: classes.cell }}>
        <div className={classes.infoWrap}>
          {data.status && (
            <ActivityStatus
              variant={'fill'}
              value={data.status}
              onClick={handleActivityStatusClick}
            />
          )}
          <div className={classes.info}>
            {data.country && data.plateNumber && (
              <PlateNumber
                wrapClass={classes.plateNumber}
                country={data.country}
                plateNumber={data.plateNumber}
              />
            )}
            {data.type && <VehicleIcon variant={'outline'} type={data.type} />}
            <div
              className={classes.vehicleModel}
              data-testid='vehicleBrand'
            >{`${data.brandName} ${data.modelName}`}</div>
          </div>
        </div>
      </TableCell>

      <TableCell classes={{ body: classes.cell }} align={'center'} data-testid='scoring'>
        <ScoreValue value={data?.scoring} />
      </TableCell>

      {tableViewType === 'info' && (
        <TableCell classes={{ body: classes.cell }} align={'center'}>
          <div className={classes.problems}>
            {Boolean(data.crashCount) && (
              <Collision count={data.crashCount} onClick={handleCrashClick} />
            )}
            {data.penaltyCards?.map((type, i) => <PenaltyCard type={type} key={`${type}-${i}`} />)}
          </div>
        </TableCell>
      )}

      <TableCell classes={{ body: classes.cell }} align={'center'} data-testid='dailyKm'>
        <StatisticValue value={data.dailyKm} />
      </TableCell>

      <TableCell classes={{ body: classes.cell }} align={'center'} data-testid='driverTime'>
        <Time timestamp={data.driveTime} degree={TIME_DEGREE.S} />
      </TableCell>

      <TableCell classes={{ body: classes.cell }} align={'center'} data-testid='idleTime'>
        <Time timestamp={data.idleTime} degree={TIME_DEGREE.S} />
      </TableCell>

      {tableViewType === 'statistic' && (
        <>
          <TableCell
            classes={{ body: classes.cell }}
            align={'center'}
            data-testid='engineHoursBySecond'
          >
            <Time timestamp={data.engineHoursBySecond} degree={TIME_DEGREE.S} />
          </TableCell>

          <TableCell classes={{ body: classes.cell }} align={'center'} data-testid='totalKm'>
            <StatisticValue value={data.totalKm} />
          </TableCell>
        </>
      )}

      <TableCell classes={{ body: classes.cell }} align={'center'}>
        <div
          onClick={(e) => {
            e.stopPropagation();
            editHandler(data);
          }}
          data-testid='button-edit'
        >
          <PencilIcon className={classes.pencil} />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default VehicleRow;
