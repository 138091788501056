import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'relative',
    color: palette.custom.blue200,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 15,
    minHeight: 320,
    minWidth: 400,
  },

  modalHeader: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 10,
  },

  row: {
    display: 'grid',
    gridTemplateColumns: '1fr 150px 90px',
    columnGap: 15,
    alignItems: 'center',
  },

  column: {
    paddingLeft: '25px !important',
    paddingRight: '25px !important',
  },

  input: {
    '& .MuiInput-input': {
      color: theme.palette.primary.light,
    },
  },
}));

export default useStyles;
