import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  badge: {
    backgroundColor: palette.custom.blue900,
    borderRadius: 20,
    fontFamily: 'SFPro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '19px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: palette.custom.blue700,
    padding: '5px 10px',
    border: '1px solid transparent',

    '&.outline': {
      backgroundColor: 'transparent',
      border: `1px solid ${palette.custom.blue400}`,
      color: palette.custom.blue400,
    },
  },

  rootIcon: {
    width: 15,
    height: 15,

    '& path': {
      fill: palette.custom.blue700,
    },

    '&.outline path': {
      fill: palette.custom.blue400,
    },
  },

  value: {
    whiteSpace: 'nowrap',
    maxWidth: 235,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

export default useStyles;
