import cx from 'classnames';
import React, { FC, useMemo } from 'react';

import { TIME_DEGREE } from 'interfaces/common/time-degree';

import { isEmptyValue } from 'utils/helpers/is-empty-value';
import { minutesToPrettyHours } from 'utils/helpers/minutes-to-pretty-hours';

import useStyles from './styles';

interface ITime {
  timestamp: number | null;
  className?: string;
  degree?: TIME_DEGREE;
}

const Time: FC<ITime> = ({ className, timestamp, degree = TIME_DEGREE.M }) => {
  const classes = useStyles();

  const { hours, minutes } = useMemo(() => {
    if (timestamp === null || timestamp === undefined) {
      return { hours: null, minutes: null };
    }

    if (degree === TIME_DEGREE.S) {
      return minutesToPrettyHours(timestamp / 60);
    }

    return minutesToPrettyHours(timestamp);
  }, [degree, timestamp]);

  if (timestamp === null) {
    return (
      <div className={cx(classes.wrap, className)} data-testid='time-component'>
        -
      </div>
    );
  }

  return (
    <div className={cx(classes.wrap, className)} data-testid='time-component'>
      {Boolean(hours) && (
        <>
          {hours}
          <sup>h</sup>
        </>
      )}
      {!isEmptyValue(minutes) && (
        <>
          {' '}
          {minutes}
          <sup>m</sup>
        </>
      )}
    </div>
  );
};

export default Time;
