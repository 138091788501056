import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

type Props = {
  technicalMessageBlockHeight: number;
};

const useStyles = makeStyles<Theme, Props>((theme) => ({
  wrap: {
    flex: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  tableContainer: {
    padding: '0 50px 0 50px',
    minHeight: ({ technicalMessageBlockHeight }) =>
      `calc(100vh - 225px - ${technicalMessageBlockHeight}px)`,
    maxHeight: ({ technicalMessageBlockHeight }) =>
      `calc(100vh - 225px - ${technicalMessageBlockHeight}px)`, // 225px it's the height of the page header and page footer
    marginBottom: 45,
  },

  headCell: {
    paddingTop: '25px !important', // TODO: remove scss styles for table
    paddingBottom: '25px !important',
  },

  editHeadCell: {
    paddingTop: '25px !important', // TODO: remove scss styles for table
    paddingBottom: '25px !important',
    width: 70,
    cursor: 'pointer',
  },

  tbody: {
    '&::before': {
      content: '""',
      height: 20,
      display: 'block',
    },
  },

  loadMoreWrap: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.primary.dark,
    columnGap: 20,
  },

  loadMore: {
    color: palette.custom.blue200,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    columnGap: 5,
  },

  arrowDown: {
    width: 12,

    '& *': {
      stroke: palette.custom.blue200,
    },
  },
}));

export default useStyles;
