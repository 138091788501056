import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ISubcompanyData } from 'interfaces/company/subcompany-data';

import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';
import userDataAtom from 'recoil/userData';

import { userDataReq } from 'requests/auth';

import CrashModal from 'components/CrashModal';
import Loader from 'components/Loader';
import Navigation from 'components/Navigation';

import NotificationProvider from 'modules/NotificationProvider';
import Notifications from 'modules/Notifications';

import server from '../../utils/server';

import useStyles from './styles';

const App = () => {
  const technicalMessageBlockHeight = useRecoilValue(technicalMessageBlockHeightSelector);
  const classes = useStyles({ technicalMessageBlockHeight });

  const [auth, setAuth] = useState(false);
  const [userData, setUserData] = useRecoilState(userDataAtom);

  useEffect(() => {
    const subcompanyData = queryString.parse(window.location.search) as Partial<ISubcompanyData>;
    if (!subcompanyData.subcompanyId) {
      return;
    }

    // if we have one field from subcompanyData - we have all fields
    window.location.search = '';
    sessionStorage.clear();
    sessionStorage.setItem('subcompanyId', subcompanyData.subcompanyId);
    sessionStorage.setItem('subcompanyLogoUrl', subcompanyData.subcompanyLogoUrl!);
    sessionStorage.setItem('subcompanyName', subcompanyData.subcompanyName!);
    sessionStorage.setItem('subcompanyCurrency', subcompanyData.subcompanyCurrency!);
    sessionStorage.setItem('subcompanyCountry', subcompanyData.subcompanyCountry!);
  }, [setUserData]);

  const handleInitUserData = useCallback(async () => {
    try {
      const response = await userDataReq();
      server.setToken(response.token);

      setUserData(response);
      setAuth(true);
    } catch {
      console.log('Error Get User Data');
    }
  }, [setUserData]);

  useEffect(() => {
    if (!userData?.token) {
      handleInitUserData();
    }
  }, [handleInitUserData, userData?.token]);

  if (!auth) {
    return (
      <div className={classes.app}>
        <Loader lightMode preventClick width={150} />
      </div>
    );
  }

  return (
    <NotificationProvider>
      <div className={classes.app}>
        <Outlet />

        <Navigation />

        <Notifications />

        <CrashModal />
      </div>
    </NotificationProvider>
  );
};

export default App;
