import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  loadMoreWrap: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.primary.dark,
    columnGap: 20,
  },

  loadMore: {
    color: palette.custom.blue200,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    columnGap: 5,
  },

  arrowDown: {
    width: 12,

    '& *': {
      stroke: palette.custom.blue200,
    },
  },
}));

export default useStyles;
