import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    opacity: '0 !important',
  },

  dialogContent: {
    width: 320,
    position: 'fixed',
    top: '50%',
    left: 125,
    backgroundColor: palette.custom.bluePrimary,
    borderRadius: 16,
    padding: 10,
    transform: 'translateY(-50%) !important',
    maxHeight: 585,
  },

  item: {
    transition: 'all ease 0.3s',

    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.15),
    },
  },
}));

export default useStyles;
