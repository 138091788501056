import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    minWidth: 50,
    minHeight: 50,
    position: 'relative',
  },

  btn: {
    position: 'absolute',
    right: 0,
    width: 50,
    height: 50,
    borderRadius: 15,
    backgroundColor: theme.palette.primary.light,
    boxShadow: '0px 16px 40px rgba(0, 0, 0, 0.12)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    '&.isOpenSettingsModal': {
      display: 'none',
    },
  },

  searchIcon: {
    width: '16px !important',
    height: '16px !important',
  },

  filtersCount: {
    width: 23,
    height: 23,
    borderRadius: '50%',
    backgroundColor: palette.custom.goldSecondary,
    color: theme.palette.primary.light,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: -6,
    right: -6,
    fontFamily: 'SFPro',
  },

  filter: {
    maxWidth: 50,
    maxHeight: 50,
    borderRadius: 10,
    overflow: 'hidden',
    transition: 'max-width 0.3s ease, max-height 0.3s ease, opacity 0.3s ease',

    '&.isOpenSettingsModal': {
      maxWidth: 1000,
      maxHeight: 1000,
    },
  },

  filterModal: {
    padding: 8,
    display: 'flex',
    columnGap: 8,
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
  },

  filterBtn: {
    width: 106,
    height: 40,
    border: `1px solid ${palette.custom.blue300}`,
    borderRadius: 5,
    color: palette.custom.blue300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 500,
    cursor: 'pointer',

    '&.isLittle': {
      height: 24,
    },

    '&.isActive': {
      border: `1px solid ${palette.custom.bluePrimary}`,
      backgroundColor: palette.custom.bluePrimary,
      color: theme.palette.primary.light,
    },
  },
}));

export default useStyles;
