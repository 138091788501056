import i18next from 'i18next';

import { IUserAuth } from 'interfaces/user/user-auth';

import server from 'utils/server';

import { TFormValues } from 'modules/Login/form-schema';

import { localizationRequestHeaders } from '../../../localization';

const checkLanguage = (lang: string) => {
  const userLang = lang.toLowerCase();
  localStorage.setItem('lang', userLang);

  if (i18next.language !== userLang) {
    i18next.changeLanguage(userLang);
  }
};

/**
 * Makes a login request to the server.
 *
 * @param {TFormValues} body - The login form values.
 * @returns {Promise<IUserAuth>} - A promise that resolves with the authenticated user data.
 */
export const loginReq = async (body: TFormValues): Promise<IUserAuth> => {
  return server.services.beService.post<IUserAuth>('api/auth/login', body).then((res) => {
    // TODO: we need to take this logic out of the request
    localizationRequestHeaders.set('Authorization', `Bearer ${res.data.token}`);
    checkLanguage(res.data.language);
    localStorage.setItem('authToken', res.data.token);
    localStorage.setItem('pubnubChanel', res.data.channel);
    return res.data;
  });
};
