import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { StyledEngineProvider } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import React, { useCallback, useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import SimpleBar from 'simplebar-react';

import { ReactComponent as WarningIcon } from 'assets/images/warning.svg';

import { TRANSLATION_SERVICE_LANG, TranslationsService } from 'utils/TranslationsService';

import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';

import {
  getTranslationPagesLIstReq,
  ITranslationPagesListItem,
} from 'requests/translation-service/get-translation-pages-lIst';

import Loader from 'components/Loader';

import Header from '../../components/header';

import useStyles from './styles';

const Language = () => {
  const technicalMessageBlockHeight = useRecoilValue(technicalMessageBlockHeightSelector);
  const classes = useStyles({ technicalMessageBlockHeight });
  const { t } = useTranslation(['setting.language.page', 'setting.page']);

  const [fetching, setFetching] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [pages, setPages] = useState<ITranslationPagesListItem[]>([]);

  /**
   * Get pages list
   */
  useEffect(() => {
    const getPages = async () => {
      try {
        setFetching(true);
        const res = await getTranslationPagesLIstReq();
        setPages(res);
      } catch (e) {
        console.log(e);
      } finally {
        setFetching(false);
      }
    };

    getPages();
  }, []);

  /**
   * Get translations for page and open modal
   */
  const openModal = useCallback(async (page: string) => {
    try {
      setFetching(true);

      await TranslationsService.getInstance().getTranslationData(page);

      setIsOpenModal(true);
    } catch (error) {
      console.log(error);
    } finally {
      setFetching(false);
    }
  }, []);

  /**
   * Close page translation modal
   */
  const closeModal = useCallback(() => {
    TranslationsService.getInstance().clearData();
    setIsOpenModal(false);
  }, []);

  /**
   * Set new translation value
   */
  const inputHandler = useCallback(
    (
      key: string,
      lang: TRANSLATION_SERVICE_LANG,
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      TranslationsService.getInstance().onChangeInput(key, lang, event);
    },
    [],
  );

  /**
   * Send updated translations to API
   */
  const submitHandler = useCallback(() => {
    const updateTranslation = async () => {
      try {
        setFetching(true);

        await TranslationsService.getInstance().submit();
      } catch (error) {
        console.log(error);
      } finally {
        setFetching(false);
        closeModal();
      }
    };

    updateTranslation();
  }, [closeModal]);

  return (
    <div className={classes.wrap}>
      <Header backBtn={{ label: t('setting.page:language.label') }} />

      {fetching && <Loader width={150} lightMode />}

      <div className={classes.warningWrap}>
        <WarningIcon className={classes.warningIcon} />
        <div>
          Warning! Some translation fields may contain strings of the form: <b>{'{{someText}}'}</b>.
          It is important not to change this construction when editing translations. It is allowed
          to move it inside the text, but the form of the entry must remain unchanged. Also, you
          cannot delete this construction from the text.
        </div>
        <WarningIcon className={classes.warningIcon} />
      </div>

      <SimpleBar className={classes.pagesWrapper}>
        <div className={classes.pagesList}>
          {pages?.map(({ key, value }) => (
            <div
              className={classes.pageName}
              key={key}
              onClick={() => openModal(key)}
              data-testid='disconary-block'
            >
              {value}
            </div>
          ))}
        </div>
      </SimpleBar>

      <StyledEngineProvider injectFirst>
        <Dialog open={isOpenModal} onClose={closeModal} classes={{ container: classes.modalWrap }}>
          <DialogContent>
            <TableContainer>
              <Table size='small' aria-label='a dense table'>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('field.name.label')}</TableCell>
                    <TableCell classes={{ root: classes.column }} align='left'>
                      {t('en.label')}
                    </TableCell>
                    <TableCell classes={{ root: classes.column }} align='left'>
                      {t('az.label')}
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {Object.values(TranslationsService.getInstance().getRenderData()).map(
                    ({ key, translationAz, translationEn }) => (
                      <TableRow key={key}>
                        <TableCell>
                          <div>{key}</div>
                        </TableCell>
                        <TableCell classes={{ root: classes.column }} align='left'>
                          <TextField
                            variant='filled'
                            classes={{
                              root: classes.input,
                            }}
                            defaultValue={translationEn}
                            onChange={(e) =>
                              inputHandler(key, TRANSLATION_SERVICE_LANG.translationEn, e)
                            }
                            data-testid='input-en'
                          />
                        </TableCell>
                        <TableCell classes={{ root: classes.column }} align='left'>
                          <TextField
                            variant='filled'
                            classes={{ root: classes.input }}
                            defaultValue={translationAz}
                            onChange={(e) =>
                              inputHandler(key, TRANSLATION_SERVICE_LANG.translationAz, e)
                            }
                            data-testid='input-az'
                          />
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>

          <DialogActions>
            <Button onClick={closeModal} color='primary' data-testid='button-cancel'>
              {t('cancel.label')}
            </Button>
            <Button onClick={submitHandler} color='primary' data-testid='button-save'>
              {t('save.label')}
            </Button>
          </DialogActions>
        </Dialog>
      </StyledEngineProvider>
    </div>
  );
};

export default Language;
