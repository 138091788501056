import { ITripPenaltyPoint } from 'interfaces/map/i-trip-penalty-point';
import { PENALTIES } from 'interfaces/map/penalties';

import server from 'utils/server';

export type ITripPenalties = {
  [key in PENALTIES]: ITripPenaltyPoint[];
};

/**
 * Retrieve penalty points requirement for a given trip.
 *
 * @param {string} tripId - The ID of the trip.
 * @returns {Promise<ITripPenalties>} - A promise that resolves to the trip penalties object.
 */
export const getPenaltiesPointsReq = async (tripId: string): Promise<ITripPenalties> => {
  const body = [...Object.values(PENALTIES)].filter(
    (item) => item !== PENALTIES.GEOFENCING && item !== PENALTIES.TOTAL,
  );

  return server.services.beService
    .post<ITripPenalties>(`map/trips/${tripId}/violations`, body)
    .then((res) => res.data);
};
