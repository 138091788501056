import React, { FC, useMemo } from 'react';

import { SENSOR_TYPES } from 'interfaces/sensor/types';

import { ReactComponent as HumidityIcon } from 'assets/images/humidity.svg';
import { ReactComponent as MovementIcon } from 'assets/images/movement.svg';
import { ReactComponent as TemperatureIcon } from 'assets/images/temperature.svg';

import useStyles from './styles';

interface IProps {
  label: SENSOR_TYPES;
}

const SensorAlarmMarker: FC<IProps> = ({ label }) => {
  const classes = useStyles();

  const Icon = useMemo(() => {
    switch (label) {
      case SENSOR_TYPES.TEMPERATURE:
        return TemperatureIcon;
      case SENSOR_TYPES.MOVEMENT:
        return MovementIcon;
      case SENSOR_TYPES.HUMIDITY:
        return HumidityIcon;
    }
  }, [label]);

  return (
    <div className={classes.wrap} data-testid={`sensor-marker-${label}`}>
      <Icon className={classes.icon} />
    </div>
  );
};

export default React.memo(SensorAlarmMarker);
