import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

type Props = {
  technicalMessageBlockHeight: number;
};

const useStyles = makeStyles<Theme, Props>(() => ({
  content: {
    padding: '0 50px',
    maxWidth: 1440,
    margin: '50px auto 0',
  },

  companiesWrap: {
    marginTop: 15,
    height: ({ technicalMessageBlockHeight }) =>
      `calc(100vh - 450px - ${technicalMessageBlockHeight}px)`,

    '&.hide': {
      height: ({ technicalMessageBlockHeight }) =>
        `calc(100vh - 310px - ${technicalMessageBlockHeight}px)`,
    },
  },
}));

export default useStyles;
