import { IUser } from 'interfaces/user/user-data';

import server from 'utils/server';

export type TUserCreateData = Pick<
  IUser,
  | 'firstName'
  | 'lastName'
  | 'companyId'
  | 'userName'
  | 'password'
  | 'enabled'
  | 'email'
  | 'language'
  | 'role'
  | 'measurement'
>;
export type TUserData = TUserCreateData & Pick<IUser, 'id' | 'photoUrl'>;

export const createUserReq = async (data: TUserCreateData) =>
  server.services.beService.post<TUserData>('system-users/', data).then((res) => res.data);
