import { ApexOptions } from 'apexcharts';
import { format } from 'date-fns';

import { palette } from 'assets/theme';

export const getOptions = (
  categories: string[],
  indexes: number[],
  setCurrentIndex: (index: number) => void,
): ApexOptions => {
  return {
    chart: {
      id: 'speed-chart',
      type: 'line',
      events: {
        click(e: unknown, chart?: unknown, options?: unknown) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (options?.dataPointIndex && options.dataPointIndex >= 0) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setCurrentIndex(indexes[options.dataPointIndex]);
          }
        },
      },
    },
    stroke: {
      curve: 'straight',
      colors: [palette.custom.goldSecondary],
      width: 2,
    },
    legend: {
      show: false,
    },
    grid: {
      show: true,
      borderColor: palette.custom.mainLight,
      strokeDashArray: 2,
      position: 'back',
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      type: 'datetime',
      categories: categories,
      labels: {
        show: true,
        style: {
          colors: palette.custom.mainLight,
        },
        formatter: (value: string) => {
          return format(new Date(value), 'hh:mm:ss');
        },
      },
      axisBorder: {
        show: true,
        color: palette.custom.mainLight,
        offsetX: 0,
        offsetY: 1,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      tickAmount: 6,
      forceNiceScale: true,
      labels: {
        show: true,
        style: {
          colors: palette.custom.mainLight,
          fontSize: '12px',
          fontWeight: 500,
          fontFamily: 'SFPro',
        },
      },
      axisBorder: {
        show: true,
        color: palette.custom.mainLight,
        width: 0.665924,
        offsetX: -1,
        offsetY: 0,
      },
    },
  };
};
