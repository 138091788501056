import cx from 'classnames';
import React from 'react';
import { useRecoilValue } from 'recoil';

import { FCC } from 'interfaces/common/fc-with-children';

import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';

import useStyles from './styles';

interface IContent {
  isOpenMenu: boolean;
}

const Content: FCC<IContent> = ({ isOpenMenu, children }) => {
  const technicalMessageBlockHeight = useRecoilValue(technicalMessageBlockHeightSelector);
  const classes = useStyles({ technicalMessageBlockHeight });

  return (
    <div className={cx(classes.content)}>
      <div className={cx(classes.container, { full: !isOpenMenu })}>{children}</div>
    </div>
  );
};

export default Content;
