import { ApexOptions } from 'apexcharts';

export const createOptions = (lineColor: string, statistics: number): ApexOptions => ({
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: '82%',
        image: undefined,
        imageOffsetX: 0,
        imageOffsetY: 0,
        position: 'front',
      },
      track: {
        strokeWidth: '100%',
        margin: 0,
        background: '#4A62A1',
        opacity: 0.2,
      },
      dataLabels: {
        show: false,
        name: {
          offsetY: 12,
          show: true,
          fontSize: '34px',
          fontWeight: 500,
          color: '#C4CFDD',
        },
        value: {
          show: false,
        },
      },
    },
  },
  labels: [String(statistics)],
  fill: {
    type: 'gradient',
    gradient: {
      type: 'horizontal',
      inverseColors: false,
      colorStops: [
        {
          offset: 0,
          color: lineColor,
          opacity: 1,
        },
        {
          offset: 100,
          color: lineColor,
          opacity: 1,
        },
      ],
    },
  },
  stroke: {
    lineCap: 'round',
  },
  states: {
    active: {
      filter: {
        type: 'none',
      },
    },
    hover: {
      filter: {
        type: 'none',
      },
    },
  },
  grid: {
    padding: {
      top: 0,
      bottom: 0,
    },
  },
});

export const series = (item: number) => {
  if (item > 0) {
    return item * (100 / 30);
  }
  if (item > -30) {
    return -(item * (100 / 30));
  }
  return -((item + 30) * (100 / 30));
};
