import { Box, BoxProps } from '@mui/material';
import React, { FC, useMemo } from 'react';

import { ACTIVITY_STATUSES } from 'interfaces/common/activity-statuses';

import { ReactComponent as ActiveFillIcon } from 'assets/images/activity-statuses/fill/active.svg';
import { ReactComponent as IdleFillIcon } from 'assets/images/activity-statuses/fill/idle.svg';
import { ReactComponent as ParkingFillIcon } from 'assets/images/activity-statuses/fill/parking.svg';
import { ReactComponent as ActiveOutlineIcon } from 'assets/images/activity-statuses/outline/active.svg';
import { ReactComponent as IdleOutlineIcon } from 'assets/images/activity-statuses/outline/idle.svg';
import { ReactComponent as ParkingOutlineIcon } from 'assets/images/activity-statuses/outline/parking.svg';
import { ReactComponent as InactiveIcon } from 'assets/images/device/status-inactive.svg';

interface IActivityStatus {
  value: ACTIVITY_STATUSES;
  className?: string;
  variant?: 'fill' | 'outline';
}

type Props = BoxProps & IActivityStatus;

const ActivityStatus: FC<Props> = ({ value, variant = 'fill', ...props }) => {
  /**
   * Get activity status icon depending on props
   */
  const Icon = useMemo(() => {
    if (variant === 'outline') {
      if (value === ACTIVITY_STATUSES.ACTIVE) {
        return ActiveOutlineIcon;
      }
      if (value === ACTIVITY_STATUSES.IDLING) {
        return IdleOutlineIcon;
      }
      if (value === ACTIVITY_STATUSES.INACTIVE) {
        return ParkingOutlineIcon;
      }
    }

    if (value === ACTIVITY_STATUSES.ACTIVE) {
      return ActiveFillIcon;
    }
    if (value === ACTIVITY_STATUSES.IDLING) {
      return IdleFillIcon;
    }
    if (value === ACTIVITY_STATUSES.INACTIVE) {
      return ParkingFillIcon;
    }

    return InactiveIcon;
  }, [value, variant]);

  return (
    <Box {...props} data-testid='activity-status'>
      <Icon />
    </Box>
  );
};

export default ActivityStatus;
