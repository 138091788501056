import { selectorFamily } from 'recoil';

import {
  driverShortReq,
  IDriverShortData,
  TDriverShortFilters,
} from 'requests/be-service/driver-controller/get-driver-general-info';

type TParams = {
  driverId: string;
  filters: TDriverShortFilters | null;
};

const driverShortDataSelector = selectorFamily<IDriverShortData, TParams>({
  key: 'driverShortDataAtom',
  get:
    ({ driverId, filters }) =>
    async () => {
      return await driverShortReq(driverId, filters);
    },
});

export default driverShortDataSelector;
