import { ContentCopy, Check } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import cx from 'classnames';
import { format } from 'date-fns';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';

import settingPaymentCompanyAtom from 'recoil/settings/payment/selected-company/company';

import { ITransaction } from 'requests/payment/get-company-transactions';

import Button from 'components/form-v2/button';

import { Money } from '../../components/money';
import TransactionResult from '../../components/transaction-result';

import useStyles from './styles';

interface IProps {
  data: ITransaction | null;
  onClose: () => void;
}
const TransactionDrawer: FC<IProps> = ({ data, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation(['payment.page', 'fields']);
  const { id } = useParams() as { id: string };
  const companyData = useRecoilValueLoadable(settingPaymentCompanyAtom(id));
  const [wasCopy, setWasCopy] = useState(false);

  if (companyData.state !== 'hasValue') {
    return null;
  }

  return (
    <div className={classes.wrap} data-testid='transaction-drawer'>
      <div className={classes.header}>
        <div className={classes.companyName} data-testid='companyName'>
          {companyData.contents.companyName}
        </div>

        <div className={classes.btns}>
          <div className={classes.btn} onClick={onClose} data-testid='button-close'>
            <CloseIcon width={12} />
          </div>
        </div>

        <div className={classes.shadow} />
      </div>

      <div className={classes.list}>
        <div className={classes.item}>
          <div className={classes.label}>{t('transaction.balance.label')}</div>
          <div className={classes.value} data-testid='amount'>
            {data?.amount ? <Money count={data.amount} /> : '-'}
          </div>
        </div>

        <div className={classes.item}>
          <div className={classes.label}>{t('transaction.status.label')}</div>
          <div className={classes.value} data-testid='transactionResult'>
            {data?.transactionResult ? <TransactionResult result={data?.transactionResult} /> : '-'}
          </div>
        </div>

        <div className={classes.item}>
          <div className={classes.label}>{t('transaction.date.label')}</div>
          <div className={classes.value} data-testid='paymentDate'>
            {data?.paymentDate && format(new Date(data.paymentDate), 'dd.MM.yyyy hh:mm')}
          </div>
        </div>

        <div className={classes.item}>
          <div className={classes.label}>{t('transaction.id.label')}</div>
          <div
            className={classes.row}
            onMouseLeave={() => {
              setWasCopy(false);
            }}
            data-testid='copy-transactionId'
          >
            <Tooltip title={data?.transactionId} arrow>
              <div className={classes.value} data-testid='transactionId'>
                {data?.transactionId}
              </div>
            </Tooltip>
            {!wasCopy && (
              <ContentCopy
                onClick={() => {
                  navigator.clipboard.writeText(data?.transactionId ?? '');
                  setWasCopy(true);
                }}
                className={classes.copyIcon}
              />
            )}
            {wasCopy && <Check className={classes.checlIcon} />}
          </div>
        </div>

        <div className={classes.item}>
          <div className={classes.label}>{t('transaction.provider.label')}</div>
          <div className={classes.value} data-testid='provider'>
            {data?.provider}
          </div>
        </div>

        <div className={classes.item}>
          <div className={classes.label}>{t('transaction.description.label')}</div>
          <div className={cx(classes.value, 'description')} data-testid='paimentInfo'>
            {data?.paymentInfo}
          </div>
        </div>
      </div>

      <Button className={classes.closeBtn} onClick={onClose} testId='button-close'>
        {t('fields:ok.label')}
      </Button>
    </div>
  );
};

export default TransactionDrawer;
