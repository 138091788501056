import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrap: {
    maxWidth: 1182,
    margin: '0 auto',
    padding: '38px 7px 36px 0',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1.13fr',
    gap: 17,
    gridTemplateAreas: `
    "chart chart filter"
    "chart chart saving"
    "fuel wear drivers"
    "mainterence emissions drivers"
    `,

    '& div[data-id="chart"]': {
      gridArea: 'chart',
      minHeight: 334,
    },

    '& div[data-id="filter"]': {
      gridArea: 'filter',
    },

    '& div[data-id="total"]': {
      gridArea: 'saving',
      minHeight: 269,
    },

    '& div[data-id="fuel"]': {
      gridArea: 'fuel',
    },

    '& div[data-id="wearAndTear"]': {
      gridArea: 'wear',
    },

    '& div[data-id="drivers"]': {
      gridArea: 'drivers',
    },

    '& div[data-id="mainterence"]': {
      gridArea: 'mainterence',
    },

    '& div[data-id="emissions"]': {
      gridArea: 'emissions',
    },

    ['@media (max-width:1230px)']: {
      paddingLeft: 25,
      paddingRight: 25,
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `
    "chart chart"
    "filter filter"
    "saving saving"
    "fuel wear"
    "mainterence emissions"
    "drivers drivers"
    `,
    },
  },

  filter: {
    maxHeight: 43,
  },

  isSmall: {
    height: 206,
  },

  drivers: {
    maxHeight: 427,
  },
}));

export default useStyles;
