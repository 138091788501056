import { atom } from 'recoil';

import { ISortParameters } from 'interfaces/common/sort';

import recoilLocalStorageEffect from 'utils/recoil-localStorage-effect';

import { SORT_DIRECTION } from '../../payment/transactions/sort';

const initValues = {
  sort: 'registration',
  direction: SORT_DIRECTION.DESC,
} as const;

const settingsVehiclesSortDataAtom = atom<ISortParameters>({
  key: 'settingsVehiclesSortDataAtom',
  default: initValues,
  effects_UNSTABLE: [recoilLocalStorageEffect('settingsVehiclesSortDataAtom', initValues)],
});

export default settingsVehiclesSortDataAtom;
