import { selectorFamily } from 'recoil';

import { driverShortReq } from 'requests/be-service/driver-controller/get-driver-general-info';
import { driverShortAllDayReq } from 'requests/be-service/map-view-controller/get-driver-trips-by-period';

const tripsDaySelector = selectorFamily({
  key: 'tripsDaySelector',
  get:
    ({ id, date }) =>
    async () => {
      try {
        const driver = await driverShortReq(id, null);
        const result = await driverShortAllDayReq(id, date.split('-').join('.'));
        return {
          driver,
          result,
        };
      } catch (e) {
        return { errorMessage: e.message };
      }
    },
});

export default tripsDaySelector;
