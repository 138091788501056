import { selector } from 'recoil';

import { CURRENCY_ICONS } from '../../constants/currency';
import { measurementSystem } from '../../constants/measure-unit';
import userDataAtom from '../userData';

const userConfigAtom = selector({
  key: 'userConfigAtom',
  get: ({ get }) => {
    const userData = get(userDataAtom);
    const subcompanyCurrency = sessionStorage.getItem('subcompanyCurrency');

    return {
      ...userData.localizationConfig,
      isMetricSystem: userData.measurement === measurementSystem.metric,
      currencyIcon: subcompanyCurrency
        ? CURRENCY_ICONS[subcompanyCurrency]
        : CURRENCY_ICONS[userData.localizationConfig.currency],
    };
  },
});

export default userConfigAtom;
