import cx from 'classnames';
import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import useStyles from './styles';

interface ICheckbox {
  wrapClass?: string;
  isIndeterminate?: boolean;
  label?: string;
  disabled?: boolean;
  testId?: string;
  name: string;
}

type Props = ICheckbox;

const Checkbox: FC<Props> = ({
  wrapClass,
  label,
  testId,
  isIndeterminate = false,
  disabled = false,
  name,
}) => {
  const classes = useStyles();

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <label
            className={cx(classes.wrap, wrapClass, { disabled })}
            data-testid={testId}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={cx(classes.checkbox, { isChecked: field.value })}>
              <div className={cx(classes.checkedIcon, { isIndeterminate })} />
            </div>
            <div className={classes.label}>{label}</div>
            <input
              className={classes.hiddenInput}
              type='checkbox'
              onChange={(e) => field.onChange(e.target.checked)}
              checked={field.value}
            />
          </label>
        );
      }}
    ></Controller>
  );
};

export default Checkbox;
