import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import { atom } from 'recoil';

import recoilLocalStorageEffect from 'utils/recoil-localStorage-effect';

export const initValues = {
  movementAtFrom: startOfDay(new Date()).getTime(),
  movementAtTill: endOfDay(new Date()).getTime(),
};

const driverTripsFilterAtom = atom({
  key: 'driverTripsFilterAtom',
  default: initValues,
  effects_UNSTABLE: [recoilLocalStorageEffect('driverTripsFilterAtom', initValues)],
});

export default driverTripsFilterAtom;
