import * as yup from 'yup';

export const formSchema = () => {
  return yup.object().shape({
    start: yup.date().required(),
    end: yup.date().required(),
  });
};

export type TDateFilters = yup.InferType<ReturnType<typeof formSchema>>;
