import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { postContactsReq } from 'requests/be-service/contanct-controller/demo';

import InputHome from 'components/FormComponents/InputHome';
import TextArea from 'components/FormComponents/TextArea';

import useStyles from './styles';

const emailRegExp = /\S+@\S+\.\S+/;

const Form = ({ setShouldViewSuccess }) => {
  const classes = useStyles();
  const { t } = useTranslation('home.page');

  const [isFeching, setIsFeching] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    setShouldViewSuccess(!isFeching && isSuccess);
  }, [isFeching, isSuccess, setShouldViewSuccess]);

  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      message: '',
      industry: '',
      companyName: '',
      contactNumber: '',
      country: '',
      name: '',
      vehicleQuantity: '',
      email: '',
    },
  });

  const onSubmit = async (values) => {
    try {
      setIsFeching(true);
      await postContactsReq(values);
      setIsSuccess(true);
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    } finally {
      setIsFeching(false);
    }
  };

  const formData = [
    { label: t('name.label'), name: 'name', error: errors.name },
    {
      label: t('companyName.label'),
      name: 'companyName',
      error: errors.companyName,
    },
    {
      label: t('industry.label'),
      name: 'industry',
      error: errors.industry,
    },
  ];

  const formDataTwo = [
    {
      label: t('country.label'),
      name: 'country',
      error: errors.country,
    },
    {
      label: t('email.label'),
      name: 'email',
      error: errors.email,
      required: watch('contactNumber'),
      pattern: emailRegExp,
    },
    {
      label: t('contactNumber.label'),
      name: 'contactNumber',
      error: errors.contactNumber,
      required: watch('email'),
      type: 'number',
    },
    {
      label: t('numberAssets.label'),
      name: 'vehicleQuantity',
      error: errors.vehicleQuantity,
      type: 'number',
    },
  ];

  return (
    <>
      <div className={classes.container}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.block}>
            <div className={classes.blockRow}>
              <div className={classes.text}>
                <div>{t('fillFields.label')}</div>
                <div>{t('youBack.label')}</div>
              </div>
              {formData.map((props) => (
                <InputHome
                  {...props}
                  key={props.name}
                  control={control}
                  error={Boolean(props.error)}
                />
              ))}
            </div>
            <div className={classes.blockRow}>
              {formDataTwo.map((props) => (
                <InputHome
                  {...props}
                  key={props.name}
                  control={control}
                  error={Boolean(props.error)}
                />
              ))}
            </div>
            <div className={classes.blockRow}>
              <div className={classes.textAreaWrapper}>
                <TextArea
                  control={control}
                  rows={13}
                  error={Boolean(errors.message)}
                  label={t('yourMessage.label')}
                  className={classes.area}
                  required
                />
              </div>
              <Button className={classes.button} type='submit'>
                {t('send.label')}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

Form.defaultProps = {
  setShouldViewSuccess: () => null,
};

Form.propTypes = {
  setShouldViewSuccess: PropTypes.func,
};

export default React.memo(Form);
