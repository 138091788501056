import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';

import { ISelectItem, ISelectItemWithCompanyId } from 'interfaces/common/select-items';
import { IUserData } from 'interfaces/user/user-data';

import { ROLES_OBJECT } from 'constants/user';

import driversFiltersDataAtom from 'recoil/driver/drivers-filters';
import { initValues } from 'recoil/driver/drivers-filters/atom';
import driversVehiclesShowDriversFilterAtom from 'recoil/drivers-vehicles/show-drivers-filter';
import subcompaniesSelectListAtom from 'recoil/subcompanies/select-list';
import userDataAtom from 'recoil/userData';

import { getCompanyUsersReq } from 'requests/be-service/company-controller/get-company-users';
import { getSystemUsersReq } from 'requests/be-service/system-user-controller/get-all-users';
import { teamsGetReq } from 'requests/be-service/teams-controller/get-all-teams';

import Select from 'components/form-v2/select';
import ModalActions from 'components/modal-actions';

import { TDriverFiltersForm, formSchema } from './form-schema';
import useStyles from './styles';

const DriversFilter = () => {
  const classes = useStyles();
  const { t } = useTranslation('fields');

  const setShowFilter = useSetRecoilState(driversVehiclesShowDriversFilterAtom);
  const { contents: subcompaniesSelectList } = useRecoilValueLoadable(subcompaniesSelectListAtom);
  const [managers, setManagers] = useState<ISelectItemWithCompanyId[]>([]);
  const [teams, setTeams] = useState<ISelectItem[]>([]);
  const [managersListPage, setManagersListPage] = useState(0);
  const [managersListTotalPages, setManagersListTotalPages] = useState(0);
  const [teamsPage, setTeamsPage] = useState(0);
  const [teamsTotalPages, setTeamsTotalPages] = useState(0);
  const [filtersData, setFiltersData] = useRecoilState(driversFiltersDataAtom);
  const userData = useRecoilValue(userDataAtom) as IUserData;

  const formMethods = useForm<TDriverFiltersForm>({
    resolver: yupResolver(formSchema()),
    defaultValues: {
      ...filtersData,
      companyIds: filtersData.companyIds ? filtersData.companyIds[0] : null,
      userIds: filtersData.userIds ? filtersData.userIds[0] : null,
      teamIds: filtersData.teamIds ? filtersData.teamIds[0] : null,
    },
  });

  /**
   * Reset filters by user role
   * @type {(function(): void)|*}
   */
  const handleResetFilters = useCallback(() => {
    if (
      ![
        ROLES_OBJECT.TECH_ADMIN,
        ROLES_OBJECT.ADMIN,
        ROLES_OBJECT.OWNER,
        ROLES_OBJECT.OPERATOR,
      ].includes(userData.role)
    ) {
      formMethods.reset({
        ...initValues,
        companyIds: userData.companyId,
        userIds: userData.id,
      });
      return;
    }
    formMethods.reset(initValues);
  }, [formMethods, userData.companyId, userData.id, userData.role]);

  /**
   * Get managers for selected company
   */
  const getManagers = useCallback(async (companyId: string | null, page: number | null) => {
    try {
      let response;
      let content;

      if (companyId) {
        content = await getCompanyUsersReq(companyId);
      } else {
        const res = await getSystemUsersReq(page ?? 0, { enabled: true });
        response = res;
        content = res.content;
      }

      const formattedResponse = content?.map((item) => {
        return {
          value: item.id,
          companyId: item.companyId,
          label: `${item.firstName} ${item.lastName}`,
        };
      });

      setManagersListTotalPages(response?.totalPages ?? 0);
      setManagersListPage(response?.number ?? 0);
      if (!response?.number || response?.number === 0) {
        setManagers(formattedResponse);
      } else {
        setManagers((prevState) => [...prevState, ...formattedResponse]);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  /**
   * Get teams
   */
  const getTeams = useCallback(async (page = 0) => {
    try {
      const response = await teamsGetReq(page);

      const formattedResponse = response.content.map((team) => {
        return {
          value: team.teamId,
          label: team.name,
        };
      });

      if (response.number === 0) {
        setTeams(formattedResponse);
      } else {
        setTeams((prev) => [...prev, ...formattedResponse]);
      }
      setTeamsPage(response.number);
      setTeamsTotalPages(response.totalPages);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    getTeams(teamsPage);
  }, [getTeams, teamsPage]);

  /**
   * Getting a list of managers with data loading when scrolling
   */
  useEffect(() => {
    getManagers(formMethods.watch('companyIds') ?? null, managersListPage);
  }, [formMethods, getManagers, managersListPage]);

  // TODO: need refactor types after add form schema
  const onSubmitForm = (data: TDriverFiltersForm) => {
    setFiltersData((prevState) => ({
      ...prevState,
      ...data,
      companyIds: data.companyIds ? [data.companyIds] : null,
      teamIds: data.teamIds ? [data.teamIds] : null,
      userIds: data.userIds ? [data.userIds] : null,
    }));
    setShowFilter(false);
  };

  return (
    <div className={classes.wrap} data-testid='drivers-filters'>
      <FormProvider {...formMethods}>
        <Select
          items={Array.isArray(subcompaniesSelectList) ? subcompaniesSelectList : []}
          name={'companyIds'}
          title={t('company.label')}
          disabled={
            ![
              ROLES_OBJECT.TECH_ADMIN,
              ROLES_OBJECT.ADMIN,
              ROLES_OBJECT.OWNER,
              ROLES_OBJECT.OPERATOR,
            ].includes(userData.role)
          }
          additionalOnChange={(val) => {
            formMethods.setValue('userIds', null);
            getManagers(val.target.value, null);
          }}
        />
        <Select
          items={managers ?? []}
          name={'userIds'}
          title={t('manager.label')}
          disabled={
            ![
              ROLES_OBJECT.TECH_ADMIN,
              ROLES_OBJECT.ADMIN,
              ROLES_OBJECT.OWNER,
              ROLES_OBJECT.OPERATOR,
            ].includes(userData.role)
          }
          additionalOnChange={(val) => {
            const managerCompanyId = managers.find((manager) => manager.value === val.target.value)
              ?.companyId;
            if (!formMethods.watch('companyIds')) {
              formMethods.setValue('companyIds', managerCompanyId!);
              getManagers(managerCompanyId!, null);
            }
          }}
          onScroll={(e) => {
            const target = e.target as HTMLDivElement;
            const isBottom = target.scrollHeight - target.scrollTop - target.offsetHeight < 1;
            if (isBottom) {
              setManagersListPage((prevState) => {
                if (prevState < managersListTotalPages - 1) {
                  return prevState + 1;
                }
                return prevState;
              });
            }
          }}
        />

        <Select
          items={teams ?? []}
          name={'teamIds'}
          title={t('teams.label')}
          onScroll={(e) => {
            const target = e.target as HTMLDivElement;
            const isBottom = target.scrollHeight - target.scrollTop - target.offsetHeight < 1;
            if (isBottom) {
              setTeamsPage((prevState) => {
                if (prevState < teamsTotalPages - 1) {
                  return prevState + 1;
                }
                return prevState;
              });
            }
          }}
        />

        <ModalActions
          handleClose={() => setShowFilter(false)}
          handleSave={formMethods.handleSubmit(onSubmitForm)}
          handleReset={handleResetFilters}
        />
      </FormProvider>
    </div>
  );
};

export default DriversFilter;
