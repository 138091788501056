import { makeStyles } from '@mui/styles';

import theme, { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    display: 'flex',
    flexDirection: 'row',
    padding: '36px 38px 0 38px',
  },

  avatar: {
    position: 'relative',
    width: 120,
    height: 120,
  },

  dropzone: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'transparent',
    borderRadius: '50%',
    cursor: 'pointer',
  },

  dropzoneActive: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: palette.custom.bluePrimary,
    borderRadius: '50%',
    outline: `1px dashed ${theme.palette.primary.main}`,

    '& svg': {
      width: 50,
      fill: theme.palette.primary.main,
    },
  },

  dropzoneAvatar: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    borderRadius: '50%',
  },

  form: {
    width: '100%',
    maxWidth: 845,
    marginLeft: 33,
  },

  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 30,
    rowGap: 25,
  },

  passwordBtn: {
    marginTop: 17,
    height: 47,
    width: '160px !important',
  },

  separator: {
    marginTop: 38,
    marginBottom: 47,
    borderBottom: `1px dashed ${palette.custom.gray}`,
  },

  submit: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: 30,
    marginTop: 28,
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },
}));

export default useStyles;
