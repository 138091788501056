export enum NOTIFICATION_NOT_READ_TYPES {
  CRASH = 'CRASH',
  PENALTY_CARD = 'PENALTY_CARD',
  RESET_PASSWORD = 'RESET_PASSWORD',
  CONTACT_US = 'CONTACT_US',
  CAN_CONTROL = 'CAN_CONTROL',
  SENSOR = 'SENSOR',
  CLOWN = 'CLOWN',
  SPEEDING = 'SPEEDING',
  DEVICE_COMMAND = 'DEVICE_COMMAND',
  DRIVER_LOGIN_REGISTRATION_CODE = 'DRIVER_LOGIN_REGISTRATION_CODE',
  TOTAL = 'TOTAL', // this type is used only on frontend
  DEVICE_POWER = 'DEVICE_POWER',
}

export enum NOTIFICATION_TYPES {
  RED_CARD = 'RED',
  YELLOW_CARD = 'YELLOW',
  CRASH = 'CRASH',
  BELT = 'BELT',
  BOOT = 'BOOT',
  DOOR = 'DOOR',
  FUEL_FRAUD = 'FUEL_FRAUD',
  HOOD = 'HOOD',
  OIL_LEVEL = 'OIL_LEVEL',
  ENGINE_TEMPERATURE = 'ENGINE_TEMPERATURE',
  WINDOW = 'WINDOW',
  TEMPERATURE = 'TEMPERATURE',
  HUMIDITY = 'HUMIDITY',
  MOVEMENT = 'MOVEMENT',
  SPEEDING = 'SPEEDING',
  UNPLUG = 'UNPLUG',
  IGNITION_ON = 'IGNITION_ON',
  IGNITION_OFF = 'IGNITION_OFF',
}

export type TNotificationNotRead = {
  [field in NOTIFICATION_NOT_READ_TYPES]?: number;
};
