import server from 'utils/server';

export interface IFuelPrice {
  country: string;
  currency: string;
  fuelType: string;
  gasolineType: number | null;
  price: number;
}

export interface IFuelPriceFormData {
  [key: string]: IFuelPrice;
}
export const getCountryFuelData = async (country: string) => {
  return server.services.beService
    .get<IFuelPrice[]>(`settings/countries/${country}/fuel/prices`)
    .then((res) =>
      res.data.reduce((acc: IFuelPriceFormData, item: IFuelPrice) => {
        acc[`${item.fuelType}${item.gasolineType ?? ''}`] = item;
        return acc;
      }, {}),
    );
};
