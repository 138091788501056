import { TFunction } from 'react-i18next';

export const getColumnWidth = (widthInPX: number, tableWidth = 1230) =>
  `${(widthInPX / tableWidth) * 100}%`;

export const TABLE_HEAD_COLUMNS = (t: TFunction, isMetricSystem: boolean) =>
  ({
    info: [
      {
        id: 'plateNumber',
        label: t('vehicle.table.header.label'),
        styles: { minWidth: 300, width: getColumnWidth(400) },
        align: 'left',
        isSort: true,
      },
      {
        id: 'imei',
        label: t('imei.table.header.label'),
        styles: { minWidth: 125, width: getColumnWidth(126.6) },
        align: 'center',
        isSort: true,
      },
      {
        id: 'vin',
        label: t('vin.table.header.label'),
        styles: { minWidth: 125, width: getColumnWidth(126.6) },
        align: 'center',
        isSort: true,
      },
      {
        id: 'activity',
        label: t('activity.table.header.label'),
        styles: { minWidth: 125, width: getColumnWidth(126.6) },
        align: 'center',
        isSort: true,
      },
      {
        id: 'totalKm',
        label: t(`odometer.table.header.${isMetricSystem ? '' : 'mile.'}label`),
        styles: { minWidth: 125, width: getColumnWidth(126.6) },
        align: 'center',
        isSort: true,
      },
      {
        id: 'engineHoursBySecond',
        label: t('motoH.table.header.label'),
        styles: { minWidth: 125, width: getColumnWidth(126.6) },
        align: 'center',
        isSort: true,
      },
      {
        id: 'registration',
        label: t('registration.table.header.label'),
        styles: { minWidth: 125, width: getColumnWidth(126.6) },
        align: 'center',
        isSort: true,
      },
    ],
    statistic: [
      {
        id: 'plateNumber',
        label: t('vehicle.table.header.label'),
        styles: { minWidth: 300, width: getColumnWidth(400) },
        align: 'left',
        isSort: true,
      },
      {
        id: 'crashCount',
        label: t('problems.table.header.label'),
        styles: { minWidth: 200, width: getColumnWidth(200) },
        align: 'center',
        isSort: false,
      },
      {
        id: 'driveTime',
        label: t('driveTime.table.header.label'),
        styles: { minWidth: 140, width: getColumnWidth(140) },
        align: 'center',
        isSort: true,
      },
      {
        id: 'idleTime',
        label: t('idle.table.header.label'),
        styles: { minWidth: 140, width: getColumnWidth(140) },
        align: 'center',
        isSort: true,
      },
      {
        id: 'parkingTime',
        label: t('parking.time.table.header.label'),
        styles: { minWidth: 140, width: getColumnWidth(140) },
        align: 'center',
        isSort: true,
      },
      {
        id: 'fuel',
        label: t(`fuel.table.header.${isMetricSystem ? '' : 'gal.'}label`),
        styles: { minWidth: 140, width: getColumnWidth(140) },
        align: 'center',
        isSort: false,
      },
    ],
  }) as const;
