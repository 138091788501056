import { StyledEngineProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';

import useStyles from './styles';

const TextArea = ({
  control,
  name,
  required,
  pattern,
  label,
  error,
  rows,
  className,
  isDisabled,
}) => {
  const classes = useStyles();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=''
      rules={{ required: required, pattern: pattern }}
      render={({ field: { onChange, value } }) => (
        <StyledEngineProvider injectFirst>
          <TextField
            disabled={isDisabled}
            className={className}
            label={label}
            value={value ?? ''}
            onChange={onChange}
            error={error}
            multiline
            rows={rows}
            variant='filled'
            id='filled-multiline-static'
            InputLabelProps={{
              className: classes.placeholder,
            }}
            InputProps={{
              classes: {
                root: classes.root,
                input: classes.input,
                underline: classes.underline,
              },
            }}
          />
        </StyledEngineProvider>
      )}
    />
  );
};

TextArea.defaultProps = {
  name: 'message',
  required: false,
  label: '',
  pattern: false,
  error: false,
  rows: 3,
  isDisabled: false,
};

TextArea.propTypes = {
  rows: PropTypes.number,
  name: PropTypes.string,
  required: PropTypes.any,
  error: PropTypes.any,
  label: PropTypes.any,
  pattern: PropTypes.any,
  control: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
};

export default React.memo(TextArea);
