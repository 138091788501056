import { yupResolver } from '@hookform/resolvers/yup';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import DialogContent from '@mui/material/DialogContent';
import Grow from '@mui/material/Grow';
import Modal from '@mui/material/Modal';
import cx from 'classnames';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { BUTTON_VARIANTS } from 'interfaces/common/button-variants';
import { TSensorRenderData } from 'interfaces/sensor/t-sensor-render-data';

import { ReactComponent as BellIcon } from 'assets/images/bell.svg';

import { valueConverter } from 'utils/helpers/value-converter';

import sensorAvailableNumbersAtom from 'recoil/sensor-available-numbers';
import vehicleDirtySensorsAtom from 'recoil/vehicles/dirty-sensors';

import Button from 'components/form-v2/button';

import Input from '../form-v2/input';
import Select from '../form-v2/select';
import Slider from '../form-v2/slider';
import SwitchWithController from '../form-v2/switch-with-controller';
import TemperatureSwitch from '../form-v2/temperature-switch';

import { formSchema } from './form-schema';
import useStyles from './styles';

interface IProps {
  vehicleId: string;
  sensorData: TSensorRenderData;
  changeSensorDataHandler: (data: TSensorRenderData) => void;
  createSensor: (data: TSensorRenderData) => void;
  deleteSensor: (data: TSensorRenderData) => void;
  index: number;
  disabled?: boolean;
}

const Sensor: FC<IProps> = ({
  vehicleId,
  sensorData,
  changeSensorDataHandler,
  createSensor,
  deleteSensor,
  index,
  disabled,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('sensor.service');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [expanded, setExpanded] = useState(!sensorData.id);
  const sensorAvailableNumbers = useRecoilValue(sensorAvailableNumbersAtom);
  const setVehicleDirtySensors = useSetRecoilState(vehicleDirtySensorsAtom);

  const formMethods = useForm<TSensorRenderData>({
    defaultValues: sensorData,
    resolver: yupResolver(formSchema()),
  });

  useEffect(() => {
    setVehicleDirtySensors((prev) => {
      const res = [...prev];
      res[index] = formMethods.formState.isDirty;
      return res;
    });
  }, [index, setVehicleDirtySensors, formMethods.formState.isDirty]);

  useEffect(() => {
    const subscription = formMethods.watch((value) => {
      changeSensorDataHandler(value as TSensorRenderData);
    });
    return () => subscription.unsubscribe();
  }, [changeSensorDataHandler, sensorData.id, formMethods, formMethods.formState.isDirty]);

  const sensorList = useMemo(() => {
    if (sensorData.sensorNumber) {
      return [
        { label: sensorData.sensorNumber.replaceAll('_', ' '), value: sensorData.sensorNumber },
        ...sensorAvailableNumbers,
      ];
    }
    return sensorAvailableNumbers;
  }, [sensorAvailableNumbers, sensorData.sensorNumber]);

  return (
    <div className={classes.wrap} data-testid='sensor-wrap'>
      <Accordion className={classes.accordion} expanded={expanded}>
        <FormProvider {...formMethods}>
          <AccordionSummary
            className={classes.header}
            classes={{ content: classes.headerContent, focusVisible: classes.headerFocus }}
            aria-controls='panel1d-content'
            expandIcon={
              <ExpandMoreIcon
                className={classes.accordionArrow}
                onClick={() => setExpanded((prev) => !prev)}
                data-testid='button-more'
              />
            }
          >
            <div className={classes.title}>{t('sensor.label')}</div>

            <Input
              placeholder={t('id.placeholder.label')}
              title={''}
              name={'sensorName'}
              disabled={Boolean(sensorData?.id) || !vehicleId || disabled}
            />

            <Select
              title={''}
              name={'sensorNumber'}
              items={sensorList}
              wrapClass={classes.sensorNumber}
              disabled={disabled}
            />

            <div className={classes.notificationWrap}>
              <div>{t('notify.label')}</div>
              <SwitchWithController
                name={'notification'}
                LeftIcon={BellIcon}
                RightIcon={BellIcon}
                disabled={!vehicleId || disabled}
              />
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionContent}>
            <div className={classes.parameter}>
              <div className={cx(classes.header, classes.parameterHeader)}>
                <SwitchWithController
                  name={'temperature.active'}
                  disabled={!vehicleId || disabled}
                />

                <div className={classes.parameterTitle}>{t('temperature.title.label')}</div>

                <TemperatureSwitch
                  name={'temperature.celsius'}
                  disabled={!formMethods.watch('temperature.active') || disabled}
                  additionalOnChange={(event) => {
                    const temperature = formMethods.getValues('temperature.value');
                    formMethods.setValue(
                      'temperature.value',
                      temperature.map((item) => {
                        return event.target.checked
                          ? valueConverter.temperature.fahrenheitToCelsius(item)
                          : valueConverter.temperature.celsiusToFahrenheit(item);
                      }) as [number, number],
                    );
                  }}
                />
              </div>
              <Slider
                name={'temperature.value'}
                min={
                  formMethods.watch('temperature.celsius')
                    ? -20
                    : valueConverter.temperature.celsiusToFahrenheit(-20)
                }
                max={
                  formMethods.watch('temperature.celsius')
                    ? 60
                    : valueConverter.temperature.celsiusToFahrenheit(60)
                }
                marks={[
                  {
                    value: formMethods.watch('temperature.celsius')
                      ? -20
                      : valueConverter.temperature.celsiusToFahrenheit(-20),
                    label: formMethods.watch('temperature.celsius')
                      ? -20
                      : valueConverter.temperature.celsiusToFahrenheit(-20),
                  },
                  {
                    value: 0,
                    label: 0,
                  },
                  {
                    value: formMethods.watch('temperature.celsius')
                      ? 60
                      : valueConverter.temperature.celsiusToFahrenheit(60),
                    label: formMethods.watch('temperature.celsius')
                      ? 60
                      : valueConverter.temperature.celsiusToFahrenheit(60),
                  },
                ]}
                disabled={!formMethods.watch('temperature.active') || disabled}
              />
            </div>

            <div className={classes.parameter}>
              <div className={cx(classes.header, classes.parameterHeader)}>
                <SwitchWithController name={'humidity.active'} disabled={!vehicleId || disabled} />

                <div className={classes.parameterTitle}>{t('humidity.title.label')}</div>

                <div className={classes.parameterSubtitle}>%</div>
              </div>

              <Slider
                name={'humidity.value'}
                min={0}
                max={100}
                disabled={!formMethods.watch('humidity.active') || disabled}
                marks={[
                  {
                    value: 0,
                    label: '0',
                  },
                  {
                    value: 100,
                    label: '100',
                  },
                ]}
              />
            </div>

            <div className={classes.parameter}>
              <div className={cx(classes.header, classes.parameterHeader, 'isMovement')}>
                {/* remove true after implement movement functionality*/}
                <SwitchWithController name={'movement.active'} disabled={true || disabled} />

                <div className={classes.parameterTitle}>{t('movement.title.label')}</div>

                <div className={classes.parameterSubtitle}>dB</div>
              </div>

              <div className={classes.selectWrap}>
                <Select
                  title={''}
                  name={'movement.zones'}
                  items={[]}
                  disabled={!formMethods.watch('movement.active') || disabled}
                />
                <Select
                  title={''}
                  name={'movement.subzones'}
                  items={[]}
                  disabled={!formMethods.watch('movement.active') || disabled}
                />
              </div>

              <Slider
                name={'movement.value'}
                min={0}
                max={100}
                disabled={!formMethods.watch('movement.active') || disabled}
                marks={[
                  {
                    value: 0,
                    label: '0',
                  },
                  {
                    value: 100,
                    label: '100',
                  },
                ]}
              />
            </div>

            {!sensorData.id && vehicleId && (
              <button
                className={classes.btn}
                onClick={formMethods.handleSubmit(createSensor)}
                disabled={disabled}
                data-testid='button-add-sensor'
              >
                {t('create.label')}
              </button>
            )}
            {!vehicleId && <div className={classes.title}>{t('create.error.label')}</div>}

            {sensorData.id && vehicleId && (
              <div
                className={classes.deleteBtn}
                onClick={() => setIsOpenModal(true)}
                data-testid='button-delete-sensor'
              >
                {t('delete.label')}
              </div>
            )}
          </AccordionDetails>
        </FormProvider>
      </Accordion>

      <Modal
        className={classes.modal}
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        closeAfterTransition
      >
        <Grow in={isOpenModal} timeout={250}>
          <DialogContent className={classes.dialogContent}>
            <div className={classes.label}>{t('delete.question.label')}</div>
            <div className={classes.btns}>
              <Button
                className={classes.modalBtn}
                variant={BUTTON_VARIANTS.OUTLINE}
                onClick={() => setIsOpenModal(false)}
                testId='button-cancel'
              >
                {t('cancel.label')}
              </Button>
              <Button
                className={classes.modalBtn}
                onClick={formMethods.handleSubmit(deleteSensor)}
                testId='button-submit'
              >
                {t('delete.submit.label')}
              </Button>
            </div>
          </DialogContent>
        </Grow>
      </Modal>
    </div>
  );
};

export default Sensor;
