import { atom } from 'recoil';

import { PENALTY_CARD } from 'interfaces/common/penalty-card';

import { IGetDriverTrip } from 'requests/be-service/map-view-controller/get-trip-points';

interface IDriverStatistics {
  distance: [number];
  customer: null;
  driver: null;
  geofencing: number;
  maxSpeed: [number];
  penaltyCard: null | PENALTY_CARD;
  saving: [null];
  totalScore: number;
  usage: [number | null];
}

interface IRouteStartEnd {
  address: string;
  date: {
    day: string;
    time: string;
  };
}

interface ITripRoute {
  start: IRouteStartEnd;
  end: IRouteStartEnd;
}

export interface IDriverTrip {
  statistics: IDriverStatistics;
  route: ITripRoute;
  id: string;
  maxSpeedPoint?: IGetDriverTrip['maxSpeedPoint'];
}
const driverTripAtom = atom<IDriverTrip>({
  key: 'driverTripAtom',
  default: {
    statistics: {
      distance: [0],
      driver: null,
      customer: null,
      maxSpeed: [0],
      usage: [null],
      saving: [null],
      geofencing: 0,
      totalScore: 0,
      penaltyCard: null,
    },
    route: {
      start: {
        address: '',
        date: {
          day: '',
          time: '',
        },
      },
      end: {
        address: '',
        date: {
          day: '',
          time: '',
        },
      },
    },
    id: '',
    maxSpeedPoint: null,
  },
});

export default driverTripAtom;
