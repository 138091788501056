import React from 'react';
import { Navigate } from 'react-router-dom';

import { FCC } from 'interfaces/common/fc-with-children';

interface IConditionalRenderWrapper {
  condition: boolean;
  redirectPath?: string;
  state?: object;
}

const ConditionalRenderWrapper: FCC<IConditionalRenderWrapper> = ({
  condition,
  redirectPath,
  children,
  state,
}) => {
  if (!condition) {
    if (redirectPath) {
      return <Navigate to={redirectPath} state={state} />;
    } else {
      return null;
    }
  }

  return <>{children}</>;
};

export default ConditionalRenderWrapper;
