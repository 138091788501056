import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IPolygonData } from 'interfaces/common/polygon-data';

import { ReactComponent as PlusIcon } from 'assets/images/plus.svg';
import { ReactComponent as UploadIcon } from 'assets/images/upload.svg';

import { getSpeedPolygonsReq } from 'requests/gps-service/poligon-service/find-all-polygons';
import { uploadSpeedPolygonsReq } from 'requests/gps-service/poligon-service/import-polygon';

import Loader from 'components/Loader';

import Header from '../../components/header';

import Modal from './Modal';
import PolygonsTable from './PolygonsTable';
import useStyles from './styles';

const SpeedManagement = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('setting.page');

  const [isFetching, setIsFetching] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [polygons, setPolygons] = useState<IPolygonData[]>([]);

  /**
   * Get polygons data for table
   */
  useEffect(() => {
    const request = async () => {
      try {
        setIsFetching(true);
        const result = await getSpeedPolygonsReq();
        setPolygons(result);
      } catch (error) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      } finally {
        setIsFetching(false);
      }
    };

    request();
  }, [enqueueSnackbar]);

  /**
   * Upload validate file
   */
  const onInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      const currentFile = event.target.files?.[0];
      if (!currentFile) return;

      const isValidateFile = currentFile.name.substring(currentFile.name.length - 4) === '.kml';

      if (!isValidateFile) {
        enqueueSnackbar('Wrong file type.', { variant: 'error' });
        return;
      }

      setFile(currentFile);
    },
    [enqueueSnackbar],
  );

  const openModal = () => setIsOpenModal(true);
  const closeModal = useCallback(() => setIsOpenModal(false), []);

  /**
   * Send file to API
   */
  const onSubmit = useCallback(async () => {
    try {
      if (!file) return;

      setIsFetching(true);
      setPolygons([]);
      closeModal();
      const result = await uploadSpeedPolygonsReq(file);

      setPolygons(result);
      setFile(null);
      enqueueSnackbar('Success', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    } finally {
      setIsFetching(false);
    }
  }, [closeModal, enqueueSnackbar, file]);

  return (
    <div className={classes.container}>
      <Header backBtn={{ label: t('speed.management.label') }} />

      <div className={classes.content}>
        <div className={classes.controls}>
          {file && (
            <div className={classes.fileName} data-testid='fileName'>
              {file.name}
            </div>
          )}

          <IconButton component='label' data-testid='button-add'>
            <PlusIcon />
            <input type='file' hidden onChange={onInputChange} accept='.kml' />
          </IconButton>

          {file && (
            <IconButton onClick={openModal} data-testid='button-upload'>
              <UploadIcon />
            </IconButton>
          )}
        </div>

        {polygons && <PolygonsTable polygons={polygons} />}

        <Modal
          isOpenModal={isOpenModal}
          fileName={file?.name ?? ''}
          closeModal={closeModal}
          onSubmit={onSubmit}
        />

        {isFetching && <Loader width={150} preventClick lightMode />}
      </div>
    </div>
  );
};

export default SpeedManagement;
