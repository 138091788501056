import { atom } from 'recoil';

import { TDataWithId } from 'interfaces/common/data-with-id';

import { TDriverCreateData } from 'modules/DriverProfile/form-schema';

const settingQuickEditDriverInfoAtom = atom<TDataWithId<TDriverCreateData> | null>({
  key: 'settingQuickEditDriverInfoAtom',
  default: null,
});

export default settingQuickEditDriverInfoAtom;
