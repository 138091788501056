import { TDateISO } from 'interfaces/common/date-iso';
import { IDriverData } from 'interfaces/driver/i-driver-data';
import { ITrip } from 'interfaces/map/i-trip';
import { IBasePointCoordinates, IPointCoordinates } from 'interfaces/map/point';

import server from 'utils/server';

// TODO: need refactor interface
export interface IGetDriverTrip {
  driverId: IDriverData['id'];
  trip: ITrip;
  points: (IPointCoordinates & { speed: number })[];
  greenPoints: IPointCoordinates[];
  tripSaving: null;
  maxSpeedPoint:
    | (IBasePointCoordinates & {
        currentSpeed: number;
        maxSpeed: number;
        timestamp: TDateISO;
      })
    | null;
}

/**
 * Retrieves trip requests from the server for a given ID.
 *
 * @async
 * @param {string} id - The ID of the trip to retrieve requests for.
 * @returns {Promise<IGetDriverTrip>} - A promise that resolves to the retrieved trip requests.
 */
export const getTripReq = async (id: string): Promise<IGetDriverTrip> =>
  server.services.beService
    .get<IGetDriverTrip>(`map/trips/${id}/points`)
    .then<IGetDriverTrip>(({ data }) => ({
      ...data,
      points: data?.points?.length === 1 ? [...data.points, ...data.points] : data?.points || [],
    }));
