import React, { useState } from 'react';

import { ReactComponent as MinusIcon } from 'assets/images/minus.svg';
import { ReactComponent as PlusIcon } from 'assets/images/plus.svg';

import VehicleList from '../vehicle-list';

import useStyles from './styles';

const Team = ({ team, vehicleCheckboxHandler, watch }) => {
  const classes = useStyles();
  const [isOpenList, setIsOpenList] = useState(false);

  return (
    <div className={classes.wrap} data-testdid={`team-item-${team.teamId}`}>
      <div
        className={classes.team}
        onClick={() => setIsOpenList((prev) => !prev)}
        data-testdid='button-toggle'
      >
        {!isOpenList ? (
          <PlusIcon className={classes.icon} />
        ) : (
          <MinusIcon className={classes.icon} />
        )}

        <div data-testdid='teamName'>{team.name}</div>
      </div>

      {isOpenList && (
        <VehicleList
          vehicleCheckboxHandler={vehicleCheckboxHandler}
          watch={watch}
          height={'320px'}
          teamId={team.teamId}
        />
      )}
    </div>
  );
};

Team.propTypes = {};

Team.defaultProps = {};

export default Team;
