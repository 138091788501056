import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

type Props = {
  technicalMessageBlockHeight: number;
};

const useStyles = makeStyles<Theme, Props>(() => ({
  wrap: {
    width: '100%',
    height: '100%',
  },

  scrollbar: {
    height: ({ technicalMessageBlockHeight }) => {
      return `calc(100vh - 48px - ${technicalMessageBlockHeight}px)`;
    },
  },

  content: {
    height: ({ technicalMessageBlockHeight }) => {
      return `calc(100vh - 48px - ${technicalMessageBlockHeight}px)`;
    },
  },

  map: {
    width: '100%',
    height: '110vh',
    marginTop: '-150px',

    '& iframe': {
      pointerEvents: 'none',
      width: '100%',
      height: '100%',
    },
  },
}));

export default useStyles;
