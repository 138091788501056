import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  header: {
    width: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
    position: 'relative',
  },

  btns: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 10,
  },

  btn: {
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },

  shadow: {
    width: '100%',
    height: 6,
    position: 'absolute',
    left: 0,
    bottom: 0,
    background: 'linear-gradient(180deg, #000000 0%, rgba(35, 45, 70, 0) 100%)',
    opacity: 0.1,
  },

  list: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 20,
  },

  emptyMessage: {
    fontSize: 18,
    fontWeight: 400,
    color: palette.custom.blue200,
    textAlign: 'center',
  },
}));

export default useStyles;
