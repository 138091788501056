import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import cx from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import SimpleBar from 'simplebar-react';

import { IUserData } from 'interfaces/user/user-data';

import { ROLES_OBJECT } from 'constants/user';

import { ReactComponent as NextIcon } from 'assets/images/next.svg';

import RouteManager from 'utils/services/route-manager';

import scoringRatingChartAtom from 'recoil/scoring/scoringRatingChart';
import userDataAtom from 'recoil/userData';

import { scoringRatingDriversReq } from 'requests/be-service/scoring-controller/get-top-bad-drivers';

import ActivityStatus from 'components/activity-statuses';
import DiffScore from 'components/diff-score';
import Loader from 'components/Loader';
import ScoreValue from 'components/score-value';
import VehicleInfo from 'components/VehicleInfo';

import useStyles from './styles';

const DriversList = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation(['scoring.page', 'saving.page']);
  const [scoringRatingChart, setScoringRatingChart] = useRecoilState(scoringRatingChartAtom);
  const resetScoringRatingChart = useResetRecoilState(scoringRatingChartAtom);
  const [loader, setLoader] = useState(false);
  const [active, setActive] = useState('Driver');
  const userData = useRecoilValue(userDataAtom) as IUserData;

  useEffect(() => {
    const req = async () => {
      setLoader(true);
      try {
        const response = await scoringRatingDriversReq();
        setScoringRatingChart(response);
      } catch {
        console.log('Scoring Rating Error');
      } finally {
        setLoader(false);
      }
    };

    req();

    return () => resetScoringRatingChart();
  }, [resetScoringRatingChart, setScoringRatingChart]);

  const handleOpenDriverTrips = (driverId: string) => {
    if (
      [ROLES_OBJECT.TECH_ADMIN, ROLES_OBJECT.ADMIN, ROLES_OBJECT.OWNER].includes(userData?.role)
    ) {
      navigate(RouteManager.makeURL('drivers.trips', { id: driverId }));
    }
  };

  const handleLinkButton = () => {
    if (active === 'Driver') {
      navigate(RouteManager.path('drivers', { hasLeadingSlash: true }));
    } else {
      navigate(RouteManager.path('vehicles', { hasLeadingSlash: true }));
    }
  };

  const handleActiveMarker = (vehicleId: string) => {
    if ([ROLES_OBJECT.TECH_ADMIN, ROLES_OBJECT.ADMIN, ROLES_OBJECT.OWNER].includes(userData.role)) {
      navigate(RouteManager.makeURL('dashboardMap.live', { id: vehicleId }));
    }
  };

  const handleVehicleClick = useCallback(
    (id: string) => {
      if (
        [ROLES_OBJECT.TECH_ADMIN, ROLES_OBJECT.ADMIN, ROLES_OBJECT.OWNER].includes(userData.role)
      ) {
        navigate(RouteManager.makeURL('vehicles.edit', { id }));
      }
    },
    [navigate, userData.role],
  );

  if (loader) {
    return (
      <div className={classes.drivers} data-testid='drivers-list'>
        <Loader width={100} lightMode isBlock />
      </div>
    );
  }

  return (
    <div className={classes.drivers} data-testid='drivers-list'>
      <div className={classes.sorting}>
        <div
          data-testid='tabItem'
          className={cx(classes.sortingName, active === 'Driver' && classes.active)}
          onClick={() => setActive('Driver')}
        >
          {t('drivers.label')}
        </div>
        <div
          data-testid='tabItem'
          className={cx(classes.sortingName, active === 'Vehicle' && classes.active)}
          onClick={() => setActive('Vehicle')}
        >
          {t('vehicles.label')}
        </div>
      </div>
      <SimpleBar className={classes.scrollbar}>
        {(scoringRatingChart?.length === 0 || !scoringRatingChart) && (
          <div className={classes.emptyWrap}>{t('saving.page:no.data.label')}</div>
        )}
        {scoringRatingChart?.map((item) => (
          <div key={item.driverId + item.vehicleId + item.companyId} className={classes.item}>
            <div className={classes.block}>
              <div
                className={classes.circleMarker}
                data-testid='activityStatus'
                onClick={() => handleActiveMarker(item.vehicleId)}
              >
                <ActivityStatus value={item.status} variant={'fill'} />
              </div>
              {active === 'Vehicle' ? (
                <VehicleInfo
                  country={item.country}
                  id={item?.vehicleId}
                  plateNumber={item?.plateNumber}
                  brandName={item?.brandName}
                  type={item?.type}
                  littleNumber
                  isLittlePlateNumber
                  handleOpen={() => handleVehicleClick(item?.vehicleId)}
                />
              ) : (
                <Tooltip title={`${item.firstName} ${item.lastName}`} arrow>
                  <div
                    data-testid='driverInfo'
                    onClick={() => handleOpenDriverTrips(item.driverId)}
                    className={classes.name}
                  >
                    <span>{item.firstName}</span>
                    <span>{item.lastName}</span>
                  </div>
                </Tooltip>
              )}
            </div>
            <div className={classes.cards} data-testid='penaltyCards'>
              {item?.penaltyCards?.map((color, i) => (
                <div key={i} className={cx(classes.card, `${classes.card}--${color}`)} />
              ))}
            </div>
            <div className={classes.values}>
              <ScoreValue value={item.currentScoring} className={classes.scoring} />

              <DiffScore
                wrapClassName={classes.delta}
                value={item.diffScoring}
                isBetter={item.better}
              />
            </div>
          </div>
        ))}
      </SimpleBar>
      <div className={classes.button}>
        <IconButton onClick={() => handleLinkButton()} data-testid='nextBtn'>
          <NextIcon />
        </IconButton>
      </div>
    </div>
  );
};

DriversList.propTypes = {};

export default React.memo(DriversList);
