import { selectorFamily } from 'recoil';

import tripPointsSelector from '../../points';

const tripRouteSelector = selectorFamily<google.maps.LatLngLiteral[], string>({
  key: 'tripRouteSelector',
  get:
    (tripId) =>
    ({ get }) => {
      const tripPoints = get(tripPointsSelector(tripId));
      return tripPoints.points.map(({ latitude, longitude }) => {
        return {
          lat: latitude,
          lng: longitude,
        };
      });
    },
});

export default tripRouteSelector;
