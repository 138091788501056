import server from 'utils/server';

/**
 * Retrieves the crash list for a given driver.
 *
 * @param {string} driverId - The ID of the driver.
 *
 * @returns {Promise<string[]>} The list of crashes for the driver, in reverse order.
 */
export const getDriverCrashListReq = async (driverId: string): Promise<string[]> =>
  server.services.gpsService
    .get<string[]>(`crashes/drivers/${driverId}`)
    .then(({ data }) => data.reverse());
