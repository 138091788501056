import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import settingQuickEditDriverInfoAtom from 'recoil/settings/drivers/disable/driver-info';
import settingDriversShowDisableModalAtom from 'recoil/settings/drivers/disable/show-modal';
import settingsDriverFilterAtom from 'recoil/settings/drivers/filters';
import settingDriversShowQuickEditAtom from 'recoil/settings/drivers/show-quick-edit';

import { disableDriverReq } from 'requests/be-service/driver-controller/disable-driver';

import Checkbox from 'components/form-v2/checkbox-with-controller';
import Input from 'components/form-v2/input';
import Loader from 'components/Loader';
import ModalActions from 'components/modal-actions';

import { formSchema, TDisableDriverForm } from './form-schema';
import useStyles from './styles';

interface IProps {}

const DisableModal: FC<IProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation('fields');

  const driverInfo = useRecoilValue(settingQuickEditDriverInfoAtom);
  const setFiltersData = useSetRecoilState(settingsDriverFilterAtom);
  const [isLoading, setIsLoading] = useState(false);
  const setShowDisableModal = useSetRecoilState(settingDriversShowDisableModalAtom);
  const setShowQuickEditDriver = useSetRecoilState(settingDriversShowQuickEditAtom);

  const formMethods = useForm<TDisableDriverForm>({
    resolver: yupResolver(formSchema()),
    defaultValues: {
      id: driverInfo?.id,
      includeDriverVehicle: false,
      active: !driverInfo?.active,
      comment: '',
    },
  });

  /**
   * Enable or disable driver
   * @param {TDisableDriverForm} data - The data to be processed.
   */
  const onSave = useCallback(
    async (data: TDisableDriverForm) => {
      try {
        setIsLoading(true);
        await disableDriverReq(data);

        setIsLoading(false);
        setShowDisableModal(false);
        setShowQuickEditDriver(false);

        setFiltersData((prevState) => ({
          ...prevState,
        }));
      } catch (e) {
        console.log(e);
      }
    },
    [setFiltersData, setShowDisableModal, setShowQuickEditDriver],
  );

  return (
    <FormProvider {...formMethods}>
      {isLoading && <Loader width={100} isBlock preventClick lightMode />}

      <div className={classes.title}>
        {t(`${driverInfo?.active ? 'disable' : 'enable'}.driver.title.label`, {
          driverName: `${driverInfo?.firstName} ${driverInfo?.lastName}`,
        })}
      </div>

      {driverInfo?.active && (
        <Checkbox
          wrapClass={classes.checkbox}
          name={'includeDriverVehicle'}
          testId={'includeDriverVehicle'}
          label={t('disable.assign.vehicle.label')}
        />
      )}

      <Input name={'comment'} type='text' multiline rows={3} title={t('comment.label')} />

      <ModalActions
        handleClose={() => {
          setShowDisableModal(false);
        }}
        handleSave={formMethods.handleSubmit(onSave)}
      />
    </FormProvider>
  );
};

export default DisableModal;
