import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { DEVICE_FUNCTION } from 'interfaces/vehicles/device-functions';

import { ReactComponent as ReportIcon } from 'assets/images/download.svg';

import driverShortDataSelector from 'recoil/driver/short-data';

import { tripReportReq } from 'requests/report-service/trip-report';

import Header from './conponents/header';
import Map from './conponents/map';
import Penalties from './conponents/penalties';
import Sensors from './conponents/sensors';
import useStyles from './styles';

const Trip = () => {
  const params = useParams() as { id: string; tripId: string };
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const driverData = useRecoilValue(
    driverShortDataSelector({ driverId: params.id, filters: null }),
  );

  const reportBtnHandler = useCallback(async () => {
    try {
      await tripReportReq(params.tripId);
      enqueueSnackbar('Request has been sent', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Save Report Error', { variant: 'error' });
    }
  }, [params.tripId, enqueueSnackbar]);

  return (
    <div className={classes.container}>
      <IconButton className={classes.reportBtn} onClick={reportBtnHandler}>
        <ReportIcon />
      </IconButton>

      <Header tripId={params.tripId} />

      <div className={classes.content}>
        <div className={classes.sidebar}>
          <Penalties tripId={params.tripId} />

          {driverData.vehicle.deviceFunctions.includes(
            DEVICE_FUNCTION.TEMPERATURE_HUMIDITY_SENSOR,
          ) && <Sensors tripId={params.tripId} />}
        </div>

        <Map tripId={params.tripId} />
      </div>
    </div>
  );
};

Trip.defaultProps = {};

Trip.propTypes = {};

export default Trip;
