import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ISettingPaymentCompany } from 'interfaces/company/setting-payment-company';

import DaysLeft from '../../components/daysLeft';
import { Money } from '../../components/money';

import useStyles from './styles';

const Company: FC<ISettingPaymentCompany> = ({ companyName, balance, daysLeftEstimation }) => {
  const classes = useStyles();
  const { t } = useTranslation('payment.page');

  return (
    <div className={classes.wrap}>
      <div className={classes.name} data-testid='company-name'>
        {companyName}
      </div>
      <div className={classes.estimation}>
        {t('company.days.estimation.label')}{' '}
        <DaysLeft days={daysLeftEstimation} className={classes.days} />
      </div>
      <div className={classes.money}>
        {balance ? <Money count={Number(balance?.toFixed(2))} /> : '-'}
      </div>
    </div>
  );
};

export default Company;
