import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  table: {
    position: 'relative',
    width: 'min-content',
    cursor: 'pointer',
    userSelect: 'none',
    whiteSpace: 'nowrap',

    '&.disabled': {
      pointerEvents: 'none',
    },
  },
  tableIcon: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 'calc(100% + 2px)',
    margin: 'auto',
  },
}));

export default useStyles;
