import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  mobiles: {
    position: 'absolute',
    bottom: 5,
    right: 5,
    display: 'flex',
    alignItems: 'center',
    columnGap: 30,
  },

  item: {
    fontWeight: 500,
    fontSize: 14,
    color: palette.custom.blue600,
    display: 'flex',
    alignItems: 'center',

    '&:last-child': {
      marginBottom: 0,
      whiteSpace: 'nowrap',
    },
  },

  label: {
    marginRight: 5,
  },

  number: {
    color: palette.custom.blue700,
    textDecoration: 'none',
  },
}));

export default useStyles;
