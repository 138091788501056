import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { SelectChangeEvent } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';

import { BUTTON_VARIANTS } from 'interfaces/common/button-variants';
import { ISelectItem } from 'interfaces/common/select-items';
import { USER_ROLES } from 'interfaces/user/roles';
import { IUserData } from 'interfaces/user/user-data';

import { ROLES_OBJECT } from 'constants/user';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/delete.svg';
import { ReactComponent as SettingIcon } from 'assets/images/gear.svg';
import { ReactComponent as PowerIcon } from 'assets/images/power.svg';

import { driverFormDTO } from 'utils/dto/driver/edit-form';
import RouteManager from 'utils/services/route-manager';

import settingQuickEditDriverInfoAtom from 'recoil/settings/drivers/disable/driver-info';
import settingDriversShowDisableModalAtom from 'recoil/settings/drivers/disable/show-modal';
import subcompaniesSelectListAtom from 'recoil/subcompanies/select-list';
import userConfigAtom from 'recoil/user-config';
import userDataAtom from 'recoil/userData';

import { vehicleBrandsReq } from 'requests/be-service/brand-model-controller/find-brands';
import { vehicleModelsReq } from 'requests/be-service/brand-model-controller/find-models-by-brand';
import { getCompanyUsersReq } from 'requests/be-service/company-controller/get-company-users';
import { driverGetReq } from 'requests/be-service/driver-controller/get-driver';
import { ISettingDriver } from 'requests/be-service/driver-controller/get-settings-drivers';
import { driverEditReq } from 'requests/be-service/driver-controller/update-driver';
import { getVehicleByBrandAndModelReq } from 'requests/be-service/vehicle-controller/find-by-brand-mode';
import { vehiclesAllReq } from 'requests/be-service/vehicle-controller/get-all-vehicle';
import { vehicleGetReq } from 'requests/be-service/vehicle-controller/get-vehicle-dto';

import Button from 'components/form-v2/button';
import Input from 'components/form-v2/input';
import Select from 'components/form-v2/select';
import Loader from 'components/Loader';

import { formSchema, TDriverCreateData } from 'modules/DriverProfile/form-schema';

import useStyles from './styles';

interface IProps {
  onClose: () => void;
  driver: ISettingDriver;
}

const QuickEdit: FC<IProps> = ({ driver, onClose }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation(['driver.details.page', 'fields', 'measurement.system.page']);
  const { enqueueSnackbar } = useSnackbar();

  const setShowDisableModal = useSetRecoilState(settingDriversShowDisableModalAtom);
  const [driverInfo, setDriverInfo] = useRecoilState(settingQuickEditDriverInfoAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [brands, setBrands] = useState<ISelectItem[] | null>(null);
  const [models, setModels] = useState<ISelectItem[] | null>(null);
  const { isMetricSystem } = useRecoilValue(userConfigAtom);
  const { contents: subcompaniesSelectList } = useRecoilValueLoadable(subcompaniesSelectListAtom);
  const userData = useRecoilValue(userDataAtom) as IUserData;
  const [managers, setManagers] = useState<ISelectItem[] | null>(null);
  const [vehicles, setVehicles] = useState<ISelectItem[] | null>(null);

  const formMethods = useForm<TDriverCreateData>({
    resolver: yupResolver(formSchema()),
  });

  const isSubcompanyView = Boolean(sessionStorage.getItem('subcompanyId'));
  const isActiveDriver = formMethods.watch('active');

  const editHandler = useCallback(() => {
    onClose();
    navigate(RouteManager.makeURL('drivers.edit', { id: driver.driverId }));
  }, [onClose, navigate, driver.driverId]);

  /**
   * Get managers for selected subcompany
   */
  const getCompanyManagers = useCallback(async (companyId: string) => {
    try {
      const response = await getCompanyUsersReq(companyId);
      setManagers(
        response.map((manager) => ({
          value: manager.id,
          label: `${manager.firstName} ${manager.lastName}`,
        })),
      );
    } catch (e) {
      console.log(e);
    }
  }, []);

  /**
   * Get managers, vehicles for selected company and reset manager and assign vehicle fields
   */
  const handleChangeCompany = async (e: SelectChangeEvent) => {
    try {
      formMethods.setValue('managedById', '');
      formMethods.setValue('driverVehicleDto.vehicleDto.id', '');
      formMethods.setValue('make', null);
      formMethods.setValue('model', null);
      await getCompanyManagers(e.target.value);
      const vehicleList = await vehiclesAllReq(e.target.value);
      const brandsList = await vehicleBrandsReq();
      setBrands(brandsList);
      setVehicles(vehicleList);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Get models for selected brand
   */
  const getModels = useCallback(async (brandId: number) => {
    try {
      const response = await vehicleModelsReq(brandId);
      setModels(response);
    } catch (e) {
      console.log(e);
    }
  }, []);

  /**
   * Set selected vehicle brand and model
   */
  const handleChangeAssignVehicle = useCallback(
    async (e: SelectChangeEvent | string) => {
      let value: string;
      if (typeof e === 'string') {
        value = e;
      } else {
        value = e.target.value;
      }
      try {
        const res = await vehicleGetReq(value);
        formMethods.setValue('make', res.generalInfo.brandId);
        await getModels(res.generalInfo.brandId);
        formMethods.setValue('model', res.generalInfo.modelId);
      } catch (error) {
        console.log(error);
      }
    },
    [formMethods, getModels],
  );

  /**
   * Get vehicle list when change brand or model fields
   * @param companyId
   * @param brandId
   * @param modelId
   */
  const getVehiclesByBrandAndModel = async (
    companyId: string,
    brandId: number,
    modelId?: number,
  ) => {
    try {
      formMethods.setValue('driverVehicleDto.vehicleDto.id', '');
      const res = await getVehicleByBrandAndModelReq(companyId, brandId, modelId);
      setVehicles(res);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Get initial driver data for edit page
   */
  useEffect(() => {
    const getDriverData = async () => {
      try {
        setIsLoading(true);
        const response = await driverGetReq(driver.driverId);
        setDriverInfo(response);

        await getCompanyManagers(response.companyId);
        const vehicleList = await vehiclesAllReq(response.companyId);
        const brandsList = await vehicleBrandsReq();
        setVehicles(vehicleList);
        setBrands(brandsList);
        if (response?.driverVehicleDto?.vehicleDto?.id) {
          handleChangeAssignVehicle(response?.driverVehicleDto?.vehicleDto?.id);
        }
        formMethods.reset(driverFormDTO.get(response));
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };

    getDriverData();
  }, [
    driver.driverId,
    formMethods,
    getCompanyManagers,
    handleChangeAssignVehicle,
    isMetricSystem,
    setDriverInfo,
  ]);

  const updateDriver = async (data: TDriverCreateData) => {
    try {
      setIsLoading(true);
      await driverEditReq(driver.driverId, driverFormDTO.post(data));
      enqueueSnackbar('Saved', { variant: 'success' });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div data-testid='driver-quick-edit-wrap'>
      {isLoading && <Loader lightMode width={150} isBlock preventClick />}

      <div className={classes.header}>
        <div
          className={classes.driverName}
          data-testid='driver-name'
        >{`${driver.firstName} ${driver.lastName}`}</div>

        <div className={classes.btns}>
          {userData.role === USER_ROLES.TECH_ADMIN && (
            <div
              className={classes.btn}
              onClick={() => setShowDisableModal(true)}
              data-testid='button-delete'
            >
              {driverInfo?.active && <DeleteIcon width={20} />}
              {!driverInfo?.active && (
                <PowerIcon className={classes.powerIcon} width={20} height={20} />
              )}
            </div>
          )}

          <div className={classes.btn} onClick={editHandler} data-testid='button-setting'>
            <SettingIcon width={20} />
          </div>
          <div className={classes.btn} onClick={onClose} data-testid='button-close'>
            <CloseIcon width={12} />
          </div>
        </div>

        <div className={classes.shadow} />
      </div>

      <div className={classes.content}>
        <FormProvider {...formMethods}>
          <div className={classes.form} data-testid='form-wrap'>
            <div className={classes.title}>{t('general.information.label')}</div>
            <Input
              title={t('firstName.label')}
              name='firstName'
              disabled={!isActiveDriver || isSubcompanyView}
            />
            <Input
              title={t('lastName.label')}
              name='lastName'
              disabled={!isActiveDriver || isSubcompanyView}
            />

            <Input
              title={t('middleName.label')}
              name='middleName'
              subtitle={t('optional.label')}
              disabled={!isActiveDriver || isSubcompanyView}
            />
          </div>

          <div className={classes.form}>
            <div className={classes.title}>{t('employment.information.label')}</div>
            <Select
              title={t('company.label')}
              name='companyId'
              items={Array.isArray(subcompaniesSelectList) ? subcompaniesSelectList : []}
              disabled={
                ![ROLES_OBJECT.TECH_ADMIN, ROLES_OBJECT.ADMIN, ROLES_OBJECT.OWNER].includes(
                  userData.role,
                ) ||
                !isActiveDriver ||
                isSubcompanyView
              }
              additionalOnChange={handleChangeCompany}
            />

            <Select
              title={t('manager.label')}
              name='managedById'
              items={managers ?? []}
              disabled={
                !managers ||
                userData.role === ROLES_OBJECT.MANAGER ||
                !isActiveDriver ||
                isSubcompanyView
              }
            />
          </div>

          <div className={classes.form}>
            <div className={classes.title}>{t('assign.vehicle.section.label')}</div>
            <Select
              title={t('assign.vehicle.label')}
              name='driverVehicleDto.vehicleDto.id'
              items={vehicles ?? []}
              disabled={!vehicles || !isActiveDriver || isSubcompanyView}
              additionalOnChange={handleChangeAssignVehicle}
            />
            <Select
              title={t('make.label')}
              name='make'
              items={brands ?? []}
              disabled={!brands || !isActiveDriver || isSubcompanyView}
              additionalOnChange={(e) => {
                formMethods.resetField('model');
                getModels(+e.target.value);
                getVehiclesByBrandAndModel(formMethods.watch('companyId'), +e.target.value);
              }}
            />
            <Select
              title={t('model.label')}
              name='model'
              items={models ?? []}
              disabled={!models || !isActiveDriver || isSubcompanyView}
              additionalOnChange={(e) => {
                getVehiclesByBrandAndModel(
                  formMethods.watch('companyId'),
                  formMethods.watch('make')!,
                  +e.target.value,
                );
              }}
            />
          </div>

          <div className={classes.save}>
            <Button
              variant={BUTTON_VARIANTS.FILL}
              onClick={formMethods.handleSubmit(updateDriver)}
              testId='button-submit'
              disabled={!isActiveDriver || isSubcompanyView}
            >
              {t('fields:save.label')}
            </Button>
          </div>
        </FormProvider>
      </div>
    </div>
  );
};

export default QuickEdit;
