import cx from 'classnames';
import React, { FC } from 'react';

import { ReactComponent as DeltaIcon } from 'assets/images/delta.svg';

import { getScoringColor } from 'utils/helpers/get-scoring-color';
import { numberLimit } from 'utils/helpers/number-limit';

import useStyles from './styles';

interface IDeltaComponent {
  value: number;
  deltaValue: number;
  deltaBetter: boolean;
}

const DeltaComponent: FC<IDeltaComponent> = ({ value, deltaValue, deltaBetter }) => {
  const classes = useStyles();

  return (
    <div className={classes.delta}>
      <span className={classes.value} style={{ color: getScoringColor(value) }}>
        {numberLimit(Math.abs(value))}
      </span>
      <div className={cx(classes.item, deltaBetter && classes.reversed)}>
        <span className={classes.itemValue}>{numberLimit(deltaValue)}</span>
        <DeltaIcon />
      </div>
    </div>
  );
};

export default DeltaComponent;
