import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import React, { useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { FCC } from 'interfaces/common/fc-with-children';

import userDataAtom from 'recoil/userData';

const NotificationProvider: FCC = ({ children }) => {
  const [userData] = useRecoilState(userDataAtom);

  const pubnub = useMemo(() => {
    return new PubNub({
      subscribeKey: process.env.REACT_APP_PUBNUB_KEY ?? '',
      uuid: userData!.id,
    });
  }, [userData]);

  return <PubNubProvider client={pubnub}>{children}</PubNubProvider>;
};

export default NotificationProvider;
