import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },

  label: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: '33px',
  },

  graphNegative: {
    transform: 'rotateY(-180deg)',
  },
}));

export default useStyles;
