import React, { FC } from 'react';

import { PENALTIES } from 'interfaces/map/penalties';

import { ReactComponent as AccelerationIcon } from 'assets/images/acceleration.svg';
import { ReactComponent as BrakingIcon } from 'assets/images/braking.svg';
import { ReactComponent as CorneringIcon } from 'assets/images/cornering.svg';
import { ReactComponent as CrashIcon } from 'assets/images/crash.svg';

import useStyles from './styles';

interface IProps {
  label: PENALTIES;
}

const PenaltyMarker: FC<IProps> = ({ label }) => {
  const classes = useStyles();

  if (label === PENALTIES.ACCELERATION) {
    return (
      <div className={classes.wrap} data-testid={`penalty-marker-${label}`}>
        <AccelerationIcon />
      </div>
    );
  }

  if (label === PENALTIES.BRAKING) {
    return (
      <div className={classes.wrap} data-testid={`penalty-marker-${label}`}>
        <BrakingIcon />
      </div>
    );
  }

  if (label === PENALTIES.CORNERING) {
    return (
      <div className={classes.wrap} data-testid={`penalty-marker-${label}`}>
        <CorneringIcon />
      </div>
    );
  }

  if (label === PENALTIES.CRASH) {
    return (
      <div className={classes.wrap} data-testid={`penalty-marker-${label}`}>
        <CrashIcon />
      </div>
    );
  }

  return null;
};

export default React.memo(PenaltyMarker);
