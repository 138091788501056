import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useVirtual } from '@tanstack/react-virtual';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import useTableSort from 'hooks/useTableSort';

import { TABLE_VIEW_TYPE } from 'interfaces/common/table-view-type';

import { ReactComponent as PencilIcon } from 'assets/images/pencil.svg';

import { driverShortDataDTO } from 'utils/dto/driver/short-data';
import { tripsListItemsDTO } from 'utils/dto/trips/list-item';
import { joinArrayWithoutDupes } from 'utils/helpers/join-array-without-dupes';
import { addingDateLabel } from 'utils/helpers/trip/adding-date-label';
import RouteManager from 'utils/services/route-manager';

import crashModalAtom from 'recoil/crash-modal';
import { CRASH_ANCHOR, CRASH_MODAL_TABS } from 'recoil/crash-modal/atom';
import driverTripsFilterAtom from 'recoil/drivers-vehicles/driver-trips-filters';
import driverTripsListAtom from 'recoil/drivers-vehicles/driver-trips-list';
import driverTripsSelectedPeriodAtom from 'recoil/drivers-vehicles/driver-trips-selected-period';
import driverTripsSortDataAtom from 'recoil/drivers-vehicles/driver-trips-sort';
import showDriverTripsDateFilterAtom from 'recoil/drivers-vehicles/show-driver-trip-date-filter';
import driversVehiclesShowReportFilterAtom from 'recoil/drivers-vehicles/show-report-filters';
import { SORT_DIRECTION } from 'recoil/settings/payment/transactions/sort';
import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';
import userConfigAtom from 'recoil/user-config';

import {
  driverShortReq,
  IDriverShortData,
  TDriverShortFilters,
} from 'requests/be-service/driver-controller/get-driver-general-info';
import { driverTripsReq } from 'requests/be-service/driver-controller/get-trips';
import { getDriverCrashListReq } from 'requests/gps-service/crash-controller/get-driver-crashes';

import ActivityStatus from 'components/activity-statuses';
import Collision from 'components/Collision';
import Loader from 'components/Loader';
import Modal from 'components/modal';
import PaginationScroll from 'components/pagination-scroll';
import PenaltyCard from 'components/penalty-card';
import ScoreValue from 'components/score-value';
import StatisticValue from 'components/statistic-value';
import TableSortLabel from 'components/TableSortLabel';
import Time from 'components/time';
import TripsDateLabel from 'components/trips-date-label';

import DateFilter from '../components/date-filter';
import ReportFilter from '../components/driver-report-filter';
import TripRow from '../components/trip-row';

import { TABLE_HEAD_COLUMNS } from './data';
import useStyles from './styles';

const DriverTrips = () => {
  const technicalMessageBlockHeight = useRecoilValue(technicalMessageBlockHeightSelector);
  const classes = useStyles({ technicalMessageBlockHeight });
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();
  const { t } = useTranslation(['driver.list.page', 'fields']);
  const { isMetricSystem } = useRecoilValue(userConfigAtom);

  const [isLoadingDriverInfo, setIsLoadingDriverInfo] = useState(false);
  const setCrashModal = useSetRecoilState(crashModalAtom);
  const [isLoadingTrips, setIsLoadingTrips] = useState(false);
  const [driverInfo, setDriverInfo] = useState<IDriverShortData | null>(null);
  const [driverTripsFilter, setDriverTripsFilter] = useRecoilState(driverTripsFilterAtom);
  const [trips, setTrips] = useRecoilState(driverTripsListAtom);
  const [tableViewType, setTableViewType] = useState(TABLE_VIEW_TYPE.INFO);
  const [sortData, setSortData] = useRecoilState(driverTripsSortDataAtom);
  const [, setDriverTripsSort] = useTableSort({ sort: '', direction: SORT_DIRECTION.ASC });
  const [showDriverTripsDateFilter, setShowDriverTripsDateFilter] = useRecoilState(
    showDriverTripsDateFilterAtom,
  );
  const setSelectedPeriod = useSetRecoilState(driverTripsSelectedPeriodAtom);
  const [showReportFilter, setShowReportFilter] = useRecoilState(
    driversVehiclesShowReportFilterAtom,
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [totalTrips, setTotalTrips] = useState(0);
  const tableRef = React.useRef(null);

  const clearData = useCallback(() => {
    setTrips([]);
  }, [setTrips]);

  useEffect(() => {
    return () => clearData();
  }, [clearData]);

  const rowVirtualizer = useVirtual({
    size: trips.length,
    parentRef: tableRef,
    overscan: 5,
  });

  /**
   * Get driver information
   * @type {(function(): Promise<void>)|*}
   */
  const getDriverInfo = useCallback(
    async (filters: TDriverShortFilters) => {
      try {
        setIsLoadingDriverInfo(true);
        const res = await driverShortReq(id, filters);
        setDriverInfo(driverShortDataDTO(res, isMetricSystem));
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoadingDriverInfo(false);
      }
    },
    [id, isMetricSystem],
  );

  const getTrips = useCallback(
    async (page?: number) => {
      try {
        setIsLoadingTrips(true);
        const res = await driverTripsReq({ id, page }, driverTripsFilter);
        setTotalTrips(res.totalElements);
        setCurrentPage(res.pageable.pageNumber);
        setTrips((prevState) =>
          addingDateLabel(
            joinArrayWithoutDupes(prevState, tripsListItemsDTO(res.content, isMetricSystem), 'id'),
          ),
        );
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoadingTrips(false);
      }
    },
    [isMetricSystem, setTrips, driverTripsFilter, id],
  );

  /**
   * Set new sort for table
   * @type {(function(*): void)|*}
   */
  const handleTableSort = useCallback(
    (value: string) => {
      const newSort = setDriverTripsSort(value);
      setSortData(newSort);
    },
    [setDriverTripsSort, setSortData],
  );

  /**
   * Change table view type
   */
  const handleTableViewType = () => {
    setTableViewType((prevState) =>
      prevState === TABLE_VIEW_TYPE.INFO ? TABLE_VIEW_TYPE.STATISTIC : TABLE_VIEW_TYPE.INFO,
    );
  };

  /**
   * Load additional drivers
   */
  const loadMoreHandler = useCallback(() => getTrips(currentPage + 1), [currentPage, getTrips]);

  const handleCrashClick = useCallback(async () => {
    if (!driverInfo) return;

    try {
      setCrashModal((prevState) => {
        return {
          ...prevState,
          isOpen: true,
          isFetching: true,
          anchor: CRASH_ANCHOR.VEHICLE,
          activeTab: CRASH_MODAL_TABS.DESCRIPTION,
        };
      });

      const response = await getDriverCrashListReq(driverInfo.driverId);

      setCrashModal((prevState) => {
        return {
          ...prevState,
          currentCrashIdNumber: response.length - 1,
          crashList: response,
        };
      });
    } catch (error) {
      setCrashModal((prevState) => {
        return {
          ...prevState,
          error: error,
        };
      });
    } finally {
      setCrashModal((prevState) => {
        return {
          ...prevState,
          isFetching: false,
        };
      });
    }
  }, [driverInfo, setCrashModal]);

  const handleActivityStatusClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (!driverInfo?.vehicle?.id) return;

      navigate(RouteManager.makeURL('dashboardMap.live', { id: driverInfo.vehicle.id }));
    },
    [driverInfo?.vehicle?.id, navigate],
  );

  const pencilEditHandler = () => {
    if (!driverInfo?.driverId) return;

    return navigate(RouteManager.makeURL('drivers.edit', { id: driverInfo?.driverId }));
  };

  /**
   * Get initial driver data
   */
  useEffect(() => {
    getDriverInfo(driverTripsFilter);
  }, [driverTripsFilter, getDriverInfo]);

  /**
   * get initial trips list
   */
  useEffect(() => {
    setTrips([]);
    setCurrentPage(0);
    getTrips();
  }, [getTrips, setTrips]);

  return (
    <div className={classes.wrap}>
      <div className={classes.driverWrap}>
        {isLoadingDriverInfo && <Loader lightMode width={30} isBlock />}

        {!isLoadingDriverInfo && driverInfo && (
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow data-testid={`driver-row-${driverInfo.driverId}`}>
                  <TableCell
                    classes={{ body: classes.driverCell }}
                    align='left'
                    style={{ minWidth: 400, flex: 1 }}
                  >
                    <div className={classes.driverName}>
                      {Boolean(driverInfo?.status) && (
                        <ActivityStatus
                          variant={'fill'}
                          value={driverInfo.status}
                          onClick={handleActivityStatusClick}
                          className={classes.activityStatus}
                        />
                      )}
                      <div data-testid='driverName'>{`${driverInfo?.firstName} ${driverInfo?.lastName}`}</div>
                    </div>
                  </TableCell>

                  <TableCell
                    classes={{ body: classes.driverCell }}
                    align='center'
                    style={{ width: tableViewType === TABLE_VIEW_TYPE.INFO ? 135 : 152 }}
                    data-testid='currentScoring'
                  >
                    <ScoreValue
                      className={classes.driverScore}
                      value={driverInfo?.currentScoring}
                    />
                  </TableCell>

                  {tableViewType === TABLE_VIEW_TYPE.INFO && (
                    <TableCell
                      classes={{ body: classes.driverCell }}
                      align='center'
                      style={{ width: 220 }}
                    >
                      <div className={classes.problems}>
                        {!!driverInfo?.crashCount && (
                          <Collision count={driverInfo.crashCount} onClick={handleCrashClick} />
                        )}
                        {driverInfo?.penaltyCards?.map((type, i) => (
                          <PenaltyCard type={type} key={`${type}-${i}`} />
                        ))}
                      </div>
                    </TableCell>
                  )}

                  <TableCell
                    classes={{ body: classes.driverCell }}
                    align='center'
                    style={{ width: tableViewType === 'info' ? 135 : 152 }}
                    data-testid='dailyKm'
                  >
                    <StatisticValue value={driverInfo.dailyKm} />
                  </TableCell>

                  <TableCell
                    classes={{ body: classes.driverCell }}
                    align='center'
                    style={{ width: tableViewType === TABLE_VIEW_TYPE.INFO ? 135 : 152 }}
                    data-testid='dailyH'
                  >
                    <Time timestamp={driverInfo?.dailyH} />
                  </TableCell>

                  <TableCell
                    classes={{ body: classes.driverCell }}
                    align='center'
                    style={{ width: tableViewType === TABLE_VIEW_TYPE.INFO ? 135 : 152 }}
                    data-testid='idling'
                  >
                    <Time timestamp={driverInfo?.idling} />
                  </TableCell>

                  {tableViewType === TABLE_VIEW_TYPE.STATISTIC && (
                    <TableCell
                      classes={{ body: classes.driverCell }}
                      align='center'
                      style={{ width: 152 }}
                      data-testid='fuelUsed'
                    >
                      <StatisticValue value={driverInfo.fuelUsed} />
                    </TableCell>
                  )}

                  <TableCell classes={{ body: classes.editHeadCell }}>
                    <div onClick={pencilEditHandler} data-testid='button-editHandler'>
                      <PencilIcon className={classes.pencil} />
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>

      {isLoadingTrips && <Loader isBlock width={150} preventClick lightMode />}

      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader data-testid='trips-table'>
          <TableHead>
            <TableRow>
              {TABLE_HEAD_COLUMNS(t, isMetricSystem)[tableViewType].map(
                ({ id, label, styles, align }) => (
                  <TableCell
                    classes={{ head: classes.headCell }}
                    key={id}
                    style={{ ...styles }}
                    align={align}
                  >
                    <TableSortLabel
                      name={id}
                      onClick={handleTableSort}
                      sortField={sortData.sort}
                      direction={sortData.direction}
                      disabled
                    >
                      {label}
                    </TableSortLabel>
                  </TableCell>
                ),
              )}
              <TableCell
                align={'center'}
                classes={{ head: classes.editHeadCell }}
                onClick={handleTableViewType}
                data-testid='button-handleTableViewType'
              >
                |||
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody ref={tableRef} data-testid='trips-list'>
            {rowVirtualizer.virtualItems.map((item) => {
              const data = trips[item.index];

              return (
                <Fragment key={item.key}>
                  {data.startDateLabel && (
                    <TableRow className={classes.dateRow}>
                      <TableCell colSpan={7}>
                        <TripsDateLabel startDateLabel={data.startDateLabel} driverId={id} />
                      </TableCell>
                    </TableRow>
                  )}
                  <TripRow data={data} tableViewType={tableViewType} />
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationScroll
        count={trips.length}
        maxCount={totalTrips}
        loadMoreHandler={loadMoreHandler}
      />

      <Modal onClose={() => setShowDriverTripsDateFilter(false)} open={showDriverTripsDateFilter}>
        <DateFilter
          setDateFilter={(data) => {
            setDriverTripsFilter((prevState) => ({
              ...prevState,
              movementAtFrom: new Date(data.start).getTime(),
              movementAtTill: new Date(data.end).getTime(),
            }));
            setShowDriverTripsDateFilter(false);
          }}
          setSelectedPeriod={setSelectedPeriod}
          onCloseFilter={() => setShowDriverTripsDateFilter(false)}
        />
      </Modal>

      <Modal open={showReportFilter} onClose={() => setShowReportFilter(false)}>
        <ReportFilter driverId={id} />
      </Modal>
    </div>
  );
};

export default DriverTrips;
