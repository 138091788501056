import cx from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';

import { getMoneyColor } from 'utils/helpers';
import { formatMoney } from 'utils/helpers/format-money';
import { valueConverter } from 'utils/helpers/value-converter';

import driverShortDataAtom from 'recoil/driver/driverShortData';
import driverTripAtom from 'recoil/driver/driverTrip';
import showDriverTripMaxSpeedMarkerAtom from 'recoil/driver/show-trip-max-speed';
import subcompaniesCurrencyIconAtom from 'recoil/subcompanies/currency-icon';
import userConfigAtom from 'recoil/user-config';

import useStyles from './styles';

const Statistics = () => {
  const classes = useStyles();
  const { t } = useTranslation(['trip.page', 'measurement.system.page']);

  const { isMetricSystem } = useRecoilValue(userConfigAtom);
  const driverShortData = useRecoilValue(driverShortDataAtom);
  const [showTripMaxSpeedMarker, setShowTripMaxSpeedMarker] = useRecoilState(
    showDriverTripMaxSpeedMarkerAtom,
  );

  const subcompaniesCurrencyIcon = useRecoilValueLoadable(
    subcompaniesCurrencyIconAtom({ companyId: driverShortData.companyId }),
  );
  const [{ statistics, maxSpeedPoint }] = useRecoilState(driverTripAtom);

  const statisticFields = useMemo(() => {
    return [
      {
        label: t(isMetricSystem ? 'distance.label' : 'distance.mile.label'),
        type: 'distance',
        value: isMetricSystem
          ? statistics?.distance[0].toFixed(2)
          : valueConverter.distance.toImperial(statistics?.distance[0]).toFixed(2),
      },
      {
        label: t(isMetricSystem ? 'max.speed.label' : 'max.speed.mile.label'),
        type: 'maxSpeed',
        value: isMetricSystem
          ? statistics?.maxSpeed[0]
          : valueConverter.distance.toImperial(statistics?.maxSpeed[0]).toFixed(2),
        onClick: () => setShowTripMaxSpeedMarker((prevState) => !prevState),
      },
      {
        label: t('usage.label'),
        type: 'usage',
        value: statistics?.usage[0]
          ? `${statistics?.usage[0]}${t(
              `measurement.system.page:${isMetricSystem ? 'liters' : 'gallon'}.label`,
            )}`
          : '-',
      },
      {
        label: t('saving.label'),
        type: 'saving',
        value: statistics?.saving[0]
          ? formatMoney(
              statistics?.saving[0],
              subcompaniesCurrencyIcon.state === 'hasValue'
                ? subcompaniesCurrencyIcon.contents
                : null,
            )
          : '-',
      },
    ];
  }, [
    isMetricSystem,
    setShowTripMaxSpeedMarker,
    statistics?.distance,
    statistics?.maxSpeed,
    statistics?.saving,
    statistics?.usage,
    subcompaniesCurrencyIcon.contents,
    subcompaniesCurrencyIcon.state,
    t,
  ]);

  return (
    <div className={classes.fields} data-testid='statistic-wrap'>
      {statisticFields.map(({ label, type, value, onClick }) => {
        const color =
          type === 'saving'
            ? getMoneyColor(String(statistics[type]).replace(/[^0-9-]/g, ''))
            : null;

        return (
          <div
            className={cx(classes.field, type, {
              canClick: type === 'maxSpeed' && Boolean(maxSpeedPoint),
              isActive: showTripMaxSpeedMarker && type === 'maxSpeed',
            })}
            key={label}
            onClick={() => {
              onClick?.();
            }}
            data-testid={`statistic-item-${type}`}
          >
            <div className={classes.fieldTitle}>{label}</div>
            <div className={cx(classes.fieldCount, `${color}`)}>
              <div>{value}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Statistics;
