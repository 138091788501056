import { valueConverter } from 'utils/helpers/value-converter';
import server from 'utils/server';

interface ICrashDetails {
  crashId: string;
  severity: number;
  impactNumber: string;
  impactDate: number;
  crashCondition: {
    crashDuration: number;
    evasiveManeuver: string;
    meanAcceleration: number;
    beforeAccidentSpeed: number;
    impactSpeed: number;
    deltaV: number;
    afterImpactSpeed: number;
    numberOfImpacts: number;
    vehicleDrivenPostCrash: boolean;
  };
  weatherCondition: {
    temperature: number;
    dayTime: string;
    cloudCoverage: number;
    conditions: string;
  };
  carDamage: {
    front: number;
    back: number;
    left: number;
    right: number;
  };
}

/**
 * Retrieves crash information from the server.
 *
 * @param {string} crashId - The ID of the crash to retrieve information for.
 * @param {boolean} [isMetricSystem=true] - Whether to return crash information in metric or imperial units. Defaults to true (metric).
 * @returns {Promise<ICrashDetails>} - A promise that resolves to the crash details.
 */
export const getCrashInfoReq = async (crashId: string, isMetricSystem: boolean = true) =>
  server.services.gpsService
    .get<ICrashDetails>(`crashes/${crashId}/details`)
    .then<ICrashDetails>(({ data }) =>
      isMetricSystem
        ? { ...data }
        : {
            ...data,
            crashCondition: {
              ...data.crashCondition,
              afterImpactSpeed: valueConverter.distance.toImperial(
                data.crashCondition.afterImpactSpeed,
              ),
              beforeAccidentSpeed: valueConverter.distance.toImperial(
                data.crashCondition.beforeAccidentSpeed,
              ),
              deltaV: valueConverter.distance.toImperial(data.crashCondition.deltaV),
              impactSpeed: valueConverter.distance.toImperial(data.crashCondition.impactSpeed),
              meanAcceleration: valueConverter.distance.toImperial(
                data.crashCondition.meanAcceleration,
              ),
            },
          },
    );
