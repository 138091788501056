import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  layer: {
    zIndex: 10,
    cursor: 'pointer',
    position: 'absolute',
    bottom: 30,
    right: 60,
  },
}));

export default useStyles;
