import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import { StyledEngineProvider } from '@mui/material/styles';
import React from 'react';

import { FCC } from 'interfaces/common/fc-with-children';

import useStyles from './styles';

interface IFieldLabel {
  title: string;
  subtitle?: string;
}

type Props = InputLabelProps & IFieldLabel;

const FieldLabel: FCC<Props> = ({ disabled, subtitle, error, htmlFor, title }) => {
  const classes = useStyles();

  return (
    <StyledEngineProvider injectFirst>
      <InputLabel
        htmlFor={htmlFor}
        error={error}
        classes={{
          root: classes.label,
        }}
        disabled={disabled}
      >
        {title}
        <span className={classes.subtitle}>{subtitle}</span>
      </InputLabel>
    </StyledEngineProvider>
  );
};

export default FieldLabel;
