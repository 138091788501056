import Modal from '@mui/material/Modal';
import React, { useMemo } from 'react';
import { useRecoilState } from 'recoil';

import crashModalAtom from 'recoil/crash-modal';
import { CRASH_MODAL_TABS } from 'recoil/crash-modal/atom';

import Loader from 'components/Loader';

import ModalInfo from './ModalInfo';
import ModalMessage from './ModalMessage';
import useStyles from './styles';

const CrashModal = () => {
  const classes = useStyles();

  const [{ isOpen, isFetching, activeTab }] = useRecoilState(crashModalAtom);

  const tabs = useMemo(() => {
    switch (true) {
      case isFetching:
        return <Loader lightMode />;

      case activeTab === CRASH_MODAL_TABS.PREVIEW:
        return <ModalMessage />;

      case activeTab === CRASH_MODAL_TABS.DESCRIPTION:
        return <ModalInfo />;

      default:
        return null;
    }
  }, [activeTab, isFetching]);

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
    >
      <section className={classes.overlay}>
        <div className={classes.paper}>{tabs}</div>
      </section>
    </Modal>
  );
};

CrashModal.defaultProps = {};

CrashModal.propTypes = {};

export default React.memo(CrashModal);
