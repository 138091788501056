import { COUNTRIES } from 'interfaces/common/countries';

import flagAzIcon from 'assets/images/login/az.png';
import flagUkIcon from 'assets/images/login/uk.png';

export const domainsData = {
  [COUNTRIES.AZ]: {
    label: 'Azerbaijan',
    Icon: flagAzIcon,
  },
  [COUNTRIES.UK]: {
    label: 'United Kingdom',
    Icon: flagUkIcon,
  },
} as const;
