export const KM_IN_MILE = 1.60934;
export const LITER_IN_GALLON = 3.78541;
export const LBS_IN_KG = 2.20462;
export const L100KM_IN_MPG = 235.215;

export const measurementSystem = {
  metric: 'METRIC',
  imperial: 'IMPERIAL',
};

export const MEASUREMENT = [
  { label: measurementSystem.metric.toUpperCase(), value: measurementSystem.metric },
  { label: measurementSystem.imperial.toUpperCase(), value: measurementSystem.imperial },
];
