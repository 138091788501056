import * as yup from 'yup';

import { BleSensorNumber } from 'interfaces/sensor/ble-sensor-number';
import { TSensorRenderData } from 'interfaces/sensor/t-sensor-render-data';

export const formSchema = () =>
  yup.object().shape({
    id: yup.string().nullable(),
    vehicleId: yup.string().required(),
    sensorName: yup.string().required(),
    sensorNumber: yup.string<BleSensorNumber>().required(),
    notification: yup.boolean().required(),
    temperature: yup.object().shape({
      active: yup.boolean().required(),
      celsius: yup.boolean().required(),
      value: yup.array<[number, number]>().required(),
    }),
    humidity: yup.object().shape({
      active: yup.boolean().required(),
      value: yup.array<[number, number]>().required(),
    }),
    movement: yup.object().shape({
      active: yup.boolean().required(),
      value: yup.number().required(),
      zones: yup.array<string[]>().required(),
      subzones: yup.array<string[]>().required(),
    }),
  });

export const defaultValues = (vehicleId: string): TSensorRenderData => ({
  sensorName: '',
  vehicleId,
  sensorNumber: BleSensorNumber.BLE_SENSOR_1,
  notification: false,
  temperature: {
    active: false,
    celsius: true,
    value: [-20, 60],
  },
  humidity: {
    active: false,
    value: [0, 100],
  },
  movement: {
    active: false,
    value: 0,
    zones: [],
    subzones: [],
  },
});
