import { atom } from 'recoil';

import { SENSOR_TYPES } from 'interfaces/sensor/types';

const activeSensorAlarmsAtom = atom<SENSOR_TYPES[]>({
  key: 'activeSensorAlarmsAtom',
  default: [],
});

export default activeSensorAlarmsAtom;
