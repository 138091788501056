import { atom } from 'recoil';

import { PENALTY_CARD } from 'interfaces/common/penalty-card';

interface ILiveTrackingStatistic {
  driveDistance: number;
  driveTime: number;
  idlingTime: number;
  parkingTime: number;
  penalties: number;
  totalScore: null | number;
  penaltyCard: null | PENALTY_CARD;
  hasCrash: boolean;
}

const defaultData = {
  driveDistance: 0,
  driveTime: 0,
  idlingTime: 0,
  parkingTime: 0,
  penalties: 0,
  totalScore: null,
  penaltyCard: null,
  hasCrash: false,
};

const liveTrackingStatisticAtom = atom<ILiveTrackingStatistic>({
  key: 'liveTrackingStatisticAtom',
  default: defaultData,
});

export default liveTrackingStatisticAtom;
