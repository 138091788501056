import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  scoring: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: palette.custom.lightBlue,
    borderRadius: 10,
  },

  title: {
    position: 'absolute',
    fontWeight: 300,
    fontSize: 12,
    color: theme.palette.primary.light,
    lineHeight: '14.32px',
    userSelect: 'none',

    '&.scoring__charts__title': {
      right: 45,
    },
  },

  small: {
    top: 7,
    right: 7,

    '&.left': {
      left: 7,
      right: 'unset',
    },
  },

  large: {
    top: 11,
    right: 19,

    '&.left': {
      left: 19,
      right: 'unset',
    },
  },

  titleAdditional: {
    color: theme.palette.primary.light,
    opacity: 0.3,
  },

  subtitle: {
    position: 'absolute',
    top: 11,
    left: 19,
    color: theme.palette.primary.dark,
    fontWeight: 300,
    fontSize: 12,
    lineHeight: '14.32px',
    userSelect: 'none',
  },
}));

export default useStyles;
