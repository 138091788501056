import { MenuProps, SelectChangeEvent, SvgIconProps } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import MUISelect from '@mui/material/Select';
import { StyledEngineProvider } from '@mui/material/styles';
import cx from 'classnames';
import React, { FC } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

import { ISelectItem } from 'interfaces/common/select-items';

import { ReactComponent as ArrowIcon } from 'assets/images/arrow-simple.svg';

import { getDeepValue } from 'utils/helpers/get-object-deep-value';

import ErrorMessage from '../error-message';
import FieldLabel from '../field-label';

import useStyles from './styles';

/**
 * Check if some value is selected
 * @param value
 * @return {boolean}
 */
const getHasValue = (value: unknown): boolean | null => {
  if (Array.isArray(value)) {
    return value.length !== 0;
  }

  if (['string', 'number'].includes(typeof value)) {
    return Boolean(value);
  }
  if (typeof value === 'object' && value !== null) {
    return true;
  }

  return null;
};

/**
 * Checking menu item was selected or not
 * @param selectedValue
 * @param itemValue
 * @return {boolean}
 */
const getIsChecked = (selectedValue: string | string[], itemValue: string | number): boolean => {
  if (Array.isArray(selectedValue)) {
    return (
      selectedValue.findIndex((item) => {
        return item === itemValue;
      }) > -1
    );
  }
  return selectedValue === itemValue;
};

const IconComponent = (props: SvgIconProps) => {
  const classes = useStyles();

  return <ArrowIcon {...props} className={cx(classes.arrow, props.className)} />;
};

interface IProps {
  name: string;
  title: string;
  subtitle?: string;
  disabled?: boolean;
  multiple?: boolean;
  additionalOnChange?: (e: SelectChangeEvent) => void;
  defaultValue?: string;
  wrapClass?: string;
  renderValueType?: 'values' | 'number';
  onScroll?: MenuProps['onScroll'];
  items: ISelectItem[];
}

const Select: FC<IProps> = ({
  name,
  title,
  subtitle,
  items,
  defaultValue,
  renderValueType = 'values',
  wrapClass,
  onScroll,
  disabled = false,
  multiple = false,
  additionalOnChange = null,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const classes = useStyles();

  const menuProps = {
    classes: {
      paper: classes.paper,
      list: classes.list,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    slotProps: {
      paper: {
        onScroll: onScroll,
      },
    },
  } as Partial<MenuProps>;

  const createSelectedValues = (selected: string | string[]) => {
    if (!selected || selected.length === 0) {
      return 'Select';
    }

    if (Array.isArray(selected)) {
      if (renderValueType === 'number') {
        return (
          <div className={classes.renderValueWrap}>
            <span className={classes.selectedValue}>{`${selected.length} Selected`}</span>
          </div>
        );
      }

      return (
        <div className={classes.renderValueWrap}>
          {selected?.map((option) => (
            <span key={option} className={cx(classes.selectedValue, 'withBackground')}>
              {items.find((item) => item.value === option)?.label}
            </span>
          ))}
        </div>
      );
    }

    return (
      <div className={classes.renderValueWrap}>
        <span className={classes.selectedValue}>
          {items.find((item) => item.value === selected)?.label}
        </span>
      </div>
    );
  };

  const errorData = getDeepValue<FieldError | undefined>(errors, name);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className={cx(classes.wrap, wrapClass)} data-menu={name}>
          <FieldLabel
            title={title}
            subtitle={subtitle}
            error={!!errorData}
            htmlFor={field.name}
            disabled={disabled}
          />
          <StyledEngineProvider injectFirst>
            <MUISelect
              data-testid={`select-${name}`}
              {...field}
              classes={{
                select: cx(classes.root, { hasValue: getHasValue(field.value) }),
                disabled: classes.disabled,
              }}
              id={field.name}
              variant='outlined'
              IconComponent={IconComponent}
              multiple={multiple}
              disabled={disabled}
              error={!!errorData}
              value={field.value ? field.value : multiple ? [] : ''}
              defaultValue={defaultValue}
              MenuProps={menuProps}
              displayEmpty={true}
              renderValue={(selected) => createSelectedValues(selected)}
              onChange={(e) => {
                additionalOnChange?.(e);
                field.onChange(e);
              }}
            >
              {items.map((option) => {
                const isChecked = getIsChecked(field.value, option.value);

                return (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    classes={{
                      root: classes.selectedOption,
                    }}
                    data-testid={`select-item-${option.value}`}
                  >
                    <div
                      className={cx(multiple ? classes.checkBox : classes.radioButton, {
                        isChecked,
                      })}
                    >
                      {isChecked && <span className={cx(classes.checkedIcon, { multiple })} />}
                    </div>
                    <div className={classes.text}>{option.label}</div>
                  </MenuItem>
                );
              })}
            </MUISelect>
          </StyledEngineProvider>

          <ErrorMessage message={errorData?.message} />
        </div>
      )}
    />
  );
};

export default Select;
