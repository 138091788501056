import { TFunction } from 'react-i18next';

import { TABLE_VIEW_TYPE } from 'interfaces/common/table-view-type';

export const TABLE_HEAD_COLUMNS = (t: TFunction, isMetricSystem: boolean) =>
  ({
    [TABLE_VIEW_TYPE.INFO]: [
      {
        id: 'trips',
        label: t('trips.table.header.label'),
        styles: { minWidth: 400, flex: 1 },
        align: 'left',
        isSort: false,
      },
      {
        id: 'currentScoring',
        label: t('score.table.header.label'),
        styles: { width: 135 },
        align: 'center',
        isSort: true,
      },
      {
        id: 'problems',
        label: t('penalties.table.header.label'),
        styles: { width: 220 },
        align: 'center',
        isSort: true,
      },
      {
        id: 'dailyKm',
        label: t(`daily.${isMetricSystem ? 'km' : 'mile'}.header.label`),
        styles: { width: 135 },
        align: 'center',
        isSort: false,
      },
      {
        id: 'dailyH',
        label: t('daily.hours.header.label'),
        styles: { width: 135 },
        align: 'center',
        isSort: true,
      },
      {
        id: 'idling',
        label: t('idle.header.label'),
        styles: { width: 135 },
        align: 'center',
        isSort: true,
      },
    ],
    [TABLE_VIEW_TYPE.STATISTIC]: [
      {
        id: 'trips',
        label: t('trips.table.header.label'),
        styles: { minWidth: 400, flex: 1 },
        align: 'left',
        isSort: false,
      },
      {
        id: 'currentScoring',
        label: t('score.table.header.label'),
        styles: { width: 152 },
        align: 'center',
        isSort: true,
      },
      {
        id: 'dailyKm',
        label: t(`daily.${isMetricSystem ? 'km' : 'mile'}.header.label`),
        styles: { width: 152 },
        align: 'center',
        isSort: true,
      },
      {
        id: 'dailyH',
        label: t('daily.hours.header.label'),
        styles: { width: 152 },
        align: 'center',
        isSort: true,
      },
      {
        id: 'idling',
        label: t('idle.header.label'),
        styles: { width: 152 },
        align: 'center',
        isSort: true,
      },
      {
        id: 'bonus',
        label: t(`fuelUsed${isMetricSystem ? '' : '.gal'}.header.label`),
        styles: { width: 152 },
        align: 'center',
        isSort: true,
      },
    ],
  }) as const;
