import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  modal: {
    width: '100%',
    height: '100%',
  },

  overlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper: {
    position: 'relative',
    minWidth: 250,
    minHeight: 350,
    boxShadow: theme.shadows[5],
    background: palette.custom.bluePrimary,
    border: `1px solid ${palette.custom.black}`,
    borderRadius: '20px',
  },
}));

export default useStyles;
