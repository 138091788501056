import { atom } from 'recoil';

const dashboardMapLayoutSettingsAtom = atom({
  key: 'dashboardMapLayoutSettingsAtom',
  default: {
    showParams: ['showDriverInfo', 'showVehicleInfo'],
    isOpenSettingsModal: false,
  },
});

export default dashboardMapLayoutSettingsAtom;
