import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    position: 'relative',
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  header: {
    position: 'absolute',
    top: 0,
    width: '100%',
  },

  emptyText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    '& > div:first-child': {
      color: palette.custom.goldSecondary,
      cursor: 'pointer',
    },
  },

  list: {
    marginTop: 70,
    position: 'relative',
  },

  row: {
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: 30,
    padding: 20,
    borderRadius: 10,
    transition: 'background-color 0.3s ease',

    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.15),
    },

    '&.isSelected': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.15),
    },

    '&:hover $icon': {
      opacity: 1,
      pointerEvents: 'all',
    },
  },

  name: {
    fontSize: 20,
    fontWeight: 500,
    color: palette.custom.blue200,
    display: 'flex',
    alignItems: 'center',

    '& > span': {
      marginLeft: 5,
      color: palette.custom.blue700,
    },
  },

  icon: {
    width: 24,
    fill: theme.palette.primary.dark,
    opacity: 0,
    pointerEvents: 'none',
    cursor: 'pointer',
    transition: 'opacity 0.3s ease',
  },
}));

export default useStyles;
