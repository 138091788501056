import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    padding: '12px 0',
    backgroundColor: palette.custom.yellow400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: 15,
    zIndex: 10000,
  },

  text: {
    maxWidth: '75%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',

    '&.showFullMessage': {
      WebkitLineClamp: 'unset',
    },
  },

  learnMore: {
    cursor: 'pointer',
  },

  close: {
    cursor: 'pointer',
    width: 12,
    position: 'absolute',
    right: 15,
  },
}));

export default useStyles;
