import server from 'utils/server';

import { IPaymentFormData } from 'modules/Settings/pages/finance/company-wrapper/make-payment/form-schema';

import { ITransaction } from './get-company-transactions';

/**
 * Sends a payment request to the server.
 *
 * @async
 * @param {string} balanceId - The ID of the balance to make payment from.
 * @param {IPaymentFormData} data - The payment form data.
 * @returns {Promise<ITransaction>} A promise that resolves with the transaction data.
 */
export const sendPaymentReq = async (
  balanceId: string,
  data: IPaymentFormData,
): Promise<ITransaction> => {
  const body = {
    balanceId,
    ...data,
  };

  return server.services.paymentService
    .post<ITransaction>('payments/make-payment', body)
    .then((res) => res.data);
};
