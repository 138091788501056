import queryString from 'query-string';

import { ENGINE_STATUS } from 'interfaces/vehicles/engine-status';
import { ENGINE_STATUS_COMMANDS } from 'interfaces/vehicles/engine-status-commands';

import server from 'utils/server';

/**
 * Toggles the engine power of a vehicle.
 *
 * @param {string} vehicleIMEI - The IMEI number of the vehicle.
 * @param {ENGINE_STATUS} engineStatus - The desired engine status.
 * @returns {Promise<ENGINE_STATUS_COMMANDS>} - A promise that resolves with the response data from the server.
 */
export const vehicleEnginePowerToggleReq = async (
  vehicleIMEI: string,
  engineStatus: ENGINE_STATUS,
) => {
  const params = {
    command:
      engineStatus === ENGINE_STATUS.ACTIVE
        ? ENGINE_STATUS_COMMANDS.BLOCK
        : engineStatus === ENGINE_STATUS.INACTIVE
        ? ENGINE_STATUS_COMMANDS.UNBLOCK
        : null,
    imei: vehicleIMEI,
  };

  return server.services.beService
    .get<ENGINE_STATUS_COMMANDS>('vehicles/send/command?' + queryString.stringify(params))
    .then((res) => res.data);
};
