import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import MUISelect from '@mui/material/Select';
import cx from 'classnames';
import React, { FC, useMemo, useRef, useState } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

import { ReactComponent as ArrowIcon } from 'assets/images/arrow-simple.svg';

import { getDeepValue } from 'utils/helpers/get-object-deep-value';

import ErrorMessage from '../error-message';
import FieldLabel from '../field-label';

import { data } from './data';
import useStyles from './styles';

interface IProps {
  name: string[];
  title: string;
  disabled?: boolean;
  subtitle?: string;
}

const InputWithPrefix: FC<IProps> = ({ name, title, subtitle, disabled }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const inputsWrapRef = useRef<HTMLDivElement | null>(null);
  const classes = useStyles({ inputsWrapWidth: inputsWrapRef?.current?.offsetWidth ?? 0 });

  const [hasFocusInput, setHasFocusInput] = useState(false);
  const [hasFocusSelect, setHasFocusSelect] = useState(false);

  const error = useMemo(() => {
    return getDeepValue<FieldError>(errors, name[1]) || getDeepValue<FieldError>(errors, name[0]);
  }, [errors, name]);

  return (
    <div className={classes.wrap}>
      <FieldLabel
        title={title}
        subtitle={subtitle}
        error={!!error}
        htmlFor={name[1]}
        disabled={disabled}
      />
      <div
        ref={inputsWrapRef}
        className={cx(classes.inputsWrap, {
          hasFocus: hasFocusInput || hasFocusSelect,
          hasError: !!error,
        })}
      >
        <Controller
          control={control}
          name={name[0]}
          render={({ field }) => (
            <MUISelect
              {...field}
              classes={{
                root: cx(classes.selectRoot, { hasValue: !!field.value }),
                select: classes.select,
                disabled: classes.selectDisabled,
              }}
              data-testid={`phone-select-${name[0]}`}
              onFocus={() => setHasFocusSelect(true)}
              onBlur={() => setHasFocusSelect(false)}
              IconComponent={(props) => (
                <ArrowIcon {...props} className={cx(classes.selectArrow, props.className)} />
              )}
              renderValue={(selected) => {
                return <div>{selected}</div>;
              }}
              disabled={disabled}
              error={!!error}
              MenuProps={{
                classes: {
                  paper: classes.selectPaper,
                  list: classes.selectList,
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
            >
              {data.map(({ flag, countryNameEn, countryCallingCode }) => {
                return (
                  <MenuItem
                    key={countryCallingCode}
                    value={countryCallingCode}
                    data-testid={`phone-select-item-${countryCallingCode}`}
                    classes={{
                      root: classes.selectedOption,
                    }}
                  >
                    <div className={classes.countryCode}>
                      <div>{flag}</div>
                      <div>{countryNameEn}</div>
                    </div>
                    <div>{countryCallingCode}</div>
                  </MenuItem>
                );
              })}
            </MUISelect>
          )}
        />

        <Controller
          name={name[1]}
          control={control}
          render={({ field }) => {
            return (
              <InputBase
                {...field}
                error={!!error}
                classes={{
                  root: classes.inputWrap,
                  disabled: classes.inputDisabled,
                  input: classes.input,
                }}
                inputProps={{
                  'data-testid': `phone-input-${name[1]}`,
                }}
                type='number'
                disabled={disabled}
                onFocus={() => setHasFocusInput(true)}
                onBlur={() => setHasFocusInput(false)}
              />
            );
          }}
        />
      </div>

      <ErrorMessage message={error?.message} />
    </div>
  );
};

export default InputWithPrefix;
