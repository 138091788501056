import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    position: 'relative',
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: 16,
    fontWeight: 400,
    color: palette.custom.blue200,
  },

  header: {
    position: 'absolute',
    top: 0,
    width: '100%',
    display: 'flex',
  },

  closeBtn: {
    width: 'fit-content',
  },

  emptyTeam: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    '& > div:first-child': {
      color: palette.custom.goldSecondary,
      cursor: 'pointer',
    },
  },

  list: {
    marginTop: 70,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },

  addBtn: {
    width: 290,
    position: 'sticky',
    bottom: 30,
    alignSelf: 'center',
  },

  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    borderRadius: 10,
    padding: 15,

    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.15),
    },

    '&:hover $moreIcon': {
      opacity: 1,
      pointerEvents: 'all',
    },

    '&.isActive': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.15),

      '& $moreIcon': {
        opacity: 1,
        pointerEvents: 'all',
      },
    },
  },

  content: {
    display: 'flex',
    alignItems: 'flex-start',
    columnGap: 20,
  },

  item: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.primary.dark,
    display: 'grid',
    gridTemplateColumns: '40px 1fr',
    alignItems: 'center',
    columnGap: 10,
    rowGap: 7,
    gridTemplateAreas: `
    "plateNumber plateNumber"
    "vehicleIcon vehicleBrand"
    "driverName driverName"
    `,
  },

  plateNumber: {
    gridArea: 'plateNumber',
  },

  vehicleIcon: {
    gridArea: 'vehicleIcon',
  },

  vehicleBrand: {
    gridArea: 'vehicleBrand',
  },

  driverName: {
    gridArea: 'driverName',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  moreIcon: {
    cursor: 'pointer',
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 0.3s ease',
  },

  popover: {
    borderRadius: 10,
    padding: 25,
    boxShadow: `0px 15px 30px 0px ${alpha(palette.custom.black, 0.15)}`,
    backgroundColor: palette.custom.blue900,
  },

  deleteIcon: {
    cursor: 'pointer',
  },
}));

export default useStyles;
