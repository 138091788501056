import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { ENGINE_STATUS } from 'interfaces/vehicles/engine-status';

import { palette } from 'assets/theme';

type Props = {
  status: ENGINE_STATUS;
};

const useStyles = makeStyles<Theme, Props>((theme) => ({
  wrap: {
    width: 28,
    height: 28,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 0px 13px 2px rgba(0, 0, 0, 0.3)',
    cursor: ({ status }) => (status === ENGINE_STATUS.IN_PROGRESS ? 'default' : 'pointer'),
  },

  icon: {
    width: 10,
    '& path': {
      stroke: ({ status }) => {
        switch (status) {
          case ENGINE_STATUS.ACTIVE:
            return palette.custom.green400;
          case ENGINE_STATUS.IN_PROGRESS:
            return palette.custom.yellow;
          case ENGINE_STATUS.INACTIVE:
            return palette.custom.redSecondary;
          default:
            return palette.custom.gray;
        }
      },
    },
  },
}));

export default useStyles;
