import * as yup from 'yup';

import { valueConverter } from 'utils/helpers/value-converter';

export const defaultValues = {
  generalInfo: {
    price: 5.55,
    devicePhonePrefix: '+994',
    active: true,
  },
};

const currentYear = new Date().getFullYear();

export const formSchema = (isMetricSystem: boolean) => {
  return yup.object().shape({
    generalInfo: yup.object().shape({
      active: yup.boolean().nullable(),
      id: yup.string().nullable(),
      hasGpsPoints: yup.boolean().nullable(),
      country: yup.string().required(),
      plateNumber: yup
        .string()
        .required()
        .max(36)
        .matches(/^[a-z0-9]+$/i),
      brandId: yup.number().required(),
      modelId: yup.number().required(),
      year: yup.number().nullable().required().integer().max(currentYear).min(1886),
      color: yup.string().nullable(),
      iccid: yup.string().max(30).nullable(),
      companyId: yup.string().required(),
      managedById: yup.string().required(),
      driverId: yup.string().nullable(),
      marketValue: yup
        .number()
        .nullable()
        .transform((value, originalValue) => {
          if (typeof originalValue === 'string' && !originalValue) {
            return null;
          }
          return value;
        }),
      price: yup
        .number()
        .nullable()
        .required()
        .max(99.99)
        .min(0)
        .transform((value, originalValue) => {
          if (typeof originalValue === 'string' && !originalValue) {
            return null;
          }
          return value;
        }),
      odometer: yup
        .number()
        .nullable()
        .required()
        .max(isMetricSystem ? 999999999 : valueConverter.distance.toImperial(999999999))
        .min(0)
        .transform((value, originalValue) => {
          if (typeof originalValue === 'string' && !originalValue) {
            return null;
          }
          return value;
        }),
      imei: yup
        .string()
        .required()
        .max(200)
        .matches(/^[a-z0-9]+$/i),
      deviceType: yup.string().required(),
      deviceFunctions: yup.array().of(yup.string()),
      devicePhoneNumber: yup.string().max(100).nullable(),
      devicePhonePrefix: yup
        .string()
        .nullable()
        .when('devicePhoneNumber', (devicePhoneNumber) =>
          devicePhoneNumber[0] ? yup.string().required() : yup.string().nullable(),
        ),
      maxPermissibleMass: yup
        .number()
        .max(isMetricSystem ? 999999999 : valueConverter.weight.toImperial(999999999))
        .min(0)
        .nullable()
        .transform((value, originalValue) => {
          if (typeof originalValue === 'string' && !originalValue) {
            return null;
          }
          return value;
        }),
      engineCapacity: yup
        .number()
        .max(isMetricSystem ? 999999999 : valueConverter.volume.toImperial(999999999))
        .min(0)
        .nullable()
        .transform((value, originalValue) => {
          if (typeof originalValue === 'string' && !originalValue) {
            return null;
          }
          return value;
        }),
      type: yup.string().required(),

      vin: yup
        .string()
        .required()
        .max(255)
        .matches(/^[a-z0-9]+$/i),
    }),
    detailsInfo: yup.object().shape({
      enginePower: yup
        .number()
        .nullable()
        .transform((value, originalValue) => {
          if (typeof originalValue === 'string' && !originalValue) {
            return null;
          }
          return value;
        }),
      driverType: yup.string().nullable().required(),
      fuelType: yup.string().nullable().required(),
      gasolineType: yup
        .string()
        .when('fuelType', (fuelType) => {
          // TODO: need fix types
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return fuelType[0] === 'GASOLINE' ? yup.string().required() : yup.string().nullable();
        })
        .nullable(),
      oilCapacity: yup
        .number()
        .nullable()
        .required()
        .max(isMetricSystem ? 999999999 : valueConverter.volume.toImperial(999999999))
        .min(0)
        .transform((value, originalValue) => {
          if (typeof originalValue === 'string' && !originalValue) {
            return null;
          }
          return value;
        }),
      fuelConsumptionCombined: yup
        .number()
        .nullable()
        .required()
        .max(isMetricSystem ? 99.99 : 10000)
        .min(isMetricSystem ? 0 : 2.36)
        .transform((value, originalValue) => {
          if (typeof originalValue === 'string' && !originalValue) {
            return null;
          }
          return value;
        }),
      fuelConsumptionCity: yup
        .number()
        .nullable()
        .required()
        .max(isMetricSystem ? 99.99 : 10000)
        .min(isMetricSystem ? 0 : 2.36)
        .transform((value, originalValue) => {
          if (typeof originalValue === 'string' && !originalValue) {
            return null;
          }
          return value;
        }),
      fuelConsumptionHighWay: yup
        .number()
        .nullable()
        .required()
        .max(isMetricSystem ? 99.99 : 10000)
        .min(isMetricSystem ? 0 : 2.36)
        .transform((value, originalValue) => {
          if (typeof originalValue === 'string' && !originalValue) {
            return null;
          }
          return value;
        }),
      weight: yup
        .number()
        .nullable()
        .required()
        .max(isMetricSystem ? 999999999 : valueConverter.weight.toImperial(999999999))
        .min(0)
        .transform((value, originalValue) => {
          if (typeof originalValue === 'string' && !originalValue) {
            return null;
          }
          return value;
        }),
      maxSpeed: yup
        .number()
        .max(isMetricSystem ? 999999999 : valueConverter.distance.toImperial(999999999))
        .min(0)
        .nullable()
        .transform((value, originalValue) => {
          if (typeof originalValue === 'string' && !originalValue) {
            return null;
          }
          return value;
        }),
      maxDistance: yup
        .number()
        .max(isMetricSystem ? 999999999 : valueConverter.distance.toImperial(999999999))
        .min(0)
        .nullable()
        .transform((value, originalValue) => {
          if (typeof originalValue === 'string' && !originalValue) {
            return null;
          }
          return value;
        }),
    }),
  });
};
