import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getDriversStatusReq,
  IUsageStatistic,
} from 'requests/be-service/scoring-controller/usage-statistic';

import Loader from 'components/Loader';

import useStyles from './styles';

const DriversStatus = () => {
  const classes = useStyles();
  const { t } = useTranslation('scoring.page');

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IUsageStatistic | null>(null);

  const renderData = useMemo(() => {
    return [
      { value: data?.activeDrivers ?? '-', label: 'active.drivers.label' },
      { value: data?.totalDrivers ?? '-', label: 'total.drivers.label' },
      { value: data?.totalVehicles ?? '-', label: 'total.vehicles.label' },
    ];
  }, [data?.activeDrivers, data?.totalDrivers, data?.totalVehicles]);

  /**
   * Get data from API
   */
  useEffect(() => {
    const req = async () => {
      try {
        setIsLoading(true);
        const res = await getDriversStatusReq();
        setData(res);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };

    req();
  }, []);

  if (isLoading) {
    return <Loader lightMode isBlock width={60} />;
  }

  return (
    <div className={classes.wrap} data-testid='drivers-status-wrapper'>
      {renderData.map(({ label, value }) => (
        <div key={label} className={classes.item}>
          <div className={classes.count} data-testid={label}>
            {value}
          </div>
          <div className={classes.title}>{t(label)}</div>
        </div>
      ))}
    </div>
  );
};

export default DriversStatus;
