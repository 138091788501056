import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useMemo, FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { NOTIFICATION_TYPES } from 'interfaces/notifications/notification-types';

import {
  NOTIFICATION_TYPES_DATA,
  NOTIFICATION_FILTERS_MODAL_DEFAULT_VALUES,
  TABS_DATA,
} from 'constants/notifications';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';

import { calculateNotificationActiveTab } from 'utils/helpers/calculate-notifications-active-tab';

import notificationActiveTabAtom from 'recoil/notifications/active-tab';
import notificationAppliedFiltersAtom from 'recoil/notifications/applied-filters';

import Badge from 'components/Badge';
import DatePicker from 'components/form-v2/date-picker';
import Select from 'components/form-v2/select';

import { formSchema, IFormValues } from './form-shema';
import useStyles from './styles';

interface INotificationFilter {
  closeFilterModalHandler: () => void;
}

const NotificationFilter: FC<INotificationFilter> = ({ closeFilterModalHandler }) => {
  const classes = useStyles();
  const { t } = useTranslation('notifications.service');

  const activeTab = useRecoilValue(notificationActiveTabAtom);
  const appliedFilters = useRecoilValue(notificationAppliedFiltersAtom);

  const formMethods = useForm<IFormValues>({
    defaultValues: appliedFilters,
    resolver: yupResolver(formSchema),
  });

  /**
   * Remove selected types in filter
   */
  const removeCartTypeHandler = useCallback(
    (type: NOTIFICATION_TYPES) => {
      const filteredTypes = formMethods.watch('cartType').filter((item) => item !== type);
      formMethods.setValue('cartType', filteredTypes);
    },
    [formMethods],
  );

  /**
   * Submit filters and close modal
   */
  const submitFiltersHandler = useRecoilCallback(({ set }) => (data: IFormValues) => {
    set(notificationActiveTabAtom, calculateNotificationActiveTab(data).value);
    set(notificationAppliedFiltersAtom, data);
    closeFilterModalHandler();
  });

  /**
   * Applied notification types based by active tab and selected types
   */
  const formTypes = formMethods.watch('cartType');
  const appliedTypes = useMemo(() => {
    const tabTypes = TABS_DATA(t).find((item) => item.value === activeTab)?.cartType;

    return NOTIFICATION_TYPES_DATA(t).filter((item) => {
      if (tabTypes?.length === 0) {
        return Boolean(formTypes.includes(item.value));
      }
      return Boolean(tabTypes?.includes(item.value) && formTypes.includes(item.value));
    });
  }, [activeTab, formTypes, t]);

  return (
    <div className={classes.wrap} data-testid='notification-filter-main'>
      <div className={classes.header}>
        <div className={classes.title}>{t('filter.title.label')}</div>
        <div onClick={closeFilterModalHandler} data-testid='close-button'>
          <CloseIcon className={classes.closeIcon} />
        </div>
      </div>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(submitFiltersHandler)} data-testid='filter-form'>
          <div className={classes.dateWrap}>
            <DatePicker
              title={t('date.input.label')}
              name='startAt'
              data-testid='start-date-picker'
            />
            <DatePicker
              title=''
              name='finishAt'
              wrapClass={classes.finishDate}
              data-testid='finish-date-picker'
            />
          </div>

          <Select
            title={t('type.input.label')}
            name='cartType'
            multiple
            items={NOTIFICATION_TYPES_DATA(t)}
            renderValueType='number'
            data-testid='type-select'
          />

          <div className={classes.filters} data-testid='filter-badge-list'>
            {appliedTypes.map(({ value, label }) => (
              <Badge
                key={value}
                handleRemove={() => removeCartTypeHandler(value)}
                disabled={false}
                type='outline'
                testId={`filter-badge-${value}`}
              >
                {label}
              </Badge>
            ))}
          </div>
          <div className={classes.btns} data-testid='buttons-wrap'>
            <button
              className={classes.clearBtn}
              onClick={() => formMethods.reset(NOTIFICATION_FILTERS_MODAL_DEFAULT_VALUES)}
              data-testid='clear-button'
            >
              {t('clear.filter.btn.label')}
            </button>
            <button className={classes.submitBtn} type='submit' data-testid='submit-button'>
              {t('apply.filter.btn.label')}
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default NotificationFilter;
