import { atom } from 'recoil';

import { ISettingDriver } from 'requests/be-service/driver-controller/get-settings-drivers';

const settingDriversListAtom = atom<ISettingDriver[]>({
  key: 'settingDriversListAtom',
  default: [],
});

export default settingDriversListAtom;
