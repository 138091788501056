import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';

import settingPaymentCompanyAtom from 'recoil/settings/payment/selected-company/company';
import invoicesIdsAtom from 'recoil/settings/payment/selected-company/invoices';

import { sendPaymentReq } from 'requests/payment/send-payment';

import Button from 'components/form-v2/button';
import Input from 'components/form-v2/input';
import Select from 'components/form-v2/select';
import Loader from 'components/Loader';

import { formSchema, IPaymentFormData } from './form-schema';
import useStyles from './styles';

interface IProps {
  onClose: () => void;
  getCompanyData: () => void;
}
const Payment: FC<IProps> = ({ onClose, getCompanyData }) => {
  const classes = useStyles();
  const { t } = useTranslation(['payment.page', 'fields']);
  const { id } = useParams() as { id: string };
  const companyData = useRecoilValueLoadable(settingPaymentCompanyAtom(id));
  const invoicesIds = useRecoilValueLoadable(invoicesIdsAtom(id));
  const [isLoading, setIsLoading] = useState(false);
  const formFields = useForm<IPaymentFormData>({
    resolver: yupResolver(formSchema),
  });

  const onSubmitForm = async (data: IPaymentFormData) => {
    if (companyData.state !== 'hasValue') {
      return null;
    }
    try {
      setIsLoading(true);
      await sendPaymentReq(companyData.contents.balanceId, data);
      getCompanyData();
      onClose();
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  if (companyData.state !== 'hasValue') {
    return null;
  }

  return (
    <FormProvider {...formFields}>
      <div className={classes.wrap} data-testid='make-payment-wrap'>
        <div className={classes.header}>
          <div className={classes.companyName} data-testid='company-name'>
            {companyData.contents.companyName}
          </div>

          <div className={classes.btns}>
            <div className={classes.btn} onClick={onClose} data-testid='close-button'>
              <CloseIcon width={12} />
            </div>
          </div>

          <div className={classes.shadow} />
        </div>

        <div className={classes.list}>
          {isLoading && <Loader width={150} isBlock lightMode preventClick />}

          <div className={classes.item}>
            <Input
              name={'amount'}
              type={'number'}
              endAdornment={'₼'}
              title={t('transaction.balance.label')}
            />
          </div>

          <div className={classes.item}>
            <Input
              name={'paymentDetails'}
              title={t('transaction.description.label')}
              multiline
              rows={3}
            />
          </div>

          {invoicesIds.state === 'hasValue' && Boolean(invoicesIds.contents.length) && (
            <div className={classes.item}>
              <Select name='transactionId' title='Transaction ID' items={invoicesIds.contents} />
            </div>
          )}
        </div>

        <Button
          className={classes.closeBtn}
          onClick={formFields.handleSubmit(onSubmitForm)}
          disabled={isLoading}
          testId='button-submit'
        >
          {t('fields:save.label')}
        </Button>
      </div>
    </FormProvider>
  );
};

export default Payment;
