import { yupResolver } from '@hookform/resolvers/yup';
import cx from 'classnames';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable } from 'recoil';

import { useCallbackPrompt } from 'hooks/useCallbackPromt';

import { COUNTRIES } from 'interfaces/common/countries';
import { TDataWithId } from 'interfaces/common/data-with-id';
import { IFileWithPreview } from 'interfaces/common/file-with-preview';
import { ISelectItem } from 'interfaces/common/select-items';
import { TCompany } from 'interfaces/company/company';
import { IUserData } from 'interfaces/user/user-data';

import { COUNTRIES_LIST } from 'constants/company';
import { countryDefaultCurrency, currencyList } from 'constants/currency';
import { MEASUREMENT } from 'constants/measure-unit';
import { ROLES_OBJECT } from 'constants/user';

import { ReactComponent as DriverIcon } from 'assets/images/menu/user-menu.svg';
import { ReactComponent as VehicleIcon } from 'assets/images/menu/vehicles-menu.svg';
import { ReactComponent as UploadIcon } from 'assets/images/up-load.svg';

import RouteManager from 'utils/services/route-manager';

import companyStructureSelector from 'recoil/company-structure';
import subcompaniesReqGetAtom from 'recoil/subcompanies/request';
import subcompaniesSelectListAtom from 'recoil/subcompanies/select-list';
import userDataAtom from 'recoil/userData';

import { createCompanyReq } from 'requests/be-service/company-controller/create-company';
import { editCompanyReq } from 'requests/be-service/company-controller/edit-company';
import { getCompanyReq } from 'requests/be-service/company-controller/get-company-details';
import { getCompanyTypesReq } from 'requests/be-service/company-controller/get-company-types';
import { editCompanyAvatarReq } from 'requests/be-service/company-controller/upload-photo';

import Button from 'components/form-v2/button';
import Checkbox from 'components/form-v2/checkbox-with-controller';
import DatePicker from 'components/form-v2/date-picker';
import Input from 'components/form-v2/input';
import Select from 'components/form-v2/select';
import Loader from 'components/Loader';
import Modal from 'components/modal';
import NewAvatar from 'components/NewAvatar';
import UnsavedModal from 'components/unsaved-modal';

import { formSchema } from './form-schema';
import useStyles from './styles';

const CompanyForm = () => {
  const { t } = useTranslation(['setting.company.page', 'settings.company.page.company.types']);
  const { id } = useParams() as { id?: string };
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [avatarError, setAvatarError] = useState(false);
  const [loader, setLoader] = useState(!!id);
  const [avatar, setAvatar] = useState<IFileWithPreview | null>(null);
  const [companyData, setCompanyData] = useState<TCompany | null>(null);
  const [companyTypes, setCompanyTypes] = useState<ISelectItem[] | null>(null);
  const subcompaniesSelectList = useRecoilValueLoadable(subcompaniesSelectListAtom);
  const companyStructures = useRecoilValueLoadable(companyStructureSelector);
  const refreshSubcompaniesList = useRecoilRefresher_UNSTABLE(subcompaniesReqGetAtom);

  const userData = useRecoilValue(userDataAtom) as IUserData;
  const isSubcompanyView = Boolean(sessionStorage.getItem('subcompanyId'));

  const classes = useStyles();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setAvatar(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      )[0],
    );
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'image/*',
    maxSize: 7000000,
    onDropRejected: () => {
      setAvatarError(true);
      setTimeout(() => setAvatarError(false), 1500);
    },
  });

  const formMethods = useForm<TCompany | TDataWithId<TCompany>>({
    resolver: yupResolver(formSchema()),
    defaultValues: {
      useParentBalance: false,
      parentId: userData.companyId,
      activeDriverCount: 0,
      activeVehicleCount: 0,
    },
  });

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    formMethods.formState.isDirty || Boolean(avatar),
  );

  const handleInitCompany = useCallback(async () => {
    if (!id) return;
    try {
      const response = await getCompanyReq(id);

      formMethods.reset(response);
      setCompanyData(response);
    } catch {
      enqueueSnackbar('Something Went Wrong', { variant: 'error' });
    } finally {
      setLoader(false);
    }
  }, [enqueueSnackbar, formMethods, id]);

  const handleCreateCompany = useCallback(
    async (values: TCompany) => {
      setLoader(true);
      try {
        const response = await createCompanyReq(values);
        avatar && (await editCompanyAvatarReq(response.id, avatar));
        await formMethods.reset(response);
        await setAvatar(null);
        enqueueSnackbar('Saved', { variant: 'success' });
        navigate(RouteManager.makeURL('settings.companies.edit', { id: response.id }));
      } catch {
        enqueueSnackbar('Something Went Wrong', { variant: 'error' });
      } finally {
        setLoader(false);
      }
    },
    [avatar, enqueueSnackbar, formMethods, navigate],
  );

  const handleEditCompany = useCallback(
    async (values: TDataWithId<TCompany>) => {
      try {
        setLoader(true);
        await editCompanyReq(values);
        avatar && (await editCompanyAvatarReq(values.id, avatar));
        enqueueSnackbar('Saved', { variant: 'success' });
        await handleInitCompany();
        setAvatar(null);
      } catch {
        enqueueSnackbar('Something Went Wrong', { variant: 'error' });
      } finally {
        setLoader(false);
      }
    },
    [avatar, enqueueSnackbar, handleInitCompany],
  );

  useEffect(() => {
    refreshSubcompaniesList();
    id && handleInitCompany();
  }, [handleInitCompany, id, refreshSubcompaniesList]);

  /**
   * Get company types
   */
  useEffect(() => {
    const req = async () => {
      try {
        const response = await getCompanyTypesReq();

        setCompanyTypes(
          response.map(({ type, translationCode }) => ({
            value: type,
            label: t(`settings.company.page.company.types:${translationCode}`),
          })),
        );
      } catch (error) {
        console.log(error);
      }
    };

    req();
  }, [t]);

  const { iconUrl } = formMethods.getValues();

  if (![ROLES_OBJECT.TECH_ADMIN].includes(userData.role) || (isSubcompanyView && !id)) {
    return <Navigate to={RouteManager.makeURL('scoring')} />;
  }

  return (
    <div className={classes.settings}>
      {loader && <Loader width={150} isBlock lightMode preventClick />}

      <div
        data-testid='company-logo'
        className={cx(classes.settingsAvatar, {
          'animated headShake': avatarError,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div className={classes.dropzone}>
          {isDragActive && (
            <div className={classes.active}>
              <UploadIcon />
            </div>
          )}
          {(avatar || iconUrl) && (
            <div
              className={classes.avatar}
              style={{ backgroundImage: `url(${avatar?.preview || iconUrl})` }}
            />
          )}
        </div>
        {iconUrl ? '' : <NewAvatar />}
      </div>

      <FormProvider {...formMethods}>
        <form
          className={classes.form}
          onSubmit={formMethods.handleSubmit((values) => {
            id
              ? handleEditCompany(values as TDataWithId<TCompany>)
              : handleCreateCompany(values as TCompany);
          })}
          data-testid='form-wrap'
        >
          <div className={classes.container}>
            <Input
              title={t('company.name.label')}
              name='name'
              placeholder={'Enter value'}
              disabled={isSubcompanyView}
            />

            <Input
              title={t('monthly.price.label')}
              name='vehicleMonthlyPrice'
              type='number'
              placeholder={'Enter value'}
              disabled={isSubcompanyView}
            />

            <Select
              title={t('company.type.label')}
              name='companyType'
              items={companyTypes ?? []}
              disabled={isSubcompanyView}
            />

            {id !== userData.companyId && (
              <Select
                title={t('company.parent.label')}
                name='parentId'
                items={
                  Array.isArray(subcompaniesSelectList.contents)
                    ? subcompaniesSelectList.contents
                    : []
                }
                disabled={isSubcompanyView}
                additionalOnChange={() => {
                  formMethods.resetField('useParentBalance');
                }}
              />
            )}

            <Select
              title={t('measurement.label')}
              name='localizationConfig.measurement'
              items={MEASUREMENT}
              disabled={isSubcompanyView}
            />

            <Select
              title={t('currency.label')}
              name='localizationConfig.currency'
              items={currencyList}
              disabled
            />

            <Select
              title={t('country.label')}
              name='localizationConfig.country'
              items={COUNTRIES_LIST}
              disabled={isSubcompanyView || Boolean(id)}
              additionalOnChange={(event) =>
                formMethods.setValue(
                  'localizationConfig.currency',
                  countryDefaultCurrency[event.target.value as COUNTRIES],
                )
              }
            />
            <Input
              title={t('tin.label')}
              name='tin'
              type='text'
              placeholder={'Enter value'}
              disabled={isSubcompanyView}
            />

            <Input
              title={t('contractNumber.label')}
              name='contractNumber'
              type='text'
              placeholder={'Enter value'}
              disabled={isSubcompanyView}
            />
            <DatePicker
              title={t('contractDate.label')}
              name='contractDate'
              disabled={isSubcompanyView}
            />

            <Select
              title={t('companyStructure.label')}
              name='companyStructure'
              items={Array.isArray(companyStructures.contents) ? companyStructures.contents : []}
              disabled={isSubcompanyView}
            />

            <Checkbox
              name='useParentBalance'
              wrapClass={classes.useParent}
              label={t('useParent.label')}
              disabled={formMethods.watch('parentId') === userData.companyId} // block for zaman company
            />
          </div>

          <div className={classes.fieldsWrap}>
            <div className={classes.field}>
              <DriverIcon />
              <div data-testid='activeDriverCount'>{companyData?.activeDriverCount ?? '-'}</div>
            </div>
            <div className={classes.field}>
              <VehicleIcon />
              <div data-testid='activeVehicleCount'>{companyData?.activeVehicleCount ?? '-'}</div>
            </div>
          </div>
          <div className={classes.submit}>
            {!isSubcompanyView && (
              <Button testId='button-submit' type='submit'>
                {t('save.label')}
              </Button>
            )}
          </div>
        </form>
      </FormProvider>

      <Modal open={showPrompt} onClose={cancelNavigation}>
        <UnsavedModal cancelNavigation={cancelNavigation} confirmNavigation={confirmNavigation} />
      </Modal>
    </div>
  );
};

export default CompanyForm;
