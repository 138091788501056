import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

type Props = {
  technicalMessageBlockHeight: number;
};

const useStyles = makeStyles<Theme, Props>(() => ({
  container: {
    width: '100%',
    height: ({ technicalMessageBlockHeight }) => `calc(100vh - ${technicalMessageBlockHeight}px)`,
    position: 'relative',
  },

  layer: {
    cursor: 'pointer',
    zIndex: 100,
    position: 'absolute',
    right: 60,
    bottom: 30,
  },

  btn: {
    position: 'absolute',
    right: 0,
    top: 10,
    width: 40,
    height: 50,
    backgroundColor: palette.custom.bluePrimary,
    borderRadius: '16px 0px 0px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all 0.3s cubic-bezier(0, 0, 0.2, 1)',
    zIndex: 1300,

    '&.isOpen': {
      right: 500,

      '& $arrow': {
        transform: 'rotate(-90deg)',
      },
    },
  },

  arrow: {
    transform: 'rotate(90deg)',
    transition: 'all 0.3s ease',
  },
}));

export default useStyles;
