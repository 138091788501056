import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 1440,
    maxHeight: 'calc(100vh - 450px)',
  },

  row: {
    '&:hover': {
      background: palette.custom.lightBlue,
    },
  },
}));

export default useStyles;
