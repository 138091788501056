import { IConvertedSensorsAlarms } from 'interfaces/sensor/i-converted-sensors-alarms';
import { TSensorAlarmByTrip } from 'interfaces/sensor/t-sensor-alarm-by-trip';

import { convertSensorsAlarms } from 'utils/helpers/convert-sensors-alarms';
import server from 'utils/server';

/**
 * Retrieves the sensor alarms for a given trip.
 *
 * @async
 * @param {string} tripId - The ID of the trip to retrieve the sensor alarms for.
 * @returns {Promise<IConvertedSensorsAlarms>} A Promise that resolves to the converted sensor alarms.
 */
export const getSensorAlarmForTrip = async (tripId: string): Promise<IConvertedSensorsAlarms> => {
  return server.services.gpsService
    .get<TSensorAlarmByTrip[]>(`sensors/alarm/${tripId}`)
    .then<IConvertedSensorsAlarms>((res) => convertSensorsAlarms(res.data));
};
