import cx from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { ReactComponent as SearchIcon } from 'assets/images/search.svg';

import dashboardSidebarIsAllSelectedAtom from 'recoil/dashboard-map/sidebar/is-all-selected';
import dashboardSidebarListTypeAtom from 'recoil/dashboard-map/sidebar/list-type';
import { SIDEBAR_TYPES } from 'recoil/dashboard-map/sidebar/list-type/atom';
import dashboardSidebarListViewTypeAtomAtom from 'recoil/dashboard-map/sidebar/list-view-type';
import dashboardSidebarSelectedItemsAtom from 'recoil/dashboard-map/sidebar/selected-items';

import Checkbox from 'components/form-v2/checkbox';
import Switch from 'components/form-v2/switch';

import Search from '../../search';

import useStyles from './styles';

const TeamsSwitchLabel = () => {
  const classes = useStyles();
  const { t } = useTranslation('dashboard.map.page');

  return <span className={classes.teamsSwitchLabel}>{t('teams.label')}</span>;
};

const Header = ({ setIsDirty, setValue }) => {
  const classes = useStyles();
  const { t } = useTranslation('dashboard.map.page');

  const [isShowSearchInput, setIsShowSearchInput] = useState(false);
  const [isSelectAll, setIsSelectAll] = useRecoilState(dashboardSidebarIsAllSelectedAtom);
  const [selectedItems, setSelectedItems] = useRecoilState(dashboardSidebarSelectedItemsAtom);
  const [viewType, setViewType] = useRecoilState(dashboardSidebarListViewTypeAtomAtom);
  const [listType, setListType] = useRecoilState(dashboardSidebarListTypeAtom);

  return (
    <div className={classes.header}>
      {!isShowSearchInput && (
        <>
          <div className={classes.leftSide}>
            <Checkbox
              value={isSelectAll}
              onChange={(value) => {
                setIsDirty(true);
                setSelectedItems([]);
                setValue('selectedItems', []);
                setIsSelectAll(value);
                setValue('isSelectAll', value);
              }}
              testId='checkbox-map-sidebar-all'
              isIndeterminate={selectedItems.length > 0}
            />
            {['DRIVER', 'VEHICLE'].map((item) => {
              const isActive = item === viewType;
              return (
                <div
                  key={item}
                  className={cx(classes.viewBtn, { isActive })}
                  onClick={() => setViewType(item)}
                  data-testdid='button-viewType'
                >
                  {t(`${item.toLowerCase()}s.label`)}
                </div>
              );
            })}

            <Switch
              value={listType === SIDEBAR_TYPES.TEAMS}
              onChange={() =>
                setListType((prev) => {
                  if (prev === SIDEBAR_TYPES.TEAMS) {
                    return SIDEBAR_TYPES.DEFAULT;
                  }
                  return SIDEBAR_TYPES.TEAMS;
                })
              }
              LeftIcon={TeamsSwitchLabel}
              RightIcon={TeamsSwitchLabel}
              wrapClass={classes.switch}
            />
          </div>

          <SearchIcon
            className={classes.searchIcon}
            onClick={() => {
              setIsShowSearchInput(true);
              setListType(SIDEBAR_TYPES.SEARCH);
            }}
            data-testdid='button-search'
          />
        </>
      )}

      {isShowSearchInput && <Search setIsShowSearchInput={setIsShowSearchInput} />}

      <div className={classes.boxShadow} />
    </div>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
