import { atom } from 'recoil';

const vehiclesPageDataAtom = atom({
  key: 'vehiclesPageDataAtom',
  default: {
    content: [],
    empty: false,
    number: 0,
    numberOfElements: 0,
    pageable: {},
    size: 15,
    sort: {},
    totalElements: 0,
    totalPages: 0,
    isLoading: false,
  },
});

export default vehiclesPageDataAtom;
