import { ISensorData } from 'interfaces/sensor/i-sensor-data';
import { TSensorRenderData } from 'interfaces/sensor/t-sensor-render-data';

import { valueConverter } from 'utils/helpers/value-converter';

interface ISensorDto {
  post: (data: TSensorRenderData) => ISensorData;
  get: (data: ISensorData) => TSensorRenderData;
}

/**
 * Represents a sensor data transfer object.
 * @typedef {Object} ISensorDto
 * @property {Function} post - A function that formats sensor data for posting.
 * @property {Function} get - A function that formats sensor data for getting.
 */
export const sensorDTO: ISensorDto = {
  post: ({ sensorName, notification, humidity, movement, temperature, ...data }) => {
    return {
      ...data,
      sensorName,
      notification,
      temperature: {
        active: temperature.active,
        celsius: temperature.celsius,
        from: temperature.celsius
          ? temperature.value[0]
          : valueConverter.temperature.fahrenheitToCelsius(temperature.value[0]),
        to: temperature.celsius
          ? temperature.value[1]
          : valueConverter.temperature.fahrenheitToCelsius(temperature.value[1]),
      },
      humidity: {
        active: humidity.active,
        from: humidity.value[0],
        to: humidity.value[1],
      },
      movement: {
        active: movement.active,
        from: null,
        to: movement.value,
        zones: movement.zones,
        subzones: movement.subzones,
      },
    };
  },
  get: ({ sensorName, notification, temperature, humidity, movement, ...data }) => {
    return {
      ...data,
      sensorName,
      notification,
      temperature: {
        active: temperature.active,
        celsius: temperature.celsius,
        value: [
          temperature.celsius
            ? temperature.from ?? 0
            : valueConverter.temperature.celsiusToFahrenheit(temperature.from ?? 0),
          temperature.celsius
            ? temperature.to ?? 0
            : valueConverter.temperature.celsiusToFahrenheit(temperature.to ?? 0),
        ],
      },
      humidity: {
        active: humidity.active,
        value: [humidity.from ?? 0, humidity.to ?? 0],
      },
      movement: {
        active: movement.active,
        value: movement.to ?? 0,
        zones: movement.zones,
        subzones: movement.subzones,
      },
    };
  },
};
