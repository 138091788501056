import { ICompanyType } from 'interfaces/company/company-type';

import server from 'utils/server';

/**
 * Retrieves the company types from the server.
 *
 * @async
 * @returns {Promise<ICompanyType[]>} A promise that resolves to an array of company types.
 */
export const getCompanyTypesReq = async (): Promise<ICompanyType[]> =>
  server.services.beService.get<ICompanyType[]>('companies/types').then((res) => res.data);
