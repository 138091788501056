import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    textTransform: 'capitalize',

    '&.SUCCESS': {
      color: `${palette.custom.green400} !important`,
    },

    '&.PROCESSING': {
      color: `${palette.custom.yellow400} !important`,
    },

    '&.REJECTED, &.ERROR': {
      color: `${palette.custom.red900} !important`,
    },
  },
}));

export default useStyles;
