import { useCallback, useState, useEffect, useRef } from 'react';

export enum ANIMATION_STATUS {
  PLAY = 'PLAY',
  PAUSE = 'PAUSE',
  STOP = 'STOP',
}

interface RouteAnimationResult {
  currentPosition: google.maps.LatLngLiteral | null;
  animationStatus: ANIMATION_STATUS;
  play: () => void;
  pause: () => void;
  stop: () => void;
  currentIndex: number;
  setPosition: (positionIndex: number) => void;
}

const useRouteAnimation = (
  path: google.maps.LatLngLiteral[],
  speedMetersPerSecond: number,
): RouteAnimationResult => {
  const [animationStatus, setAnimationStatus] = useState<ANIMATION_STATUS>(ANIMATION_STATUS.STOP);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [currentPosition, setCurrentPosition] = useState<google.maps.LatLngLiteral | null>(null);
  const animationTimerRef = useRef<number | null>(null);
  const animateMarkerRef = useRef<() => void>();

  const play = useCallback(() => {
    setAnimationStatus(ANIMATION_STATUS.PLAY);
  }, []);

  const pause = useCallback(() => {
    if (animationTimerRef.current !== null) {
      cancelAnimationFrame(animationTimerRef.current);
      animationTimerRef.current = null;
    }
    setAnimationStatus(ANIMATION_STATUS.PAUSE);
  }, []);

  const stop = useCallback(() => {
    if (animationTimerRef.current !== null) {
      cancelAnimationFrame(animationTimerRef.current);
      animationTimerRef.current = null;
    }
    setAnimationStatus(ANIMATION_STATUS.STOP);
    setCurrentIndex(0);
    setCurrentPosition(null);
  }, []);

  const setPosition = useCallback(
    (positionIndex: number) => {
      setCurrentIndex(positionIndex);
      setCurrentPosition(path[positionIndex]);
    },
    [path],
  );

  const animateMarker = useCallback(() => {
    const currentPoint = path[currentIndex];
    const nextPoint = path[currentIndex + 1];

    if (!currentPoint || !nextPoint) {
      setAnimationStatus(ANIMATION_STATUS.STOP);
      setCurrentIndex(0);
      setCurrentPosition(null);
      return;
    }

    const distanceBetweenPoints = google.maps.geometry.spherical.computeDistanceBetween(
      currentPoint,
      nextPoint,
    );

    if (distanceBetweenPoints === 0) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      return;
    }

    const timeInSeconds = distanceBetweenPoints / speedMetersPerSecond;

    const startTime = performance.now();

    const moveMarker = (currentTime: number) => {
      if (animationStatus !== ANIMATION_STATUS.PLAY) {
        return;
      }

      const elapsedTime = (currentTime - startTime) / 1000;
      const t = Math.min(elapsedTime / timeInSeconds, 1);

      const newPosition = {
        lat: currentPoint.lat + (nextPoint.lat - currentPoint.lat) * t,
        lng: currentPoint.lng + (nextPoint.lng - currentPoint.lng) * t,
      };

      setCurrentPosition(newPosition);

      if (t < 1) {
        animationTimerRef.current = requestAnimationFrame(moveMarker);
      } else {
        setCurrentIndex((prevIndex) => prevIndex + 1);
        if (currentIndex < path.length) {
          animationTimerRef.current = requestAnimationFrame(animateMarkerRef.current!);
        } else {
          setAnimationStatus(ANIMATION_STATUS.STOP);
          setCurrentIndex(0);
          setCurrentPosition(null);
        }
      }
    };

    animationTimerRef.current = requestAnimationFrame(moveMarker);

    return () => {
      if (animationTimerRef.current !== null) {
        cancelAnimationFrame(animationTimerRef.current);
        animationTimerRef.current = null;
      }
    };
  }, [animationStatus, path, speedMetersPerSecond, currentIndex]);

  useEffect(() => {
    animateMarkerRef.current = animateMarker;
  }, [animateMarker]);

  useEffect(() => {
    if (animationStatus === ANIMATION_STATUS.PLAY && currentIndex < path.length) {
      animateMarkerRef.current?.();
    }

    return () => {
      if (animationTimerRef.current !== null) {
        cancelAnimationFrame(animationTimerRef.current);
        animationTimerRef.current = null;
      }
    };
  }, [animationStatus, path, speedMetersPerSecond, currentIndex]);

  return {
    currentPosition,
    animationStatus,
    play,
    pause,
    stop,
    currentIndex,
    setPosition,
  };
};

export default useRouteAnimation;
