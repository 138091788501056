import React, { FC } from 'react';

import { getScoringColor } from 'utils/helpers/get-scoring-color';
import { isEmptyValue } from 'utils/helpers/is-empty-value';
import { numberLimit } from 'utils/helpers/number-limit';

interface IScoreValue {
  className?: string;
  value?: number | null;
  isMonochrome?: boolean;
}

const ScoreValue: FC<IScoreValue> = ({ className, value, isMonochrome = false }) => {
  return (
    <span
      className={className}
      style={{
        color: isMonochrome
          ? 'unset'
          : !isEmptyValue(value)
          ? getScoringColor(value as number)
          : 'unset',
      }}
      data-testid='score-component'
    >
      {!isEmptyValue(value) ? numberLimit(Math.abs(value as number)) : '-'}
    </span>
  );
};

export default ScoreValue;
