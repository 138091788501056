import { ApexOptions } from 'apexcharts';

import { CURRENCY_SYMBOL } from 'interfaces/common/currency-symbol';

import { palette } from 'assets/theme';

import { IChartData } from 'requests/be-service/saving-controller/get-graph-saving';

const createOptions = (data: IChartData, currencyIcon: CURRENCY_SYMBOL): ApexOptions => {
  return {
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
      colors: [palette.custom.goldSecondary],
      width: 2,
    },
    legend: {
      show: false,
    },
    grid: {
      show: true,
      borderColor: palette.custom.mainLight,
      strokeDashArray: 2,
      position: 'back',
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    tooltip: {
      custom: function ({ series, dataPointIndex }) {
        const total = series[0][dataPointIndex];

        return (
          '<div class="tooltip">' +
          '<div>' +
          `${data.date[dataPointIndex]}:` +
          '</div>' +
          '<div>' +
          `${total < 0 ? '-' : ''}${currencyIcon}${Math.abs(total)
            .toString()
            .replace(/(\d)(?=(\d{3})+$)/g, '$1,')}` +
          '</div>' +
          '</div>'
        );
      },
    },
    xaxis: {
      categories: data?.date ?? [],
      labels: {
        show: true,
        style: {
          colors: palette.custom.mainLight,
          fontSize: '12px',
          fontWeight: 500,
          fontFamily: 'SFPro',
        },
      },
      axisBorder: {
        show: true,
        color: palette.custom.mainLight,
        offsetX: 0,
        offsetY: 1,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      tickAmount: 6,
      forceNiceScale: true,
      labels: {
        show: true,
        style: {
          colors: palette.custom.mainLight,
          fontSize: '12px',
          fontWeight: 500,
          fontFamily: 'SFPro',
        },
        formatter: (value) =>
          `${value < 0 ? '-' : ''}${currencyIcon}${Math.abs(+value?.toFixed(1))}`,
      },
      axisBorder: {
        show: true,
        color: palette.custom.mainLight,
        width: 0.665924,
        offsetX: -1,
        offsetY: 0,
      },
    },
    markers: {
      size: [4],
      colors: [palette.custom.lightBlue],
      strokeColors: palette.custom.goldSecondary,
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: 'circle',
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },
  };
};

export default createOptions;
