import Tooltip from '@mui/material/Tooltip';
import cx from 'classnames';
import React, { FC, useMemo } from 'react';

import { ACTIVITY_STATUSES } from 'interfaces/common/activity-statuses';

import { VEHICLES_ACTIVE_STATUSES } from 'constants/vehicle';

import { ReactComponent as PathActiveIcon } from 'assets/images/dashboard-map/path-active.svg';
import { ReactComponent as PathFinishedIcon } from 'assets/images/dashboard-map/path-finished.svg';
import { ReactComponent as VehicleIdliIcon } from 'assets/images/dashboard-map/vehicle-idli.svg';
import { ReactComponent as VehicleParkedIcon } from 'assets/images/dashboard-map/vehicle-parked.svg';

import useStyles from './styles';

interface IProps {
  status: ACTIVITY_STATUSES;
  startTime: string;
  startAddress: string;
  finishTime?: string;
  finishAddress?: string;
}

const Path: FC<IProps> = ({ status, startTime, startAddress, finishAddress, finishTime }) => {
  const classes = useStyles();

  const isParked = [VEHICLES_ACTIVE_STATUSES.IDLING, VEHICLES_ACTIVE_STATUSES.INACTIVE].includes(
    status,
  );
  const hasFinishAddress = Boolean(finishAddress);

  const Icon = useMemo(() => {
    if (status === ACTIVITY_STATUSES.ACTIVE && !finishAddress) {
      return PathActiveIcon;
    }

    if (status === ACTIVITY_STATUSES.ACTIVE && finishAddress) {
      return PathFinishedIcon;
    }

    if (status === ACTIVITY_STATUSES.INACTIVE) {
      return VehicleParkedIcon;
    }

    if (status === ACTIVITY_STATUSES.IDLING) {
      return VehicleIdliIcon;
    }
  }, [finishAddress, status]);

  return (
    <div className={cx(classes.wrap, { isParked })} data-testid='path-wrap'>
      {Icon && <Icon />}
      <div className={classes.addresses}>
        <div className={cx(classes.point, { isParked })}>
          <Tooltip title={startAddress} arrow>
            <div
              className={cx(classes.address, { isParked, hasFinishAddress })}
              data-testid='startAddress'
            >
              {startAddress}
            </div>
          </Tooltip>
          <div className={classes.time} data-testdid='startTime'>
            {startTime}
          </div>
        </div>

        {finishAddress && (
          <div className={classes.point}>
            <Tooltip title={finishAddress} arrow>
              <div
                className={cx(classes.address, { hasFinishAddress }, 'isFinish')}
                data-testdid='finishAddress'
              >
                {finishAddress}
              </div>
            </Tooltip>
            <div className={classes.time} data-testdid='finishTime'>
              {finishTime}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Path;
