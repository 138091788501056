import { setLocale } from 'yup';

setLocale({
  mixed: {
    required: 'required.label',
    default: 'default.label',
    notType: 'wrongType.label',
  },
  number: {
    integer: 'number.integer.label',
    positive: 'number.positive.label',
    moreThan: ({ more }) => ({ key: 'number.moreThan.label', values: { more } }),
    max: ({ max }) => ({ key: 'number.max.label', values: { max } }),
    min: ({ min }) => ({ key: 'number.min.label', values: { min } }),
  },
  string: {
    max: ({ max }) => ({ key: 'string.max.label', values: { max } }),
    min: ({ min }) => ({ key: 'string.min.label', values: { min } }),
    matches: 'string.matches.label',
  },
  date: {
    min: ({ min }) => ({ key: 'date.min.label', values: { min } }),
    max: ({ max }) => ({ key: 'date.max.label', values: { max } }),
  },
});
