import { FC, useState } from 'react';

import { ReactComponent as VehicleIcon } from 'assets/images/map/vehicle.svg';

import useStyles from './styles';

interface IProps {
  speed: number;
}

const VehicleMarker: FC<IProps> = ({ speed }) => {
  const classes = useStyles();
  const [showSpeed, setShowSpeed] = useState(true);

  const toggleSpeed = () => {
    setShowSpeed((prevShowSpeed) => !prevShowSpeed);
  };

  return (
    <div className={classes.wrap} onClick={toggleSpeed}>
      {showSpeed && <div className={classes.speed}>{speed}</div>}

      <div className={classes.icon}>
        <VehicleIcon />
      </div>
    </div>
  );
};

export default VehicleMarker;
