import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import subWeeks from 'date-fns/subWeeks';
import { TFunction } from 'react-i18next';

import { INotificationTab, NOTIFICATION_TABS } from 'interfaces/notifications/notification-tab';

import {
  NOTIFICATION_NOT_READ_TYPES,
  NOTIFICATION_TYPES,
} from '../interfaces/notifications/notification-types';

// data for select in filters
export const NOTIFICATION_TYPES_DATA = (t: TFunction<'notifications.service'>) => {
  return [
    {
      label: t('notification.type.yellow.label'),
      value: NOTIFICATION_TYPES.YELLOW_CARD,
    },
    {
      label: t('notification.type.red.label'),
      value: NOTIFICATION_TYPES.RED_CARD,
    },
    {
      label: t('notification.type.crash.label'),
      value: NOTIFICATION_TYPES.CRASH,
    },
    {
      label: t('notification.type.hood.label'),
      value: NOTIFICATION_TYPES.HOOD,
    },
    {
      label: t('notification.type.boot.label'),
      value: NOTIFICATION_TYPES.BOOT,
    },
    {
      label: t('notification.type.belt.label'),
      value: NOTIFICATION_TYPES.BELT,
    },
    {
      label: t('notification.type.door.label'),
      value: NOTIFICATION_TYPES.DOOR,
    },
    {
      label: t('notification.type.window.label'),
      value: NOTIFICATION_TYPES.WINDOW,
    },
    {
      label: t('notification.type.temperature.label'),
      value: NOTIFICATION_TYPES.TEMPERATURE,
    },
    {
      label: t('notification.type.humidity.label'),
      value: NOTIFICATION_TYPES.HUMIDITY,
    },
    {
      label: t('notification.type.movement.label'),
      value: NOTIFICATION_TYPES.MOVEMENT,
    },
    {
      label: t('notification.type.speeding.violation.label'),
      value: NOTIFICATION_TYPES.SPEEDING,
    },
    {
      label: t('notification.type.ignition.on.label'),
      value: NOTIFICATION_TYPES.IGNITION_ON,
    },
    {
      label: t('notification.type.ignition.off.label'),
      value: NOTIFICATION_TYPES.IGNITION_OFF,
    },
    {
      label: t('notification.type.unplug.label'),
      value: NOTIFICATION_TYPES.UNPLUG,
    },
  ];
};

export const NOTIFICATION_FILTERS_MODAL_DEFAULT_VALUES = {
  startAt: subWeeks(startOfDay(new Date()), 1),
  finishAt: endOfDay(new Date()),
  cartType: [],
};

/**
 * list of notification tabs with default types
 */
export const TABS_DATA = (t: TFunction<'notifications.service'> = () => ''): INotificationTab[] => {
  return [
    {
      title: t('tab.all.label'),
      unreadMessagesField: NOTIFICATION_NOT_READ_TYPES.TOTAL,
      value: NOTIFICATION_TABS.ALL,
      cartType: [],
    },
    {
      title: t('tab.penalty.label'),
      unreadMessagesField: NOTIFICATION_NOT_READ_TYPES.PENALTY_CARD,
      value: NOTIFICATION_TABS.PENALTY,
      cartType: [NOTIFICATION_TYPES.RED_CARD, NOTIFICATION_TYPES.YELLOW_CARD],
    },
    {
      title: t('tab.collision.label'),
      unreadMessagesField: NOTIFICATION_NOT_READ_TYPES.CRASH,
      value: NOTIFICATION_TABS.COLLISION,
      cartType: [NOTIFICATION_TYPES.CRASH],
    },
    {
      title: t('tab.can.label'),
      unreadMessagesField: NOTIFICATION_NOT_READ_TYPES.CAN_CONTROL,
      value: NOTIFICATION_TABS.CAN_CONTROL,
      cartType: [
        NOTIFICATION_TYPES.BELT,
        NOTIFICATION_TYPES.BOOT,
        NOTIFICATION_TYPES.DOOR,
        NOTIFICATION_TYPES.FUEL_FRAUD,
        NOTIFICATION_TYPES.HOOD,
        NOTIFICATION_TYPES.OIL_LEVEL,
        NOTIFICATION_TYPES.ENGINE_TEMPERATURE,
        NOTIFICATION_TYPES.WINDOW,
      ],
    },
    {
      title: t('tab.sensors.label'),
      unreadMessagesField: NOTIFICATION_NOT_READ_TYPES.SENSOR,
      value: NOTIFICATION_TABS.SENSORS,
      cartType: [
        NOTIFICATION_TYPES.TEMPERATURE,
        NOTIFICATION_TYPES.HUMIDITY,
        NOTIFICATION_TYPES.MOVEMENT,
      ],
    },
    {
      title: t('tab.status.label'),
      value: NOTIFICATION_TABS.DEVICE_POWER,
      cartType: [
        NOTIFICATION_TYPES.IGNITION_ON,
        NOTIFICATION_TYPES.IGNITION_OFF,
        NOTIFICATION_TYPES.UNPLUG,
      ],
      unreadMessagesField: NOTIFICATION_NOT_READ_TYPES.DEVICE_POWER,
    },
  ];
};
