import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },

  logo: {
    maxWidth: 264,
    width: '100%',
  },

  form: {
    marginTop: 60,
    maxWidth: 343,
    width: '100%',
  },

  input: {
    marginBottom: 25,
  },

  link: {
    marginTop: 30,
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  modalContent: {
    backgroundColor: palette.custom.bluePrimary,
    borderRadius: 10,
    padding: 15,
    position: 'relative',
  },

  closeIcon: {
    width: 15,
    cursor: 'pointer',
    position: 'absolute',
    top: 19,
    right: 15,
  },

  title: {
    marginBottom: 20,
    fontWeight: 600,
    fontSize: 20,
    color: palette.custom.blue200,
  },

  domains: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 10,
    marginBottom: 20,
  },

  domain: {
    width: 152,
    height: 152,
    borderRadius: 10,
    backgroundColor: palette.custom.blue900,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: '2px solid transparent',

    '&.selected': {
      border: `2px solid ${palette.custom.goldSecondary}`,
    },
  },

  domainLabel: {
    fontWeight: 500,
    fontSize: 14,
    color: palette.custom.blue200,
    marginTop: 39,
  },

  btn: {
    minHeight: 55,
  },
}));

export default useStyles;
