import { FC, useRef, useEffect } from 'react';
import { createRoot, Root } from 'react-dom/client';

type LatLngLiteral = google.maps.LatLngLiteral;
type Map = google.maps.Map;
type AdvancedMarkerElement = google.maps.marker.AdvancedMarkerElement;

interface MarkerProps {
  map: Map;
  position: LatLngLiteral | null;
  children: React.ReactNode;
  onClick?: () => void;
}

const Marker: FC<MarkerProps> = ({ map, position, onClick, children }) => {
  const markerRef = useRef<AdvancedMarkerElement>();
  const rootRef = useRef<Root>();
  const clickListenerRef = useRef<google.maps.MapsEventListener>();

  useEffect(() => {
    if (!rootRef.current) {
      const container = document.createElement('div');
      rootRef.current = createRoot(container);
      markerRef.current = new google.maps.marker.AdvancedMarkerElement({
        position: position,
        content: container,
      });
    }

    if (onClick) {
      // Remove previous click listener
      if (clickListenerRef.current) {
        clickListenerRef.current.remove();
      }

      // Add new click listener
      clickListenerRef.current = markerRef.current?.addListener('click', onClick);
    }

    return () => {
      // Clean up click listener when component unmounts
      if (clickListenerRef.current) {
        clickListenerRef.current.remove();
      }
    };
  }, [onClick, position]);

  useEffect(() => {
    if (!markerRef.current || !rootRef.current) return;

    if (position) {
      rootRef.current.render(children);
      markerRef.current.position = position;
      markerRef.current.map = map;
    } else {
      markerRef.current.map = null;
    }
  }, [map, position, children]);

  return null;
};

export default Marker;
