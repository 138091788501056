import { atom } from 'recoil';

import { ISortParameters } from 'interfaces/common/sort';

import recoilLocalStorageEffect from 'utils/recoil-localStorage-effect';

import { SORT_DIRECTION } from '../../settings/payment/transactions/sort';

const initValues = {
  sort: '',
  direction: SORT_DIRECTION.ASC,
};

const driversSortDataAtom = atom<ISortParameters>({
  key: 'driversSortDataAtom',
  default: initValues,
  effects_UNSTABLE: [recoilLocalStorageEffect('driversSortDataAtom', initValues)],
});

export default driversSortDataAtom;
