/**
 * Represents different currency symbols.
 *
 * @enum {string}
 * @readonly
 */
export enum CURRENCY_SYMBOL {
  AZN = '₼',
  GBP = '£',
  USD = '$',
  EUR = '€',
}
