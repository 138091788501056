import { atom, selector } from 'recoil';

const technicalMessageBlockHeightAtom = atom<number>({
  key: 'technicalMessageBlockHeightAtom',
  default: 0,
});

const technicalMessageBlockHeightSelector = selector<number>({
  key: 'technicalMessageBlockHeightSelector',
  get: ({ get }) => {
    return get(technicalMessageBlockHeightAtom);
  },
  set: ({ set }, value) => {
    if (!value) {
      set(technicalMessageBlockHeightAtom, 0);
    } else set(technicalMessageBlockHeightAtom, Number(value) + 24);
  },
});

export default technicalMessageBlockHeightSelector;
