import server from 'utils/server';

interface IPostContacts {
  companyName: string;
  contactNumber: string;
  country: string;
  email: string;
  industry: string;
  message: string;
  name: string;
  vehicleQuantity: string;
}

/**
 * Sends a POST request to create a new call request ticket.
 *
 * @param {IPostContacts} body - The contact data to be sent in the request body.
 *
 * @returns {Promise<IPostContacts>} A promise that resolves with the created call request ticket or rejects with an error.
 */
export const postContactsReq = async (body: IPostContacts): Promise<IPostContacts> =>
  server.services.beService.post<IPostContacts>('contact/', body).then((res) => res.data);
