/**
 * Enum representing a list of countries.
 *
 * @enum {string}
 * @readonly
 */
export enum COUNTRIES {
  AZ = 'AZ',
  UK = 'UK',
  US = 'US',
  AT = 'AT',
}
