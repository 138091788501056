import BadgeCounter from '@mui/material/Badge';
import DialogContent from '@mui/material/DialogContent';
import Modal from '@mui/material/Modal';
import cx from 'classnames';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { INotificationMessage } from 'interfaces/notifications/message';
import { NOTIFICATION_TABS } from 'interfaces/notifications/notification-tab';
import { NOTIFICATION_TYPES } from 'interfaces/notifications/notification-types';

import { TABS_DATA } from 'constants/notifications';

import { ReactComponent as ReadAllIcon } from 'assets/images/double-check.svg';
import { ReactComponent as FilterIcon } from 'assets/images/notifications/filter.svg';

import RouteManager from 'utils/services/route-manager';

import crashModalAtom from 'recoil/crash-modal';
import notificationActiveTabAtom from 'recoil/notifications/active-tab';
import notificationAppliedFiltersAtom from 'recoil/notifications/applied-filters';
import notificationAppliedFiltersCountAtom from 'recoil/notifications/applied-filters-count';
import notificationMessagesAtom from 'recoil/notifications/messages';
import notificationsNotReadCountAtom from 'recoil/notifications/not-read-count';
import notificationRenderFiltersAtom from 'recoil/notifications/render-filters';
import notificationRequestFiltersSelector from 'recoil/notifications/request-filters';

import { messageStatusChange } from 'requests/notification-service/change-message-status';
import { getNotificationsReq } from 'requests/notification-service/get-messages';

import Badge from 'components/Badge';
import Loader from 'components/Loader';

import Backdrop from '../backdrop';
import NotificationFilter from '../filter';
import { IFormValues } from '../filter/form-shema';
import Message from '../message';
import ReadAllModal from '../read-all-modal';

import useStyles from './styles';

interface INotificationsModal {
  getCounter: () => void;
  closeNotificationModalHandler: () => void;
}

const NotificationsModal: FC<INotificationsModal> = ({
  getCounter,
  closeNotificationModalHandler,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation('notifications.service');

  const setCrashModalRecoilState = useSetRecoilState(crashModalAtom);
  const notificationsNotReadCount = useRecoilValue(notificationsNotReadCountAtom);
  const appliedFiltersCount = useRecoilValue(notificationAppliedFiltersCountAtom);
  const renderFilters = useRecoilValue(notificationRenderFiltersAtom);
  const setAppliedFilters = useSetRecoilState(notificationAppliedFiltersAtom);
  const requestFilters = useRecoilValue(notificationRequestFiltersSelector);
  const [notificationMessages, setNotificationMessages] = useRecoilState(notificationMessagesAtom);
  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);
  const [isOpenReadAllModal, setIsOpenReadAllModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [infinityScrollData, setInfinityScrollData] = useState({
    page: 0,
    totalMessages: 0,
  });
  const [activeTab, setActiveTab] = useRecoilState(notificationActiveTabAtom);

  const openFilterModalHandler = () => setIsOpenFilterModal(true);
  const closeFilterModalHandler = () => setIsOpenFilterModal(false);

  /**
   * Mark notification as read and get count of unread notifications
   */
  const onClickNotificationHandler = useCallback(
    async (notification: INotificationMessage) => {
      try {
        await messageStatusChange(notification.id);
        getCounter();
        setNotificationMessages((prevState) =>
          prevState.map((item) =>
            item.id === notification.id ? { ...item, read: true } : { ...item },
          ),
        );

        if (
          [NOTIFICATION_TYPES.RED_CARD, NOTIFICATION_TYPES.YELLOW_CARD].includes(
            notification.type,
          ) &&
          notification.tripId
        ) {
          navigate(
            RouteManager.makeURL('drivers.trip', {
              id: notification.driverId,
              tripId: notification.tripId,
            }),
          );
        }

        if (notification.type === NOTIFICATION_TYPES.CRASH) {
          // TODO: fix after complete crash modal
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setCrashModalRecoilState((prevState) => {
            return {
              ...prevState,
              isOpen: true,
              crashList: [notification.crashId],
              currentCrashIdNumber: 0,
            };
          });
        }

        closeNotificationModalHandler();
      } catch (e) {
        console.log(e);
      }
    },
    [
      closeNotificationModalHandler,
      getCounter,
      navigate,
      setCrashModalRecoilState,
      setNotificationMessages,
    ],
  );

  /**
   * Clear filters when close notifications modal and messages list
   */
  useEffect(() => {
    return () => setNotificationMessages([]);
  }, [setNotificationMessages]);

  /**
   * Retrieves notification messages based on the supplied page number.
   * @param {number} page - The page number to retrieve notifications from.
   * @returns {void}
   * @throws {Error} - If an error occurs while retrieving notifications.
   */
  const getNotifications = useCallback(
    async (page: number) => {
      try {
        if (!requestFilters.cartType) {
          setNotificationMessages([]);
          return;
        }
        const response = await getNotificationsReq(page, requestFilters as IFormValues);
        if (page === 0) {
          setNotificationMessages(response.content);
        } else {
          setNotificationMessages((prev) => [...prev, ...response.content]);
        }
        setInfinityScrollData({
          page: response.pageable.pageNumber,
          totalMessages: response.totalElements,
        });
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [requestFilters, setNotificationMessages],
  );

  /**
   * Initial request
   */
  useEffect(() => {
    setIsLoading(true);

    getNotifications(0);
  }, [getNotifications]);

  /**
   * Set active tab with info about default filters
   */
  const onClickTabHandler = useCallback(
    (tab: NOTIFICATION_TABS) => setActiveTab(tab),
    [setActiveTab],
  );

  /**
   * remove selected filters
   */
  const handleRemoveFilter = useCallback(
    (filter: NOTIFICATION_TYPES) => {
      setAppliedFilters((prevState) => ({
        ...prevState,
        cartType: prevState.cartType.filter((item) => item !== filter),
      }));
    },
    [setAppliedFilters],
  );

  return (
    <div className={classes.wrap} data-testid='notifications-modal'>
      <div className={classes.header}>
        <div className={classes.title}>{t('title.label')}</div>
        <div
          className={classes.readAll}
          onClick={() => setIsOpenReadAllModal(true)}
          data-testid='read-all-button'
        >
          <ReadAllIcon className={classes.readAllIcon} />
          {t('read.all.btn.label')}
        </div>
      </div>

      <div className={classes.toolbar} data-testid='toolbar'>
        <div className={classes.tabs}>
          {TABS_DATA(t).map(({ title, value, unreadMessagesField }) => (
            <div
              className={cx(classes.tabItem, { isActive: value === activeTab })}
              key={value}
              onClick={() => onClickTabHandler(value)}
              data-testid={`tab-item-${value}`}
            >
              <div>{t(title)}</div>
              {Boolean(notificationsNotReadCount[unreadMessagesField]) && (
                <div className={classes.tabUnreadMessagesCount} data-testid={`tab-unread-${value}`}>
                  {notificationsNotReadCount[unreadMessagesField] ?? 0 < 100
                    ? notificationsNotReadCount[unreadMessagesField]
                    : '99+'}
                </div>
              )}
            </div>
          ))}
        </div>

        <BadgeCounter
          badgeContent={appliedFiltersCount}
          color='secondary'
          invisible={!appliedFiltersCount}
          classes={{ badge: classes.notificationsBadge }}
          data-testid='badge-counter'
        >
          <div
            className={classes.filterBtn}
            onClick={openFilterModalHandler}
            data-testid='filter-button'
          >
            <FilterIcon />
          </div>
        </BadgeCounter>
      </div>

      <div className={classes.filters} data-testid='filters'>
        {renderFilters.map(({ value, label, disabled }) => (
          <Badge
            type={'outline'}
            key={value}
            handleRemove={() => handleRemoveFilter(value as NOTIFICATION_TYPES)}
            disabled={disabled}
            testId={`filter-badge-${value}`}
          >
            {label}
          </Badge>
        ))}
      </div>

      <div className={classes.list} data-testid='message-list'>
        {isLoading && <Loader isBlock preventClick lightMode width={100} />}

        {!isLoading && !notificationMessages.length && (
          <div className={classes.emptyMessagesWrap} data-testid='empty-message'>
            <div className={classes.emptyTitle}>{t('empty.data.title.label')}</div>
            <div className={classes.emptySubtitle}>{t('empty.data.subtitle.label')}</div>
            <button
              className={classes.emptyBtn}
              onClick={openFilterModalHandler}
              data-testid='filter-button-empty'
            >
              {t('filter.btn.label')}
            </button>
          </div>
        )}

        {!isLoading && Boolean(notificationMessages?.length) && (
          <InfiniteScroll
            className={classes.infiniteScroll}
            dataLength={notificationMessages.length}
            hasMore={notificationMessages.length < infinityScrollData.totalMessages}
            next={() => getNotifications(infinityScrollData.page + 1)}
            loader={<div className={classes.loadingMoreMessage}>Loading...</div>}
            height={'450px'}
            data-testid='infinite-scroll'
          >
            {notificationMessages.map((notification) => {
              return (
                <Message
                  key={notification.id}
                  message={notification.shortMessage}
                  type={notification.type}
                  createdAt={notification.createdAt}
                  isRead={notification.read}
                  driverFullName={`${notification.driverFirstName} ${notification.driverLastName}`}
                  vehiclePlateNumber={notification.carPlateNumber}
                  onClick={() => onClickNotificationHandler(notification)}
                />
              );
            })}
          </InfiniteScroll>
        )}
      </div>

      <Modal open={isOpenFilterModal} onClose={closeFilterModalHandler}>
        <DialogContent>
          <Backdrop onClose={closeFilterModalHandler}>
            <NotificationFilter closeFilterModalHandler={closeFilterModalHandler} />
          </Backdrop>
        </DialogContent>
      </Modal>

      <Modal open={isOpenReadAllModal} onClose={() => setIsOpenReadAllModal(false)}>
        <DialogContent>
          <Backdrop onClose={() => setIsOpenReadAllModal(false)}>
            <ReadAllModal
              setIsOpenReadAllModal={setIsOpenReadAllModal}
              closeNotificationModalHandler={closeNotificationModalHandler}
              getCounter={getCounter}
            />
          </Backdrop>
        </DialogContent>
      </Modal>
    </div>
  );
};

export default NotificationsModal;
