import DialogContent from '@mui/material/DialogContent';
import Grow from '@mui/material/Grow';
import ModalMui, { ModalProps } from '@mui/material/Modal';
import React from 'react';

import { FCC } from 'interfaces/common/fc-with-children';

import useStyles from './styles';

const Modal: FCC<ModalProps> = ({ open, onClose, children }) => {
  const classes = useStyles();

  return (
    <ModalMui className={classes.modal} open={open} onClose={onClose}>
      <Grow in={open} timeout={250}>
        <DialogContent className={classes.dialogContent}>{children}</DialogContent>
      </Grow>
    </ModalMui>
  );
};

export default Modal;
