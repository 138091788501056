import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: '15px 25px',
    marginBottom: 15,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: palette.custom.lightBlue,
    borderRadius: '10px',

    '&:last-child': {
      marginBottom: 0,
    },

    '&.isTechAdmin': {
      cursor: 'pointer',
    },
  },

  info: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  avatar: {
    width: 84,
    height: 84,
    marginRight: 25,
  },

  company: {
    fontFamily: 'SFPro',
    fontWeight: 600,
    fontSize: 21,
    color: palette.custom.linkBlue,
  },

  statistic: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    fontFamily: 'SFPro',
    fontWeight: 400,
    fontSize: 18,
    color: theme.palette.primary.dark,
  },

  field: {
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',

    '&:last-child': {
      marginBottom: 0,
    },
  },

  iconWrap: {
    marginRight: 10,
    width: 24,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
