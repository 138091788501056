import cx from 'classnames';
import React, { FC, useEffect, useRef, useState, MouseEvent, ChangeEvent } from 'react';

import { ReactComponent as ApproveIcon } from 'assets/images/approve.svg';
import { ReactComponent as RejectIcon } from 'assets/images/cross.svg';
import { ReactComponent as PencilIcon } from 'assets/images/pencil.svg';

import useStyles from './styles';

interface IProps {
  initValue: string;
  handlerApprove: (value: string) => void;
  rejectHandler?: () => void;
  disabled?: boolean;
}

const Input: FC<IProps> = ({
  initValue,
  handlerApprove,
  rejectHandler = null,
  disabled = false,
}) => {
  const classes = useStyles();

  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState<string | null>(null);
  const [isDisabled, setIsDisabled] = useState(disabled);

  useEffect(() => {
    if (isDisabled) {
      return;
    }

    inputRef.current?.focus();
  }, [isDisabled]);

  const defaultRejectHandler = () => {
    setIsDisabled(true);
    setValue(null);
  };

  const approveHandler = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    handlerApprove(value ? value : initValue);
    setValue(null);
    inputRef.current?.focus();
  };

  return (
    <div className={classes.wrap}>
      <input
        ref={inputRef}
        className={classes.input}
        onClick={(event) => event.stopPropagation()}
        type='text'
        value={value ?? initValue}
        disabled={isDisabled}
        data-testid='input'
        onInput={(event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
      />

      <div className={classes.controls}>
        {isDisabled && (
          <div
            className={cx(classes.icon, classes.pencilIcon)}
            data-testid='button-edit'
            onClick={(e) => {
              e.stopPropagation();
              setIsDisabled(false);
            }}
          >
            <PencilIcon />
          </div>
        )}

        {!isDisabled && (
          <>
            {value && (
              <div className={classes.icon} onClick={approveHandler} data-testid='button-approve'>
                <ApproveIcon />
              </div>
            )}
            <div
              className={classes.icon}
              data-testid='button-reject'
              onClick={(e) => {
                e.stopPropagation();
                rejectHandler ? rejectHandler() : defaultRejectHandler();
              }}
            >
              <RejectIcon />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Input;
