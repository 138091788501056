import queryString from 'query-string';

import { IResponseWrapper } from 'interfaces/server/response-wrapper';

import server from 'utils/server';

import { ISortParameters } from 'recoil/settings/payment/transactions/sort';
import { ISettingPaymentVehiclesFilters } from 'recoil/settings/payment/vehicles/filters';

export enum TRANSACTION_RESULT {
  SUCCESS = 'SUCCESS',
  PROCESSING = 'PROCESSING',
  REJECTED = 'REJECTED',
  ERROR = 'ERROR',
}

export interface ITransaction {
  provider: string;
  paymentInfo: string;
  transactionId: string;
  balance: {
    companyId: string;
    balance: number;
    dueDate: string; // '2023-12-06'
    overdueDays: number;
  };
  amount: number;
  transactionResult: TRANSACTION_RESULT;
  paymentDate: string; // '2023-12-06T15:10:33.023Z'
  invoiceId: string;
}

export const getCompanyTransactionsReq = async (
  page: number,
  filters: ISettingPaymentVehiclesFilters,
  sort: ISortParameters,
  search: string,
  balanceId: string,
): Promise<IResponseWrapper<ITransaction[]>> => {
  const params = {
    page,
    ...sort,
  };

  const body = {
    ...filters,
    balanceId,
    query: search,
  };

  return server.services.paymentService
    .post<IResponseWrapper<ITransaction[]>>(
      'payments/transactions?' + queryString.stringify(params),
      body,
    )
    .then((res) => res.data);
};
