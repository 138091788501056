import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  loader: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    width: '120px',
    height: '120px',
    zIndex: 100,
    transform: 'translate(-50%, -50%)',

    '& rect': {
      fill: palette.custom.bluePrimary,
    },
  },

  light: {
    '& rect': {
      fill: theme.palette.primary.light,
    },
  },

  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'block',
    width: '100%',
    height: '100%',
    zIndex: 2,
  },

  block: {
    position: 'absolute',
  },
}));

export default useStyles;
