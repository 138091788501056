import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  head: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'flex-start',
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.primary.dark,
    padding: 15,
    height: 45,
  },

  content: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 20,
  },

  searchIcon: {
    width: 24,
    height: 24,
    cursor: 'pointer',

    '& path': {
      fill: theme.palette.primary.dark,
    },
  },
}));

export default useStyles;
