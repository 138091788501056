import { StyledEngineProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker as DatePickerMUI } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import cx from 'classnames';
import React, { FC } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

import { getDeepValue } from 'utils/helpers/get-object-deep-value';

import ErrorMessage from '../error-message';
import FieldLabel from '../field-label';

import useStyles from './styles';

interface IProps {
  name: string;
  title: string;
  subtitle?: string;
  disabled?: boolean;
  wrapClass?: string;
}

const DatePicker: FC<IProps> = ({ name, title, subtitle, disabled = false, wrapClass }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const classes = useStyles();

  const errorData = getDeepValue<FieldError>(errors, name);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ...rest } }) => {
        return (
          <div className={cx(classes.wrap, wrapClass)}>
            <FieldLabel
              htmlFor={rest.name}
              error={!!errorData}
              title={title}
              subtitle={subtitle}
              disabled={disabled}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StyledEngineProvider injectFirst>
                <DatePickerMUI
                  {...rest}
                  value={rest.value ?? null}
                  inputFormat='DD/MM/YYYY'
                  disabled={disabled}
                  className={classes.inputWrap}
                  InputProps={{
                    classes: {
                      root: classes.root,
                      error: classes.inputError,
                      disabled: classes.inputDisabled,
                      input: classes.input,
                      focused: classes.focused,
                    },
                  }}
                  PaperProps={{
                    classes: {
                      root: classes.paper,
                    },
                  }}
                  OpenPickerButtonProps={{
                    classes: {
                      root: classes.icon,
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      data-testid={`date-picker-${name}`}
                      {...params}
                      error={!!errorData}
                    />
                  )}
                />
              </StyledEngineProvider>
            </LocalizationProvider>

            <ErrorMessage message={errorData?.message} />
          </div>
        );
      }}
    />
  );
};
export default DatePicker;
