import Badge from '@mui/material/Badge';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { BUTTON_VARIANTS } from 'interfaces/common/button-variants';

import { ReactComponent as DownloadIcon } from 'assets/images/download.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/settings/arrow.svg';
import { ReactComponent as FilterIcon } from 'assets/images/settings/filter.svg';

import RouteManager from 'utils/services/route-manager';

import Button from 'components/form-v2/button';
import SearchInput from 'components/search';

import useStyles from './styles';

export interface IToolBarButton {
  label: string;
  onClick: () => void;
  variant?: BUTTON_VARIANTS;
  hide?: boolean;
  disabled?: boolean;
}

interface IHeader {
  backBtn: {
    label: string;
    onClick?: (() => void) | null;
  };
  searchHandler?: (data: { query: string }) => void;
  onCancelSearch?: () => void;
  searchValue?: string;
  onClickFilter?: () => void;
  appliedFiltersCount?: number;
  reportHandler?: () => void;
  toolBarBtns?: IToolBarButton[];
}

const Header: FC<IHeader> = ({
  backBtn,
  searchHandler,
  onClickFilter,
  reportHandler,
  searchValue,
  onCancelSearch,
  appliedFiltersCount,
  toolBarBtns,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.wrap} data-testid='header'>
      <div
        className={classes.backBtn}
        onClick={
          backBtn.onClick ? backBtn.onClick : () => navigate(RouteManager.makeURL('settings'))
        }
        data-testid='back-button'
      >
        <ArrowIcon className={classes.backIcon} />
        {backBtn.label}
      </div>

      {searchHandler && onCancelSearch && (
        <SearchInput
          onSubmit={searchHandler}
          onCancel={onCancelSearch}
          searchValue={searchValue}
          wrapClass={classes.search}
        />
      )}

      <div className={classes.toolbarBtns} data-testid='toolbar-buttons'>
        {toolBarBtns?.map(({ label, onClick, variant, hide = false, disabled = false }) => {
          if (hide) {
            return null;
          }

          return (
            <Button
              key={label}
              onClick={onClick}
              variant={variant}
              className={classes.toolbarBtn}
              disabled={disabled}
              testId='toolbar-button'
            >
              {label}
            </Button>
          );
        })}
      </div>

      <div className={classes.btns} data-testid='header-buttons'>
        {onClickFilter && (
          <div className={classes.btn} onClick={onClickFilter} data-testid='filter-button'>
            <Badge
              badgeContent={appliedFiltersCount}
              color='secondary'
              invisible={!appliedFiltersCount}
              classes={{ badge: classes.badge }}
              data-testid='filters-badge'
            >
              <FilterIcon />
            </Badge>
          </div>
        )}

        {reportHandler && (
          <div className={classes.btn} onClick={reportHandler} data-testid='report-button'>
            <DownloadIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
