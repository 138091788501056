import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  label: {
    fontFamily: 'SFPro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '19px',
    color: palette.custom.blue200,
    marginBottom: 5,

    '&.Mui-error': {
      color: theme.palette.primary.light,
    },

    '&.Mui-disabled': {
      color: alpha(palette.custom.blue200, 0.4),
    },

    '&:empty': {
      display: 'none',
    },
  },

  subtitle: {
    marginLeft: 5,
    fontFamily: 'SFPro',
    fontWeight: 400,
    fontSize: 14,
    color: palette.custom.blue800,

    '&:empty': {
      display: 'none',
    },
  },
}));

export default useStyles;
