import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { currencyList } from 'constants/currency';

import {
  getCountryFuelData,
  IFuelPriceFormData,
} from 'requests/fuel-price-settings-controller/get-fuel-prices';
import { setCountryFuelData } from 'requests/fuel-price-settings-controller/save-prices';

import Input from 'components/form-v2/input';
import Select from 'components/form-v2/select';
import Loader from 'components/Loader';
import ModalActions from 'components/modal-actions';

import useStyles from './styles';

interface IPriceModal {
  onClose: () => void;
  country: string | null;
}

const PriceModal: FC<IPriceModal> = ({ onClose, country }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('setting.fuel.page');

  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState<IFuelPriceFormData | null>(null);

  const formMethods = useForm<IFuelPriceFormData>();

  /**
   * Get fuel data
   */
  useEffect(() => {
    if (!country) {
      return;
    }
    const req = async () => {
      try {
        setIsLoading(true);

        const res = await getCountryFuelData(country);
        setFields(res);
        formMethods.reset(res);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    req();
  }, [country, formMethods]);

  const submitHandler = async (data: IFuelPriceFormData) => {
    if (!country) return;

    try {
      setIsLoading(true);
      await setCountryFuelData(country, Object.values(data));
      onClose();
    } catch (e) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <div className={classes.modal} data-testid='price-modal-wrap'>
        {isLoading && <Loader lightMode width={150} isBlock />}

        <div className={classes.modalHeader}>
          <div className={classes.row}>
            <div>{t('fuel.type.label')}</div>
            <div>{t('price.per.litre.label')}</div>
            <div>{t('currency.label')}</div>
          </div>
        </div>

        <div>
          {fields &&
            Object.keys(fields)?.map((key) => {
              return (
                <div className={classes.row} key={key} data-testid='row'>
                  <div data-testid='key'>{key}</div>

                  <Input name={`${key}.price`} type='number' />

                  <Select
                    title={''}
                    name={`${key}.currency`}
                    items={currencyList}
                    disabled={true}
                  />
                </div>
              );
            })}
        </div>
      </div>

      <ModalActions handleSave={formMethods.handleSubmit(submitHandler)} handleClose={onClose} />
    </FormProvider>
  );
};

export default PriceModal;
