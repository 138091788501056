import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: '0 10px',
  },

  rail: {
    backgroundColor: palette.custom.blue800,
    height: 6,
    border: 'none',
    borderRadius: 100,
  },

  track: {
    backgroundColor: palette.custom.goldSecondary,
    height: 6,
    border: 'none',
    borderRadius: 100,
  },

  mark: {
    width: 1,
    height: 4,
    backgroundColor: palette.custom.blue700,
    transform: 'translateY(9px)', // height of track + 5px
  },

  markLabel: {
    fontSize: 14,
    fontWeight: 500,
    color: palette.custom.blue700,
  },

  thumb: {
    width: 20,
    height: 12,
    backgroundColor: palette.custom.goldSecondary,
    border: `1.5px solid ${theme.palette.primary.light}`,
    borderRadius: 100,

    '&:hover': {
      boxShadow: 'none',
    },

    '&.Mui-active': {
      boxShadow: 'none',
    },
  },

  valueLabel: {
    backgroundColor: 'transparent',
    fontSize: 16,
    fontWeight: 500,
    color: palette.custom.blue200,
  },

  disabled: {
    '& .MuiSlider-valueLabel': {
      color: theme.palette.primary.dark,
    },

    '& .MuiSlider-rail': {
      backgroundColor: palette.custom.softBlue,
    },

    '& .MuiSlider-mark': {
      backgroundColor: palette.custom.softBlue,
    },

    '& .MuiSlider-markLabel': {
      color: palette.custom.softBlue,
    },

    '& .MuiSlider-track': {
      backgroundColor: palette.custom.mainLight,
    },

    '& .MuiSlider-thumb': {
      borderColor: palette.custom.blue600,
      backgroundColor: palette.custom.mainLight,
    },
  },
}));

export default useStyles;
