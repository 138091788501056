import { TDataWithId } from 'interfaces/common/data-with-id';
import { TCompany } from 'interfaces/company/company';

import server from 'utils/server';

/**
 * Creates a company request.
 *
 * @async
 * @param {TCompany} data - The data for creating a company.
 * @returns {Promise<TDataWithId<TCompany>>} The created company.
 */
export const createCompanyReq = async (data: TCompany): Promise<TDataWithId<TCompany>> =>
  server.services.beService
    .post<TDataWithId<TCompany>>('companies/create', data)
    .then((res) => res.data);
