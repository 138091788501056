import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import cx from 'classnames';
import { format } from 'date-fns';
import { az, enUS } from 'date-fns/locale';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';

import useTableSort from 'hooks/useTableSort';

import RouteManager from 'utils/services/route-manager';

import settingPaymentCompanyAtom from 'recoil/settings/payment/selected-company/company';
import { SORT_DIRECTION } from 'recoil/settings/payment/transactions/sort';
import {
  isOpenSettingPaymentVehiclesListFilterAtom,
  settingPaymentVehiclesListFilterSelector,
} from 'recoil/settings/payment/vehicles/filters';
import settingPaymentVehiclesListPaginationSelector from 'recoil/settings/payment/vehicles/pagination';
import settingPaymentVehiclesListSearchSelector from 'recoil/settings/payment/vehicles/search';
import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';
import userDataAtom from 'recoil/userData';

import { getSpendingVehiclesReq, ISpendingVehicle } from 'requests/payment/get-company-vehicles';

import Loader from 'components/Loader';
import Modal from 'components/modal';
import PaginationScroll from 'components/pagination-scroll';
import PlateNumber from 'components/plate-number';
import TableSortLabel from 'components/TableSortLabel';
import VehicleIcon from 'components/vehicle-icon';

import { Money } from '../components/money';

import Filters from './filters';
import useStyles from './styles';

const VehiclesSpending = () => {
  const { id, idOfVehicle } = useParams() as { id: string; idOfVehicle?: string };
  const navigate = useNavigate();
  const [vehiclesSort, setVehiclesSort] = useTableSort({ sort: '', direction: SORT_DIRECTION.ASC });
  const companyData = useRecoilValueLoadable(settingPaymentCompanyAtom(id));
  const technicalMessageBlockHeight = useRecoilValue(technicalMessageBlockHeightSelector);
  const classes = useStyles({ technicalMessageBlockHeight });
  const userData = useRecoilValue(userDataAtom);
  const { t } = useTranslation('payment.page');
  const [isOpenFilters, setIsOpenFilters] = useRecoilState(
    isOpenSettingPaymentVehiclesListFilterAtom,
  );
  const filters = useRecoilValue(settingPaymentVehiclesListFilterSelector);
  const [page, setPage] = useRecoilState(settingPaymentVehiclesListPaginationSelector);
  const search = useRecoilValue(settingPaymentVehiclesListSearchSelector);
  const [totalVehicles, setTotalVehicles] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [vehicles, setVehicles] = useState<ISpendingVehicle[]>([]);

  const onClickVehicleRow = (vehicleId: string) => {
    if (idOfVehicle) {
      return;
    }
    navigate(
      RouteManager.makeURL('settings.payment.company.vehicles.vehicle', {
        id,
        idOfVehicle: vehicleId,
      }),
    );
  };

  const getVehicles = useCallback(async () => {
    try {
      if (companyData.state !== 'hasValue') {
        return;
      }
      if (page === 0) {
        setVehicles([]);
      }
      setIsLoading(true);
      const res = await getSpendingVehiclesReq(
        page,
        vehiclesSort,
        filters,
        companyData.contents.balanceId,
        search.query,
        idOfVehicle,
      );
      setTotalVehicles(res.totalElements);
      setVehicles((prev) => [...prev, ...res.content]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, [
    companyData.contents.balanceId,
    companyData.state,
    filters,
    idOfVehicle,
    page,
    search.query,
    vehiclesSort,
  ]);

  useEffect(() => {
    getVehicles();
  }, [getVehicles]);

  return (
    <div>
      <TableContainer className={classes.table}>
        {isLoading && <Loader width={150} isBlock lightMode />}
        <Table stickyHeader data-testid='vehicles-table'>
          <TableHead>
            <TableRow>
              <TableCell align='left' className={classes.headCell}>
                {t('vehicle.label')}
              </TableCell>

              <TableCell align='center' className={classes.headCell}>
                <TableSortLabel
                  onClick={setVehiclesSort}
                  name={'periodStart'}
                  sortField={vehiclesSort.sort}
                  direction={vehiclesSort.direction}
                >
                  {t('vehicle.date.label')}
                </TableSortLabel>
              </TableCell>

              <TableCell align='center' className={classes.headCell}>
                <TableSortLabel
                  onClick={setVehiclesSort}
                  name={'daysUsed'}
                  sortField={vehiclesSort.sort}
                  direction={vehiclesSort.direction}
                >
                  {t('vehicle.days.label')}
                </TableSortLabel>
              </TableCell>

              <TableCell align='center' className={classes.headCell}>
                {t('vehicle.amount.label')}
              </TableCell>

              <TableCell align='center' className={classes.headCell}>
                <TableSortLabel
                  onClick={setVehiclesSort}
                  name={'price'}
                  sortField={vehiclesSort.sort}
                  direction={vehiclesSort.direction}
                >
                  {t('vehicle.price.label')}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody data-testid='vehicles-list'>
            {vehicles.map(
              ({
                vehicleId,
                price,
                amount,
                daysUsed,
                period,
                country,
                plateNumber,
                type,
                modelName,
                brandName,
              }) => {
                return (
                  <TableRow
                    key={`${vehicleId}-${period}`}
                    className={cx(classes.row, idOfVehicle === vehicleId && 'disabled')}
                    onClick={() => onClickVehicleRow(vehicleId)}
                    data-testid={`vehicle-row-${vehicleId}`}
                  >
                    <TableCell align='left' className={classes.cell} data-testid='vehicle-info'>
                      <div className={classes.item}>
                        <PlateNumber
                          country={country}
                          plateNumber={plateNumber}
                          wrapClass={classes.plateNumber}
                        />
                        <VehicleIcon
                          type={type}
                          variant='outline'
                          className={classes.vehicleIcon}
                        />
                        <div data-testid='brand'>{`${brandName} ${modelName}`}</div>
                      </div>
                    </TableCell>
                    <TableCell
                      align='center'
                      className={cx(classes.cell, classes.date)}
                      data-testid='period'
                    >
                      {format(new Date(period), 'dd MMMM yyyy', {
                        locale: userData?.language === 'AZ' ? az : enUS,
                      })}
                    </TableCell>
                    <TableCell align='center' className={classes.cell} data-testid='daysUsed'>
                      {daysUsed}
                    </TableCell>
                    <TableCell align='center' className={classes.cell} data-testid='amount'>
                      {amount ? (
                        <Money
                          count={Number(amount.toFixed(2))}
                          withOutColor
                          className={classes.money}
                        />
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell align='center' className={classes.cell} data-testid='price'>
                      {price ? <Money count={price} withOutColor className={classes.money} /> : '-'}
                    </TableCell>
                  </TableRow>
                );
              },
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationScroll
        count={vehicles.length}
        maxCount={totalVehicles}
        loadMoreHandler={() => setPage((prevState) => prevState + 1)}
      />

      <Modal open={isOpenFilters} onClose={() => setIsOpenFilters(false)}>
        <Filters onClose={() => setIsOpenFilters(false)} />
      </Modal>
    </div>
  );
};

export default VehiclesSpending;
