import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    position: 'relative',
    padding: 5,
    width: 49,
    height: 34,
    borderRadius: 10,
    backgroundColor: alpha(theme.palette.primary.dark, 0.15),
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    transition: 'all ease 0.3s',
    border: '2px solid transparent',

    '&.checked': {
      border: `2px solid ${palette.custom.green400}`,
      backgroundColor: 'transparent',
    },

    '&.disabled': {
      pointerEvents: 'none',
      opacity: 0.4,
    },
  },

  hide: {
    width: 0,
    height: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
  },

  track: {
    width: 15,
    height: 'calc(100% - 5px)',
    borderRadius: 5,
    backgroundColor: palette.custom.blue200,
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06)',
    position: 'absolute',
    left: 5,
    transition: 'all ease 0.3s',

    '&.checked': {
      left: 'calc(100% - 20px)',
      backgroundColor: palette.custom.green400,
    },
  },

  icon: {
    // paddingInline: 8,
    position: 'absolute',
    left: 5,
    transition: 'all ease 0.3s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&.right': {
      position: 'unset',
      left: 'unset',
      opacity: 0.4,
    },

    '&.hide': {
      transform: 'scale(0.01)',
      opacity: 0,
    },
  },
}));

export default useStyles;
