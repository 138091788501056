import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import cx from 'classnames';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { ReactComponent as FilterIcon } from 'assets/images/map-filter.svg';

import dashboardMapLayoutSettingsAtom from 'recoil/dashboard-map/toolbar/layout-setting';

import data, { TBtnKey } from './data';
import useStyles from './styles';

const Settings = () => {
  const classes = useStyles();
  const { t } = useTranslation('dashboard.map.page');
  const [layoutSettings, setLayoutSettings] = useRecoilState(dashboardMapLayoutSettingsAtom);

  const filterRef = useRef(null);

  const filtersCount = layoutSettings.showParams.length;

  /**
   * Toggle filter modal
   * @type {function(): void}
   */
  const filterIconHandler = useCallback(
    () =>
      setLayoutSettings((prev) => ({
        ...prev,
        isOpenSettingsModal: !prev.isOpenSettingsModal,
      })),
    [setLayoutSettings],
  );

  /**
   * Close filter modal
   * @type {function(): void}
   */
  const closeMenu = useCallback(
    () =>
      setLayoutSettings((prev) => ({
        ...prev,
        isOpenSettingsModal: false,
      })),
    [setLayoutSettings],
  );

  /**
   * Toggling filters for showing information about driver and vehicle
   * @type {(function(*): void)|*}
   */
  const viewBtnHandler = useCallback(
    (key: TBtnKey, isSelect: boolean) => {
      if (!isSelect) {
        setLayoutSettings((prevState) => ({
          ...prevState,
          showParams: [...prevState.showParams, key],
        }));
      } else {
        setLayoutSettings((prevState) => ({
          ...prevState,
          showParams: prevState.showParams.filter((item) => item !== key),
        }));
      }
    },
    [setLayoutSettings],
  );

  return (
    <div className={classes.wrap} data-testdid='setting-wrap'>
      <div
        className={cx(classes.btn, {
          isOpenSettingsModal: layoutSettings.isOpenSettingsModal,
        })}
        ref={filterRef}
        onClick={filterIconHandler}
        data-testdid='button-filter'
      >
        {Boolean(filtersCount) && <div className={classes.filtersCount}>{filtersCount}</div>}
        <FilterIcon />
      </div>

      <div
        className={cx(classes.filter, {
          isOpenSettingsModal: layoutSettings.isOpenSettingsModal,
        })}
      >
        {layoutSettings.isOpenSettingsModal && (
          <ClickAwayListener onClickAway={closeMenu}>
            <Paper className={classes.filterModal}>
              <div className={classes.column}>
                {data(t).map(({ label, key }) => {
                  const isSelect = layoutSettings.showParams.includes(key);

                  return (
                    <div
                      className={cx(classes.filterBtn, {
                        isActive: isSelect,
                      })}
                      key={key}
                      onClick={() => viewBtnHandler(key, isSelect)}
                      data-testdid={`button-view-${key}`}
                    >
                      {label}
                    </div>
                  );
                })}
              </div>
            </Paper>
          </ClickAwayListener>
        )}
      </div>
    </div>
  );
};

Settings.propTypes = {};

Settings.defaultProps = {};

export default Settings;
