import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';

import useStyles from './styles';

const InputHome = ({ control, name, required, pattern, label, error, type }) => {
  const classes = useStyles();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=''
      rules={{ required: required === '', pattern: pattern }}
      render={({ field: { onChange, value } }) => (
        <TextField
          InputLabelProps={{
            className: classes.placeholder,
          }}
          InputProps={{
            classes: {
              root: classes.root,
              input: classes.input,
              underline: classes.underline,
            },
          }}
          className={classes.name}
          label={label}
          value={value}
          onChange={onChange}
          error={error}
          type={type}
        />
      )}
    />
  );
};

InputHome.defaultProps = {
  name: '',
  required: false,
  label: '',
  pattern: false,
  type: 'text',
};

InputHome.propTypes = {
  name: PropTypes.string,
  required: PropTypes.any,
  type: PropTypes.any,
  label: PropTypes.any,
  pattern: PropTypes.any,
  control: PropTypes.object.isRequired,
};

export default React.memo(InputHome);
