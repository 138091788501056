import { atom } from 'recoil';

import { ISavingCircleData } from 'interfaces/saving/i-saving-circle-data';

export enum SAVING_CIRCLE_CHART_TYPE {
  TOTAL = 'TOTAL',
  FUEL = 'FUEL',
  WEAR_AND_TEAR = 'WEAR_AND_TEAR',
  MAINTENANCE = 'MAINTENANCE',
  EMISSIONS = 'EMISSIONS',
}

export type ISavingCircleChartsData = {
  [key in SAVING_CIRCLE_CHART_TYPE]: null | ISavingCircleData;
};

const savingCircleChartsAtom = atom<ISavingCircleChartsData>({
  key: 'savingCircleChartsAtom',
  default: {
    [SAVING_CIRCLE_CHART_TYPE.FUEL]: null,
    [SAVING_CIRCLE_CHART_TYPE.WEAR_AND_TEAR]: null,
    [SAVING_CIRCLE_CHART_TYPE.MAINTENANCE]: null,
    [SAVING_CIRCLE_CHART_TYPE.EMISSIONS]: null,
    [SAVING_CIRCLE_CHART_TYPE.TOTAL]: null,
  },
});

export default savingCircleChartsAtom;
