import { IPolygonData } from 'interfaces/common/polygon-data';

import server from 'utils/server';

/**
 * A function that fetches speed polygons data from the server.
 * @async
 * @returns {Promise<IPolygonData[]>} A promise that resolves to an array of speed polygons data.
 */
export const getSpeedPolygonsReq = async (): Promise<IPolygonData[]> =>
  server.services.gpsService.get<IPolygonData[]>('polygons').then((res) => res.data);
