import { yupResolver } from '@hookform/resolvers/yup';
import DialogContent from '@mui/material/DialogContent';
import Grow from '@mui/material/Grow';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import cx from 'classnames';
import i18next from 'i18next';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import fullLogo from 'assets/images/logo.svg';

import server from 'utils/server';
import { userDefaultDomain } from 'utils/server/default-domain';
import RouteManager from 'utils/services/route-manager';

import wasReadTechnicalMessageAtom from 'recoil/technical-message/was-read';
import userDataAtom from 'recoil/userData';

import { loginReq } from 'requests/be-service/login-controller/login';
import { getUserDomainsReq, IUserDomain } from 'requests/router-service/get-user-domains';

import Button from 'components/form-v2/button';
import Input from 'components/form-v2/input';
import Loader from 'components/Loader';

import { domainsData } from './data';
import { formSchema, TFormValues } from './form-schema';
import useStyles from './styles';

function Login() {
  const { t } = useTranslation('login');
  const classes = useStyles();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState<IUserDomain | null>(null);
  const [domainsList, setDomainsList] = useState<IUserDomain[]>([]);
  const [isLoadingDomains, setIsLoadingDomains] = useState(false);
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const setUserData = useSetRecoilState(userDataAtom);
  const resetTechnicalWorksMessage = useResetRecoilState(wasReadTechnicalMessageAtom);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const formMethods = useForm<TFormValues>({
    resolver: yupResolver(formSchema),
  });

  /**
   * Clear data if was logout
   */
  useEffect(() => {
    i18next.changeLanguage(process.env.REACT_APP_DEFAULT_LANG);
    server.setDefaultBaseDomain(userDefaultDomain);
    resetTechnicalWorksMessage();
    setUserData(null);
  }, [resetTechnicalWorksMessage, setUserData]);

  /**
   * Logging to the system after choosing the domain
   * @param values
   */
  const login = async (values: TFormValues) => {
    try {
      setIsLoadingLogin(true);
      await loginReq(values);
      navigate(RouteManager.path('scoring', { hasLeadingSlash: true }));
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingLogin(false);
    }
  };

  /**
   * Get user domains after trying login
   * @param values
   */
  const getUserDomains = async (values: TFormValues) => {
    try {
      setIsLoadingDomains(true);
      const domainsRes = await getUserDomainsReq(values);
      if (domainsRes.envs?.length === 1) {
        server.setDefaultBaseDomain(domainsRes.envs[0].url);
        await loginReq(values);
        navigate(RouteManager.path('scoring', { hasLeadingSlash: true }));
      } else {
        setDomainsList(domainsRes.envs);
        setIsOpenModal(true);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(error.response?.data.error, { variant: 'error' });
      } else {
        console.log(error);
      }
    } finally {
      setIsLoadingDomains(false);
    }
  };

  if (localStorage.getItem('authToken')) {
    return <Navigate to={RouteManager.path('scoring', { hasLeadingSlash: true })} />;
  }

  return (
    <div className={classes.wrap}>
      <img className={classes.logo} src={fullLogo} alt='Logo' />

      <FormProvider {...formMethods}>
        <form className={classes.form} onSubmit={formMethods.handleSubmit(getUserDomains)}>
          <Input
            className={classes.input}
            name='userName'
            title={t('login.username.label')}
            placeholder={t('username.placeholder.label')}
          />
          <Input
            className={classes.input}
            name='password'
            title={t('login.password.label')}
            placeholder={t('password.placeholder.label')}
            type='password'
          />
          <Button type='submit' className={classes.btn} testId={'authBtn'}>
            {isLoadingDomains ? <Loader width={20} preventClick isBlock /> : t('sign.in.button')}
          </Button>
        </form>
      </FormProvider>

      <Modal open={isOpenModal} className={classes.modal}>
        <Grow in={isOpenModal} timeout={250}>
          <DialogContent className={classes.content}>
            <div className={classes.modalContent}>
              <CloseIcon
                data-testid='domainsCloseIcon'
                className={classes.closeIcon}
                onClick={() => setIsOpenModal(false)}
              />
              <div className={classes.title}>{t('choose.region.label')}</div>
              <div className={classes.domains}>
                {domainsList?.map((item) => (
                  <div
                    key={item.name}
                    className={cx(classes.domain, { selected: item.name === selectedDomain?.name })}
                    onClick={() => setSelectedDomain(item)}
                    data-testid='domainItem'
                  >
                    <img src={domainsData[item.name].Icon} alt='icon' />
                    <div className={classes.domainLabel}>{domainsData[item.name].label}</div>
                  </div>
                ))}
              </div>

              <Button
                type='button'
                className={classes.btn}
                testId='domainSubmitBtn'
                disabled={!selectedDomain}
                onClick={() => {
                  if (!selectedDomain) {
                    return;
                  }
                  localStorage.setItem('domain', selectedDomain.url);
                  login(formMethods.getValues());
                }}
              >
                {isLoadingLogin ? <Loader width={20} preventClick isBlock /> : t('sign.in.button')}
              </Button>
            </div>
          </DialogContent>
        </Grow>
      </Modal>
    </div>
  );
}

export default Login;
