import cx from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LayersIcon } from 'assets/images/layers.svg';
import satelliteImg from 'assets/images/satellite-layer.png';
import terrainImg from 'assets/images/terrain-layer.png';

import useStyles from './styles';

interface IProps {
  isSatellite: boolean;
  onClick: () => void;
  wrapClass?: string;
}

const Layer: FC<IProps> = ({ isSatellite, onClick, wrapClass }) => {
  const classes = useStyles();
  const { t } = useTranslation('dashboard.map.page');

  return (
    <div className={cx(classes.container, wrapClass)} onClick={onClick} data-testdid='map-layer'>
      <img className={classes.img} src={isSatellite ? terrainImg : satelliteImg} alt='' />
      <div className={classes.shadow} />
      <LayersIcon />
      <div className={classes.title}>{isSatellite ? t('map.label') : t('terrain.label')}</div>
    </div>
  );
};

export default Layer;
