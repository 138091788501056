import { atom } from 'recoil';

import { IVehiclesListItem } from 'requests/be-service/vehicle-controller/get-vehicles';

const vehiclesListAtom = atom<IVehiclesListItem[]>({
  key: 'vehiclesListAtom',
  default: [],
});

export default vehiclesListAtom;
