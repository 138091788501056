import cx from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { CHART_TABS } from 'interfaces/scoring/chart-tabs';
import { IUserData } from 'interfaces/user/user-data';

import { createRenderData } from 'utils/helpers/scoring/create-annual-chart-render-data';

import annualScoreAtom from 'recoil/scoring/scoringAnnualScore';
import userDataAtom from 'recoil/userData';

import { getDataForGraphForYear } from 'requests/be-service/scoring-controller/get-data-for-graph-for-year';

import Loader from 'components/Loader';

import { formTabsRender, options } from './options';
import useStyles from './styles';

const AnnualScoreChart = () => {
  const classes = useStyles();
  const { t } = useTranslation(['scoring.page', 'saving.page']);
  const { language } = useRecoilValue(userDataAtom) as IUserData;

  const [annualScore, setAnnualScore] = useRecoilState(annualScoreAtom);
  const resetAnnualScore = useResetRecoilState(annualScoreAtom);
  const [loader, setLoader] = useState(false);
  const [formTab, setFormTab] = useState<CHART_TABS>(CHART_TABS.D);

  const handleAnnualScoreReq = useCallback(
    async (item: CHART_TABS = CHART_TABS.D) => {
      setLoader(true);
      try {
        setFormTab(item);

        const response = await getDataForGraphForYear(item);
        const renderData = createRenderData(response, item);
        setAnnualScore(renderData);
      } catch (e) {
        console.log('Annual Score Chart Error');
      } finally {
        setLoader(false);
      }
    },
    [setAnnualScore],
  );

  useEffect(() => {
    const req = async () => handleAnnualScoreReq();
    req();
  }, [handleAnnualScoreReq]);

  useEffect(() => {
    return resetAnnualScore();
  }, [resetAnnualScore]);

  if (loader) {
    return (
      <div className={classes.annual} data-testid='annual-chart'>
        <Loader width={100} lightMode isBlock />
      </div>
    );
  }

  return (
    <div className={classes.annual} data-testid='annual-chart'>
      <>
        {!annualScore && (
          <div className={classes.emptyWrap} data-testid='no-data-label'>
            {t('saving.page:no.data.label')}
          </div>
        )}

        {annualScore && (
          <Chart
            series={[
              { name: t('average.label'), data: annualScore.competitorData },
              { name: t('fleet.label'), data: annualScore.companyData },
            ]}
            options={options(annualScore.categories)}
            type='line'
            height='100%'
            width='100%'
            data-testid='annual-chart-graph'
          />
        )}
      </>

      <div className={classes.buttons} data-testid='tabs-section'>
        {formTabsRender[language].map(({ title, key }, i) => (
          <div
            onClick={() => handleAnnualScoreReq(key)}
            key={i}
            className={cx(classes.button, formTab === key && classes.active)}
            data-testid={`form-tab-button-${key}`}
          >
            {title}
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(AnnualScoreChart);
