import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  marker: {
    transform: 'translate(-50%, -100%)',
    width: 29.45,
    height: 36,
  },

  markerNumber: {
    position: 'relative',
    transform: 'translate(-50%, -100%)',
    width: 29.45,
    height: 36,
  },

  markerActive: {
    transform: 'scale(1.4) translate(-40%, -85%)',
  },

  number: {
    position: 'absolute',
    top: '16%',
    width: 28,
    fontFamily: 'SFText',
    fontSize: '13px',
    lineHeight: '18px',
    color: palette.custom.bluePrimary,
    textAlign: 'center',
  },

  startWrap: {
    paddingRight: 8,
    width: 'fit-content',
    height: 26,
    backgroundColor: palette.custom.white,
    borderRadius: 13,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transform: 'translate(-13px, -13px)',
  },

  startIcon: {
    width: 26,
    height: 26,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: palette.custom.bluePrimary,
    borderRadius: '50%',
    marginRight: 5,

    '& svg': {
      marginLeft: 2,
    },
  },

  startLabel: {
    fontFamily: 'SFPro',
    fontWeight: 400,
    fontSize: 12,
    color: palette.custom.bluePrimary,
  },
}));

export default useStyles;
