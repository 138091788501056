import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

type Props = {
  technicalMessageBlockHeight: number;
};

const useStyles = makeStyles<Theme, Props>(() => ({
  content: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: ({ technicalMessageBlockHeight }) => {
      return `calc(100vh - 48px - ${technicalMessageBlockHeight}px)`;
    },
    display: 'flex',
    flexDirection: 'row-reverse',
    backgroundColor: palette.custom.bluePrimary,
    transition: 'bottom 0.3s ease',
  },

  container: {
    width: 'calc(100% - 220px)',
    height: '100%',
    overflow: 'auto',
    transition: 'width 0.3s',

    '&.full': {
      width: 'calc(100% - 108px)',
    },
  },
}));

export default useStyles;
