import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

type Props = {
  technicalMessageBlockHeight: number;
};

const useStyles = makeStyles<Theme, Props>((theme) => ({
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },

  driverScore: {
    fontSize: 46,
  },

  tableContainer: {
    minHeight: ({ technicalMessageBlockHeight }) =>
      `calc(100vh - 360px - ${technicalMessageBlockHeight}px)`,
    maxHeight: ({ technicalMessageBlockHeight }) =>
      `calc(100vh - 360px - ${technicalMessageBlockHeight}px)`, // 360px it's the height of the page header and page footer
    marginBottom: 45,
  },

  driverWrap: {
    position: 'relative',
    minHeight: 58,
    width: '100%',
  },

  driverInfo: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 40,
  },

  driverName: {
    display: 'flex',
    alignItems: 'flex-start',
    columnGap: 30,
    fontSize: 24,
    fontWeight: 500,
    color: palette.custom.blue200,
  },

  headCell: {
    paddingTop: '25px !important', // TODO: remove scss styles for table
    paddingBottom: '25px !important',
  },

  driverCell: {
    paddingTop: '12px !important', // TODO: remove scss styles for table
    paddingBottom: '12px !important',
    fontSize: 28,
  },

  editHeadCell: {
    paddingTop: '12px !important', // TODO: remove scss styles for table
    paddingBottom: '12px !important',
    width: 70,
    cursor: 'pointer',
  },

  problems: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 10,
    justifyContent: 'center',

    '&:empty::after': {
      content: '"-"',
    },
  },

  loadMoreWrap: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.primary.dark,
    columnGap: 20,
  },

  loadMore: {
    color: palette.custom.blue200,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    columnGap: 5,
  },

  arrowDown: {
    width: 12,

    '& *': {
      stroke: palette.custom.blue200,
    },
  },

  activityStatus: {
    cursor: 'pointer',
  },

  pencil: {
    width: 20,
    cursor: 'pointer',

    '& *': {
      fill: theme.palette.primary.dark,
    },
  },

  dateRow: {
    position: 'sticky',
    top: 50,
    background: palette.custom.bluePrimary,
  },
}));

export default useStyles;
