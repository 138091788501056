import { atom, selector } from 'recoil';

const settingPaymentVehiclesListPaginationAtom = atom<number>({
  key: 'settingPaymentVehiclesListPaginationAtom',
  default: 0,
});

const settingPaymentVehiclesListPaginationSelector = selector<number>({
  key: 'settingPaymentVehiclesListPaginationSelector',
  get: ({ get }) => {
    return get(settingPaymentVehiclesListPaginationAtom);
  },
  set: ({ set }, newValue) => {
    set(settingPaymentVehiclesListPaginationAtom, newValue);
  },
});

export default settingPaymentVehiclesListPaginationSelector;
