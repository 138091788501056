import { selector } from 'recoil';

import { countryMapCenter, countryMapZoom } from 'constants/map';

import userDataAtom from '../userData';

const countryMapDefaultSettingAtom = selector({
  key: 'countryMapDefaultSettingAtom',
  get: async ({ get }) => {
    const userData = get(userDataAtom);
    const country =
      sessionStorage.getItem('subcompanyCountry') ?? userData.localizationConfig.country;
    const center = countryMapCenter[country];
    const zoom = countryMapZoom[country];
    return {
      center,
      zoom,
    };
  },
});

export default countryMapDefaultSettingAtom;
