import { atom } from 'recoil';

export interface IScoringAnnualRenderData {
  categories: (string | null)[];
  companyData: (number | null)[];
  competitorData: (number | null)[];
}

const scoringAnnualScore = atom<IScoringAnnualRenderData | null>({
  key: 'scoringAnnualScoreAtom',
  default: null,
});

export default scoringAnnualScore;
