import React from 'react';
import ReactDOM from 'react-dom';

import './localization';
import './utils/yup-locales';
import Root from 'modules/Root';

import 'assets/scss/fonts.scss';
import 'assets/scss/scroll.scss';
import 'assets/scss/animations.scss';

// TODO: need update to react18 render after fixed google map markers
// eslint-disable-next-line react/no-deprecated
ReactDOM.render(<Root />, document.getElementById('root'));
