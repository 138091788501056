import { selector } from 'recoil';

import { ISelectItemWithCompanyVehicePrice } from 'interfaces/common/select-items';

import sybcompaniesListWithCurrentAtom from '../with-current-company';

const subcompaniesSelectListAtom = selector<ISelectItemWithCompanyVehicePrice[]>({
  key: 'subcompaniesSelectListAtom',
  get: async ({ get }) => {
    const list = get(sybcompaniesListWithCurrentAtom);

    if (!list) return [];

    return list.map((company) => {
      return {
        value: company.id,
        label: company.name,
        vehiclePrice: company.price,
      };
    });
  },
});

export default subcompaniesSelectListAtom;
