import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
  },

  header: {
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 20,
    fontWeight: 500,
    color: palette.custom.blue200,
    position: 'relative',

    '&::after': {
      content: '""',
      opacity: 0.1,
      background: 'linear-gradient(180deg, #000 0%, rgba(35, 45, 70, 0.00) 100%)',
      height: 6,
      position: 'absolute',
      bottom: -6,
      left: 0,
      right: 0,
    },
  },

  content: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 20,
  },

  section: {
    width: '100%',
    padding: 30,
    backgroundColor: palette.custom.backgroundBlock,
  },

  close: {
    width: 12,
    cursor: 'pointer',
  },

  btn: {
    width: 400,
  },
}));

export default useStyles;
