import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import cx from 'classnames';
import { format } from 'date-fns';
import React, { FC } from 'react';

import { ReactComponent as PencilIcon } from 'assets/images/pencil.svg';

import { ISettingDriver } from 'requests/be-service/driver-controller/get-settings-drivers';

import useStyles from './styles';

interface IProps {
  data: ISettingDriver;
  editHandler: (data: ISettingDriver) => void;
}

const DriverRow: FC<IProps> = ({ data, editHandler }) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.row} data-testid={`driver-row-${data.driverId}`}>
      <TableCell
        classes={{ body: cx(classes.cell, 'driver') }}
        align={'left'}
        data-testid='driver-name'
      >
        {`${data.firstName} ${data.lastName}`}
      </TableCell>

      <TableCell classes={{ body: classes.cell }} align={'center'} data-testid='companyName'>
        {data.companyName ?? '-'}
      </TableCell>

      <TableCell classes={{ body: classes.cell }} align={'center'} data-testid='managerName'>
        {data.managerName ?? '-'}
      </TableCell>

      <TableCell classes={{ body: classes.cell }} align={'center'} data-testid='registrationDate'>
        {data.registrationDate ? format(new Date(data.registrationDate), 'dd.MM.yyyy') : '-'}
      </TableCell>

      <TableCell classes={{ body: classes.cell }} align={'center'}>
        <div
          data-testid='button-editHandler'
          onClick={(e) => {
            e.stopPropagation();
            editHandler(data);
          }}
        >
          <PencilIcon className={classes.pencil} />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default DriverRow;
