import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

interface IProps {
  driverPhone: string;
  driverPhonePrefix: string;
  driverSosPhone?: string;
  driverSosPhonePrefix?: string;
}
const MobilesNumbers: FC<IProps> = ({
  driverPhone,
  driverPhonePrefix,
  driverSosPhone,
  driverSosPhonePrefix,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('dashboard.map.page');

  return (
    <div className={classes.mobiles} data-testdid='mobile-numbers-wrap'>
      <div className={classes.item}>
        <div className={classes.label}>{`${t('mobile.number.label')}:`}</div>
        <a
          href={`tel:${driverPhonePrefix}${driverPhone}`}
          className={classes.number}
          data-testdid='driverPhone'
        >
          {driverPhonePrefix} <span>{driverPhone}</span>
        </a>
      </div>

      {driverSosPhone && (
        <div className={classes.item}>
          <div className={classes.label}>{`${t('emergency.number.label')}:`}</div>
          <a
            href={`tel:${driverSosPhonePrefix}${driverSosPhone}`}
            className={classes.number}
            data-testdid='driverSosPhone'
          >
            {driverSosPhonePrefix} <span>{driverSosPhone}</span>
          </a>
        </div>
      )}
    </div>
  );
};

export default MobilesNumbers;
