import { TFunction } from 'react-i18next';

export const useParentSelectItems = (t: TFunction) => {
  return [
    {
      label: t('useParent.item.label'),
      value: 'true',
    },
    {
      label: t('dont.useParent.item.label'),
      value: 'false',
    },
  ];
};
