import queryString from 'query-string';

import { ISettingPaymentCompany } from 'interfaces/company/setting-payment-company';
import { IResponseWrapper } from 'interfaces/server/response-wrapper';

import server from 'utils/server';

import { ISettingPaymentCompaniesListFilter } from 'recoil/settings/payment/companies-list/filters';

export const getPaymentCompaniesListReq = async (
  page: number,
  filters: ISettingPaymentCompaniesListFilter,
  searchValue: string,
): Promise<IResponseWrapper<ISettingPaymentCompany[]>> => {
  const params = {
    page,
    size: 100,
  };

  const body = {
    ...filters,
    companyName: searchValue,
  };

  return server.services.paymentService
    .post<IResponseWrapper<ISettingPaymentCompany[]>>(
      'balance/all?' + queryString.stringify(params),
      body,
    )
    .then((res) => res.data);
};
