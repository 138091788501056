import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 15,
  },

  checkboxWrap: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 10,
    color: palette.custom.blue200,
  },
}));

export default useStyles;
