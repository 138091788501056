import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { VEHICLES_ACTIVE_STATUSES } from 'constants/vehicle';
import { VEHICLE_TYPES } from 'constants/vehicle-types';

import ActivityStatus from 'components/activity-statuses';
import PlateNumber from 'components/plate-number';
import ScoreValue from 'components/score-value';
import VehicleIcon from 'components/vehicle-icon';

import useStyles from './styles';

const VehicleInfo = ({
  handleLink,
  viewType,
  wrapClass,
  status,
  firstName,
  lastName,
  vehicleType,
  country,
  plateNumber,
  brandName,
  modelName,
  scoring,
}) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.wrap, wrapClass)} onClick={handleLink} data-testid='info-item-wrap'>
      <div className={cx(classes.info, viewType)}>
        <ActivityStatus className={classes.status} value={status} />
        <div
          className={cx(classes.driverName, { isAccent: viewType === 'DRIVER' })}
          data-testdid='driverName'
        >
          {`${firstName} ${lastName}`}
        </div>
        {vehicleType && (
          <VehicleIcon className={classes.vehicleIcon} type={vehicleType} variant='outline' />
        )}
        <PlateNumber wrapClass={classes.plateNumber} country={country} plateNumber={plateNumber} />
        <div className={classes.model} data-testdid='vehicleBrand'>{`${brandName ?? ''} ${
          modelName ?? ''
        }`}</div>
      </div>

      <div className={classes.scoring} data-testdid='scoring'>
        <ScoreValue value={scoring} />
      </div>
    </div>
  );
};

VehicleInfo.propTypes = {
  handleLink: PropTypes.func,
  viewType: PropTypes.oneOf(['DRIVER', 'VEHICLE']),
  wrapClass: PropTypes.string,
  status: PropTypes.oneOf([...Object.values(VEHICLES_ACTIVE_STATUSES)]),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  vehicleType: PropTypes.oneOf([...Object.values(VEHICLE_TYPES)]),
  country: PropTypes.string,
  plateNumber: PropTypes.string,
  brandName: PropTypes.string,
  modelName: PropTypes.string,
  scoring: PropTypes.number,
};

VehicleInfo.defaultProps = {
  handleLink: () => null,
  viewType: 'DRIVER',
};

export default VehicleInfo;
