import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,

    '& fieldset': {
      border: 'none',
    },
  },

  input: {
    padding: '8px 0 12px',
  },

  placeholder: {
    fontFamily: 'SFText',
    color: theme.palette.primary.dark,
    fontSize: '18px',
    lineHeight: '21.48px',
    transform: 'translate(0px, 16px) scale(1)',

    '&.Mui-focused': {
      transform: 'translate(0px, -9px) scale(0.75)',
    },

    '&.MuiFormLabel-filled': {
      transform: 'translate(0px, -9px) scale(0.75)',
    },
  },

  underline: {
    '&::before': {
      borderBottom: `1px solid ${theme.palette.primary.dark} !important`,
    },
    '&::after': {
      borderBottom: `2px solid ${theme.palette.primary.main} !important`,
    },
  },

  name: {
    width: '100%',
    maxWidth: '316px',
    marginBottom: '49px',
    borderBottom: `1px solid ${theme.palette.primary.dark} !important`,

    '&:last-child': {
      marginBottom: 0,

      [theme.breakpoints.down('md')]: {
        marginBottom: '49px',
      },
    },

    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
}));

export default useStyles;
