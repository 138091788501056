import { valueConverter } from 'utils/helpers/value-converter';

import { IVehicleFilters } from 'recoil/settings/vehicles/filters/atom';

export const vehicleListItemsFiltersDTO = (
  data: IVehicleFilters,
  isMetricSystem: boolean,
): IVehicleFilters => {
  if (isMetricSystem) {
    return data;
  }
  return {
    ...data,
    startDailyKm: data.startDailyKm && valueConverter.distance.toMetric(data.startDailyKm),
    finishDailyKm: data.finishDailyKm && valueConverter.distance.toMetric(data.finishDailyKm),
  };
};
