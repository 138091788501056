import { atom, selector } from 'recoil';

import settingPaymentTransactionsListPaginationSelector from '../pagination';

interface ISettingPaymentTransactionsListSearch {
  query: string;
}
export const initValues = { query: '' };

const settingPaymentTransactionsListSearchAtom = atom<ISettingPaymentTransactionsListSearch>({
  key: 'settingPaymentTransactionsListSearchAtom',
  default: initValues,
});

const settingPaymentTransactionsListSearchSelector =
  selector<ISettingPaymentTransactionsListSearch>({
    key: 'settingPaymentTransactionsListSearchSelector',
    get: ({ get }) => {
      return get(settingPaymentTransactionsListSearchAtom);
    },
    set: ({ set, reset }, newValue) => {
      reset(settingPaymentTransactionsListPaginationSelector);
      set(settingPaymentTransactionsListSearchAtom, newValue);
    },
  });

export default settingPaymentTransactionsListSearchSelector;
