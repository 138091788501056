import cx from 'classnames';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import { CURRENCY_SYMBOL } from 'interfaces/common/currency-symbol';

import showMaxSpeedMarkerAtom from 'recoil/trip/show-max-speed-marker';
import userConfigAtom from 'recoil/user-config';

import Money from 'components/money';
import StatisticValue from 'components/statistic-value';

import useStyles from './styles';

export interface IProps {
  distance?: number | null;
  maxSpeed?: number | null;
  fuelUsage?: number | null;
  saving?: number | null;
  currencyIcon: CURRENCY_SYMBOL;
}

const Statistics: FC<IProps> = ({ distance, maxSpeed, fuelUsage, saving, currencyIcon }) => {
  const classes = useStyles();
  const { t } = useTranslation(['trip.page', 'measurement.system.page']);
  const [showMaxSpeedMarker, setShowMaxSpeedMarker] = useRecoilState(showMaxSpeedMarkerAtom);

  const { isMetricSystem } = useRecoilValue(userConfigAtom);

  const statisticFields = useMemo(() => {
    return [
      {
        type: 'distance',
        label: t(isMetricSystem ? 'distance.label' : 'distance.mile.label'),
        Item: <StatisticValue value={distance} />,
      },
      {
        type: 'maxSpeed',
        label: t(isMetricSystem ? 'max.speed.label' : 'max.speed.mile.label'),
        Item: <StatisticValue value={maxSpeed} />,
        onClick: () => setShowMaxSpeedMarker((prev) => !prev),
      },
      {
        type: 'usage',
        label: t('usage.label'),
        Item: <StatisticValue value={fuelUsage} />,
      },
      {
        type: 'saving',
        label: t('saving.label'),
        Item: <Money value={saving} currencyIcon={currencyIcon} />,
      },
    ];
  }, [
    currencyIcon,
    distance,
    fuelUsage,
    isMetricSystem,
    maxSpeed,
    saving,
    setShowMaxSpeedMarker,
    t,
  ]);

  return (
    <div className={classes.fields} data-testid='statistic-wrap'>
      {statisticFields.map(({ Item, label, onClick, type }) => {
        return (
          <div
            className={cx(classes.field, type, {
              canClick: onClick,
              isActive: showMaxSpeedMarker && type === 'maxSpeed',
            })}
            key={type}
            onClick={() => {
              onClick?.();
            }}
            data-testid={`statistic-item-${label}`}
          >
            <div className={classes.fieldTitle}>{label}</div>
            <div className={classes.fieldCount}>{Item}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Statistics;
