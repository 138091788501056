import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  header: {
    width: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
    position: 'relative',
  },

  vehicleInfo: {
    gridColumnStart: 2,
    gridColumnEnd: 3,
    display: 'grid',
    gridTemplateColumns: '40px 1fr',
    alignItems: 'center',
    rowGap: 10,
    columnGap: 20,
  },

  plateNumber: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },

  vehicleModel: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.primary.dark,
    maxWidth: 320,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  content: {
    padding: 20,
  },

  form: {
    padding: 30,
    backgroundColor: alpha(theme.palette.primary.dark, 0.08),
    borderRadius: 10,
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: 20,
  },

  title: {
    fontSize: 20,
    fontWeight: 500,
    color: palette.custom.blue200,
  },

  btns: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 10,
  },

  btn: {
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },

  shadow: {
    width: '100%',
    height: 6,
    position: 'absolute',
    left: 0,
    bottom: 0,
    background: 'linear-gradient(180deg, #000000 0%, rgba(35, 45, 70, 0) 100%)',
    opacity: 0.1,
  },

  list: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 20,
  },

  emptyMessage: {
    fontSize: 18,
    fontWeight: 400,
    color: palette.custom.blue200,
    textAlign: 'center',
  },

  save: {
    marginTop: 20,
    padding: '0 30px',
  },

  powerIcon: {
    '& path': {
      stroke: theme.palette.primary.dark,
    },
  },
}));

export default useStyles;
