import { IPointCoordinates } from 'interfaces/map/point';

/**
 * Calculates the direct distance between two points on a map.
 *
 * @param {IPointCoordinates} start - The starting point, containing a latitude and longitude.
 * @param {IPointCoordinates} finish - The finishing point, containing a latitude and longitude.
 * @returns {number} - The direct distance between the two points.
 */
export const getTripDirectDistance = (
  start: IPointCoordinates,
  finish: IPointCoordinates,
): number => {
  const sLat = start.latitude;
  const sLng = start.longitude;
  const fLat = finish.latitude;
  const fLng = finish.longitude;

  return Math.sqrt((fLat - sLat) ** 2 + (fLng - sLng) ** 2);
};
