import queryString from 'query-string';

import { ISettingPaymentCompany } from 'interfaces/company/setting-payment-company';
import { IResponseWrapper } from 'interfaces/server/response-wrapper';

import server from 'utils/server';

import { ISortParameters } from 'recoil/settings/payment/transactions/sort';

export const getSubCompaniesReq = async (
  parentCompanyId: string,
  page: number,
  sortParams: ISortParameters,
  query: string,
): Promise<IResponseWrapper<ISettingPaymentCompany[]>> => {
  const params = {
    page,
    size: 100,
    ...sortParams,
    query,
  };

  return server.services.paymentService
    .get<IResponseWrapper<ISettingPaymentCompany[]>>(
      `balance/${parentCompanyId}/child-companies?` + queryString.stringify(params),
    )
    .then((res) => res.data);
};
