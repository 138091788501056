import { selector } from 'recoil';

import { TCompanyListItem } from 'interfaces/company/list-item';

import { getCompanyChildrenReq } from 'requests/be-service/company-controller/get-child-company-ids';

import userDataAtom from '../../userData';

const subcompaniesReqGetAtom = selector<TCompanyListItem[] | null>({
  key: 'subcompaniesAtom',
  get: async ({ get }) => {
    const companyId = get(userDataAtom)?.companyId;
    if (!companyId) {
      return null;
    }
    return await getCompanyChildrenReq(companyId);
  },
});

export default subcompaniesReqGetAtom;
