import React from 'react';

import { ReactComponent as ConstructionIcon } from 'assets/images/construction.svg';

import useStyles from './styles';

const UnderConstruction = () => {
  const classes = useStyles();

  return (
    <div className={classes.block}>
      <ConstructionIcon />
      <div className={classes.title}>Work in progress</div>
      <div className={classes.text}>we will be rolling out this feature soon!</div>
    </div>
  );
};

export default UnderConstruction;
