import server from 'utils/server';

/**
 * Sends a request to the server to generate a trip report and initiates a file download.
 *
 * @returns {Promise<void>} - A promise representing the completion of the request and file download.
 */
export const tripReportReq = async (tripId: string): Promise<void> => {
  const url = `reports/export/drivers/${tripId}/points`;

  return server.services
    .reportService({ url: url, method: 'post', responseType: 'blob' })
    .then((res) => {
      const disposition = res.headers['content-disposition'];
      const href = URL.createObjectURL(res.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `${disposition.split('Filename=')[1].split('.csv')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};
