import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },

  numberContainer: {
    width: '50px',
    maxWidth: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '6.69px',
    padding: '0 14px',
    background: palette.custom.lightBlue,
    borderRadius: '4px',
    color: theme.palette.primary.main,
    fontSize: '46px',
    fontWeight: 600,
    lineHeight: '55px',

    '&:last-child': {
      marginRight: 0,
    },
  },
}));

export default useStyles;
