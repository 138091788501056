import cx from 'classnames';
import React, { FC } from 'react';

import useStyles from './styles';

interface IProps {
  pointCount: number;
  setActiveCluster: () => void;
  clusterId?: number;
  isActive?: number;
}
const ClusterMarker: FC<IProps> = ({ pointCount, setActiveCluster, clusterId, isActive }) => {
  const classes = useStyles();

  return (
    <div
      className={cx(classes.wrap, { isActive })}
      onClick={(e) => {
        e.stopPropagation();
        setActiveCluster();
      }}
      data-testdid='cluster-marker'
      onDoubleClick={(e) => e.stopPropagation()}
    >
      <div id={`cluster-marker-${clusterId}`} className={cx(classes.count, { isActive })}>
        {pointCount}
      </div>
    </div>
  );
};

export default ClusterMarker;
