import server from 'utils/server';

/**
 * Retrieves the list of company structures from the server.
 *
 * @async
 * @returns {Promise<string[]>} - A promise that resolves to an array of company structures.
 * @throws {Error} - If there was an error retrieving the data from the server.
 */
const companyStructureReq = async (): Promise<string[]> => {
  return server.services.beService.get<string[]>('/companies/structures').then((res) => res.data);
};

export default companyStructureReq;
