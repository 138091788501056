import cx from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { ReactComponent as TechnicalWorksIcon } from 'assets/images/tecnical-works.svg';

import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';
import wasReadTechnicalMessageAtom from 'recoil/technical-message/was-read';

import useStyles from './styles';

const TechnicalWorks: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('system.messages.service');
  const wrapRef = useRef(null);
  const textRef: React.RefObject<HTMLInputElement> = React.createRef();
  const setBlockHeight = useSetRecoilState(technicalMessageBlockHeightSelector);
  const [showFullMessage, setShowFullMessage] = useState(false);
  const [showMoreBtn, setShowMoreBtn] = useState(false);
  const setWasReadTechnicalMessage = useSetRecoilState(wasReadTechnicalMessageAtom);

  useEffect(() => {
    if (textRef.current && textRef.current.clientHeight < textRef.current.scrollHeight) {
      setShowMoreBtn(true);
    }
  }, [textRef]);

  useEffect(() => {
    const elem = wrapRef.current;
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        window.requestAnimationFrame(() => setBlockHeight(entry.contentRect.height));
      }
    });

    if (elem) {
      observer.observe(elem);
    }

    return () => {
      if (elem) {
        observer.unobserve(elem);
      }
    };
  }, [setBlockHeight]);

  return (
    <div className={classes.wrap} ref={wrapRef}>
      <TechnicalWorksIcon />
      <div ref={textRef} className={cx(classes.text, { showFullMessage })}>
        {t('technical.works.notification.label')}
      </div>
      {!showFullMessage && showMoreBtn && (
        <div className={classes.learnMore} onClick={() => setShowFullMessage(true)}>
          Learn more
        </div>
      )}

      <CloseIcon className={classes.close} onClick={() => setWasReadTechnicalMessage(true)} />
    </div>
  );
};

export default TechnicalWorks;
