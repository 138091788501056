import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PENALTY_CARD } from 'interfaces/common/penalty-card';

import { ReactComponent as CrashIcon } from 'assets/images/dashboard-map/crash.svg';
import { ReactComponent as RedCardIcon } from 'assets/images/dashboard-map/red-card.svg';
import { ReactComponent as YellowCardIcon } from 'assets/images/dashboard-map/yellow-card.svg';

import useStyles from './styles';

interface IProps {
  penalties: number;
  penaltyCard?: PENALTY_CARD;
  hasCrash?: boolean;
}
const Penalties: FC<IProps> = ({ penalties, penaltyCard, hasCrash }) => {
  const classes = useStyles();
  const { t } = useTranslation('dashboard.map.page');

  return (
    <div className={classes.wrap} data-testdid='penalties-wrap'>
      <div className={classes.item}>
        <div className={classes.label}>{t('penalties.label')}</div>
        <div className={classes.count}>{penalties ? penalties : 0}</div>
      </div>

      <div className={classes.item}>
        <div className={classes.icons}>
          {penaltyCard === PENALTY_CARD.YELLOW && <YellowCardIcon />}
          {penaltyCard === PENALTY_CARD.RED && <RedCardIcon />}
        </div>
      </div>

      {hasCrash && (
        <div className={classes.item}>
          <div className={classes.label}>{t('collisions.label')}</div>
          <CrashIcon />
        </div>
      )}
    </div>
  );
};

export default Penalties;
