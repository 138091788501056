import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

type Props = {
  technicalMessageBlockHeight: number;
};

const useStyles = makeStyles<Theme, Props>((theme) => ({
  wrap: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  searchInput: {
    width: 400,
    border: `2px solid ${palette.custom.lightBlue}`,
    marginTop: 50,
    backgroundColor: 'transparent',
    marginBottom: 15,
    color: theme.palette.primary.light,
    padding: '10px 15px',
    borderRadius: 5,
    outline: 'none',

    '&::placeholder': {
      color: palette.custom.gray200,
    },
  },

  brandsWrap: {
    width: 400,
    marginTop: 10,
    height: ({ technicalMessageBlockHeight }) =>
      `calc(100vh - 350px - ${technicalMessageBlockHeight}px)`,
  },

  brandList: {
    width: 400,
    height: '100%',
  },

  brandItem: {
    columnGap: 10,
    backgroundColor: palette.custom.lightBlue,
    color: theme.palette.primary.light,
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    borderBottom: `1px solid ${palette.custom.borderDark}`,

    '&.active': {
      backgroundColor: palette.custom.softBlue,
    },
  },

  row: {
    paddingInline: 10,
    display: 'flex',
    alignItems: 'center',
    columnGap: 10,

    '& > div:last-child': {
      flex: 1,
    },
  },

  loaderWrap: {
    position: 'relative',
    minHeight: 200,
  },

  modelsWrap: {
    backgroundColor: palette.custom.mainDark,
  },

  modelsList: {
    maxHeight: 200,
  },
}));

export default useStyles;
