import { atom } from 'recoil';

import { ITotalScoring } from 'requests/be-service/scoring-controller/get-total-scoring';

const scoringChartScore = atom<ITotalScoring | null>({
  key: 'scoringChartScore',
  default: null,
});

export default scoringChartScore;
