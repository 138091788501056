import server from 'utils/server';

export interface ITranslationPagesListItem {
  key: string;
  value: string;
}

/**
 * Retrieves the list of translation pages items from the server.
 * @async
 * @returns {Promise<ITranslationPagesListItem[]>} A promise that resolves to an array of translation pages items.
 */
export const getTranslationPagesLIstReq = async (): Promise<ITranslationPagesListItem[]> =>
  server.services.translationService
    .get<ITranslationPagesListItem[]>('categories')
    .then((res) => res.data);
