import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { StyledEngineProvider } from '@mui/material/styles';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';

import useStyles from './styles';

const OutlinedSelect = ({
  items,
  className,
  label,
  control,
  name,
  defaultValue,
  rules,
  helperText,
  modal,
  isDisabled,
  multiple,
  additionalOnChange,
}) => {
  const classes = useStyles({ helperText });

  return (
    <div className={cx(classes.outlined, className)}>
      <span className={classes.outlinedLabel}>{label}</span>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field }) => (
          <StyledEngineProvider injectFirst>
            <Select
              {...field}
              disabled={isDisabled}
              labelId='select'
              multiple={multiple}
              value={field.value ?? ''}
              classes={{ root: classes.inputRoot, icon: classes.icon, select: classes.select }}
              className={cx(classes.input, modal && classes.inputModal)}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              defaultValue={defaultValue}
              error={Boolean(helperText)}
              onChange={(val) => {
                additionalOnChange(val);
                field.onChange(val);
              }}
            >
              {items.map((item) => (
                <MenuItem key={item.value} value={item.value} disabled={item.disabled}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </StyledEngineProvider>
        )}
      />
      {helperText && (
        <StyledEngineProvider injectFirst>
          <FormHelperText classes={{ root: classes.textError }}>{helperText}</FormHelperText>
        </StyledEngineProvider>
      )}
    </div>
  );
};

OutlinedSelect.defaultProps = {
  items: [],
  className: '',
  label: '',
  defaultValue: '',
  rules: {},
  helperText: '',
  modal: false,
  isDisabled: false,
  multiple: false,
  additionalOnChange: () => null,
};

OutlinedSelect.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.any,
      value: PropTypes.any,
      disabled: PropTypes.bool,
    }),
  ),
  className: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.any,
  rules: PropTypes.object,
  helperText: PropTypes.string,
  control: PropTypes.object.isRequired,
  modal: PropTypes.bool,
  isDisabled: PropTypes.bool,
  multiple: PropTypes.bool,
  additionalOnChange: PropTypes.func,
};

export default React.memo(OutlinedSelect);
