import React, { FC } from 'react';

import { ReactComponent as TripPathIcon } from 'assets/images/trip-path-vertical.svg';

import useStyles from './styles';

interface ITripPath {
  startAddress?: string;
  startDate: string;
  endAddress?: string;
  endDate: string;
}
const TripPath: FC<ITripPath> = ({ startDate, startAddress, endDate, endAddress }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrap} data-testid='trip-path-wrap'>
      <TripPathIcon />
      <div className={classes.info}>
        <div>
          <div className={classes.address} data-testid='startAddress'>
            {startAddress ?? '-'}
          </div>
          <div className={classes.date} data-testid='startDate'>
            {startDate}
          </div>
        </div>
        <div>
          <div className={classes.address} data-testid='endAddress'>
            {endAddress ?? '-'}
          </div>
          <div className={classes.date} data-testid='endDate'>
            {endDate}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripPath;
