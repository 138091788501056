import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

type Props = {
  isLittle: boolean;
};

const useStyles = makeStyles<Theme, Props>((theme) => ({
  wrap: {
    cursor: 'pointer',
    padding: 3,
    width: ({ isLittle }) => (isLittle ? 115 : 143),
    height: ({ isLittle }) => (isLittle ? 26 : 32),
    background: theme.palette.primary.light,
    border: `1px solid ${palette.custom.mainLight}`,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    fontSize: ({ isLittle }) => (isLittle ? 17 : 20),
    color: palette.custom.greyDarker,
  },

  country: {
    padding: 3,
    marginRight: ({ isLittle }) => (isLittle ? 5 : 10),
    width: ({ isLittle }) => (isLittle ? 16 : 19),
    minWidth: ({ isLittle }) => (isLittle ? 16 : 19),
    maxWidth: ({ isLittle }) => (isLittle ? 16 : 19),
    height: '100%',
    backgroundColor: palette.custom.mainLight,
    color: theme.palette.primary.light,
    borderTopLeftRadius: 7,
    borderBottomLeftRadius: 7,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: ({ isLittle }) => (isLittle ? 8 : 10),
    textTransform: 'uppercase',
  },

  plateNumber: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

export default useStyles;
