import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  header: {
    width: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
    position: 'relative',
  },

  companyName: {
    fontSize: 20,
    fontWeight: 500,
    color: palette.custom.blue200,
    maxWidth: 410,
  },

  btns: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 20,
  },

  btn: {
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },

  shadow: {
    width: '100%',
    height: 6,
    position: 'absolute',
    left: 0,
    bottom: 0,
    background: 'linear-gradient(180deg, #000000 0%, rgba(35, 45, 70, 0) 100%)',
    opacity: 0.1,
  },

  list: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 20,
  },

  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '18px 15px',
    backgroundColor: alpha(theme.palette.primary.dark, 0.08),
    borderRadius: 10,
    fontSize: 20,
    fontWeight: 400,
    columnGap: 10,

    '&:last-child': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },

  label: {
    color: theme.palette.primary.dark,
  },

  value: {
    color: palette.custom.blue200,
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '&.description': {
      maxWidth: 430,
      whiteSpace: 'unset',
      marginTop: 15,
    },
  },

  closeBtn: {
    width: 400,
  },

  row: {
    display: 'flex',
  },

  copyIcon: {
    marginLeft: 5,
    fill: palette.custom.blue200,
    cursor: 'pointer',
  },

  checlIcon: {
    marginLeft: 5,
    fill: palette.custom.green400,
  },
}));

export default useStyles;
