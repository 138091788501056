import { FC } from 'react';

import { isEmptyValue } from 'utils/helpers/is-empty-value';

interface IProps {
  value?: number | null;
}
const StatisticValue: FC<IProps> = ({ value }) => {
  return <span>{!isEmptyValue(value) ? value?.toFixed(2) : '-'}</span>;
};

export default StatisticValue;
