import { useState } from 'react';

import { ISortParameters } from 'interfaces/common/sort';

import { SORT_DIRECTION } from '../recoil/settings/payment/transactions/sort';

const useTableSort = (defaultValues: ISortParameters) => {
  const [sort, setSort] = useState(defaultValues || { sort: '', direction: SORT_DIRECTION.ASC });

  const handleSort = (field: string): ISortParameters => {
    const isAsc = sort.sort === field && sort.direction === SORT_DIRECTION.ASC;
    const newValue = { sort: field, direction: isAsc ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC };
    setSort(newValue);
    return newValue;
  };

  return [sort, handleSort] as const;
};

export default useTableSort;
