import { alpha, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

interface IProps {
  technicalMessageBlockHeight: number;
}

const useStyles = makeStyles<Theme, IProps>((theme) => ({
  table: {
    height: ({ technicalMessageBlockHeight }) =>
      `calc(100vh - 360px - ${technicalMessageBlockHeight}px)`,
    margin: '40px 50px',
    maxWidth: 'calc(100% - 100px)',
  },

  headCell: {
    paddingBottom: '20px !important',

    '&:first-child': {
      paddingLeft: '15px !important',
    },

    '&:last-child': {
      paddingRight: '15px !important',
    },
  },

  row: {
    transition: 'all 0.3s ease',
    borderRadius: 10,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.08),
    },

    '&.disabled': {
      cursor: 'default',
      pointerEvents: 'none',
    },
  },

  cell: {
    fontSize: 20,
    fontWeight: 500,
    color: palette.custom.blue200,

    '&:first-child': {
      paddingLeft: '15px !important',
    },

    '&:last-child': {
      paddingRight: '15px !important',
    },
  },

  date: {
    color: `${theme.palette.primary.dark} !important`,
  },

  item: {
    display: 'grid',
    gridTemplateColumns: '40px 1fr',
    columnGap: 10,
    rowGap: 12,
  },

  plateNumber: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },

  money: {
    fontSize: 20,
  },
}));

export default useStyles;
