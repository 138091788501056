import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ReactComponent as DeltaIcon } from 'assets/images/delta.svg';

import { numberLimit } from 'utils/helpers/number-limit';

import useStyles from './styles';

const Delta = ({ deltaValue }) => {
  const classes = useStyles();

  return (
    <div className={classes.delta}>
      <div className={cx(classes.item, deltaValue >= 0 && classes.reversed)}>
        <span className={classes.itemValue}>{numberLimit(Math.abs(deltaValue))}</span>
        <DeltaIcon />
      </div>
    </div>
  );
};

Delta.defaultProps = {
  deltaValue: 0,
};

Delta.propTypes = {
  deltaValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default React.memo(Delta);
