import endOfDay from 'date-fns/endOfDay';
import startOfMonth from 'date-fns/startOfMonth';
import { atom, selector } from 'recoil';

import settingPaymentVehiclesListPaginationSelector from '../pagination';

export interface ISettingPaymentVehiclesFilters {
  startDate: Date | null;
  endDate: Date | null;
}

export const initValues = {
  startDate: startOfMonth(new Date()),
  endDate: endOfDay(new Date()),
};

const settingPaymentVehiclesListFilterAtom = atom<ISettingPaymentVehiclesFilters>({
  key: 'settingPaymentVehiclesListFilterAtom',
  default: initValues,
});

export const settingPaymentVehiclesListFilterSelector = selector<ISettingPaymentVehiclesFilters>({
  key: 'settingPaymentVehiclesListFilterSelector',
  get: ({ get }) => {
    return get(settingPaymentVehiclesListFilterAtom);
  },
  set: ({ set, reset }, newValue) => {
    reset(settingPaymentVehiclesListPaginationSelector);
    set(settingPaymentVehiclesListFilterAtom, newValue);
  },
});

export const settingPaymentVehiclesListFilterCountSelector = selector<number>({
  key: 'settingPaymentVehiclesListFilterCountSelector',
  get: ({ get }) => {
    const filters = get(settingPaymentVehiclesListFilterAtom);

    return Object.values(filters).filter((item) => item).length;
  },
});

export const isOpenSettingPaymentVehiclesListFilterAtom = atom<boolean>({
  key: 'isOpenSettingPaymentVehiclesListFilterAtom',
  default: false,
});
