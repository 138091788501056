import { ISuccessBaseResponse } from 'interfaces/common/success-base-response';

import server from 'utils/server';

/**
 * Deletes a team from the server.
 *
 * @param {string} teamId - The ID of the team to delete.
 * @returns {Promise<ISuccessBaseResponse>} A promise that resolves with the response data.
 */
export const deleteTeamReq = async (teamId: string): Promise<ISuccessBaseResponse> =>
  server.services.beService.delete<ISuccessBaseResponse>(`teams/${teamId}`).then((res) => {
    console.log(res.data);
    return res.data;
  });
