import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },

  line: {
    minWidth: 10,
    height: 1,
    backgroundColor: palette.custom.mainLight,
  },

  icon: {
    marginInline: '5px 10px',
    minWidth: 31,
    width: 31,
    height: 31,
    borderRadius: 5,
    backgroundColor: palette.custom.lightBlue,
    backgroundSize: 'cover',
  },

  searchIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& path': {
      fill: palette.custom.blue600,
    },
  },

  name: {
    fontFamily: 'SFPro',
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: '-0.5px',
    color: palette.custom.mainLight,
    textOverflow: 'ellipsis',
    maxWidth: 100,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

export default useStyles;
