import server from 'utils/server';

import { ICrashManagementData } from './get-management-data';

/**
 * Sends crash management data to the server
 *
 * @param {string} crashId - The ID of the crash
 * @param {ICrashManagementData} body - The crash management data to send
 * @returns {Promise<ICrashManagementData>} - A promise that resolves with the response data
 */
export const sendCrashDataReq = async (
  crashId: string,
  body: ICrashManagementData,
): Promise<ICrashManagementData> =>
  server.services.gpsService
    .post<ICrashManagementData>(`crashes/${crashId}/management-data`, body)
    .then((res) => res.data);
