import queryString from 'query-string';

import { TNotificationNotRead } from 'interfaces/notifications/notification-types';

import server from 'utils/server';

export const notificationsCounterReq = async () => {
  const params = {
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
  };

  return server.services.notificationService
    .get<TNotificationNotRead>('notifications/count/not-read?' + queryString.stringify(params))
    .then((res) => res.data);
};
