/**
 * Converts a local timestamp to UTC timestamp.
 *
 * @param {string | number | Date} localTimestamp - The local timestamp to be converted.
 * @returns {number | undefined} - The converted UTC timestamp, or undefined if the localTimestamp is falsy.
 */
export const convertToUtcTimestamp = (localTimestamp: string | number | Date) => {
  if (!localTimestamp) {
    return;
  }
  const date = new Date(localTimestamp);

  return Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds(),
  );
};
