import { TFunction } from 'react-i18next';

import { TABLE_VIEW_TYPE } from 'interfaces/common/table-view-type';

const getColumnWidth = (widthInPX: number) => {
  const tableWidth = 1230;

  return `${(widthInPX / tableWidth) * 100}%`;
};

export const TABLE_HEAD_COLUMNS = (t: TFunction, isMetricSystem: boolean) =>
  ({
    [TABLE_VIEW_TYPE.INFO]: [
      {
        id: 'firstName',
        label: t('drivers.table.header.label'),
        styles: { minWidth: 135, width: getColumnWidth(400) },
        align: 'left',
        isSort: true,
      },
      {
        id: 'currentScoring',
        label: t('score.table.header.label'),
        styles: { minWidth: 135, width: getColumnWidth(135) },
        align: 'center',
        isSort: true,
      },
      {
        id: 'penalties',
        label: t('penalties.table.header.label'),
        styles: { minWidth: 220, width: getColumnWidth(220) },
        align: 'center',
        isSort: false,
      },
      {
        id: 'dailyKm',
        label: t(`daily.${isMetricSystem ? 'km' : 'mile'}.header.label`),
        styles: { minWidth: 135, width: getColumnWidth(135) },
        align: 'center',
        isSort: true,
      },
      {
        id: 'dailyH',
        label: t('daily.hours.header.label'),
        styles: { minWidth: 135, width: getColumnWidth(135) },
        align: 'center',
        isSort: true,
      },
      {
        id: 'idling',
        label: t('idle.header.label'),
        styles: { minWidth: 135, width: getColumnWidth(135) },
        align: 'center',
        isSort: true,
      },
    ],
    [TABLE_VIEW_TYPE.STATISTIC]: [
      {
        id: 'firstName',
        label: t('drivers.table.header.label'),
        styles: { minWidth: 135, width: getColumnWidth(400) },
        align: 'left',
        isSort: true,
      },
      {
        id: 'currentScoring',
        label: t('score.table.header.label'),
        styles: { minWidth: 135, width: getColumnWidth(152) },
        align: 'center',
        isSort: true,
      },
      {
        id: 'dailyKm',
        label: t(`daily.${isMetricSystem ? 'km' : 'mile'}.header.label`),
        styles: { minWidth: 135, width: getColumnWidth(152) },
        align: 'center',
        isSort: true,
      },
      {
        id: 'dailyH',
        label: t('daily.hours.header.label'),
        styles: { minWidth: 135, width: getColumnWidth(152) },
        align: 'center',
        isSort: true,
      },
      {
        id: 'maxTimeBetweenTripsBySecond',
        label: t('maxTimeBetweenTrips.header.label'),
        styles: { minWidth: 135, width: getColumnWidth(152) },
        align: 'center',
        isSort: true,
      },
      {
        id: 'fuelUsed',
        label: t(`fuelUsed${isMetricSystem ? '' : '.gal'}.header.label`),
        styles: { minWidth: 135, width: getColumnWidth(152) },
        align: 'center',
        isSort: true,
      },
    ],
  }) as const;
