import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  parameterWrap: {
    position: 'relative',
    width: '100%',
    backgroundColor: palette.custom.hoverBlue,
    borderRadius: 10,
    padding: 15,
  },

  parameterHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 15,
  },

  icon: {
    '&.isGood': {
      '& path': {
        stroke: palette.custom.green400,
      },

      '& circle': {
        fill: palette.custom.green400,
      },
    },

    '&.isBad': {
      '& path': {
        stroke: palette.custom.red900,
      },

      '& circle': {
        fill: palette.custom.red900,
      },
    },
  },

  degrees: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '24px',

    '&.isGood': {
      color: palette.custom.green400,
    },

    '&.isBad': {
      color: palette.custom.red900,
    },
  },

  noData: {
    fontSize: 24,
    color: palette.custom.blue200,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  date: {
    marginLeft: 'auto',
    fontSize: 14,
    fontWeight: 500,
    color: palette.custom.blue600,
  },
}));

export default useStyles;
