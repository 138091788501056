import { alpha, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

type Props = {
  technicalMessageBlockHeight: number;
};

const useStyles = makeStyles<Theme, Props>((theme) => ({
  container: {
    width: '100%',
    height: ({ technicalMessageBlockHeight }) => `calc(100vh - ${technicalMessageBlockHeight}px)`,
    position: 'relative',
  },

  geoFencing: {
    cursor: 'pointer',
    position: 'absolute',
    bottom: 30,
    right: 120,
    background: 'rgba(29, 39, 62, 0.8)',
    border: '1px solid #000',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,

    '&.isSatellite': {
      background: 'rgba(255, 255, 255, 0.8)',
    },
  },

  layerBtn: {
    zIndex: 10,
    cursor: 'pointer',
    position: 'absolute',
    bottom: 30,
    right: 60,
  },

  info: {
    minWidth: 760,
    padding: 15,
    backgroundColor: palette.custom.bluePrimary,
    boxShadow: '0px 2px 13px rgba(0, 0, 0, 0.18)',
    borderRadius: 20,
    position: 'absolute',
    bottom: 10,
    left: '50%',
    transform: 'translateX(calc(-50%))',
  },

  widgets: {
    minHeight: 100,
    marginTop: 30,
    display: 'flex',
    alignItems: 'stretch',
    columnGap: 15,
    position: 'relative',
    paddingBottom: 30,
  },

  modal: {
    width: '100vw',
    height: '100vh',
    backgroundColor: alpha(palette.custom.black, 0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 100,
  },

  modalContent: {
    backgroundColor: palette.custom.bluePrimary,
    borderRadius: 20,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  modalTitle: {
    fontWeight: 600,
    fontSize: 20,
    color: theme.palette.primary.light,
  },

  controls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: 10,
    marginTop: 50,
  },

  rejectBtn: {
    width: 203,
    height: 55,
    border: `1px solid ${palette.custom.goldSecondary}`,
    borderRadius: 10,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    fontWeight: 500,
    fontSize: 16,
    color: palette.custom.goldSecondary,
    cursor: 'pointer',
  },

  approveBtn: {
    width: 203,
    height: 55,
    border: 'none',
    borderRadius: 10,
    backgroundColor: palette.custom.goldSecondary,
    boxShadow: 'none',
    fontWeight: 500,
    fontSize: 16,
    color: palette.custom.bluePrimary,
    cursor: 'pointer',
  },

  sensors: {
    position: 'absolute',
    top: -20,
    right: 0,
    transform: 'translateY(-100%)',
    display: 'flex',
    alignItems: 'flex-end',

    '&.isFull': {
      transform: 'translateY(-100%) translateX(-20%)',
      right: 'unset',
    },
  },

  sensorWrap: {
    marginRight: 5,

    '&:last-child': {
      marginRight: 0,
    },
  },

  deviceInfo: {
    position: 'absolute',
    bottom: 5,
    left: 5,
    fontSize: 14,
    fontWeight: 500,
    color: palette.custom.blue500,
  },
}));

export default useStyles;
