import Drawer from '@mui/material/Drawer';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { ReactComponent as ArrowIcon } from 'assets/images/arrow-simple.svg';

import dashboardSidebarIsAllSelectedAtom from 'recoil/dashboard-map/sidebar/is-all-selected';
import isOpenDashboardSidebarAtom from 'recoil/dashboard-map/sidebar/is-open';
import dashboardSidebarListTypeAtom from 'recoil/dashboard-map/sidebar/list-type';
import { SIDEBAR_TYPES } from 'recoil/dashboard-map/sidebar/list-type/atom';
import dashboardSidebarSelectedItemsAtom from 'recoil/dashboard-map/sidebar/selected-items';
import dashboardMapVehiclesAtom from 'recoil/dashboard-map/vehicles';
import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';

import Button from 'components/form-v2/button';
import Loader from 'components/Loader';

import Header from './header';
import useStyles from './styles';
import TeamsList from './teams-list';
import VehicleList from './vehicle-list';

const Sidebar = ({ onClose, clearMapZoomSettings }) => {
  const technicalMessageBlockHeight = useRecoilValue(technicalMessageBlockHeightSelector);
  const classes = useStyles({ technicalMessageBlockHeight });
  const { t } = useTranslation('dashboard.map.page');

  const [isOpenDashboardSidebar, setIsOpenDashboardSidebar] = useRecoilState(
    isOpenDashboardSidebarAtom,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const listType = useRecoilValue(dashboardSidebarListTypeAtom);
  const [isSelectAll, setIsSelectAll] = useRecoilState(dashboardSidebarIsAllSelectedAtom);
  const [selectedItems, setSelectedItems] = useRecoilState(dashboardSidebarSelectedItemsAtom);
  const setVehiclesData = useSetRecoilState(dashboardMapVehiclesAtom);

  const { handleSubmit, reset, getValues, setValue, watch } = useForm({
    defaultValues: {
      isSelectAll,
      selectedItems,
    },
  });

  /**
   * Clear form data when close sidebar
   * @type {(function(): void)|*}
   */
  const closeHandler = useCallback(() => {
    setIsOpenDashboardSidebar(false);
    reset();
    onClose();
    setIsDirty(false);
  }, [onClose, reset, setIsOpenDashboardSidebar]);

  /**
   * Set selected vehicles to form state
   * @type {(function(*, *): void)|*}
   */
  const vehicleCheckboxHandler = useCallback(
    (id, isSelected) => {
      const prevSelectedItems = getValues('selectedItems');
      setIsDirty(true);
      if (!isSelected) {
        setValue('selectedItems', [...prevSelectedItems, id], { shouldDirty: false });
        return;
      }
      setValue(
        'selectedItems',
        prevSelectedItems.filter((item) => item !== id),
        { shouldDirty: false },
      );
    },
    [getValues, setValue],
  );

  /**
   * Set sidebar settings to state
   * @type {(function(*): void)|*}
   */
  const onSubmit = useCallback(
    (data) => {
      clearMapZoomSettings();
      setIsSelectAll(data.isSelectAll);
      setSelectedItems(data.selectedItems);
      setVehiclesData((prevState) => ({
        ...prevState,
        data: null,
      }));
      closeHandler();
    },
    [clearMapZoomSettings, closeHandler, setIsSelectAll, setSelectedItems, setVehiclesData],
  );

  /**
   * Set initial data for form
   */
  useEffect(() => {
    if (isOpenDashboardSidebar) {
      reset({
        isSelectAll,
        selectedItems,
      });
    }
  }, [isOpenDashboardSidebar, isSelectAll, reset, selectedItems]);

  return (
    <>
      <Drawer
        anchor='right'
        open={isOpenDashboardSidebar}
        transitionDuration={300}
        onClose={closeHandler}
        classes={{
          paper: classes.modal,
        }}
      >
        <div className={classes.wrap} data-testdid='sidebar-wrap'>
          <div className={cx(classes.btn)} onClick={closeHandler} data-testdid='button-close'>
            <ArrowIcon className={classes.arrow} />
          </div>
          <div className={classes.content}>
            <Header setIsDirty={setIsDirty} setValue={setValue} setIsLoading={setIsLoading} />

            {isLoading && <Loader isBlock width={200} lightMode />}

            {[SIDEBAR_TYPES.DEFAULT, SIDEBAR_TYPES.SEARCH].includes(listType) && (
              <VehicleList
                vehicleCheckboxHandler={vehicleCheckboxHandler}
                watch={watch}
                height={'calc(100vh - 115px)'}
              />
            )}

            {listType === SIDEBAR_TYPES.TEAMS && (
              <TeamsList vehicleCheckboxHandler={vehicleCheckboxHandler} watch={watch} />
            )}

            {isDirty && (
              <Button
                className={classes.updateBtn}
                variant='fill'
                type='button'
                onClick={handleSubmit(onSubmit)}
                testId='button-update'
              >
                {t('sidebar.update.btn.label')}
              </Button>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
};

Sidebar.propTypes = {
  onClose: PropTypes.func.isRequired,
};

Sidebar.defaultProps = {};

export default Sidebar;
