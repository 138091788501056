import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '48px 16px',

    '& .MuiPagination-ul': {
      '& .MuiPaginationItem-root': {
        padding: '5px 18px',
        color: theme.palette.primary.light,
        fontWeight: 300,
        fontSize: 20,
        maxHeight: '30px',

        '&. MuiPaginationItem-rounded': {
          borderRadius: '10px',
        },

        '&.Mui-selected': {
          backgroundColor: palette.custom.lightBlue,
        },

        '& .MuiTouchRipple-root': {
          padding: 'initial',
        },
      },

      '& .MuiPaginationItem-icon': {
        fontSize: 24,
      },
    },
  },
  results: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '19px',
    fontWeight: 300,
    fontSize: 20,
    lineHeight: '24px',
    color: theme.palette.primary.light,
    opacity: 0.5,
  },
}));

export default useStyles;
