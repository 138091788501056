import React, { FC } from 'react';
import { useResetRecoilState } from 'recoil';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { ReactComponent as DownloadIcon } from 'assets/images/download.svg';
import { ReactComponent as MailIcon } from 'assets/images/mail.svg';

import crashModalAtom from 'recoil/crash-modal';

import useStyles from './styles';

interface IProps {
  showOnlyCloseIcon?: boolean;
}

const Controls: FC<IProps> = ({ showOnlyCloseIcon = false }) => {
  const classes = useStyles();

  const resetCrashModalRecoilState = useResetRecoilState(crashModalAtom);

  return (
    <div className={classes.wrapper}>
      {!showOnlyCloseIcon && (
        <>
          <DownloadIcon className={classes.icon} />
          <MailIcon className={classes.icon} />
        </>
      )}
      <CloseIcon className={classes.icon} onClick={resetCrashModalRecoilState} />
    </div>
  );
};

export default React.memo(Controls);
