import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { ReactComponent as SuccessIcon } from 'assets/images/demo-success.svg';
import { ReactComponent as InIcon } from 'assets/images/in.svg';
import { ReactComponent as InstaIcon } from 'assets/images/insta.svg';
import { ReactComponent as MetaIcon } from 'assets/images/meta.svg';
import { ReactComponent as ZamanIcon } from 'assets/images/zaman.svg';

import RouteManager from 'utils/services/route-manager';

import Form from './Form';
import Header from './Header';
import Main from './Main';
import Slider from './Slider';
import useStyles from './styles';

const Home = () => {
  const classes = useStyles();
  const [shouldViewSuccess, setShouldViewSuccess] = useState(false);
  const { t } = useTranslation('home.page');

  const inform = [
    { name: 'terms.label', value: '' },
    {
      name: 'privacy.label',
      value:
        'https://fleet-management-system.s3.eu-central-1.amazonaws.com/docs/geekbro-privacy-policy.pdf',
    },
    { name: 'cookies.label', value: '' },
    { name: 'careers.label', value: '' },
  ];

  const handleOpenBlank = (linkBlank) => {
    if (!linkBlank) {
      return;
    }
    window.open(linkBlank, '_blank');
  };

  if (localStorage.getItem('authToken')) {
    return <Navigate to={RouteManager.path('scoring')} />;
  }

  return (
    <>
      <Header />
      <Main />
      <Slider />
      <div>
        <div id='get-in-touch-anchor' className={classes.logo}>
          <div>{t('formGetInTouch.label')}</div>
        </div>
        {!shouldViewSuccess && <Form setShouldViewSuccess={setShouldViewSuccess} />}

        {shouldViewSuccess && (
          <div className={classes.success}>
            <SuccessIcon className={classes.iconSuccess} />
            <p className={classes.title}>{t('gotYou.label')}</p>
            <p className={classes.subtitle}>{t('ourTeam.label')}</p>
          </div>
        )}
      </div>
      <div className={classes.footer}>
        <div className={classes.inform}>
          <div className={classes.inform}>
            {inform.map(({ name, value }) => (
              <p onClick={() => handleOpenBlank(value)} key={name}>
                {t(name)}
              </p>
            ))}
          </div>
        </div>
        <ZamanIcon className={classes.logoIcon} />
        <div className={classes.socialNetworks}>
          <MetaIcon className={classes.social} />
          <InIcon className={classes.social} />
          <InstaIcon className={classes.social} />
        </div>
      </div>
    </>
  );
};

export default Home;
