import { atom } from 'recoil';

import { ITripWithDateLabel } from 'interfaces/map/i-trip';

const driverTripsListAtom = atom<ITripWithDateLabel[]>({
  key: 'driverTripsListAtom',
  default: [],
});

export default driverTripsListAtom;
