import cx from 'classnames';
import React from 'react';
import { useRecoilState } from 'recoil';

import { ReactComponent as BusIcon } from 'assets/images/fnol/bus.svg';
import { ReactComponent as MotorcycleIcon } from 'assets/images/fnol/motorcycle.svg';
import { ReactComponent as SedanIcon } from 'assets/images/fnol/sedan.svg';
import { ReactComponent as TractorIcon } from 'assets/images/fnol/tractor.svg';
import { ReactComponent as TruckIcon } from 'assets/images/fnol/truck.svg';

import crashModalAtom from 'recoil/crash-modal';

import useStyles from './styles';

const CarIcon = ({ type }) => {
  switch (type) {
    case 'SEDAN':
      return <SedanIcon />;

    case 'TRUCK':
      return <TruckIcon />;

    case 'MOTORCYCLE':
      return <MotorcycleIcon />;

    case 'TRACTOR':
      return <TractorIcon />;

    case 'BUS':
      return <BusIcon />;

    default:
      return null;
  }
};

const Car = () => {
  const [
    {
      crashData: { carDamage },
      driverData,
    },
  ] = useRecoilState(crashModalAtom);

  const classes = useStyles({ carDamage });

  return (
    <div className={classes.wrap}>
      <CarIcon type={driverData?.vehicle?.vehicleType} />

      <div className={classes.damageWrap}>
        {Boolean(carDamage.right) && (
          <div className={cx(classes.damageCount, 'right')}>{`${carDamage.right}%`}</div>
        )}

        {Boolean(carDamage.back) && (
          <div className={cx(classes.damageCount, 'back')}>{`${carDamage.back}%`}</div>
        )}

        {Boolean(carDamage.left) && (
          <div className={cx(classes.damageCount, 'left')}>{`${carDamage.left}%`}</div>
        )}

        {Boolean(carDamage.front) && (
          <div className={cx(classes.damageCount, 'front')}>{`${carDamage.front}%`}</div>
        )}
      </div>
    </div>
  );
};

Car.defaultProps = {};

Car.propTypes = {};

export default React.memo(Car);
