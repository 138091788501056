import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: '10px 10px 0 10px',
    height: '100%',
    fontSize: 28,
    color: theme.palette.primary.main,

    '& .apexcharts-tooltip': {
      border: 'none',
      boxShadow: '2px 2px 9px 2px rgba(0, 0, 0, 0.33)',
      background: 'none',
    },

    '& .tooltip': {
      backgroundColor: palette.custom.bluePrimary,
      padding: '3px 8px 5px 8px',
      color: palette.custom.grayLight,
    },
  },

  emptyWrap: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
