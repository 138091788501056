import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  scoring: {
    fontWeight: 600,
    fontSize: 28,
    lineHeight: '33px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&.red': {
      color: palette.custom.red900,
    },

    '&.green': {
      color: palette.custom.green400,
    },

    '&.yellow': {
      color: palette.custom.yellow400,
    },

    '&.light': {
      backgroundColor: palette.custom.gray100,
    },

    '&.top': {
      alignSelf: 'flex-start',
    },

    '&.center': {
      alignSelf: 'center',
    },

    '&.bottom': {
      alignSelf: 'flex-end',
    },
  },
}));

export default useStyles;
