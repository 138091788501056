import { IVehicleFormResponseData } from 'interfaces/vehicles/i-vehicle-form-data';
import { TVehicleEditData } from 'interfaces/vehicles/vehicle-edit-data';

import { vehicleDTO } from 'utils/dto/vehicle/edit-form';
import server from 'utils/server';

/**
 * Edits a vehicle with the provided ID and data.
 *
 * @async
 * @param {string} id - The ID of the vehicle to be edited.
 * @param {TVehicleEditData} data - The data to update the vehicle with.
 * @param {boolean} [isMetricSystem=true] - Indicates whether the data is in metric system.
 * @returns {Promise<IVehicleFormResponseData>} A promise that resolves to the response data of the edited vehicle.
 */
export const vehicleEditReq = async (
  id: string,
  data: TVehicleEditData,
  isMetricSystem: boolean = true,
): Promise<IVehicleFormResponseData> => {
  const body = isMetricSystem ? { ...data } : vehicleDTO.toMetric(data);
  body.generalInfo.active = true;

  return server.services.beService
    .put<IVehicleFormResponseData>(`vehicles/v2/${id}`, body)
    .then((res) => res.data);
};
