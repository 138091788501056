import React, { DragEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { BUTTON_VARIANTS } from 'interfaces/common/button-variants';
import { ITeamMember } from 'interfaces/teams/i-team-member';

import isAllSelectAvailableMembersAtom from 'recoil/settings/teams/is-all-select-available-members';
import isDraggableMembersAtom from 'recoil/settings/teams/is-draggable';
import selectedAvailableMembersAtom from 'recoil/settings/teams/selected-available-members';
import selectedTeamAtom from 'recoil/settings/teams/selected-team';
import showAvailableMembersAtom from 'recoil/settings/teams/show-available-members';
import teamsListAtom from 'recoil/settings/teams/teams-list';
import totalAvailableMembersAtom from 'recoil/settings/teams/total-available-members';
import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';

import { addedAvailableMembersReq } from 'requests/be-service/teams-controller/add-team-member';
import { teamMembersGetReq } from 'requests/be-service/teams-controller/find-team-members';

import Button from 'components/form-v2/button';
import Checkbox from 'components/form-v2/checkbox';
import Loader from 'components/Loader';
import PlateNumber from 'components/plate-number';
import VehicleIcon from 'components/vehicle-icon';

import ColumnHeader from '../column-header';

import useStyles from './styles';

const TeamMembers = () => {
  const technicalMessageBlockHeight = useRecoilValue(technicalMessageBlockHeightSelector);
  const classes = useStyles({ technicalMessageBlockHeight });
  const { t } = useTranslation(['teams.page', 'calendar.service']);
  const [isLoading, setIsLoading] = useState(false);
  const [members, setMembers] = useState<ITeamMember[]>([]);
  const [selectedTeam, setSelectedTeam] = useRecoilState(selectedTeamAtom);

  const setShowAvailableMembers = useSetRecoilState(showAvailableMembersAtom);
  const [selectedAvailableMembers, setSelectedAvailableMembers] = useRecoilState(
    selectedAvailableMembersAtom,
  );
  const [isAllSelected, setIsAllSelected] = useRecoilState(isAllSelectAvailableMembersAtom);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalAvailableMembers, setTotalAvailableMembers] =
    useRecoilState(totalAvailableMembersAtom);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const setTeams = useSetRecoilState(teamsListAtom);

  const setIsDraggableMembers = useSetRecoilState(isDraggableMembersAtom);

  const addedBtnText = useMemo(() => {
    if (isAllSelected) {
      if (selectedAvailableMembers.length) {
        return t('add.all.without.selected.label', {
          selectedCount: selectedAvailableMembers.length,
        });
      }
      return t('add.all.label');
    }

    if (selectedAvailableMembers.length) {
      return t('add.selected.label', { selectedCount: selectedAvailableMembers.length });
    }

    return null;
  }, [isAllSelected, selectedAvailableMembers.length, t]);

  /**
   * Get team members by params
   * @type {(function(*): Promise<void>)|*}
   */

  const getAvailableMembers = useCallback(
    async (page: number) => {
      if (!selectedTeam) return;

      try {
        setIsLoading(true);
        const res = await teamMembersGetReq(selectedTeam.teamId, page, searchValue, false);
        setMembers((prev) => [...prev, ...res.content]);
        setCurrentPage(res.number);
        setTotalPages(res.totalPages);
        setTotalAvailableMembers(res.totalElements);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [searchValue, selectedTeam, setTotalAvailableMembers],
  );

  /**
   * Handler for checkboxes in members list
   * @param value
   * @param id
   */
  const checkBoxHandler = (value: boolean, id: string) => {
    setSelectedAvailableMembers((prevState) => {
      if (value) {
        return [...prevState, id];
      }
      return prevState.filter((item) => item !== id);
    });
  };

  /**
   * Added team members with selected list
   * @type {(function(): Promise<void>)|*}
   */
  const addedListHandler = useCallback(async () => {
    if (!selectedTeam) return;

    try {
      setIsLoading(true);
      await addedAvailableMembersReq(selectedTeam.teamId, isAllSelected, selectedAvailableMembers);
      setSelectedAvailableMembers([]);
      setIsAllSelected(false);
      const newCount = isAllSelected
        ? selectedAvailableMembers.length
          ? selectedTeam.count + totalAvailableMembers - selectedAvailableMembers.length
          : selectedTeam.count + totalAvailableMembers
        : selectedTeam.count + selectedAvailableMembers?.length;

      setTeams((prevState) => {
        const teams = [...prevState];
        const currentTeamIndex = teams.findIndex((item) => {
          return item.teamId === selectedTeam?.teamId;
        });

        teams[currentTeamIndex] = {
          ...teams[currentTeamIndex],
          count: newCount,
        };

        return teams;
      });

      setSelectedTeam((prev) => ({
        ...prev!,
        count: newCount,
      }));
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, [
    isAllSelected,
    selectedAvailableMembers,
    selectedTeam,
    setIsAllSelected,
    setSelectedAvailableMembers,
    setSelectedTeam,
    setTeams,
    totalAvailableMembers,
  ]);

  /**
   * Handler for ending drag available member
   * @param e
   */
  const onDragEndHandler: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    document.getElementById('draggable-ghost')?.remove();
    setIsDraggableMembers(false);
  };

  /**
   * Handler for starting drag available member
   * @param e
   */
  const onDragStartHandler: DragEventHandler<HTMLDivElement> = (e) => {
    setIsDraggableMembers(true);
    const draggableGhost = document.createElement('div');
    draggableGhost.style.position = 'fixed';
    draggableGhost.style.bottom = '-1000px';
    draggableGhost.setAttribute('id', 'draggable-ghost');

    setSelectedAvailableMembers([...selectedAvailableMembers]);

    if (isAllSelected) {
      if (selectedAvailableMembers.length) {
        draggableGhost.innerText = t('add.all.without.selected.label', {
          selectedCount: selectedAvailableMembers.length,
        });
      } else {
        draggableGhost.innerText = t('add.all.label');
      }
    } else {
      if (selectedAvailableMembers.length) {
        draggableGhost.innerText = t('add.selected.label', {
          selectedCount: selectedAvailableMembers.length,
        });
      } else {
        draggableGhost.innerText = (
          e.currentTarget.querySelector('[data-plateNumber="plateNumber"]') as HTMLElement
        ).innerText;
        setSelectedAvailableMembers([e.currentTarget.dataset.id as string]);
      }
    }

    document.body.appendChild(draggableGhost);
    e.dataTransfer.setDragImage(draggableGhost, 0, 0);
  };

  /**
   * Init request team members
   */
  useEffect(() => {
    setMembers([]);
    if (selectedTeam) {
      getAvailableMembers(0);
    }
  }, [getAvailableMembers, selectedTeam]);

  return (
    <div className={classes.wrap} data-testid='available-members-wrap'>
      {isLoading && <Loader lightMode width={150} isBlock preventClick />}

      <div className={classes.header}>
        <ColumnHeader
          label={t('available.drivers.label')}
          onSearchSubmit={(data) => setSearchValue(data.query)}
          checkBoxData={{
            show: true,
            handler: (value: boolean) => {
              setSelectedAvailableMembers([]);
              setIsAllSelected(value);
            },
            isIndeterminate: Boolean(selectedAvailableMembers.length),
            value: isAllSelected,
          }}
        />

        <Button
          onClick={() => setShowAvailableMembers(false)}
          variant={BUTTON_VARIANTS.OUTLINE}
          className={classes.closeBtn}
          testId='button-close-available-members'
        >
          {t('available.drivers.close.label')}
        </Button>
      </div>

      <div data-testid='available-members-list'>
        <InfiniteScroll
          dataLength={members.length}
          next={() => getAvailableMembers(currentPage + 1)}
          loader={null}
          hasMore={currentPage < totalPages - 1}
          height={`calc(100vh - 220px - ${technicalMessageBlockHeight}px)`}
          className={classes.list}
        >
          {members.map((member) => {
            const isSelected = selectedAvailableMembers.includes(member.driverInfo.id);

            return (
              <div
                key={member.driverInfo.id}
                data-testid={`available-member-${member.driverInfo.id}`}
                className={classes.row}
                data-id={member.driverInfo.id}
                draggable={
                  !selectedAvailableMembers.length
                    ? 'true'
                    : isAllSelected
                    ? !isSelected
                    : isSelected
                }
                onDragStart={onDragStartHandler}
                onDragEnd={onDragEndHandler}
              >
                <div className={classes.content}>
                  <Checkbox
                    onChange={(value) => checkBoxHandler(value, member.driverInfo.id)}
                    value={isAllSelected ? !isSelected : isSelected}
                    testId='checkbox-member-selected'
                  />

                  <div className={classes.item}>
                    <PlateNumber
                      wrapClass={classes.plateNumber}
                      plateNumber={member.vehicleInfo.plateNumber}
                      country={member.vehicleInfo.country}
                    />
                    <VehicleIcon
                      className={classes.vehicleIcon}
                      type={member.vehicleInfo.vehicleType}
                      variant='outline'
                    />
                    <div
                      className={classes.vehicleBrand}
                      data-testid='brand'
                    >{`${member.vehicleInfo.brandName} ${member.vehicleInfo.modelName}`}</div>
                    <div
                      className={classes.driverName}
                      data-testid='driver-name'
                    >{`${member.driverInfo.firstName} ${member.driverInfo.lastName}`}</div>
                  </div>
                </div>
              </div>
            );
          })}

          {(Boolean(selectedAvailableMembers.length) || isAllSelected) && (
            <Button
              variant={BUTTON_VARIANTS.FILL}
              onClick={addedListHandler}
              className={classes.addBtn}
              testId='button-add-all'
            >
              {addedBtnText}
            </Button>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default TeamMembers;
