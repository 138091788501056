import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { TABLE_VIEW_TYPE } from 'interfaces/common/table-view-type';
import { TIME_DEGREE } from 'interfaces/common/time-degree';
import { ITripWithDateLabel } from 'interfaces/map/i-trip';

import RouteManager from 'utils/services/route-manager';

import PenaltyCard from 'components/penalty-card';
import ScoreValue from 'components/score-value';
import StatisticValue from 'components/statistic-value';
import Time from 'components/time';
import TripPath from 'components/trip-path';

import useStyles from './styles';

interface IProps {
  data: ITripWithDateLabel;
  tableViewType: TABLE_VIEW_TYPE;
}

const TripRow: FC<IProps> = ({ data, tableViewType }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const rowHandler = () => navigate(RouteManager.makeURL('drivers.trip', { id, tripId: data.id }));

  return (
    <TableRow className={classes.row} onClick={rowHandler} data-testid={`trip-row-${data.id}`}>
      <TableCell>
        {
          // TODO: need fix types
        }
        <TripPath
          startAddress={data?.startAddress?.fullAddress}
          startDate={format(new Date(data?.startGpsPoint?.timestamp as string), 'HH:mm')}
          endAddress={data?.finishAddress?.fullAddress}
          endDate={format(new Date(data?.finishGpsPoint?.timestamp as string), 'HH:mm')}
        />
      </TableCell>

      <TableCell align={'center'} classes={{ body: classes.cell }} data-testid='currentScoring'>
        <ScoreValue value={data.currentScoring} />
      </TableCell>

      {tableViewType === 'info' && (
        <TableCell align={'center'} classes={{ body: classes.cell }}>
          <div className={classes.problems}>
            {data.penaltyCard && <PenaltyCard type={data.penaltyCard} />}
          </div>
        </TableCell>
      )}

      <TableCell classes={{ body: classes.cell }} align={'center'} data-testid='km'>
        <StatisticValue value={data.km} />
      </TableCell>
      <TableCell classes={{ body: classes.cell }} align={'center'} data-testid='time'>
        <Time timestamp={data.time} />
      </TableCell>
      <TableCell classes={{ body: classes.cell }} align={'center'} data-testid='idling'>
        <Time timestamp={data.idling} degree={TIME_DEGREE.S} />
      </TableCell>

      {tableViewType === 'statistic' && (
        <TableCell classes={{ body: classes.cell }} align={'center'} data-testid='fuelUsed'>
          <StatisticValue value={data.fuelUsed} />
        </TableCell>
      )}

      <TableCell />
    </TableRow>
  );
};

export default TripRow;
