import * as yup from 'yup';

export const formSchema = () => {
  return yup.object().shape({
    companyIds: yup.string().nullable(),
    userIds: yup.string().nullable(),
    teamIds: yup.string().nullable(),
  });
};

export type TDriverFiltersForm = yup.InferType<ReturnType<typeof formSchema>>;
