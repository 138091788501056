import { TSensorAlarmByTrip } from 'interfaces/sensor/t-sensor-alarm-by-trip';

import { convertSensorsAlarms } from './convert-sensors-alarms';

/**
 * Checks if there are any sensor alarms in the given data.
 *
 * @param {TSensorAlarmByTrip[]} data - An array of sensor alarms by trip.
 * @returns {boolean} - Returns true if there are any sensor alarms, otherwise returns false.
 */
export const checkSensorsAlarms = (data: TSensorAlarmByTrip[]): boolean =>
  Object.values(convertSensorsAlarms(data)).flat().length !== 0;
