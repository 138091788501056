import cx from 'classnames';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { DEVICE_FUNCTION } from 'interfaces/vehicles/device-functions';
import { ENGINE_STATUS } from 'interfaces/vehicles/engine-status';

import { ReactComponent as LoadingIcon } from 'assets/images/dashboard-map/loading-btn.svg';
import { ReactComponent as PowerBlueIcon } from 'assets/images/dashboard-map/power-blue.svg';
import { ReactComponent as PowerGrayIcon } from 'assets/images/dashboard-map/power-gray.svg';

import { vehicleEnginePowerToggleReq } from 'requests/be-service/vehicle-controller/send-command';

import EngineStatusModal from 'components/EngineStatusModal';

import useStyles from './styles';

interface IProps {
  deviceFunctions: DEVICE_FUNCTION[];
  engineStatus: ENGINE_STATUS;
  setEngineStatus: (value: ENGINE_STATUS) => void;
  vehicleIMEI: string;
}

const Communication: FC<IProps> = ({
  engineStatus,
  vehicleIMEI,
  deviceFunctions,
  setEngineStatus,
}) => {
  const classes = useStyles();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const BtnIcon = useMemo(() => {
    switch (engineStatus) {
      case ENGINE_STATUS.ACTIVE:
        return PowerBlueIcon;

      case ENGINE_STATUS.INACTIVE:
        return PowerGrayIcon;

      case ENGINE_STATUS.IN_PROGRESS:
        return LoadingIcon;
    }
  }, [engineStatus]);

  const onToggleEngineStatus = useCallback(async () => {
    if (engineStatus === ENGINE_STATUS.IN_PROGRESS) {
      return;
    }

    try {
      await vehicleEnginePowerToggleReq(vehicleIMEI, engineStatus);
      setEngineStatus(ENGINE_STATUS.IN_PROGRESS);
    } catch (e) {
      console.log(e);
    }
  }, [engineStatus, setEngineStatus, vehicleIMEI]);

  const onCancel = () => setIsOpenModal(false);

  return (
    <div className={classes.wrap} data-testdid='communication-wrap'>
      <div className={classes.btns}>
        {deviceFunctions?.includes(DEVICE_FUNCTION.START_STOP_BUTTON) && engineStatus && (
          <div
            className={cx(classes.btn, engineStatus)}
            onClick={() => setIsOpenModal(true)}
            data-testdid='button-engine'
          >
            <BtnIcon />
          </div>
        )}
      </div>

      {engineStatus && (
        <EngineStatusModal
          isOpen={isOpenModal}
          status={engineStatus}
          onCancel={onCancel}
          onSubmit={onToggleEngineStatus}
        />
      )}
    </div>
  );
};

export default Communication;
