import { UsePaginationProps } from '@mui/material/usePagination/usePagination';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import SimpleBar from 'simplebar-react';

import { IResponseWrapper } from 'interfaces/server/response-wrapper';
import { TCompanyUser } from 'interfaces/user/company-user';

import RouteManager from 'utils/services/route-manager';

import settingsUsersFilterAtom from 'recoil/settings/settingsUsersFilter';
import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';

import { getSystemUsersReq } from 'requests/be-service/system-user-controller/get-all-users';

import Loader from 'components/Loader';
import PaginationComponent from 'components/PaginationComponent';

import AddButton from '../../components/AddButton';
import Header from '../../components/header';

import UserSettingsItem from './components/UserSettingsItem';
import useStyles from './styles';

const Users = () => {
  const technicalMessageBlockHeight = useRecoilValue(technicalMessageBlockHeightSelector);
  const classes = useStyles({ technicalMessageBlockHeight });
  const navigate = useNavigate();
  const { t } = useTranslation('setting.page');

  const [usersData, setUsersData] = useState<IResponseWrapper<TCompanyUser[]> | null>(null);
  const [loader, setLoader] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [settingsUsersFilter, setSettingsUsersFilter] = useRecoilState(settingsUsersFilterAtom);
  const isSubcompanyView = sessionStorage.getItem('subcompanyId');

  const handleGetSystemUsersReq = useCallback(
    async (page: number) => {
      setLoader(true);

      try {
        const formatPage = page - 1;
        const response = await getSystemUsersReq(formatPage, settingsUsersFilter);
        setUsersData(response);

        if (response.numberOfElements === 0) {
          enqueueSnackbar('No Users Found', { variant: 'error' });
        }
      } catch {
        enqueueSnackbar('Get Users Error', { variant: 'error' });
      } finally {
        setLoader(false);
      }
    },
    [enqueueSnackbar, settingsUsersFilter],
  );

  useEffect(() => {
    const req = async () => handleGetSystemUsersReq(1);
    req();
  }, [handleGetSystemUsersReq, settingsUsersFilter]);

  const handlePagination: UsePaginationProps['onChange'] = (_event, value) =>
    handleGetSystemUsersReq(value);

  const handleAddUser = useCallback(
    () => navigate(RouteManager.makeURL('settings.users.create')),
    [navigate],
  );

  return (
    <div className={classes.wrap}>
      <Header
        backBtn={{ label: t('user.roles.label') }}
        searchValue={settingsUsersFilter.query}
        searchHandler={({ query }) => {
          setSettingsUsersFilter((prev) => ({ ...prev, query }));
        }}
        onCancelSearch={() => {
          setSettingsUsersFilter((prev) => ({ ...prev, query: '' }));
        }}
      />

      <div className={classes.content} data-testid='users-list'>
        {!isSubcompanyView && <AddButton onClick={handleAddUser} />}

        <SimpleBar className={classes.usersWrap}>
          {loader && <Loader width={100} lightMode preventClick isBlock />}

          {!loader &&
            usersData?.content.map((item) => <UserSettingsItem key={item.id} data={item} />)}
        </SimpleBar>

        {usersData && (
          <PaginationComponent
            size={usersData.size}
            page={usersData.number}
            totalPages={usersData.totalPages}
            totalElements={usersData.totalElements}
            numberOfElements={usersData.numberOfElements}
            onChange={handlePagination}
          />
        )}
      </div>
    </div>
  );
};

export default Users;
