import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

type Props = {
  technicalMessageBlockHeight: number;
};

const useStyles = makeStyles<Theme, Props>((theme) => ({
  pagesWrapper: {
    paddingTop: 50,
    height: ({ technicalMessageBlockHeight }) =>
      `calc(100vh - 240px - ${technicalMessageBlockHeight}px)`,
  },

  pagesList: {
    margin: '0 auto',
    width: 400,
    height: '100%',
  },

  pageName: {
    padding: '10px 25px',
    marginBottom: 15,
    backgroundColor: palette.custom.lightBlue,
    color: theme.palette.primary.light,
    borderRadius: 5,
    cursor: 'pointer',
    transition: 'all 0.3s ease',

    '&:hover': {
      backgroundColor: palette.custom.softBlue,
    },
  },

  modalWrap: {
    '& .MuiPaper-root': {
      backgroundColor: palette.custom.lightBlue,
      maxWidth: 800,
    },
  },

  column: {
    minWidth: 200,
    maxWidth: 200,
    width: 200,
    paddingLeft: '25px !important',
    paddingRight: '25px !important',
  },

  input: {
    '& > div': {
      background: 'transparent',
    },

    '& input': {
      color: palette.custom.white,
    },

    '& fieldset': {
      border: 'none',
    },
  },

  warningWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: 25,

    '& div': {
      fontSize: 18,
      color: palette.custom.blue200,
      maxWidth: 720,
    },
  },

  warningIcon: {
    width: 50,
  },
}));

export default useStyles;
