import { selectorFamily } from 'recoil';

import { IConvertedSensorsAlarms } from 'interfaces/sensor/i-converted-sensors-alarms';

import { getSensorAlarmForTrip } from 'requests/gps-service/sensor-controller/get-alarm-by-trip';

const sensorAlarmsSelector = selectorFamily<IConvertedSensorsAlarms, string>({
  key: 'sensorAlarmsSelector',
  get: (tripId) => async () => {
    return await getSensorAlarmForTrip(tripId);
  },
});

export default sensorAlarmsSelector;
