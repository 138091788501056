import ClickAwayListener from '@mui/material/ClickAwayListener';
import { lineString } from '@turf/turf';
import cx from 'classnames';
import { format } from 'date-fns';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import subMonths from 'date-fns/subMonths';
import GoogleMapReact from 'google-map-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { timeoutWorker } from 'timeout-worker';

import { liveTripUrlRegExp, mapDefaultSetting } from 'constants/map';
import { VEHICLES_ACTIVE_STATUSES } from 'constants/vehicle';

import { ReactComponent as GeoFencingIcon } from 'assets/images/geo-fencing.svg';

import { getMapSettings, isEmptyObj, setZonedTime, delay } from 'utils/helpers';
import { valueConverter } from 'utils/helpers/value-converter';
import RouteManager from 'utils/services/route-manager';

import liveTrackingDriverDescriptionAtom from 'recoil/live-tracking/driver-description';
import liveTrackingPathAtom from 'recoil/live-tracking/path';
import liveTrackingStatisticAtom from 'recoil/live-tracking/statistic';
import navigationOptionsAtom from 'recoil/navigation';
import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';
import userConfigAtom from 'recoil/user-config';
import vehicleSensorsListAtom from 'recoil/vehicles/vehicleSensorsList';

import { driverTripsReq } from 'requests/be-service/driver-controller/get-trips';
import { getTrackingDriverInfoReq } from 'requests/be-service/driver-vehicle-controller/get-details-info';
import { getTripReq } from 'requests/be-service/map-view-controller/get-trip-points';
import { getPenaltiesPointsReq } from 'requests/be-service/map-view-controller/get-trip-violations';
import { getSensorAlarmForLiveTripReq } from 'requests/gps-service/sensor-controller/get-alarm-by-imei-for-live';
import { getLiveTripPointsReq } from 'requests/mobile-service/get-live-trip-pionts';

import DeviceStatus from 'components/device-status';
import Loader from 'components/Loader';
import BackButton from 'components/Map/BackButton';
import Communication from 'components/Map/Communication';
import DriverInfo from 'components/Map/DriverInfo';
import Layer from 'components/Map/Layer';
import MobilesNumbers from 'components/Map/mobiles-numbers';
import Toolbar from 'components/Map/Toolbar';
import TripInfo from 'components/Map/TripInfo';
import VehicleMarker from 'components/Map/VehicleMarker';
import FinishMarker from 'components/TripComponents/FinishMarker';
import StartMarker from 'components/TripComponents/StartMarker';

import Sensor from './components/sensor';
import useStyles from './styles';

timeoutWorker.start();

const TripLive = () => {
  const technicalMessageBlockHeight = useRecoilValue(technicalMessageBlockHeightSelector);
  const classes = useStyles({ technicalMessageBlockHeight });
  const { id } = useParams();
  const location = useLocation();
  const { t } = useTranslation('dashboard.map.page');

  const mapRef = useRef(null);
  const finishRef = useRef(null);
  const marker = useRef(null);
  const route = useRef(null);

  const recursiveTimeoutRef = useRef(null);
  const recursiveSearchTrip = useRef(null);
  const recursiveTrackingAfterFinished = useRef(null);
  const moveMarkerRef = useRef(null);
  const goToPointRef = useRef(null);

  const [driverDescription, setDriverDescription] = useRecoilState(
    liveTrackingDriverDescriptionAtom,
  );
  const { isMetricSystem, country } = useRecoilValue(userConfigAtom);
  const resetDriverDescription = useResetRecoilState(liveTrackingDriverDescriptionAtom);
  const [markerCoordinates, setMarkerCoordinates] = useState(null);
  const [pathInfo, setPathInfo] = useRecoilState(liveTrackingPathAtom);
  const resetPathInfo = useResetRecoilState(liveTrackingPathAtom);
  const [, setStatistic] = useRecoilState(liveTrackingStatisticAtom);
  const resetStatistic = useResetRecoilState(liveTrackingStatisticAtom);
  const [shouldTracking, setShouldTracking] = useState(true);
  const [isOpenNewTripModal, setIsOpenNewTripModal] = useState(false);
  const { isOpenNav } = useRecoilValue(navigationOptionsAtom);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isFinishTrip, setIsFinishTrip] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [startPoint, setStartPoint] = useState(null);
  const [tripPoints, setTripPoints] = useState(null);
  const [isSatellite, setIsSatellite] = useState(false);
  const [hasUserDrag, setHasUserDrag] = useState(false);
  const [gmap, setGmap] = useState({ map: null, maps: null });
  const [mapSize, setMapSize] = useState({ width: 0, height: 0 });
  const [mapSettings, setMapSettings] = useState({
    center: mapDefaultSetting.center,
    zoom: mapDefaultSetting.zoom,
  });
  const [shouldSearchTrip, setShouldSearchTrip] = useState(false);
  const [engineStatus, setEngineStatus] = useState(null);
  const [vehicleSensorsList, setVehicleSensorsList] = useRecoilState(vehicleSensorsListAtom);
  const resetSensorsList = useResetRecoilState(vehicleSensorsListAtom);
  const [hasSensors, setHasSensors] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState(null);

  const getTripPoints = useCallback(async (vehicleId, startTime) => {
    try {
      return await getLiveTripPointsReq(vehicleId, startTime);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const clearAllData = useCallback(() => {
    finishRef.current = null;
    marker.current = null;
    setMarkerCoordinates(null);
    route.current?.setMap(null);
    route.current = null;
    recursiveTimeoutRef.current && timeoutWorker.clearTimeout(recursiveTimeoutRef.current);
    moveMarkerRef.current && timeoutWorker.clearTimeout(moveMarkerRef.current);
    goToPointRef.current && timeoutWorker.clearTimeout(goToPointRef.current);
    recursiveTrackingAfterFinished.current &&
      timeoutWorker.clearTimeout(recursiveTrackingAfterFinished.current);
    recursiveSearchTrip.current && timeoutWorker.clearTimeout(recursiveSearchTrip.current);
    setEngineStatus(null);
    resetPathInfo();
    resetStatistic();
    setShouldTracking(true);
    setIsOpenNewTripModal(false);
    setShouldSearchTrip(false);
    setIsFirstLoad(true);
    setIsFinishTrip(false);
    setStartPoint(null);
    setTripPoints(null);
  }, [resetPathInfo, resetStatistic]);

  /**
   * Get initial data for current live trip
   * Get driver and vehicle description
   */
  useEffect(() => {
    let isMounted = true;
    if (!isFirstLoad) {
      return;
    }

    const req = async () => {
      try {
        if (!isMounted) return;

        setIsLoading(true);

        const driverInfo = await getTrackingDriverInfoReq(id);

        setHasSensors(
          driverInfo?.vehicleInfo.deviceFunctions.includes('TEMPERATURE_HUMIDITY_SENSOR'),
        );
        setDriverDescription(driverInfo);

        const tripInfo = await getTripPoints(id, null);
        const lastPoint = tripInfo.wayPoints[tripInfo.wayPoints.length - 1];
        const firstPoint = tripInfo.wayPoints[0];

        setDeviceInfo(tripInfo.deviceInfo);

        setPathInfo({
          startTime: format(
            new Date(tripInfo?.startPoint?.timestamp ?? firstPoint.timestamp),
            'dd.MM.yyyy  HH:mm',
          ),
          startAddress:
            tripInfo?.startPoint?.name ?? `${firstPoint.latitude}, ${firstPoint.longitude}`,
          status: tripInfo.status,
          speed: isMetricSystem
            ? lastPoint.speed
            : valueConverter.distance.toImperial(lastPoint.speed).toFixed(2),
          angle: lastPoint.angle,
        });
        setStatistic((prevState) => ({
          ...prevState,
          driveDistance: isMetricSystem
            ? tripInfo.distance / 1000
            : valueConverter.distance.toImperial(tripInfo.distance / 1000),
          driveTime: tripInfo.driveTime / 60,
          idlingTime: tripInfo.idleTime / 60,
          parkingTime: tripInfo.parkingTime / 60,
        }));
        setStartPoint(firstPoint);
        setTripPoints(tripInfo?.wayPoints);
        setEngineStatus(tripInfo?.engineStatus);
        finishRef.current = lastPoint;
        setIsFirstLoad(false);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };

    req();

    return () => {
      isMounted = false;
    };
  }, [
    isMetricSystem,
    getTripPoints,
    id,
    isFirstLoad,
    setDriverDescription,
    setPathInfo,
    setStatistic,
  ]);

  /**
   * Get map client width and height
   */
  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    setMapSize({
      width: mapRef.current.offsetWidth,
      height: mapRef.current.offsetHeight,
    });
  }, []);

  /**
   * Get map center and zoom settings
   */
  useEffect(() => {
    if (!tripPoints || isEmptyObj(tripPoints)) {
      return;
    }

    if (tripPoints.length === 1) {
      setMapSettings({
        center: { lat: tripPoints[0].latitude, lng: tripPoints[0].longitude },
        zoom: 16,
      });
      return;
    }
    const line = lineString(tripPoints.map((point) => [point.longitude, point.latitude]));

    setMapSettings(getMapSettings(line, mapSize));
  }, [tripPoints, mapSize]);

  /**
   * if trip isFinished search new points
   */
  useEffect(() => {
    if (!isFinishTrip) {
      return;
    }
    const req = async () => {
      try {
        const response = await getTripPoints(id, finishRef.current?.timestamp);

        setDeviceInfo(response.deviceInfo);

        if (response.status === VEHICLES_ACTIVE_STATUSES.ACTIVE) {
          setShouldTracking(false);
          setIsOpenNewTripModal(true);
        } else {
          recursiveTrackingAfterFinished.current = timeoutWorker.setTimeout(() => req(), 3000);
        }
      } catch (e) {
        console.log(e);
      }
    };
    req();
  }, [getTripPoints, id, isFinishTrip, shouldTracking]);

  /**
   * Get new trip points in live
   */
  useEffect(() => {
    let isMounted = true;
    if (isFirstLoad || !shouldTracking) {
      return;
    }

    const req = async () => {
      try {
        if (!isMounted) return;

        const isLiveTrackingPage = liveTripUrlRegExp.test(location.pathname);
        if (!shouldTracking || !isLiveTrackingPage) {
          return;
        }

        await delay(5000);
        const newPoints = await getTripPoints(id, finishRef.current?.timestamp);

        setDeviceInfo(newPoints.deviceInfo);

        if (hasSensors) {
          const vehiclesSensorsInfo = await getSensorAlarmForLiveTripReq(
            id,
            finishRef.current?.timestamp,
          );
          const hasAlarm = vehiclesSensorsInfo[vehiclesSensorsInfo.length - 1];
          setDriverDescription((prevState) => {
            return {
              ...prevState,
              vehicleInfo: { ...prevState.vehicleInfo, hasAlarm },
            };
          });
          setVehicleSensorsList(vehiclesSensorsInfo.slice(0, -1));
        }
        setEngineStatus(newPoints?.engineStatus);

        if (
          newPoints?.status === VEHICLES_ACTIVE_STATUSES.INACTIVE &&
          tripPoints.length > 1 &&
          !isFinishTrip
        ) {
          setIsFinishTrip(true);
          return;
        }

        if (!isFinishTrip) {
          setStatistic((prevState) => {
            return {
              ...prevState,
              parkingTime: prevState.parkingTime,
              idlingTime:
                newPoints.status === VEHICLES_ACTIVE_STATUSES.ACTIVE
                  ? prevState.idlingTime + newPoints.idleTime / 60
                  : newPoints.idleTime / 60,
              driveTime: prevState.driveTime + newPoints.driveTime / 60,
              driveDistance:
                prevState.driveDistance +
                (isMetricSystem
                  ? newPoints.distance / 1000
                  : valueConverter.distance.toImperial(newPoints.distance / 1000)),
            };
          });

          const filteredNewPoints =
            newPoints?.wayPoints?.filter(
              ({ timestamp }) => timestamp > finishRef.current?.timestamp,
            ) ?? [];

          if (filteredNewPoints.length === 0) {
            if (isMounted) {
              recursiveTimeoutRef.current = timeoutWorker.setTimeout(() => req(), 5000);
              return;
            }
          }

          if (tripPoints.length > 1) {
            const path = route.current.getPath();

            filteredNewPoints.forEach((point) => {
              path.push(new gmap.maps.LatLng(point.latitude, point.longitude));
            });
            route.current.path = path;
            marker.current = new gmap.maps.LatLng(
              filteredNewPoints[filteredNewPoints.length - 1].latitude,
              filteredNewPoints[filteredNewPoints.length - 1].longitude,
            );
            setMarkerCoordinates({
              lat: filteredNewPoints[filteredNewPoints.length - 1].latitude,
              lng: filteredNewPoints[filteredNewPoints.length - 1].longitude,
            });
            finishRef.current = filteredNewPoints[filteredNewPoints.length - 1];
            setTripPoints((prevState) => [...prevState, ...filteredNewPoints]);
            setPathInfo((prevState) => {
              return {
                ...prevState,
                speed: isMetricSystem
                  ? filteredNewPoints[filteredNewPoints.length - 1].speed
                  : valueConverter.distance
                      .toImperial(filteredNewPoints[filteredNewPoints.length - 1].speed)
                      .toFixed(2),
                angle: filteredNewPoints[filteredNewPoints.length - 1].angle,
              };
            });
          } else {
            clearAllData();
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

    req();

    return () => {
      isMounted = false;
    };
  }, [
    location,
    isMetricSystem,
    clearAllData,
    getTripPoints,
    gmap.maps?.LatLng,
    hasSensors,
    id,
    isFinishTrip,
    isFirstLoad,
    setDriverDescription,
    setPathInfo,
    setStatistic,
    setVehicleSensorsList,
    shouldTracking,
    tripPoints?.length,
  ]);

  /**
   * Create initial route
   */
  if (gmap.maps && finishRef.current) {
    if (!marker.current) {
      marker.current = new gmap.maps.LatLng(
        finishRef.current.latitude,
        finishRef.current.longitude,
      );
      setMarkerCoordinates({ lat: finishRef.current.latitude, lng: finishRef.current.longitude });
    }

    if (!route.current && tripPoints.length > 0) {
      const path = tripPoints?.map((item) => {
        return new gmap.maps.LatLng(item.latitude, item.longitude);
      });
      route.current = new gmap.maps.Polyline({
        path,
        map: gmap.map,
        geodesic: false,
      });
    }
    route.current.setOptions({ strokeColor: isSatellite ? '#fff' : '#000' });
  }

  /**
   * looking for a formed trip, after its completion
   */
  useEffect(() => {
    if (!isFinishTrip && !shouldSearchTrip) {
      return;
    }

    const req = async () => {
      const driverTrips = await driverTripsReq(
        {
          id: driverDescription?.driverInfo.id,
        },
        {
          createdAtFrom: subMonths(startOfDay(new Date()), 1).getTime(),
          createdAtTill: endOfDay(new Date()).getTime(),
          vehicleTypes: [],
          startDailyKm: undefined,
          finishDailyKm: undefined,
          query: '',
          page: 0,
        },
      );
      const filteredDriverTrips = driverTrips.content.filter((trip) => {
        return (
          Math.abs(new Date(trip.startGpsPoint.timestamp).getTime() - startPoint?.timestamp) <=
          10000
        );
      });
      if (filteredDriverTrips.length > 0) {
        const tripInfo = await getTripReq(filteredDriverTrips[0].id);
        const tripViolations = await getPenaltiesPointsReq(filteredDriverTrips[0].id);
        setPathInfo((prevState) => {
          return {
            ...prevState,
            startTime: setZonedTime(tripInfo.trip.startGpsPoint?.timestamp, 'dd.MM.yyyy  HH:mm'),
            startAddress: tripInfo.trip.startAddress.fullAddress,
            finishTime: setZonedTime(tripInfo.trip.finishGpsPoint?.timestamp, 'dd.MM.yyyy  HH:mm'),
            finishAddress: tripInfo.trip.finishAddress.fullAddress,
          };
        });
        setStatistic((prevState) => ({
          ...prevState,
          driveTime: tripInfo.trip.time,
          driveDistance: isMetricSystem
            ? tripInfo.trip.km.toFixed(2)
            : valueConverter.distance.toImperial(tripInfo.trip.km).toFixed(2),
          penalties: tripInfo.trip.penalties,
          totalScore: tripInfo.trip.totalScore,
          penaltyCard: tripInfo.trip.penaltyCard,
          hasCrash: tripViolations?.CRASH?.length > 0,
        }));
      } else {
        recursiveSearchTrip.current = timeoutWorker.setTimeout(() => req(), 10000);
      }
    };

    req();
  }, [
    driverDescription?.driverInfo?.id,
    driverDescription?.vehicleInfo?.imei,
    isFinishTrip,
    isMetricSystem,
    setPathInfo,
    setStatistic,
    shouldSearchTrip,
    startPoint,
  ]);

  /**
   * Clear timeouts and data
   */
  useEffect(() => {
    return () => {
      clearAllData();
      resetDriverDescription();
      resetSensorsList();
    };
  }, [clearAllData, resetDriverDescription, resetSensorsList]);

  /**
   * Create map options
   */
  const createMapOptions = () => ({
    fullscreenControl: false,
    mapTypeId: isSatellite ? 'hybrid' : 'roadmap',
    styles: [{ stylers: [{ visibility: 'on' }] }],
  });

  const toggleMapType = useCallback(() => setIsSatellite((prevState) => !prevState), []);

  const mapBootstrapURLKeys = useMemo(() => {
    const config = {
      key: process.env.REACT_APP_GOOGLE_MAP_KEY,
      version: 'beta',
      libraries: ['marker'],
      mapIds: [process.env.REACT_APP_GOOGLE_MAP_ID],
    };

    if (country === 'AZ') {
      config.region = 'AZ';
    }

    return config;
  }, [country]);

  return (
    <div className={classes.container} ref={mapRef}>
      {isLoading && <Loader preventClick width={100} />}

      <GoogleMapReact
        options={createMapOptions}
        bootstrapURLKeys={mapBootstrapURLKeys}
        defaultCenter={mapDefaultSetting.center}
        defaultZoom={mapDefaultSetting.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) =>
          setGmap({
            map,
            maps,
          })
        }
        center={hasUserDrag ? null : mapSettings.center}
        zoom={hasUserDrag ? null : mapSettings.zoom}
        onDrag={() => setHasUserDrag(true)}
      >
        {Boolean(startPoint) && tripPoints?.length > 1 && !isLoading && (
          <StartMarker lat={startPoint.latitude} lng={startPoint.longitude} />
        )}

        {isFinishTrip && !isLoading && (
          <FinishMarker lat={finishRef?.current.latitude} lng={finishRef?.current.longitude} />
        )}
        {!isFinishTrip && !isLoading && (
          <VehicleMarker
            lat={markerCoordinates?.lat}
            lng={markerCoordinates?.lng}
            vehicleId={id}
            plateNumber={driverDescription?.vehicleInfo.plateNumber ?? ''}
            driverName={`${driverDescription?.driverInfo.firstName} ${driverDescription?.driverInfo.lastName}`}
            status={pathInfo?.status}
            angle={pathInfo?.angle ?? 0}
            disabledInfo
          />
        )}
      </GoogleMapReact>

      <div className={classes.info}>
        {driverDescription && <DriverInfo />}

        {driverDescription?.isShowSensors && (
          <ClickAwayListener
            onClickAway={() => {
              setDriverDescription((prevState) => {
                return {
                  ...prevState,
                  isShowSensors: false,
                };
              });
            }}
          >
            <div className={cx(classes.sensors, { isFull: vehicleSensorsList?.length > 3 })}>
              {vehicleSensorsList?.map((sensor) => {
                return (
                  <Sensor
                    sensor={sensor}
                    wrapClass={classes.sensorWrap}
                    key={sensor.id}
                    finishTimestamp={finishRef.current?.timestamp}
                  />
                );
              })}
            </div>
          </ClickAwayListener>
        )}
        <div className={classes.widgets}>
          {isLoading ? (
            <Loader isBlock lightMode width={40} />
          ) : (
            <>
              <TripInfo isFinishTrip={isFinishTrip} />

              {driverDescription && (
                <>
                  <Communication
                    deviceFunctions={driverDescription?.vehicleInfo.deviceFunctions}
                    setEngineStatus={setEngineStatus}
                    engineStatus={engineStatus}
                    vehicleIMEI={driverDescription?.vehicleInfo.imei}
                  />
                  <MobilesNumbers
                    driverPhone={driverDescription?.driverInfo.phoneNumber}
                    driverPhonePrefix={driverDescription?.driverInfo.phonePrefix}
                    driverSosPhone={driverDescription?.driverInfo.sosPhoneNumber}
                    driverSosPhonePrefix={driverDescription?.driverInfo.sosPhonePrefix}
                  />
                </>
              )}

              {Boolean(deviceInfo) && (
                <div className={classes.deviceInfo}>
                  <DeviceStatus
                    active={deviceInfo.active}
                    time={deviceInfo.gpsPoint.timestamp}
                    inlineDate
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {isOpenNewTripModal && (
        <div className={classes.modal} data-testdid='new-trip-modal-wrap'>
          <div className={classes.modalContent}>
            <p className={classes.modalTitle}>
              {`${driverDescription?.driverInfo.firstName} ${driverDescription?.driverInfo.lastName}`}{' '}
              {t('new.trip.start.label')}
            </p>
            <div className={classes.controls}>
              <button
                className={classes.rejectBtn}
                onClick={() => {
                  setIsOpenNewTripModal(false);
                  timeoutWorker.clearTimeout(recursiveTimeoutRef.current);
                  timeoutWorker.clearTimeout(moveMarkerRef.current);
                  timeoutWorker.clearTimeout(goToPointRef.current);
                  timeoutWorker.clearTimeout(recursiveTrackingAfterFinished.current);
                }}
                data-testdid='button-reject'
              >
                {t('new.trip.modal.reject.label')}
              </button>
              <button
                className={classes.approveBtn}
                onClick={clearAllData}
                data-testdid='button-approve'
              >
                {t('new.trip.modal.approve.label')}
              </button>
            </div>
          </div>
        </div>
      )}

      <Toolbar />

      <div
        className={cx(classes.geoFencing, isSatellite && 'isSatellite')}
        role='presentation'
        onClick={() => setHasUserDrag(false)}
        data-testdid='button-geo'
      >
        <GeoFencingIcon />
      </div>
      <div className={classes.layerBtn}>
        <Layer isSatellite={isSatellite} onClick={toggleMapType} />
      </div>

      <BackButton
        isOpenNav={isOpenNav}
        href={RouteManager.path('dashboardMap', { hasLeadingSlash: true })}
      />
    </div>
  );
};

TripLive.defaultProps = {};

TripLive.propTypes = {};

export default TripLive;
