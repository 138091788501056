import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  linkBack: {
    position: 'absolute',
    top: 36,
    left: 140,
    backgroundColor: theme.palette.primary.light,
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: 10,
    fontWeight: 500,
    fontSize: 14,
    color: palette.custom.bluePrimary,
    boxShadow: '0px 2px 13px rgba(0, 0, 0, 0.18)',
    textDecoration: 'none',
    width: 50,
    height: 50,
    borderRadius: '50%',
    cursor: 'pointer',

    '&.isOpenNav': {
      left: 240,
    },

    '&.hasLabel': {
      padding: '10px 8px',
      borderRadius: 10,
      width: 'unset',
      height: 'unset',
    },
  },
}));

export default useStyles;
