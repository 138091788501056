export const blocks = ['bad', 'normal', 'good'];

export const markers = [
  {
    value: '∞',
    type: 'bad',
  },
  {
    value: '30',
    type: 'normal',
  },
  {
    value: '0',
    type: null,
  },
  {
    value: '30',
    type: 'good',
  },
];
