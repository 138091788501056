import queryString from 'query-string';

import { TSensorAlarmByTrip } from 'interfaces/sensor/t-sensor-alarm-by-trip';

import { checkSensorsAlarms } from 'utils/helpers/check-sensors-alarms';
import server from 'utils/server';

/**
 * Returns sensor alarms for a live trip.
 *
 * @param {string} vehicleId - The ID of the vehicle.
 * @param {number} timestamp - The timestamp for the start time of the trip.
 * @return {Promise<[...TSensorAlarmByTrip[], boolean]>} - A promise that resolves to an array containing the sensor alarms and a boolean value indicating whether there are any alarms.
 */
export const getSensorAlarmForLiveTripReq = async (
  vehicleId: string,
  timestamp: number,
): Promise<[...TSensorAlarmByTrip[], boolean]> => {
  const from = new Date(timestamp).toISOString();
  const params = {
    from,
  };

  return server.services.gpsService
    .get<TSensorAlarmByTrip[]>(`sensors/alarm/live/${vehicleId}?` + queryString.stringify(params))
    .then<[...TSensorAlarmByTrip[], boolean]>((res) => {
      const hasAlarm = checkSensorsAlarms(res.data);
      return [...res.data, hasAlarm];
    });
};
