import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '1440px',
    minHeight: 'calc(100vh - 83px)',
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
    padding: '133px 122px 33px',
    boxSizing: 'border-box',
  },

  containerInner: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },

  leftContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  title: {
    maxWidth: '360px',
    margin: 0,
    fontSize: '50px',
    lineHeight: '60px',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },

  descriptionContainer: {
    maxWidth: '600px',
    marginTop: '33px',
    fontSize: '24px',
    lineHeight: '38px',
    color: theme.palette.primary.main,
  },

  descriptionOrangeText: {
    color: palette.custom.goldSecondary,
  },

  actionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: '46px',
  },

  genInTouchButton: {
    height: 'fit-content',
    fontSize: '16px',
    lineHeight: '19px',
    padding: '16px 33px',
    marginRight: '36px',
    background: 'transparent',
    color: theme.palette.primary.main,
    textTransform: 'initial',
    outline: `1px solid ${palette.custom.goldSecondary}`,
    borderRadius: '7px',
  },

  playButton: {
    display: 'flex',
    flexDirection: 'row',
    background: 'transparent',
    color: theme.palette.primary.main,
  },

  playButtonLabel: {
    marginLeft: '19px',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 600,
    textTransform: 'initial',
  },

  applicationsWrap: {
    marginTop: 80,
    display: 'flex',
  },

  app: {
    '&:first-child': {
      marginRight: 10,
    },

    '&:hover path': {
      fill: theme.palette.primary.light,
    },

    '& path': {
      transition: 'all 0.3s ease',
    },
  },

  rightContainer: {
    width: '470px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '15px',
  },

  counterWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '42px',
  },

  counterIcon: {
    width: '38px',
  },

  counterLabel: {
    color: theme.palette.primary.main,
    fontSize: '16px',
    lineHeight: '19px',
    transform: 'rotateZ(-90deg)',
    opacity: 0.5,
  },
}));

export default useStyles;
