import RouteManager from 'utils/services/route-manager';

export const mapDefaultSetting = {
  center: {
    lat: 40.41122300644325,
    lng: 49.8667280377296,
  },
  zoom: 12,
};

export const countryMapCenter = {
  AZ: {
    lat: 40.41122300644325,
    lng: 49.8667280377296,
  },
  UK: {
    lat: 51.506807,
    lng: -0.128407,
  },
  US: {
    lat: 40.13835621768623,
    lng: -102.15224846913628,
  },
};
export const countryMapZoom = {
  AZ: 10,
  UK: 12,
  US: 5,
};

export const liveTripUrlRegExp = new RegExp(
  RouteManager.path('dashboardMap.live', { isFullPath: true }).replace(':id', '([^/]+)'),
);
