import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from '../../assets/theme';

const useStyles = makeStyles((theme) => ({
  nav: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 220,
    background: palette.custom.bluePrimary,
    boxShadow: `9px 4px 10px ${alpha(palette.custom.black, 0.07)}`,
    borderRadius: '0 60px 60px 0',
    zIndex: 10,
    transition: 'width 0.3s',
    overflow: 'auto',

    '&.withOpenSearch': {
      width: 330,
    },

    '&.minimized': {
      width: 108,

      '& $menuItem': {
        '& button': {
          minWidth: 'initial',
          width: 'auto',
        },

        '& svg': {
          marginRight: 0,
        },
      },

      '& $menuItemName': {
        display: 'none',
      },

      '& $name': {
        display: 'none',
      },

      '& $avatar': {
        marginRight: 0,
      },
    },
  },

  scrollbar: {
    paddingTop: 39,
  },

  logo: {
    alignSelf: 'flex-start',
    height: 23,
    marginBottom: 42,
    justifySelf: 'end',
    marginLeft: 44,
    marginTop: 'auto',
  },

  user: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 35,
  },

  avatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 35,
    minWidth: 35,
    height: 35,
    cursor: 'default',
    marginRight: 17,
  },

  name: {
    fontSize: 14,
    lineHeight: '16.71px',
    color: theme.palette.primary.dark,
    whiteSpace: 'nowrap',
  },

  menu: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 78,
    marginLeft: 35,
  },

  menuItem: {
    display: 'block',
    marginBottom: 27,
    fontSize: 16,
    lineHeight: '19px',

    '&.active': {
      fontSize: 17,
      fontWeight: 500,

      '& $menuItemName': {
        color: theme.palette.secondary.main,
        fontWeight: 500,
      },

      '& svg path': {
        stroke: `${theme.palette.secondary.main} !important`,
      },
    },

    '& svg': {
      width: 18,
      marginRight: 18,
    },

    '& button': {
      height: 36,
    },
  },

  menuItemName: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.primary.dark,
    whiteSpace: 'nowrap',
    textTransform: 'initial',
  },
}));

export default useStyles;
