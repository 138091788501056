import { selector } from 'recoil';

import notificationAppliedFiltersAtom from '../applied-filters';

const notificationAppliedFiltersCountAtom = selector<number>({
  key: 'notificationAppliedFiltersCountAtom',
  get: ({ get }) => {
    return Object.values(get(notificationAppliedFiltersAtom))
      .flat()
      .filter((item) => item).length;
  },
});

export default notificationAppliedFiltersCountAtom;
