import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    position: 'absolute',
    bottom: 23,
    right: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: palette.custom.white,
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
  },

  button: {
    width: 40,
    height: 40,
    backgroundColor: palette.custom.white,
    border: 'none',
    cursor: 'pointer',
    fontSize: 35,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgb(102, 102, 102)',

    '&:hover': {
      color: palette.custom.black,
    },

    '&:disabled': {
      color: 'rgb(209, 209, 209)',
    },
  },

  line: {
    height: 1,
    backgroundColor: 'rgb(230, 230, 230)',
    width: 30,
  },
}));

export default useStyles;
