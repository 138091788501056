import { ISelectItem } from 'interfaces/common/select-items';
import { DEVICES } from 'interfaces/vehicles/devices';

import server from 'utils/server';

/**
 * Retrieves the available device types for vehicles.
 *
 * @returns {Promise<ISelectItem[]>} A promise that resolves to an array of device types for vehicles, where each device type is represented by an object with a label and value.
 */
export const vehicleDeviceTypesReq = async (): Promise<ISelectItem[]> => {
  return server.services.beService
    .get<DEVICES[]>('vehicles/device/types')
    .then<ISelectItem[]>(({ data }) => data.map((item) => ({ label: item, value: item })));
};
