import { atom, selector } from 'recoil';

import recoilLocalStorageEffect from 'utils/recoil-localStorage-effect';

import settingPaymentCompaniesListPaginationSelector from '../pagination';

interface ISettingPaymentCompaniesListSearch {
  query: string;
}
export const initValues = { query: '' };

const settingPaymentCompaniesListSearchAtom = atom<ISettingPaymentCompaniesListSearch>({
  key: 'settingPaymentCompaniesListSearchAtom',
  default: initValues,
  effects_UNSTABLE: [recoilLocalStorageEffect('settingPaymentCompaniesListSearchAtom', initValues)],
});

const settingPaymentCompaniesListSearchSelector = selector<ISettingPaymentCompaniesListSearch>({
  key: 'settingPaymentCompaniesListSearchSelector',
  get: ({ get }) => {
    return get(settingPaymentCompaniesListSearchAtom);
  },
  set: ({ set, reset }, newValue) => {
    reset(settingPaymentCompaniesListPaginationSelector);
    set(settingPaymentCompaniesListSearchAtom, newValue);
  },
});

export default settingPaymentCompaniesListSearchSelector;
