import { selectorFamily } from 'recoil';

import { vehicleDeviceFunctionsReq } from 'requests/be-service/vehicle-controller/devices';

const vehicleDevicesFunctionsAtom = selectorFamily({
  key: 'vehicleDevicesFunctionsAtom',
  get:
    ({ deviceType }) =>
    async () => {
      const response = await vehicleDeviceFunctionsReq();
      return response[deviceType].map((item) => ({
        value: item,
        label: item,
      }));
    },
});

export default vehicleDevicesFunctionsAtom;
