/**
 * Converts a number to a string representation with a limit of -999 to 999.
 *
 * @param {number} value - The number to be converted.
 * @returns {string} The string representation of the number with a limit of -999 to 999.
 */
export const numberLimit = (value: number): string => {
  if (value < -999 || value > 999) {
    value = value / 1000;
    return `${value.toFixed(1)}K`;
  }
  return String(value);
};
