import Checkbox from '@mui/material/Checkbox';
import { StyledEngineProvider } from '@mui/material/styles';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';

import { ReactComponent as CheckedIcon } from 'assets/images/checked-icon.svg';
import { ReactComponent as ModalIcon } from 'assets/images/checked-modal.svg';

import useStyles from './styles';

const SingleCheckBox = forwardRef(
  (
    {
      size,
      disableRipple,
      label,
      outlined,
      onChange,
      checked,
      control,
      isLightBackground,
      isDisabled,
    },
    ref,
  ) => {
    const classes = useStyles({ isLightBackground });

    return (
      <div className={classes.wrapper} onChange={onChange} ref={ref}>
        <StyledEngineProvider injectFirst>
          <Checkbox
            control={control}
            checked={checked}
            disableRipple={disableRipple}
            disabled={isDisabled}
            classes={{ root: classes.root }}
            icon={
              <div
                className={cx(classes.icon, classes[size], { [classes.outlinedIcon]: outlined })}
              />
            }
            checkedIcon={
              <div
                className={cx(classes.icon, classes[size], { [classes.outlinedIcon]: outlined })}
              >
                {size === 'extra' ? <ModalIcon /> : <CheckedIcon />}
              </div>
            }
          />
        </StyledEngineProvider>
        {label && (
          <div
            className={cx(
              classes.label,
              { [classes.largeLabel]: size === 'large' },
              { [classes.extraLabel]: size === 'extra' },
              { [classes.smallLabel]: size === 'small' },
            )}
            title={label}
          >
            {label}
          </div>
        )}
      </div>
    );
  },
);

SingleCheckBox.displayName = 'SingleCheckBox';

const CheckBox = ({
  checked,
  size,
  disableRipple,
  name,
  control,
  label,
  outlined,
  onChange,
  isLightBackground,
  isDisabled,
}) => {
  if (!control) {
    return (
      <SingleCheckBox
        checked={checked}
        onChange={onChange}
        size={size}
        disableRipple={disableRipple}
        label={label}
        outlined={outlined}
        isLightBackground={isLightBackground}
        isDisabled={isDisabled}
      />
    );
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <SingleCheckBox
          {...field}
          control={control}
          checked={field.value}
          size={size}
          disableRipple={disableRipple}
          label={label}
          outlined={outlined}
          isLightBackground={isLightBackground}
          isDisabled={isDisabled}
        />
      )}
    />
  );
};

CheckBox.defaultProps = {
  size: 'large',
  checked: false,
  disableRipple: true,
  outlined: false,
  control: undefined,
  label: '',
  onChange: () => null,
  isLightBackground: false,
  isDisabled: false,
};

CheckBox.propTypes = {
  size: PropTypes.oneOf(['small', 'large', 'extra']),
  checked: PropTypes.bool,
  disableRipple: PropTypes.bool,
  outlined: PropTypes.bool,
  control: PropTypes.object,
  label: PropTypes.string,
  isLightBackground: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default React.memo(CheckBox);
