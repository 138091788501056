import { ISelectItem } from 'interfaces/common/select-items';
import { IVehicleModel } from 'interfaces/vehicles/model';

import server from 'utils/server';

/**
 * Retrieves vehicle models based on the given ID.
 * @param {number} id - The ID of the brand.
 * @returns {Promise<ISelectItem[]>} - An array of objects representing the vehicle models, each containing a label and a value.
 */
export const vehicleModelsReq = async (id: number): Promise<ISelectItem[]> => {
  return server.services.beService.get<IVehicleModel[]>(`brands/${id}`).then<ISelectItem[]>((res) =>
    res.data.map(({ id, name }) => ({
      value: id,
      label: name,
    })),
  );
};
