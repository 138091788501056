/**
 * Joins two arrays without duplicates based on given field.
 *
 * @template T - Type of the elements in the arrays
 * @param {Array<T>} A - The first array
 * @param {Array<T>} B - The second array
 * @param {keyof T} [field] - Optional field to consider for deduplication
 * @returns {Array<T>} - The joined array without duplicates
 */
export const joinArrayWithoutDupes = <T>(A: Array<T>, B: Array<T>, field?: keyof T): Array<T> => {
  const a = new Set(A.map((x) => (field ? x[field] : x)));
  return [...A, ...B.filter((x) => !a.has(field ? x[field] : x))];
};
