import { selectorFamily } from 'recoil';

import { ISettingPaymentCompany } from 'interfaces/company/setting-payment-company';

import { getSettingPaymentCompanyData } from 'requests/payment/company';

const settingPaymentCompanyAtom = selectorFamily<ISettingPaymentCompany, string>({
  key: 'settingPaymentCompanyAtom',
  get: (companyId: string) => async () => {
    return getSettingPaymentCompanyData(companyId);
  },
});
export default settingPaymentCompanyAtom;
