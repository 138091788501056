import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
  },

  item: {
    fontWeight: 500,
    fontSize: 14,
    color: palette.custom.blue600,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,

    '&:last-child': {
      marginBottom: 0,
      whiteSpace: 'nowrap',
    },
  },

  label: {
    marginRight: 5,
  },

  number: {
    color: palette.custom.blue700,
    textDecoration: 'none',

    '& span': {
      color: theme.palette.primary.light,
    },
  },

  btns: {
    marginLeft: 30,

    '&:empty': {
      display: 'none',
    },
  },

  btn: {
    width: 44,
    height: 44,
    backgroundColor: palette.custom.blue900,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    '&.IN_PROGRESS': {
      pointerEvents: 'none',

      '& svg': {
        animation: '$rotate 2s linear infinite',
      },
    },
  },

  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(359deg)',
    },
  },
}));

export default useStyles;
