import { selectorFamily } from 'recoil';

import { CURRENCY_SYMBOL } from 'interfaces/common/currency-symbol';

import userConfigAtom from '../../user-config';
import sybcompaniesListWithCurrentAtom from '../with-current-company';

const subcompaniesCurrencyIconAtom = selectorFamily<CURRENCY_SYMBOL, { companyId?: string | null }>(
  {
    key: 'subcompaniesCurrencyIconAtom',
    get:
      ({ companyId }) =>
      async ({ get }) => {
        const defaultCurrencyIcon = get(userConfigAtom).currencyIcon;

        if (!companyId) return defaultCurrencyIcon;

        const list = get(sybcompaniesListWithCurrentAtom);

        if (!list) return defaultCurrencyIcon;

        const subcompanyCurrency = list.find(({ id }) => companyId === id)?.localizationConfig
          .currency;

        if (!subcompanyCurrency) return defaultCurrencyIcon;

        return CURRENCY_SYMBOL[subcompanyCurrency];
      },
  },
);

export default subcompaniesCurrencyIconAtom;
