import { atom } from 'recoil';

import { IVehiclesListItem } from 'requests/be-service/vehicle-controller/get-vehicles';

const settingVehiclesListAtom = atom<IVehiclesListItem[]>({
  key: 'settingVehiclesListAtom',
  default: [],
});

export default settingVehiclesListAtom;
