import { CURRENCY_SYMBOL } from 'interfaces/common/currency-symbol';

import { numberLimit } from './number-limit';

/**
 * Formats the given count into a monetary value with currency symbol.
 *
 * @param {number} count - The count to be formatted.
 * @param {CURRENCY_SYMBOL} [currency=CURRENCY_SYMBOL.AZN] - The currency symbol to be used (optional).
 * @param {number} [fractionDigits] - The number of fraction digits to include (optional).
 * @returns {string} The formatted monetary value.
 */
export const formatMoney = (
  count: number,
  currency: CURRENCY_SYMBOL | null = CURRENCY_SYMBOL.AZN,
  fractionDigits?: number,
) => {
  if (!fractionDigits) {
    return `${count < 0 ? '-' : ''}${currency}${numberLimit(Math.abs(count))}`;
  }
  return `${count < 0 ? '-' : ''}${currency}${numberLimit(
    +Math.abs(count).toFixed(fractionDigits),
  )}`;
};
