import { atom } from 'recoil';

export interface ISettingsUsersFilter {
  userName?: string;
  email?: string;
  enabled?: null | boolean;
  query?: string;
}

const settingsUsersFilterAtom = atom<ISettingsUsersFilter>({
  key: 'settingsUsersFilterAtom',
  default: {
    userName: '',
    email: '',
    enabled: null,
    query: '',
  },
});

export default settingsUsersFilterAtom;
