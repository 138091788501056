import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    background: palette.custom.bluePrimary,
    boxShadow: '0 0 0 rgba(0, 0, 0, 0.4)',
    transition: 'box-shadow 300ms',
    zIndex: 100,
  },

  wrapperShadow: {
    boxShadow: '0 0 13px rgba(0, 0, 0, 0.4)',
  },

  container: {
    maxWidth: '1440px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0 auto',
    padding: '33px 122px',
    boxSizing: 'border-box',
  },

  rightContainer: {
    display: 'flex',
    flexDirection: 'row',
  },

  logo: {
    width: '136px',
    cursor: 'pointer',
  },

  loginButton: {
    padding: '16px 56px',
    color: palette.custom.bluePrimary,
    background: palette.custom.goldSecondary,
    borderRadius: '10px',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 600,
    textTransform: 'initial',

    '&:hover': {
      background: palette.custom.thirdOrange,
    },
  },
}));

export default useStyles;
