/**
 * Represents the supported currencies.
 *
 * @enum {string}
 * @readonly
 */
export enum CURRENCY {
  EUR = 'EUR',
  AZN = 'AZN',
  GBP = 'GBP',
  USD = 'USD',
}
