import { format } from 'date-fns';
import * as yup from 'yup';

import { COUNTRIES } from 'interfaces/common/countries';
import { CURRENCY } from 'interfaces/common/currency';
import { ILocalizationConfig } from 'interfaces/common/localization-config';
import { MEASUREMENT_SYSTEM } from 'interfaces/common/measurement';

export const formSchema = () => {
  return yup.object().shape({
    name: yup
      .string()
      .matches(/[\w ]*/g)
      .max(250)
      .required(),
    vehicleMonthlyPrice: yup.number().max(99.99).min(0).nullable(),
    companyType: yup.string().required(),
    parentId: yup.string().required(),
    localizationConfig: yup.object<ILocalizationConfig>().shape({
      measurement: yup.string<MEASUREMENT_SYSTEM>().required(),
      currency: yup.string<CURRENCY>(),
      country: yup.string<COUNTRIES>().required(),
    }),
    tin: yup.string().nullable().max(20),
    contractNumber: yup.string().nullable().max(50),
    contractDate: yup
      .string()
      .transform((value) => {
        return value ? format(new Date(value), 'yyyy-MM-dd') : null;
      })
      .nullable(),
    companyStructure: yup.string().required(),
    iconUrl: yup.string().nullable(),
    activeDriverCount: yup.number().required(),
    activeVehicleCount: yup.number().required(),
    useParentBalance: yup.boolean(),
  });
};
