import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    position: 'relative',
    width: 76,
    height: 40,
    borderRadius: 5,
    backgroundColor: palette.custom.blue900,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    transition: 'all ease 0.3s',

    '&.disabled': {
      opacity: 0.4,
      pointerEvents: 'none',
    },
  },

  hide: {
    width: 0,
    height: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
  },

  track: {
    width: 36,
    height: 36,
    borderRadius: 5,
    backgroundColor: palette.custom.softBlue,
    position: 'absolute',
    left: 2,
    transition: 'all ease 0.3s',

    '&.checked': {
      left: 38,
    },
  },

  icon: {
    width: 36,
    height: 36,
    position: 'absolute',
    left: 2,
    transition: 'all ease 0.3s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 400,
    color: palette.custom.blue300,

    '&.right': {
      left: 'unset',
      right: 2,
    },

    '&.hide': {
      opacity: 0.3,
    },
  },
}));

export default useStyles;
