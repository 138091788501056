import { atom } from 'recoil';

import { PENALTIES } from 'interfaces/map/penalties';

const activePenaltiesAtom = atom<PENALTIES[]>({
  key: 'activePenaltiesAtom',
  default: [],
});

export default activePenaltiesAtom;
