import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { USER_ROLES } from 'interfaces/user/roles';

import RouteManager from 'utils/services/route-manager';

import {
  settingPaymentCompaniesListFilterCountSelector,
  settingPaymentCompaniesListFilterSelector,
} from 'recoil/settings/payment/companies-list/filters';
import { settingPaymentCompaniesListAtom } from 'recoil/settings/payment/companies-list/list';
import settingPaymentCompaniesListPaginationSelector from 'recoil/settings/payment/companies-list/pagination';
import settingPaymentCompaniesListSearchSelector from 'recoil/settings/payment/companies-list/search';
import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';
import userDataAtom from 'recoil/userData';

import { getPaymentCompaniesListReq } from 'requests/payment/companies-list';

import ConditionalRenderWrapper from 'components/conditional-render-wrapper';
import Loader from 'components/Loader';
import Modal from 'components/modal';
import PaginationScroll from 'components/pagination-scroll';

import Header from '../../../components/header';

import Company from './company';
import Filters from './filters';
import useStyles from './styles';

const Finance = () => {
  const technicalMessageBlockHeight = useRecoilValue(technicalMessageBlockHeightSelector);
  const classes = useStyles({ technicalMessageBlockHeight });
  const { t } = useTranslation(['payment.page', 'pagination.page', 'setting.page']);

  const userData = useRecoilValue(userDataAtom);
  const filtersCount = useRecoilValue(settingPaymentCompaniesListFilterCountSelector);
  const filters = useRecoilValue(settingPaymentCompaniesListFilterSelector);
  const [page, setPage] = useRecoilState(settingPaymentCompaniesListPaginationSelector);
  const [totalElements, setTotalElements] = useState(0);
  const [searchValue, setSearchValue] = useRecoilState(settingPaymentCompaniesListSearchSelector);
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useRecoilState(settingPaymentCompaniesListAtom);

  /**
   * Fetches a list of payment companies.
   *
   * @callback getCompanies
   * @async
   * @returns {void}
   */
  const getCompanies = useCallback(async () => {
    try {
      if (page === 0) {
        setCompanies([]);
      }
      setIsLoading(true);
      const res = await getPaymentCompaniesListReq(page, filters, searchValue.query);
      setTotalElements(res.totalElements);
      setCompanies((prevState) => [...prevState, ...res.content]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, [filters, page, searchValue, setCompanies]);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  /**
   * A function that handles the action to load more content.
   *
   * @function
   * @name loadMoreHandler
   * @returns {void}
   */
  const loadMoreHandler = (): void => setPage((prevState) => prevState + 1);

  if (![USER_ROLES.TECH_ADMIN, USER_ROLES.ADMIN, USER_ROLES.OWNER].includes(userData!.role)) {
    return <Navigate to={RouteManager.makeURL('scoring')} />;
  }

  return (
    <ConditionalRenderWrapper
      condition={userData?.role === USER_ROLES.TECH_ADMIN}
      redirectPath={`${userData?.companyId}`}
      state={{ shodDoubleBack: true }}
    >
      <div>
        <Header
          backBtn={{ label: t('setting.page:payment.label') }}
          searchHandler={(data) => setSearchValue(data)}
          onCancelSearch={() => setSearchValue({ query: '' })}
          searchValue={searchValue.query}
          onClickFilter={() => setIsOpenFilters(true)}
          appliedFiltersCount={filtersCount}
        />

        <div className={classes.content}>
          {isLoading && <Loader width={150} isBlock preventClick lightMode />}

          <div className={classes.title}>{t('companies.list.label')}</div>

          <div className={classes.items}>
            {companies?.map((item) => {
              return (
                <Link
                  className={classes.item}
                  key={item.companyId}
                  to={`${item.companyId}`}
                  data-testid={`company-link-${item.companyId}`}
                >
                  <Company {...item} />
                </Link>
              );
            })}
          </div>

          <PaginationScroll
            count={companies.length}
            maxCount={totalElements}
            loadMoreHandler={loadMoreHandler}
          />
        </div>
      </div>

      <Modal open={isOpenFilters} onClose={() => setIsOpenFilters(false)}>
        <Filters onClose={() => setIsOpenFilters(false)} />
      </Modal>
    </ConditionalRenderWrapper>
  );
};

export default Finance;
