import { yupResolver } from '@hookform/resolvers/yup';
import { isAxiosError } from 'axios';
import cx from 'classnames';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useResetRecoilState,
} from 'recoil';

import { useCallbackPrompt } from 'hooks/useCallbackPromt';

import { ISelectItem, ISelectItemWithCompanyVehicePrice } from 'interfaces/common/select-items';
import { TSensorRenderData } from 'interfaces/sensor/t-sensor-render-data';
import { USER_ROLES } from 'interfaces/user/roles';
import { IUserData } from 'interfaces/user/user-data';
import { TVehicleEditData } from 'interfaces/vehicles/vehicle-edit-data';

import { ROLES_OBJECT } from 'constants/user';
import {
  COUNTRY_TYPES,
  DRIVE_TYPES,
  FUEL_TYPES_SELECT,
  GASOLINE_TYPES,
  VEHICLE_TYPES_SELECT,
} from 'constants/vehicle';

import { ReactComponent as ArrowBackIcon } from 'assets/images/arrow.svg';
import { ReactComponent as GeoIcon } from 'assets/images/geo-fencing.svg';
import { ReactComponent as PlusIcon } from 'assets/images/plus.svg';

import RouteManager from 'utils/services/route-manager';

import subcompaniesCurrencyIconAtom from 'recoil/subcompanies/currency-icon';
import subcompaniesReqGetAtom from 'recoil/subcompanies/request';
import subcompaniesSelectListAtom from 'recoil/subcompanies/select-list';
import userConfigAtom from 'recoil/user-config';
import userDataAtom from 'recoil/userData';
import vehicleDevicesFunctionsAtom from 'recoil/vehicles/device-functions';
import vehicleDirtySensorsAtom from 'recoil/vehicles/dirty-sensors';
import vehicleFilterAtom from 'recoil/vehicles/vehicleFilter';
import vehicleSensorsListAtom from 'recoil/vehicles/vehicleSensorsList';
import vehiclesPageDataAtom from 'recoil/vehicles/vehiclesPageData';

import { vehicleBrandsReq } from 'requests/be-service/brand-model-controller/find-brands';
import { vehicleModelsReq } from 'requests/be-service/brand-model-controller/find-models-by-brand';
import { getCompanyUsersReq } from 'requests/be-service/company-controller/get-company-users';
import { getAllActiveDriversReq } from 'requests/be-service/driver-controller/get-all-active-drivers';
import { deleteSensorReq } from 'requests/be-service/sensor-controller/delete-sensor';
import { getSensorListReq } from 'requests/be-service/sensor-controller/get-all-sensors-by-vehicle-id';
import { createSensorReq } from 'requests/be-service/sensor-controller/save-sensor';
import { updateSensorReq } from 'requests/be-service/sensor-controller/update-sensor';
import { vehicleDeviceTypesReq } from 'requests/be-service/vehicle-controller/find-device-types';
import { vehicleGetReq } from 'requests/be-service/vehicle-controller/get-vehicle-dto';
import { vehicleCreateReq } from 'requests/be-service/vehicle-controller/save-vehicle-v2';
import { vehicleEditReq } from 'requests/be-service/vehicle-controller/update-vehicle-v2';

import ColorPicker from 'components/form-v2/color-picker';
import PhoneInput from 'components/form-v2/custom-phone-input';
import Input from 'components/form-v2/input';
import Section from 'components/form-v2/section';
import Select from 'components/form-v2/select';
import Loader from 'components/Loader';
import Modal from 'components/modal';
import Sensor from 'components/sensor';
import { defaultValues as sensorDefaultValues } from 'components/sensor/form-schema';
import UnsavedModal from 'components/unsaved-modal';

import { defaultValues, formSchema } from './form-schema';
import useStyles from './styles';

const VehicleForm = () => {
  const { t } = useTranslation([
    'vehicle.details.page',
    'fields',
    'measurement.system.page',
    'sensor.service',
  ]);
  const { id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const { isMetricSystem } = useRecoilValue(userConfigAtom);

  const formMethods = useForm<TVehicleEditData>({
    resolver: yupResolver(formSchema(isMetricSystem)),
    defaultValues,
  });

  const userData = useRecoilValue(userDataAtom) as IUserData;
  const subcompaniesCurrencyIcon = useRecoilValueLoadable(
    subcompaniesCurrencyIconAtom({ companyId: formMethods.watch('generalInfo.companyId') }),
  );
  const [brands, setBrands] = useState<ISelectItem<number>[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [models, setModels] = useState<ISelectItem[] | null>(null);
  const [managers, setManagers] = useState<ISelectItem[] | null>(null);
  const [devices, setDevices] = useState<ISelectItem[] | null>(null);
  const [drivers, setDrivers] = useState<ISelectItem[] | null>(null);
  const resetVehiclesPageData = useResetRecoilState(vehiclesPageDataAtom);
  const vehicleDirtySensors = useRecoilValue(vehicleDirtySensorsAtom);
  const resetVehicleFilters = useResetRecoilState(vehicleFilterAtom);
  const [sensorsList, setSensorsList] = useRecoilState(vehicleSensorsListAtom);
  const resetSensorsList = useResetRecoilState(vehicleSensorsListAtom);
  const { contents: subcompaniesSelectList } = useRecoilValueLoadable(subcompaniesSelectListAtom);
  const { contents: vehicleDevicesFunctions } = useRecoilValueLoadable(
    vehicleDevicesFunctionsAtom({ deviceType: formMethods.watch('generalInfo.deviceType') }),
  );

  const hasUnsavedData = useMemo(() => {
    return (
      formMethods.formState.isDirty || Boolean(vehicleDirtySensors.filter((item) => item).length)
    );
  }, [formMethods.formState.isDirty, vehicleDirtySensors]);

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(hasUnsavedData);

  const refreshSubcompaniesList = useRecoilRefresher_UNSTABLE(subcompaniesReqGetAtom);

  const { enqueueSnackbar } = useSnackbar();

  const isSubcompanyView = Boolean(sessionStorage.getItem('subcompanyId'));
  const isActiveVehicle = formMethods.watch('generalInfo.active');

  /**
   * Get models for selected brand
   */
  const getModels = useCallback(async (brandId: number) => {
    try {
      const response = await vehicleModelsReq(brandId);

      setModels(response);
    } catch (e) {
      console.log(e);
    }
  }, []);

  /**
   * Show error message for backend errors
   */
  const showValidateError = useCallback(
    (errorMessage: string) => {
      switch (errorMessage) {
        case 'vehicle.duplicate.imei':
          formMethods.setError(
            'generalInfo.imei',
            { type: 'custom', message: 'Duplicate IMEI' },
            { shouldFocus: true },
          );
          enqueueSnackbar('Duplicate IMEI', { variant: 'error' });
          break;

        case 'vehicle.duplicate.platenumber':
          formMethods.setError(
            'generalInfo.plateNumber',
            { type: 'custom', message: 'Duplicate platenumber' },
            { shouldFocus: true },
          );
          enqueueSnackbar('Duplicate platenumber', { variant: 'error' });
          break;

        default:
          enqueueSnackbar('Saving error', { variant: 'error' });
          break;
      }
    },
    [enqueueSnackbar, formMethods],
  );

  /**
   * Get managers for selected subcompany
   */
  const getCompanyManagers = useCallback(async (companyId: string) => {
    try {
      const response = await getCompanyUsersReq(companyId);
      setManagers(
        response.map((manager) => ({
          value: manager.id,
          label: `${manager.firstName} ${manager.lastName}`,
        })),
      );
    } catch (e) {
      console.log(e);
    }
  }, []);

  /**
   * Get drivers for selected subcompany
   */
  const getCompanyDrivers = useCallback(async (companyId: string) => {
    try {
      const response = await getAllActiveDriversReq(companyId);
      setDrivers(
        response.map((driver) => ({
          value: driver.id,
          label: `${driver.firstName} ${driver.lastName}`,
        })),
      );
    } catch (e) {
      console.log(e);
    }
  }, []);

  /**
   * Get sensor list for current vehicle
   */
  const getSensorList = useCallback(async () => {
    if (!id) return;

    try {
      const res = await getSensorListReq(id);
      setSensorsList(res);
    } catch (e) {
      console.log(e);
    }
  }, [id, setSensorsList]);

  /**
   * Create new sensor and assign to vehicle
   * @type {(function(*): void)|*}
   */
  const createSensor = useCallback(
    async (data: TSensorRenderData) => {
      if (!id) return;

      try {
        setIsLoading(true);
        await createSensorReq(id, data);
        await getSensorList();
        enqueueSnackbar(`Sensor ${data.sensorName} was assign`, { variant: 'success' });
      } catch (e) {
        if (isAxiosError(e)) {
          if (e.response?.data.error === 'sensor.duplicate') {
            enqueueSnackbar('Duplicate sensor number', { variant: 'error' });
            return;
          }
          console.log(e);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [enqueueSnackbar, getSensorList, id],
  );
  /**
   * Delete sensor
   * @type {(function(*): void)|*}
   */
  const deleteSensor = useCallback(
    async (data: TSensorRenderData) => {
      if (!data.id) return;

      try {
        setIsLoading(true);
        await deleteSensorReq(data.id);
        await getSensorList();
        enqueueSnackbar(`Sensor ${data.sensorName} was deleted`, { variant: 'success' });
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [enqueueSnackbar, getSensorList],
  );

  const showDevice = useMemo(() => {
    if (!id) {
      return true;
    }

    return userData.role === ROLES_OBJECT.TECH_ADMIN;
  }, [id, userData.role]);

  /**
   * Get initial vehicle data for edit page
   */
  useEffect(() => {
    const getVehicleData = async () => {
      if (!id) return;

      try {
        setIsLoading(true);
        const response = await vehicleGetReq(id, isMetricSystem);
        getModels(response.generalInfo.brandId);
        getCompanyManagers(response.generalInfo.companyId);
        getCompanyDrivers(response.generalInfo.companyId);
        if (response.generalInfo.deviceFunctions?.includes('TEMPERATURE_HUMIDITY_SENSOR')) {
          getSensorList();
        }
        formMethods.reset(response);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      getVehicleData();
    }
  }, [
    formMethods,
    getCompanyDrivers,
    getCompanyManagers,
    getModels,
    getSensorList,
    id,
    isMetricSystem,
  ]);

  /**
   * Get sensor list for current vehicle if apply TEMPERATURE_HUMIDITY_SENSOR
   */
  useEffect(() => {
    if (!id) {
      return;
    }
    const subscription = formMethods.watch((value, { name }) => {
      if (name !== 'generalInfo.deviceFunctions') {
        return;
      }
      if (!value.generalInfo?.deviceFunctions?.includes('TEMPERATURE_HUMIDITY_SENSOR')) {
        setSensorsList([]);
        return;
      }
      getSensorList();
    });
    return () => subscription.unsubscribe();
  }, [formMethods, getSensorList, id, setSensorsList]);

  /**
   * Set default company
   */
  useEffect(() => {
    refreshSubcompaniesList();
    if (!id && userData.role === ROLES_OBJECT.MANAGER) {
      formMethods.setValue('generalInfo.companyId', userData.companyId);
      formMethods.setValue('generalInfo.managedById', userData.id);
      getCompanyManagers(userData.companyId);
      getCompanyDrivers(userData.companyId);
    }
  }, [
    formMethods,
    getCompanyDrivers,
    getCompanyManagers,
    id,
    refreshSubcompaniesList,
    userData.companyId,
    userData.id,
    userData.role,
  ]);

  /**
   * Get vehicle brands
   */
  useEffect(() => {
    const req = async () => {
      try {
        const response = await vehicleBrandsReq();
        setBrands(response);
      } catch (e) {
        console.log(e);
      }
    };

    req();
  }, []);

  /**
   * Get vehicle devices
   */
  useEffect(() => {
    const req = async () => {
      try {
        const response = await vehicleDeviceTypesReq();
        setDevices(response);
      } catch (e) {
        console.log(e);
      }
    };

    req();
  }, []);

  /**
   * Update vehicle data
   * @param data
   * @return {Promise<void>}
   */
  const updateVehicle = async (data: TVehicleEditData) => {
    if (!id) return;

    try {
      setIsLoading(true);
      for (const sensor of sensorsList) {
        if (!sensor.id) {
          continue;
        }
        await updateSensorReq(sensor.id, id, sensor);
      }

      await vehicleEditReq(id, data, isMetricSystem);
      const res = await vehicleGetReq(id, isMetricSystem);
      if (res.generalInfo.deviceFunctions?.includes('TEMPERATURE_HUMIDITY_SENSOR')) {
        getSensorList();
      }
      formMethods.reset(res);
      enqueueSnackbar('Saved', { variant: 'success' });
    } catch (e) {
      if (isAxiosError(e)) {
        showValidateError(e.response?.data.error);
      }
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Create new vehicle
   * @param data
   * @return {Promise<void>}
   */
  const saveVehicle = async (data: TVehicleEditData) => {
    try {
      setIsLoading(true);
      const res = await vehicleCreateReq(data, isMetricSystem);
      formMethods.reset(data);

      enqueueSnackbar('Saved', { variant: 'success' });
      navigate(RouteManager.makeURL('vehicles.edit', { id: res.id }));
    } catch (e) {
      if (isAxiosError(e)) {
        showValidateError(e.response?.data.error);
      }
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Show main map and current vehicle
   */
  const handleGeoFencing = useCallback(() => {
    if (id) {
      navigate(RouteManager.makeURL('dashboardMap.live', { id }));
    }
  }, [navigate, id]);

  /**
   * Clear vehicle filters and list when leave page
   */
  useEffect(() => {
    return () => {
      resetVehiclesPageData();
      resetSensorsList();
      if (!window.location.pathname.includes(RouteManager.makeURL('vehicles'))) {
        resetVehicleFilters();
      }
    };
  }, [resetSensorsList, resetVehicleFilters, resetVehiclesPageData]);

  /**
   * Update sensors list when sensor was changed
   */
  const addSensorHandler = useCallback(() => {
    if (!id) return;

    setSensorsList((prevState) => [
      ...prevState,
      {
        ...sensorDefaultValues(id),
      },
    ]);
  }, [setSensorsList, id]);

  const changeSensorDataHandler = useCallback(
    (index: number, data: TSensorRenderData) => {
      setSensorsList((prevState) => {
        const res = [...prevState];
        res[index] = data;
        return res;
      });
    },
    [setSensorsList],
  );

  if (
    userData.role === ROLES_OBJECT.OPERATOR ||
    (sessionStorage.getItem('subcompanyId') && !id) ||
    (!id && userData.role !== ROLES_OBJECT.TECH_ADMIN)
  ) {
    return <Navigate to={RouteManager.makeURL('vehicles')} />;
  }

  if (isLoading) {
    return <Loader width={150} lightMode preventClick />;
  }

  return (
    <div className={classes.wrap}>
      <div className={classes.header}>
        <div
          className={classes.btnBack}
          onClick={() => navigate(RouteManager.makeURL('settings.vehicles'))}
          data-testid='button-back'
        >
          <ArrowBackIcon />
        </div>
        <div className={classes.title}>{t('back.btn.label')}</div>

        <div className={classes.btnWrap}>
          {sensorsList.length < 4 &&
            id &&
            formMethods
              .watch('generalInfo.deviceFunctions')
              ?.includes('TEMPERATURE_HUMIDITY_SENSOR') && (
              <div
                className={classes.addSensorBtn}
                onClick={addSensorHandler}
                data-testid='button-add-sensor'
              >
                {t('sensor.service:add.sensor.label')} <PlusIcon />
              </div>
            )}
          {!isSubcompanyView && (
            <button
              type='submit'
              className={classes.btn}
              form='main-form'
              data-testid='button-save'
            >
              {t('add.btn.label')}
            </button>
          )}
        </div>
      </div>
      <FormProvider {...formMethods}>
        <form
          className={classes.form}
          onSubmit={formMethods.handleSubmit(id ? updateVehicle : saveVehicle)}
          id='main-form'
          data-testid='vehicle-form'
        >
          <Section title={t('vehicle.identity.label')}>
            <Input
              title={t('vin.label')}
              name='generalInfo.vin'
              placeholder={'Enter value'}
              disabled={isSubcompanyView || !isActiveVehicle}
            />
            <Select
              title={t('country.label')}
              name='generalInfo.country'
              items={COUNTRY_TYPES}
              disabled={isSubcompanyView || !isActiveVehicle}
            />

            <Input
              title={t('number.plate.label')}
              name='generalInfo.plateNumber'
              placeholder={'Enter value'}
              disabled={isSubcompanyView || !isActiveVehicle}
              textTransform='uppercase'
            />
            <Select
              title={t('make.label')}
              name='generalInfo.brandId'
              items={brands ?? []}
              additionalOnChange={(e) => {
                formMethods.resetField('generalInfo.modelId');
                getModels(+e.target.value);
              }}
              disabled={isSubcompanyView || !isActiveVehicle}
            />

            <Select
              title={t('model.label')}
              name='generalInfo.modelId'
              disabled={!models || isSubcompanyView || !isActiveVehicle}
              items={models ?? []}
            />
            <Input
              title={t('year.label')}
              name='generalInfo.year'
              type='number'
              placeholder={'Enter value'}
              disabled={isSubcompanyView || !isActiveVehicle}
            />

            <Select
              title={t('type.label')}
              name='generalInfo.type'
              items={VEHICLE_TYPES_SELECT.map((item) => ({
                ...item,
                label: t(`fields:${item.label}`),
              }))}
              disabled={isSubcompanyView || !isActiveVehicle}
            />
            <ColorPicker
              name='generalInfo.color'
              disabled={isSubcompanyView || !isActiveVehicle}
              subtitle={t('optional.label')}
              title={t('color.label')}
            />
          </Section>

          <Section title={t('assign.vehicle.label')}>
            <Select
              title={t('company.label')}
              name='generalInfo.companyId'
              items={Array.isArray(subcompaniesSelectList) ? subcompaniesSelectList : []}
              additionalOnChange={(e) => {
                formMethods.setValue('generalInfo.managedById', '');
                formMethods.setValue('generalInfo.driverId', '');
                formMethods.setValue('generalInfo.marketValue', null);
                formMethods.setValue(
                  'generalInfo.price',
                  subcompaniesSelectList.find(
                    (item: ISelectItemWithCompanyVehicePrice) => item.value === e.target.value,
                  ).vehiclePrice ?? defaultValues.generalInfo.price,
                );
                getCompanyManagers(e.target.value);
                getCompanyDrivers(e.target.value);
              }}
              disabled={
                ![ROLES_OBJECT.TECH_ADMIN, ROLES_OBJECT.ADMIN, ROLES_OBJECT.OWNER].includes(
                  userData.role,
                ) ||
                isSubcompanyView ||
                !isActiveVehicle
              }
            />
            <Select
              title={t('manager.label')}
              name='generalInfo.managedById'
              items={managers ?? []}
              disabled={
                !managers ||
                isSubcompanyView ||
                userData.role === ROLES_OBJECT.MANAGER ||
                !isActiveVehicle
              }
            />

            <Select
              title={t('assign.driver.label')}
              name='generalInfo.driverId'
              items={drivers ?? []}
              disabled={!drivers || isSubcompanyView || !isActiveVehicle}
              subtitle={t('optional.label')}
            />
          </Section>

          <Section title={t('vehicle.details.label')}>
            <Input
              title={t('market.value.label')}
              name='generalInfo.marketValue'
              type='number'
              placeholder={'Enter value'}
              endAdornment={
                typeof subcompaniesCurrencyIcon.contents === 'string'
                  ? subcompaniesCurrencyIcon.contents
                  : ''
              }
              disabled={isSubcompanyView || !isActiveVehicle}
            />
            <Input
              title={t('odometer.label')}
              name='generalInfo.odometer'
              placeholder={'Enter value'}
              type='number'
              endAdornment={t(`measurement.system.page:${isMetricSystem ? 'km' : 'mile'}.label`)}
              disabled={isSubcompanyView || !isActiveVehicle}
            />

            <Select
              title={t('drive.type.label')}
              name='detailsInfo.driverType'
              items={DRIVE_TYPES}
              disabled={isSubcompanyView || !isActiveVehicle}
            />
            <Input
              title={t('price.label')}
              name='generalInfo.price'
              placeholder={'Enter value'}
              type='text'
              disabled={
                isSubcompanyView || userData.role !== USER_ROLES.TECH_ADMIN || !isActiveVehicle
              }
              endAdornment={
                typeof subcompaniesCurrencyIcon.contents === 'string'
                  ? subcompaniesCurrencyIcon.contents
                  : ''
              }
            />
          </Section>

          <Section title={t('fuel.details.label')}>
            <Select
              title={t('fuel.type.label')}
              name='detailsInfo.fuelType'
              items={FUEL_TYPES_SELECT.map((item) => ({
                ...item,
                label: t(`fields:${item.label}`),
              }))}
              disabled={isSubcompanyView || !isActiveVehicle}
            />
            <Select
              title={t('fuel.supplied.label')}
              name='detailsInfo.gasolineType'
              items={GASOLINE_TYPES}
              disabled={
                formMethods.watch('detailsInfo.fuelType') !== 'GASOLINE' ||
                isSubcompanyView ||
                !isActiveVehicle
              }
            />

            <Input
              title={t('fuel.capacity.label')}
              name='detailsInfo.oilCapacity'
              type='number'
              placeholder={'Enter value'}
              endAdornment={t(
                `measurement.system.page:${isMetricSystem ? 'liters' : 'gallon'}.label`,
              )}
              disabled={isSubcompanyView || !isActiveVehicle}
            />
            <Input
              title={t('fuel.efficiency.label')}
              name='detailsInfo.fuelConsumptionCombined'
              subtitle={t('fuel.efficiency.comb.label')}
              type='number'
              placeholder={'Enter value'}
              endAdornment={t(
                `measurement.system.page:${
                  isMetricSystem ? 'liters.per.km' : 'mile.per.gal'
                }.label`,
              )}
              disabled={isSubcompanyView || !isActiveVehicle}
            />

            <Input
              title={t('fuel.efficiency.label')}
              name='detailsInfo.fuelConsumptionCity'
              subtitle={t('fuel.efficiency.city.label')}
              type='number'
              placeholder={'Enter value'}
              endAdornment={t(
                `measurement.system.page:${
                  isMetricSystem ? 'liters.per.km' : 'mile.per.gal'
                }.label`,
              )}
              disabled={isSubcompanyView || !isActiveVehicle}
            />
            <Input
              title={t('fuel.efficiency.label')}
              name='detailsInfo.fuelConsumptionHighWay'
              subtitle={t('fuel.efficiency.highway.label')}
              type='number'
              placeholder={'Enter value'}
              endAdornment={t(
                `measurement.system.page:${
                  isMetricSystem ? 'liters.per.km' : 'mile.per.gal'
                }.label`,
              )}
              disabled={isSubcompanyView || !isActiveVehicle}
            />
          </Section>

          <Section title={t('technical.details.label')}>
            <Input
              title={t('imei.label')}
              name='generalInfo.imei'
              placeholder={'Enter value'}
              disabled={
                (Boolean(id) && userData.role !== ROLES_OBJECT.TECH_ADMIN) ||
                isSubcompanyView ||
                !isActiveVehicle
              }
            />
            {userData.role === ROLES_OBJECT.TECH_ADMIN && (
              <Input
                title={t('iccid.label')}
                name='generalInfo.iccid'
                placeholder={'Enter value'}
                disabled={isSubcompanyView || !isActiveVehicle}
              />
            )}

            {showDevice && (
              <Select
                title={t('device.type.label')}
                name='generalInfo.deviceType'
                items={devices ?? []}
                disabled={isSubcompanyView || !isActiveVehicle}
                additionalOnChange={() => formMethods.setValue('generalInfo.deviceFunctions', [])}
              />
            )}

            {userData.role === ROLES_OBJECT.TECH_ADMIN && (
              <PhoneInput
                name={['generalInfo.devicePhonePrefix', 'generalInfo.devicePhoneNumber']}
                title={t('device.mobile.number.label')}
                disabled={isSubcompanyView || !isActiveVehicle}
              />
            )}

            {showDevice && (
              <Select
                title={t('additional.device.type.label')}
                name='generalInfo.deviceFunctions'
                items={Array.isArray(vehicleDevicesFunctions) ? vehicleDevicesFunctions : []}
                multiple
                disabled={
                  !formMethods.watch('generalInfo.deviceType') ||
                  isSubcompanyView ||
                  !isActiveVehicle
                }
              />
            )}

            <Input
              title={t('weight.label')}
              name='detailsInfo.weight'
              type='number'
              placeholder={'Enter value'}
              endAdornment={t(`measurement.system.page:${isMetricSystem ? 'kg' : 'lb'}.label`)}
              disabled={isSubcompanyView || !isActiveVehicle}
            />

            <Input
              title={t('max.permissible.mass.label')}
              subtitle={t('optional.label')}
              name='generalInfo.maxPermissibleMass'
              placeholder={'Enter value'}
              type='number'
              endAdornment={t(`measurement.system.page:${isMetricSystem ? 'kg' : 'lb'}.label`)}
              disabled={isSubcompanyView || !isActiveVehicle}
            />

            <Input
              title={t('engine.size.label')}
              subtitle={t('optional.label')}
              name='generalInfo.engineCapacity'
              type='number'
              placeholder={'Enter value'}
              endAdornment={t(
                `measurement.system.page:${isMetricSystem ? 'liters' : 'gallon'}.label`,
              )}
              disabled={isSubcompanyView || !isActiveVehicle}
            />
            <Input
              title={t('engine.power.label')}
              subtitle={t('optional.label')}
              name='detailsInfo.enginePower'
              type='number'
              placeholder={'Enter value'}
              endAdornment={t('measurement.system.page:hp.label')}
              disabled={isSubcompanyView || !isActiveVehicle}
            />
          </Section>

          {formMethods
            .watch('generalInfo.deviceFunctions')
            ?.includes('TEMPERATURE_HUMIDITY_SENSOR') &&
            id &&
            sensorsList.map((sensor, i) => {
              return (
                <Section contentClass={classes.sensorSection} key={`${sensor?.id}-${i}`}>
                  <Sensor
                    vehicleId={id}
                    sensorData={sensor}
                    changeSensorDataHandler={changeSensorDataHandler.bind(null, i)}
                    createSensor={createSensor}
                    deleteSensor={deleteSensor}
                    index={i}
                    disabled={!isActiveVehicle}
                  />
                </Section>
              );
            })}

          <Section title={t('additional.section.label')}>
            <Input
              title={t('maximum.speed.label')}
              subtitle={t('optional.label')}
              name='detailsInfo.maxSpeed'
              type='number'
              placeholder={'Enter value'}
              endAdornment={t(
                `measurement.system.page:${isMetricSystem ? 'km' : 'mile'}.per.h.label`,
              )}
              disabled={isSubcompanyView || !isActiveVehicle}
            />
            <Input
              title={t('maximum.distance.label')}
              subtitle={t('optional.label')}
              name='detailsInfo.maxDistance'
              placeholder={'Enter value'}
              type='number'
              endAdornment={t(
                `measurement.system.page:${isMetricSystem ? 'km' : 'mile'}.per.day.label`,
              )}
              disabled={isSubcompanyView || !isActiveVehicle}
            />

            {id && (
              <div className={classes.geo}>
                <div
                  className={cx(classes.geoIcon, {
                    disabled: !formMethods.watch('generalInfo.hasGpsPoints'),
                  })}
                  onClick={handleGeoFencing}
                  data-testid='button-geo'
                >
                  <GeoIcon />
                </div>
                <div>{t('geofencing.label')}</div>
              </div>
            )}
          </Section>
        </form>
      </FormProvider>

      <Modal open={showPrompt} onClose={cancelNavigation}>
        <UnsavedModal cancelNavigation={cancelNavigation} confirmNavigation={confirmNavigation} />
      </Modal>
    </div>
  );
};

VehicleForm.defaultProps = {};

VehicleForm.propTypes = {};

export default VehicleForm;
