import { DEVICE_FUNCTION } from 'interfaces/vehicles/device-functions';
import { DEVICES } from 'interfaces/vehicles/devices';

import server from 'utils/server';

type IDevices = {
  [key in DEVICES]: DEVICE_FUNCTION[];
};

export const vehicleDeviceFunctionsReq = async () => {
  return server.services.beService.get<IDevices>('vehicles/devices').then((res) => res.data);
};
