import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    position: 'absolute',
    top: 10,
    right: 125,
    display: 'flex',
    alignItems: 'flex-start',
    zIndex: 10,
    marginRight: 20,
    transition: 'all 0.3s ease',

    '&.isOpenDashboardSidebar': {
      right: 605,
    },
  },

  statuses: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 5,
    padding: 5,
    borderRadius: 14,
    backgroundColor: theme.palette.primary.light,
    boxShadow: '0px 16px 40px rgba(0, 0, 0, 0.12)',
    marginRight: 20,
  },

  status: {
    width: 40,
    height: 40,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.4,
    cursor: 'pointer',

    '&.isActiveFilter': {
      opacity: 1,
      backgroundColor: palette.custom.bluePrimary,

      '& path': {
        fill: theme.palette.primary.light,
        stroke: theme.palette.primary.light,
      },
      '& rect': {
        stroke: theme.palette.primary.light,
      },
    },
  },
}));

export default useStyles;
