import { selectorFamily } from 'recoil';

import { ITripPenaltyPoint } from 'interfaces/map/i-trip-penalty-point';
import { PENALTIES } from 'interfaces/map/penalties';

import activePenaltiesAtom from '../../penalties/active-penalties';
import penaltiesDataSelector from '../../penalties/data';

type TMapPenalties = ITripPenaltyPoint & {
  label: PENALTIES;
};

const mapPenaltiesSelector = selectorFamily<TMapPenalties[], string>({
  key: 'mapPenaltiesSelector',
  get:
    (tripId) =>
    ({ get }) => {
      const activePenalties = get(activePenaltiesAtom);
      const penaltiesData = get(penaltiesDataSelector(tripId));

      return activePenalties
        .map((penalty) => {
          return penaltiesData[penalty].map((point) => {
            return {
              ...point,
              label: penalty,
            };
          });
        })
        .flat();
    },
});

export default mapPenaltiesSelector;
