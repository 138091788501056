import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  vehicleInfo: {
    display: 'flex',
  },

  vehicleLittle: {
    marginLeft: '24px',
  },
  colorCircle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 65,
    height: 65,
    borderRadius: '50%',
    marginRight: '15px',
    backgroundColor: palette.custom.bluePrimary,
  },

  vehicle: {
    width: 35,
    height: 35,
  },

  regNumber: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 21,
    cursor: 'pointer',
  },

  regLittle: {
    marginLeft: 9,
  },

  brand: {
    marginTop: 8,
    fontWeight: 500,
    fontSize: 11,
    lineHeight: '12px',
    color: theme.palette.primary.main,
  },

  brandLittle: {
    marginTop: 6,
    fontWeight: 500,
    fontSize: 9,
    lineHeight: '8.6px',
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
