import axios from 'axios';

import { userDefaultDomain } from '../default-domain';

const routerService = axios.create({
  baseURL: `${userDefaultDomain}${process.env.REACT_APP_ROUTER_API}/`,
  headers: {
    'x-app-type': 'admin',
    accept: '*/*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Expose-Headers': 'Content-Disposition',
  },
});

export default routerService;
