import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },

  content: {
    width: 250,
    position: 'fixed',
    backgroundColor: palette.custom.lightBlue,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },

  inputWrap: {
    width: '100%',
    position: 'relative',
  },

  icon: {
    top: '50%',
    left: 9,
    transform: 'translateY(-50%)',
    position: 'absolute',

    '& path': {
      fill: palette.custom.blue600,
    },
  },

  input: {
    position: 'relative',
    zIndex: 50,
    padding: '7px 5px 7px 35px',
    width: '100%',
    backgroundColor: 'transparent',
    border: `1px solid ${palette.custom.mainLight}`,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    fontSize: 14,
    color: palette.custom.smokyWhite,
    fontFamily: 'SFPro',

    '&:focus': {
      outline: 'none',
    },
  },

  dataWrap: {
    width: 250,
    maxHeight: 260,
    backgroundColor: palette.custom.lightBlue,
  },

  noData: {
    paddingTop: 20,
    paddingBottom: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontFamily: 'SFPro',
    color: palette.custom.mainLight,
  },
}));

export default useStyles;
