import cx from 'classnames';
import React, { FC, useMemo } from 'react';
import Chart from 'react-apexcharts';
import CountUp from 'react-countup';

import { ReactComponent as TripIcon } from 'assets/images/route-vertical.svg';

import { getScoringColor } from 'utils/helpers/get-scoring-color';

import { createOptions, series } from './options';
import useStyles from './styles';

export interface IProps {
  score?: number;
  startAddress?: string;
  startTime?: string;
  finishAddress?: string;
  finishTime?: string;
}

const PathCard: FC<IProps> = ({
  finishAddress = '',
  finishTime = '',
  startTime = '',
  startAddress = '',
  score = 0,
}) => {
  const classes = useStyles();

  const countUp = useMemo(() => {
    if (!score) {
      return {
        suffix: '',
        countEnd: 0,
        decimals: 0,
      };
    }
    const isBigNumber = score < -999 || score > 999;

    return {
      suffix: isBigNumber ? 'K' : '',
      countEnd: isBigNumber ? Math.abs(score) / 1000 : Math.abs(score),
      decimals: isBigNumber ? 1 : 0,
    };
  }, [score]);

  const options = useMemo(() => {
    const color = getScoringColor(score);
    return createOptions(color, score);
  }, [score]);

  return (
    <div className={cx(classes.container)}>
      <div className={classes.trip}>
        <div className={classes.chartWrap} data-testid='chart'>
          <span
            className={cx(classes.counterLabel, score < -99900 && classes.counterMin)}
            style={{ color: getScoringColor(score) }}
            data-testid='totalScore'
          >
            <CountUp
              start={0}
              end={countUp.countEnd}
              decimals={countUp.decimals}
              suffix={countUp.suffix}
            />
          </span>
          <Chart
            className={cx(classes.chart, score < 0 && classes.chartMin)}
            options={options}
            type='radialBar'
            series={[series(score)]}
            height={170}
          />
        </div>

        <TripIcon className={classes.tripIcon} />

        <div>
          <div className={classes.point}>
            <div
              className={classes.name}
              title={startAddress ?? 'Not selected'}
              data-testid='startTrip'
            >
              {startAddress ?? 'Not selected'}
            </div>
            <div className={classes.time} data-testid='startTripTime'>
              {startTime ?? '-'}
            </div>
          </div>
          <div className={cx(classes.point, classes.endPoint)}>
            <div className={classes.name} title={finishAddress ?? '-'} data-testid='endTrip'>
              {finishAddress ?? 'Not selected'}
            </div>
            <div className={classes.time} data-testid='endTripTime'>
              {finishTime ?? '-'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PathCard;
