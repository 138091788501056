import InputBase from '@mui/material/InputBase';
import React, { ChangeEvent, FC } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

import { getDeepValue } from 'utils/helpers/get-object-deep-value';

import ErrorMessage from '../error-message';
import FieldLabel from '../field-label';

import useStyles from './styles';

interface IProps {
  name: string;
  title: string;
  disabled?: boolean;
  subtitle?: string;
  placeholder?: string;
  endAdornment?: string;
  additionalOnChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const ColorPicker: FC<IProps> = ({
  name,
  title,
  subtitle,
  placeholder,
  endAdornment,
  additionalOnChange,
  disabled = false,
}) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorData = getDeepValue<FieldError>(errors, name);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <div className={classes.wrap}>
            <FieldLabel
              htmlFor={field.name}
              error={!!errorData}
              title={title}
              subtitle={subtitle}
              disabled={disabled}
            />
            <div className={classes.inputContent}>
              <InputBase
                {...field}
                id={field.name}
                error={!!errorData}
                onChange={(e) => {
                  additionalOnChange?.(e);
                  field.onChange(e);
                }}
                classes={{
                  root: classes.inputWrap,
                  error: classes.inputWrapError,
                  focused: classes.inputWrapFocus,
                  disabled: classes.inputDisabled,
                  input: classes.input,
                }}
                disabled={disabled}
                placeholder={placeholder}
                endAdornment={
                  endAdornment && <span className={classes.endAdornment}>{endAdornment}</span>
                }
                inputProps={{
                  type: 'color',
                }}
              />
              {field.value && (
                <div className={classes.content}>
                  <div className={classes.colorBox} style={{ backgroundColor: field.value }} />
                  <div className={classes.textBox}>{field.value}</div>
                </div>
              )}
            </div>

            <ErrorMessage message={errorData?.message} />
          </div>
        );
      }}
    />
  );
};

export default ColorPicker;
