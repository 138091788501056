import { atom } from 'recoil';

import { ACTIVITY_STATUSES } from 'interfaces/common/activity-statuses';

interface ILiveTrackingPath {
  status: ACTIVITY_STATUSES;
  startTime: string;
  startAddress: string;
  finishTime?: string;
  finishAddress?: string;
  speed: number;
  angle: number;
}

const defaultData = {
  status: ACTIVITY_STATUSES.INACTIVE,
  startTime: '',
  startAddress: '',
  speed: 0,
  angle: 0,
};

const liveTrackingPathAtom = atom<ILiveTrackingPath>({
  key: 'liveTrackingPathAtom',
  default: defaultData,
});

export default liveTrackingPathAtom;
