// TODO: need change type of lang to LANGUAGES enum

import server from 'utils/server';

export interface ITranslateField {
  key: string;
  value: string;
}

/**
 * Retrieves translation for a specific page and language.
 *
 * @function getTranslationReq
 * @async
 * @param {string} page - The name of the page to retrieve translation for.
 * @param {string} lang - The language code.
 * @returns {Promise<ITranslateField[]>} - A promise that resolves with the translation data.
 */
export const getTranslationReq = async (page: string, lang: string): Promise<ITranslateField[]> =>
  server.services.translationService
    .get<ITranslateField[]>(`${page}?language=${lang}`)
    .then((res) => res.data);
