import queryString from 'query-string';

import { ACTIVITY_STATUSES } from 'interfaces/common/activity-statuses';
import { IMapSideBarItem } from 'interfaces/map/i-map-side-bar-item';
import { IResponseWrapper } from 'interfaces/server/response-wrapper';

import server from 'utils/server';

/**
 * Get list of vehicles for sidebar on dashboard map
 *
 * @param {ACTIVITY_STATUSES[]} status - An array of activity status values.
 * @param {number} [page=0] - The page number to retrieve (default is 0).
 * @param {string | null} [teamIds=null] - The team IDs to filter by (default is null).
 * @returns {Promise<IResponseWrapper<IMapSideBarItem[]>>} - A promise that resolves to an array of vehicle sidebar items.
 */
export const getVehiclesSidebarReq = async (
  status: ACTIVITY_STATUSES[],
  page: number = 0,
  teamIds: string | null = null,
): Promise<IResponseWrapper<IMapSideBarItem[]>> => {
  const params = {
    page,
    size: 100,
    status,
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
    teamIds,
  };

  return server.services.beService
    .get<IResponseWrapper<IMapSideBarItem[]>>('v2/map/list?' + queryString.stringify(params))
    .then((res) => res.data);
};
