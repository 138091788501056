import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  tabs: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },

  tab: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 18,
    fontWeight: 500,
    paddingTop: 15,
    paddingBottom: 15,
    borderRadius: '0 0 10px 10px',
    cursor: 'pointer',
    textDecoration: 'none',
    transition: 'all 0.3s ease',
    backgroundColor: palette.custom.borderDarkGrey,
    borderTop: `4px solid ${alpha(palette.custom.blue200, 0.2)}`,
    color: palette.custom.blue200,

    '&:first-child': {
      borderRadius: '0 0 10px 0',
    },

    '&:last-child': {
      borderRadius: '0 0 0 10px',
    },

    '&.isActive': {
      backgroundColor: 'unset',
      color: palette.custom.goldSecondary,
      borderTop: `4px solid ${palette.custom.goldSecondary}`,
    },
  },
}));

export default useStyles;
