import Badge from '@mui/material/Badge';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import cx from 'classnames';
import React, { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import useNotificationsWebSocket from 'hooks/useNotificationsWebSocket';

import { NOTIFICATION_NOT_READ_TYPES } from 'interfaces/notifications/notification-types';

import { ReactComponent as NotificationIcon } from 'assets/images/notification-icon.svg';

import { sumOfArrayElements } from 'utils/helpers/sum-array-elements';

import isOpenDashboardSidebarAtom from 'recoil/dashboard-map/sidebar/is-open';
import notificationsNotReadCountAtom from 'recoil/notifications/not-read-count';

import { notificationsCounterReq } from 'requests/notification-service/counter';

import NotificationsModal from './NotificationsModal';
import useStyles from './styles';

const Notifications = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const isDashboardMapPage = pathname.includes('dashboard-map');
  const isGeoPages = pathname.includes('geo');

  const [isOpenModal, setIsOpenModal] = useState(false);

  const [notificationsCounter, setNotificationsCounter] = useRecoilState(
    notificationsNotReadCountAtom,
  );
  const isOpenDashboardSidebar = useRecoilValue(isOpenDashboardSidebarAtom);

  const handleModal = useCallback(() => setIsOpenModal((prevIsOpen) => !prevIsOpen), []);
  const closeNotificationModalHandler = useCallback(() => setIsOpenModal(false), []);

  const getCounter = useCallback(async () => {
    try {
      const response = await notificationsCounterReq();

      const total = sumOfArrayElements(Object.values(response));
      setNotificationsCounter({
        ...response,
        [NOTIFICATION_NOT_READ_TYPES.TOTAL]: total,
      });
    } catch (error) {
      console.log(error);
    }
  }, [setNotificationsCounter]);

  useNotificationsWebSocket(getCounter);

  useEffect(() => {
    const req = async () => getCounter();
    req();
  }, [getCounter]);

  return (
    <div
      className={cx(classes.wrap, { isDashboardMapPage, isOpenDashboardSidebar, isGeoPages })}
      data-testid='notifications'
    >
      <IconButton
        onClick={handleModal}
        className={classes.notificationsButton}
        aria-label='fingerprint'
        color='primary'
        data-testid='notifications-button'
      >
        <Badge
          badgeContent={notificationsCounter.TOTAL}
          color='secondary'
          invisible={!notificationsCounter.TOTAL}
          classes={{ badge: classes.notificationsBadge }}
          data-testid='notifications-badge'
        >
          <NotificationIcon className={classes.notificationsButtonIcon} />
        </Badge>
      </IconButton>

      <Modal open={isOpenModal} onClose={closeNotificationModalHandler}>
        <DialogContent>
          <NotificationsModal
            getCounter={getCounter}
            closeNotificationModalHandler={closeNotificationModalHandler}
          />
        </DialogContent>
      </Modal>
    </div>
  );
};

export default Notifications;
