import cx from 'classnames';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { COUNTRIES } from 'interfaces/common/countries';
import { PENALTY_CARD } from 'interfaces/common/penalty-card';
import { ITripPenaltyPoint } from 'interfaces/map/i-trip-penalty-point';
import { PENALTIES } from 'interfaces/map/penalties';

import { ReactComponent as WarningIcon } from 'assets/images/warning.svg';

import driverTripAtom from 'recoil/driver/driverTrip';

import useStyles from './styles';

interface IProps {
  activePenalties: PENALTIES[];
  setActivePenalties: (data: PENALTIES[]) => void;
  penaltiesPoints: Record<PENALTIES, ITripPenaltyPoint[]> | null;
  country?: COUNTRIES;
}

const createData = (
  name: string,
  penaltiesCount: number | [PENALTY_CARD | null, number],
  key: PENALTIES,
) => ({
  name,
  penaltiesCount,
  key,
});

const Penalties: FC<IProps> = ({
  activePenalties,
  setActivePenalties,
  penaltiesPoints,
  country,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('trip.page');

  const [{ statistics }] = useRecoilState(driverTripAtom);

  /**
   * Set list of active penalties for rendering on map
   */
  const rowHandler = useCallback(
    (name: PENALTIES, isActive: boolean) => {
      if (isActive) {
        const newActivePenalties = activePenalties.filter((penalty) => penalty !== name);
        setActivePenalties(newActivePenalties);
        return;
      }

      if (!activePenalties) {
        setActivePenalties([name]);
        return;
      }

      setActivePenalties([...activePenalties, name]);
    },
    [activePenalties, setActivePenalties],
  );

  /**
   * Create penalties table data
   */
  const rows = useMemo(() => {
    if (!penaltiesPoints) {
      return;
    }
    const { SPEED_10, SPEED_20, ACCELERATION, BRAKING, CRASH, CORNERING } = penaltiesPoints;
    const total = Object.values(penaltiesPoints)?.flat()?.length ?? 0;
    return [
      createData(t('penalties.label'), total, PENALTIES.TOTAL),
      createData(t('low.speed.label'), SPEED_10.length, PENALTIES.SPEED_10),
      createData(t('high.speed.label'), SPEED_20.length, PENALTIES.SPEED_20),
      createData(t('acceleration.label'), ACCELERATION.length, PENALTIES.ACCELERATION),
      createData(t('braking.label'), BRAKING.length, PENALTIES.BRAKING),
      createData(t('cornering.label'), CORNERING.length, PENALTIES.CORNERING),
      createData(
        t('geofencing.label'),
        country !== COUNTRIES.AZ ? statistics.geofencing ?? 0 : 0,
        PENALTIES.GEOFENCING,
      ),
      createData(t('foul.label'), [statistics.penaltyCard, CRASH?.length], PENALTIES.CRASH),
    ];
  }, [penaltiesPoints, t, country, statistics.geofencing, statistics.penaltyCard]);

  return (
    <div className={classes.wrap} data-testid='penalties-wrap'>
      {rows?.map(({ name, penaltiesCount, key }) => {
        const isActive = activePenalties?.includes(key);
        const isTotal = key === PENALTIES.TOTAL;
        const isGeofencing = key === PENALTIES.GEOFENCING;
        const isCrash = key === PENALTIES.CRASH;

        return (
          <div
            className={cx(classes.row, {
              active: isActive,
              total: isTotal,
              geofencing: isGeofencing,
              foul: isCrash,
            })}
            key={name}
            onClick={() => rowHandler(key, isActive)}
            data-testid={`penalties-item-${key}`}
          >
            <div className={classes.label}>{name}</div>
            <div className={classes.count} data-testid='penalty-count'>
              {isCrash && typeof penaltiesCount !== 'number' ? (
                <>
                  {['RED', 'YELLOW'].includes(penaltiesCount[0] ?? '') && (
                    <div className={cx(classes.card, `${classes.card}--${penaltiesCount[0]}`)} />
                  )}

                  {penaltiesCount[1] ? <WarningIcon className={classes.warningIcon} /> : null}

                  {!['RED', 'YELLOW'].includes(penaltiesCount[0] ?? '') && !penaltiesCount[1] ? (
                    <div>-</div>
                  ) : null}
                </>
              ) : (
                <div data-testid='tableCell'>{penaltiesCount ?? '-'}</div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(Penalties);
