import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'absolute',
    top: 25,
    right: 25,
    display: 'flex',
    alignItems: 'center',

    '& svg:nth-child(2)': {
      margin: '0 20px',
    },
  },

  icon: {
    cursor: 'pointer',
  },
}));

export default useStyles;
