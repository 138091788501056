import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PauseIcon } from 'assets/images/pause.svg';
import { ReactComponent as PlayIcon } from 'assets/images/play.svg';

import { ANIMATION_STATUS } from '../use-route-animation';

import useStyles from './styles';

interface IProps {
  animationStatus: ANIMATION_STATUS;
}

const StartMarker: FC<IProps> = ({ animationStatus }) => {
  const classes = useStyles();
  const { t } = useTranslation('dashboard.map.page');

  return (
    <div className={classes.startWrap} data-testid='start-marker'>
      <div className={classes.startIcon}>
        {[ANIMATION_STATUS.STOP, ANIMATION_STATUS.PAUSE].includes(animationStatus) && (
          <PlayIcon className={classes.playIcon} />
        )}
        {animationStatus === ANIMATION_STATUS.PLAY && <PauseIcon />}
      </div>
      <div className={classes.startLabel}>{t('start.label')}</div>
    </div>
  );
};

export default React.memo(StartMarker);
