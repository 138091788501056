import { ApexOptions } from 'apexcharts';

import theme from 'assets/theme';

export const createOptions = (color: string): ApexOptions => {
  return {
    series: [75],
    chart: {
      type: 'radialBar',
      offsetY: 0,
      selection: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -126,
        endAngle: 126,
        offsetX: 0,
        offsetY: 0,
        hollow: {
          margin: 0,
          size: '65%',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
        },
        track: {
          strokeWidth: '100%',
          margin: 0,
          background: theme.palette.secondary.main,
          opacity: 0.24,
        },
        dataLabels: {
          show: false,
          name: {
            offsetY: -10,
            show: true,
            color: theme.palette.secondary.main,
            fontSize: '23px',
          },
          value: {
            formatter: function (val) {
              return String(parseInt(String(val), 10));
            },
            color: theme.palette.secondary.main,
            fontSize: '48px',
            show: true,
          },
        },
      },
    },
    fill: {
      type: 'solid',
      colors: [color],
    },
    stroke: {
      lineCap: 'round',
    },
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    labels: ['Percent'],
    grid: {
      padding: {
        top: -10,
        bottom: 10,
      },
    },
  };
};
