import { selectorFamily } from 'recoil';

import { getSettingPaymentCompanyData } from 'requests/payment/get-active-invoices';

import settingPaymentCompanyAtom from '../company';

// TODO: need change to ISelectItem[];
const invoicesIdsAtom = selectorFamily<{ value: string; label: string }[], string>({
  key: 'settingPaymentCompanyAtom',
  get:
    (companyId: string) =>
    async ({ get }) => {
      const companyData = get(settingPaymentCompanyAtom(companyId));

      const data = await getSettingPaymentCompanyData(companyData.balanceId);

      return Object.entries(data)?.map((item) => {
        return {
          value: item[1],
          label: item[0],
        };
      });
    },
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
});
export default invoicesIdsAtom;
