import { selector } from 'recoil';

import userDataAtom from 'recoil/userData';

import LINKS from './data';

const settingsPagesLinksAtom = selector({
  key: 'settingsPagesLinksAtom',
  get: ({ get }) => {
    const userRole = get(userDataAtom)?.role;

    if (!userRole) return [];

    return LINKS(userRole).filter(({ availableRoles }) => availableRoles.includes(userRole));
  },
});

export default settingsPagesLinksAtom;
