import * as yup from 'yup';

export type TFormValues = {
  userName: string;
  password: string;
};

export const formSchema = yup.object().shape({
  userName: yup.string().required(),
  password: yup.string().required(),
});
