import cx from 'classnames';
import { FC } from 'react';

import useStyles from './styles';

interface IProps {
  count: number;
  className?: string;
  withOutColor?: boolean;
}

export const Money: FC<IProps> = ({ count, className, withOutColor = false }) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.money, className)} data-testid='money-wrap'>
      ₼{' '}
      <span
        className={cx(classes.count, count <= 0 && 'isWarning', { withOutColor })}
        data-testid='money-count'
      >
        {count}
      </span>
    </div>
  );
};
