import { AxiosError } from 'axios';

import beService from './be-service';
import { userDefaultDomain } from './default-domain';
import geoService from './geo-service';
import gpsService from './gps-service';
import mobileService from './mobile-service';
import notificationService from './notification-service';
import paymentService from './payment-service';
import reportService from './report-service';
import routerService from './router-service';
import translationService from './translation-service';

type TServices =
  | 'routerService'
  | 'beService'
  | 'gpsService'
  | 'geoService'
  | 'mobileService'
  | 'notificationService'
  | 'reportService'
  | 'paymentService'
  | 'translationService';

class Server {
  services = {
    routerService,
    beService,
    gpsService,
    mobileService,
    notificationService,
    reportService,
    translationService,
    paymentService,
    geoService,
  } as const;
  constructor() {
    (Object.keys(this.services) as TServices[]).forEach((key) => {
      this.services[key].interceptors.response.use(
        (res) => {
          return res;
        },
        (error: AxiosError) => {
          if (error.response?.status === 401) {
            localStorage.clear();
            sessionStorage.clear();
            if (window.location.pathname !== '/login') {
              window.location.href = '/login';
            }
            throw error;
          }
          throw error;
        },
      );
    });
  }

  setDefaultBaseDomain = (domain: string) => {
    (Object.keys(this.services) as TServices[]).forEach((key) => {
      this.services[key].defaults.baseURL = this.services[key].defaults.baseURL!.replace(
        userDefaultDomain,
        domain,
      );
    });

    return this;
  };

  setToken = (token: string) => {
    (Object.keys(this.services) as TServices[]).forEach((key) => {
      this.services[key].defaults.headers.common['Authorization'] = `Bearer ${token}`;
    });

    return this;
  };
}

export default new Server();
