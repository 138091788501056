import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    position: 'absolute',
    padding: 20,
    width: 350,
    top: 72,
    right: 25,
    backgroundColor: palette.custom.bluePrimary,
    borderRadius: 10,
    boxShadow: '0px 16px 40px rgba(0, 0, 0, 0.12)',
    fontFamily: 'SFPro',
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
  },

  title: {
    fontWeight: 500,
    fontSize: 18,
    color: palette.custom.blue200,
  },

  closeIcon: {
    cursor: 'pointer',
    width: 9,
    height: 9,
  },

  dateWrap: {
    display: 'grid',
    gridTemplateColumns: '150px 150px',
    gridColumnGap: 8,
    marginBottom: 25,
  },

  finishDate: {
    marginTop: 19,
  },

  filters: {
    marginTop: 10,
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 10,
    rowGap: 10,
  },

  btns: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: 16,
    fontWeight: 500,
  },

  clearBtn: {
    cursor: 'pointer',
    color: palette.custom.blue200,
    backgroundColor: 'transparent',
    border: 'none',
  },

  submitBtn: {
    cursor: 'pointer',
    width: 152,
    height: 43,
    backgroundColor: palette.custom.goldSecondary,
    borderRadius: 10,
    border: 'none',
    marginLeft: 55,
    color: palette.custom.bluePrimary,
  },
}));

export default useStyles;
