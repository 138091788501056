import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingInline: 50,
    paddingTop: 15,
    paddingBottom: 25,
  },

  content: {
    display: 'flex',
    flex: 1,
    columnGap: 35,
    marginTop: 30,
  },

  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 290,
    width: '100%',
  },

  reportBtn: {
    position: 'absolute',
    top: 15,
    right: 25,
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
