import React, { FC } from 'react';

import Input from 'components/form-v2/input';

import useStyles from './styles';

interface IProps {
  name: string;
  title: string;
  disabled: boolean;
}

const Pin: FC<IProps> = ({ name, title, disabled }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrap}>
      <Input title={title} name={name} disabled={disabled} />
    </div>
  );
};

export default Pin;
