import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    width: 30,
    height: 30,
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.primary.light,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    border: `1px solid ${palette.custom.red900}`,
  },

  icon: {
    '& path': {
      stroke: palette.custom.red900,
    },

    '& circle': {
      fill: palette.custom.red900,
    },
  },
}));

export default useStyles;
