import cx from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PlayIcon } from 'assets/images/play.svg';
import { ReactComponent as MarkerIcon } from 'assets/images/start-point-map.svg';

import useStyles from './styles';

interface IProps {
  number: number | null;
  active?: number;
  setActivePoints: (data: number) => void;
}

const StartMarker: FC<IProps> = ({ number, active, setActivePoints }) => {
  const classes = useStyles();
  const { t } = useTranslation('dashboard.map.page');

  if (!number && number !== 0) {
    return (
      <div className={classes.startWrap} data-testid='start-marker'>
        <div className={classes.startIcon}>
          <PlayIcon />
        </div>
        <div className={classes.startLabel}>{t('start.label')}</div>
      </div>
    );
  }

  return (
    <div
      onClick={() => setActivePoints(number)}
      className={cx(classes.markerNumber, active === number && classes.markerActive)}
      data-testid='start-marker'
    >
      <div className={classes.number}>{number + 1}</div>
      <MarkerIcon />
    </div>
  );
};

export default React.memo(StartMarker);
