import { IConvertedSensorsAlarms } from 'interfaces/sensor/i-converted-sensors-alarms';
import { TSensorAlarmByTrip } from 'interfaces/sensor/t-sensor-alarm-by-trip';

/**
 * Convert sensors alarms list from API
 */
export const convertSensorsAlarms = (data: TSensorAlarmByTrip[]): IConvertedSensorsAlarms => {
  const res = {
    HUMIDITY: [],
    MOVEMENT: [],
    TEMPERATURE: [],
  } as IConvertedSensorsAlarms;
  data.forEach((point) => {
    if (point.humidity.active && point.humidity.alarm) {
      res.HUMIDITY.push({
        ...point.gpsPoint,
        ...point.humidity,
      });
    }
    if (point.movement.active && point.movement.alarm) {
      res.MOVEMENT.push({
        ...point.gpsPoint,
        ...point.movement,
      });
    }
    if (point.temperature.active && point.temperature.alarm) {
      res.TEMPERATURE.push({
        ...point.gpsPoint,
        ...point.temperature,
      });
    }
  });

  return res;
};
