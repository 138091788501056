import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  btn: {
    position: 'relative',
    cursor: 'pointer',
    maxWidth: 845,
    width: '100%',
    fontFamily: 'SFPro',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '19px',
    border: '1px solid transparent',
    padding: 13,
    borderRadius: 10,
    transition: 'all 0.3s ease',

    '&:disabled': {
      pointerEvents: 'none',
      opacity: 0.4,
    },

    '&.fill': {
      backgroundColor: palette.custom.goldSecondary,
      border: `1px solid ${palette.custom.goldSecondary}`,
      color: palette.custom.bluePrimary,

      '&:hover': {
        backgroundColor: palette.custom.goldLight,
      },
    },

    '&.outline': {
      border: `1px solid ${palette.custom.goldSecondary}`,
      color: palette.custom.goldSecondary,
      backgroundColor: 'transparent',

      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.dark, 0.15),
      },
    },

    '&.default': {
      color: palette.custom.goldSecondary,
      backgroundColor: 'transparent',

      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.dark, 0.15),
      },
    },
  },
}));

export default useStyles;
