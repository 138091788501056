import React, { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { TDataWithId } from 'interfaces/common/data-with-id';
import { TSensorRenderData } from 'interfaces/sensor/t-sensor-render-data';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { ReactComponent as SettingIcon } from 'assets/images/gear.svg';

import RouteManager from 'utils/services/route-manager';

import vehicleSensorsListAtom from 'recoil/vehicles/vehicleSensorsList';

import { IGetDriversItem } from 'requests/be-service/driver-controller/get-drivers';
import { getSensorListReq } from 'requests/be-service/sensor-controller/get-all-sensors-by-vehicle-id';

import Loader from 'components/Loader';
import PlateNumber from 'components/plate-number';

import Sensor from '../sensor';

import useStyles from './styles';

interface IProps {
  driverId: string;
  onClose: () => void;
  vehicle: IGetDriversItem['vehicle'];
}

const QuickDriverEdit: FC<IProps> = ({ vehicle, onClose, driverId }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const isSubcompanyView = Boolean(sessionStorage.getItem('subcompanyId'));

  const [sensorsList, setSensorsList] = useRecoilState(vehicleSensorsListAtom);
  const resetSensorsList = useResetRecoilState(vehicleSensorsListAtom);
  const [isLoading, setIsLoading] = useState(false);

  const editHandler = useCallback(() => {
    navigate(RouteManager.makeURL('drivers.edit', { id: driverId }));
  }, [navigate, driverId]);

  /**
   * Get sensor list for current vehicle
   */
  const getSensorList = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await getSensorListReq(vehicle?.id);
      setSensorsList(res);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, [vehicle?.id, setSensorsList]);

  useEffect(() => {
    getSensorList();

    return resetSensorsList;
  }, [getSensorList, resetSensorsList]);

  return (
    <div data-testid='driver-quick-edit-wrap'>
      {isLoading && <Loader lightMode width={150} isBlock preventClick />}

      <div className={classes.header}>
        <PlateNumber country={vehicle?.country} plateNumber={vehicle?.plateNumber} />

        <div className={classes.btns}>
          <div className={classes.btn} onClick={editHandler} data-testid='button-setting'>
            <SettingIcon width={20} />
          </div>
          <div className={classes.btn} onClick={onClose} data-testid='button-close'>
            <CloseIcon width={12} />
          </div>
        </div>

        <div className={classes.shadow} />
      </div>

      <div className={classes.list}>
        {!sensorsList.length && !isLoading && (
          <div className={classes.emptyMessage}>Selected vehicle doesn't have any sensors</div>
        )}

        {sensorsList.map((sensor) => (
          <Sensor
            key={sensor.id}
            data={sensor as TDataWithId<TSensorRenderData>}
            disabled={isSubcompanyView}
          />
        ))}
      </div>
    </div>
  );
};

export default QuickDriverEdit;
