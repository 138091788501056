import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  scoring: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    maxWidth: '1220px',
    height: '100%',
    minHeight: '787px',
    margin: '0 auto',
    padding: '39px 26px 39px 19px',
    overflow: 'hidden',
  },

  charts: {
    display: 'flex',
    flexDirection: 'column',
    width: '744px',
    minWidth: '500px',
    marginRight: '19px',
  },

  trio: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '111px',
    minHeight: '111px',
    marginBottom: '17px',

    '& > div': {
      width: 'calc(33.33% - 8px)',
      height: '100%',
    },
  },

  duo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '274px',
    minHeight: '274px',
    marginBottom: '17px',

    '& > div': {
      width: 'calc(50% - 9px)',
      height: '100%',
    },
  },

  solo: {
    flex: 1,
    minHeight: 290,
  },

  stats: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },

  statsOne: {
    height: '111px',
    minHeight: '111px',
    marginBottom: '17px',
  },

  statsTwo: {
    flex: 1,
    minHeight: 581,
  },
}));

export default useStyles;
