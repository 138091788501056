import React, { ChangeEvent, ComponentType, FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Switch from '../switch';

interface IProps {
  name: string;
  disabled?: boolean;
  additionalOnChange?: (e: ChangeEvent) => void;
  RightIcon?: ComponentType;
  LeftIcon?: ComponentType;
}

const SwitchWithController: FC<IProps> = ({
  name,
  LeftIcon,
  RightIcon,
  additionalOnChange,
  disabled = false,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Switch
            value={field.value}
            onChange={(e) => {
              field.onChange(e);
              additionalOnChange?.(e);
            }}
            disabled={disabled}
            LeftIcon={LeftIcon}
            RightIcon={RightIcon}
          />
        );
      }}
    />
  );
};

export default SwitchWithController;
