import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    '&.hasReset': {
      justifyContent: 'space-between',
    },
  },

  icons: {
    display: 'flex',
    alignItems: 'center',
  },

  reset: {
    width: 'unset',
    paddingInline: 20,
  },
}));

export default useStyles;
