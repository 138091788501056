const penaltyLabels = {
  SPEED_10: 'SPEED_10',
  SPEED_20: 'SPEED_20',
  ACCELERATION: 'ACCELERATION',
  BRAKING: 'BRAKING',
  CORNERING: 'CORNERING',
  CRASH: 'CRASH',
};

export default penaltyLabels;
