import server from 'utils/server';
import { TRANSLATION_SERVICE_LANG } from 'utils/TranslationsService';

interface IUpdateTranslationField {
  key: string;
  [TRANSLATION_SERVICE_LANG.translationAz]: string;
  [TRANSLATION_SERVICE_LANG.translationEn]: string;
}

interface IUpdateTranslationFieldResponse {
  key: string;
  [TRANSLATION_SERVICE_LANG.translationAz]: string | null;
  [TRANSLATION_SERVICE_LANG.translationEn]: string | null;
}

/**
 * Updates translations for a specific page.
 *
 * @async
 * @param {string} page - The page to update translations for.
 * @param {IUpdateTranslationField[]} body - An array of translation fields to update.
 * @returns {Promise<IUpdateTranslationFieldResponse[]>} - A promise that resolves with the updated translation field responses.
 */
export const updateTranslationReq = async (
  page: string,
  body: IUpdateTranslationField[],
): Promise<IUpdateTranslationFieldResponse[]> =>
  server.services.translationService
    .put<IUpdateTranslationFieldResponse[]>(page, body)
    .then((res) => res.data);
