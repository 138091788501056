import { atom } from 'recoil';

import recoilLocalStorageEffect from 'utils/recoil-localStorage-effect';

const initValues = {
  companyIds: null,
  companyName: null,
  teamIds: null,
  condition: null,
  createdAtFrom: null,
  createdAtTill: null,
  vehicleTypes: [],
  query: null,
  userIds: null,
  userNames: null,
  page: 0,
  sort: { sort: 'createdAt', direction: 'DESC' },
  hasInitFilters: false,
};

const vehicleFilterAtom = atom({
  key: 'vehicleFilterAtom',
  default: initValues,
  effects_UNSTABLE: [recoilLocalStorageEffect('vehicleFilterAtom', initValues)],
});

export default vehicleFilterAtom;
