import { atom, selector } from 'recoil';

const settingPaymentTransactionsListPaginationAtom = atom<number>({
  key: 'settingPaymentTransactionsListPaginationAtom',
  default: 0,
});

const settingPaymentTransactionsListPaginationSelector = selector<number>({
  key: 'settingPaymentTransactionsListPaginationSelector',
  get: ({ get }) => {
    return get(settingPaymentTransactionsListPaginationAtom);
  },
  set: ({ set }, newValue) => {
    set(settingPaymentTransactionsListPaginationAtom, newValue);
  },
});

export default settingPaymentTransactionsListPaginationSelector;
