import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  reconstruction: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: '25px',
  },

  crush: {
    fontWeight: 600,
    fontSize: 21,
    lineHeight: '25px',
    color: palette.custom.gray,
    paddingBottom: '35.66px',
  },

  crashData: {
    marginTop: 40,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 55,
  },

  crashItems: {
    borderTop: `1px solid ${palette.custom.gray}`,
  },

  crashItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '405px',
    fontSize: '17.5149px',
    paddingTop: '7px',
    lineHeight: '21px',
    fontWeight: '300',
    color: theme.palette.primary.main,
  },

  crashKey: {
    fontSize: '17.5149px',
    fontFamily: 'SFText',
    width: '250px',
    fontWeight: '600',
    lineHeight: '21px',
  },

  severity: {
    marginLeft: 50,
    display: 'flex',
    fontFamily: 'SFText',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: 300,
  },

  block: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 190,
  },

  severityRating: {
    display: 'flex',
    width: 190,
    justifyContent: 'space-between',
    paddingBottom: 18,
  },

  severityInfo: {
    fontSize: 24,
    lineHeight: '29px',
    fontWeight: 300,
    fontFamily: 'SFText',
    color: theme.palette.primary.main,
  },

  severityId: {
    color: theme.palette.primary.main,
    fontWeight: 300,
    fontSize: 24,
    lineHeight: '29px',
    fontFamily: 'SFText',
  },

  rating: {
    position: 'relative',
    width: '185px',
  },

  ratingBody: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    overflow: 'hidden',
    top: 0,

    '& > div': {
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },

  ratingActive: {
    width: ({ ratingSeverity }) => `${ratingSeverity}%`,
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-end',
    overflow: 'hidden',
    top: 0,

    '& > div': {
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },

  ratingUp: {
    marginLeft: '4px',
    minWidth: '14.72px',
    backgroundColor: palette.custom.grayDark,
    borderRadius: '2px',
  },

  ratingButton: {
    marginLeft: '4px',
    minWidth: '14.72px',
    backgroundColor: palette.custom.errorRed,
    borderRadius: '2px',
  },

  item0: {
    height: 7.44,
  },

  item1: {
    height: 11.17,
  },

  item2: {
    height: 14.89,
  },

  item3: {
    height: 18.62,
  },

  item4: {
    height: 22.34,
  },

  item5: {
    height: 26.07,
  },

  item6: {
    height: 29.79,
  },

  item7: {
    height: 33.51,
  },

  item8: {
    height: 37.24,
  },

  item9: {
    height: 40.96,
  },
}));

export default useStyles;
