import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    display: 'flex',
    '&.isParked': {
      display: 'flex',
      alignItems: 'flex-start',
      columnGap: 8,
    },
  },

  addresses: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    columnGap: 22,
  },

  point: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: 14,
    lineHeight: '17px',
  },

  time: {
    fontWeight: 400,
    color: palette.custom.blue500,
  },

  address: {
    width: 200,
    fontWeight: 500,
    color: palette.custom.blue200,
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',

    '&.isParked': {
      fontSize: 16,
    },

    '&.isFinish': {
      marginTop: 24,
    },
  },
}));

export default useStyles;
