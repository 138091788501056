import React, { useEffect, useMemo, useState } from 'react';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { ReactComponent as InfoIcon } from 'assets/images/info.svg';

import scoringChartScoreAtom from 'recoil/scoring/scoringChartScore';

import { scoringChartReq } from 'requests/be-service/scoring-controller/get-total-scoring';

import DiffScore from 'components/diff-score';
import Loader from 'components/Loader';

import { descriptionData } from './data';
import Info from './info';
import useStyles from './styles';

const ScoringChart = () => {
  const { t } = useTranslation('scoring.page');

  const [isLoading, setIsLoading] = useState(false);
  const [scoringChart, setScoringChart] = useRecoilState(scoringChartScoreAtom);
  const resetScoringChart = useResetRecoilState(scoringChartScoreAtom);
  const [showInfo, setShowInfo] = useState(false);
  const classes = useStyles({ scoring: scoringChart?.currentScoring });

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      try {
        const response = await scoringChartReq();

        setScoringChart(response);
      } catch (e) {
        console.log('Scoring Chart Error');
      } finally {
        setIsLoading(false);
      }
    };

    getData();

    return resetScoringChart();
  }, [resetScoringChart, setScoringChart]);

  const countUp = useMemo(() => {
    if (!scoringChart) {
      return;
    }
    const isBigNumber = scoringChart?.currentScoring < -999 || scoringChart?.currentScoring > 999;

    return {
      suffix: isBigNumber ? 'K' : '',
      end: isBigNumber
        ? Math.abs(scoringChart?.currentScoring) / 1000
        : Math.abs(scoringChart?.currentScoring),
      decimals: isBigNumber ? 1 : 0,
    };
  }, [scoringChart]);

  const description = useMemo(() => {
    if (!scoringChart) {
      return;
    }

    const score = scoringChart?.currentScoring;
    switch (true) {
      case score >= 15 && score <= 30:
        return descriptionData.awesome;
      case score >= 0 && score <= 14:
        return descriptionData.good;
      case score <= -1 && score >= -30:
        return descriptionData.bad;
      case score <= -31 && score >= -100:
        return descriptionData.risky;
      case score <= -101 && score >= -500:
        return descriptionData.dangerous;
      case score <= -501:
        return descriptionData.aggressive;
    }
  }, [scoringChart]);

  const toggleShowInfo = () => setShowInfo((prevState) => !prevState);

  if (isLoading) {
    return (
      <div className={classes.wrap} data-testid='scoring-chart'>
        <Loader width={100} lightMode isBlock />
      </div>
    );
  }

  if (showInfo) {
    return (
      <div className={classes.wrap} data-testid='scoring-chart'>
        <CloseIcon
          className={classes.closeIcon}
          onClick={toggleShowInfo}
          data-testid='close-info'
        />
        <Info />
      </div>
    );
  }

  return (
    <div className={classes.wrap} data-testid='scoring-chart'>
      <InfoIcon className={classes.infoBtn} onClick={toggleShowInfo} data-testid='show-info' />

      {!scoringChart && <div className={classes.emptyWrap}>{t('saving.page:no.data.label')}</div>}

      {scoringChart && description && countUp && (
        <>
          <div className={classes.title} data-testid='scoring-chart-description'>
            {t(description.title)}
          </div>
          <div className={classes.counter} data-testid='scoring-chart-countup'>
            {/* duplicate need for centering title and subtitle*/}
            <DiffScore wrapClassName={classes.delta} value={scoringChart.diffScoring} isBetter />

            <CountUp
              start={0}
              end={countUp.end}
              decimals={countUp.decimals}
              suffix={countUp.suffix}
              className={classes.scoring}
            />

            <DiffScore
              wrapClassName={classes.delta}
              value={scoringChart.diffScoring}
              isBetter={scoringChart.better}
            />
          </div>
          <div className={classes.subtitle} data-testid='scoring-chart-subtitle'>
            {t(description.subtitle)}
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(ScoringChart);
