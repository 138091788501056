import cx from 'classnames';
import React, { FC, useMemo } from 'react';
import ApexChart from 'react-apexcharts';
import { useRecoilValue } from 'recoil';

import { ISavingCircleData } from 'interfaces/saving/i-saving-circle-data';

import { SAVING_CIRCLE_CHART_TYPE } from 'recoil/saving/circleCharts/atom';
import userConfigAtom from 'recoil/user-config';

import Money from 'components/money';

import createOptions from './options';
import useStyles from './styles';

interface IProps {
  isBig?: boolean;
  data: ISavingCircleData | null;
  id: SAVING_CIRCLE_CHART_TYPE;
}

const CircleChart: FC<IProps> = ({ isBig = false, data, id }) => {
  const { currencyIcon } = useRecoilValue(userConfigAtom);
  const classes = useStyles({
    isBig,
  });

  const options = useMemo(() => {
    return createOptions(
      isBig,
      (data?.totalFleetSaving ?? 0) < 0,
      (data?.maxTotalFleetSaving ?? 0) < 0,
      data?.totalFleetSaving ?? 0,
      data?.maxTotalFleetSaving ?? 0,
    );
  }, [isBig, data]);

  return (
    <div className={classes.wrap} data-testid={`circle-chart-${id}-wrap`}>
      <ApexChart
        className={cx((data?.totalFleetSaving ?? 0) < 0 && classes.negativeChart)}
        options={options}
        type='radialBar'
        series={options.series}
        height={isBig ? 280 : 220}
        data-testid='chart'
      />

      <div className={classes.label}>
        <div className={classes.saved} data-testid='label'>
          <Money value={data?.totalFleetSaving} currencyIcon={currencyIcon} />
        </div>
      </div>
    </div>
  );
};

export default CircleChart;
