import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  backdrop: {
    position: 'absolute',
    top: 48,
    right: 0,
    width: 625,
    height: 630,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '10px 0px 20px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
