import { ApexOptions } from 'apexcharts';

import { LANGUAGES } from 'interfaces/common/languages';
import { CHART_TABS } from 'interfaces/scoring/chart-tabs';

import { palette } from 'assets/theme';

export const options = (categories: ApexXAxis['categories']): ApexOptions => ({
  chart: {
    zoom: { enabled: false },
    toolbar: { show: false },
  },
  legend: {
    show: false,
  },
  stroke: {
    curve: 'straight',
    width: 2,
    colors: [palette.custom.white, palette.custom.goldSecondary],
  },
  grid: {
    show: true,
    borderColor: palette.custom.mainLight,
    strokeDashArray: 2,
    position: 'back',
  },
  xaxis: {
    categories: categories,
    labels: {
      show: true,
      style: {
        colors: palette.custom.mainLight,
        fontSize: '12px',
        fontWeight: 500,
        fontFamily: 'SFPro',
      },
    },
    axisBorder: {
      show: true,
      color: palette.custom.mainLight,
      strokeWidth: 1,
      offsetX: 0,
      offsetY: 1,
    },
    axisTicks: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  yaxis: {
    tickAmount: 6,
    labels: {
      show: true,
      style: {
        colors: palette.custom.mainLight,
        fontSize: '12px',
        fontWeight: 500,
        fontFamily: 'SFPro',
      },
    },
    axisBorder: {
      show: true,
      color: palette.custom.mainLight,
      width: 1,
      offsetX: -1,
      offsetY: 0,
    },
  },
  markers: {
    size: [4],
    colors: [palette.custom.lightBlue],
    strokeColors: [palette.custom.white, palette.custom.goldSecondary],
    strokeWidth: 2,
    strokeOpacity: 0.9,
    strokeDashArray: 0,
    fillOpacity: 1,
    discrete: [],
    shape: 'circle',
    radius: 2,
    offsetX: 0,
    offsetY: 0,
    onClick: undefined,
    onDblClick: undefined,
    showNullDataPoints: true,
    hover: {
      size: undefined,
      sizeOffset: 3,
    },
  },
  tooltip: {
    enabled: true,
    shared: true,
    inverseOrder: true,
    x: {
      show: false,
    },
    marker: {
      fillColors: [palette.custom.white, palette.custom.goldSecondary],
    },
  },
});

export const formTabsRender = {
  [LANGUAGES.EN]: [
    { title: 'H', key: CHART_TABS.H },
    { title: 'D', key: CHART_TABS.D },
    { title: 'W', key: CHART_TABS.W },
    { title: 'M', key: CHART_TABS.M },
    { title: 'Y', key: CHART_TABS.Y },
  ],
  [LANGUAGES.AZ]: [
    { title: 'S', key: CHART_TABS.H },
    { title: 'G', key: CHART_TABS.D },
    { title: 'H', key: CHART_TABS.W },
    { title: 'A', key: CHART_TABS.M },
    { title: 'I', key: CHART_TABS.Y },
  ],
} as const;
