import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  drivers: {
    height: '100%',
    padding: '12px 10px 101px 22px',
    userSelect: 'none',
  },

  sorting: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 11px 48px 0',
  },

  sortingName: {
    fontWeight: 500,
    fontSize: '13px',
    color: theme.palette.primary.light,
    lineHeight: '14px',
    cursor: 'pointer',
    marginLeft: '14px',
    paddingBottom: '2px',
    opacity: 0.5,
  },

  active: {
    borderBottom: '1px solid #D6A45F',
    opacity: 1,
  },

  scrollbar: {
    maxHeight: '95%',

    '@global': {
      '.simplebar-scrollbar': {
        overflowY: 'scroll',
        backgroundColor: palette.custom.black,
        width: '7px',
        borderRadius: '7px',
        opacity: '0.5',
      },
    },
  },

  item: {
    display: 'grid',
    gridTemplateColumns: '2fr 64px 120px',
    alignItems: 'start',
    width: '100%',
    marginBottom: '37px',
    color: theme.palette.primary.main,
    lineHeight: '17.9px',
    fontSize: 15,

    '&:last-child': {
      marginBottom: 0,
    },
  },

  block: {
    display: 'flex',
    alignItems: 'center',
  },

  circleMarker: {
    cursor: 'pointer',
  },

  name: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',

    '& > span': {
      flex: 1,
      maxWidth: '150px',
      marginLeft: '20px',
      fontFamily: 'SFText',
      letterSpacing: '-1px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      paddingRight: '8px',
    },
  },

  cards: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    minWidth: '64px',
  },

  card: {
    width: '16px',
    height: '23.47px',
    marginRight: '4px',
    borderRadius: '2.13333px',
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.48)',

    '&--RED': {
      backgroundColor: palette.custom.redSecondary,
    },

    '&--YELLOW': {
      backgroundColor: palette.custom.yellow,
    },
  },

  values: {
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
    maxWidth: '115px',
    justifyContent: 'flex-end',
    paddingRight: '18px',
  },

  scoring: {
    fontSize: '24px',
    lineHeight: '22px',
    fontWeight: 500,
    marginLeft: '10px',
  },

  delta: {
    fontSize: '11px',
    marginLeft: '8px',
  },

  button: {
    position: 'absolute',
    width: '36px',
    height: '36px',
    bottom: '27px',
    left: 0,
    right: 0,
    margin: '0 auto',
  },

  emptyWrap: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 28,
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
