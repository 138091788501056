import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: 300,
    flexDirection: 'column',
    rowGap: 15,
    display: 'flex',
  },
}));

export default useStyles;
