import server from 'utils/server';

import { TDisableVehicleForm } from 'modules/Settings/pages/vehicles/components/disable-modal/form-schema';

/**
 * Disables a vehicle request.
 *
 * @async
 * @param {TDisableVehicleForm} data - The data to disable the vehicle request.
 * @returns {Promise<string>} - A Promise that resolves with a string indicating the success status or assign driver id
 */
export const disableVehicleReq = async (data: TDisableVehicleForm): Promise<string> =>
  server.services.beService
    .put<string>('vehicles/activate', data)
    .then((res) => (res.data ? res.data : 'ok'));
