import { format } from 'date-fns';

import { ITrip, ITripWithDateLabel } from 'interfaces/map/i-trip';

/**
 * Add startDateLabel to a trip if previous trip date different
 */
export const addingDateLabel = (trips: ITrip[]): ITripWithDateLabel[] => {
  return trips.reduce((acc, item, i) => {
    const itemDate = format(new Date(item.startGpsPoint.timestamp!), 'dd MMM yyyy');
    const prevItemDate = acc[i - 1]
      ? format(new Date(acc[i - 1].startGpsPoint.timestamp!), 'dd MMM yyyy')
      : null;

    const formatItem = {
      ...item,
      startDateLabel: itemDate !== prevItemDate ? itemDate : null,
    };

    return acc.concat(formatItem);
  }, [] as ITripWithDateLabel[]);
};
