import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { FCC } from 'interfaces/common/fc-with-children';

import RouteManager from 'utils/services/route-manager';

import wasReadTechnicalMessageAtom from 'recoil/technical-message/was-read';
import userDataAtom from 'recoil/userData';

import TechnicalWorks from '../tecnical-works';

const PrivateRoute: FCC = ({ children }) => {
  const navigate = useNavigate();
  const wasReadTechnicalMessage = useRecoilValue(wasReadTechnicalMessageAtom);
  const userData = useRecoilValue(userDataAtom);

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');

    if (!authToken) {
      navigate(RouteManager.makeURL('login'));
    }
  });

  return (
    <div>
      {!wasReadTechnicalMessage && userData?.warningNotification && <TechnicalWorks />}
      {children}
    </div>
  );
};

export default PrivateRoute;
