import { atom } from 'recoil';

import { INotificationMessage } from 'interfaces/notifications/message';

const notificationMessagesAtom = atom<INotificationMessage[]>({
  key: 'notificationMessagesAtom',
  default: [],
});

export default notificationMessagesAtom;
