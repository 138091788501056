import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    color: palette.custom.blue200,
    fontWeight: 500,

    '&.isWarning': {
      color: palette.custom.yellow400,
    },
  },
}));

export default useStyles;
