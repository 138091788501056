import React, { FC, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import savingCircleChartsAtom from 'recoil/saving/circleCharts';
import { SAVING_CIRCLE_CHART_TYPE } from 'recoil/saving/circleCharts/atom';
import savingFilterAtom from 'recoil/saving/filter';

import { getCircleData } from 'requests/be-service/saving-controller/get-circle-data';

import Loader from 'components/Loader';

import CircleChart from '../CircleChart';
import useStyles from '../CircleChart/styles';

interface IProps {
  path: string;
  id: SAVING_CIRCLE_CHART_TYPE;
  isBig?: boolean;
}

const CircleChartApi: FC<IProps> = ({ path, id, isBig = false }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [savingCircleCharts, setSavingCircleCharts] = useRecoilState(savingCircleChartsAtom);
  const savingFilter = useRecoilValue(savingFilterAtom);
  const classes = useStyles({ isBig });

  /**
   * Get data for current chart and set this to recoil
   */
  useEffect(() => {
    const request = async () => {
      try {
        setIsLoading(true);
        const res = await getCircleData(path, savingFilter);
        setSavingCircleCharts((prevState) => ({
          ...prevState,
          [id]: res,
        }));
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    request();
  }, [id, path, savingFilter, setSavingCircleCharts]);

  if (isLoading) {
    return (
      <div className={classes.wrap}>
        <Loader width={100} lightMode isBlock />
      </div>
    );
  }

  return <CircleChart id={id} data={savingCircleCharts[id]} isBig={isBig} />;
};

export default CircleChartApi;
