import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    filter: 'drop-shadow(0px 2px 13px rgba(0, 0, 0, 0.18))',
    position: 'relative',
    width: 'fit-content',
    height: 'fit-content',
    padding: 3,
    backgroundColor: theme.palette.primary.light,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid transparent',
    transform: 'translateX(-50%) translateY(-48px) rotate(45deg)',

    '&.isActive': {
      border: `2px solid ${palette.custom.goldSecondary}`,
      backgroundColor: palette.custom.goldSecondary,
      zIndex: 1500,
      filter: 'none',
    },
  },

  count: {
    position: 'relative',
    width: 24,
    height: 24,
    color: theme.palette.primary.light,
    background: palette.custom.bluePrimary,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 14,
    cursor: 'pointer',
    transform: 'rotate(-45deg)',

    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: -9,
      left: '50%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '9.5px 5.5px 0 5.5px',
      borderColor: `${theme.palette.primary.light} transparent transparent transparent`,
    },

    '&.isActive:after': {
      borderColor: `${palette.custom.goldSecondary} transparent transparent transparent`,
    },
  },
}));

export default useStyles;
