import { bbox } from '@turf/turf';
import { format } from 'date-fns';
import { getTimezoneOffset, utcToZonedTime } from 'date-fns-tz';
import { fitBounds } from 'google-map-react';

import { palette } from 'assets/theme';

import { valueConverter } from './helpers/value-converter';

/**
 * Function for converting time without taking into account time zones
 * @param date
 * @param timeFormat
 * @returns {string}
 */
export const setZonedTime = (date, timeFormat) => {
  if (!date) {
    return null;
  }

  if (typeof date === 'number') {
    return format(utcToZonedTime(new Date(date), 'UTC'), timeFormat);
  }
  const result = getTimezoneOffset(date);
  return format(utcToZonedTime(Date.parse(date) + result, 'UTC'), timeFormat);
};

/**
 * Convert vehicle data from API to map cluster format
 */
export const createClusterVehicle = (vehicle) => ({
  type: 'Feature',
  properties: {
    cluster: false,
    ...vehicle,
  },
  geometry: {
    type: 'Point',
    coordinates: [
      vehicle?.position?.longitude ?? vehicle.viewData.point.gpsPoint.longitude,
      vehicle?.position?.latitude ?? vehicle.viewData.point.gpsPoint.latitude,
    ],
  },
});

/**
 * Create driver trip state
 * @return {{route: {start: {date: {time: (string|string), day: (string|string)}, address: (string|*)}, end: {date: {time: (string|string), day: (string|string)}, address: (string|*)}}, startDateLabel: (boolean|string|*), id: (*|string), statistics: {saving: (string|string|`${string}₼${number}`)[], distance: (number|*|number|string)[], driver: null, penaltyCard, usage: (string|string|`${string}₼${number}`)[], maxSpeed: ([number]|*|string)[], geofencing: number, totalScore: (number|*), customer: null}}}
 */
export const createDriverTripInfo = ({ trip, tripSaving, maxSpeedPoint }, isMetricSystem) => {
  const usage = [
    trip?.fuelUsed
      ? isMetricSystem
        ? `${trip?.fuelUsed.toFixed(2)}`
        : `${valueConverter.volume.toImperial(trip?.fuelUsed).toFixed(2)}`
      : null,
  ];

  const saving = [
    tripSaving?.overFuelCostAmount ? tripSaving.overFuelCostAmount?.toFixed(1) : null,
  ];
  return {
    statistics: {
      distance: [trip.km],
      driver: null,
      customer: null,
      geofencing: 0,
      maxSpeed: [trip?.maxSpeed ?? '-'],
      penaltyCard: trip.penaltyCard,
      totalScore: trip?.currentScoring ? Math.round(trip?.currentScoring) : 0,
      usage,
      saving,
    },
    route: {
      start: {
        address: trip?.startAddress?.fullAddress ?? '',
        date: {
          day: trip?.startGpsPoint?.timestamp
            ? format(new Date(trip.startGpsPoint.timestamp), 'dd.MM.yyyy')
            : '',
          time: trip?.startGpsPoint?.timestamp
            ? format(new Date(trip.startGpsPoint.timestamp), 'HH:mm')
            : '',
        },
      },
      end: {
        address: trip?.finishAddress?.fullAddress,
        date: {
          day: trip?.finishGpsPoint?.timestamp
            ? format(new Date(trip.finishGpsPoint.timestamp), 'dd.MM.yyyy')
            : '',
          time: trip?.finishGpsPoint?.timestamp
            ? format(new Date(trip.finishGpsPoint.timestamp), 'HH:mm')
            : '',
        },
      },
    },
    id: trip?.id ?? '',
    startDateLabel: trip?.startDateLabel ?? '',
    maxSpeedPoint,
  };
};

/**
 * @param count
 * @return {string|null}
 */
export const getMoneyColor = (count) => {
  if (count < 0) {
    return 'isRed';
  }

  if (count > 0) {
    return 'isGreen';
  }

  return null;
};

/**
 * Function to navigate to an anchor on the page
 */
export const handleGetInAnchor = (id) => {
  const anchorNode = document.getElementById(id);
  anchorNode?.scrollIntoView({ behavior: 'smooth' });
};

// change color scoring page
export const scoringColor = (scoring = 0) => {
  if (scoring >= 0) {
    return palette.custom.green400;
  }

  if (scoring >= -30) {
    return palette.custom.yellow400;
  }

  return palette.custom.redSecondary;
};

/**
 * Create progress for chart
 * @param scoring
 * @return {number}
 */
export const createScoringSeries = (scoring) => {
  if (isNaN(!scoring)) {
    return 0;
  }
  if (scoring > 0) {
    return scoring * (100 / 30);
  }
  if (scoring > -30) {
    return -(scoring * (100 / 30));
  }
  return -((scoring + 30) * (100 / 30));
};

// removing and sorting duplicate driver cards
export const removingDuplicates = (arr) => {
  if (!arr) {
    return null;
  }
  let yellow = 2;
  let red = 1;
  return arr
    .filter((card) => {
      if (card === 'YELLOW' && yellow > 0) {
        yellow--;
        return true;
      }
      if (card === 'RED' && red > 0) {
        red--;
        return true;
      }
      return false;
    })
    .sort()
    .reverse();
};

// calculation of the length of the minibus that the driver drove, from the one proposed by Google
export const calculateGeofencing = (distance, route) => {
  const geofencing = Math.round((distance * 1000 - route) / 1000);
  return geofencing < 0 ? 0 : geofencing;
};

/**
 * @param obj
 * @return {boolean}
 */
export const isEmptyObj = (obj) => {
  for (let key in obj) {
    return false;
  }
  return true;
};

/**
 * Get map settings based on route
 * @param line
 * @param mapSize
 * @return {{center: {lat: number, lng: number}, zoom: number}}
 */
export const getMapSettings = (line, mapSize) => {
  const boundsBox = bbox(line);
  const bounds = {
    nw: {
      lng: boundsBox[0],
      lat: boundsBox[1],
    },
    se: {
      lng: boundsBox[2],
      lat: boundsBox[3],
    },
  };

  const { center, zoom } = fitBounds(bounds, mapSize);
  return {
    center,
    zoom: zoom - 1,
  };
};

/**
 * @param ms
 * @return {Promise<unknown>}
 */
export const delay = async (ms) => await new Promise((resolve) => setTimeout(resolve, ms));

export const convertToDoubleNumber = (number) => (number > 9 ? number : `0${number}`);
