import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useVirtual } from '@tanstack/react-virtual';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import useTableSort from 'hooks/useTableSort';

import { ISortParameters } from 'interfaces/common/sort';
import { TABLE_VIEW_TYPE } from 'interfaces/common/table-view-type';

import { driverListItemsDTO } from 'utils/dto/driver/list-item';

import driversFiltersDataAtom from 'recoil/driver/drivers-filters';
import { IDriversFilters } from 'recoil/driver/drivers-filters/atom';
import driversSortDataAtom from 'recoil/driver/sort-data';
import driverTripsFilterAtom from 'recoil/drivers-vehicles/driver-trips-filters';
import driverTripsSelectedPeriodAtom from 'recoil/drivers-vehicles/driver-trips-selected-period';
import driversListAtom from 'recoil/drivers-vehicles/drivers-list';
import driversSelectedPeriodAtom from 'recoil/drivers-vehicles/drivers-selected-period';
import driversVehiclesShowDateFilterAtom from 'recoil/drivers-vehicles/show-date-filter';
import driversVehiclesShowDriversFilterAtom from 'recoil/drivers-vehicles/show-drivers-filter';
import driversVehiclesShowReportFilterAtom from 'recoil/drivers-vehicles/show-report-filters';
import { SORT_DIRECTION } from 'recoil/settings/payment/transactions/sort';
import technicalMessageBlockHeightSelector from 'recoil/technical-message/block-height';
import userConfigAtom from 'recoil/user-config';

import { driversReq, IGetDriversItem } from 'requests/be-service/driver-controller/get-drivers';

import Drawer from 'components/drawer';
import Loader from 'components/Loader';
import Modal from 'components/modal';
import PaginationScroll from 'components/pagination-scroll';
import TableSortLabel from 'components/TableSortLabel';

import DateFilter from '../components/date-filter';
import ReportFilter from '../components/driver-report-filter';
import DriverRow from '../components/driver-row';
import DriversFilter from '../components/drivers-filter';
import QuickDriverEdit from '../components/quick-driver-edit';

import { TABLE_HEAD_COLUMNS } from './data';
import useStyles from './styles';

const DriversList = () => {
  const technicalMessageBlockHeight = useRecoilValue(technicalMessageBlockHeightSelector);
  const classes = useStyles({ technicalMessageBlockHeight });
  const { t } = useTranslation(['driver.list.page', 'fields']);
  const { isMetricSystem } = useRecoilValue(userConfigAtom);

  const [drivers, setDrivers] = useRecoilState(driversListAtom);
  const [sortData, setSortData] = useRecoilState(driversSortDataAtom);
  const [, setDriversSort] = useTableSort({ sort: '', direction: SORT_DIRECTION.ASC });
  const [driversFiltersData, setDriversFiltersData] = useRecoilState(driversFiltersDataAtom);
  const setSelectedPeriod = useSetRecoilState(driversSelectedPeriodAtom);
  const setDriverTripsFiltersData = useSetRecoilState(driverTripsFilterAtom);
  const setDriverTripsSelectedPeriod = useSetRecoilState(driverTripsSelectedPeriodAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [tableViewType, setTableViewType] = useState<TABLE_VIEW_TYPE>(TABLE_VIEW_TYPE.INFO);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalDrivers, setTotalDrivers] = useState(0);
  const [showDateFilter, setShowDateFilter] = useRecoilState(driversVehiclesShowDateFilterAtom);
  const [showQuickEditVehicle, setShowQuickEditVehicle] = useState(false);
  const [quickEditVehicle, setQuickEditVehicle] = useState<IGetDriversItem['vehicle'] | null>(null);
  const [quickEditDriverId, setQuickEditDriverId] = useState<string | null>(null);
  const [showDriversFilter, setShowDriversFilter] = useRecoilState(
    driversVehiclesShowDriversFilterAtom,
  );
  const [showReportFilter, setShowReportFilter] = useRecoilState(
    driversVehiclesShowReportFilterAtom,
  );

  const tableRef = React.useRef(null);

  const rowVirtualizer = useVirtual({
    size: drivers.length,
    parentRef: tableRef,
    overscan: 5,
  });

  const clearData = useCallback(() => {
    setDrivers([]);
  }, [setDrivers]);

  /**
   * Change table view type
   */
  const handleTableViewType = () =>
    setTableViewType((prevState) =>
      prevState === TABLE_VIEW_TYPE.INFO ? TABLE_VIEW_TYPE.STATISTIC : TABLE_VIEW_TYPE.INFO,
    );

  /**
   * Set new sort for table
   * @type {(function(*): void)|*}
   */
  const handleTableSort = useCallback(
    (value: string) => {
      const newSort = setDriversSort(value);
      setSortData(newSort);
    },
    [setDriversSort, setSortData],
  );

  /**
   * Get drivers from API
   * @type {(function(*, *): Promise<void>)|*}
   */
  const getDrivers = useCallback(
    async (sort: ISortParameters, page: number, filters: IDriversFilters) => {
      try {
        setIsLoading(true);
        const res = await driversReq(sort, page, filters);
        setCurrentPage(res.pageable.pageNumber);
        setTotalDrivers(res.totalElements);
        setDrivers((prevState) => [
          ...prevState,
          ...driverListItemsDTO(res.content, isMetricSystem),
        ]);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [isMetricSystem, setDrivers],
  );

  /**
   * Load additional drivers
   */
  const loadMoreHandler = useCallback(
    () => getDrivers(sortData, currentPage + 1, driversFiltersData),
    [currentPage, driversFiltersData, getDrivers, sortData],
  );

  /**
   * Open drawer for editing assign vehicle sensors
   */
  const vehicleEditHandler = useCallback(
    (vehicle: IGetDriversItem['vehicle'], driverId: string) => {
      setQuickEditVehicle(vehicle);
      setShowQuickEditVehicle(true);
      setQuickEditDriverId(driverId);
    },
    [],
  );

  /**
   * Init drivers request
   */
  useEffect(() => {
    setDrivers([]);
    setCurrentPage(0);
    getDrivers(sortData, 0, driversFiltersData);
  }, [driversFiltersData, getDrivers, setDrivers, sortData]);

  /**
   * clear after leave page
   */
  useEffect(() => {
    return () => clearData();
  }, [clearData]);

  return (
    <div className={classes.wrap}>
      {isLoading && <Loader lightMode width={250} isBlock preventClick />}

      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader data-testid='drivers-table'>
          <TableHead>
            <TableRow>
              {TABLE_HEAD_COLUMNS(t, isMetricSystem)[tableViewType].map(
                ({ id, label, styles, align }) => (
                  <TableCell
                    classes={{ head: classes.headCell }}
                    key={id}
                    style={{ ...styles }}
                    align={align}
                  >
                    <TableSortLabel
                      name={id}
                      onClick={handleTableSort}
                      sortField={sortData.sort}
                      direction={sortData.direction}
                    >
                      {label}
                    </TableSortLabel>
                  </TableCell>
                ),
              )}
              <TableCell
                align={'center'}
                classes={{ head: classes.editHeadCell }}
                onClick={handleTableViewType}
                data-testid='button-handleTableViewType'
              >
                |||
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tbody} ref={tableRef} data-testid='drivers-list'>
            {rowVirtualizer.virtualItems.map((item) => {
              const data = drivers[item.index];

              return (
                <DriverRow
                  key={`${data.driverId}-${data.firstName}`}
                  data={data}
                  tableViewType={tableViewType}
                  editHandler={vehicleEditHandler}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationScroll
        count={drivers.length}
        maxCount={totalDrivers}
        loadMoreHandler={loadMoreHandler}
      />

      <Modal onClose={() => setShowDateFilter(false)} open={showDateFilter}>
        <DateFilter
          setDateFilter={(data) => {
            setDriversFiltersData((prevState) => ({
              ...prevState,
              movementAtFrom: new Date(data.start).getTime(),
              movementAtTill: new Date(data.end).getTime(),
            }));
            setDriverTripsFiltersData((prevState) => ({
              ...prevState,
              movementAtFrom: new Date(data.start).getTime(),
              movementAtTill: new Date(data.end).getTime(),
            }));
          }}
          setSelectedPeriod={(id) => {
            setSelectedPeriod(id);
            setDriverTripsSelectedPeriod(id);
          }}
          onCloseFilter={() => setShowDateFilter(false)}
        />
      </Modal>

      <Modal onClose={() => setShowDriversFilter(false)} open={showDriversFilter}>
        <DriversFilter />
      </Modal>

      <Modal onClose={() => setShowReportFilter(false)} open={showReportFilter}>
        <ReportFilter />
      </Modal>

      <Drawer open={showQuickEditVehicle} onClose={() => setShowQuickEditVehicle(false)}>
        {quickEditVehicle && quickEditDriverId && (
          <QuickDriverEdit
            vehicle={quickEditVehicle}
            onClose={() => setShowQuickEditVehicle(false)}
            driverId={quickEditDriverId}
          />
        )}
      </Drawer>
    </div>
  );
};

export default DriversList;
