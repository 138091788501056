import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { ReactComponent as GeoIcon } from 'assets/images/geo.svg';
import { ReactComponent as WarningIcon } from 'assets/images/warning.svg';

import crashModalAtom from 'recoil/crash-modal';
import isMapOpenAtom from 'recoil/mainMap/isOpen';

import useStyles from './styles';

const Path = ({ isBig }) => {
  const classes = useStyles({ isBig });
  const navigate = useNavigate();

  const [, setIsMainMapOpen] = useRecoilState(isMapOpenAtom);
  const [{ driverData }] = useRecoilState(crashModalAtom);
  const resetCrashModal = useResetRecoilState(crashModalAtom);

  const handleAddressClick = useCallback(() => {
    setIsMainMapOpen(false);
    navigate(`/app/content/driver/${driverData?.driver?.id}/trip/${driverData?.tripId}`);
    resetCrashModal();
  }, [driverData?.driver?.id, driverData?.tripId, navigate, resetCrashModal, setIsMainMapOpen]);

  return (
    <div className={classes.pathWrap}>
      {driverData?.location?.startPoint?.address && (
        <div className={classes.point}>
          <GeoIcon />
          <div className={classes.pointDesc}>
            <div
              className={classes.address}
              title={driverData.location.startPoint.address}
              onClick={handleAddressClick}
            >
              {driverData.location.startPoint.address}
            </div>
            <div className={classes.time}>
              {format(new Date(driverData.location.startPoint.gpsPoint.timestamp), 'HH:mm')}
            </div>
          </div>
        </div>
      )}

      <div className={classes.point}>
        <WarningIcon />
        <div className={classes.pointDesc}>
          <div
            className={classes.address}
            title={driverData.location.crashPoint.address}
            onClick={handleAddressClick}
          >
            {driverData.location.crashPoint.address}
          </div>
          <div className={classes.time}>
            {format(new Date(driverData.location.crashPoint.gpsPoint.timestamp), 'HH:mm')}
          </div>
        </div>
      </div>
    </div>
  );
};

Path.defaultProps = {
  isBig: false,
};

Path.propTypes = {
  isBig: PropTypes.bool,
};

export default React.memo(Path);
