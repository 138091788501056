import i18next from 'i18next';
import Fetch from 'i18next-fetch-backend';
import { initReactI18next } from 'react-i18next';

import { TTranslates, TTranslatesObj } from 'interfaces/common/translates';

const localStorageKey = 'authToken';
const token = window.localStorage.getItem(localStorageKey);
const userLang = window.localStorage.getItem('lang');
const defaultLang = process.env.REACT_APP_DEFAULT_LANG;
const userDomain = localStorage.getItem('domain') || process.env.REACT_APP_BASE_URL || '';

export const localizationRequestHeaders = new Headers();

localizationRequestHeaders.append('accept', '*/*');
localizationRequestHeaders.append('content-type', 'application/json');
localizationRequestHeaders.append('Access-Control-Allow-Origin', String(true));

if (token) {
  localizationRequestHeaders.set('Authorization', `Bearer ${token}`);
}

const requestOptions = {
  headers: localizationRequestHeaders,
};

const i18n = i18next
  .use(Fetch)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${userDomain}${process.env.REACT_APP_LOCALIZE_API_URL}/{{ns}}?language={{lng}}`,
      parse: (data: string) => {
        const result = {} as TTranslatesObj;
        const dataArray = JSON.parse(data) as TTranslates;
        dataArray.forEach((item) => {
          result[item.key.trim()] = item.value;
        });
        return result;
      },
      requestOptions,
      referenceLng: userLang ?? defaultLang,
    },
    react: {
      useSuspense: false,
    },
    fallbackLng: ['en', 'az'],
    lng: userLang ?? defaultLang,
    ns: ['login'],
    defaultNS: 'login',
  });

export default i18n;
