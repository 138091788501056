import { TVehicleEditData } from 'interfaces/vehicles/vehicle-edit-data';

import { vehicleDTO } from 'utils/dto/vehicle/edit-form';
import server from 'utils/server';

/**
 * Retrieves vehicle data from the server.
 * @async
 * @param {string} id - The ID of the vehicle.
 * @param {boolean} [isMetricSystem=true] - Specifies whether the data should be returned in metric units.
 * @returns {Promise<TVehicleEditData>} A promise that resolves to the requested vehicle data.
 */
export const vehicleGetReq = async (
  id: string,
  isMetricSystem: boolean = true,
): Promise<TVehicleEditData> => {
  return server.services.beService
    .get<TVehicleEditData>(`vehicles/v2/${id}`)
    .then<TVehicleEditData>(({ data }) =>
      isMetricSystem ? { ...data } : vehicleDTO.toImperial(data),
    );
};
