import React from 'react';

import { FCC } from 'interfaces/common/fc-with-children';

import useStyles from './styles';

interface IBackdrop {
  onClose: () => void;
}

const Backdrop: FCC<IBackdrop> = ({ children, onClose }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.backdrop}
      data-id='backdrop'
      onClick={(e) => {
        const target = e.target as HTMLDivElement;

        if (target.dataset?.id === 'backdrop') {
          onClose();
        }
      }}
    >
      {children}
    </div>
  );
};

export default Backdrop;
