import cx from 'classnames';
import React, { FC } from 'react';

import { ReactComponent as DeltaIcon } from 'assets/images/delta.svg';

import ScoreValue from '../score-value';

import useStyles from './styles';

interface IProps {
  value: number;
  isBetter: boolean;
  wrapClassName?: string;
}

const DiffScore: FC<IProps> = ({ value, isBetter, wrapClassName }) => {
  const classes = useStyles();

  return (
    <span
      className={cx(classes.item, isBetter && classes.reversed, wrapClassName)}
      data-testid={'diff-scoring'}
    >
      <ScoreValue value={value} isMonochrome />
      <DeltaIcon />
    </span>
  );
};

export default DiffScore;
