import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

const useStyles = makeStyles((theme) => ({
  wrap: {
    marginBottom: 56,
    paddingLeft: 35,
    cursor: 'pointer',
    borderBottom: '1px solid transparent',

    '&.isOpen': {
      borderBottom: `1px solid ${palette.custom.lightBlue}`,
    },
  },

  companyWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  avatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 35,
    minWidth: 35,
    height: 35,
    marginRight: 17,
  },

  companyName: {
    fontSize: 14,
    lineHeight: '16.71px',
    color: theme.palette.primary.dark,
    whiteSpace: 'nowrap',
  },

  arrow: {
    marginLeft: 9,
    width: 8,
    cursor: 'pointer',
    transform: 'rotate(-90deg)',

    '&.isOpenList': {
      transform: 'rotate(90deg)',
    },
  },

  listScroll: {
    maxHeight: 140,
    paddingRight: 10,

    '& .simplebar-content': {
      minHeight: 'unset',
    },
  },

  list: {
    marginLeft: 15,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
    borderLeft: `1px solid ${palette.custom.mainLight}`,
  },
}));

export default useStyles;
