import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { palette } from 'assets/theme';

type Props = {
  inputsWrapWidth: number;
};

const useStyles = makeStyles<Theme, Props>(() => ({
  wrap: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },

  inputsWrap: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    border: '1px solid transparent',
    borderRadius: 5,
    backgroundColor: palette.custom.blue900,

    '&.hasFocus': {
      backgroundColor: palette.custom.softBlue,
    },

    '&.hasError': {
      border: `1px solid ${palette.custom.redSecondary}`,
    },
  },

  selectRoot: {
    color: palette.custom.blue200,
    display: 'flex',
    alignItems: 'center',

    '& fieldset': {
      border: 'none',
    },
  },

  selectDisabled: {
    textFillColor: `${palette.custom.blue200} !important`,
  },

  selectArrow: {
    marginRight: 10,
    pointerEvents: 'none',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',

    '&.MuiSelect-iconOpen': {
      transform: 'translateY(-50%) rotate(180deg)',
    },
  },

  selectPaper: {
    boxShadow: 'none',
    borderRadius: '0 0 4px 4px',
    border: `1px solid ${palette.custom.blue800}`,
    borderTop: 'none',
    maxHeight: 125,
    width: ({ inputsWrapWidth }) => inputsWrapWidth,
    transform: 'translateX(-1px) !important',
    backgroundColor: palette.custom.blue900,

    '&::-webkit-scrollbar': {
      borderRadius: '0 0 10px 0',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: palette.custom.blue700,
    },
  },

  selectList: {
    backgroundColor: palette.custom.blue900,
  },

  selectedOption: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'SFPro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: palette.custom.blue200,
  },

  select: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '5px',
    padding: 8,
  },

  inputWrap: {
    borderRadius: '0 5px 5px 0',
    padding: '3px 10px',
    border: 'none',
    flex: 1,
    color: palette.custom.blue300,
  },

  countryCode: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 5,
  },
}));

export default useStyles;
