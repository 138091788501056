import queryString from 'query-string';

import { ACTIVITY_STATUSES } from 'interfaces/common/activity-statuses';
import { IMapSideBarItem } from 'interfaces/map/i-map-side-bar-item';

import server from 'utils/server';

/**
 * Get all vehicles from API by sidebar settings
 *
 * @async
 * @param {ACTIVITY_STATUSES[]} status - The activity statuses of vehicles.
 * @param {boolean} all - Flag indicating whether to fetch all vehicles or not.
 * @param {string[]} driverIds - The IDs of drivers who are associated with the vehicles.
 * @param {AbortSignal} signal - The AbortSignal object used to cancel the request.
 * @returns {Promise<IMapSideBarItem[]>} The promise that resolves with the fetched data.
 */
export const mapGetAllVehiclesBySidebarReq = async (
  status: ACTIVITY_STATUSES[],
  all: boolean,
  driverIds: string[],
  signal: AbortSignal,
): Promise<IMapSideBarItem[]> => {
  const params = {
    all,
    driverIds,
    status: status.length ? status : null,
    companyId: sessionStorage.getItem('subcompanyId') ?? localStorage.getItem('companyId'),
  };

  return server.services.beService
    .get<IMapSideBarItem[]>('v2/map/view?' + queryString.stringify(params), { signal })
    .then((res) => res.data);
};
