import { atom } from 'recoil';

import recoilLocalStorageEffect from 'utils/recoil-localStorage-effect';

import { CALENDAR_DEFAULT_TYPES } from 'modules/drivers-vehicles/components/header/data';

const driverTripsSelectedPeriodAtom = atom<CALENDAR_DEFAULT_TYPES>({
  key: 'driverTripsSelectedPeriodAtom',
  default: CALENDAR_DEFAULT_TYPES.TODAY,
  effects_UNSTABLE: [
    recoilLocalStorageEffect('driverTripsSelectedPeriodAtom', CALENDAR_DEFAULT_TYPES.TODAY),
  ],
});

export default driverTripsSelectedPeriodAtom;
